/**
 * User: Kupin.R
 * Date: 5/29/14
 */



angular
    .module("flipto.components.goals")
    .filter("goalName",
    [
        "$filter", "goalDescriptor", 'goalType',
        function ($filter, goalDescriptor, goalType) {

            var translate = $filter("translate"),
                nameFormats = {};

            angular.forEach(goalType, function (value, key) {
                nameFormats[value] = 'flipto.components.goals.' + key;
            });

            function getGoalName(goal) {
                var descriptor = goalDescriptor(goal);
                var format = translate(nameFormats[descriptor.type]);
                if (angular.isDefined(descriptor.value)) {
                    return format.replace("#", descriptor.value);
                }
                return format;
            }

            return function (goal) {
                return getGoalName(goal);
            };
        }
    ]);
