/**
 * Created by roman.kupin on 3/16/2018.
 */

import {CreateContestModalComponent} from "./createContestModal.component";


export class CreateContestModalService {

    static Name = "ftCreateContestModal";

    private scope = null;
    private element = null;

    static $inject = ["$compile", "$rootScope", "ftModal"];

    constructor(private $compile,
                private $rootScope,
                private ftModal) {

        const destroyModal = () => {
            this.ftModal.destroy("AccountV2.Components.CreateContestModal");
            if (!!this.element) {
                this.element.remove();
                this.element = null;
            }
            if (!!this.scope) {
                this.scope.$destroy();
                this.scope = null;
            }
        };

        this.$rootScope.$on("modal-AccountV2.Components.CreateContestModal.cancel", destroyModal);
        this.$rootScope.$on("modal-AccountV2.Components.CreateContestModal.done", destroyModal);
    }

    show(propertyUuid, clonableContests, onCreated, onCreateFailed, theme) {
        this.ensureComponent();
        this.scope.propertyUuid = propertyUuid;
        this.scope.clonableContests = clonableContests;
        this.scope.onCreated = onCreated;
        this.scope.onCreateFailed = onCreateFailed;
        this.scope.theme = theme;
        this.ftModal.show("AccountV2.Components.CreateContestModal");
    }

    hide() {
        this.ftModal.hide("AccountV2.Components.CreateContestModal");
    }

    private ensureComponent() {
        if (!this.element) {
            this.scope = Object.assign(this.$rootScope.$new(true));
            this.element = this.$compile(`<ft-create-contest-modal property-uuid="propertyUuid" clonable-contests="clonableContests" on-created="onCreated(contestUuid)" on-create-failed="onCreateFailed()" theme="theme"></ft-create-contest-modal>`)(this.scope);
            document.body.appendChild(this.element[0]);
            this.scope.$on("destroy", () => {
                if (!!this.element) {
                    this.element.remove();
                    this.element = null;
                }
                this.scope = null;
            });
        }
    }

}