/**
 * Created by kpomk on 1/17/2017.
 */

angular.module('flipto.components.common.iframeContent', [])
    .directive("ftIframeContent", [function () {

        return {
            restrict: "E",
            scope: {
                content:"="
            },
            template: "<iframe ng-if='!!content'></iframe>",
            link: function (scope, elem) {

                scope.$watch("content", function (content) {
                    if(!!content){
                        writeToIframe(content);
                    }

                }, true);

                function getIframe() {
                    return elem.find("iframe")[0];
                }

                function writeToIframe(content) {
                    try {
                        var iframeElem = getIframe();
                        iframeElem.contentWindow.document.open();
                        iframeElem.contentWindow.document.write(content);
                        iframeElem.contentWindow.document.close();    
                    } catch (e) {
                    }
                }

            }
        };
    }]);