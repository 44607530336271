<!-- styling component here.-->
<ft-snapshot-form (commit)="commit($event)" [loading$]="isLoading$" [commitSuccess$]="commitSuccess$">
  <div class="ft2-container">
    <div class="ft2-block -small">
      <p>
        This CSS applies site-wide for the Discovery implementation. This should only be used to inject styles that must persist even when a Discovery component is closed or does not appear on the page.
      </p>
  </div>
    <div class="ft2-block -small">
      <form ngForm>
        <ng-container *ngIf="stylingViewModel$ | async | clone as data">
          <ft-code-editor [replaceInvalidSymbols]="true" [language]="'css'" [title]="'CSS'" name="globalCss" [(ngModel)]="data.globalCss"
            [required]="false">
            <ft-edit-panel label="CSS" [loading]="isLoading$ | async">
              <div class="col-8" [innerHTML]="data.globalCss | ftPlaceholder"></div>
            </ft-edit-panel>
          </ft-code-editor>
          </ng-container>
      </form>
    </div>
  </div>
</ft-snapshot-form>
