/**
 * Created by ostapenko.r on 2/9/2015.
 * Updated by kevintiller on 10/19/2015
 */
angular.module('flipto.components.common.validation')
    .directive('ftEmailCsvValidate', function () {
        var emailRegex = new RegExp('^([^.@]+)(\\.[^.@]+)*@([^.@]+\\.)+([^.@]{2,})$');
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                var separator = attrs.ftEmailCsv || ';';

                ctrl.$parsers.unshift(function (value) {
                    var valid = validate(value.split(separator));
                    ctrl.$setValidity('emailCsvValidate', valid);
                    return valid ? value : undefined;
                });

                ctrl.$formatters.unshift(function (value) {
                    var valid = validate(value);
                    ctrl.$setValidity('emailCsvValidate', valid);
                    return value;
                });

                function validate(value) {
                    var emails = _.chain(value).map(function (email) {
                        return email.trim();
                    }).compact().value();
                    var valid = _.every(emails, function (email) {
                        return emailRegex.test(email);
                    });
                    return valid;
                }

            }
        };
    });
