import { Component, forwardRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';
import { NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';

@Component({
    selector: 'ft-booking-option-text-editor',
    templateUrl: './booking-option-text-editor.component.html',
    styleUrls: ['./booking-option-text-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => BookingOptionTextEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: BookingOptionTextEditorComponent
        }
    ]
})
export class BookingOptionTextEditorComponent extends ModalEditorBase {
    @Input() bindLabel: string = 'label';
    @Input() bindSummary: string = 'summary';
    @Input() bindDescription: string = 'description';


    isInvalid(): boolean {
        return this.controlsModelRef.some(input => input.invalid) || false;
    }
}
