/**
 * Created by roman.kupin on 2/15/2017.
 */



angular.module('flipto.components.common.loadingAnimation', ['flipto.components.common.spinner'])
    .directive('ftLoadingAnimation', ['$compile', '$animate', "_", function ($compile, $animate, _) {

        var template = '<span class="ft-loading-animation-{{theme}}">{{message}} <ft-spinner></ft-spinner></span>';
        var templateBlock = '<div class="ft-loading-animation-{{theme}}">{{message}} <ft-spinner></ft-spinner></div>';

        return {
            restrict: 'A',
            link: function (scope, elem, attrs) {

                var options = angular.extend({
                    disableUi: true,
                    theme: "dark"
                }, scope.$eval(attrs.ftLoadingAnimation)),
                    lastChild = elem[0].childNodes && elem[0].childNodes[elem[0].childNodes.length - 1];

                if (!options.theme || !_.find(["light", "dark"], function (theme) { return theme === options.theme; })) options.theme = "dark";

                var loadingAnimation = $compile(options.display && options.display === 'block' ? templateBlock : template)(angular.extend(scope.$new(true), options));

                scope.$watch(function () {
                    return scope.$eval(attrs.ftLoadingAnimation).trigger;
                }, function (show) {

                    $animate[show ? 'enter' : 'leave'](loadingAnimation, elem, lastChild);
                    elem.css('pointer-events', options.disableUi && show ? 'none' : '');
                    options.disableUi && show && elem.attr('disabled', 'disabled');
                    options.disableUi && !show && elem.removeAttr('disabled');
                });

            }
        };
    }]);