import { Component, OnInit } from '@angular/core';
import { CreatePropertyDto } from '../../models/create-property.dto';
import { PropertyType } from '../../../../../core/authentication/models/user';
import { Store } from '@ngrx/store';
import { ManagementState } from '../../store/management/management.state';
import {
    createProperty,
    createPropertyFailure,
    createPropertySuccess
} from '../../store/management/management.actions';
import { Actions, ofType } from '@ngrx/effects';

@Component({
    selector: 'ft-create-property',
    templateUrl: './create-property.component.html',
    styleUrls: ['./create-property.component.scss']
})
export class CreatePropertyComponent implements OnInit {
    propertyTypes = [
        {
            label: 'Full service hotel',
            value: PropertyType.FullServiceHotel
        },
        {
            label: 'Select service hotel',
            value: PropertyType.SelectServiceHotel
        },
        {
            label: 'Condotel',
            value: PropertyType.Condotel
        },
        {
            label: 'Vacation rental',
            value: PropertyType.VacationRental
        },
        {
            label: 'Restaurant',
            value: PropertyType.Restaurant
        },
        {
            label: 'Golf',
            value: PropertyType.Golf
        },
        {
            label: 'RV Resort',
            value: PropertyType.RVResort
        },
        {
            label: 'DMO',
            value: PropertyType.DMO
        },
        {
            label: 'Cruise Ship',
            value: PropertyType.CruiseShip
        }
    ];

    success$ = this.actions$.pipe(ofType(createPropertySuccess));
    error$ = this.actions$.pipe(ofType(createPropertyFailure));


    constructor(private store$: Store<ManagementState>,
                private actions$: Actions) {
    }

    ngOnInit(): void {
    }

    onSave(data: CreatePropertyDto) {
        this.store$.dispatch(createProperty({ data }));
    }

}
