/**
 * Created by ostapenko.r on 10/21/2014.
 */


angular.module('flipto.components.fieldEditors')
    .config(['$provide', '$compileProvider', function ($provide, $compileProvider) {
            $compileProvider.directive('ftDateField', [function () {
                return {
                    restrict: 'E',
                    scope: {
                        'datasource': '=',
                        'title': '@'
                    },
                    templateUrl: '/app/components/field-editors/dateField.html',
                    link: function (scope, elem, attrs) {
                        scope.fields = adjustFields(scope.$eval(attrs.fields) || []);

                        scope.resetDate = function(field){
                            scope.datasource[field.name] = scope.datasource[field.name] ? '' : moment();
                        };

                        /**
                         * Returns fields array as objects
                         * @param fields
                         * @returns {Array}
                         */
                        function adjustFields(fields) {
                            return _.map(fields, function (field) {
                                if (angular.isString(field) == true) {
                                    return {name: field, label: null};
                                }
                                return field;
                            });
                        }
                    }
                };
            }]);

    }]);