<ft-modal-wrapper>
  <ft-touchpoint-header
    [name]="options.touchpointTitle"
    [canActivate]="true"
    [isActive]="(selectedFollowupEmailComponent$ | async)?.isActive == 1"
    (activeToggled)="commit($event)"
    >
  </ft-touchpoint-header>
  <mat-tab-group
    class="mat-tab-group-nav"
    mat-align-tabs="center"
    animationDuration="0ms"
    [(selectedIndex)]="selectedIndex">
    <mat-tab label="messaging">
      <ng-template matTabContent>
        <div *ngIf="isRemider()" class="ft2-container ft2-bg-striped py-6 -full">
          <p class="ft2-container -narrow">
            Send a reminder email to guests who received the Invitation Email but did not click through to the Post-stay Process. <strong>Pro tip:</strong> 
            A guest will never receive more than one followup email for Post-stay Advocacy (reminder or re-engagement) so you can be sure they won’t feel spammed.
          </p>
        </div>

        <ft-snapshot-form
          *ngIf="selectedFollowupEmailComponent$ | async | clone as data"
          [loading$]="selectedFollowupEmailLoading$"
          [commitSuccess$]="commitSuccess$"
          (commit)="onFormCommit($event, 'data')">
          <section class="ft2-section">
            <div class="ft2-preview">
              <div class="preview-chrome">
                <span>Inbox</span>
              </div>
              <div class="preview-content">
                <form ngForm>
                  <span class="muted-text">Subject: </span>
                  <ft-input-editor
                    title="Subject"
                    [(ngModel)]="data.languages.item[0].data.emailSubject || data.languages.item[0].data.nonViewEmailSubject"
                    required
                    [name]="isRemider() ? 'nonViewEmailSubject' : 'emailSubject'">
                    <span [innerHTML]="data.languages.item[0].data.emailSubject || data.languages.item[0].data.nonViewEmailSubject"></span>
                  </ft-input-editor>
                  <hr>
                  <ft-html-editor
                    title="Body"
                    [tokens]="[
                      {
                        name: 'first',
                        value: '{first}'
                      },
                      {
                        name: 'last',
                        value: '{last}'
                      },
                      {
                        name: 'property',
                        value: '{property}'
                      },
                      {
                        name: 'incentive-short',
                        value: '{incentive-short}'
                      }
                    ]"
                    [name]="isRemider() ? 'nonViewEmailBody' : 'emailBody'"
                    [(ngModel)]="data.languages.item[0].data.emailBody || data.languages.item[0].data.nonViewEmailBody">
                    <div [innerHTML]="data.languages.item[0].data.emailBody || data.languages.item[0].data.nonViewEmailBody"></div>
                  </ft-html-editor>
                  <ft-input-editor
                    *ngIf="data.languages.item[0].data.emailFooter || data.languages.item[0].data.nonViewEmailFooter"
                    title="Footer"
                    [(ngModel)]="data.languages.item[0].data.emailFooter || data.languages.item[0].data.nonViewEmailFooter"
                    required
                    [name]="isRemider() ? 'nonViewEmailFooter' : 'emailFooter'">
                    <span [innerHTML]="data.languages.item[0].data.emailFooter || data.languages.item[0].data.nonViewEmailFooter"></span>
                  </ft-input-editor>
                  <div class="muted-text">
                    {{'{property}'}}
                  </div>
                </form>
              </div>
              <div class="preview-options">
                <a class="launch-preview">
                  Email sample
                </a>
              </div>
            </div>
          </section>
        </ft-snapshot-form>
      </ng-template>
    </mat-tab>
    <mat-tab label="styling">
      <ng-template matTabContent>
        <ft-snapshot-form
          *ngIf="stylingFollowupEmailComponent$ | async | clone as styleData"
          [loading$]="selectedFollowupEmailLoading$"
          [commitSuccess$]="commitSuccess$"
          (commit)="onFormCommit($event, 'styling')">
          <section class="ft2-section">
            <div class="ft2-container">
              <div class="row">
                <div class="col-12">
                  <form ngForm>
                    <ft-select-editor
                      title="Email template"
                      required
                      [placeholder]="'Select template'"
                      [name]="isRemider() ? 'nonViewEmailtemplateUUID' : 'templateUUID'"
                      [ngModel]="selectedTemplate(styleData)"
                      [options]="styleData.templates ? styleData.templates.item : styleData.nonViewEmailTemplates.item"
                      [bindLabel]="'name'"
                      [bindValue]="'id'">
                        <ft-edit-panel label="Email template">
                          {{selectedTemplate(styleData) + '(Default)'}}
                        </ft-edit-panel>
                    </ft-select-editor>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </ft-snapshot-form>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</ft-modal-wrapper>
