<ft-modal-wrapper>
  <ft-touchpoint-header
    name="Confirmation Experience"
    [canActivate]="true"
    [isActive]="(selectedConfirmationExperienceComponent$ | async)?.isActive == 1"
    (activeToggled)="commit($event)"
    >
  </ft-touchpoint-header>
  <mat-tab-group
    class="mat-tab-group-nav"
    mat-align-tabs="center"
    animationDuration="0ms"
    [(selectedIndex)]="selectedIndex">
    <mat-tab label="messaging">
      <ng-template matTabContent>
        <ft-snapshot-form
          *ngIf="selectedConfirmationExperienceComponent$ | async | clone as data"
          [loading$]="selectedConfirmationExperienceLoading$"
          [commitSuccess$]="commitSuccess$"
          (commit)="onFormCommit($event, 'data')">
          <section class="ft2-section">
            <div class="ft2-container">
              <div class="ft2-browser py-4 -dark"></div>
              <div class="ft2-container ft2-edit-panel f-column p-6 -static mb-0">
                <form ngForm >
                  <div class="h-between">
                    <ng-container>
                      <ft-input-editor
                        title="Confirmation message"
                        [(ngModel)]="data.languages?.item[0].data.topReservationComplete"
                        required
                        name="topReservationComplete">
                          <p class="ft2-editor-content">{{data.languages?.item[0].data.topReservationComplete}}</p>
                      </ft-input-editor>
                      <ft-html-editor
                        title="Confirmation link"
                        name="topHideConfirmation"
                        [(ngModel)]="data.languages?.item[0].data.topHideConfirmation">
                        <div class="ft2-editor-content" [innerHTML]="data.languages?.item[0].data.topHideConfirmation"></div>
                      </ft-html-editor>
                    </ng-container>
                  </div>
                  <div class="ft2-edit-panel f-column -static">
                    <div>
                      <ft-html-editor
                        title="Share Message"
                        [fields]="[
                          {name: 'header', label: 'Default header'},
                          {name: 'homepageHeader', label: 'Message that appears after Homepage signup'}
                        ]"
                        name="header"
                        [tokens]="sharedMessageTokens"
                        [(ngModel)]="data?.languages?.item[0].data"
                        class="col-12">
                        <div class="ft2-editor-content" [innerHTML]="data?.languages?.item[0].data.header"></div>
                      </ft-html-editor>
                    </div>
                    <div class="f-row">
                      <div class="ft2-edit-panel flex-auto -read-only">
                        <p class="mb-0 v-center">{{'{' + 'advocate-share-message' + '}'}}</p>
                      </div>
                      <div class="mr-8 mt-2">
                        <ul class="text-gray-500">
                            <li>
                              <mat-icon class="icon-md" svgIcon="icon_cards_social_facebook"></mat-icon> Share on Facebook
                            </li>
                            <li class="mt-1">
                              <mat-icon class="icon-md" svgIcon="icon_cards_social_twitter"></mat-icon> Post on X
                            </li>
                            <li class="mt-1">
                              <mat-icon class="icon-md" svgIcon="icon_cards_social_linkedin"></mat-icon> Post on LinkedIn
                            </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="preview-options ft2-confirmation-experience-preview">
                <a
                  target="_blank"
                  (click)="openConfirmationLivePreview(data?.languages?.item[0].code, data?.languages?.item[0].fullCode)"
                  class="launch-preview">
                    Live Preview URL
                </a>
              </div>
            </div>
          </section>
        </ft-snapshot-form>
      </ng-template>
    </mat-tab>
    <mat-tab label="styling">
      <ng-template matTabContent>
        <ft-snapshot-form
          *ngIf="stylingConfirmationExperienceComponent$ | async | clone as styleData"
          [loading$]="[selectedConfirmationExperienceLoading$]"
          [commitSuccess$]="[commitSuccess$]"
          (commit)="onFormCommit($event, 'styling', styleData?.bookingEngines?.item[0].id)">
          <section class="ft2-section">
            <div class="ft2-container">
              <div class="row">
                <div class="col-12">
                  <form ngForm>
                    <ng-container>
                      <ft-code-editor
                        title="Touchpoint CSS"
                        [(ngModel)]="styleData?.bookingEngines?.item[0].advancedCss"
                        [language]="'css'"
                        name="advancedCss">
                          <ft-edit-panel label="Touchpoint CSS">
                            <p>{{styleData?.bookingEngines?.item[0].advancedCss}}</p>
                          </ft-edit-panel>
                      </ft-code-editor>
                      <ft-code-editor
                        *ngIf="styleData?.bookingEngines?.item[0].confirmationDisplayTypeId !== '3'"
                        title="Parent CSS"
                        [(ngModel)]="styleData?.bookingEngines?.item[0].advancedParentCss"
                        [language]="'css'"
                        name="advancedParentCss">
                          <ft-edit-panel label="Parent CSS">
                            <p>{{styleData?.bookingEngines?.item[0].advancedParentCss}}</p>
                          </ft-edit-panel>
                      </ft-code-editor>
                    </ng-container>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </ft-snapshot-form>
      </ng-template>
    </mat-tab>
    <mat-tab label="integration">
      <ng-template matTabContent>
        <section class="ft2" *ngIf="integrationConfirmationExperienceComponent$ | async | clone as integrationData">
          <form class="ft2-block"
                #form="ngForm"
                ngForm>
              <ft-loading [loading$]="selectedConfirmationExperienceLoading$"></ft-loading>
              <div class="ft2-container">
                <!-- Configuration section -->
                  <div class="row">
                      <div class="col-4">
                          <p class="solid-text medium-text">
                            Configuration
                          </p>
                      </div>
                      <div *ngFor="let engine of integrationData?.bookingEngines.item" class="col-8">
                        <ft-custom-editor title="Booking engine"
                          (onLoad)="engineCodes = getEngineCodes(engine.codes.item)"
                          (save)="saveBookingEngine($event, engine)">
                          <ft-edit-panel label="Booking engine"
                            secondLabel="Property code">
                            {{engine?.name}}
                            <span secondLabel *ngFor="let item of engine?.codes.item">
                              {{item.code}}, 
                            </span>
                          </ft-edit-panel>
                          <div content>
                            <form>
                              <div class="ft2-form-group">
                                <div class="ft2-input -large v-center h-between mb-5">
                                  <ng-select
                                    class="ft2-select -pure -no-border -large"
                                    [clearable]="false"
                                    [searchable]="false"
                                    bindLabel="name"
                                    bindValue="id"
                                    name="type"
                                    [items]="bookingEngines"
                                    [(ngModel)]="engine.type">
                                  </ng-select>
                                  <mat-slide-toggle name="isActive" [(ngModel)]="engine.isActive" #control="ngModel"></mat-slide-toggle>
                                </div>
                                <ft-list-element
                                  name="engineCodes"
                                  [(ngModel)]="engineCodes"
                                  type="text"
                                  placeholder="Add a code"
                                  [allowDuplicates]="false"
                                  [caseSensitive]="true"
                                ></ft-list-element>
                              </div>
                            </form>
                          </div>
                        </ft-custom-editor>

                        <ft-custom-editor title="App mode"
                          (save)="saveAppMode($event, engine)">
                          <ft-edit-panel
                            label="App mode"
                            [secondLabel]="engine?.confirmationDisplayType !== '3' ? 'Selector' : null">
                            {{engine?.confirmationDisplayType | confirmationDisplayType}}
                            <span secondLabel *ngIf="engine?.confirmationDisplayType !== '3'">
                              {{engine.selector}} 
                            </span>
                          </ft-edit-panel>
                          <div content>
                            <ng-select
                              class="ft2-select -large"
                              [clearable]="false"
                              [searchable]="false"
                              bindLabel="name"
                              bindValue="id"
                              name="confirmationDisplayType"
                              [items]="confirmationExperienceDisplayId"
                              [(ngModel)]="engine.confirmationDisplayType">
                            </ng-select>
                            <ng-container *ngIf="engine.confirmationDisplayType === '3'">
                              <div class="ft2-form-group">
                                <label>TIMER</label>
                                <div class="ft2-input -large v-center h-between">
                                  <label>Show timer on intro</label>
                                  <mat-slide-toggle name="isTimerEnabled" [(ngModel)]="engine.isTimerEnabled" #control="ngModel"></mat-slide-toggle>
                                </div>
                                <ng-container *ngIf="engine.isTimerEnabled">
                                  <ng-select
                                    class="ft2-select -large mt-2"
                                    [clearable]="false"
                                    [searchable]="false"
                                    bindLabel="name"
                                    bindValue="id"
                                    name="timerActionType"
                                    [items]="confirmationTimerActionTypes"
                                    [(ngModel)]="engine.timerActionType">
                                  </ng-select>
                                </ng-container>
                              </div>
                              <div class="ft2-form-group">
                                <label>INCENTIVE</label>
                                <div class="ft2-input -large v-center h-between">
                                  <label>Show incentive on intro</label>
                                  <mat-slide-toggle name="isShowIncentiveOnIntro" [(ngModel)]="engine.isShowIncentiveOnIntro" #control="ngModel"></mat-slide-toggle>
                                </div>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="engine.confirmationDisplayType !== '3'">
                              <div class="ft2-form-group">
                                <label>Page location</label>
                                <input
                                  class="ft2-input -large"
                                  type="text"
                                  placeholder="Add DOM selector"
                                  name="selector"
                                  [(ngModel)]="engine.selector">
                              </div>
                              <div class="ft2-form-group">
                                <label>Insertion Type</label>
                                <ng-select
                                  class="ft2-select -large mt-2"
                                  [clearable]="false"
                                  [searchable]="false"
                                  bindLabel="name"
                                  bindValue="id"
                                  name="insertionType"
                                  [items]="insertionType"
                                  [(ngModel)]="engine.insertionType">
                                </ng-select>
                              </div>
                              <div class="ft2-form-group">
                                <label>Minimized View</label>
                                <div class="ft2-input -large v-center h-between">
                                  <label>Desktop</label>
                                  <mat-slide-toggle name="isDesktopReopenerActive" [(ngModel)]="engine.isDesktopReopenerActive" #control="ngModel"></mat-slide-toggle>
                                </div>
                                <div class="ft2-input -large v-center h-between">
                                  <label>Mobile</label>
                                  <mat-slide-toggle name="isMobileReopenerActive" [(ngModel)]="engine.isMobileReopenerActive" #control="ngModel"></mat-slide-toggle>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </ft-custom-editor>
                      </div>
                  </div>
                  <hr class="mb-6">
                  <div class="row">
                      <div class="col-4">
                          <p class="solid-text medium-text">
                            Advanced
                          </p>
                      </div>
                      <div class="col-8">
                        <ft-edit-panel label="Flip.to account code" [readOnly]="true">
                          <p>{{integrationData.companyCode}}</p>
                        </ft-edit-panel>

                        <ft-list-editor *ngIf="{rateCodes: rateCodes$ | async} as data"
                          title="Excluded reservation types"
                          summary="Certain reservation types can be excluded from being shown the Confirmation Experience based on room type, rate code or hotel code."
                          name="rateCodes"
                          [(ngModel)]="data.rateCodes"
                          type="text"
                          placeholder="Add a code"
                          [allowDuplicates]="false"
                          [caseSensitive]="true"
                          (save)="updateRateCodes($event)">
                          <ft-edit-panel label="Excluded reservation types">
                            <ng-container *ngIf="data.rateCodes?.length !== 0">
                              <span *ngFor="let code of data.rateCodes">{{code}} ,</span>
                            </ng-container>
                            <span *ngIf="data.rateCodes?.length === 0">
                              No reservation types are excluded.
                            </span>
                          </ft-edit-panel>
                        </ft-list-editor>
                      </div>
                  </div>
            </div>
          </form>
        </section>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</ft-modal-wrapper>
