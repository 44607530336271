import { Injectable, Inject, forwardRef, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URL_FORMATTER_TOKEN } from '../../app.module';
import { SpacesService } from 'account-hybrid/common/components/spaces';
import { Environment, ENVIRONMENT_TOKEN } from 'account-hybrid/core/environment.service';

@Injectable({
  providedIn: 'root'
})
export class ShortUrlService {

  constructor(private http: HttpClient,
    @Inject(forwardRef(() => URL_FORMATTER_TOKEN)) private urlFormatter,
    private spacesService: SpacesService,
    @Inject(ENVIRONMENT_TOKEN) private environment: Environment) { }

  getAllShortUrls(propertyUuid: string, themeId: number, contestUuid: string = null): Observable<ShortUrl[]> {
    return this.http.get<ShortUrl[]>(
      this.urlFormatter.format(
        `${this.environment.apiBaseUrl}/${propertyUuid}/short-urls`,
        {
          propertyUuid,
          themeId,
          contestUuid
        },
        true
      )
    );
  }

  createShortUrl(
    companyCode: string,
    propertyUuid: string,
    destinationUrl: string,
    displayName: string,
    shortCode: string,
    contestUuid?: string,
    themeId?: number
  ) {
    return this.http.put(`${this.environment.apiBaseUrl}/${propertyUuid}/short-urls`, {
      companyCode,
      propertyUuid,
      destinationUrl,
      displayName,
      shortCode,
      themeId,
      ...{ contestUuid: !themeId && !!contestUuid ? contestUuid : null }
    });
  }

  validateShortCode(code: string) {
    return this.http.get(
      this.urlFormatter.format(`${this.environment.apiBaseUrl}/${this.spacesService.getPropertyUuid()}/short-urls/:code`, { code }, false)
    );
  }

  validateDisplayName(propertyUUID: string, displayName: string, contestUUID: string = null, themeId: number = null) {
    return this.http.get(
      this.urlFormatter.format(
        `${this.environment.apiBaseUrl}/${propertyUUID}/short-urls/display-name/is-exists`,
        {
          propertyUUID,
          displayName,
          contestUUID,
          themeId
        },
        true
      )
    );
  }

}


export interface ShortUrl {
  companyCode: string;
  propertyUUID: string;
  destinationUrl: string;
  contestUUID: string;
  displayName: string;
  themeId: number;
  shortCode: string;
  createDate: string;
}
