<div class="container">
    <div class="ft-row">
        <div class="ft-col-60">
            All
        </div>
        <div class="ft-col-40">
            <input type="checkbox" [disabled]="keys.length < 2" [checked]="allValuesTrue()" (change)="toggleAll()">
        </div>
    </div>
    <hr>
    <div class="ft-row" *ngFor="let key of keys; index as i">
        <div class="ft-col-60">
            {{key}}
        </div>
        <div class="ft-col-40">
            <input type="checkbox" [disabled]="keys.length < 2" [checked]="values[i]" (change)="onChange(i)">
        </div>
    </div>
</div>