import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import { Language } from 'account-hybrid/common/models/language.model';
import { sortDefaultAccountLanguages } from '../../../../utils/account-langauge.util';
import { EditorLanguageVariation } from '../../models/editor-language-variation.model';

@Component({
    selector: 'ft-language-variations',
    templateUrl: 'language-variations.component.html',
    styleUrls: ['language-variations.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageVariationsComponent implements OnChanges {
    @Input() value: any;
    @Input() fieldName: string;
    @Input() languages: Language[] = [];
    @Output() activeVariationsChange = new EventEmitter<EditorLanguageVariation[]>();

    activeVariations: EditorLanguageVariation[];

    constructor(private cdr: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('languages') || changes.hasOwnProperty('value')) {
            this.activeVariations = [
                ...this.languages.map(language => ({
                    ...language,
                    isVariationActive: language.languageCode === 'en' || this.value?.find(value => value?.languageCode === language.languageCode)?.[this.fieldName]?.length > 0
                }))
            ];
            this.activeVariationsChange.emit(this.activeVariations);
        }
    }

    get availableLanguageVariations() {
        return sortDefaultAccountLanguages((this.languages || [])
            ?.filter(language => language.languageCode !== 'en'
                && !!this.activeVariations?.find(variation => variation.languageCode === language.languageCode && !variation.isVariationActive)));
    }

    addLanguageVariation(language: Language) {
        const foundEntry = this.value?.find(value => value?.languageCode === language.languageCode);
        const foundVariation = this.activeVariations?.find(variation => variation.languageCode === language.languageCode);

        if (foundVariation) {
            foundVariation.isVariationActive = true;
            this.activeVariationsChange.emit(this.activeVariations);
        }

        // if item already exist
        if (foundEntry) {
            foundEntry[this.fieldName] = '';
            return;
        }

        // if no language entry exist
        const clonedEntry = {
            ...this.value?.find(item => !!item)
        };
        Object.entries(clonedEntry).forEach(([key, value]) => {
            clonedEntry[key] = null;
            clonedEntry[this.fieldName] = null;

            if (key === 'languageName') {
                clonedEntry[key] = language.languageName;
            }

            if (key === 'languageCode') {
                clonedEntry[key] = language.languageCode;
            }
        });
        this.value.push(clonedEntry);
    }

    removeVariation(languageCode: string) {
        const foundVariation = this.activeVariations?.find(variation => variation.languageCode === languageCode);
        if (foundVariation) {
            foundVariation.isVariationActive = false;
            this.activeVariationsChange.emit(this.activeVariations);
        }
    }
}
