

export const NAME = "ftContentPreview";

export const VIEWPORT_BY_SOCIAL_NETWORK = {
    Facebook: { width: 1200, height: 630 },
    Twitter: { width: 1200, height: 630 },
    LinkedIn: { width: 1200, height: 627 }
};

export const DEFINITION = [function () {
    return {
        restrict: "E",
        scope: {
            content: "<"
        },
        link: function (scope, elem) {

            const viewport = VIEWPORT_BY_SOCIAL_NETWORK.Facebook;
            let currentContent;

            scope.$watch("content", (content) => {
                if (currentContent == content) return;
                currentContent = content;
                if (!!content) {
                    const iframe = document.createElement("iframe");
                    iframe.style.width = viewport.width + "px";
                    iframe.style.height = viewport.height + "px";
                    iframe.onload = () => {
                        iframe.contentWindow.document.open();
                        iframe.contentWindow.document.write(content);
                        iframe.contentWindow.document.close();
                    }

                    elem.empty();
                    elem[0].appendChild(iframe);
                }
            });

        }
    };
}];
