<ng-content>
</ng-content>
<ng-template #dialogTmpl>
    <button *ngIf="!hideCloseBtn" class="ft2-btn-close -fixed" (click)="onClose()"></button>
    <div class="ft2-confirm-modal v-center h-center text-center">
        <div>
            <h1 class="h4">{{ title }}</h1>
            <p class="mt-3 mb-5">{{ description }}</p>
            <div>
                <button class="ft2-btn-light -large -outline px-5 mx-2" (click)="onCancel()">
                    {{cancelLabel}}
                </button>
                <button class="ft2-btn-primary -large px-5 mx-2" (click)="onConfirm()">
                    {{confirmLabel}}
                </button>
            </div>
        </div>
    </div>
</ng-template>
