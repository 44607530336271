/**
 * User: Kupin.R
 * Date: 6/2/14
 */



angular
    .module("flipto.components.goals")
    .factory("updateGoalsSerializer",
        [
            "_", "goalDescriptor", "goalType",
            function (_, goalDescriptor, goalType) {

                function serializeGoalsModel(model, originalGoals) {
                    var serialized = {
                        newGoals: createNewGoalsArray(model),
                        updateGoals: createUpdateGoalsArray(model, originalGoals),
                        deleteGoals: createDeletedGoalsArray(model)
                    };

                    return serialized;
                }

                function createDeletedGoalsArray(model) {
                    return model.goals.deleteGoals;
                }

                function createNewGoalsArray(model) {
                    return  _.map(model.goals.newGoals, function (goal) {
                        return createGoalModel(goal, model.type);
                    });
                }

                function createUpdateGoalsArray(model, originalGoals) {
                    var goalsThatRequireUpdate = _.filter(model.goals.updateGoals, function (goal) {
                        var originalGoal = createGoalModel(_.find(originalGoals, function (g) {
                            return g.id == goal.id;
                        }), model.type);
                        var currentGoal = createGoalModel(goal, model.type);
                        return !angular.equals(originalGoal, currentGoal);
                    });
                    return _.map(goalsThatRequireUpdate, function (goal) {
                        return createGoalModel(goal, model.type);
                    });
                }

                function createGoalModel(goal, type) {
                    var model = {
                        incentiveUUID: goal.incentiveUUID,
                        isActive: getIsActiveOrDefault(goal),
                        type: type
                    };
                    if (goal.id) {
                        model.uuid = goal.id;
                    }
                    addDataForAdvocates(model, goal);

                    return model;
                }

                function addDataForAdvocates(model, goal) {
                    if (model.type == 1) {
                        var descriptor = goalDescriptor(goal);
                        if (descriptor.type === goalType.GetUniqueVisitors) {
                            model["uniqueVisitors"] = descriptor.value;
                        } else if (descriptor.type === goalType.GetSocialConnections) {
                            model["signups"] = descriptor.value;
                        } else {
                            model["uniqueVisitors"] = 0;
                            model["signups"] = 0;
                        }
                    }
                }

                function getIsActiveOrDefault(goal) {
                    if (goal.isActive == 1) return true;
                    return false;
                }

                return {
                    serialize: serializeGoalsModel,
                    createGoalModel: createGoalModel
                };
            }
        ]);
