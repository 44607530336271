/**
 * Created by Roman on 2014-09-24.
 */


angular.module('flipto.core.DeepDiff', [])
    .config(['$provide', function ($provide) {
        $provide.factory('DeepDiff', ['$window', function ($window) {
            return $window.DeepDiff;
        }]);
    }]);