/**
 * Created by Roman on 2016-10-10.
 */

angular.module('flipto.accountv1.SharedFeatures')
    .controller('flipto.account.sections.platform.EmailsDataController', [
        '$scope', '$q', 'Property', 'propertyUuid', 'parse', '_', '$sce', 'livePreview', 'notifications', '$filter', 'emailTypes',
        function ($scope, $q, Property, propertyUuid, parse, _, $sce, livePreview, notifications, $filter, emailTypes) {

            var vm = this;
            vm.model = {};
            vm.emailTypes = emailTypes;
            vm.$sce = $sce;
            vm.tokensPerType = {
                11: ['visitor-firstname', 'guest-firstname','property-city','property-name','property-shortname','link-to-guest-photo','link-to-gallery'],
                7: ['friend-firstname', 'guest-firstname', 'property-city', 'property-name', 'property-shortname', 'link-to-guest-photo', 'link-to-gallery'],
                1: ['friend-firstname', 'guest-firstname', 'property-city', 'property-name', 'property-shortname', 'link-to-gallery', 'contest-content'],
                3: ['friend-firstname', 'guest-firstname', 'property-city', 'property-name', 'property-shortname', 'link-to-gallery', 'contest-content'],
                13: ['guest-firstname', 'property-city', 'property-name', 'property-shortname', 'link-to-gallery', 'contest-content'],
                22: ['guest-firstname', 'property-city', 'property-name', 'property-shortname', 'link-to-gallery', 'contest-content'],
                12: ['visitor-firstname', 'guest-firstname', 'property-city', 'property-name', 'property-shortname', 'link-to-guest-photo', 'link-to-gallery'],
                8: ['friend-firstname', 'guest-firstname', 'property-city', 'property-name', 'property-shortname', 'link-to-guest-photo', 'link-to-gallery'],
                2: ['friend-firstname', 'guest-firstname', 'property-city', 'property-name', 'property-shortname', 'link-to-gallery', 'contest-content'],
                4: ['friend-firstname', 'guest-firstname', 'property-city', 'property-name', 'property-shortname', 'link-to-gallery', 'contest-content'],
                23: ['guest-firstname', 'property-city', 'property-name', 'property-shortname', 'link-to-gallery', 'contest-content'],
                36: ['traveler-firstname', 'property-city', 'property-name', 'property-shortname', 'link-to-gallery', 'contest-content'],
                37: ['traveler-firstname', 'property-city', 'property-name', 'property-shortname', 'link-to-gallery', 'contest-content'],
                80: ['guest-firstname', 'property-city', 'property-name', 'property-shortname', 'link-to-gallery', 'contest-content','incentive-custom-code'],
                81: ['guest-firstname', 'property-city', 'property-name', 'property-shortname', 'link-to-gallery', 'contest-content','incentive-custom-code']
            };

            var updates = [],
                translate = $filter('translate'),
                snapshotProxy;

            /**
             * Select email data by email type
             * @param {} association
             * @returns {}
             */
            vm.selectByEmailType = function (association) {
                vm.selectedEmailData = _.find(vm.model.emailsData, function (e) {
                    return e.emailType === association
                });
            };

            /**
             * Save emails data changes
             * @param changes
             */
            vm.saveChanges = function (changes) {
                var updates = getUpdates(changes);
                return Property.saveEmailsData(propertyUuid, updates);
            };

            /**
             * Get property emails data
             * @returns {*}
             */
            vm.getPropertyEmailData = function () {
                return Property.getEmailsData(propertyUuid);
            };

            /**
             * Send test email
             */
            vm.sendTestEmail = function () {
                livePreview.sendTestEmail({
                        propertyUuid: propertyUuid,
                        emailType: vm.selectedEmailData.emailType,
                        languageCode: vm.model.selectedLanguage.code
                    })
                    .then(showEmailSendingSuccess, showEmailSendingError);
            };

            vm.showEmailHtml = function () {
                livePreview.showTestEmail({
                    propertyUuid: propertyUuid,
                    emailType: vm.selectedEmailData.emailType,
                    languageCode: vm.model.selectedLanguage.code
                })
            };

            /**
             * setup snapshot proxy
             * @param proxy
             */
            vm.setupSnaphotProxy = function (proxy) {
                snapshotProxy = proxy;
            };

            /**
             * Returns array of updates
             * @returns {Array}
             */
            vm.getUpdates = function () {
                return updates;
            };

            function showEmailSendingSuccess() {
                notifications.show(translate('flipto.account.SendSampleEmailSuccess'));
            }

            function showEmailSendingError() {
                notifications.show(translate('flipto.account.SendSampleEmailError'));
            }

            /**
             * Convert deepdiff changes to update objects
             * @param changes
             * @returns {Array}
             */
            function getUpdates(changes) {
                var updatedData = _.map(changes, function (c) {
                    var chngObj = vm.model.emailsData[c.path[3]];
                    return angular.extend({}, {emailType: chngObj.emailType}, chngObj.languages[c.path[5]]);
                });
                /*remove dupes*/
                updatedData = _.reduce(updatedData, function (result, value) {
                    var isThere = !!_.find(result, function (r) {
                        return r.emailType === value.emailType &&
                            r.code === value.code;
                    });
                    if (!isThere) result.push(value);
                    return result;
                }, []);

                /*tune model*/
                updatedData = _.map(updatedData, function (d) {
                    return {
                        emailType: d.emailType,
                        languageCode: d.code,
                        subject: d.subject,
                        body: d.body,
                        footer: d.footer,
                        contestContent: d.contest_content
                    };
                });

                return updatedData;
            }

            $scope.$watchCollection(function () {
                if (!snapshotProxy) return [];
                return snapshotProxy.getTracker().pendingChanges;
            }, function (changes) {
                updates = getUpdates(changes);
            }, true);

            vm.getPropertyEmailData()
                .then(function (data) {
                    vm.model.emailsData = data;
                    vm.model.languages = _.map(vm.model.emailsData[0].languages, function (l) {
                        return {code: l.code, name: l.name};
                    });
                    vm.selectByEmailType(vm.emailTypes[Object.keys(vm.emailTypes)[0]]);
                    //sort by en first
                    _.each(vm.model.emailsData, function (data) {
                        data.languages = _.sortBy(data.languages, function (lang) {
                            return lang.code === 'en' ? '0' : lang.code;
                        });
                    });

                });

        }]);
