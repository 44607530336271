import { FollowupEmailSettings, FollowupEmailStylingSettings } from './../../models/followup-email';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { JourneysApiService } from '../../services/journeys-api.service';
import {
    commitFollowupEmail,
    commitFollowupEmailFail,
    commitFollowupEmailSuccess,
    loadFollowupEmail,
    loadFollowupEmailFail,
    loadFollowupEmailSuccess,
    loadStylingFollowupEmail,
    loadStylingFollowupEmailFail,
    loadStylingFollowupEmailSuccess,
    saveFollowupEmail,
    saveFollowupEmailFail,
    saveFollowupEmailSuccess
} from './actions';

@Injectable()
export class FollowupEmailEffects {
    constructor(private actions$: Actions,
        private store$: Store<any>,
        private journeysApiService: JourneysApiService) {
    }

    load$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadFollowupEmail),
            mergeMap(({componentGroupUuid, propertyId, typeRequest, state}) =>
                this.journeysApiService.getTouchpointFollowupEmailData(componentGroupUuid, propertyId, typeRequest, state)
                    .pipe(
                        map((settings: FollowupEmailSettings) => {
                            return loadFollowupEmailSuccess({data: settings});
                        }),
                        catchError(error => of(loadFollowupEmailFail({error})))
                    ))
        )
    );

    commit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(commitFollowupEmail),
            mergeMap(({componentGroupUuid, propertyId, isActive}) =>
                this.journeysApiService.commitTouchpointFollowupEmail(componentGroupUuid, propertyId, isActive)
                    .pipe(
                        map((settings: FollowupEmailSettings) => {
                            return commitFollowupEmailSuccess({data: settings});
                        }),
                        catchError(error => of(commitFollowupEmailFail({error})))
                    ))
        )
    );

    loadStyling$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadStylingFollowupEmail),
            mergeMap(({componentGroupUuid, propertyId, typeRequest, state}) =>
                this.journeysApiService.getStylingFollowupEmailData(componentGroupUuid, propertyId, typeRequest, state)
                    .pipe(
                        map((stylingSettings: FollowupEmailStylingSettings) => {
                            return loadStylingFollowupEmailSuccess({data: stylingSettings});
                        }),
                        catchError(error => of(loadStylingFollowupEmailFail({error})))
                    ))
        )
    );

    saveChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(saveFollowupEmail),
            withLatestFrom(this.store$),
            mergeMap(
                ([{ componentGroupUuid, propertyId, typeRequest, changes, tab }]) => this.journeysApiService.saveTouchpointFollowupEmail(
                    componentGroupUuid, propertyId, typeRequest, changes, tab
                ).pipe(map(() => {
                    return saveFollowupEmailSuccess();
                }),
                    catchError(error => {
                        return of(saveFollowupEmailFail(error));
                    })
                )
            )
        )
    );
}
