<ft-modal-editor-wrapper>
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
    <ft-daterange-picker [from]="value?.fromDate"
                         [to]="value?.toDate"
                         [pastDays]="pastDays"
                         [rangeLimit]="rangeLimit"
                         [futureDisabled]="futureDisabled"
                         [pastDisabled]="pastDisabled"
                         [fullWidth]="true"
                         (changed)="onDateRangeChanged($event)">
        <ng-template #valueTmpl>
            <div class="ft2-input-group full-width">
                <input class="ft2-input -large"
                       [value]="(value.fromDate | date: 'longDate') + ' - ' + (value.toDate | date: 'longDate')"
                       [placeholder]="placeholder"
                       [required]="required"
                       [name]="controlName"
                       readonly
                       type="text">
                <div class="ft2-input-group_append">
                    <button class="ft2-btn-primary -large" type="button">
                        <mat-icon class="icon-lg">date_range</mat-icon>
                    </button>
                </div>
            </div>
        </ng-template>
        <ng-template #placeholderTmpl>
            <div class="ft2-input-group full-width">
                <input class="ft2-input -large" [placeholder]="placeholder" readonly>
                <div class="ft2-input-group_append">
                    <button class="ft2-btn-primary" type="button">
                        <mat-icon class="icon-lg">date_range</mat-icon>
                    </button>
                </div>
            </div>
        </ng-template>
    </ft-daterange-picker>
</ft-modal-editor-wrapper>
