/**
 * Created by Roman on 2015-08-20.
 */

angular.module('flipto.core.logFormatters', [])
    .provider('apiEntriesLogFormatter', [function () {

        var formatter = {
            supports: ['api'],
            format: function (log, dump) {
                angular.forEach(log, function (item) {
                    var tmp = [],
                        request = item.entry.request,
                        response = item.entry.response;

                    tmp.push('/');
                    tmp.push(request.method);
                    tmp.push(' ');
                    tmp.push(request.url);
                    tmp.push(' ');
                    tmp.push('token: ');
                    tmp.push(request.headers['X-Flipto-Token']);
                    tmp.push(' ');
                    tmp.push('property: ');
                    tmp.push(request.headers['X-Flipto-Property']);
                    tmp.push(' ');
                    tmp.push('data: ');
                    tmp.push(JSON.stringify(request.data));
                    tmp.push(' - ');
                    tmp.push('status: ');
                    tmp.push(response.status);
                    tmp.push(' ');
                    tmp.push(response.statusText);
                    tmp.push(' ');
                    tmp.push('data: ');
                    tmp.push(JSON.stringify(response.data));
                    tmp.push(' ');

                    dump.push(Array.prototype.join.call(tmp, ''));
                    dump.push('\r\n');
                });
            }
        };

        this.$get = [function () {
            return formatter;
        }];

        this.formatter = formatter;

    }]);
