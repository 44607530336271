<ft-modal-editor-wrapper>
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
    <ng-container *ngIf="!isStructuredLocation">
        <span class="solid-text medium-text">Quick lookup</span>
        <input [(ngModel)]="fullAddress"
               #lookupRef
               class="ft2-input -large mb-4"
               placeholder="Enter the full address">
    </ng-container>
    <span class="solid-text medium-text">
        {{ isManualEntry ? 'Manual entry' : 'Fields' }}
    </span>
    <input *ngIf="value?.hasOwnProperty('name')"
           [(ngModel)]="value.name"
           class="ft2-input -large mb-1"
           placeholder="Venue name">
    <ng-container *ngIf="!isStructuredLocation">
        <input *ngIf="value?.hasOwnProperty('streetAddress')"
               [readonly]="!isManualEntry"
               [(ngModel)]="value.streetAddress"
               class="ft2-input -large mb-1"
               placeholder="Street address">
    </ng-container>
    <input *ngIf="value?.hasOwnProperty('city')"
           [(ngModel)]="value.city"
           [readonly]="!isManualEntry"
           class="ft2-input -large mb-1"
           placeholder="City">
    <input *ngIf="value?.hasOwnProperty('region')"
           [(ngModel)]="value.region"
           [readonly]="!isManualEntry"
           class="ft2-input -large mb-1"
           placeholder="State, province or region">
    <input *ngIf="value?.hasOwnProperty('countryName')"
           [(ngModel)]="value.countryName"
           [readonly]="!isManualEntry"
           name="countryName"
           #inputs="ngModel"
           class="ft2-input -large mb-1"
           placeholder="Country"
           required>
    <ng-container *ngIf="!isStructuredLocation">
        <input *ngIf="value?.hasOwnProperty('postalCode')"
               [(ngModel)]="value.postalCode"
               [readonly]="!isManualEntry"
               class="ft2-input -large mb-4"
               placeholder="Postal code">
    </ng-container>
    <span *ngIf="value?.hasOwnProperty('latitude') && value?.hasOwnProperty('longitude')" class="solid-text medium-text">
        Geo coordinates
    </span>
    <input *ngIf="value?.hasOwnProperty('latitude')"
           [(ngModel)]="value.latitude"
           [readonly]="!isManualEntry"
           name="latitude"
           #inputs="ngModel"
           class="ft2-input -large mb-1"
           placeholder="Latitude"
           required>
    <input *ngIf="value?.hasOwnProperty('longitude')"
           [(ngModel)]="value.longitude"
           [readonly]="!isManualEntry"
           name="longitude"
           #inputs="ngModel"
           class="ft2-input -large mb-4"
           placeholder="Longitude"
           required>
    <ng-container *ngIf="value?.hasOwnProperty('customVenueName')">
        <span class="solid-text medium-text">Custom venue name</span>
        <p class="small-text">This will be displayed only for the experience you are currently editing</p>
        <input [(ngModel)]="value.customVenueName"
               class="ft2-input -large mb-4"
               placeholder="Preferred alternative for venue name (optional)">
    </ng-container>
    <ng-container *ngIf="value?.hasOwnProperty('area') && !isVenue">
        <span class="solid-text medium-text">Custom area name</span>
        <input [(ngModel)]="value.area"
               class="ft2-input -large mb-4"
               placeholder="Preferred alternative for city name (optional)">
    </ng-container>
    <ng-container *ngFor="let input of inputs">
        <div class="ft2-form-error mb-1" *ngIf="input.invalid && input.touched">
            <p *ngIf="input.errors.required">
                {{ input.name }} value is required.
            </p>
        </div>
    </ng-container>
</ft-modal-editor-wrapper>
