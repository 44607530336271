/**
 * Created by roman.kupin on 1/6/2017.
 */



angular.module('flipto.accountv1.SharedFeatures')
    .directive("ftContestInvitationShare", ["ftModal", "parse", "PhotoContest", "fileSaver", "shareOAuth", "$window", "$filter", "$timeout",
        function (ftModal, parse, PhotoContest, fileSaver, shareOAuth, $window, $filter, $timeout) {

        var translate = $filter('translate'),
            defaultShareMessage = translate("flipto.account.ContestInvitationDefaultShareMessage");

        return {
            restrict: "E",
            templateUrl: "/app/sections/platform/layer/photo-advocacy/contest/invitationShareModal.html",
            scope: {
                contestUuid: "=",
                companyCode: "=",
                property: "=",
                contest: "=",
                network: "=",
                invitationBackground: "=",
                propertyBackground: "=",
                invitationUrl: "=",
                languageCode: "="
            },
            link: function (scope) {

                scope.model = {
                    isLoadingTemplate: false
                };

                var defaultMessages = {
                    facebook: defaultShareMessage,
                    twitter: defaultShareMessage,
                    linkedin: defaultShareMessage
                };

                angular.extend(scope, {
                    sharing: {
                        isInvitationBackground: true,
                        message: defaultMessages[scope.network.title]
                    }
                });

                scope.$watch("sharing.isInvitationBackground", function (isInvitationBackground) {
                    if (angular.isDefined(isInvitationBackground)) {
                        scope.sharing.background = isInvitationBackground ?
                            scope.invitationBackground : scope.propertyBackground;
                    }
                }, true);

                scope.createShareImage = function () {
                    var sharing = scope.sharing,
                        network = scope.network;

                    ftModal.hide('invitationShare').then(function () {
                        ftModal.show('invitationShare.createImageProgress');
                        var model = {
                            SocialApplication: parse.int(network.socialNetworkType),
                            /*SelectedPhotoUrl: sharing.background,*/
                            ContestUUID: scope.contestUuid,
                            LanguageCode: scope.languageCode,
                            Template: scope.sharing.template
                        };
                        PhotoContest.createInvitationShareImageFromTemplate(
                            scope.property.UUID,
                            model
                        )
                            .then(function (shareImageUrl) {
                                ftModal.hide('invitationShare.createImageProgress')
                                    .then(function () {
                                        sharing.shareImageUrl = shareImageUrl;
                                        ftModal.show('invitationShare.shareImageCreated');
                                    });
                            }, function () {
                                ftModal.hide('invitationShare.createImageProgress').then(function () {
                                    ftModal.show('invitationShare').then(function () {
                                        if (!!scope.sharing.template.length) {
                                            setPreviewContent(scope.sharing.template);
                                        }
                                        $timeout(function () {
                                            $window.alert(translate('flipto.account.CurationShareError'));
                                        }, 50);
                                    });
                                });
                            });

                    });
                };

                scope.reloadImageTemplate = function () {
                    if (scope.model.isLoadingTemplate) return;
                    var model = {
                        SocialApplication: parse.int(scope.network.socialNetworkType),
                        //SelectedPhotoUrl: scope.sharing.background,
                        ContestUUID: scope.contestUuid,
                        LanguageCode: scope.languageCode
                    };
                    scope.model.isLoadingTemplate = true;
                    PhotoContest.getInvitationShareImageTemplate(
                        scope.property.UUID,
                        model
                    )
                        .then(function (data) {
                            scope.sharing.template = data.template;
                        }, function () {
                            scope.sharing.template = "";
                        })
                        .finally(function () {
                            scope.model.isLoadingTemplate = false;
                                if (!!scope.sharing.template.length) {
                                $timeout(function () {
                                    setPreviewContent(scope.sharing.template);
                                });
                            }
                        });
                };

                scope.downloadShareImage = function (isStayOpen) {
                    PhotoContest.downloadShareImage(scope.property.UUID, scope.sharing.shareImageUrl)
                        .then(function (data) {
                            fileSaver.saveBlob(data.content, data.fileName);
                            if (!isStayOpen) {
                                ftModal.hide('invitationShare.shareImageCreated');
                            }
                        });
                };

                scope.showInvitationShareModal= function () {
                    ftModal.show('invitationShare')
                        .then(function () {
                            setPreviewContent(scope.sharing.template);
                        });
                };

                scope.openOAuth = function () {
                    var sharing = scope.sharing,
                        network = scope.network,
                        companyCode = scope.companyCode;

                    var model = {
                        contestUUID: scope.contestUuid,
                        companyCode: companyCode,
                        languageCode: scope.languageCode,
                        socialApp: network.socialNetworkType,
                        shareMessage: sharing.message,
                        shareImageUrl: sharing.shareImageUrl,
                        invitationUrl: scope.invitationUrl,
                        callback: 'fliptoInvitationShareComplete'
                    };
                        shareOAuth.log('invitation-share', { action: "open", state: model });
                    shareOAuth.tryOpenWindow(network.socialNetworkType);
                    shareOAuth.trySetLocation(network.socialNetworkType, model);
                };

                scope.reloadImageTemplate();

                $window.fliptoInvitationShareComplete = function (state) {
                    shareOAuth.log('invitation-share', { action: "complete", state: state });
                    scope.$apply(function () {
                        if (state == 0 || state == false) {
                            $window.alert(translate('flipto.account.CurationShareError'));
                        } else if (state == 1 || state == true) {
                            $window.alert(translate('flipto.account.ShareSuccess'));
                        }
                        shareOAuth.close();
                        ftModal.hide('invitationShare.shareImageCreated')
                            .then(function () {
                                ftModal.get('invitationShare').cancel();
                            });
                    });
                };

                function setPreviewContent(content) {
                    var iframe = getPreviewIframeElement();
                    if (!iframe || !iframe.contentWindow) return;
                    var viewport = getViewportBySocialNetwork(scope.network.socialNetworkType);
                    iframe.style.width = viewport.width + "px";
                    iframe.style.height = viewport.height + "px";
                    iframe.contentWindow.document.open();
                    iframe.contentWindow.document.write(content);
                    iframe.contentWindow.document.close();
                }

                function getPreviewIframeElement() {
                    var modal = ftModal.get("invitationShare");
                    if (!modal) return null;
                    var modalElement = modal.instance.$element;
                    return modalElement.find("iframe")[0];
                }

                function getViewportBySocialNetwork(socialNetworkType) {
                        if (socialNetworkType == 1) {
                            //Facebook
                            return { width: 1200, height: 630 };
                        } else if (socialNetworkType == 2) {
                            //Twitter
                            return { width: 1200, height: 630 };
                        } else if (socialNetworkType == 3) {
                            //LinkedIn
                            return { width: 1200, height: 627 };

                    }
                }

            }
        };
    }]);