import { Component, OnDestroy } from "@angular/core";
import { Subject } from 'rxjs';

@Component({
    selector: 'ft-unsubscribe',
    template: '<ng-content></ng-content>'
})
export class UnsubscribeComponent implements OnDestroy {
    destroyed$ = new Subject();

    ngOnDestroy() {
        this.destroyed$.next(null)
    }
}
