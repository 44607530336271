import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { NgbDate, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { DateRange } from '../../models/date-range.model';
import dayjs from 'dayjs';

@Component({
    selector: 'ft-simple-daterange-picker',
    templateUrl: './simple-daterange-picker.component.html',
    styleUrls: ['./simple-daterange-picker.component.scss'],
})
export class SimpleDaterangePickerComponent implements OnChanges, OnInit {
    dateFormat = 'yyyy-MM-dd';
    ngbFromDate: NgbDate;
    ngbToDate: NgbDate;
    @Input('from') fromDate: string | Date;
    @Input('to') toDate: string | Date;
    @Input() fromLabel: string;
    @Input() toLabel: string;
    @Input() divider: boolean;
    @Output() changed = new EventEmitter<DateRange>();
    _dateRange: DateRange;
    @ViewChild('dp') datepicker: NgbDatepicker;

    constructor() {}

    set dateRange(dateRange: DateRange) {
        this._dateRange = dateRange;
        this.fromDate = this.fromDate && typeof this.fromDate === 'string' ? dateRange.fromDate?.toDateString() : dateRange.fromDate;
        this.toDate = this.toDate && typeof this.toDate === 'string' ? dateRange.toDate?.toDateString() : dateRange.toDate;
    }

    get dateRange(): DateRange {
        return this._dateRange;
    }

    ngOnInit() {
        this.initDates();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('fromDate') || changes.hasOwnProperty('toDate')) {
            // let's sure that date really changed, and not just format
            if (this.isDateChange(this.dateRange?.fromDate, changes.fromDate?.currentValue) || (this.isDateChange(this.dateRange?.toDate, changes.toDate?.currentValue))) {
                this.initDates();
            }
        }
    }

    isDateChange(date1: Date | string, date2: Date | string): boolean {
        return !dayjs(date1).isSame(date2);
    }

    initDates() {
        this.ngbFromDate = this.dateToNgbDate(this.fromDate);
        this.ngbToDate = this.dateToNgbDate(this.toDate);
        this.onRangeSelected();
    }

    onRangeSelected() {
        const fromDate = this.ngbDateToDate(this.ngbFromDate);
        const toDate = this.ngbDateToDate(this.ngbToDate);
        this.dateRange = new DateRange(fromDate, toDate);
        this.changed.next(this.dateRange);
    }

    dateToNgbDate(date: string | Date) {
        if (!date) {
            return null;
        }
        let year;
        let month;
        let day;
        [year, month, day] = dayjs(date).format('YYYY-MM-DD').split('-');
        return new NgbDate(
            +year,
            +month,
            +day
        );
    }

    ngbDateToDate(date: NgbDate) {
        if (!date) {
            return null;
        }
        const year = date.year;
        const month = ('0' + date.month).slice(-2);
        const day = ('0' + date.day).slice(-2);
        return `${year}-${month}-${day}`;
    }
}
