<div class="ft2">
  <!-- PRE-STAY GOALS -->
  <div *ngIf="layer == 'prestay'">
      <!-- For advocates -->
      <ft-custom-editor
        title="Goals for Advocates"
        (save)="editGoals([1], 1)">
        <ft-edit-panel>
          <div class="f-column text-left">
            <div class="h5 mb-4">Advocates</div>
            <ng-container *ngIf="goals.length !== 0">
              <div *ngFor="let goal of (goals | filter: {type: '1', isActive: '1'}); let last = last">
                <p class="bold-text" [innerHTML]="getGoalName(goal)"></p>
                <p [innerText]="getIncentiveName(goal)"></p>
                <hr *ngIf="!last">
              </div>
            </ng-container>
            <span *ngIf="goals.length === 0">No goals have been set.</span>
          </div>
        </ft-edit-panel>
      </ft-custom-editor>

      <ft-custom-editor
        title="Goals for Warm leads"
        (save)="editGoals([2], 2)">
        <ft-edit-panel>
          <div class="f-column text-left">
            <div class="h5">Warm leads</div>
            <ng-container *ngIf="goals.length !== 0">
              <div *ngFor="let goal of (goals | filter: {type: '2', isActive: '1'}); let last = last">
                <p class="bold-text" [innerHTML]="getGoalName(goal)"></p>
                <p [innerText]="getIncentiveName(goal)"></p>
                <hr *ngIf="!last">
              </div>
            </ng-container>
            <span *ngIf="goals.length === 0">No goals have been set.</span>
          </div>
        </ft-edit-panel>
      </ft-custom-editor>
  </div>
</div>
