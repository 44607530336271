import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import { SessionService } from "account-hybrid/core/authentication";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {


    constructor(private sessionService: SessionService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.indexOf('/api') > -1) {
            return next.handle(
                req.clone({ withCredentials: true })
            ).pipe(catchError(err => {
                if (err.status === 403 || err.status === 401) {
                    this.sessionService.signOut();
                }
                return throwError(err);
            }));
        } else {
            return next.handle(req);
        }
    }
}
