/**
 * Created by roman.kupin on 9/11/2017.
 */

//require("ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../app/**/*.html");

require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/campaigns/directives/campaignListDirective.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/common/carousel/carousel.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/common/code-editor/codeEditor.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/common/contextual-navigation/contextualNavigation.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/common/contextual-navigation/contextEntitySelector.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/common/contextual-navigation/contextActions.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/common/date-range/dateRange.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/common/download/download.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/common/file-upload/fileUpload.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/common/language-selector/languageSelector.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/common/modal/modal_v1.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/common/modal/modal_v2.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/common/modal-fields-editor/modalFieldsEditor.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/common/navigation-pane/navigationPane.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/common/navigation-pane/navigationPaneItem.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/common/panel/panel.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/common/pending-changes/pendingChanges.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/common/select/select.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/common/stats/stat.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/common/switch/switch.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/curation/curationModal.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/dashboard/emailEngagementStory.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/dashboard/generalEngagementStory.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/device-chart/deviceChart.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/email-images-preview/emailImagesPreview.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/field-editors/boolField.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/field-editors/dateField.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/field-editors/htmlField.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/field-editors/numberField.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/field-editors/selectField.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/field-editors/textAreaField.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/field-editors/textField.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/goals/directives/campaignGoals.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/goals/directives/editCampaignGoals.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/goals/directives/editGoals.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/goals/directives/goalSelector.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/goals/directives/goalsPanel.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/goals/directives/incentiveSelector.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/image-library/imageLibrary.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/image-library/imageList.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/image-library/imagePreview.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/image-library/imageProperties.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/image-library/imageUpload.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/image-library/imageUploadFromUrl.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/ranking/rankSlot.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/ranking/rankRunnerUps.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/rating/rating.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/ratings-meter/ratingsMeter.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/reports/reportPanel.component.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/version-panel/version-panel.html");


// touchpoints templates
// prestay
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/pre-stay/touchpoint/integration/edit-confirmation-integration.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/pre-stay/touchpoint/integration/edit-landing-integration.html");

require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/pre-stay/touchpoint/messaging/edit-confirmation-email-image-messages.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/pre-stay/touchpoint/messaging/edit-confirmation-messages.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/pre-stay/touchpoint/messaging/edit-followup-messages.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/pre-stay/touchpoint/messaging/edit-landing-messages.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/pre-stay/touchpoint/messaging/edit-mobile-messages.html");

require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/pre-stay/touchpoint/styling/edit-confirmation-email-image-styles.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/pre-stay/touchpoint/styling/edit-confirmation-styles.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/pre-stay/touchpoint/styling/edit-followup-styles.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/pre-stay/touchpoint/styling/edit-landing-styles.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/pre-stay/touchpoint/styling/edit-mobile-styles.html");

// poststay
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/post-stay/touchpoint/integration/edit-quote-integration.html");

require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/post-stay/touchpoint/messaging/edit-invitation-email-messages.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/post-stay/touchpoint/messaging/edit-quote-messages.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/post-stay/touchpoint/messaging/edit-quote-messages-v1.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/post-stay/touchpoint/messaging/edit-quote-messages-v2.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/post-stay/touchpoint/messaging/edit-reengagement-emails-messages.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/post-stay/touchpoint/messaging/edit-reminder-emails-messages.html");

require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/post-stay/touchpoint/styling/edit-invitation-email-styles.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/post-stay/touchpoint/styling/edit-quote-styles.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/post-stay/touchpoint/styling/edit-quote-styles-v1.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/post-stay/touchpoint/styling/edit-quote-styles-v2.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/post-stay/touchpoint/styling/edit-reengagement-emails-styles.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/post-stay/touchpoint/styling/edit-reminder-emails-styles.html");

// photo advocacy
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/photo-advocacy/touchpoint/integration/edit-contestPage-integration.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/photo-advocacy/touchpoint/integration/edit-photo-river-integration.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/photo-advocacy/touchpoint/messaging/edit-activityEmail-messages.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/photo-advocacy/touchpoint/messaging/edit-finalistsEmail-messages.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/photo-advocacy/touchpoint/messaging/edit-photo-river-messages.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/photo-advocacy/touchpoint/messaging/edit-poststay-email-image-messages.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/photo-advocacy/touchpoint/messaging/edit-reminderEmail-messages.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/photo-advocacy/touchpoint/messaging/edit-winnerEmail-messages.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/photo-advocacy/touchpoint/styling/edit-activityEmail-styles.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/photo-advocacy/touchpoint/styling/edit-contestPage-styles.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/photo-advocacy/touchpoint/styling/edit-finalistsEmail-styles.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/photo-advocacy/touchpoint/styling/edit-photo-river-styles.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/photo-advocacy/touchpoint/styling/edit-poststay-email-image-styles.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/photo-advocacy/touchpoint/styling/edit-pre-arrival-email-styles.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/photo-advocacy/touchpoint/styling/edit-reminderEmail-styles.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/photo-advocacy/touchpoint/styling/edit-winnerEmail-styles.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/photo-advocacy/contest/details.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/photo-advocacy/contest/finalistIncentives.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/photo-advocacy/contest/invitations.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/photo-advocacy/contest/invitationShareModal.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/photo-advocacy/contest/submissions.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/photo-advocacy/contest/winners.html");

// warm leads nurturing
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/warm-lead-nurturing/certificate-email/certificateEmail.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/warm-lead-nurturing/reminder-email/reminderEmail.html");


//
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/touchpoint/messaging/messaging-version.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/touchpoint/styling/styling-version.html");


require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/campaigns/directives/campaignListDirective.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/campaigns/directives/campaignModeSelectorTemplate.html");


require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/pre-stay/campaign/campaign.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/post-stay/campaign/campaign.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/photo-advocacy/campaign/campaign.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/goals/directives/campaignGoals.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/goals/directives/editCampaignGoals.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/goals/directives/editGoals.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/goals/directives/goalSelector.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/goals/directives/goalsPanel.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/goals/directives/incentiveSelector.html");

require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/homepage/homepage.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/homepage/touchpoint/integration/edit-homepage-integration.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/homepage/touchpoint/messaging/edit-homepage-messages.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/homepage/touchpoint/messaging/edit-homepageEmail-messages.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/homepage/touchpoint/messaging/edit-predefinedQuote-messages.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/homepage/touchpoint/styling/edit-homepage-styles.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/homepage/touchpoint/styling/edit-homepageEmail-styles.html");

require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/quote-list/quoteList.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/quote-list/touchpoint/integration/edit-list-integration.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/platform/layer/quote-list/touchpoint/styling/edit-list-styles.html");


require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/stories/library/library.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/stories/curation/curation.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/stories/storyCard.html");

require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/components/ratings-meter/ratingsMeter.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/people/directory/directory.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/people/feedback/feedback.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/people/guestCard.html");

require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/settings/subsections/demos/demos.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/settings/subsections/languages/languages.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/settings/subsections/notifications/notifications.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/settings/subsections/privacy/privacy.html");
require("!ngtemplate-loader?relativeTo=/account-v1/src&prefix=/app!html-loader!../../account-v1/src/sections/account/settings/domains/domains.html");

