import { createReducer, on } from '@ngrx/store';
import { signOut, signIn } from '../actions/authentication.actions';
import { User, getSession } from 'account-hybrid/core/authentication';

export interface State {
    session: User;
}

export const initialState: State = {
    session: getSession()
};

const _reducer = createReducer(initialState,
    on(signOut, state => ({ ...state, session: null })),
    on(signIn, (state, action) => ({ ...state, session: action.session }))
);

export function reducer(state, action) {
    return _reducer(state, action);
}
