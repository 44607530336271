/**
 * Created by ostapenko.r on 9/22/2014.
 */

angular.module('flipto.core.api.languages', [])
    .config(['$provide', function ($provide) {

        $provide.factory('Languages', ['$http', function ($http) {

            /**
             * Languages service
             * @constructor
             */
            function Languages() {

                /**
                 * Get all languages in the system
                 * @returns {*}
                 */
                this.get = function () {
                    return $http.get('/api/languages')
                        .then(function (response) {
                            return response.data;
                        });
                };
            }

            return new Languages();
        }]);
    }])
    .constant('rtlLanguages', ['ar', 'he']);
