/**
 * Created by ostapenko.r on 03/12/2015.
 */

angular.module('flipto.components.versionPanel', ['flipto.core.user', 'flipto.core.storage', 'flipto.core.lodash'])
    .config(['$compileProvider', '$provide', function ($compileProvider, $provide) {

        $compileProvider.directive('ftVersionPanel', ['IdentityService', '_', function (identity, _) {
            return {
                restrict: 'E',
                templateUrl: '/app/components/version-panel/version-panel.html',
                scope: {
                    'currentVersion': '=',
                    'activatedVersion': '=',
                    'updateInstalled': '=',
                    'availableVersion': '=',
                    'onInstall': '&',
                    'onActivate': '&',
                    'onVersionChange': '&'
                },
                link: function (scope, elem, attrs) {
                    scope.identity = identity;
                    scope.panel = {
                        visible: false,
                        newVersionVisible: false,
                        activationVisible: false,
                        oldVersionVisible: false,
                        installationVisible: false
                    };

                    scope.$watchGroup(['currentVersion', 'activatedVersion', 'updateInstalled', 'availableVersion'], function (newValues) {
                        var currentVersion = newValues[0],
                            activatedVersion = newValues[1],
                            updateInstalled = newValues[2],
                            availableVersion = newValues[3];

                        scope.panel.visible = activatedVersion < availableVersion && ((updateInstalled && !identity.isFliptoStaff()) || identity.isFliptoStaff());
                        scope.panel.newVersionVisible = currentVersion == availableVersion && currentVersion != activatedVersion;
                        scope.panel.activationVisible = updateInstalled && identity.isFliptoStaff();
                        scope.panel.oldVersionVisible = currentVersion < availableVersion && updateInstalled;
                        scope.panel.installationVisible = !updateInstalled && identity.isFliptoStaff();
                    });

                    scope.activateVersion = function () {
                        scope.onActivate({version: scope.availableVersion})
                    };

                    scope.installVersion = function () {
                        scope.onInstall({version: scope.availableVersion})
                    };

                    scope.goToOldVersion = function () {
                        scope.onVersionChange({oldVersion: scope.currentVersion, newVersion: scope.activatedVersion});
                        scope.currentVersion = scope.activatedVersion;
                    };

                    scope.goToNewVersion = function () {
                        scope.onVersionChange({oldVersion: scope.currentVersion, newVersion: scope.availableVersion});
                        scope.currentVersion = scope.availableVersion;
                    };
                }
            }
        }]);


        $provide.factory('versionSelection', ['sessionStorage', function (sessionStorage) {


            /**
             * Encapsulates version selection defined by user
             * @constructor
             */
            function VersionSelection() {

            }

            /**
             * Save selection
             * @param newVersion
             */
            VersionSelection.prototype.update = function (newVersion) {
                sessionStorage.put('poststay-version', newVersion);
            };

            VersionSelection.prototype.get = function (selection, current, installed) {
                if (angular.isDefined(selection))
                    return selection;

                var savedSelection = sessionStorage.get('poststay-version');
                if (angular.isDefined(savedSelection) == false)
                    return current;

                if (_.includes([current, installed], savedSelection))
                    return savedSelection;

                return current;
            };

            /**
             * Removes version selection
             */
            VersionSelection.prototype.clear = function () {
                sessionStorage.remove('poststay-version');
            };


            return new VersionSelection();
        }])
    }]);