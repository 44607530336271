/**
 * Created by roman.kupin on 9/18/2017.
 */

import {CONTEXT_SELECTOR_LINK_MODULE} from "./context-selector-link/contextSelectorLink.module";
import {AREAS_MODULE} from "./navigation/areas/areas.module";
import {BRANCHES_MODULE} from "./navigation/branches/branches.module";
import {CONTEXT_SELECTOR_MODULE} from "./context-selector/contextSelector.module";
import {SECTIONS_MODULE} from "./navigation/sections/sections.module";
import {SHOWCASESTORIES_MODULE} from "./showcase-stories/showcaseStories.module";
import {GOOGLE_CHARTS_MODULE} from "./google-charts/googleCharts.module";
import {TOUCHPOINT_CONTAINER_MODULE} from "./v1-adapters/touchpoint-container/touchpointContainer.module";
import {CAMPAIGN_CONTAINER_MODULE} from "./v1-adapters/campaign-container/campaignContainer.module";
import {CONTEST_CONTAINER_MODULE} from "./v1-adapters/contest-container/contestContainer.module";
import {CREATE_CONTEST_MODAL_MODULE} from "./photo-contest/create-contest-modal/createContestModal.module";
import {EXPANDABLE_CONTENT_MODULE} from "./expandable-content/expandableContent.module";
import {STORY_HIGHLIGHT_MODULE} from "./story-highlight/storyHighlight.module";
import {STORY_MODULE} from "./story/story.module";
import {FLOW_MODULE} from "./flows/flow.module";
import {BROADCAST_MODULE} from "./broadcast/broadcast.module";
import {RECOMMENDATIONS_MODULE} from "./recommendations/recommendations.module";

export default [
    CONTEXT_SELECTOR_LINK_MODULE,
    AREAS_MODULE,
    BRANCHES_MODULE,
    CONTEXT_SELECTOR_MODULE,
    SECTIONS_MODULE,
    SHOWCASESTORIES_MODULE,
    GOOGLE_CHARTS_MODULE,
    TOUCHPOINT_CONTAINER_MODULE,
    CAMPAIGN_CONTAINER_MODULE,
    CONTEST_CONTAINER_MODULE,
    CREATE_CONTEST_MODAL_MODULE,
    EXPANDABLE_CONTENT_MODULE,
    STORY_HIGHLIGHT_MODULE,
    STORY_MODULE,
    FLOW_MODULE,
    BROADCAST_MODULE,
    RECOMMENDATIONS_MODULE
];
