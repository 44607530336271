/**
 * Created by roman.kupin on 9/28/2017.
 */
import * as ContextActions from "../actions/context";


export default function contextReducer(state = {
    singleProperty: null,
    multipleProperties: null
}, {type, payload}) {

    switch (type) {
        case ContextActions.SET_SINGLE: {
            return Object.assign({}, state, {singleProperty: payload});
        }
        case ContextActions.SET_MULTIPLE: {
            return Object.assign({}, state, {multipleProperties: payload});
        }

        default: {
            return state;
        }
    }

}
