/**
 * Created by kevin.tiller on 6/4/2018. (copied from prestay.ts)
 */

import * as PhotoAdvocacyApiActions from "../../actions/api/photoAdvocacyApi";


export default function photoAdvocacyReducer(state = {}, {type, payload}) {
    switch (type) {
    case PhotoAdvocacyApiActions.CAMPAIGNS_FETCH_SUCCESS: {
        return Object.assign(state, payload);
    }
    default: {
        return state;
    }
    }
}