import dayjs, { OpUnitType } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs().format();

export type AppDate = string | number | Date | dayjs.Dayjs;

export class DateUtils {
    static ngbDateToDate(ngbDate: NgbDate): Date {
        return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
    }

    static dateToNgbDate(date: Date): NgbDate {
        return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
    }

    static toDateFormat(date: Date | NgbDate): Date {
        return date instanceof Date ? date : DateUtils.ngbDateToDate(date);
    }

    static isSame(date1: AppDate, date2: AppDate, unit: any = 'day'): boolean {
        if (date1 && date2) {
            return dayjs(date1).isSame(date2, unit);
        }
        return null;
    }

    static isBetween(start: AppDate, end: AppDate, date: AppDate, unit: OpUnitType = 'day', d: any = '[]'): boolean {
        if (start && end && date) {
            return dayjs(date)?.isBetween(start, end, unit, d);
        }
        return null;
    }

    static isBefore(date: AppDate, beforeDate: AppDate, inclusive = false): boolean {
        return dayjs(date).isBefore(beforeDate, 'day') || (inclusive && dayjs(date).isSame(beforeDate, 'day'));
    }

    static isAfter(date: AppDate, afterDate: AppDate, inclusive = false): boolean {
        return dayjs(date).isAfter(afterDate, 'day') || (inclusive && dayjs(date).isSame(afterDate, 'day'));
    }

    static year(date?: AppDate) {
        return dayjs(date || new Date()).year();
    }

    static duration(startDate: AppDate, endDate: AppDate, unit: OpUnitType = 'days', format?: string): number {
        if (format) {
            endDate = dayjs(endDate, format);
            startDate = dayjs(startDate, format);
        } else {
            endDate = dayjs(endDate);
        }
        return endDate.diff(startDate, unit);
    }

    static subtract(date: Date | string, amount: number, unit: dayjs.ManipulateType = 'day'): dayjs.Dayjs {
        return dayjs(date).subtract(amount, unit);
    }

    static add(date: Date | string, amount: number, unit: dayjs.ManipulateType = 'day'): dayjs.Dayjs {
        return dayjs(date).add(amount, unit);
    }

    static startOfMonth(date: AppDate): Date {
        return dayjs(date).startOf('month').toDate();
    }

    static endOfMonth(date: AppDate): Date {
        return dayjs(date).endOf('month').toDate();
    }
}
