/**
 * Created by kevin tiller on 2/25/2016.
 */

angular
    .module("flipto.accountv1.SharedFeatures")
    .filter("insertionType",
    [
        "$filter", "_", "insertionTypes",
        function ($filter, _, insertionTypes) {

            var translate = $filter("translate");

            function getTypeName(type) {
                var insertionType = _.find(insertionTypes, function (aType) { return aType.id == type; }); // Coerce string to number
                if (insertionType) {
                    return translate(insertionType.description);
                }
                return '';
            }

            return function (type) {
                return getTypeName(type);
            };
        }
    ]);