/**
 * User: Kupin.R
 * Date: 5/12/14
 */



angular
    .module('flipto.components.campaigns')
    .constant('CampaignMode', {
        'DailyUniqueVisitors': 'dailyUniqueVisitors',
        'DailySignups': 'dailySignups'
    });
