<div class="carousel">
  <div>
      <div *ngFor="let item of items" class="px-5 py-5" [hidden]="!item.isVisible">
        <span  [innerHTML]="item.text"></span>
      </div>

      <div class="carousel-item-dots">
        <div class="carousel-item-dot" *ngFor="let item of items" (click)="select(item)" [ngClass]="{'active': item.isVisible}"></div>
      </div>

      <div class="carousel-nav">
          <button class="ft2-btn -left" (click)="left()" [disabled]="!canGoLeft()" [ngClass]="{'disabled': !canGoLeft()}">
            <mat-icon svgIcon="arrow-left"></mat-icon>
          </button>
          <button class="ft2-btn -right" (click)="right()" [disabled]="!canGoRight()" [ngClass]="{'disabled': !canGoRight()}">
              <mat-icon svgIcon="arrow-right"></mat-icon>
          </button>
      </div>

  </div>
</div>
