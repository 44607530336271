/**
 * Created by ostapenko.r on 11/24/2014.
 */


angular.module('flipto.accountv1.SharedFeatures')
    .controller('flipto.account.sections.platform.photoAdvocacy.SubmissionsController', [
        '$scope', 'PhotoContest', 'contestUuid', '_', '$q', '$sce', '$filter', 'curationModal', 'SpacesService', 'onReviewFinalists', 'SessionService',
        function ($scope, PhotoContest, contestUuid, _, $q, $sce, $filter, curationModal, spacesService, onReviewFinalists, sessionService) {

            var newSubmissions = [],
                curatedSubmissions = [],
                photos = [],
                ftAzureStoredPhoto = $filter('ftAzureStoredPhoto'),
                translate = $filter('translate');

            angular.extend($scope, {
                translate: translate,
                $sce: $sce,
                screen: 'new',
                model: {}
            });

            $scope.model.paging = { SortBy: 'score', SortDirection: 'desc', Offset: 0, Fetch: 24 };
                $scope.model.hasMoreData = true;
                $scope.model.scrollLoadsResetCounter = 0;
                $scope.model.loadNextPage = function () {
                    $scope.model.paging.Offset += $scope.model.paging.Fetch;
                    return PhotoContest.submissionsWithPaging(spacesService.getPropertyUuid(), contestUuid, $scope.model.paging)
                        .then(function (data) {
                            photoStorageInit($scope.model.contest, data);
                            $scope.model.hasMoreData = data.length == $scope.model.paging.Fetch;
                            $scope.model.submissionsWithPaging = Array.prototype.concat($scope.model.submissionsWithPaging, data);
                            return data;
                        });
                };

            $q.all([
                PhotoContest.load({contestId: contestUuid, propertyUuid: spacesService.getPropertyUuid()}),
                PhotoContest.submissions(spacesService.getPropertyUuid(), contestUuid).then(function (data) {
                    if (data.submissions && data.submissions.item) {
                        // Default sort is creation date
                        data.submissions.item = _.sortBy(data.submissions.item, function (submission) {
                            return submission.createDate;
                        }).reverse();

                        // Put passed curations at the bottom
                        data.submissions.item = _.sortBy(data.submissions.item, function (submission) {
                            return submission.isPassed;
                        });

                        angular.forEach(data.submissions.item, function (submission) {
                            submission.isNewSubmission ? (newSubmissions.push(submission)) : (curatedSubmissions.push(submission));
                        });
                    }

                    $scope.screen = newSubmissions.length === 0 ? 'curated' : 'new';
                    photoStorageInit(data, data.submissions ? data.submissions.item : null);

                    return data;
                }),
                PhotoContest.submissionsWithPaging(spacesService.getPropertyUuid(), contestUuid, $scope.model.paging)])
                .then(function (data) {
                    angular.extend($scope.model, {
                        finalistsIncentives: data[0].finalistsIncentives,
                        contest: data[1],
                        contestInfo: data[0],
                        submissionsWithPaging: data[2]
                    });
                    var now = moment();
                    $scope.model.isContestRunning = now.isAfter($scope.model.contestInfo.startDate) && now.isBefore($scope.model.contestInfo.votingEndDate);

                    photoStorageInit($scope.model.contest, $scope.model.submissionsWithPaging);
                    $scope.model.hasMoreData = data[2]?.length == $scope.model.paging.Fetch;
                });

            /**
             * Listen to curation modal events
             */
            curationModal
                .on('finalist', function (photoUUID) {
                    var selectedPhotos = _.filter(photos, { 'photoUUID': photoUUID.toLowerCase() });
                    angular.forEach(selectedPhotos, function (photo) {
                        photo.isNewSubmission = false;
                        photo.isFinalist = true;
                        photo.isPassed = false;
                    });
                })
                .on('pass', function (photoUUID) {
                    var selectedPhotos = _.filter(photos, { 'photoUUID': photoUUID.toLowerCase() });
                    var contest = $scope.model.contest;
                    angular.forEach(selectedPhotos, function (photo) {
                        photo.isNewSubmission = false;
                        photo.isFinalist = false;
                        photo.isPassed = true;
                        if (contest.totalNewPhotoSubmissions > 0) {
                            contest.totalNewPhotoSubmissions -= 1;
                        }
                    });
                })
                .on('photo-updated', function (photoUUID) {
                    var selectedPhotos = _.filter(photos, {'photoUUID': photoUUID.toLowerCase()});
                    angular.forEach(selectedPhotos, function (photo) {
                        photo.isUpdated = true;
                        photo.photoUrl = ftAzureStoredPhoto(photo, 'small');
                    });
                });


            /**
             * Open curation modal for selected photo submission
             * @param submission
             */
            $scope.openPhotoCuration = function (submission) {
                curationModal.new(sessionService.getProperty(spacesService.getPropertyUuid()), submission.guestUUID, true);
            };

            $scope.reviewFinalists = onReviewFinalists;

            function photoStorageInit(data, submissions) {
                var photoKeys = ['firstPlace', 'secondPlace', 'thirdPlace'];
                submissions && angular.forEach(submissions, function (submission) {
                    submission.photoUrl = ftAzureStoredPhoto(submission, 'small');
                    photos.push(submission);
                });
                data.runnerUps && angular.forEach(data.runnerUps.item, function (runnerUp) {
                    runnerUp.photoUrl = ftAzureStoredPhoto(runnerUp, 'thumbnail');
                    photos.push(runnerUp);
                });
                angular.forEach(photoKeys, function(key) {
                    if (!data[key]) return;
                    data[key].photoUrl = ftAzureStoredPhoto(data[key], 'thumbnail');
                    photos.push(data[key]);
                });
            }

        }]);