/**
 * Created by Roman on 2015-03-26.
 */



angular
    .module("flipto.components.common.textFormatting")
    .directive('ftDirection', ['parse', 'rtlLanguages', '_', function (parse, rtlLanguages, _) {

        return {
            restrict: 'A',
            link: function (scope, elem, attr) {

                scope.$watch(function () {
                    return scope.$eval(attr.ftDirection);
                }, function (language) {

                    if (angular.isDefined(language) == false) return;
                    var isRtl = angular.isDefined(language.isRtl) ? parse.bool(language.isRtl) : _.includes(rtlLanguages, language.code);
                    elem.css('direction', isRtl ? 'rtl' : 'ltr');
                });

            }
        };
    }]);