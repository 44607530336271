import { Inject, Injectable } from "@angular/core";
import {
    PASSWORD_STRENGTH_CONFIG,
    PASSWORD_STRENGTH_DEFAULT_CONFIG,
    PasswordStrengthConfig
} from "../config/password-strength.config";

@Injectable()
export class PasswordStrengthService {
    config: PasswordStrengthConfig;

    constructor(
        @Inject(PASSWORD_STRENGTH_CONFIG) config: Partial<PasswordStrengthConfig>,
        @Inject(PASSWORD_STRENGTH_DEFAULT_CONFIG) defaultConfig: PasswordStrengthConfig
    ) {
        this.config = Object.assign({}, defaultConfig, config);
    }

    public specialsMatches(value: string): boolean {
        const match = value?.match((/\W/g));
        return match && match.length >= this.config.minSpecialsLength;
    }

    public numbersMatches(value: string): boolean {
        const match = value?.match((/\d/g));
        return match && match.length >= this.config.minNumbersLength;
    }

    public uppercaseMatches(value: string): boolean {
        const match = value?.match((/[A-Z]/g));
        return match && match.length >= this.config.minUppercaseLength;
    }

    public lowercaseMatches(value: string): boolean {
        const match = value?.match((/[a-z]/g));
        return match && match.length >= this.config.minLowercaseLength;
    }

    public lengthMatches(value: string): boolean {
        return !!value && value.length >= this.config.minLength && value.length <= this.config.maxLength;
    }

    public noSpaceMatches(value): boolean {
        return !this.config.noSpaces || !value.match(/\s/);
    }

    public numbersSpecialsMatches(value: string): boolean {
        return this.config.numberOrSpecials
            ? this.numbersMatches(value) || this.specialsMatches(value)
            : this.numbersMatches(value) && this.specialsMatches(value);
    }
}
