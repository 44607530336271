import { Component, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { filter } from "rxjs/operators";
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

@Component({
    selector: 'ft-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {
    dialogRef: MatDialogRef<any>;
    @Input() title: string;
    @Input() description: string;
    @Input() cancelLabel = 'Cancel';
    @Input() confirmLabel = 'Confirm';
    @Input() hideCloseBtn: boolean;
    @Input() disabled: boolean;
    @ViewChild('dialogTmpl', { static: false }) dialogTmpl: TemplateRef<any>;
    @Output() confirm = new EventEmitter();
    @Output() cancel = new EventEmitter();

    constructor(private dialog: MatDialog) {
    }

    @HostListener('click', ['$event'])
    onClick(e: MouseEvent) {
        if (this.disabled){
            this.confirm.emit();
        }else{
            e.stopPropagation();
            this.openDialog();
        }
    }

    openDialog() {
        this.dialogRef = this.dialog.open(this.dialogTmpl, {
            panelClass: 'ft2',
            backdropClass: '-dark',
            data: this
        });
        this.dialogRef.keydownEvents()
            .pipe(
                filter(event => (event.key === 'Escape'))
            )
            .subscribe(() => {
            });
    }

    onClose() {
        this.dialogRef?.close();
    }

    onCancel() {
        this.cancel.emit();
        this.onClose();
    }

    onConfirm() {
        this.confirm.emit();
        this.onClose();
    }

    ngOnInit(): void {
    }
}
