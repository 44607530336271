import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'format',
})
export class FormatPipe implements PipeTransform {
  transform(value: any, cb: any, ...props: any[]): any {
    return cb(value, ...props);
  }
}
