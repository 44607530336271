<nav class="ft-appmenu"
     [hidden]="!(visibility$ | async)"
     (clickOutside)="hideAppMenu()">
    <button class="ft-btn-close" (click)="hideAppMenu()"></button>

    <div class="ft-userid pointer" *ngIf="(user$ | async) as user" >
        <div class="v-center" (click)="goToProfile()">
            <ft-user-profile-image [user]="user" class="mr-3">
            </ft-user-profile-image>
            <span class="ft-username">{{user | fullName}}</span>
        </div>
        <button *ngIf="user.isImpersonating" (click)="unimpersonate()">Unimpersonate</button>
    </div>

    <div class="ft-spaceid" *ngIf="(spaces$ | async)?.allSpaces.length > 1" (click)="showSpacesComponent()">
        <span class="ft-spacename" *ngIf="spaceService.current">{{spaceTitle}}</span>
        <span class="ft-switchspace">Switch</span>
    </div>

    <ul class="ft-area-links"
        *ftAreas="let areas=areas; let isActive=isActive; let invoke=invoke;registryName:'AppMenuAreasRegistry'">
        <!-- Area links - appears only on mobile -->
        <ng-container *ngFor="let area of areas | orderBy: 'order'" >
            <li [class]="area.$class"
                [ftDisabled]="area.isDisabled"
                [ngClass]="{active: isActive(area)}">
                <!-- Primary -->
                <ng-container *ngIf="area.state">
                    <a [uiSref]="area.state" [uiParams]="area.stateParams" (click)="hideAppMenu()">{{area.name}}</a>
                </ng-container>

                <!-- More -->
                <ng-container *ngIf="area.options">
                    <ul>
                        <li *ngFor="let option of area.options | orderBy: 'order'" [class]="option.$class" [ngClass]="{active: isActive(option)}"
                            [ftDisabled]="option.isDisabled">
                            <a *ngIf="option.state" [uiSref]="option.state" [uiParams]="option.stateParams">{{option.name}}</a>
                            <a *ngIf="option.handler" (click)="hideAppMenu(); invoke($event, option.handler)">{{option.name}}</a>
                        </li>
                    </ul>
                </ng-container>
            </li>
            <li class="ft-navitem ft-navitem-divider" *ngIf="area?.divider"></li>
        </ng-container>
    </ul>

    <ul class="ft-appmenu-links">
        <!-- Permanent appmenu links -->
        <li class="ft-navitem">
            <a (click)="signOut()">Sign out</a>
        </li>
    </ul>

    <!-- <ft-notifications>
    </ft-notifications> -->
</nav>
