/**
 * Created by Roman on 2015-01-06.
 */


angular
    .module("flipto.components.common.languageSelector")
    .filter('languagesToCSV', ['_', function (_) {

        function convertToCSV(languages) {
            return _.map(languages, function (language) {
                return language.name;
            }).join(', ');
        }

        return function (languages) {
            if (!angular.isArray(languages)) return '';
            return convertToCSV(languages);
        }
    }]);