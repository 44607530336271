/**
 * Created by roman.kupin on 10/3/2017.
 */



export const NAME = "ftCarouselCardMoveLeftOrRight";

export const DEFINITION = [function () {
    return {
        scope: false,
        require: ["^ftCarouselCards", "ftCarouselCard"],
        link: function (scope, elem, attrs, ctrls) {

            const cardsCtrl = ctrls[0];
            const cardCtrl = ctrls[1];

            function move() {
                scope.$apply(() => {
                    if (cardCtrl.index > cardsCtrl.activeCardIndex) {
                        cardsCtrl.moveRight();
                    } else if (cardCtrl.index < cardsCtrl.activeCardIndex) {
                        cardsCtrl.moveLeft();
                    }
                });
            }

            elem.bind("click", move);

            scope.$on("$destroy", () => {
                elem.unbind("click", move);
            });
        }
    };
}];

