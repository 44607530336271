import { sha1 } from './sha1'


export class HashGenerator {
    static getSha1IntHash(text: string): number {
        const sha1Hash = HashGenerator.getSha1Hash(text);
        return sha1Hash.split('').map(char => char.charCodeAt(0)).reduce((prev, curr) => prev + curr, 0);
    }

    static getSha1Hash(text: string): string {
        return sha1(text) as string;
    }
}
