import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbPopoverModule, NgbTimepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from "@angular/forms";
import { ClickOutsideModule } from "ng-click-outside";
import { SimpleTimerangePickerComponent } from './components/simple-timerange-picker/simple-timerange-picker.component';


@NgModule({
    declarations: [
        SimpleTimerangePickerComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ClickOutsideModule,
        NgbTimepickerModule,
        NgbTooltipModule,
        NgbPopoverModule,
    ],
    exports: [
        SimpleTimerangePickerComponent
    ]
})
export class TimeRangePickerModule {
    constructor() {
    }
}
