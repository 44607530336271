

import * as StoryModalComponent from "./storyModal.component";
import { StoryModalService } from "./storyModal.service";

declare const angular;
export const STORY_MODULE = angular.module("AccountApp.v2.Components.Story", []);

export class StoryModalConfig {

    static $inject = ["$compileProvider"];

    constructor($compileProvider) {
        $compileProvider.directive(StoryModalComponent.NAME, StoryModalComponent.DEFINITION);
    }
}

STORY_MODULE.config(StoryModalConfig);
STORY_MODULE.service(StoryModalService.Name, StoryModalService);
