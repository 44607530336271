/**
 * Created by ostapenko.r on 10/7/2014.
 */
angular.module('flipto.components.fieldEditors')
    .config(['$provide', '$compileProvider', function ($provide, $compileProvider) {

        $compileProvider.directive('ftNumberField', [function () {
            return {
                restrict: 'E',
                scope: {
                    'datasource': '='
                },
                templateUrl: '/app/components/field-editors/numberField.html',
                link: function (scope, elem, attrs) {
                    scope.fields = adjustFields(scope.$eval(attrs.fields) || []);
                    scope.required = (angular.isDefined(attrs.required) && attrs.required!=='false') || false;
                    scope.min = angular.isDefined(attrs.min) ? scope.$eval(attrs.min) : '';
                    scope.max = angular.isDefined(attrs.max) ? scope.$eval(attrs.max) : '';
                    scope.pattern = attrs.pattern || '';

                    /**
                     * Returns fields array as objects
                     * @param fields
                     * @returns {Array}
                     */
                    function adjustFields(fields) {
                        return _.map(fields, function (field) {
                            if (angular.isString(field) == true) {
                                return {name: field, label: null};
                            }
                            return field;
                        });
                    }
                }
            };
        }]);
    }]);