import { Injectable } from '@angular/core';
import { saveAs } from "file-saver";
import { HttpResponse } from '@angular/common/http';

@Injectable({ providedIn: "any" })
export class FileSaverService {
    saveTextAs(textContent: string = '', fileName: string = 'download.txt', charset: string = 'utf-8') {
        textContent = textContent.replace(/\r?\n/g, "\r\n");
        if (Blob) {
            saveAs(new Blob([textContent], { type: "text/plain;charset=" + charset }), fileName);
            return true;
        } else {//IE9-
            var saveTxtWindow = (window.frames as any).saveTxtWindow;
            if (!saveTxtWindow) {
                saveTxtWindow = document.createElement('iframe');
                saveTxtWindow.id = 'saveTxtWindow';
                saveTxtWindow.style.display = 'none';
                document.body.insertBefore(saveTxtWindow, null);
                saveTxtWindow = (window.frames as any).saveTxtWindow;
                if (!saveTxtWindow) {
                    saveTxtWindow = window.open('', '_temp', 'width=100,height=100');
                    if (!saveTxtWindow) {
                        window.alert('Sorry, download file could not be created.');
                        return false;
                    }
                }
            }

            var doc = saveTxtWindow.document;
            doc.open('text/html', 'replace');
            doc.charset = charset;
            if (['.htm', 'html'].some(ext => fileName.endsWith(ext))) {
                doc.close();
                doc.body.innerHTML = '\r\n' + textContent + '\r\n';
            } else {
                if (!fileName.endsWith('.txt')) fileName += '.txt';
                doc.write(textContent);
                doc.close();
            }

            var retValue = doc.execCommand('SaveAs', null, fileName);
            saveTxtWindow.close();
            return retValue;
        }
    }

    saveContent(content, contentType, fileName) {
        if (Blob) {
            saveAs(new Blob([content], { type: contentType }), fileName);
        } else {
            this.saveTextAs(content, fileName);
        }
    };

    saveBlob(blob, fileName) {
        if (Blob) {
            saveAs(blob, fileName);
        } else {
            this.saveTextAs(blob, fileName);
        }
    };
}