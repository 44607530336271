/**
 * Created by luis.suarez on 1/31/2018.
 */

import * as AdvocacyImpactApiActions from "../../actions/advocacyImpactApi";


export default function propertyAdvocacyImpactReducer(state = {}, {type, payload}) {
    switch (type) {
        case AdvocacyImpactApiActions.LAYER_PRESTAY_STORIES_FETCH_SUCCESS: {
            return Object.assign({}, state, { prestay: payload });
        }

        case AdvocacyImpactApiActions.LAYER_POSTSTAY_STORIES_FETCH_SUCCESS: {
            return Object.assign({}, state, { poststay: payload });
        }

        case AdvocacyImpactApiActions.LAYER_PHOTOCONTEST_STORIES_FETCH_SUCCESS:
        {
            let photoAdvocacy = {
                totalSubmissions: 0,
                totalFinalists: 0,
                totalGuestAdvocates: 0,
                totalFriendAdvocates: 0,
                totalFollowers: 0,
                totalVisitors: 0,
                totalSignups: 0,
                contests: payload
            };

            if(!!payload) {
                payload.forEach((contest) => {
                    photoAdvocacy.totalSubmissions += contest.totalPhotoSubmissions;
                    photoAdvocacy.totalFinalists += contest.totalFinalists;
                    photoAdvocacy.totalGuestAdvocates += contest.totalGuestAdvocates;
                    photoAdvocacy.totalFriendAdvocates += contest.totalFriendAdvocates;
                    photoAdvocacy.totalFollowers += contest.totalFollowers;
                    photoAdvocacy.totalVisitors += contest.totalVisitors;
                    photoAdvocacy.totalSignups += contest.totalSignups;
                });
            }

            return Object.assign({}, state, {photoAdvocacy});
        }
        
        case AdvocacyImpactApiActions.LAYER_HOMEPAGE_STORIES_FETCH_SUCCESS:
        {
            return Object.assign({}, state, { homepage: payload });
        }

        case AdvocacyImpactApiActions.LAYER_QUOTELIST_STORIES_FETCH_SUCCESS:
        {
            return Object.assign({}, state, { quoteList: payload });
        }

        default: {
            return state;
        }
    }
}