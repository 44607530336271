import {
    Component,
    ContentChildren,
    forwardRef,
    Input,
    OnInit,
    QueryList,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';
import { ModalEditorWrapperComponent } from '../../modal-editor-wrapper/modal-editor-wrapper.component';


@Component({
    selector: 'ft-wizard-editor',
    templateUrl: './wizard-editor.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => WizardEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: WizardEditorComponent
        }
    ]
})
export class WizardEditorComponent extends ModalEditorBase implements OnInit {
    @ContentChildren('step') stepTemplates: QueryList<TemplateRef<any>>;
    @ContentChildren(NgModel, { descendants: true }) stepControls: QueryList<NgModel>;
    @Input() initialStepIndex = 0;
    @Input() singleStep = false;
    @ViewChild(ModalEditorWrapperComponent) modal: ModalEditorWrapperComponent;
    controls: NgModel[] = [];
    activeStepIndex;

    constructor() {
        super();
    }

    ngOnInit() {
        this.activeStepIndex = this.initialStepIndex;
    }

    onEditorVisible(): void {
        this.controls.push(...this.stepControls);
    }

    onEditorHidden(): void {
        this.activeStepIndex = this.initialStepIndex;
    }

    onNext() {
        this.activeStepIndex++;
        setTimeout(() => {
            this.controls.push(...this.stepControls);
        });
    }

    get controlsValue() {
        return this.controls.reduce((prev, control) => {
            return { ...prev, [control.name]: control.value };
        }, {});
    }

    applyChanges() {
        this.save.next(this.controlsValue);
    }

    get canSave() {
        return this.activeStepIndex === this.stepTemplates.length - 1 || this.singleStep;
    }

    isInvalid(): boolean {
        if (this.stepControls) {
            return this.stepControls.some(control => control.invalid);
        }
        return super.isInvalid();
    }
}
