/**
 * Created by roman.kupin on 10/25/2017.
 */


import {
    ContextSelectorGroup,
    ContextSelectorSetup,
    PropertyContext,
} from "../components/context-selector/contextSelector.setup";
import ContextSelectorActions from "../components/context-selector/actions/index";
import ContextGroupActions from "../components/context-selector/context-group/actions/index";
import { PROVIDE_RESULT } from "../middleware/delayedResult.middleware";
import ContextActions from "./context";
import { SpaceType } from 'account-hybrid/common/components/spaces/models/space-type';

export function setupContextSelectors() {
    return (dispatch, getState, { IdentityService, SessionService, SpacesService }) => {
        const currentSpace = SpacesService.current;
        if (!currentSpace) return;
        const propertyContexts = getPropertyContextsBasedOnSpace(currentSpace, SessionService)
        const singlePropertySelectorSetup = new ContextSelectorSetup("singlePropertySelector", [
            new ContextSelectorGroup(
                "properties",
                (contextGroup) => `${contextGroup.contexts.length} properties`,
                propertyContexts)
        ]);
        const multiplePropertySelectorSetup = new ContextSelectorSetup("multiplePropertySelector", [
            new ContextSelectorGroup(
                "properties",
                (contextGroup) => `${contextGroup.contexts.length} properties`,
                propertyContexts)
        ]);
        dispatch(ContextSelectorActions.addNew(singlePropertySelectorSetup));
        dispatch(ContextSelectorActions.addNew(multiplePropertySelectorSetup));
        dispatch(ContextSelectorActions.selectGroup("singlePropertySelector", "properties"));
        dispatch(ContextSelectorActions.selectGroup("multiplePropertySelector", "properties"));

        if (currentSpace.type == SpaceType.Property) {
            dispatch(ContextActions.setSingle(currentSpace.id));
            dispatch(ContextActions.setMultiple([currentSpace.id]));
        }
        if (currentSpace.type == SpaceType.Mixed) {
            dispatch(ContextActions.setSingle(currentSpace.property.id));
            dispatch(ContextActions.setMultiple([currentSpace.property.id]));
        }
    };
}

export function getPropertyContextsBasedOnSpace(currentSpace, SessionService) {
    if (!currentSpace) return [];
    switch (currentSpace.type) {
        case SpaceType.Company: {
            return SessionService.getCompanyProperties(currentSpace.id)
                .map(property => new PropertyContext(property.DisplayName || property.Name, property.UUID, property.Slug, property.BackgroundImageUrl));
        }
        case SpaceType.Mixed: {
            return SessionService.getCompanyProperties(currentSpace.company.id)
                .map(property => new PropertyContext(property.DisplayName || property.Name, property.UUID, property.Slug, property.BackgroundImageUrl));
        }
        case SpaceType.Property: {
            return [SessionService.getProperty(currentSpace.id)]
                .map(property => new PropertyContext(property.DisplayName || property.Name, property.UUID, property.Slug, property.BackgroundImageUrl));
        }
    }
}

export function setSinglePropertyContext(propertySlug?) {
    return (dispatch, getState) => {
        return new Promise(((resolve, reject) => {
            const state = getState();
            const isComingFromSinglePropertyContextSelector = !propertySlug;
            let singlePropertyContext;
            if (isComingFromSinglePropertyContextSelector) {
                singlePropertyContext = state.ui.contextSelector["singlePropertySelector"]
                    .contextGroups.find(group => group.name === "properties")
                    .contexts.find(context => context.isActive);
                dispatch(ContextSelectorActions.hide("singlePropertySelector"));
            } else {
                singlePropertyContext = state.ui.contextSelector["singlePropertySelector"]
                    .contextGroups.find(group => group.name === "properties")
                    .contexts.find(context => context.propertySlug === propertySlug);
                dispatch(ContextGroupActions.toggleSingle("singlePropertySelector", "properties", singlePropertyContext._id));
            }
            dispatch(ContextActions.setSingle(singlePropertyContext.propertySlug));
            dispatch({
                [PROVIDE_RESULT]: {
                    meta: "singlePropertySelector",
                    result: singlePropertyContext
                }
            });
            resolve(null);
        }));

    };
}

const Actions = { setupContextSelectors, setSinglePropertyContext };
export default Actions;
