/**
 * Created by Roman on 2015-03-13.
 */



angular.module('flipto.components.common.modelFormatters')
    .directive('numbersOnly', function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, el, attrs, ngModelCtrl) {

                ngModelCtrl.$parsers.push(function (value) {
                    if (value.match(/[^0-9]/g)) {
                        var transformedInput = value.replace(/[^0-9]/g, '');
                        ngModelCtrl.$setViewValue(transformedInput);
                        ngModelCtrl.$render();
                    }
                    return value;
                });
            }
        };
    });