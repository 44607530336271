/**
 * Created by max.ackerman on 8/21/2018.
 */


declare let angular;
export const NAME = "ftPrevPage";

export const DEFINITION = {
    scope: false,
    require: "^ftPaging",
    link: function PrevPageLinkFn(scope, elem, attrs, ctrl) {

        scope.$watch(() => { return ctrl.pageIndex; }, (pageIndex) => {
            return pageIndex === 1 ? elem.addClass('ng-hide') : elem.removeClass('ng-hide');
        });

        function prev() {
            if (ctrl.pageIndex === 1) return;
            scope.$apply(() => {
                ctrl.prev();
            });
        }

        elem.on("click", prev);

        scope.$on("$destroy", () => {
            elem.off("click", prev);
        });
    }
};