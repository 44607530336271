/**
 * Created by roman.kupin on 10/2/2017.
 */


import * as ClickClassDirective from "./click-class/clickClass.directive";
import * as InitializedContentDirective from "./initialized-content/initializedContent.directive";
import * as CarouselCardsDirective from "./carousel-cards/carouselCards.directive";
import * as CarouselCardDirective from "./carousel-cards/carouselCard.directive";
import * as CarouselCardTriggerDirective from "./carousel-cards/carouselCardTrigger.directive";
import * as CarouselCardMoveLeftOrRight from "./carousel-cards/carouselCardMoveLeftOrRight.directive";
import * as CarouselCardMoveRight from "./carousel-cards/carouselCardMoveRight.directive";
import * as CarouselCardMoveLeft from "./carousel-cards/carouselCardMoveLeft.directive";
import * as PageClassHook from "./page-class-hook/pageClassHook.directive";
import * as PositionalClass from "./positional-class/positionalClass.directive";
import * as EnsureModalSingleScrollDirective from "./ensure-modal-single-scroll/ensureModalSingleScroll";
import * as HideInV2Directive from "./hide-in-v2/hideInV2.directive";
import * as ContentPreview from "./content-preview/contentPreview.directive";

declare let angular;


export default angular.module("AccountApp.v2.Directives", [])
    .directive(EnsureModalSingleScrollDirective.NAME, EnsureModalSingleScrollDirective.DEFINITION)
    .directive(ClickClassDirective.NAME, ClickClassDirective.DEFINITION)
    .directive(InitializedContentDirective.NAME, InitializedContentDirective.DEFINITION)
    .directive(CarouselCardsDirective.NAME, CarouselCardsDirective.DEFINITION)
    .directive(CarouselCardDirective.NAME, CarouselCardDirective.DEFINITION)
    .directive(CarouselCardTriggerDirective.NAME, CarouselCardTriggerDirective.DEFINITION)
    .directive(CarouselCardMoveLeftOrRight.NAME, CarouselCardMoveLeftOrRight.DEFINITION)
    .directive(CarouselCardMoveRight.NAME, CarouselCardMoveRight.DEFINITION)
    .directive(CarouselCardMoveLeft.NAME, CarouselCardMoveLeft.DEFINITION)
    .directive(PageClassHook.NAME, PageClassHook.DEFINITION)
    .directive(PositionalClass.NAME, PositionalClass.DEFINITION)
    .directive(HideInV2Directive.NAME, HideInV2Directive.DEFINITION)
    .directive(ContentPreview.NAME, ContentPreview.DEFINITION);
