/**
 * Created by Roman on 2014-10-30.
 */



angular
  .module('flipto.components.imageLibrary', ['flipto.core.user'])
  .config(['$provide', '$compileProvider', function($provide, $compileProvider) {

    $provide.constant('imageType', {
      All: 0,
      Logos: 1,
      Assets: 2,
      Background: 3
    });

    $compileProvider.directive('ftImageLibrary', ['urlFormatter', function(urlFormatter) {
      return {
        restrict: 'E',
        require: 'ftImageLibrary',
        controller: ['$scope', function($scope) {

          /**
           * Select image and show it's preview
           * @param image
           */
          this.selectImage = function(image) {
            $scope.view = 'preview';
            $scope.image = image;
          };

          /**
           * Clear selection and show list view
           */
          this.clearImageSelection = function() {
            $scope.view = 'list';
            $scope.image = null;
          };

          /**
           * Adds image to images array
           * @param image
           */
          this.addImage = function(image) {
            $scope.images.push(image);
          };

        }],
        scope: {
          'images': '=',
          'propertyId': '=',
          'imageType': '=',
          'view': '@?',
          'previewImage': '=?',
          'onImageSelected': '&',
          'onImageUploaded': '&'
        },
        templateUrl: '/app/components/image-library/imageLibrary.html',
        link: function(scope, elem, attrs, ctrl) {
          scope.view = scope.view || 'list';
          scope.isProcessingUpload = false;
          scope.uploadUrl = urlFormatter.format('/api/:propertyUUID/properties/:propertyUUID/image', {
            'propertyUUID': scope.propertyId,
            'type': scope.imageType
          }, true);

          scope.$watch('image', function(image) {
            if ( angular.isDefined(image) ){
              scope.onImageSelected({ image: image });
            }
          });

          scope.$watch('previewImage', function(previewImage) {
            if ( angular.isDefined(previewImage) ){
              scope.image = previewImage;
            }
          });

          /**
           * Add new image to array and show it's preview
           * @param image
           */
          scope.onImageUploaded = function(image) {
            if ( !!image ){
              ctrl.addImage(image);
              ctrl.selectImage(image);
            }
            scope.isProcessingUpload = false;
          };

          /**
           * Show image preview
           * @param image
           */
          scope.onImageUploadedFromUrl = function(image) {
              ctrl.addImage(image);
              ctrl.selectImage(image);
            scope.isProcessingUpload = false;
          };

          scope.onImageUploading = function() {
            scope.isProcessingUpload = true;
          };
        }
      };
    }]);
  }]);
