/**
 * User: Kupin.R
 * Date: 5/29/14
 */



angular
    .module("flipto.components.goals")
    .directive("ftEditGoals",
        [
            "$document", "_", '$filter',
            function ($document, _, $filter) {
                var translate = $filter('translate');
                var  typesMap = {
                    1 : translate('flipto.components.goals.Advocates'),
                    4 : translate('flipto.components.goals.Sharing'),
                    2 : translate('flipto.components.goals.SocialConnections'),
                    3 : translate('flipto.components.goals.Participation')
                };
                return {
                    replace: true,
                    restrict: "E",
                    templateUrl: "/app/components/goals/directives/editGoals.html",
                    scope: {
                        "goals": "=",
                        "incentives": "=",
                        "culture": "=",
                        "type": "="
                    },
                    link: function (scope) {
                        scope.typesMap = typesMap;

                        /**
                         * Adds new goal
                         */
                        scope.addNewGoal = function () {
                            scope.goals.newGoals.push({
                                isActive: 1,
                                type: scope.type
                            });
                        };

                        /**
                         * Remove new goal from array
                         * @param goal
                         */
                        scope.removeNewGoal = function (index) {
                            scope.goals.newGoals.splice(index, 1);
                        };

                        /**
                         * Deactivate goal
                         * @param goal
                         */
                        scope.deactivate = function (goal) {
                            goal.isActive = 0;
                        };

                        /**
                         * Activate goal
                         * @param goal
                         */
                        scope.activate = function (goal) {
                            goal.isActive = 1;
                        };

                        /**
                         * Remove goal
                         * @param goal
                         */
                        scope.remove = function (goal) {
                            goal.markedForRemoval = true;
                        };

                        /**
                         * Restore goal
                         * @param goal
                         */
                        scope.restore = function (goal) {
                            goal.markedForRemoval = false;
                        };
                    }
                };
            }
        ]);
