/**
 * Created by ostapenko.r on 9/19/2014.
 */
angular.module('flipto.components.common.listEditor')
    .directive('ftListEditor', ['_', function (_) {
        return {
            restrict: 'E',
            require: 'ftListEditor',
            replace: true,
            template: '<div ng-transclude></div>',
            transclude: true,
            scope: {
                'list': '=',
                'map': '@?',
                "onItemAdded": "&?"
            },
            link: function (scope, element, attributes, ftListEditor) {
                var unique = angular.isDefined(attributes.unique);
                scope.$watchCollection('list', function (list) {
                    ftListEditor.register({ list: list, unique: unique, map: scope.map });
                });
            },
            controller: ['$scope', function ($scope) {
                var unique,
                    list,
                    map;

                this.register = function (model) {
                    list = model.list;
                    unique = model.unique;
                    map = model.map;
                };

                this.addItem = function (value) {
                    if (angular.isArray(value)) {
                        if (value.length === 0) return;
                        angular.forEach(value, addToList);
                    } else if (angular.isString(value)) {
                        if (!value || !value.trim()) return;
                        addToList(value)
                    } else {
                        addToList(value);
                    }
                };

                function addToList(value) {
                    var item = {};
                    if (map) {
                        item[map] = value;
                        if (unique && _.find(list, item)) return;
                    } else {
                        item = value;
                        if (unique && list.indexOf(item) !== -1) return;
                    }

                    list.push(item);
                    $scope.$broadcast('list-editor.added-to-list', item);
                    if ($scope.onItemAdded) $scope.onItemAdded({ item: item });
                }
            }]
        };
    }]);