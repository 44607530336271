import { FlowStep } from ".";


export const NAME = "ftFlow1";

export class FlowController {

    static $inject = ["$scope", "$transclude", "$compile", "$element"];

    steps: FlowStep[] = [];

    constructor(public $scope,
        public $transclude,
        public $compile,
        public $element) {
        
    }

    $onInit() {
        //if (!!this.steps.length) this.steps[0].isActive = true;
    }

    // done(args) {
    //     const activeStepIdx = this.steps.findIndex(s => s.isActive);
    //     this.steps.forEach((s, i) => s.isActive = i === activeStepIdx + 1);
    //     const onActive = this.steps.find(s => s.isActive).onActive;
    //     if (!!onActive) {
    //         onActive(args);
    //     }
    // }

}


export const OPTIONS = {
    bindings: {
        steps: "<"
    },
    controller: FlowController,
    controllerAs: "$ftFlow"
}