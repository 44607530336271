import { createAction, props } from '@ngrx/store';
import { AppNotification } from "../models/notification.model";

export const loadNotifications = createAction(
    '[App Notifications] Load Notifications'
);

export const loadNotificationsFailure = createAction(
    '[App Notifications] Load Notifications Failure',
    props<{ error: any }>()
);

export const loadNotificationsSuccess = createAction(
    '[App Notifications]  Load Notifications Success',
    props<{ notifications: AppNotification[] }>()
);

export const loadLatestBadge = createAction(
    '[App Notifications] Load Latest Badge'
);

export const setLatestBadge = createAction(
    '[App Notifications] Set Latest Badge',
    props<{ latestBadge: string }>()
);

export const updateSeenDateOfNotifications = createAction(
    '[App Notifications] Update seen date of notifications',
    props<{ seenDate: string }>()
);

export const updateSeenDateOfNotificationsSuccess = createAction(
    '[App Notifications] Update seen date of notifications success',
    props<{ notifications: AppNotification[] }>()
);
