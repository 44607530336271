/**
 * Created by roman.kupin on 02/07/2014.
 */


angular
    .module('flipto.components.common.dateRange')
    .config(['$compileProvider', function ($compileProvider) {

        $compileProvider.directive('ftDateRange',
            ['$document',
                function ($document) {
                    return {
                        restrict: 'A',
                        scope: {
                            'startDate': '=?',
                            'endDate': '=?',
                            'options': '@?',
                            'notifyDateRangeChanged': '&onDateRangeChanged'
                        },
                        link: function (scope, element, attrs) {
                            var options;

                            scope.$watch('startDate', function (date) {
                                if (angular.isDefined(date)) {
                                    initialize();
                                }
                            });

                            scope.$watch('endDate', function (date) {
                                if (angular.isDefined(date)) {
                                    initialize();
                                }
                            });

                            scope.$watch('options', function (value) {
                                options = scope.$eval(value);
                                initialize();
                            });

                            $document.bind('keydown keypress', function (event) {
                                if (event.which === 27) {
                                    $(element).data('daterangepicker') && $(element).data('daterangepicker').hide()
                                }
                            });

                            scope.$on('$destroy', function () {
                                $document.unbind('keydown keypress');
                            });

                            initialize();

                            function initialize() {
                                options = options || {};
                                options.startDate = scope.startDate || options.startDate;
                                options.endDate = scope.endDate || options.endDate;
                                var dp = $(element).daterangepicker(options,
                                    function (start, end) {
                                        scope.$apply(function () {
                                            scope.startDate = start;
                                            scope.endDate = end;
                                            scope.notifyDateRangeChanged({
                                                start: options.convertToNative ? start.toDate() : start,
                                                end: options.convertToNative ? end.toDate() : end
                                            });
                                            initialize();
                                        });
                                    });

                                dp.data("daterangepicker").show();
                                dp.data("daterangepicker").hide();
                            }
                        }
                    };
                }
            ]);


        $compileProvider.directive('ftDateRangePicker',
            [
                function () {
                    return {
                        restrict: 'E',
                        template: '<div class="ft-dropdown date-range" ft-date-range="" start-date="startDate" end-date="endDate" on-date-range-changed="notifyDateRangeChanged(start, end)"><span ft-show-date-range start-date="startDate" end-date="endDate"></span> <i class="ft-icn-caret-down"><i></div>',
                        scope: {
                            'startDate': '=?',
                            'endDate': '=?',
                            'notifyDateRangeChanged': '&onDateRangeChanged'
                        },
                        compile: function (tElement, attrs) {
                            return function (scope, element) {

                            }
                        }
                    }
                }]);

        $compileProvider.directive('ftShowDateRange',
            [
                function () {
                    var defaultFormat = 'MMMM D, YYYY';
                    return {
                        restrict: 'A',
                        scope: {
                            'startDate': '=?',
                            'endDate': '=?'
                        },
                        link: function (scope, element, attrs) {
                            var format = attrs.format;
                            scope.$watch('startDate', function (date) {
                                if (angular.isDefined(date)) {
                                    showDateRange(scope.startDate, scope.endDate);
                                }
                            });

                            scope.$watch('endDate', function (date) {
                                if (angular.isDefined(date)) {
                                    showDateRange(scope.startDate, scope.endDate);
                                }
                            });

                            function showDateRange(start, end) {
                                if (angular.isDefined(start) == false ||
                                    angular.isDefined(end) == false) return;
                                angular.element(element).html(start.format(format || defaultFormat) + ' - ' + end.format(format || defaultFormat));
                            }
                        }
                    }
                }]);

        $compileProvider.directive('ftSnapshot',
            [
                'snapshot', 'predefinedDateRanges',
                function (snapshot, predefinedDateRanges) {
                    return {
                        restrict: 'E',
                        replace: false,
                        templateUrl: '/app/components/common/date-range/dateRange.html',
                        scope: {
                            'notifyDateRangeChanged': '&onDateRangeChanged'
                        },
                        link: function (scope, element, attrs) {

                            scope.mode = "mode-default";
                            scope.appearance = "";

                            var currentSnapshot = snapshot.get(),
                                range = predefinedDateRanges[currentSnapshot.label] || [currentSnapshot.start, currentSnapshot.end],
                                isUpdateSnapshot = !angular.isDefined(attrs.dontUpdateSnapshot),
                                isOnlyPastFewDaysMode = angular.isDefined(attrs.pastFewDaysMode),
                                isInlineMode = angular.isDefined(attrs.isInlineMode),
                                isShowCustomRange = !angular.isDefined(attrs.hideCustomRange);

                            if (isOnlyPastFewDaysMode) scope.mode = "mode-conversational";
                            if (isInlineMode) scope.mode = "mode-inline";
                            if (attrs.appearance) scope.appearance = 'appearance-' + attrs.appearance;

                            scope.snapshot = currentSnapshot;
                            scope.isOnlyPastFewDaysMode = isOnlyPastFewDaysMode;
                            var dp = $(element).daterangepicker({
                                ranges: predefinedDateRanges,
                                showCustomRangeLabel: isShowCustomRange,
                                linkedCalendars: false,
                                startDate: range[0],
                                endDate: range[1]
                            },
                                function (start, end, label) {
                                    scope.$apply(function () {
                                        scope.snapshot = { label: label, start: start.toDate(), end: end.toDate() };
                                        if (isUpdateSnapshot) snapshot.set(scope.snapshot);
                                        scope.notifyDateRangeChanged({ start: start.toDate(), end: end.toDate() });
                                    });
                                });

                            dp.data("daterangepicker").show();
                            dp.data("daterangepicker").hide();
                        }
                    };
                }
            ])
    }]);