
angular.module('flipto.components.common.modelOperations')
.directive('ftRemoveArrayItem', [function () {
    return {
        restrict: 'A',
        require: ['ngModel'],
        link: function (scope, element, attrs, ctrls) {

            var ngModelCtrl = ctrls[0];

            element.on("click", function () {
                scope.$apply(function(){
                    var array = scope.$eval(attrs.ftRemoveArrayItem);
                    var idx = array.indexOf(ngModelCtrl.$modelValue);
                    array.splice(idx, 1);
                });
            });

            scope.$on("$destroy", function(){
                element.off("click");
            });
        }
    };
}])