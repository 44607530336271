/**
 * Created by kevin.tiller on 6/12/2018.
 */

import * as ContextSelectorLinkComponent from "./contextSelectorLink.component";

declare var angular;
export const CONTEXT_SELECTOR_LINK_MODULE = angular.module("AccountApp.v2.Components.ContextSelectorLink", []);

export class ContextSelectorLinkConfig {

    static $inject = ["$compileProvider"];

    constructor($compileProvider) {
        const components = [ContextSelectorLinkComponent];
        components.forEach(component => {
            $compileProvider.component(component.NAME, component.OPTIONS);
        });
    }
}

CONTEXT_SELECTOR_LINK_MODULE.config(ContextSelectorLinkConfig);