/**
 * Created by Roman on 2015-05-01.
 */



angular.module("flipto.components.common.textFormatting")
    .filter('truncate', [function () {

        return function (text, maxLength, wordwise, tail) {
            if (!text) return '';

            maxLength = parseInt(maxLength, 10);
            if (!maxLength) return text;
            if (text.length <= maxLength) return text;

            if (wordwise) {
                var lastSpace = text.lastIndexOf(' ', maxLength);
                text = text.substr(0, lastSpace != -1 ? lastSpace : maxLength);
            } else {
                text = text.substr(0, maxLength);
            }

            return text + (tail || '…');
        };
    }]);