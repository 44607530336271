import { BroadcastStoryFlowController } from "./broadcastStoryFlowController";
import { ShareType } from "../../broadcast/shareType";
import { StoryDescriptor } from "../../broadcast/storyDescriptor";

export class BroadcastStoryFromStoryModalFlowController extends BroadcastStoryFlowController {

    static $inject = [
        "$injector",

        "renderer",
        "propertyUuid",
        "storyUuid",
        "steps"
    ];
    daysUntilDate: any;

    constructor(
        public $injector,

        public renderer,
        public propertyUuid,
        public storyUuid,
        public steps
    ) {

        super(
            $injector,

            renderer,
            propertyUuid,
            storyUuid,
            null,
            steps
        );

        this.ftAzureStoredPhoto = this.$filter("ftAzureStoredPhoto");
        this.daysUntilDate = this.$filter("daysUntilDate");
        const state = this.$ngRedux.getState();
        this.property = this.SessionService.getProperty(this.propertyUuid);
        this.fullContext = `/${state.ui.context.organization}/${state.ui.context.singleProperty}`;

        this.$q.all([this.PhotoContest.details(this.propertyUuid, this.storyUuid),
        this.Layers.photocontest({ propertyId: this.propertyUuid })])
            .then(datas => {
                const story = datas[0],
                    photoContestLayer = datas[1];
                this.storyDescriptor = new StoryDescriptor(this.parse, story);
                this.storySharedTo = story.socialNetworksSharing.filter(n => this.parse.intOrDefault(n.totalShareTimes) > 0).map(n => this.parse.intOrDefault(n.socialNetworkType));
                this.contests = this.parse.arrayOrDefault(photoContestLayer.contests);
                this.isQuoteOnly = !story.guest.poststay.photo;

                this.imageSetupOptions = this.createImageSetupOptionsFromStory(story);

                const hasActiveContests = !!this.contests && !!this.contests.find(c => this.parse.bool(c.isActive) && !this.parse.bool(c.isClosed) && this.daysUntilDate(c.endDate) >= 1);
                if (!hasActiveContests && !this.isQuoteOnly) {
                    this.startLeadShare();
                }

                this.$q.resolve(!!story.guest.poststay.photo ? this.PhotoContest.load({ contestId: story.guest.poststay.photo.photoContest.uuid, propertyUuid: this.propertyUuid }) : null)
                    .then(contest => {
                        this.contest = contest;
                        this.story = story;
                        this.isInitialized = true;
                    });

            })
    }

    close() {
        this.ftModal.destroy("account-v2.BroadcastStory");
        super.close();
    }

    chooseDifferentGoal() {
        this.moveTo(BroadcastStoryFlowController.STEP_NAME_CHOOSE_GOAL);
    }

    startLeadShare() {
        this.setShareType(ShareType.Story);
        this.moveTo(BroadcastStoryFlowController.STEP_NAME_STORY_IMAGE_SETUP);
    }

    startStorytellersShare(contest) {
        this.inviteToContest = contest;
        this.setShareType(ShareType.PhotoContestInvitation);
        this.moveTo(BroadcastStoryFlowController.STEP_NAME_PHOTOCONTEST_INVITATION_IMAGE_SETUP);
        //this.createShareImageTemplate();
    }

    startWebsiteInviteShare() {
        this.setShareType(ShareType.Website);
        this.moveTo(BroadcastStoryFlowController.STEP_NAME_STORY_IMAGE_SETUP);
    }

    returnToImageSetup() {
        this.moveTo(this.shareType == ShareType.Story || this.shareType == ShareType.Website ? BroadcastStoryFlowController.STEP_NAME_STORY_IMAGE_SETUP : BroadcastStoryFlowController.STEP_NAME_PHOTOCONTEST_INVITATION_IMAGE_SETUP);
    }

    finishImageSetup() {
        this.moveTo(BroadcastStoryFlowController.STEP_NAME_CHOOSE_NETWORK);
    }

}
