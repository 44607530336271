/**
 * Created by roman.kupin on 3/1/2018.
 * Taken from website' directive originally created by Mr. Kevin
 */


declare let angular;
declare let Waypoint;

export const NAME = "ftPositionalClass";

export const DEFINITION = [function () {

    return {
        restrict: 'A',
        link($scope, $element, $attrs) {
            const addClass = $attrs.ftPositionalClass;

            /**
             * n1 - creates 1 waypoint. "on", when triggered simply adds or removes class based on direction
             */

            const on = $attrs.ftPositionalClassOn;

            createWaypoint(on, function (direction) {
                if (direction === "up") {
                    this.element.classList.remove(addClass);
                } else if (direction === "down") {
                    this.element.classList.add(addClass);
                }
            });

            function createWaypoint(offset, action) {
                new Waypoint({
                    handler: action,
                    element: $element[0],
                    continuous: true,
                    offset: offset
                });

                Waypoint.refreshAll();
            }

        }
    };
}];
