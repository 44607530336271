/**
 * Created by ostapenko.r on 10/13/2014.
 */
angular.module('flipto.components.common.navigationPane', [])
    .directive('ftNavPane', [function () {
        return {
            restrict: 'E',
            templateUrl: '/app/components/common/navigation-pane/navigationPane.html',
            transclude: true,
            required: 'ftNavPane',
            link: function (scope, element, attrs, ftNavPane) {

            },
            controller: ["$scope", function($scope) {
                $scope.screen = {
                    name: ''
                };
                this.getScreen = function(){
                    return $scope.screen;
                }
            }]
        };
    }])
    .directive('ftScreen', ['$compile', function(){
        return{
            restrict: 'E',
            compile: function(element, attrs){
                var wrapper = angular.element('<div></div>');
                wrapper.attr('ng-show', "screen.name === '" + attrs.name + "'");
                wrapper.html(element.html());
                var e = angular.element(wrapper);
                element.html(e[0].outerHTML);
                return function() { };
            }
        }
    }]);