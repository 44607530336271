import { ExperienceLicensePermission } from 'account-hybrid/features/experiences/enums/experience-license-permission.enum';

export enum LicenseType {
    None = 0,
    Advocacy = 10,
    Discovery = 11,
    Experiences = 12
}

export interface License {
    LicenseId: LicenseType;
    Permissions: ExperienceLicensePermission;
}
