import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    PASSWORD_STRENGTH_CONFIG,
    PASSWORD_STRENGTH_DEFAULT_CONFIG,
    PasswordStrengthConfig
} from "./config/password-strength.config";
import { PasswordStrengthValidatorDirective } from "./directives/password-strength-validator.directive";
import { PasswordStrengthChecklistComponent } from "./components/password-strength-checklist/password-strength-checklist.component";
import { PasswordStrengthService } from "./services/password-strength.service";
import { AccountSharedModule } from 'apps/account/src/account-shared';


@NgModule({
    declarations: [
        PasswordStrengthValidatorDirective,
        PasswordStrengthChecklistComponent
    ],
    imports: [
        CommonModule,
        AccountSharedModule
    ],
    exports: [
        PasswordStrengthValidatorDirective,
        PasswordStrengthChecklistComponent
    ]
})
export class PasswordStrengthModule {
    static forRoot(config: Partial<PasswordStrengthConfig>): ModuleWithProviders<PasswordStrengthModule> {
        return {
            ngModule: PasswordStrengthModule,
            providers: [
                {
                    provide: PASSWORD_STRENGTH_DEFAULT_CONFIG,
                    useValue: {
                        minLength: 6,
                        maxLength: 32,
                        minNumbersLength: 0,
                        minSpecialsLength: 0,
                        minUppercaseLength: 0,
                        minLowercaseLength: 0,
                        numberOrSpecials: false,
                        noSpaces: true,
                    }
                },
                {
                    provide: PASSWORD_STRENGTH_CONFIG,
                    useValue: config
                },
                PasswordStrengthService
            ]
        };
    }

    static forChild(): ModuleWithProviders<PasswordStrengthModule> {
        return {
            ngModule: PasswordStrengthModule
        };
    }
}

