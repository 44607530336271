import { Component, Input, forwardRef, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import cssFormatMonaco from 'css-format-monaco';
import { MonacoEditorConstructionOptions, MonacoStandaloneCodeEditor } from '@materia-ui/ngx-monaco-editor';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';
import { SANITIZE_OPTIONS } from '../constants/sanitize-options.const';
import * as insane from 'insane';

declare const monaco;


@Component({
    selector: 'ft-code-editor',
    templateUrl: './code-editor.component.html',
    styleUrls: ['./code-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => CodeEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: CodeEditorComponent
        }
    ]
})
export class CodeEditorComponent extends ModalEditorBase implements OnInit {
    @Input() language: 'html' | 'css' | 'json' | 'javascript' | 'typescript';
    @Input() height = 480;
    editor: MonacoStandaloneCodeEditor;
    monacoOptions: MonacoEditorConstructionOptions = {
        formatOnPaste: true,
        formatOnType: true,
        autoIndent: 'keep',
        language: 'text/plain',
        hover: {
            delay: 3000
        }
    };

    constructor() {
        super();
    }

    ngOnInit() {
        this.monacoOptions.language = this.language;``
    }

    setEditor(editor) {
        this.editor = editor;
    }

    formatContent() {
        if (this.language === 'css') {
            cssFormatMonaco(monaco);
        }
        setTimeout(() => {
            this.editor.getAction('editor.action.formatDocument').run();
        }, 100);
    }

    applyChanges() {
        if (this.language === 'html') {
            this.value = insane(this.value, SANITIZE_OPTIONS);
        }
        super.applyChanges();
    }
}
