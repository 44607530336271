/**
 * Created by ostapenko.r on 10/21/2014.
 */



angular
    .module('flipto.components.common.datePicker', [])
    .directive('ftDatePicker',
        [
            function () {
                return {
                    restrict: 'E',
                    template: '<div class="ft-dropdown date-range"><span>{{"flipto.components.common.dateRange.SetDate" | translate}}</span> <i class="ft-icn-caret-down"><i></div>',
                    scope: {
                        'date': '=?',
                        'notifyDateChanged': '&onDateChanged',
                        'position': '@?'
                    },
                    link: function (scope, element, attrs) {
                        var dataFormat = attrs.dataFormat || 'YYYY-MM-DD';
                        var displayFormat = attrs.displayFormat || 'MMMM D, YYYY';

                        var momentDate = moment(scope.date || undefined);
                        scope.$watch('date', function (date) {
                            if (date) {
                                momentDate = moment(scope.date);
                                showDate(momentDate);
                            }
                        });

                        initialize();

                        function initialize() {
                            var options = {
                                startDate: momentDate,
                                singleDatePicker: true
                            };
                            if (angular.isDefined(scope.position)) {
                                options.opens = scope.position;
                            }
                            var dp = $(element).daterangepicker(options,
                                function (start, end) {
                                    scope.$apply(function () {
                                        momentDate = start;
                                        scope.date = momentDate.format(dataFormat);
                                        scope.notifyDateChanged({ date: momentDate.toDate() });
                                        showDate(momentDate);
                                    });
                                });
                            dp.data("daterangepicker").show();
                            dp.data("daterangepicker").hide();
                        }

                        function showDate(date) {
                            if (angular.isDefined(date) == false) return;
                            angular.element(element).find('span').html(date.format(displayFormat));
                            initialize();
                        }
                    }
                };
            }
        ]);

