/**
 * Created by ostapenko.r on 10/1/2014.
 */


angular.module('flipto.core.api.settingsNotifications', [])
    .config(['$provide', function ($provide) {
        $provide.factory('SettingsNotifications', ['urlFormatter', '$http', "parse", function (urlFormatter, $http, parse) {

            /**
             * SettingsNotifications service
             * @constructor
             */
            function SettingsNotifications() {
                /**
                 * Get property notifications
                 * @param params
                 * @returns {*}
                 */
                this.get = function (params) {
                    return $http.get(urlFormatter.format('/api/:propertyId/properties/:propertyId/settings/notifications', params))
                        .then(function (response) {
                            var data = response.data;
                            data.notifications = parse.arrayOrDefault(data.notifications);
                            return data;
                        });
                };

                /**
                 * Update property notifications
                 * @param params
                 * @param model
                 * @returns {*}
                 */
                this.put = function (params, model) {
                    return $http.put(urlFormatter.format('/api/:propertyId/properties/:propertyId/settings/notifications', params), model)
                        .then(function (response) {
                            return response.data;
                        });
                };

                /**
                 * Search through a property's sent emails
                 * @param {params} params 
                 * @param {model} model 
                 * @returns {*}
                 */
                this.searchEmails = function (params) {
                    return $http.get(urlFormatter.format('/api/:propertyId/properties/:propertyId/settings/notifications/sent', params, true))
                        .then(function (response) {
                            return response.data;
                        });
                };

                this.getEmail = function (params) {
                    return $http.get(urlFormatter.format('/api/:propertyId/properties/:propertyId/settings/notifications/email', params, true))
                        .then(function (response) {
                            return response.data;
                        });
                };
                

                /**
                 * Start a marketing campaign
                 * @param {params} params 
                 * @param {model} model 
                 * @returns {*}
                 */
                this.startCampaign = function (params) {
                    return $http.put(urlFormatter.format('/api/:propertyId/properties/:propertyId/settings/notifications/start', params), params)
                        .then(function (response) {
                            return response.data;
                        });
                };
            }

            return new SettingsNotifications();
        }]);
    }]);
