
export const AREAS_REGISTRY = "AreasRegistry";

export class RegistryItem {

    activeWhen?: string;
    isDisabled = false;
    icon: string;

    constructor(public name: string, public $class: string, public order?: number, public divider?: boolean) {
    }
}

export class StateRegistryItem extends RegistryItem {

    onBefore?: any;

    constructor(name, $class, public state: string, public stateParams?: any, order?: number, divider?: boolean) {
        super(name, $class, order, divider);
    }
}

export class HandlerRegistryItem extends RegistryItem {
    constructor(name, $class, public handler: any, order?: number) {
        super(name, $class, order);
    }
}

export class MultipleOptionsRegistryItem extends RegistryItem {
    constructor(name, $class, public options: RegistryItem[], order?: number, divider?: boolean) {
        super(name, $class, order, divider);
    }
}



export class AreasRegistry {
    areas: RegistryItem[] = [];

    add(item: RegistryItem) {
        if (item instanceof MultipleOptionsRegistryItem) {
            let existing = this.areas.find(i => i.name === item.name) as MultipleOptionsRegistryItem;
            if (!existing) {
                this.areas.push(item);
                this.areas = this.sortAreas(this.areas);
            } else {
                item.options.forEach(i => existing.options.push(i));
            }
            existing = this.areas.find(i => i.name === item.name) as MultipleOptionsRegistryItem;
            existing.options = this.sortAreas(existing.options);
        } else {
            this.areas.push(item);
            this.areas = this.sortAreas(this.areas);
        }
    }

    private sortAreas(areas: RegistryItem[]) {
        return areas.sort((a, b) => {
            if (a.order > b.order) { return 1; }
            return -1;
        });
    }
}
