/**
 * Created by Roman on 2014-09-22.
 */


angular.module('flipto.components.fieldEditors')
    .config(['$provide', '$compileProvider', function ($provide, $compileProvider) {

        $compileProvider.directive('ftTextField', [function () {
            return {
                restrict: 'E',
                scope: {
                    'datasource': '='
                },
                templateUrl: '/app/components/field-editors/textField.html',
                link: function (scope, elem, attrs) {
                    scope.isMultiLanguage = angular.isArray(scope.datasource);
                    scope.languages = scope.isMultiLanguage ? scope.datasource : [];
                    scope.fields = adjustFields(scope.$eval(attrs.fields) || []);
                    scope.required = (angular.isDefined(attrs.required) && attrs.required!=='false') || false;
                    scope.type = attrs.type || 'text';
                    scope.minLength = attrs.ngMinlength && scope.$eval(attrs.ngMinlength) || 0;
                    scope.maxLength = attrs.ngMaxlength && scope.$eval(attrs.ngMaxlength) || 1024;
                    scope.pattern = attrs.pattern || '';

                    /**
                     * Returns fields array as objects
                     * @param fields
                     * @returns {Array}
                     */
                    function adjustFields(fields) {
                        return _.map(fields, function (field) {
                            if (angular.isString(field) == true) {
                                return {name: field, label: null};
                            }
                            return field;
                        });
                    }
                }
            };
        }]);
    }]);