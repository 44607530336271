<ft-modal-editor-wrapper>
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>

    <ng-container *ngIf="value?.length; else emptyMsg">
        <ng-container *ngFor="let item of value; let n = index">
            <div class="mb-2 v-center h-between">
                {{bindLabel ? item[bindLabel] : item}}
                <div>
                    <ft-language-variations *ngIf="languageVariationsView && languages?.length"
                                            [fieldName]="fields?.[0]?.name"
                                            [languages]="languages"
                                            [value]="item[bindVariations]"
                                            (activeVariationsChange)="onActiveVariationChanged($event, n)">
                    </ft-language-variations>
                    <button class="ft2-btn-icon -dark ml-1" (click)="removeItem(n)">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
            <div class="mb-4">
                <ng-container *ngIf="item[bindVariations]?.length && fields?.length">
                    <ng-container *ngFor="let model of item[bindVariations]; let i = index; trackBy: trackByFn">
                        <ng-container *ngFor="let field of fields">
                            <ng-container
                                *ngIf="(languageVariationsView && isVariationActive(model?.[field?.languageCode], n))">
                                <ng-container
                                    *ngTemplateOutlet="editorTmpl; context: { variations: item[bindVariations], model, field, i, n, isVariation: true }"></ng-container>
                            </ng-container>

                            <ng-container
                                *ngIf="!languageVariationsView">
                                <ng-container
                                    *ngTemplateOutlet="editorTmpl; context: { variations: item[bindVariations], model, field, i, n, isVariation: false }"></ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>

    <ng-template #editorTmpl let-field="field" let-variations="variations" let-model="model" let-i="i" let-n="n" let-isVariation="isVariation">
        <div class="ft2-input-container"
             [ngClass]="{'-pure': !model[field?.label] && !model[field?.languageName]}">
            <div *ngIf="model[field?.label]"
                 (click)="ref?.focus()"
                 class="ft2-input-label">
            </div>
            <div class="d-flex f-nowrap v-center">
                <input class="ft2-input -large"
                       [ngClass]="{'-pure': model[field?.label] || model[field?.languageName]}"
                       #ref
                       #input="ngModel"
                       (ngModelChange)="onModelChange($event, n, i, field.name)"
                       [ngModel]="model[field.name]"
                       [ngModelOptions]="{standalone: true}"
                       [minlength]="(validationDefinition && validationDefinition[i])?.minLength || (validationDefinition && validationDefinition[0]?.minLength)"
                       [maxlength]="(validationDefinition && validationDefinition[i])?.maxLength || (validationDefinition && validationDefinition[0]?.maxLength)"
                       [min]="(validationDefinition && validationDefinition[i])?.min || (validationDefinition && validationDefinition[0]?.min)"
                       [max]="(validationDefinition && validationDefinition[i])?.max || (validationDefinition && validationDefinition[0]?.max)"
                       [placeholder]="placeholder"
                       [required]="(validationDefinition && validationDefinition[i])?.required || (validationDefinition && validationDefinition[0]?.required)"
                       [pattern]="(validationDefinition && validationDefinition[i])?.pattern || (validationDefinition && validationDefinition[0]?.pattern)"
                       [type]="(validationDefinition && validationDefinition[i])?.type || (validationDefinition && validationDefinition[0]?.type)"
                       [name]="(validationDefinition && validationDefinition[i])?.name || (validationDefinition && validationDefinition[0]?.name)">
                <span class="ft2-input-language"
                      [ngClass]="{ '-clickable': isValueRemovable(i) && isVariation }"
                      *ngIf="(getActiveVariationsCount(n) > 1 && isVariation) || (variations?.length > 1 && !isVariation)">
                            {{ model[field?.languageName] || languageNameByCode(model.languageCode) }}
                    <button *ngIf="isValueRemovable(i, n) && isVariation"
                            class="ft2-btn-close -inline -round" (click)="removeValue(i, field.name, n)">
                    </button>
                </span>
            </div>
        </div>

        <div class="ft2-form-error mt-2"
             *ngIf="input?.invalid && input?.touched">
            <p *ngIf="input.errors.required">
                Value is required.
            </p>
            <p *ngIf="input.errors.minlength">
                Value must be at least {{ validationDefinition[i]?.minLength || validationDefinition[0]?.minLength }} characters long.
            </p>
            <p *ngIf="input.errors.maxlength">
                Value must be at most {{ validationDefinition[i]?.maxLength || validationDefinition[0]?.maxLength }} characters long.
            </p>
            <p *ngIf="input.errors.email">
                Value is not valid email address.
            </p>
            <p *ngIf="input.errors.pattern">
                {{
                    validationDefinition[i]?.patternValidationText || validationDefinition[0]?.patternValidationText
                        ? validationDefinition[i]?.patternValidationText || validationDefinition[0]?.patternValidationText
                        : 'Value doesn\'t not match pattern.' + validationDefinition[i]?.pattern || validationDefinition[0]?.pattern
                }}
            </p>
            <p *ngIf="input.errors.min">
                Value can't be less then {{ validationDefinition[i]?.min || validationDefinition[0]?.min }}
            </p>
            <p *ngIf="input.errors.max">
                Value can't be more then {{ validationDefinition[i]?.max || validationDefinition[0]?.max }}
            </p>
        </div>
    </ng-template>

    <ng-template #emptyMsg>
        <div class="ft2-edit-dialog_modal mt-3">
            {{ emptyMsgText }}
        </div>
    </ng-template>

    <div class="ft2-edit-dialog_footer-control mr-3">
        <ng-container *ngIf="availableItems?.length">
            <button class="ft2-btn-light -large -outline"
                    #popoverContainer
                    #popover="ngbPopover"
                    [ngbPopover]="listTmpl"
                    [autoClose]="'outside'"
                    placement="bottom left"
                    [triggers]="'click'">
                {{ addBtnText }}
            </button>
            <ng-template #listTmpl>
                <!--   16px === 1rem padding inside popover for both sides     -->
                <div [style]="{'min-width': popoverContainer?.clientWidth - 16 + 'px'}"
                     class="ft2-btn-list"
                     *ngFor="let item of availableItems">
                    <button (click)="addItem(item); popover.close();"
                            class="ft2-btn-text">
                        {{ item[bindLabel] }}
                    </button>
                </div>
            </ng-template>
        </ng-container>
    </div>
</ft-modal-editor-wrapper>
