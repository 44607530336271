angular.module('flipto.components.common.tasks')
    .config(['$provide', '$compileProvider',
        function ($provide, $compileProvider) {
            $compileProvider.directive('ftDisableWhileTaskPending', ['ftTasks', function (ftTasks) {

                return function (scope, elem, attrs) {
                    var taskScope = {};
                    taskScope.taskName = attrs.ftDisableWhileTaskPending;

                    attrs.$observe('ftDisableWhileTaskPending', function (newTaskName) {
                        taskScope.taskName = newTaskName;
                    });


                    scope.$watch(function () {
                        return ftTasks.isPending(taskScope.taskName);
                    }, function (isPending) {
                        elem[!!isPending ? 'attr' : 'removeAttr']('disabled', 'disabled');
                        elem.css('pointer-events', isPending ? 'none' : '');
                    });

                };
            }]);
        }
    ]);