
export function unAuthorizedInterceptor($q, $injector) {
    return {
        'responseError': function (response) {
            if (response.status === 401) {
                const sessionService = $injector.get("SessionService");
                sessionService.signOut();
            }
            return $q.reject(response);
        }
    };
}

export namespace unAuthorizedInterceptor {
    export let $inject = [
        "$q", "$injector"
    ];
}