import { IComponentFlowBuilder, FlowStep } from "..";


export class BroadcastStoryFromStoryModalComponentFlowBuilder implements IComponentFlowBuilder {

    createSteps(): FlowStep[] {
        return [
            new FlowStep("ChooseGoal",
                `<div>
                    <button class="ft-btn-close" ng-click="$ftFlow.close()"></button>
                
                    <div class="ft-modal-view choose-goal">
                        <h1 class="modal-heading">What's your goal for this broadcast?</h1>
                        <ft-broadcast-choose-goal story="$ftFlow.story" contests="$ftFlow.contests" on-invite-to-story="$ftFlow.startLeadShare()" on-invite-to-contest="$ftFlow.startStorytellersShare(contest)" on-invite-to-website="$ftFlow.startWebsiteInviteShare()"></ft-broadcast-choose-goal>
                    </div>
                </div>`,
                true
            ),
            new FlowStep("StoryShareImageSetup",
                `<div>
                    <button class="ft-btn-close" ng-click="$ftFlow.close()"></button>
                    <a class="ft-btn ft-btn-outline-light ft-btn-back" ng-if="!!$ftFlow.contests" ng-click="$ftFlow.chooseDifferentGoal()">
                        Choose a different goal
                    </a>
                
                    <div class="ft-modal-view image-setup">
                        <h1 class="modal-heading">Let's set up your content.</h1>
                        <p class="modal-summary">
                            Use the editor to set up content that will display with your broadcast. The final appearance may be adjusted for the channels you select in the next step.
                        </p>
                        
                        <ft-story-image-setup 
                                story="$ftFlow.story" 
                                property="$ftFlow.property"
                                options="$ftFlow.imageSetupOptions"
                        ></ft-story-image-setup>
                    
                    <button type="button" class="ft-btn ft-btn-plus-1 ft-btn-default ft-btn-confirm" ng-click="$ftFlow.finishImageSetup()">
                        Continue
                    </button>

                    </div>
                </div>`
            ),
            new FlowStep("PhotocontestInvitationShareImageSetup",
                `<div>
                    <button class="ft-btn-close" ng-click="$ftFlow.close()"></button>
                    <a class="ft-btn ft-btn-outline-light ft-btn-back" ng-click="$ftFlow.chooseDifferentGoal()">
                        Choose a different goal
                    </a>
                
                    <div class="ft-modal-view image-setup">
                        <h1 class="modal-heading">Let's set up your content.</h1>
                        <p class="modal-summary">
                            Use the editor below to set up content to display to your audience. The final appearance may differ
                            based on the channels you select in the next step.
                        </p>

                        <ft-story-image-setup 
                                story="$ftFlow.story" 
                                property="$ftFlow.property"
                                options="$ftFlow.imageSetupOptions"
                        ></ft-story-image-setup>
                        
                        <!--<ft-photocontest-invitation-image-setup 
                                template="$ftFlow.shareImageTemplateData.template"
                                on-reload="$ftFlow.createShareImageTemplate()"
                        ></ft-photocontest-invitation-image-setup>-->
                    
                    <button type="button" class="ft-btn ft-btn-plus-1 ft-btn-default ft-btn-confirm" ng-click="$ftFlow.finishImageSetup()">
                        Continue
                    </button>

                    </div>
                </div>`
            ),
            new FlowStep("ChooseNetwork",
                `<div>
                    <button class="ft-btn-close" ng-click="$ftFlow.close()"></button>
                    <a class="ft-btn ft-btn-outline-light ft-btn-back" ng-click="$ftFlow.returnToImageSetup()">
                        Return to set up your content
                    </a>
                    <div class="ft-modal-view choose-channel">
                        <h1 class="modal-heading">Which channel do you want to broadcast to?</h1>
            
                        <ft-broadcast-choose-network
                                shared-to="$ftFlow.storySharedTo"
                                on-open-share-modal="$ftFlow.openShareModal(network)"
                        ></ft-broadcast-choose-network>
                    
                        <p class="ft-share-to-links-caption">
                            A preview will be provided on the next step.
                            <br /> 
                            You can also broadcast this story multiple times&mdash;we'll return to this screen
                            after you're done with a channel.
                        </p>
                    </div>
                </div>`
            ),
            new FlowStep("CreateImageProgress",
                `<div>
                    <button class="ft-btn-close" ng-click="$ftFlow.close()"></button>
                    <div class="ft-modal-view rendering-image">
                        <div class="ft2-spinner -large"></div>
                        <h1 class="modal-heading">{{'flipto.account.CreatingImage' | translate}}</h1>
                    </div>
                </div>`
            ),
            new FlowStep("ShareImageReady",
                `<div>
                    <button class="ft-btn-close" ng-click="$ftFlow.close()"></button>
                    <a class="ft-btn ft-btn-outline-light ft-btn-back" ng-click="$ftFlow.chooseDifferentChannel()">Choose a different
                        channel.</a>
            
                    <ft-broadcast-share-image-ready
                        first="$ftFlow.story.guest.first"
                        share-image-url="$ftFlow.shareImageUrl"
                        network="$ftFlow.network"
                        share-url="$ftFlow.shareUrl"
                        share-type="$ftFlow.shareType"
                        on-reprocess-image="$ftFlow.reprocessImage()"
                        on-download-share-image="$ftFlow.downloadShareImage()"
                        on-share="$ftFlow.openOAuth(message)"
                        is-recommendation-setup="false"
                    ></ft-broadcast-share-image-ready>

                </div>`
            )
        ];
    }

    createTemplate(steps: FlowStep[]): string {
        return `
        <ft-modal id="account-v2.BroadcastStory" class="ft-broadcast-modal" done-visible="false" cancel-visible="false" size="full">
            <ft-initialized-content is-initialized="$ftFlow.isInitialized">
                <ft-flow>
                    ${steps.map(s => `<ft-step name='${s.name}'>${s.template}</ft-step>`).join("")}
                </ft-flow>
            </ft-initialized-content>
        </ft-modal>
        `;
    }
}

