/**
 * Created by roman.kupin on 2/9/2018.
 */


export class ContestContainerService {

    static Name = "ContestContainer";
    static $inject = ["$compile", "$rootScope"];

    private _scope: any;
    private _elem: any;

    constructor(private $compile,
                private $rootScope) {
    }

    show(propertyUuid, contestUuid, activeSection) {
        this.ensureElement();
        Object.assign(this._scope, {
            $ftContestContainer: {
                propertyUuid,
                contestUuid,
                activeSection
            }
        });
    }

    hide() {
        this.ensureElement();
        this._scope.$ftContestContainer = null;
    }

    private ensureElement() {
        if (!this._scope) {
            this.insertElement();
        }
    }

    private insertElement() {
        this._scope = this.$rootScope.$new(true);
        this._elem = this.$compile(`
<ft-contest-container 
    property-uuid="$ftContestContainer.propertyUuid"
    contest-uuid="$ftContestContainer.contestUuid"
    active-section="$ftContestContainer.activeSection"
    ng-if="!!$ftContestContainer"></ft-contest-container>`)(this._scope);

        document.body.appendChild(this._elem[0]);
        this._scope.$on("$destroy", () => {
            this._elem.remove();
            this._scope = null;
        });
    }

}