<ft-modal-wrapper>
  <ft-touchpoint-header
    name="All other guests"
    [activeToggleEnabled]="false"
    >
  </ft-touchpoint-header>
  <div class="ft2-section">
    <div class="ft2-container text-dark">
      <ft-snapshot-form
        *ngIf="campaignGuestsData$ | async | clone as data"
        [loading$]="selectedCampgainGuestsLoading$"
        [commitSuccess$]="commitSuccess$"
        (commit)="onFormCommit($event, data)">
          <form ngForm>
            <div class="row">
              <div class="col-4 text-left">
                <p class="solid-text medium-text">
                  Share message
                </p>
              </div>
              <div  class="col-8">
                <ft-textarea-editor
                  *ngFor="let message of data.messages.item | orderBy:'isActive':'1'"
                  title="Share message"
                  [(ngModel)]="message.languages.item[0].text"
                  [tokens]="[
                    {name: 'property', value: '{property}'},
                    {name: 'city', value: '{city}'}
                  ]"
                  type="text"
                  required
                  name="text">
                    <ft-edit-panel>
                      <p class="text-left">{{message.languages.item[0].text}}</p>
                    </ft-edit-panel>
                </ft-textarea-editor>
  
                <ft-textarea-editor
                  [(ngModel)]="newMessage"
                  [tokens]="[
                    {name: 'property', value: '{property}'},
                    {name: 'city', value: '{city}'}
                  ]"
                  secondButton="Delete message"
                  type="text"
                  required
                  name="messages"
                  (save)="addMessage($event)"
                  (action)="deleteMessage($event)">
                  <ng-template #title>
                    <div class="v-center">
                      <label>Share message</label>
                      <mat-slide-toggle
                        class="ml-3"
                        name="isActive"
                        [(ngModel)]="data.isActive"
                        #control="ngModel"
                      ></mat-slide-toggle>
                    </div>
                  </ng-template>
                  <div class="text-left">
                    <button class="ft2-btn-default">
                      <mat-icon svgIcon="plus"></mat-icon> Add message variation
                    </button>
                  </div>
                </ft-textarea-editor>
              </div>
            </div>
            <hr class="mb-6">
            <div class="row">
              <div class="col-4 text-left">
                <p class="solid-text medium-text">
                  Goals
                </p>
                <p class="medium-text muted-text">
                  Set the incentive that advocates or warm leads can earn when they reach a certain goal.
                </p>
              </div>
              <div  class="col-8">
                <ft-campaign-goals
                  [goals]="data.goals.item"
                  layer="prestay"
                ></ft-campaign-goals>
              </div>
            </div>
          </form>
      </ft-snapshot-form>
    </div>
  </div>
</ft-modal-wrapper>
