export enum ExperienceLicensePermission {
    None = 0,    
    TravelWorthy = 1 << 1,
    PopularityScore_1 = 1 << 2,
    PopularityScore_2 = 1 << 3,
    PopularityScore_3 = 1 << 4,
    PopularityScore_4 = 1 << 5,
    PopularityScore_5 = 1 << 6,
    PopularityScore_6 = 1 << 7,
    Local = PopularityScore_1 | PopularityScore_2 | PopularityScore_3 | PopularityScore_4 | PopularityScore_5 | PopularityScore_6,
    AllOG = Local | TravelWorthy
}
