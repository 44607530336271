<ft-modal-wrapper>
  <ft-touchpoint-header
    [name]="options.touchpointTitle"
    [canActivate]="true"
    [activeToggleEnabled]="options.activeToggleEnabled"
    [isActive]="(selectedFriendLandingComponent$ | async)?.isActive == 1"
    (activeToggled)="commit($event)"
    >
  </ft-touchpoint-header>
  <mat-tab-group
    class="mat-tab-group-nav"
    mat-align-tabs="center"
    animationDuration="0ms"
    [(selectedIndex)]="selectedIndex"
    (selectedTabChange)="selectTab($event, options.matNav.integration, integrationFriendLandingComponent$)">
    <mat-tab *ngIf="options.matNav.message" label="messaging">
      <ng-template matTabContent>
        <ft-snapshot-form
          *ngIf="selectedFriendLandingComponent$ | async | clone as data"
          [loading$]="[selectedFriendLandingLoading$]"
          [commitSuccess$]="[commitSuccess$]"
          (commit)="onFormCommit($event, 'data')"
          (viewInit)="createComponent(options.matNav.message, data)">
          <section class="ft2-section">
            <div class="ft2-container -wide">
              <form ngForm>
                <ng-container *ngIf="!options.matNav.message.template; else otherTemplate">
                  <ft-sidebar-tabs *ngIf="options.matNav.message.sidebar" [classes]="['ft2-journey-editor-nav']">
                    <ft-sidebar-tab *ngFor="let item of options.matNav.message.sidebar" [name]="item.tabName">
                      <div>
                        <div class="ft2-browser py-4 -dark"></div>
                        <div class="ft2-container ft2-edit-panel f-column p-6 py-9 -static mb-0">
                            <div class="ft2-edit-panel -static">
                              <ft-html-editor
                                *ngFor="let field of item.fields"
                                [title]="field.title + ' message'"
                                [tokens]="field.tokens"
                                [name]="field.name"
                                [(ngModel)]="data?.languages?.item[0].data[field.name]">
                                <div [innerHTML]="data?.languages?.item[0].data[field.name]"></div>
                              </ft-html-editor>
                            </div>
                        </div>
                      </div>
                    </ft-sidebar-tab>
                  </ft-sidebar-tabs>
                </ng-container>
                <ng-template #otherTemplate>
                  <ng-container #customTemplate></ng-container>
                </ng-template>
              </form>
            </div>
          </section>
        </ft-snapshot-form>
      </ng-template>
    </mat-tab>
    <mat-tab *ngIf="options.matNav.style" label="styling">
      <ng-template matTabContent>
        <ft-snapshot-form
          *ngIf="stylingFriendLandingComponent$ | async | clone as styleData"
          [loading$]="[selectedFriendLandingLoading$]"
          [commitSuccess$]="[commitSuccess$]"
          (commit)="onFormCommit($event, 'styling')">
          <section class="ft2-section">
            <div class="ft2-container -wide">
              <ft-sidebar-tabs *ngIf="options.matNav.style.sidebar" [classes]="['ft2-journey-editor-nav']">
                <ft-sidebar-tab *ngFor="let item of options.matNav.style.sidebar" [name]="item.tabName">
                  <form ngForm>
                    <ft-code-editor
                      *ngFor="let field of item.fields"
                      [name]="field.name"
                      [title]="field.title"
                      [(ngModel)]="styleData[field.name]"
                      [language]="'css'">
                        <ft-edit-panel [label]="field.title">
                          <p>{{styleData[field.name] ? styleData[field.name] : 'Click to edit'}}</p>
                        </ft-edit-panel>
                    </ft-code-editor>
                  </form>
                </ft-sidebar-tab>
              </ft-sidebar-tabs>
              <form *ngIf="!options.matNav.style.sidebar" ngForm>
                <ft-code-editor
                  *ngFor="let field of options.matNav.style.fields"
                  [name]="field.name"
                  [title]="field.title"
                  [(ngModel)]="styleData[field.name]"
                  [language]="'css'">
                    <ft-edit-panel [label]="field.title">
                      <p>{{styleData[field.name] ? styleData[field.name] : 'Click to edit'}}</p>
                    </ft-edit-panel>
                </ft-code-editor>
              </form>
            </div>
          </section>
        </ft-snapshot-form>
      </ng-template>
    </mat-tab>
    <mat-tab *ngIf="options.matNav.integration" label="integration">
      <ng-template matTabContent>
        <section class="ft2" *ngIf="integrationFriendLandingComponent$ | async | clone as integrationData">
          <form class="ft2-block"
              #form="ngForm"
              ngForm>
            <ft-loading [loading$]="selectedFriendLandingLoading$"></ft-loading>
            <div class="ft2-container -wide">
              <ng-container *ngIf="!options.matNav.integration.template; else otherTemplate">
                  <div class="row">
                    <div class="col-4">
                        <p class="solid-text medium-text">
                          Configuration
                        </p>
                        <p class="base-text">
                          Choose where to send audiences after they click on a guest’s share link.
                        </p>
                    </div>
                  </div>
                  <ft-sidebar-tabs *ngIf="options.matNav.integration.sidebar" [classes]="['ft2-journey-editor-nav']">
                    <ft-sidebar-tab *ngFor="let item of options.matNav.integration.sidebar" [name]="item.tabName">
                      <p *ngIf="item.infoText">{{item.infoText}}</p>
                      <ft-input-editor
                        *ngIf="item?.fields?.inputUrl"
                        [title]="item.fields.inputUrl.title"
                        type="url"
                        [(ngModel)]="integrationData.languages?.item[0].data[item.fields.inputUrl.name]"
                        required
                        [name]="item.fields.inputUrl.name">
                        <ft-edit-panel [label]="item.fields.inputUrl.title" [summary]="item.fields.inputUrl.summary">
                          {{integrationData.languages?.item[0].data[item.fields.inputUrl.name] ? integrationData.languages?.item[0].data[item.fields.inputUrl.name] : 'Click to edit'}}
                        </ft-edit-panel>
                      </ft-input-editor>
                      <ft-code-editor
                        *ngIf="item?.fields?.jsonEditor"
                        [name]="item?.fields?.jsonEditor.name"
                        [title]="item?.fields?.jsonEditor.title"
                        [(ngModel)]="integrationData[item?.fields?.jsonEditor.name]"
                        [language]="'json'">
                          <ft-edit-panel [label]="item?.fields?.jsonEditor.title">
                            <p>{{integrationData[item?.fields?.jsonEditor.name] ? integrationData[item?.fields?.jsonEditor.name] : 'Click to edit'}}</p>
                          </ft-edit-panel>
                      </ft-code-editor>
                      <ft-custom-editor
                        *ngIf="item?.fields?.statusEditor && integrationData[item?.fields?.statusEditor.name] !== '1'"
                        title="Switch to integrated experience?"
                        summary="Your guests' friends will be directed to the integrated experience. The current experience will automatically be turned off."
                        buttonLabel="Make the switch"
                        (save)="onSave($event, engine)">
                        <ft-edit-panel [label]="item?.fields?.statusEditor.title" [disabled]="!allowToggleLandingPageType(1, integrationData.languages?.item)">
                          {{integrationData[item?.fields?.statusEditor.name] === '1' ? 'On': 'Off'}}
                        </ft-edit-panel>
                        <div content></div>
                      </ft-custom-editor>
                    </ft-sidebar-tab>
                  </ft-sidebar-tabs>
              </ng-container>
              <ng-template #otherTemplate>
                <ng-container #customTemplate></ng-container>
              </ng-template>
            </div>
          </form>
        </section>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</ft-modal-wrapper>
