/**
 * Created by roman.kupin on 10/3/2017.
 */



declare let angular;

export const NAME = "ftCarouselCard";

export class CarouselCardDirectiveController {
    index: number;
}

export const DEFINITION = [function () {
    return {
        controller: CarouselCardDirectiveController,
        require: ["^ftCarouselCards", "ftCarouselCard"],
        link: function (scope, elem, attrs, ctrls) {
            const cardsCtrl = ctrls[0];
            const cardCtrl = ctrls[1];
            const card = { elem: elem, isActive: attrs.hasOwnProperty("active"), name: attrs.ftCarouselCard };
            cardsCtrl.addCard(card);
            cardCtrl.index = cardsCtrl.cards.indexOf(card);
        }
    };
}];