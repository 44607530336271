import { Inject, Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { PropertyUser, PropertyUserDto } from '../models';
import { Environment, ENVIRONMENT_TOKEN } from '../../../core/environment.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PropertyUserDataService extends EntityCollectionServiceBase<PropertyUserDto> {

    constructor(serviceElementaryFactory: EntityCollectionServiceElementsFactory,
                @Inject(ENVIRONMENT_TOKEN) private environment: Environment) {
        super('PropertyUser', serviceElementaryFactory);
    }

    entities$: Observable<PropertyUser[]> = this.entities$.pipe(
        map(entities => {
            if (!entities) {
                return [];
            }
            return entities.map((entity) => {
                return new PropertyUser(entity);
            });
        })
    );
}
