/**
 * Created by roman.kupin on 9/28/2017.
 */

import * as PropertyApiActions from "../../actions/propertyApi";


export default function propertyAdvocacyOverviewReducer(state = {}, {type, payload}) {
    switch (type) {
        case PropertyApiActions.ADVOCACY_OVERVIEW_FETCH_SUCCESS: {
            return Object.assign({}, payload);
        }

        default: {
            return state;
        }
    }
}