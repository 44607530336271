/**
 * User: Kupin.R
 * Date: 5/23/14
 */



angular
    .module("flipto.components.common.textFormatting")
    .filter("capitalize",
        [
            function () {

                /**
                 * Capitalizes text
                 * @param text
                 * @param isSentence
                 * @returns {string}
                 */
                function capitalizeText(text, isSentence) {
                    if (!text) return "";
                    var lowerCase = isSentence ? text : text.toLowerCase();
                    return lowerCase[0].toUpperCase() + lowerCase.slice(1);
                }

                function capitalizeArray(array) {
                    if (!array) return array;
                    return array
                        .filter(function (element) { return !!element; })
                        .map(function (element) { return element.toLowerCase(); })
                        .map(function (element) { return element[0].toUpperCase() + element.slice(1); });
                }

                return function (source, isSentence) {
                    if (Array.isArray(source)) return capitalizeArray(source);
                    return capitalizeText(source, isSentence || false);
                }
            }
        ]);