import { Store } from '@ngrx/store';
import * as fromRoot from 'account-hybrid/store/reducers/index';
import _ from 'lodash';
import { Injectable } from '@angular/core';
import { signOut } from 'account-hybrid/store/actions/authentication.actions';
import { Company, Property, User } from '..';
import { LicenseType } from '../models/licenses';
import { CollaborativeType } from 'account-hybrid/features/account-settings/enums/collaborative-type.enum';
import { DiscoveryPropertySettingState } from 'shared';
import { ExperienceLicensePermission } from 'account-hybrid/features/experiences/enums/experience-license-permission.enum';
import { Subject } from 'rxjs';


declare const session;
// update session if we got it
if (typeof session !== 'undefined') {
    localStorage.setItem('flipto.session', JSON.stringify(session));
}

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    licenseChanged$ = new Subject();

    signOut() {
    }

    constructor(store: Store<fromRoot.State>) {
        // hack so that we dont store Store instance on session as angularjs tries to clone these objects which is slowing everything substantially
        this.signOut = () => {
            store.dispatch(signOut());
        };
    }

    get(): User | null {
        return getSession();
    }

    set(session: User | null) {
        localStorage.setItem('flipto.session', JSON.stringify(session));
    }

    get properties() {
        if (!this.get()) {
            return [];
        }
        return this.get().Properties;
    }

    get companies() {
        if (!this.get()) {
            return [];
        }
        return this.get().Companies;
    }

    getCompany(uuid: string) {
        return this.companies.find(c => c.UUID.toLocaleLowerCase() === (!!uuid ? uuid.toLocaleLowerCase() : null));
    }

    getProperty(uuid: string) {
        return this.properties.find(p => p.UUID.toLocaleLowerCase() === (!!uuid ? uuid.toLocaleLowerCase() : null));
    }

    getCompanyProperties(companyUuid: string) {
        const company = this.getCompany(companyUuid);
        return !!company ?
            // associations might contain properties not accessible to current session -> filter those out
            company.Associations.map(uuid => this.getProperty(uuid)).filter(p => !!p) :
            [];
    }

    addNewProperty(property: Property, companyUuid: string) {
        const userSession = _.cloneDeep(this.get());
        const company = userSession.Companies.find(c => c.UUID === companyUuid);
        company.Associations.push(property.UUID);
        userSession.Properties.push(property);
        clearSessionCache();
        this.set(userSession);
    }

    addNewCompany(company: Company) {
        const userSession = _.cloneDeep(this.get());
        userSession.Companies.push(company);
        clearSessionCache();
        this.set(userSession);
    }

    addCompanyPropertyAssociation(companyUuid: string, propertyUuid: string) {
        const userSession = _.cloneDeep(this.get());
        const company = userSession.Companies.find(c => c.UUID === companyUuid);
        if (!company.Associations.find(associatedPropertyUuid => associatedPropertyUuid === propertyUuid) &&
            userSession.Properties.find(p => p.UUID === propertyUuid)) {
            company.Associations.push(propertyUuid);
        }
        clearSessionCache();
        this.set(userSession);
    }

    onPasswordForcefullyChanged() {
        const userSession = _.cloneDeep(this.get());
        userSession.IsForceChangePassword = false;
        clearSessionCache();
        this.set(userSession);
    }

    onFeatureFlagChanged(companyUuid: string, featureFlag: string, value: boolean) {
        const userSession = _.cloneDeep(this.get());
        const company = userSession.Companies.find(c => c.UUID.toLocaleLowerCase() === companyUuid.toLocaleLowerCase());
        company.FeatureFlags[featureFlag] = value;
        clearSessionCache();
        this.set(userSession);
    }

    onCompanyLicenseChanged(companyUuid: string, license: LicenseType | number, isActive: DiscoveryPropertySettingState, permissions?: ExperienceLicensePermission) {
        const userSession = _.cloneDeep(this.get());
        const company = userSession.Companies.find(c => c.UUID.toLocaleLowerCase() === companyUuid.toLocaleLowerCase());
        if (isActive === DiscoveryPropertySettingState.Preview || isActive === DiscoveryPropertySettingState.Published) {
            company.Licenses = _.uniq([...company.Licenses, ...[license]]);
            company.LicensesExtended = _.uniqBy([...company?.LicensesExtended?.map(item => {
                return item.LicenseId === license
                    ? { ...item, LicenseId: license, Permissions: permissions }
                    : item;
            })], 'LicenseId');
        } else {
            _.remove(company.Licenses, l => l == license);
            _.remove(company?.LicensesExtended, l => l.LicenseId == license);
        }
        clearSessionCache();
        this.set(userSession);
        this.licenseChanged$.next(null);
    }

    onCollaborativeTypeChanged(companyUuid: string, collaborativeType: CollaborativeType) {
        const userSession = _.cloneDeep(this.get());
        const company = userSession.Companies.find(c => c.UUID.toLocaleLowerCase() === companyUuid.toLocaleLowerCase());
        company.Settings.CollaborativeTypeId = collaborativeType;
        clearSessionCache();
        this.set(userSession);
    }

    onPropertyLicenseChanged(propertyUuid: string, license: LicenseType | number, isActive: DiscoveryPropertySettingState, permissions?: ExperienceLicensePermission) {
        const userSession = _.cloneDeep(this.get());
        const property = userSession.Properties.find(p => p.UUID.toLocaleLowerCase() === propertyUuid.toLocaleLowerCase());
        if (isActive === DiscoveryPropertySettingState.Preview || isActive === DiscoveryPropertySettingState.Published) {
            property.Licenses = _.uniq([...property.Licenses, ...[license]]);
            property.LicensesExtended = _.uniqBy([...property?.LicensesExtended?.map(item => {
                return item.LicenseId === license
                    ? { ...item, LicenseId: license, Permissions: permissions }
                    : item;
            }), {
                LicenseId: license,
                Permissions: permissions
            }], 'LicenseId');
        } else {
            _.remove(property.Licenses, l => l == license);
            _.remove(property?.LicensesExtended, l => l.LicenseId == license);
        }
        clearSessionCache();
        this.set(userSession);
        this.licenseChanged$.next(null);
    }
}


let cachedSession;

function getSessionCached() {
    if (!cachedSession) {
        cachedSession = !!localStorage.getItem('flipto.session') ? JSON.parse(localStorage.getItem('flipto.session')) : null;
    }
    return cachedSession;
}

export function clearSessionCache() {
    cachedSession = null;
}

export function getSession(): User {
    return getSessionCached();
}

export function hasPermissionInProperty(propertyUuid, requiredPermission): boolean {
    const session = getSession();
    if (!session) {
        return false;
    }
    const property = session.Properties.find(p => p.UUID === propertyUuid);
    // tslint:disable-next-line: no-bitwise
    return property && (property.Permissions & requiredPermission) === requiredPermission;
}

export function hasPermissionInCompany(companyUuid, requiredPermission): boolean {
    const session = getSession();
    if (!session) {
        return false;
    }
    const company = session.Companies.find(c => c.UUID === companyUuid);
    // tslint:disable-next-line: no-bitwise
    return company && (company.Permissions & requiredPermission) === requiredPermission;
}

window['hasPermissionInProperty'] = hasPermissionInProperty;
