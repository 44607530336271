import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { select, Store } from "@ngrx/store";
import * as fromAuthentication from "../../store/index";
import { Actions, ofType } from "@ngrx/effects";
import { takeWhile } from "rxjs/operators";
import { AuthError } from "../../models/auth-error";
import { UserData } from "../../models/user-data";

@Component({
    selector: 'ft-create-password',
    templateUrl: './create-password.component.html',
    styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit, OnDestroy {
    @Input() token: string;
    @Input() userUuid: string;
    @Input() companyCode: string;
    tokenVerificationLoading$: Observable<boolean>;
    tokenVerificationError$: Observable<AuthError>;
    userData$: Observable<UserData>;
    resendUserError$: Observable<AuthError>;
    resendUserLoading$: Observable<boolean>;
    isUserResent$: Observable<boolean>;
    createPasswordLoading$: Observable<boolean>;
    createPasswordError$: Observable<AuthError>;
    changeUserLoading$: Observable<boolean>;
    changeUserError$: Observable<AuthError>;
    password: string;
    isAlive = true;


    constructor(private store: Store<fromAuthentication.AuthenticationState>,
                private actions$: Actions) {
        this.tokenVerificationLoading$ = this.store.pipe(select(fromAuthentication.isTokenVerificationLoading));
        this.tokenVerificationError$ = this.store.pipe(select(fromAuthentication.getTokenVerificationError));
        this.userData$ = this.store.pipe(select(fromAuthentication.getUserData));
        this.resendUserLoading$ = this.store.pipe(select(fromAuthentication.isResendUserLoading));
        this.resendUserError$ = this.store.pipe(select(fromAuthentication.getResendUserError));
        this.isUserResent$ = this.store.pipe(select(fromAuthentication.isUserResent));
        this.createPasswordLoading$ = this.store.pipe(select(fromAuthentication.isResetPasswordLoading));
        this.createPasswordError$ = this.store.pipe(select(fromAuthentication.getResetPasswordError));
        this.changeUserLoading$ = this.store.pipe(select(fromAuthentication.isChangeUserLoading));
        this.changeUserError$ = this.store.pipe(select(fromAuthentication.getChangeUserError));
    }

    ngOnInit() {
        this.verifyToken();
    }

    ngOnDestroy() {
        this.isAlive = false;
    }

    submit(formValue) {
        this.changePassword();
        this.actions$.pipe(
            takeWhile(() => this.isAlive),
            ofType(fromAuthentication.resetPasswordSuccess),
        ).subscribe(() => {
            this.changeUser(formValue);
        });
    }

    changeUser({firstName, lastName}) {
        this.store.dispatch(fromAuthentication.changeUser({
                data: {
                    firstName,
                    lastName
                }
            })
        );
        this.actions$.pipe(
            takeWhile(() => this.isAlive),
            ofType(fromAuthentication.changeUserSuccess, fromAuthentication.changeUserFailure),
        ).subscribe(() => {
            this.store.dispatch(fromAuthentication.enterSite());
        });
    }

    changePassword() {
        this.store.dispatch(fromAuthentication.resetPassword({
                data: {
                    password: this.password,
                    token: this.token,
                    userUuid: this.userUuid
                }
            })
        );
    }

    verifyToken() {
        this.store.dispatch(fromAuthentication.verifyToken({
            userUuid: this.userUuid,
            token: this.token
        }));
    }

    requestLink() {
        this.store.dispatch(fromAuthentication.resendUser({
            userUuid: this.userUuid,
            companyCode: this.companyCode
        }));
    }

}
