export class BooleanUtil {
    public static isBoolean(value: any): boolean {
        return typeof value === 'boolean';
    }

    public static toBoolean(value: any) {
        if (BooleanUtil.isBoolean(value)) {
            return value;
        }
        if (value === 'false' || value === '0') {
            return false;
        }
        if (value === 'true' || value === '1') {
            return true;
        }
        return !!value;
    }
}
