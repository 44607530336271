import { InjectionToken } from "@angular/core";

export interface PasswordStrengthConfig {
    minLength: number;
    maxLength: number;
    minNumbersLength: number;
    minSpecialsLength: number;
    minUppercaseLength: number;
    minLowercaseLength: number;
    numberOrSpecials: boolean;
    noSpaces: boolean;
}

export const PASSWORD_STRENGTH_CONFIG = new InjectionToken('passwordStrengthConfig');
export const PASSWORD_STRENGTH_DEFAULT_CONFIG = new InjectionToken('passwordStrengthDefaultConfig');
