/**
 * Created by max.ackerman on 8/20/2018.
 */


import * as PagingDirective from "./paging.directive";
import * as NextDirective from "./next.directive";
import * as PrevDirective from "./prev.directive";

declare let angular;
export const PAGING_MODULE = angular.module("flipto.components.common.paging", []);

export class PagingConfig {

    static $inject = ["$compileProvider"];

    constructor($compileProvider) {
        const directives = [PagingDirective, NextDirective, PrevDirective];
        directives.forEach(directive => {
            $compileProvider.directive(directive.NAME, () => directive.DEFINITION);
        });    }

}

PAGING_MODULE.config(PagingConfig);