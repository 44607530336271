/**
 * Created by ostapenko.r on 10/1/2014.
 */


angular.module('flipto.core.api.settingsLanguages', [])
    .config(['$provide', function ($provide) {
        $provide.factory('SettingsLanguages', ['urlFormatter', '$http', function (urlFormatter, $http) {

            /**
             * SettingsLanguages service
             * @constructor
             */
            function SettingsLanguages() {
                /**
                 * Get property languages
                 * @param params
                 * @returns {*}
                 */
                this.get = function (params) {
                    return $http.get(urlFormatter.format('/api/:propertyId/properties/:propertyId/settings/languages', params))
                        .then(function (response) {
                            return response.data;
                        });
                };

                /**
                 * Update property languages
                 * @param params
                 * @param model
                 * @returns {*}
                 */
                this.put = function (params, model) {
                    return $http.put(urlFormatter.format('/api/:propertyId/properties/:propertyId/settings/languages', params), model)
                        .then(function (response) {
                            return response.data;
                        });
                };
            }
            return new SettingsLanguages();
        }]);
    }]);