/**
 * Created by ostapenko.r on 9/19/2014.
 */
angular.module('flipto.components.common.listEditor')
    .directive('ftAddToListByEnter', [function () {
        return {
            restrict: 'A',
            require: ['^ftListEditor', 'ngModel'],
            link: function (scope, element, attrs, controllersArr) {
                var ftListEditor = controllersArr[0],
                    ngModel = controllersArr[1],
                    isUseEvalToGetValue = !!attrs.ftAddToListByEnter;

                element.bind("keydown keypress", function (event) {
                    if (event.which === 13 && !!ngModel.$modelValue) {
                        var value = isUseEvalToGetValue ? scope.$eval(attrs.ftAddToListByEnter) : ngModel.$modelValue;
                        if (!!value) {
                            scope.$apply(function () {
                                ftListEditor.addItem(value);
                            });
                        }
                        event.preventDefault();
                    }
                });
                scope.$on('$destroy', function () {
                    element.unbind('keydown keypress');
                });
            }
        };
    }]);