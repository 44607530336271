import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { SpacesService } from 'account-hybrid/common/components/spaces';


export class FeatureFlag {
    flag_uuid: string;
    value: any;
}


export enum FeatureFlags {
    IsAudiencesEnabled = 'd2d461fe-3a1f-4478-bdd5-8460fc5580dd',
    IsCampaignsDisabled = 'b4b6846c-1615-407c-9a8e-0fa02424246d',
    IsBasicInsightsEnabled = 'a50499a7-65a7-4738-ba0e-98333a2e9114'
}

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagsService {

    // static Name = 'FeatureFlags';
    // static $inject = ['session'];

    constructor(public sessionService: SessionService,
        public spacesService: SpacesService) {
        Object.assign(this, FeatureFlags);
    }

    getFeatureFlagValue(featureFlag: FeatureFlags, defaultValue?) {
        if (!this.spacesService.hasCompanyContext()) return false;
        const company = this.sessionService.getCompany(this.spacesService.getCompanyUuid());
        if (typeof company.FeatureFlags[featureFlag] !== "undefined") { return company.FeatureFlags[featureFlag]; }
        if (typeof defaultValue !== 'undefined') { return defaultValue; }
        return false;
    }

    isAudiencesEnabled() {
        return this.getFeatureFlagValue(FeatureFlags.IsAudiencesEnabled);
    }

    isCampaignsDisabled() {
        return this.getFeatureFlagValue(FeatureFlags.IsCampaignsDisabled);
    }

    isBasicInsightsEnabled() {
        return this.getFeatureFlagValue(FeatureFlags.IsBasicInsightsEnabled);
    }

    onFlagUpdated(featureFlag: FeatureFlags, value) {
        if (!this.spacesService.hasCompanyContext()) return false;
        this.sessionService.onFeatureFlagChanged(this.spacesService.getCompanyUuid(), featureFlag, value);
    }

}
