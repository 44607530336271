<ng-container *ngIf="availableLanguageVariations?.length">
    <button class="ft2-btn-light -outline -large"
            #popoverContainer
            #popover="ngbPopover"
            [ngbPopover]="languagesVariationListTmpl"
            [autoClose]="'outside'"
            placement="bottom auto"
            [triggers]="'click'">
        + Add language variation
    </button>
    <ng-template #languagesVariationListTmpl>
        <!--   16px === 1rem padding inside popover for both sides     -->
        <div [style]="{'min-width': popoverContainer?.clientWidth - 16 + 'px'}"
             *ngFor="let language of availableLanguageVariations">
            <button (click)="addLanguageVariation(language); popover.close();"
                    class="ft2-btn-text">
                {{ language.languageName }}
            </button>
        </div>
    </ng-template>
</ng-container>
