/**
 * Created by max.ackerman on 07/12/2018.
 */


declare let angular;

export const NAME = "ftEnsureModalSingleScroll";

export const DEFINITION = [function () {
    return {
        scope: false,
        link: function (scope) {
            document.body.classList.add("ensure-modal-single-scroll");
            scope.$on("$destroy", () => {
                document.body.classList.remove("ensure-modal-single-scroll");
            });
        }
    };
}];

