<button (click)="onClose()" class="ft-btn-close"></button>
<div class="ft2-page-overlay d-flex">
    <div class="ft2-container -full">
        <div class="row full-height">
            <div class="ft2-audience-onboard-bg col-3 d-md-none">
            </div>
            <div class="col-8 col-md-12 py-9 py-md-7 py-xs-4">
                <div class="ft2-container -narrow ml-0 px-0">
                    <div *ngIf="!isUploadComplete" class="swiper-container" [swiper] [(index)]="slideIndex">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <div class="text-content px-8 px-lg-4 px-md-0">
                                    <h3>Let’s onboard your past guests.</h3>
                                    <p>
                                        We’re excited, this is a big moment for leveling up your marketing with the
                                        audience
                                        you’ve
                                        cultivated so far.
                                    </p>
                                    <p>
                                        In just a few clicks ahead, you’ll be uploading a file with your past guests
                                        list.
                                        We’ll
                                        start
                                        with just a minute or two to share some important information about what to
                                        expect.
                                    </p>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="text-content px-8 px-lg-4 px-md-0">
                                    <h3>
                                        What to expect once your audience is on your platform:
                                    </h3>
                                    <p>
                                        <b>Your platform will nurture each person based on their unique journeys</b> to
                                        inspire dreamers, win over planners, and foster collaboration with those who had
                                        a
                                        great experience with you.
                                    </p>
                                    <p>
                                        <b>Everyone will be treated as a person</b>, not as an email address. Your
                                        platform
                                        will adapt to their unique intentions for traveling so your marketing doesn’t
                                        become
                                        stale and irrelevant.
                                    </p>
                                    <p>
                                        <b>Your platform will help you build relationships that matter for your
                                            business</b>.
                                        It’s the key to making everything on the platform work, creating a better
                                        experience
                                        for your audience and bringing lasting value for your business.
                                    </p>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="text-content px-8 px-lg-4 px-md-0">
                                    <h3>Some more to think about:</h3>
                                    <p>
                                        <b>Your platform is not a mass emailer tool.</b> We don’t subscribe to this
                                        approach
                                        (pun intended) because it’s not the best way to interact with your audience.
                                        Instead, your platform is set up to communicate with each person’s individual
                                        journey.
                                    </p>
                                    <p>
                                        <b>Your audience’s data is secure.</b> We’re sticklers when it comes to data
                                        security and privacy, and uphold lawful standards handling personal information.
                                        As travelers ourselves, its the only way we’d want our
                                        data to be handled.
                                    </p>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="px-8 px-lg-4 px-md-0">
                                    <div class="text-content">
                                        <h3>Ok, let’s put your audience on the right journey.</h3>
                                    </div>
                                    <ft-dropzone-uploader
                                            [multiple]="false"
                                            [accept]="'.xls, .xlsx'"
                                            (selected)="files = $event">
                                    </ft-dropzone-uploader>
                                    <div class="ft2-form-error" *ngIf="error">
                                        {{error.message}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-navigation h-between v-center pl-8 pl-lg-4 pl-md-3">
                            <div class="swiper-pagination"></div>
                            <div class="swiper-button-next" [hidden]="totalSlides === slideIndex + 1">
                                <button class="swiper-button-circle">
                                    <mat-icon class="icon-md" svgIcon="arrow-right"></mat-icon>
                                </button>
                            </div>
                            <button class="ft2-btn-dark -outline -large"
                                    [ftLoading]="loading$"
                                    (click)="submit()"
                                    [disabled]="!files.length"
                                    *ngIf="totalSlides === slideIndex + 1">
                                Submit
                            </button>
                        </div>
                    </div>
                    <div *ngIf="isUploadComplete" class="ft2-audience-onboard-complete">
                        <div class="text-content px-8 px-lg-4 px-md-0">
                            <h3>Upload complete.</h3>
                            <p>
                                Your audience will begin their journey on your platform right away. You’ll find them in
                                the People area of your account as soon as they start engaging with you.
                            </p>
                        </div>
                        <div class="h-end">
                            <button class="ft2-btn-dark -outline -large" (click)="onClose()">Return to your account
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
