import { Inject, Injectable } from '@angular/core';
import { SpacesService } from '../../../common/components/spaces/spaces.service';
import { Environment, ENVIRONMENT_TOKEN } from '../../../core/environment.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ImpersonationApiService {
  constructor(private http: HttpClient,
    private spacesService: SpacesService,
    @Inject(ENVIRONMENT_TOKEN) private environment: Environment) {
  }

  impersonate(userEmail: string) {
    return this.http.post(`${this.environment.apiV1BaseUrl}api/auth/impersonate`, { email: userEmail }).toPromise();
  }

  unimpersonate() {
    return this.http.post(`${this.environment.apiV1BaseUrl}api/auth/unimpersonate`, {}).toPromise();
  }
}
