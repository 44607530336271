import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';


@Component({
    selector: 'ft-toggle-editor',
    templateUrl: './toggle-editor.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ToggleEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: ToggleEditorComponent
        }
    ]
})
export class ToggleEditorComponent extends ModalEditorBase {
    @Input() truthyValue: any;
    @Input() falsyValue: any;
    @Input() disabled: boolean = false;

    constructor() {
        super();
    }

    get controlValue() {
        if (this.truthyValue !== undefined && this.falsyValue !== undefined) {
            return this.value ? this.truthyValue : this.falsyValue;
        } else {
            return super.controlValue;
        }
    }

    mapValue(value: any) {
        if (this.truthyValue !== undefined && this.falsyValue !== undefined) {
            return typeof value === 'boolean'
                ? value
                : super.mapValue(value === this.truthyValue);
        }
        return super.mapValue(value);
    }
}

