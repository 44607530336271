/**
 * Created by ostapenko.r on 10/1/2014.
 */


angular.module('flipto.core.api.settingsBranding', ['flipto.core.language'])
    .config(['$provide', function ($provide) {
        $provide.factory('SettingsBranding', ['urlFormatter', '$http', 'languageHelper', function (urlFormatter, $http, languageHelper) {

            /**
             * SettingsBranding service
             * @constructor
             */
            function SettingsBranding() {

                /**
                 * Get branding data for property
                 * @param params
                 * @returns {*}
                 */
                this.get = function (params) {
                    return $http.get(urlFormatter.format('/api/:propertyId/properties/branding/:propertyId', params))
                        .then(function (response) {
                            languageHelper.sortByDefaultLanguage(response.data.languages);
                            response.data.isLogZuoraUsage = response.data.isLogZuoraUsage === "1";
                            return response.data;
                        });
                };

                /**
                 * Update branding data of property
                 * @param params
                 * @param model
                 * @returns {*}
                 */
                this.put = function (params, model) {
                    return $http.put(urlFormatter.format('/api/:propertyId/properties/branding/:propertyId', params), model)
                        .then(function (response) {
                            return response.data;
                        });
                };
            }

            return new SettingsBranding();
        }]);
    }]);