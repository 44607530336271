
/**
 * Created by roman.kupin on 11/10/2017.
 */

export const PREFIX = "[PROPERTY_API]";

export const ADVOCACY_OVERVIEW_FETCH = `${PREFIX}.AdvocacyOverviewFetch`;
export const ADVOCACY_OVERVIEW_FETCH_SUCCESS = `${ADVOCACY_OVERVIEW_FETCH}Success`;
export const ADVOCACY_OVERVIEW_FETCH_ERROR = `${ADVOCACY_OVERVIEW_FETCH}Error`;

export const ADVOCACY_GUEST_JOURNEY_FETCH = `${PREFIX}.AdvocacyGuestJourneyFetch`;
export const ADVOCACY_GUEST_JOURNEY_FETCH_SUCCESS = `${ADVOCACY_GUEST_JOURNEY_FETCH}Success`;
export const ADVOCACY_GUEST_JOURNEY_FETCH_ERROR = `${ADVOCACY_GUEST_JOURNEY_FETCH}Error`;

export const PLATFORM_LAYERS_DATA_FETCH = `${PREFIX}.PlatformLayersData`;
export const PLATFORM_LAYERS_DATA_FETCH_SUCCESS = `${PLATFORM_LAYERS_DATA_FETCH}Success`;
export const PLATFORM_LAYERS_DATA_FETCH_ERROR = `${PLATFORM_LAYERS_DATA_FETCH}Error`;

export const PHOTO_ADVOCACY_LAYER_DATA_FETCH = `${PREFIX}.PhotoAdvocacyLayerFetch`;
export const PHOTO_ADVOCACY_LAYER_DATA_FETCH_SUCCESS = `${PHOTO_ADVOCACY_LAYER_DATA_FETCH}Success`;
export const PHOTO_ADVOCACY_LAYER_DATA_FETCH_ERROR = `${PHOTO_ADVOCACY_LAYER_DATA_FETCH}Error`;

export const UPDATE_TOUCHPOINT_STATUS = `${PREFIX}.UpdateTouchpointStatus`;

export const PRESTAY_LAYER_DATA_FETCH = `${PREFIX}.PrestayLayerData`;
export const PRESTAY_LAYER_DATA_FETCH_SUCCESS = `${PRESTAY_LAYER_DATA_FETCH}Success`;
export const PRESTAY_LAYER_DATA_FETCH_ERROR = `${PRESTAY_LAYER_DATA_FETCH}Error`;

export const POSTSTAY_LAYER_DATA_FETCH = `${PREFIX}.PoststayLayerData`;
export const POSTSTAY_LAYER_DATA_FETCH_SUCCESS = `${POSTSTAY_LAYER_DATA_FETCH}Success`;
export const POSTSTAY_LAYER_DATA_FETCH_ERROR = `${POSTSTAY_LAYER_DATA_FETCH}Error`;

export const PHOTOCONTEST_LAYER_DATA_FETCH = `${PREFIX}.PhotoContestLayerData`;
export const PHOTOCONTEST_LAYER_DATA_FETCH_SUCCESS = `${PHOTOCONTEST_LAYER_DATA_FETCH}Success`;
export const PHOTOCONTEST_LAYER_DATA_FETCH_ERROR = `${PHOTOCONTEST_LAYER_DATA_FETCH}Error`;

export const HOMEPAGE_LAYER_DATA_FETCH = `${PREFIX}.HomepageLayerData`;
export const HOMEPAGE_LAYER_DATA_FETCH_SUCCESS = `${HOMEPAGE_LAYER_DATA_FETCH}Success`;
export const HOMEPAGE_LAYER_DATA_FETCH_ERROR = `${HOMEPAGE_LAYER_DATA_FETCH}Error`;

export const QUOTELIST_LAYER_DATA_FETCH = `${PREFIX}.QuoteListLayerData`;
export const QUOTELIST_LAYER_DATA_FETCH_SUCCESS = `${QUOTELIST_LAYER_DATA_FETCH}Success`;
export const QUOTELIST_LAYER_DATA_FETCH_ERROR = `${QUOTELIST_LAYER_DATA_FETCH}Error`;

export const CREATE_RECOMMENDATION = `${PREFIX}.CreateRecommendations`;
export const CREATE_RECOMMENDATION_SUCCESS = `${CREATE_RECOMMENDATION}Success`;
export const CREATE_RECOMMENDATION_ERROR = `${CREATE_RECOMMENDATION}Error`;

export const RECOMMENDATIONS_FETCH = `${PREFIX}.FetchRecommendations`;
export const RECOMMENDATIONS_FETCH_SUCCESS = `${RECOMMENDATIONS_FETCH}Success`;
export const RECOMMENDATIONS_FETCH_ERROR = `${RECOMMENDATIONS_FETCH}Error`;

export const CLOSE_RECOMMENDATION = `${PREFIX}.CloseRecommendation`;
export const CLOSE_RECOMMENDATION_SUCCESS = `${CLOSE_RECOMMENDATION}Success`;
export const CLOSE_RECOMMENDATION_ERROR = `${CLOSE_RECOMMENDATION}Error`;

export function getAdvocacyOverview(propertyUuid, startDate, endDate) {
    return (dispatch, getState, { $http, environment }) => {
        dispatch({ type: ADVOCACY_OVERVIEW_FETCH });
        return $http.get(`${environment.apiBaseUrl}/${propertyUuid}/properties/${propertyUuid}/advocacy/overview?StartDate=${startDate}&EndDate=${endDate}`)
            .then(response => {
                dispatch({ type: ADVOCACY_OVERVIEW_FETCH_SUCCESS, payload: response.data });
            }, error => {
                dispatch({ type: ADVOCACY_OVERVIEW_FETCH_ERROR, payload: error });
                throw ADVOCACY_OVERVIEW_FETCH_ERROR;
            })
    }
}

export function getAdvocacyGuestJourney(propertyUuid) {
    return (dispatch, getState, { $http, environment }) => {
        dispatch({ type: ADVOCACY_GUEST_JOURNEY_FETCH });
        return $http.get(`${environment.apiBaseUrl}/${propertyUuid}/properties/${propertyUuid}/advocacy/guestjourney`)
            .then(response => {
                dispatch({ type: ADVOCACY_GUEST_JOURNEY_FETCH_SUCCESS, payload: response.data });
            }, error => {
                dispatch({ type: ADVOCACY_GUEST_JOURNEY_FETCH_ERROR, payload: error });
                throw ADVOCACY_GUEST_JOURNEY_FETCH_ERROR;
            })
    }
}

export function getPlatformLayersData(propertyId) {
    return (dispatch, getState, { Layers, $http }) => {
        dispatch({ type: PLATFORM_LAYERS_DATA_FETCH });
        return Promise.all([
            Layers.prestay({ propertyId }),
            Layers.poststay({ propertyId }),
            Layers.photocontest({ propertyId }),
            Layers.homepage({ propertyId }),
            Layers.quotelist({ propertyId })
        ])
            .then(datas => {
                dispatch({ type: PLATFORM_LAYERS_DATA_FETCH_SUCCESS, payload: datas });
            },
                error => {
                    dispatch({ type: PLATFORM_LAYERS_DATA_FETCH_ERROR, payload: error })
                });
    }
}

export function getPrestayLayerData(propertyId) {
    return (dispatch, getState, { Layers, $http }) => {
        dispatch({ type: PRESTAY_LAYER_DATA_FETCH });
        return Layers.prestay({ propertyId })
            .then(data => {
                dispatch({ type: PRESTAY_LAYER_DATA_FETCH_SUCCESS, payload: data });
            },
                error => {
                    dispatch({ type: PRESTAY_LAYER_DATA_FETCH_ERROR, payload: error })
                });
    }
}

export function getPoststayLayerData(propertyId) {
    return (dispatch, getState, { Layers, $http }) => {
        dispatch({ type: POSTSTAY_LAYER_DATA_FETCH });
        return Layers.poststay({ propertyId })
            .then(data => {
                dispatch({ type: POSTSTAY_LAYER_DATA_FETCH_SUCCESS, payload: data });
            },
                error => {
                    dispatch({ type: POSTSTAY_LAYER_DATA_FETCH_ERROR, payload: error })
                });
    }
}
export function getPhotoContestLayerData(propertyId) {
    return (dispatch, getState, { Layers, $http }) => {
        dispatch({ type: PHOTOCONTEST_LAYER_DATA_FETCH });
        return Layers.photocontest({ propertyId })
            .then(data => {
                dispatch({ type: PHOTOCONTEST_LAYER_DATA_FETCH_SUCCESS, payload: data });
            },
                error => {
                    dispatch({ type: PHOTOCONTEST_LAYER_DATA_FETCH_ERROR, payload: error })
                });
    }
}
export function getHomepageLayerData(propertyId) {
    return (dispatch, getState, { Layers, $http }) => {
        dispatch({ type: HOMEPAGE_LAYER_DATA_FETCH });
        return Layers.homepage({ propertyId })
            .then(data => {
                dispatch({ type: HOMEPAGE_LAYER_DATA_FETCH_SUCCESS, payload: data });
            },
                error => {
                    dispatch({ type: HOMEPAGE_LAYER_DATA_FETCH_ERROR, payload: error })
                });
    }
}
export function getQuoteListLayerData(propertyId) {
    return (dispatch, getState, { Layers, $http }) => {
        dispatch({ type: QUOTELIST_LAYER_DATA_FETCH });
        return Layers.quotelist({ propertyId })
            .then(data => {
                dispatch({ type: QUOTELIST_LAYER_DATA_FETCH_SUCCESS, payload: data });
            },
                error => {
                    dispatch({ type: QUOTELIST_LAYER_DATA_FETCH_ERROR, payload: error })
                });
    }
}

export function getPhotoAdvocacyLayerData(propertyId) {
    return (dispatch, getState, { Layers, $http }) => {
        dispatch({ type: PHOTO_ADVOCACY_LAYER_DATA_FETCH });
        return Layers.photocontest({ propertyId })
            .then(data => {
                dispatch({ type: PHOTO_ADVOCACY_LAYER_DATA_FETCH_SUCCESS, payload: data });
            }, error => {
                dispatch({ type: PHOTO_ADVOCACY_LAYER_DATA_FETCH_ERROR, payload: error })
            });
    }
}


export function updateTouchpointStatus(args) {
    return dispatch => {
        dispatch({ type: UPDATE_TOUCHPOINT_STATUS, payload: args });
    }
}


export function fetchRecommendations(propertyUuid) {
    return (dispatch, getState, { $http, environment }) => {
        dispatch({ type: RECOMMENDATIONS_FETCH });
        return $http.get(`${environment.apiBaseUrl}/${propertyUuid}/properties/${propertyUuid}/recommendations`)
            .then(response => {
                const recommendations = response.data.map(r => Object.assign({}, r, { custom_data: JSON.parse(r.custom_data) }));
                dispatch({ type: RECOMMENDATIONS_FETCH_SUCCESS, payload: recommendations });
                return recommendations;
            }, error => {
                dispatch({ type: RECOMMENDATIONS_FETCH_ERROR, payload: error });
                throw RECOMMENDATIONS_FETCH_ERROR;
            })

    }
}


export function closeRecommendation(propertyUuid, recommendationUuid, isComplete) {
    return (dispatch, getState, { $http, environment }) => {
        dispatch({ type: CLOSE_RECOMMENDATION });
        return $http.put(`${environment.apiBaseUrl}/${propertyUuid}/properties/${propertyUuid}/recommendations/${recommendationUuid.toLowerCase()}?isComplete=${isComplete}`)
            .then(response => {
                dispatch({ type: CLOSE_RECOMMENDATION_SUCCESS, payload: response.data });
                return response.data;
            }, error => {
                dispatch({ type: CLOSE_RECOMMENDATION_ERROR, payload: error });
                throw CLOSE_RECOMMENDATION_ERROR;
            })

    }
}


export function createRecommendation(propertyUuid, referenceUuid, expirationDate, message, type, customData) {
    return (dispatch, getState, { $http, environment }) => {
        dispatch({ type: CREATE_RECOMMENDATION });
        return $http.post(`${environment.apiBaseUrl}/${propertyUuid}/properties/${propertyUuid}/recommendations`, { type, referenceUUID: referenceUuid, expirationDate, message, customData })
            .then(response => {
                dispatch({ type: CREATE_RECOMMENDATION_SUCCESS, payload: response.data });
                return response.data;
            }, error => {
                dispatch({ type: CREATE_RECOMMENDATION_ERROR, payload: error });
                throw CREATE_RECOMMENDATION_ERROR;
            });
    }
}

const All = {getAdvocacyOverview, getAdvocacyGuestJourney, getPlatformLayersData, getPrestayLayerData, getPoststayLayerData, getPhotoContestLayerData, getHomepageLayerData,
getQuoteListLayerData, getPhotoAdvocacyLayerData, updateTouchpointStatus, fetchRecommendations, closeRecommendation, createRecommendation};
export default All;
