import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromTouchpoints from 'account-hybrid/common/components/touchpoints/store';
import { Transition } from '@uirouter/core';
import {
    domInjectionStrategyOptions
} from 'account-hybrid/common/components/touchpoints/enums/dom-injection-strategy.enum';
import { Observable } from 'rxjs';
import { IDiscoveryInjectableComponentMessaging, IDiscoveryInjectableComponentSettings } from '../../interfaces';
import { DiscoveryComponentType } from '../../enums';
import { map, take, tap, withLatestFrom } from 'rxjs/operators';
import {
    mapLanguageNameToCode
} from 'account-hybrid/common/modules/account-management/utils/language-name-mapping.util';
import { MultiLanguageComponent } from 'account-hybrid/common/components/multi-linguage/multi-language.component';

@Component({
    selector: 'ft-book-now',
    templateUrl: './book-now.component.html',
    styleUrls: ['./book-now.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BookNowComponent extends MultiLanguageComponent implements OnInit {
    componentGroupUuid: string;
    domInjectionStrategyOptions = domInjectionStrategyOptions;
    tabsShowed = true;
    bookingButtonComponentsSettings$: Observable<Array<IDiscoveryInjectableComponentSettings<IDiscoveryInjectableComponentMessaging>>>;
    selectedBookingButtonComponent$: Observable<IDiscoveryInjectableComponentSettings<IDiscoveryInjectableComponentMessaging>>;
    isLoading$: Observable<boolean>;
    commitSuccess$: Observable<boolean>;
    messaging$: Observable<IDiscoveryInjectableComponentMessaging[]>;
    messaging: IDiscoveryInjectableComponentMessaging[];
    selectedIndex = 0;


    constructor(store: Store<fromTouchpoints.State>,
                private cdr: ChangeDetectorRef,
                private transition: Transition) {
        super(store);
    }

    ngOnInit() {
        this.componentGroupUuid = this.transition.params().componentGroupUuid;

        this.bookingButtonComponentsSettings$ = this.store.pipe(select(fromTouchpoints.selectDiscoveryBookingButtonComponents));
        this.selectedBookingButtonComponent$ = this.store.pipe(
            select(fromTouchpoints.selectDiscoveryBookingButtonComponentSettings),
            tap(selectedComponent => {
                if (!!selectedComponent) {
                    this.messaging$ = this.store.pipe(
                        select(fromTouchpoints.selectDiscoveryInjectableComponentMessaging, {
                            componentType: DiscoveryComponentType.BookingButton,
                            componentUuid: selectedComponent.componentUuid
                        }),
                        withLatestFrom(this.languages$),
                        map(([messaging, availableLanguages]) => {
                            this.messaging = mapLanguageNameToCode(messaging, availableLanguages);
                            return this.messaging;
                        }),
                        take(1)
                    );
                    this.messaging$.subscribe();
                }
            })
        );
        this.isLoading$ = this.store.pipe(select(fromTouchpoints.selectDiscoveryInjectableComponentIsLoading));
        this.commitSuccess$ = this.store.pipe(select(fromTouchpoints.selectDiscoveryInjectableComponentCommitSuccess));
    }

    toggleActiveComponent(isActive: boolean) {
        this.commit({ isActive });
    }

    selectComponent(componentUuid: string) {
        this.store.dispatch(fromTouchpoints.selectDiscoveryInjectableComponentSettings({
            componentUuid,
            componentType: DiscoveryComponentType.BookingButton
        }));
        this.refreshTabs();
    }

    refreshTabs() {
        this.tabsShowed = false;
        setTimeout(() => {
            this.tabsShowed = true;
        });
    }

    createComponent(componentName: string) {
        this.store.dispatch(fromTouchpoints.createDiscoveryInjectableComponentSettings({
            componentGroupUuid: this.componentGroupUuid,
            settings: { name: componentName },
            componentType: DiscoveryComponentType.BookingButton
        }));
    }

    commit(data: Partial<IDiscoveryInjectableComponentSettings<IDiscoveryInjectableComponentMessaging>>) {
        this.store.dispatch(fromTouchpoints.commitDiscoveryInjectableComponentSettings({
            componentGroupUuid: this.componentGroupUuid,
            settings: data,
            componentType: DiscoveryComponentType.BookingButton
        }));
    }

    getLabel(value: number) {
        return this.domInjectionStrategyOptions.find((option) => option.value === value).label;
    }
}
