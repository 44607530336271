/**
 * Created by roman.kupin on 9/25/2017.
 */


export class ContextSelectorService {

    static $inject = [];
    static Name = "contextSelectorService";

    Template: string;
}
