angular.module('flipto.components.common.tasks')
    .config(['$provide', '$compileProvider',
        function ($provide, $compileProvider) {
            $compileProvider.directive('ftFinishTask', ['ftTasks', function (ftTasks) {

                return function (scope, elem, attrs) {

                    elem.on('click', function () {
                        scope.$apply(function () {
                            ftTasks.finish(attrs.ftFinishTask);
                        });
                    });

                    scope.$on('$destroy', function () {
                        elem.unbind('click');
                    });
                };
            }]);
        }
    ]);