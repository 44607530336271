import { IRenderer, PluggableElementRenderer } from "../renderer";
import { IComponentFlowBuilder } from "../flows";
import { BroadcastStoryFromStoryModalComponentFlowBuilder } from "../flows/builders/broadcastStoryFromStoryBuilder";
import { BroadcastStoryFromRecommendationComponentFlowBuilder } from "../flows/builders/broadcastStoryFromRecommendationBuilder";
import { BroadcastStoryFromStoryModalFlowController } from "../flows/controllers/broadcastStoryFromStoryModalFlowController";
import { BroadcastStoryFromRecommendationFlowController } from "../flows/controllers/broadcastStoryFromRecommendationFlowController";
import { BuildBroadcastStoryRecommendationComponentFlowBuilder } from "../flows/builders/buildBroadcastStoryRecommendationBuilder";
import { BuildBroadcastStoryRecommendationFlowController } from "../flows/controllers/buildBroadcastStoryRecommendationFlowController";

declare const angular;

export class BroadcastModalService {

    static Name = "BroadcastModal";
    static $inject = ["$rootScope", "$compile", "ftModal", "$injector"];
    constructor(public $rootScope,
        public $compile,
        public ftModal,
        public $injector) {
    }

    broadcastStoryFromStoryModal(propertyUuid: string, storyUuid: string): IRenderer {
        const componentFlowBuilder: IComponentFlowBuilder = new BroadcastStoryFromStoryModalComponentFlowBuilder();
        const steps = componentFlowBuilder.createSteps();
        const template = componentFlowBuilder.createTemplate(steps);
        const scope = this.$rootScope.$new(true);

        const renderer = this.$injector.instantiate(PluggableElementRenderer, { scope });

        const contoller = this.$injector.instantiate(BroadcastStoryFromStoryModalFlowController, { propertyUuid, storyUuid, steps, renderer });
        angular.extend(scope, { $ftFlow: contoller });

        renderer.add(template);

        this.ftModal.show("account-v2.BroadcastStory");

        return renderer;
    }


    broadcastStoryFromRecommendation(propertyUuid: string, storyUuid: string, recommendation): IRenderer {
        const componentFlowBuilder: IComponentFlowBuilder = new BroadcastStoryFromRecommendationComponentFlowBuilder();
        const steps = componentFlowBuilder.createSteps();
        const template = componentFlowBuilder.createTemplate(steps);
        const scope = this.$rootScope.$new(true);

        const renderer = this.$injector.instantiate(PluggableElementRenderer, { scope });

        const contoller = this.$injector.instantiate(BroadcastStoryFromRecommendationFlowController, { propertyUuid, storyUuid, recommendation, steps, renderer });
        angular.extend(scope, { $ftFlow: contoller });

        renderer.add(template);

        this.ftModal.show("account-v2.BroadcastRecommendedStory");

        return renderer;
    }

    buildBroadcastStoryRecommendation(propertyUuid: string, storyUuid: string): IRenderer {
        const componentFlowBuilder: IComponentFlowBuilder = new BuildBroadcastStoryRecommendationComponentFlowBuilder();
        const steps = componentFlowBuilder.createSteps();
        const template = componentFlowBuilder.createTemplate(steps);
        const scope = this.$rootScope.$new(true);

        const renderer = this.$injector.instantiate(PluggableElementRenderer, { scope });

        const contoller = this.$injector.instantiate(BuildBroadcastStoryRecommendationFlowController, { propertyUuid, storyUuid, steps, renderer });
        angular.extend(scope, { $ftFlow: contoller });

        renderer.add(template);

        this.ftModal.show("account-v2.BuildBroadcastStoryRecommendation");

        return renderer;
    }

}