/**
 * Created by ostapenko.r on 1/21/2015.
 */

angular.module('flipto.core.array', ['flipto.core.lodash'])
    .factory("ArrayUtil", [function () {
        var ArrayUtil = function () {
            this.move = function (collection, oldIndex, newIndex) {
                if (newIndex >= collection.length) {
                    var k = newIndex - collection.length;
                    while ((k--) + 1) {
                        collection.push(undefined);
                    }
                }
                collection.splice(newIndex, 0, collection.splice(oldIndex, 1)[0]);
                return collection;
            };

            this.moveDown = function (collection, value) {
                var index = collection.indexOf(value),
                    newPos = index + 1;

                if (index === -1)
                    throw new Error('Element not found in array');

                this.move(collection, index, newPos);
            };

            this.moveUp = function (collection, value) {
                var index = collection.indexOf(value),
                    newPos = index - 1;

                if (index === -1)
                    throw new Error('Element not found in array');

                this.move(collection, index, newPos);
            }
        };

        return new ArrayUtil();
    }])
    .filter('arrayItem', ['_', function (_) {
        return function (array, key, value) {
            var obj = {};
            obj[key] = value;
            return _.find(array, obj);
        }
    }])
    .filter('findInArray', ['_', function (_) {
        return function (value, array, key) {
            var obj = {};
            obj[key] = value;
            return _.find(array, obj);
        }
    }])
    .filter('arrayFilter', ['_', function (_) {
        return function (array, values) {
            return _.filter(array, function (item) {
                return _.filter(values, function (value) {
                        return _.filter([item], value).length > 0;
                    }).length > 0;
            });
        }
    }])
    .filter('arrayFilterByPropertyInArray', ['_', function (_) {
        return function (array, property, values) {
            return _.filter(array, function (item) {
                return _.filter(values, function (value) {
                        return item[property] == value;// _.filter([item[property]], value).length > 0;
                    }).length > 0;
            });
        }
    }])
    .filter('arrayJoin', [function () {
        return function (array, separator) {
            if (!angular.isArray(array)) return '';
            return array.join(separator);
        };
    }])
    .filter('languageDataArrayAnyNotEmptyField', ['_', function (_) {
        return function (array, field) {
            if (!angular.isArray(array)) return false;
            return _.some(array, function(elem) {
                return angular.isDefined(elem.data) &&
                    angular.isDefined(elem.data[field]) &&
                    elem.data[field].length > 0;
            });
        };
    }]);