/**
 * Created by roman.kupin on 1/30/2018.
 */


angular.module("flipto.accountv1.SharedFeatures", [
    "flipto.components.touchpoints",
    "flipto.components.changesTransformer",
    "flipto.components.versionPanel",
    "flipto.components.fieldEditors",
    "flipto.components.textAngularTools",
    "flipto.components.campaigns",
    "flipto.components.goals",
    "flipto.components.incentives",
    "flipto.components.curation",
    "flipto.components.imageLibrary",
    "flipto.components.emailImagesPreview",
    "textAngular"
]);