
/**
 * Created by roman.kupin on 11/13/2017.
 */

declare let google;

const STATIC_GOOGLE_CHARTS_URL = "https://www.gstatic.com/charts/loader.js";

function isChartsLoaded() {
    return !!window["google"];
}

let injectLoaderPromise;

export function injectLoader() {
    if (!injectLoaderPromise) {
        injectLoaderPromise = injectScriptTag(STATIC_GOOGLE_CHARTS_URL, document.head);
    }
    return injectLoaderPromise;
}

export function injectGeoCharts(googleChartsMapApiKey) {
    return loadPackages(['geochart'], {'mapsApiKey': googleChartsMapApiKey});
}

export function injectCoreCharts() {
    return loadPackages(['corechart']);
}

export function injectTableCharts() {
    return loadPackages(['table']);
}

function loadPackages(packages, options?) {
    return new Promise((resolve) => {
        google.charts.load('current', Object.assign({}, {packages}, options));
        google.charts.setOnLoadCallback(resolve);
    });
}

function injectScriptTag(scriptUrl: string, source: Node) {
    return new Promise((resolve, reject) => {
        if (isChartsLoaded()) {
            resolve(null);
        } else {
            const script = document.createElement("script");
            script.src = scriptUrl;
            script.onload = () => {
                resolve(null);
            };
            script.onerror = () => {
                reject();
            };
            source.appendChild(script);
        }
    });
}
