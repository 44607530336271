import { Pipe, PipeTransform } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DateUtils } from 'shared';

@Pipe({
    name: 'dateIsSame'
})
export class DateIsSamePipe implements PipeTransform {
    constructor() {
    }

    transform(date: Date | NgbDate, targetDate: Date | NgbDate): boolean {
        if (date && targetDate) {
           return DateUtils.isSame(DateUtils.toDateFormat(date), DateUtils.toDateFormat(targetDate));
        }
        return false;
    }
}
