<div *ngIf="(spaces$ | async)?.isInitialized" class="ft2">
    <div class="ft-modal ft2-spaces" [hidden]="!isVisible">
        <div class="v-start h-end px-4">
            <div class="v-start" [class.pr-6]="(spaces$ | async).isCancelVisible">
                <ng-container *ngIf="identity.isFliptoStaff()">
                    <span class="ft-dropdown opens-right" ftClickClass [toggleClass]="'active'">
                        <span class="ft-dropdown-toggle">Flip.to <i class="ft-icn-caret-down"></i></span>
                        <ul class="ft-dropdown-menu">
                            <li>
                                <a uiSref="admin-area.dashboard" target="_blank">Admin space</a>
                            </li>
                            <li class="ft-dropdown-divider">
                                <ft-create-account (click)="isSearchable = false" (creatorClose)="isSearchable = true">
                                    <a><i class="ft-icn-plus"></i> New account</a>
                                </ft-create-account>
                            </li>
                            <li>
                                <a (click)="signOut()">Sign Out</a>
                            </li>
                        </ul>
                    </span>
                </ng-container>
                <button *ngIf="(spaces$ | async).isCancelVisible" (click)="cancel()"
                        class="ft2-btn-close -fixed -bg_dark"></button>
            </div>
        </div>
        <ft-instant-search [value]="searchCriteria$ | async"
                           [searchable]="isVisible && isSearchable"
                           (clear)="clearSearchCriteria()"
                           (valueChange)="setSearchCriteria($event)">
        </ft-instant-search>
        <section class="ft2-block -large">
            <div class="ft-container" [ngClass]="{'transparent': (searchCriteria$ | async)}">
                <h2 class="line-header medium-text -muted mb-4">
                    <span class="solid-text">
                        Manage your platform
                    </span>
                </h2>
            </div>
            <div [class.ft-container]="(totalSpacesAmount$ | async) <= 3">
                <ul class="ft2-space-cards"
                    [ngClass]="(totalSpacesAmount$ | async) <= 3 ? 'grid-' + (totalSpacesAmount$ | async) : ''">
                    <li (click)="goTo(space)"
                        *ngFor="let space of filteredSpaces$ | async; let $last=last;" [@scale]
                        [class.cell]="(totalSpacesAmount$ | async) <= 3"
                        ft-is-visible (callback)="fetchNextPage()" [when]="$last">
                        <div [ftBackgroundImage]="space.backgroundImageUrl" class="ft2-space-card">
                            <img class="ft2-space-card_logo" *ngIf="space.icon" [src]="space.icon" alt="">
                        </div>
                        <p class="fg2-space-card-name">
                            <ng-container *ngIf="isPrefixVisible">
                                <ng-container *ngIf="space.type == 1">C -</ng-container>
                                <ng-container *ngIf="space.type == 2">P -</ng-container>
                                <ng-container *ngIf="space.type == 3">M -</ng-container>
                            </ng-container>
                            {{space.title}}
                        </p>
                    </li>
                    <li class="ft2-space-cards_placeholder" *ngIf="(filteredSpaces$ | async)?.length === 0">
                        <p *ngIf="(searchCriteria$ | async)">
                            No search results.<br>
                            Please check your spelling or try a different keyword.
                        </p>
                        <p *ngIf="!(searchCriteria$ | async)">
                            You don’t have any spaces available. <br>
                            Please contact your account manager
                        </p>
                    </li>
                </ul>
            </div>
        </section>
        <ng-container *ngIf="(collaborativeSpaces$ | async).length > 0 && (collaborativeSpaces$ | async) as spaces">
            <section class="ft2-block -large" *ngIf="!(searchCriteria$ | async)" [@scale]>
                <div class="ft2-container">
                    <h2 class="line-header medium-text -muted mb-5">
                        <span class="solid-text">
                            Collaboratives
                        </span>
                    </h2>
                    <div class="row">
                        <div class="col-12 mb-5" *ngFor="let space of spaces">
                            <div class="ft2-space-card -collaborative" [ftBackgroundImage]="space.backgroundImageUrl"
                                 (click)="goTo(space)">
                                <div class="row full-height">
                                    <div class="col-6 col-md-12 f-column v-center h-center">
                                        <div class="my-auto text-center">
                                            <img class="ft2-space-card_logo px-sm-3 py-md-5" *ngIf="space.icon"
                                                 [src]="space.icon" alt="">
                                            <span class="ft2-space-card_name" *ngIf="!space.icon">
                                                {{space.title}}
                                            </span>
                                        </div>
                                        <p *ngIf="!!space.sponsorName">Sponsored by {{space.sponsorName}}</p>
                                    </div>
                                    <div class="col-5 col-md-12 v-center">
                                        <p class="full-width large-text my-md-3 text-md-center"
                                           *ngIf="!space.description">
                                            The {{space.destinationName}} community is working together to inspire
                                            travelers.
                                        </p>
                                        <p class="full-width large-text my-md-3 text-md-center"
                                           *ngIf="space.description">
                                            {{ space.description }}
                                        </p>
                                    </div>
                                </div>
                                <!--                                <div class="ft2-collaborative-badge">-->
                                <!--                                    badge-->
                                <!--                                </div>-->
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ng-container>
    </div>
</div>
