import { NgModule } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { SwiperModule } from 'swiper/angular';
import { MonthlyCalendarComponent } from './components/monthly-calendar/monthly-calendar.component';
import { MonthlyCalendarDayComponent } from './components/monthly-calendar-day/monthly-calendar-day.component';
import { AccountSharedModule, PipesModule } from 'apps/account/src/account-shared';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';

@NgModule({
    declarations: [MonthlyCalendarComponent, MonthlyCalendarDayComponent],
    imports: [
        CommonModule,
        NgbDatepickerModule,
        SwiperModule,
        PipesModule,
        NgIf,
        TranslateModule,
        AccountSharedModule,
        ClickOutsideModule,
    ],
    exports: [MonthlyCalendarComponent],
})
export class MonthlyCalendarModule {}
