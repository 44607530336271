import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';
import {
    MissionControlLanguageData
} from 'account-hybrid/features/mission-control/models/mission-control-language-data.model';
import { Language } from 'account-hybrid/common/models/language.model';
import { ExperienceLicensePermission } from 'account-hybrid/features/experiences/enums/experience-license-permission.enum';

@Component({
    selector: 'ft-experiences-license-editor',
    templateUrl: './experiences-license-editor.component.html',
    styleUrls: ['./experiences-license-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ExperiencesLicenseEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: ExperiencesLicenseEditorComponent
        }
    ]
})
export class ExperiencesLicenseEditorComponent extends ModalEditorBase {
    @Input() settingOptions = [];
    @Input() permissionsOptions = [];
    @Output() permissionsOptionsChange = new EventEmitter<any[]>();
    @Input() propertyName: string;


    constructor() {
        super();
        this.onModelInit().subscribe(() => {
            this.processPermissionsOptions();
        });
    }

    processPermissionsOptions() {
        this.permissionsOptions.forEach((option, index) => {
            option.checked = this.isExperiencesLicensePermissionChecked(option.value);
        });
        this.permissionsOptionsChange.emit(this.permissionsOptions);
    }

    isExperiencesLicensePermissionChecked(value: number) {
        return (this.value?.permissions & value) === value;
    }

    onExperiencesLicensePermissionChange(option) {
        if (!option.checked && this.isPopularityScoreValue(option.value)) {
            this.uncheckValue(ExperienceLicensePermission.Local);
        }
        this.value.permissions = this.permissionsOptions.filter(item => item.checked).reduce((acc, item) => acc | item.value, 0);
        this.processPermissionsOptions();
    }

    uncheckValue(value: ExperienceLicensePermission) {
        const option = this.permissionsOptions.find(option => option.value === value);
        option.checked = false;
        this.permissionsOptionsChange.emit(this.permissionsOptions);
    }


    isPopularityScoreValue(value: ExperienceLicensePermission): boolean {
        return [
            ExperienceLicensePermission.PopularityScore_1,
            ExperienceLicensePermission.PopularityScore_2,
            ExperienceLicensePermission.PopularityScore_3,
            ExperienceLicensePermission.PopularityScore_4,
            ExperienceLicensePermission.PopularityScore_5,
            ExperienceLicensePermission.PopularityScore_6
        ].includes(value);
    }
}
