import { Pipe, PipeTransform } from '@angular/core';
import { PropertyHierarchyDto } from 'libs/api/data-contracts';

@Pipe({
    name: 'groupAssociation'
})
export class GroupAssociation implements PipeTransform {

    transform(value: PropertyHierarchyDto[]): any {
        const data = value.reduce((acc, location) => {
            const city = location.city || location.mappedCity || '';
            acc[city] = (acc[city] || []).concat(location);
            return acc;
        }, {});

        // sort by city then by property name
        return Object.entries<PropertyHierarchyDto[]>(data)
            .sort((a, b) =>
                a[0].localeCompare(b[0])).map((entry) =>
                entry[1]
                    .sort((a, b) => a.name.localeCompare(b.name))
                    // place newly created items at the top
                    .sort((a, b) => {
                        if (a.justAdded)
                            return -1;
                        if (b.justAdded)
                            return 1;
                        return 0;
                    }))
            // place group with newly created items at the top
            .sort((a, b) => {
                if (a.some(i => i.justAdded))
                    return -1;
                if (b.some(i => i.justAdded))
                    return 1;
                return 0;
            });
    }

}
