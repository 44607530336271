/**
 * Created by max.ackerman on 8/16/2018.
 */


import * as StoryHighlightComponent from "./storyHighlight.component";

declare let angular;
export const STORY_HIGHLIGHT_MODULE = angular.module("AccountApp.v2.Components.StoryHighlight", []);

export class ComponentConfig {

    static $inject = ["$compileProvider"];

    constructor($compileProvider) {
        $compileProvider.component(StoryHighlightComponent.NAME, StoryHighlightComponent.OPTIONS);
    }
}

STORY_HIGHLIGHT_MODULE.config(ComponentConfig);