/**
 * Created by ostapenko.r on 10/1/2014.
 */


angular.module('flipto.core.api.settingsPrivacy', [])
    .config(['$provide', function ($provide) {
        $provide.factory('SettingsPrivacy', ['urlFormatter', '$http', function (urlFormatter, $http) {

            /**
             * SettingsPrivacy service
             * @constructor
             */
            function SettingsPrivacy() {

                /**
                 * Get data
                 * @param propertyUuid
                 * @returns {*}
                 */
                this.get = function (propertyUuid) {
                    return $http.get(urlFormatter.format('/api/:propertyUuid/properties/:propertyUuid/settings/privacy', {propertyUuid: propertyUuid}))
                        .then(function (response) {
                            return response.data;
                        });
                };

                /**
                 * Update data
                 * @param propertyUuid
                 * @param model
                 * @returns {*}
                 */
                this.put = function (propertyUuid, model) {
                    return $http.put(urlFormatter.format('/api/:propertyUuid/properties/:propertyUuid/settings/privacy', {propertyUuid: propertyUuid}), model)
                        .then(function (response) {
                            return response.data;
                        });
                };
            }

            return new SettingsPrivacy();
        }]);
    }]);