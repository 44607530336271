/**
 * Created by roman.kupin on 1/30/2018.
 */

import * as PropertyApiActions from "../../actions/propertyApi";
import Parse from "../../utils/parse";

declare let moment;

function getPrizesDescriptor(contest) {
    const descriptor = {
        hasFirst: false,
        hasSecond: false,
        hasThird: false,
        hasRunnerUps: false
    };
    if (contest.finalistsIncentives) {
        if (contest.finalistsIncentives.firstWinnerIncentive) descriptor.hasFirst = true;
        if (contest.finalistsIncentives.secondWinnerIncentive) descriptor.hasSecond = true;
        if (contest.finalistsIncentives.thirdWinnerIncentive) descriptor.hasThird = true;
        if (contest.finalistsIncentives.runnerUpsIncentive) descriptor.hasRunnerUps = true;
    }

    return descriptor;
}

function adjustPhotoAdvocacyContests(photoAdvocacyData) {
    if (!!photoAdvocacyData && !!photoAdvocacyData.contests) {
        const now = moment();
        photoAdvocacyData.contests.item.forEach(contest => {
            contest.inProgress = now.isBefore(contest.endDate) && now.isAfter(contest.startDate);
            contest.ended = now.isAfter(contest.endDate);
            contest.isClosed = !!contest.closeDate;
            contest.prizesDescriptor = getPrizesDescriptor(contest);
        });
    }
    return photoAdvocacyData;
}

export default function propertyPlatformDataReducer(state = {}, {type, payload}) {
    switch (type) {
        case PropertyApiActions.PLATFORM_LAYERS_DATA_FETCH_SUCCESS: {

            const prestayCampaigns = Parse.arrayOrDefault(!!payload[0] ? payload[0].touchpointGroups : null);
            const prestayCampaignUuid = !!prestayCampaigns.length ? prestayCampaigns[0].id : null;

            const poststayCampaigns = Parse.arrayOrDefault(!!payload[1] ? payload[1].touchpoints : null);
            const poststayCampaignUuid = !!poststayCampaigns.length ? poststayCampaigns[0].id : null;

            const photoAdvocacyCampaigns = Parse.arrayOrDefault(!!payload[2] ? payload[2].touchpoints : null);
            const photoAdvocacyCampaignUuid = !!photoAdvocacyCampaigns.length ? photoAdvocacyCampaigns[0].id : null;

            const homepageCampaigns = Parse.arrayOrDefault(!!payload[3] ? payload[3].touchpointGroups : null);
            const homepageCampaignUuid = !!homepageCampaigns.length ? homepageCampaigns[0].id : null;

            const quoteListCampaigns = Parse.arrayOrDefault(!!payload[4] ? payload[4].touchpointGroups : null);
            const quoteListCampaignUuid = !!quoteListCampaigns.length ? quoteListCampaigns[0].id : null;

            return Object.assign({}, state, {
                prestayCampaignUuid,
                poststayCampaignUuid,
                photoAdvocacyCampaignUuid,
                homepageCampaignUuid,
                quoteListCampaignUuid,
                prestay: payload[0],
                poststay: payload[1],
                photoAdvocacy: adjustPhotoAdvocacyContests(payload[2]),
                homepage: payload[3],
                quoteList: payload[4]
            });
        }
        case PropertyApiActions.PHOTO_ADVOCACY_LAYER_DATA_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                photoAdvocacyCampaigns: adjustPhotoAdvocacyContests(payload)
            });
        }
        case PropertyApiActions.PRESTAY_LAYER_DATA_FETCH_SUCCESS : {
            const prestayCampaigns = Parse.arrayOrDefault(!!payload ? payload.touchpointGroups : null);
            const prestayCampaignUuid = !!prestayCampaigns.length ? prestayCampaigns[0].id : null;

            return Object.assign({}, state, {
                prestayCampaignUuid,
                prestay: payload
            });
        }
        case PropertyApiActions.POSTSTAY_LAYER_DATA_FETCH_SUCCESS : {
            const poststayCampaigns = Parse.arrayOrDefault(!!payload ? payload.touchpoints : null);
            const poststayCampaignUuid = !!poststayCampaigns.length ? poststayCampaigns[0].id : null;

            return Object.assign({}, state, {
                poststayCampaignUuid,
                poststay: payload
            });
        }
        case PropertyApiActions.PHOTOCONTEST_LAYER_DATA_FETCH_SUCCESS : {
            const photoAdvocacyCampaigns = Parse.arrayOrDefault(!!payload ? payload.touchpoints : null);
            const photoAdvocacyCampaignUuid = !!photoAdvocacyCampaigns.length ? photoAdvocacyCampaigns[0].id : null;

            return Object.assign({}, state, {
                photoAdvocacyCampaignUuid,
                photoAdvocacy: adjustPhotoAdvocacyContests(payload)
            });
        }
        case PropertyApiActions.HOMEPAGE_LAYER_DATA_FETCH_SUCCESS : {
            const homepageCampaigns = Parse.arrayOrDefault(!!payload ? payload.touchpointGroups : null);
            const homepageCampaignUuid = !!homepageCampaigns.length ? homepageCampaigns[0].id : null;

            return Object.assign({}, state, {
                homepageCampaignUuid,
                homepage: payload
            });
        }
        case PropertyApiActions.QUOTELIST_LAYER_DATA_FETCH_SUCCESS : {
            const quoteListCampaigns = Parse.arrayOrDefault(!!payload ? payload.touchpointGroups : null);
            const quoteListCampaignUuid = !!quoteListCampaigns.length ? quoteListCampaigns[0].id : null;

            return Object.assign({}, state, {
                quoteListCampaignUuid,
                quoteList: payload
            });
        }
        default: {
            return state;
        }
    }
}