/**
 * Created by ostapenko.r on 12/4/2014.
 */


angular.module('flipto.core.api.incentives', ['flipto.core.language'])
    .config(['$provide', function($provide) {

        $provide.factory('Incentives', ['$http', 'languageHelper', function($http, languageHelper) {

            /**
             * Incentives service
             * @constructor
             */
            function Incentives() {


                /**
                 * Activate/deactivate goal
                 * @param id
                 * @param status
                 * @returns {*}
                 */
                this.toggleStatus = function(id, status) {
                    var url = '/api/properties/layers/campaigns/goals/' + id + '/activate/' + status;

                    return $http.get(url).then(function(response) {
                        return response.data;
                    });
                };

                /**
                 * Save incentive criteria
                 * @param incentive
                 * @returns {*}
                 */
                this.saveIncentiveCriteria = function(incentive) {
                    var url = '/api/properties/layers/campaigns/goals/' + incentive.id;
                    var postBody = transformToApiFormat(incentive);

                    return $http.put(url, postBody).then(function(response) {
                        return response.data;
                    });
                };

                /**
                 * Create incentive criteria
                 * @param incentive
                 * @returns {*}
                 */
                this.createIncentiveCriteria = function(incentive) {
                    var url = '/api/touchpoints/incentives/criteria/' + incentive.id;
                    var postBody = transformToApiFormat(incentive);

                    return $http.post(url, postBody).then(function(response) {
                        return response.data;
                    });
                };

                /**
                 * Get all available incentives
                 * @returns {*}
                 */
                this.getAvailableIncentives = function(propertyId) {
                    var url = '/api/' + propertyId + '/properties/' + propertyId + '/incentives';

                    return $http.get(url).then(function(response) {
                        const mappedResponse = { item: response.data }
                        mappedResponse.item = [...mappedResponse.item.map(item => ({
                            ...item, id: item.incentiveUuid.toUpperCase(),
                            languages: {
                                item: item.languages.map(language => ({ ...language, code: language.languageCode }))
                            }
                        }))];
                        response.data = mappedResponse;
                        if (angular.isArray(response.data)) {
                            angular.forEach(response.data, function(incentive) {
                                languageHelper.sortByDefaultLanguage(incentive.languages);
                            });
                        }
                        return response;
                    });
                };

                function transformToApiFormat(incentive) {
                    if (incentive.type == '1')
                        return transformIncentiveToAdvocateIncentive(incentive);
                    else
                        return transformIncentiveToFriendIncentive(incentive);
                }

                function transformIncentiveToFriendIncentive(incentive) {
                    var expectedDateFormat = transformDate(incentive.expirationDate);

                    return {
                        daysValidFor: incentive.daysValidFor,
                        expirationDate: expectedDateFormat
                    };
                }

                function transformIncentiveToAdvocateIncentive(incentive) {
                    var expectedDateFormat = transformDate(incentive.expirationDate);

                    return {
                        clicks: incentive.clicks,
                        signups: incentive.signups,
                        nightRangeStart: incentive.nightRangeStart,
                        nightRangeEnd: incentive.nightRangeEnd,
                        expirationDate: expectedDateFormat
                    };
                }

                function transformDate(dateString) {
                    var rc = '';
                    if (dateString) {
                        var year = moment(dateString).format('YYYY');
                        var month = moment(dateString).format('M');
                        var day = moment(dateString).format('D');
                        rc = year + '-' + month + '-' + day;
                    }

                    return rc;
                }
            }

            return new Incentives();
        }]);
    }]);
