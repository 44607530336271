import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[ftScrollIntoView]'
})
export class ScrollIntoViewDirective {

    @Input('ftScrollIntoViewNearest') nearest = true;
    @HostListener('click')
    onClick() {
        this.el.nativeElement.scrollIntoView({ behavior: 'smooth', block: this.nearest ? 'nearest' : null });
    }

    constructor(private el: ElementRef) {
    }
}
