/**
 * Created by Roman on 2014-09-30.
 */


angular.module('flipto.accountv1.SharedFeatures')
    .controller('flipto.account.sections.platform.photoAdvocacy.DetailsController', [
        '$scope', 'PhotoContest', 'contestUuid', '_', '$q', 'parse', 'Incentives', 'updateGoalsSerializer', '$sce', 'SpacesService', 'Image', 'ftTasks',
        'changesTransformer', 'transformType', 'invitationShareModal', '$filter', 'incentiveTags', '$rootScope', 'IdentityService', 'SessionService',
        function($scope, PhotoContest, contestUuid, _, $q, parse, Incentives, updateGoalsSerializer, $sce, spacesService, Image, ftTasks,
                 changesTransformer, transformType, invitationShareModal, $filter, incentiveTags, $rootScope, identity, sessionService) {

            var cnameUrl = $filter('cnameUrl');

            $scope.$sce = $sce;
            $scope.model = {
                propertyId: spacesService.getPropertyUuid(),
                propertyName: sessionService.getProperty(spacesService.getPropertyUuid()).Name,
                isActive: false,
                language: null,
                pendingRefreshes: [],
                newContest: {
                    startDate: moment(),
                    endDate: moment().add(1, 'month'),
                    name: ''
                },
                finalistGoals: [],
                defaultBackgroundImageUrl: sessionService.getProperty(spacesService.getPropertyUuid()).BackgroundImageUrl,
                contestUuid: contestUuid,
                networks: {
                    fb: { title: 'facebook', socialNetworkType: 1 },
                    tw: { title: 'twitter', socialNetworkType: 2 },
                    li: { title: 'linkedin', socialNetworkType: 3 }
                },
                phoneAcceptanceOptions: [
                    { value: '2', label: 'Accept with checkbox' },
                    { value: '1', label: 'Accept on submit' }
                ],
                phoneDisplayOptions: [
                    { value: '2', label: 'Required' },
                    { value: '1', label: 'Optional' },
                    { value: '0', label: 'Off' }
                ],
                submitConsentDefaultText: 'By clicking submit, you accept the rules of the contest and permit use of your story to introduce travelers to '
                    + sessionService.getProperty(spacesService.getPropertyUuid()).DisplayName || sessionService.getProperty(spacesService.getPropertyUuid()).Name
                    + ' as defined in the terms & conditions and photo release.'
            };
            $scope.data = {};

            loadContest();


            $scope.$watch('data.propertyIncentives', function(propertyIncentives) {
                if (propertyIncentives) {
                    $scope.filteredIncentivesByTag = [];
                    propertyIncentives.item.forEach(function(incentive) {
                        if ((incentive.categoryFlags & incentiveTags.contestPrize) === incentiveTags.contestPrize) $scope.filteredIncentivesByTag.push(incentive);
                    });
                }
            });

            $scope.phoneAcceptanceText = function(modeId) {
                return $scope.model.phoneAcceptanceOptions?.find((option) => option.value === modeId)?.label;
            };

            $scope.phoneDisplayText = function(modeId) {
                return $scope.model.phoneDisplayOptions?.find((option) => option.value === modeId)?.label;
            };

            $scope.parseIsPhoneConsentFeatureEnabled = function (value) {
                return typeof value == 'boolean' ? value : Boolean(parseInt(value, 10));
            }

            $scope.save = function() {
                var model = {
                    languages: _.map($scope.data.contest.languages.item, function(language) {
                        return {
                            languageCode: language.code,
                            followerMessage: language.followerMessage,
                            guestMessage: language.guestMessage,
                            contestTerms: language.terms,
                            prizesTerms: setTargetBlankToLinks(language.prizesTerms),
                            releaseTerms: setTargetBlankToLinks(language.releaseTerms),
                            submitConsent: setTargetBlankToLinks(language.submitConsent),
                            phoneConsent: setTargetBlankToLinks(language.phoneConsent),
                            firstPrize: language.firstPrize,
                            secondPrize: language.secondPrize,
                            inviteeGreeting: language.inviteeGreeting,
                            inviteeQuoteRequestMessage: language.inviteeQuoteRequestMessage
                        };
                    }),
                    isAutoInvite: $scope.data.contest.isAutoInvite,
                    isPhoneConsentFeatureEnabled: $scope.parseIsPhoneConsentFeatureEnabled($scope.data.contest.isPhoneConsentFeatureEnabled),
                    phoneConsentAcceptanceModeId: $scope.data.contest.phoneConsentAcceptanceModeId,
                    isPrivate: $scope.data.contest.isPrivate,
                    isExactPlaceRanking: $scope.data.contest.isExactPlaceRanking,
                    isInviteeRequestQuotes: $scope.data.contest.isInviteeRequestQuotes,
                    contestCss: $scope.data.contest.contestCss,
                    inviteeBackgroundImageUrl: $scope.data.contest.inviteeBackgroundImageUrl,
                    theme: $scope.data.contest.themeId,
                    inviteePhoneDisplayModeId: $scope.data.contest.inviteePhoneDisplayModeId,
                    friendPhoneDisplayModeId: $scope.data.contest.friendPhoneDisplayModeId
                };

                PhotoContest.update(spacesService.getPropertyUuid(), contestUuid, model)
                    .then(loadContest, loadContest)
                    .then(function(args) {
                        $rootScope.$broadcast('flipto.photo-contest-changed', args[0]);
                    });
            };

            $scope.saveContestDates = function() {
                var model = {
                    startDate: $scope.data.contest.startDate.format('YYYY-MM-DD'),
                    endDate: $scope.data.contest.endDate.format('YYYY-MM-DD')
                };
                ftTasks.register('update-email-images');
                PhotoContest.update(spacesService.getPropertyUuid(), contestUuid, model)
                    .then(loadContest, loadContest)
                    .then(function(args) {
                        $rootScope.$broadcast('flipto.photo-contest-changed', args[0]);
                    })
                    .finally(function() {
                        ftTasks.finish('update-email-images');
                    });
            };

            $scope.updateBannerImageStatus = function(signal) {
                $scope.$apply(function() {
                    if (signal.contestUuid === $scope.model.contestUuid.toLowerCase()) {
                        var imageInfo;
                        if (signal.imageType === 'prearrival') {
                            imageInfo = _.find($scope.data.contest.prearrivalEmailImages.item, function(image) {
                                return image.code === signal.languageCode;
                            });
                        } else if (signal.imageType === 'poststay') {
                            imageInfo = _.find($scope.data.contest.poststayEmailImages.item, function(image) {
                                return image.code === signal.languageCode;
                            });
                        }
                        if (imageInfo) {
                            imageInfo.imageUUID = signal.imageUuid;
                            imageInfo.isImageProcessed = true;
                        }
                    }
                });
            };

            $scope.onUseBackgroundImage = function() {
                if ($scope.model.isUseDefaultImage) {
                    delete $scope.data.contest.backgroundImage;
                }
            };

            $scope.saveName = function(diffs) {
                // fix path for transformer
                _.each(diffs, function(diff) {
                    diff.path.splice(0, 4);
                });
                var languages = changesTransformer(transformType.MultiLanguageData, diffs, $scope.data.contest.languages.item);
                var model = {
                    languages: _.map(languages, function(language) {
                        return {
                            name: language.contestName,
                            languageCode: language.languageCode
                        };
                    })
                };
                ftTasks.register('update-email-images');
                PhotoContest.update(spacesService.getPropertyUuid(), contestUuid, model)
                    .then(loadContest, loadContest)
                    .then(function(args) {
                        $rootScope.$broadcast('flipto.photo-contest-changed', args[0]);
                    })
                    .finally(function() {
                        ftTasks.finish('update-email-images');
                    });
            };

            $scope.saveGoals = function(goals, type) {
                var goals = updateGoalsSerializer.serialize({ type: type, goals: goals }, $scope.data.originalGoals);
                if (goals.deleteGoals.length == 0 && goals.newGoals.length == 0 && goals.updateGoals.length == 0) return;
                PhotoContest.update(spacesService.getPropertyUuid(), contestUuid, { friendsGoals: goals }).then(loadContest, loadContest);
            };

            $scope.saveFinalistIncentives = function() {
                var model = {
                    finalistsIncentives: {
                        firstWinnerIncentiveUUID: $scope.data.contest.finalistsIncentives.firstWinnerIncentive ? $scope.data.contest.finalistsIncentives.firstWinnerIncentive.incentiveUUID : null,
                        secondWinnerIncentiveUUID: $scope.data.contest.finalistsIncentives.secondWinnerIncentive ? $scope.data.contest.finalistsIncentives.secondWinnerIncentive.incentiveUUID : null,
                        thirdWinnerIncentiveUUID: $scope.data.contest.finalistsIncentives.thirdWinnerIncentive ? $scope.data.contest.finalistsIncentives.thirdWinnerIncentive.incentiveUUID : null,
                        finalistIncentiveUUID: $scope.data.contest.finalistsIncentives.finalistIncentive ? $scope.data.contest.finalistsIncentives.finalistIncentive.incentiveUUID : null,
                        totalRunnerUps: $scope.data.contest.finalistsIncentives.runnerUpsIncentive ? $scope.data.contest.finalistsIncentives.runnerUpsIncentive['@totalRunnerUps'] : null,
                        runnerUpsIncentiveUUID: $scope.data.contest.finalistsIncentives.runnerUpsIncentive ? $scope.data.contest.finalistsIncentives.runnerUpsIncentive['incentiveUUID'] : null
                    }
                };
                ftTasks.register('update-email-images');
                PhotoContest.update(spacesService.getPropertyUuid(), contestUuid, model)
                    .then(loadContest, loadContest)
                    .then(function(args) {
                        $rootScope.$broadcast('flipto.photo-contest-changed', args[0]);
                    })
                    .finally(function() {
                        ftTasks.finish('update-email-images');
                    });
            };

            $scope.updateState = function() {
                PhotoContest.activate(spacesService.getPropertyUuid(), contestUuid, $scope.model.isActive)
                    .then(function() {
                        $rootScope.$broadcast('flipto.photo-contest-activated');
                    });
            };

            $scope.updateInviteeImage = function(image) {
                $scope.data.contest.inviteeBackgroundImageUrl = !!image ? image.url : null;
            };

            $scope.updateImage = function(image) {
                $scope.data.contest.backgroundImage = !!image ? image.url : null;
            };

            $scope.updateBackgroundImage = function() {
                ftTasks.register('update-email-images');
                PhotoContest.updateBackgroundImage(spacesService.getPropertyUuid(), contestUuid, $scope.data.contest.backgroundImage)
                    .then(loadContest, loadContest)
                    .then(function(args) {
                        $rootScope.$broadcast('flipto.photo-contest-changed', args[0]);
                    })
                    .finally(function() {
                        ftTasks.finish('update-email-images');
                    });
            };

            $scope.updateTheme = function(themeId) {
                PhotoContest.update(spacesService.getPropertyUuid(), contestUuid, { theme: themeId })
                    .then(loadContest, loadContest)
                    .then(function(args) {
                        $rootScope.$broadcast('flipto.photo-contest-changed', args[0]);
                    });
            };

            $scope.refreshPrearrivalImage = function(language) {
                var imageUUID = language.imageUUID;
                PhotoContest.generatePreArrivalEmailImage(spacesService.getPropertyUuid(), contestUuid, language.code)
                    .then(function(newImageUUID) {
                        language.imageUUID = newImageUUID;
                        language.isImageProcessed = true;
                    })
                    .finally(function() {
                        ftTasks.finish('generate-email-image-' + imageUUID + '-' + language.code);
                    });
            };

            $scope.refreshPoststayImage = function(language) {
                var imageUUID = language.imageUUID;
                PhotoContest.generatePoststayEmailImage(spacesService.getPropertyUuid(), contestUuid, language.code)
                    .then(function(newImageUUID) {
                        language.imageUUID = newImageUUID;
                        language.isImageProcessed = true;
                    })
                    .finally(function() {
                        ftTasks.finish('generate-email-image-' + imageUUID + '-' + language.code);
                    });
            };

            $scope.getPreviewImagesCount = function() {
                return _.filter($scope.data.contest.languages.item, function(item) {
                    return item.preArrivalEmailImageID != null;
                }).length;
            };

            $scope.shareInvitation = function(network) {
                var params = { contest: $scope.data.contest.slug, source: network.title };
                if ($scope.model.language.code !== 'en') {
                    params.lang = $scope.model.language.code;
                }
                invitationShareModal.show(
                    $scope.data.contest.id,
                    $scope.data.contest.companyCode,
                    $scope.model.language.contestName,
                    $scope.data.contest.inviteeBackgroundImageUrl,
                    cnameUrl($scope.data.contest.subdomains, 3, $scope.data.contest.propertySlug, params), // TODO: Get property slug elsewhere
                    sessionService.getProperty(spacesService.getPropertyUuid()),
                    network,
                    $scope.model.language.code);
            };

            $scope.isV2NotAdmin = function() {
                return ($scope.isV2 && !identity.isFliptoStaff());
            };

            function setTargetBlankToLinks(html) {
                if (!html) {
                    return html;
                }

                const parser = new DOMParser();
                const doc = parser.parseFromString(html, 'text/html');

                const body = doc.querySelector('body');
                const links = body.querySelectorAll('a');

                links.forEach(link => {
                    if (!link.hasAttribute('target') || link.getAttribute('target') !== '_blank') {
                        link.setAttribute('target', '_blank');
                    }
                });

                return body.innerHTML.replace(/&nbsp;/g, ' ');
            }

            function loadContest() {
                return $q.all([
                    PhotoContest.load({ contestId: contestUuid, propertyUuid: spacesService.getPropertyUuid() }),
                    Incentives.getAvailableIncentives(spacesService.getPropertyUuid()),
                    Image.getPropertyImages({ propertyId: spacesService.getPropertyUuid() })])
                    .then(function(args) {

                        PhotoContest.getInvitationShareImageTemplate(
                            spacesService.getPropertyUuid(),
                            {
                                SocialApplication: parse.int($scope.model.networks.fb.socialNetworkType),
                                ContestUUID: contestUuid,
                                LanguageCode: 'en',
                                propertyUuid: spacesService.getPropertyUuid()
                            }
                        ).then(function(data) {
                            $scope.model.shareImagePreviewTemplate = data.template;
                        });


                        var contestDetails = args[0],
                            propertyIncentives = args[1].data,
                            propertyImages = args[2]['asset_images'] ? args[2]['asset_images'] : [];

                        angular.extend($scope, {
                            data: {
                                contest: angular.copy(contestDetails),
                                originalGoals: angular.copy(contestDetails.goals ? contestDetails.goals.item : []),
                                propertyIncentives: propertyIncentives,
                                propertyImages: propertyImages
                            }
                        });

                        $scope.data.contest.subdomains = parse.arrayOrDefault($scope.data.contest.subdomains);
                        $scope.data.contest.prearrivalEmailImageIsActive = parse.bool($scope.data.contest.prearrivalEmailImageIsActive);
                        $scope.data.contest.poststayEmailImageIsActive = parse.bool($scope.data.contest.poststayEmailImageIsActive);
                        $scope.data.contest.isAutoInvite = parse.bool($scope.data.contest.isAutoInvite);
                        $scope.data.contest.isPrivate = parse.bool($scope.data.contest.isPrivate);
                        $scope.data.contest.isExactPlaceRanking = parse.bool($scope.data.contest.isExactPlaceRanking);
                        $scope.data.contest.isInviteeRequestQuotes = parse.bool($scope.data.contest.isInviteeRequestQuotes);
                        $scope.data.contest.startDate = moment($scope.data.contest.startDate);
                        $scope.data.contest.endDate = moment($scope.data.contest.endDate);

                        $scope.model.isActive = parse.bool($scope.data.contest.isActive);
                        $scope.model.language = _.find($scope.data.contest.languages.item, { code: 'en' });
                        $scope.model.isUseDefaultImage = !contestDetails.backgroundImage;

                        return args;
                    });
            }
        }]);
