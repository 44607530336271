import { Pipe, PipeTransform } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DateUtils } from 'shared';

@Pipe({
    name: 'dateInMonth'
})
export class DateInMonthPipe implements PipeTransform {
    constructor() {
    }

    transform(date: Date | NgbDate, month: NgbDate): boolean {
        if (date && month) {
            let d = date;
            if (d instanceof Date) {
                d = DateUtils.dateToNgbDate(d)
            }
           return d.month === month.month;
        }
        return false;
    }
}
