/**
 * Created by ostapenko.r on 9/22/2014.
 */

angular.module('flipto.core.api.postStay', []).config([
	'$provide',
	function($provide) {
		$provide.factory('PostStay', [
			'$http',
			'urlFormatter',
			'parse',
			'_',
			function($http, urlFormatter, parse, _) {
				/**
				 * PostStay service
				 * @constructor
				 */
				function PostStay() {
					/**
					 * Loads all post-stay versions defined in the system
					 * @returns {*}
					 */
					this.versions = function() {
						return $http
							.get('/api/poststayversions')
							.then(function(response) {
								return response.data;
							})
							.then(function(versions) {
								versions = parse.arrayOrDefault(versions);
								return _.map(versions, function(v) {
									v.id = parse.intOrDefault(v.id);
									v.isActive = parse.bool(v.isActive);
									return v;
								});
							});
					};

					/**
					 * Saves the post-stay version for the specified campaign
					 * @returns {*}
					 * @param campaignUUID
					 * @param version
					 */
					this.version = function(campaignUUID, version) {
						return $http
							.put(
								urlFormatter.format(
									'/api/properties/layers/poststay/campaigns/:campaignUUID/versions?postStayVersion=:postStayVersion',
									{campaignUUID: campaignUUID, postStayVersion: version}
								),
								{}
							)
							.then(function(response) {
								return response.data;
							});
					};

					/**
					 * Load campaigns
					 * @param propertyUUID
					 * @param startDate
					 * @param endDate
					 */
					this.loadCampaigns = function(propertyUUID, startDate, endDate) {
						var model = {
							propertyUUID: propertyUUID,
							startDate: startDate,
							endDate: endDate
						};
						return $http
							.get(
								urlFormatter.format(
									'/api/:propertyUUID/properties/:propertyUUID/layers/poststay/campaigns',
									model,
									true
								)
							)
							.then(function(response) {
								return response.data;
							});
					};

					/**
					 * Returns stories
					 * @param propertyUUID
					 * @param startDate
					 * @param endDate
					 * @returns {*}
					 */
					this.loadStoryInfo = function(propertyUUID, startDate, endDate) {
						return $http
							.get(
								urlFormatter.format(
									'/api/:propertyUUID/properties/:propertyUUID/layers/poststay/stories',
									{
										propertyUUID: propertyUUID,
										startDate: startDate,
										endDate: endDate
									},
									true
								)
							)
							.then(function(response) {
								return response.data;
							});
					};
				}

				return new PostStay();
			}
		]);
	}
]);
