/**
 * Created by Roman on 2015-07-24.
 */

angular.module('flipto.components.common.fileUpload', ['flipto.components.common.tasks'])
    .factory('fileUpload', ['$http', function ($http) {

        /**
         * File uploader.
         * @param $http
         * @constructor
         */
        function FileUpload($http) {
            /**
             * $http reference
             */
            this._$http = $http;
        }

        /**
         * Upload file to specified url
         * @param url
         * @param file
         */
        FileUpload.prototype.upload = function (url, file) {

            var data = new FormData();
            data.append('file', file, file.name);

            return this._$http.post(url, data, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        return new FileUpload($http);
    }])
    .directive('ftFileUpload', ['fileUpload', 'ftTasks', function (fileUpload, ftTasks) {

        return {
            restrict: 'E',
            scope: {
                'url': '@',
                'title': '@',
                'onSuccess': '&',
                'onError': '&'
            },
            templateUrl: '/app/components/common/file-upload/fileUpload.html',
            link: function (scope, elem) {

                if (!scope.url) throw new Error('Upload url is not defined');
                scope.title = scope.title || 'Upload';
                var fileInput = elem.find('input'),
                    trigger = elem.find('button');

                /**
                 * Trigger input[file] click
                 */
                scope.trigger = function () {
                    fileInput[0].click();
                };

                var file;
                fileInput.on('change', function () {
                    var files = fileInput[0].files;
                    file = files.length ? files[0] : undefined;
                    if (!file) return;

                    ftTasks.register('groups.upload');
                    fileUpload.upload(scope.url, file)
                        .then(function (response) {
                            scope.onSuccess && scope.onSuccess({response: response});
                        }, function (response) {
                            scope.onError && scope.onError({response: response});
                        })
                        .finally(function () {
                            ftTasks.finish('groups.upload');
                            fileInput[0].value = null;
                        });
                });

                scope.$on('$destroy', function () {
                    fileInput.unbind('change');
                })
            }
        };
    }]);
