/**
 * Created by ostapenko.r on 11/5/2014.
 */

angular.module('flipto.components.common.list')
    .directive('ftHideWrapper', ["$animate", function ($animate) {
        return {
            restrict: 'A',
            require: '^?ftList',
            compile: function(element, attrs){

                return function(scope, element, attributes, ftList){
                    if(!ftList) return;

                    var hideWrapper = angular.isDefined(attributes.ftHideWrapper) && attributes.ftHideWrapper!='false';
                    if(hideWrapper){
                    var expression = ftList.getExpression();
                    scope.$watchCollection(expression.property, function(items){
                        var parentElem = element.parent();
                        $animate[items.length > 0 ? "removeClass":"addClass"](parentElem, "ng-hide");
                        /*if(items.length>0){
                            element.parent().show();
                        }else{
                            element.parent().hide();
                        }*/
                    })
                    }
                }
            },
            controller: ['$scope', function($scope){

            }]
        };
    }]);