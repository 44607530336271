import { BannerPrearrivalEmailSettings } from './../../models/banner-email';
import {
    loadCampaignGuests,
    loadCampaignGuestsSuccess,
    loadCampaignGuestsFail,
    saveCampaignGuests,
    saveCampaignGuestsSuccess,
    saveCampaignGuestsFail
} from './actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { JourneysApiService } from '../../services/journeys-api.service';

@Injectable()
export class CampaignGuestsEffects {
    constructor(private actions$: Actions,
        private store$: Store<any>,
        private journeysApiService: JourneysApiService) {
    }

    loadData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadCampaignGuests),
            mergeMap(({componentGroupUuid, propertyId, state}) =>
                this.journeysApiService.getTouchpointCampaignGuestslData(componentGroupUuid, propertyId, state)
                    .pipe(
                        map((settings: any) => {
                            return loadCampaignGuestsSuccess({data: settings});
                        }),
                        catchError(error => of(loadCampaignGuestsFail({error})))
                    ))
        )
    );

    saveChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(saveCampaignGuests),
            withLatestFrom(this.store$),
            mergeMap(
                ([{ componentGroupUuid, propertyId, state, changes }]) => this.journeysApiService.saveCampaignGuestsMessages(
                    componentGroupUuid, propertyId, state, changes
                ).pipe(map(() => {
                    return saveCampaignGuestsSuccess();
                }),
                    catchError(error => {
                        return of(saveCampaignGuestsFail(error));
                    })
                )
            )
        )
    );
}
