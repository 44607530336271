/**
 * User: Kupin.R
 * Date: 5/20/14
 */



angular
    .module("flipto.core.jsbeautify", [])
    .factory("jsBeautify",
        [
            "$window",
            function ($window) {
                return $window.js_beautify;

            }
        ]);
