
import { Action, createReducer, on } from "@ngrx/store";
import {
    commitInvitationEmail,
    commitInvitationEmailSuccess,
    loadinvitationEmail,
    loadinvitationEmailFail,
    loadinvitationEmailSuccess,
    loadStylingInvitationEmail,
    loadStylingInvitationEmailFail,
    loadStylingInvitationEmailSuccess
} from "./actions";
import { State } from "./state";

export const reducer = createReducer(
    {
        loading: false,
        settings: null,
        commitSuccess: false,
        stylingSettings: null
    } as State,
    on(loadinvitationEmail, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(loadinvitationEmailSuccess,  (state, {data}) => {
        return {
            ...state,
            loading: false,
            settings: data
        };
    }),
    on(loadinvitationEmailFail, (state, {error}) => {
        return {
            ...state,
            loading: false,
            error
        };
    }),
    // commit
    on(commitInvitationEmail, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(commitInvitationEmailSuccess, (state, {data}) => {
        return {
            ...state,
            loading: false,
            settings: data
        };
    }),
    // styling
    on(loadStylingInvitationEmail, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(loadStylingInvitationEmailSuccess, (state, {data}) => {
        return {
            ...state,
            loading: false,
            stylingSettings: data
        };
    }),
    on(loadStylingInvitationEmailFail, (state, {error}) => {
        return {
            ...state,
            loading: false,
            error
        };
    }),
);

export function invitationEmailReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}
