<ft-authentication-wrapper>
    <ng-container *ngIf="(tokenVerificationLoading$ | async) else verified">
        <p>Verification...</p>
    </ng-container>
    <ng-template #verified>
        <ng-template #changePassword>
            <div class="ft2-container mb-5">
                <p class="mb-5">
                    Hi, let’s set up your account for {{(userData$ | async)?.email}}.
                    <br>Just provide the information below to access organization.
                </p>
            </div>
            <form class="ft2-auth-container" #form="ngForm" (ngSubmit)="submit(form.value)">
                <ft-change-password-errors [error]="createPasswordError$ | async"></ft-change-password-errors>
                <input class="ft2-input"
                       type="email"
                       placeholder="Email"
                       name="email"
                       autocomplete="username"
                       hidden
                       [ngModel]="(userData$ | async)?.email">
                <input class="ft2-input"
                       type="text"
                       placeholder="First name"
                       autocomplete="off"
                       name="firstName"
                       required
                       [ngModel]="(userData$ | async)?.firstName">
                <input class="ft2-input"
                       type="text"
                       placeholder="Last name"
                       autocomplete="off"
                       name="lastName"
                       required
                       [ngModel]="(userData$ | async)?.lastName">
                <div class="ft2-input-wrapper">
                    <input class="ft2-input"
                           #input
                           type="password"
                           name="password"
                           placeholder="New password"
                           ftPasswordStrength
                           [(ngModel)]="password">
                    <ft-password-toggle class="ft2-input-icon" [inputRef]="input"></ft-password-toggle>
                    <ft-password-strength-checklist [inputRef]="input">
                    </ft-password-strength-checklist>
                </div>
                <div class="mt-2">
                    <button class="ft2-btn-primary -large -wide"
                            [disabled]="form.invalid"
                            [ftLoading]="[changeUserLoading$, createPasswordLoading$]">
                        Save and sign in
                    </button>
                </div>
            </form>

        </ng-template>
        <ft-request-link *ngIf="(tokenVerificationError$ | async) else changePassword"
                         [error]="(resendUserError$ | async)"
                         [loading$]="resendUserLoading$"
                         [isLinkSent]="(isUserResent$ | async)"
                         (sendLink)="requestLink()">
        </ft-request-link>
    </ng-template>
</ft-authentication-wrapper>
