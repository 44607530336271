
import PropertyApi from "../../actions/propertyApi";

export const NAME = "ftRecommendationNotification";

export class RecommendationNotificationController {

    static $inject = ["$ngRedux", "$rootScope", "BroadcastModal"];

    recommendation: any;
    propertyUuid;
    isExpanded;
    isClosed = false;

    constructor(
        public $ngRedux,
        public $rootScope,
        public BroadcastModal) {
    }

    $onInit() {
    }

    expand() {
        this.isExpanded = true;
    }

    skip() {
        this.isExpanded = false;
    }

    close() {
        this.isClosed = true;
        this.$ngRedux.dispatch(PropertyApi.closeRecommendation(this.recommendation.target_property_uuid, this.recommendation.recommendation_uuid, false))
            .then(() => this.$rootScope.$broadcast("[StoryModal].RecommendationComplete"));
    }

    start() {
        if (this.recommendation.recommendation_type_id) {
            this.BroadcastModal.broadcastStoryFromRecommendation(this.propertyUuid, this.recommendation.recommendation_data.campaign_promoter_uuid, this.recommendation);
        }
    }
}


export const OPTIONS = {
    bindings: {
        recommendation: "<?",
        propertyUuid: "<?",
        isExpanded: "<?"
    },
    template: `
        <div class="ft-alert-section" ng-if="!!vm.recommendation && !vm.isClosed">
            <div class="ft-alert recommendation" ng-class="{'is-collapsed' : !vm.isExpanded, 'is-expanded' : vm.isExpanded}">
                <div class="ft-alert-bg"></div>
                <div class="ft-alert-content" ng-if="!vm.isExpanded" ng-click="vm.expand()">
                    <div class="ft-recommender-profile-pic">
                        <ft-image src="vm.recommendation.profile_image_url" ft-profile-photo always-visible></ft-image>
                    </div>
                    <div class="ft-recommendation-summary">
                        <p class="ft-person-name">{{vm.recommendation.first}} recommends...</p>
                        <p class="ft-text-plus-1">Broadcast this story.</p>
                                <!-- TODO: calculate correct days left with pluralization.
                                    if days < 1, show 'Less than a day'
                                    if days = 1, show '1 day'
                                    if days > 1, plural show '# days' -->
                        <p class="ft-timestamp">{{vm.recommendation.expiration_date | daysUntilDate}} days left to view</p>
                    </div>
                </div>
                <div class="ft-alert-content" ng-if="vm.isExpanded">
                    <button class="ft-btn-close" ng-click="vm.skip()"></button>
                    <div class="ft-recommender-profile-pic">
                        <ft-image src="vm.recommendation.profile_image_url" ft-profile-photo always-visible></ft-image>
                    </div>
                    <p class="ft-person-name">{{vm.recommendation.first}}</p>
                    <div class="ft-recommendation-summary">
                        <p class="ft-text-plus-1">&ldquo;{{vm.recommendation.message}}&rdquo;</p>
                        <button class="ft-btn ft-btn-yellow ft-btn-confirm" ng-click="vm.start()">
                            Start recommended broadcast
                        </button>
                        <p class="ft-text-fineprint">
                            No thanks, <a class="ft-link-underline" ng-click="vm.close()">remove</a> this recommendation.
                        </p>
                    </div>
                </div>
            </div>
        </div>

    `,
    controller: RecommendationNotificationController,
    controllerAs: "vm"
}