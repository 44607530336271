
export function cacheInterceptor($injector) {

    return {
        request: function (config) {
            if (config.url.indexOf("/api") !== -1) {
                const cache = $injector.get("ftCache");
                if (config.method == "POST" || config.method == "PUT" || config.method == "DELETE") {
                    cache.removeAll();
                }
            }
            return config;
        }
    };
}

export namespace cacheInterceptor {
    export let $inject = [
        "$injector"
    ];
}