import { Component, ContentChild, TemplateRef, Input, HostListener, ElementRef } from '@angular/core';

@Component({
  selector: 'ft-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.css']
})
export class TooltipComponent {
  isVisible: boolean = false;
  timeout: any;

  @Input() hideDelay: number;

  @ContentChild(TemplateRef, { static: false })
  tooltip: TemplateRef<any>;

  constructor(private eRef: ElementRef) { }

  show() {
    setTimeout(() => (this.isVisible = true), 10);
    if (this.hideDelay) this.timeout = setTimeout(() => (this.isVisible = false), this.hideDelay);
  }

  @HostListener('mouseenter')
  mouseEnter() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  @HostListener('document:click', ['$event'])
  click(event) {
    if (!this.eRef.nativeElement.contains(event.target) && this.isVisible) {
      this.isVisible = false;
    }
  }
}
