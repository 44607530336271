/**
 * Created by roman.kupin on 2/9/2018.
 */


export class CampaignContainerService {

    static Name = "CampaignContainer";
    static $inject = ["$compile", "$rootScope"];

    private _scope: any;
    private _elem: any;

    constructor(private $compile,
                private $rootScope) {
    }

    showExisting(propertyUuid, campaignUuid, layerName) {
        this.ensureElement();
        Object.assign(this._scope, {
            $ftCampaignContainer: {
                propertyUuid,
                campaignUuid,
                layerName,
                isCreating: false
            }
        });
    }

    createNew(propertyUuid, layerName) {
        this.ensureElement();
        Object.assign(this._scope, {
            $ftCampaignContainer: {
                propertyUuid,
                layerName,
                isCreating: true
            }
        });
    }

    hide() {
        this.ensureElement();
        this._scope.$ftCampaignContainer = null;
    }

    private ensureElement() {
        if (!this._scope) {
            this.insertElement();
        }
    }

    private insertElement() {
        this._scope = this.$rootScope.$new(true);
        this._elem = this.$compile(`
<ft-campaign-container 
    property-uuid="$ftCampaignContainer.propertyUuid"
    campaign-uuid="$ftCampaignContainer.campaignUuid"
    layer-name="$ftCampaignContainer.layerName"
    is-creating="$ftCampaignContainer.isCreating"
    ng-if="!!$ftCampaignContainer"></ft-campaign-container>`)(this._scope);

        document.body.appendChild(this._elem[0]);
        this._scope.$on("$destroy", () => {
            this._elem.remove();
            this._scope = null;
        });
    }

}