import { commitBannerEmail, commitBannerEmailFail, commitBannerEmailSuccess, loadBannerEmailFail, loadBannerEmailSuccess, loadStylingBannerEmail, loadStylingBannerEmailFail, loadStylingBannerEmailSuccess, saveBannerEmail, saveBannerEmailFail, saveBannerEmailSuccess } from './actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { loadBannerEmail } from '.';
import { JourneysApiService } from '../../services/journeys-api.service';
import { BannerEmailSettings, BannerEmailStylingSettings } from '../../models/banner-email';

@Injectable()
export class BannerEmailEffects {
    constructor(private actions$: Actions,
        private store$: Store<any>,
        private journeysApiService: JourneysApiService) {
    }

    load$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadBannerEmail),
            mergeMap(({componentGroupUuid, propertyId, typeRequest, state}) =>
                this.journeysApiService.getTouchpointBannerEmailData(componentGroupUuid, propertyId, typeRequest, state)
                    .pipe(
                        map((settings: BannerEmailSettings) => {
                            return loadBannerEmailSuccess({data: settings});
                        }),
                        catchError(error => of(loadBannerEmailFail({error})))
                    ))
        )
    );

    commit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(commitBannerEmail),
            mergeMap(({componentGroupUuid, propertyId, isActive}) =>
                this.journeysApiService.commitTouchpointBannerEmail(componentGroupUuid, propertyId, isActive)
                    .pipe(
                        map((settings: BannerEmailSettings) => {
                            return commitBannerEmailSuccess({data: settings});
                        }),
                        catchError(error => of(commitBannerEmailFail({error})))
                    ))
        )
    );

    loadStyling$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadStylingBannerEmail),
            mergeMap(({componentGroupUuid, propertyId, typeRequest, state}) =>
                this.journeysApiService.getStylingBannerEmailData(componentGroupUuid, propertyId, typeRequest, state)
                    .pipe(
                        map((stylingSettings: BannerEmailStylingSettings) => {
                            return loadStylingBannerEmailSuccess({data: stylingSettings});
                        }),
                        catchError(error => of(loadStylingBannerEmailFail({error})))
                    ))
        )
    );

    saveChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(saveBannerEmail),
            withLatestFrom(this.store$),
            mergeMap(
                ([{ componentGroupUuid, propertyId, typeRequest, changes }]) => this.journeysApiService.saveTouchpointBannerEmail(
                    componentGroupUuid, propertyId, typeRequest, changes
                ).pipe(map(() => {
                    return saveBannerEmailSuccess();
                }),
                    catchError(error => {
                        return of(saveBannerEmailFail(error));
                    })
                )
            )
        )
    );
}
