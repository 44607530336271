angular
    .module('flipto.accountv1.SharedFeatures')
    .controller('flipto.account.sections.settings.DemosController',
    ['parse', 'Demo', 'Demotype', "urlFormatter", "PropertyDemos", '_', 'SpacesService', 'SessionService', 'IdentityService', 'ftModal', 'livePreview',
        function (parse, Demo, Demotype, urlFormatter, PropertyDemos, _, spacesService, sessionService, identity, ftModal, livePreview) {
            'use strict';

            var vm = this;
            _.extend(vm, {
                model: {
                    demos: [],
                    demotypes: []
                },
                demo: {
                    demotype: {}
                },
                edit: false
            });
            
            PropertyDemos.getAllDemos({ propertyId: spacesService.getPropertyUuid() }).then(function (data) {
                vm.model.demos = _.map(data[0], function (demo) {
                    return new Demo(demo.name, demo.type, demo.modified_date, demo.html, demo.id);
                });

                vm.model.demotypes = _.map(data[1], function (demotype) {
                    return new Demotype(demotype.name, demotype.id, demotype.snippet);
                });
            }, function (response) {
                alert("Attempt to request demos from database failed - " + response.statusText);
            });

            vm.findDemotypeByID = function (id) {
                return _.extend({}, _.find(vm.model.demotypes, function (demotype) { return demotype.id === id; }));
            }

            vm.findDemoIndexByUUID = function (uuid) {
                return _.findIndex(vm.model.demos, function (demo) { return demo.UUID === uuid; });
            }

            vm.openDemoModal = function (demo) {
                var demoIsDefined = angular.isDefined(demo);
                demoIsDefined ? _.extend(vm.demo, demo, {
                    demotype: vm.findDemotypeByID(demo.demotypeID)
                }) : _.extend(vm.demo, {
                    description: "",
                    demotype: {},
                    HTML: "",
                    UUID: "",
                    URL: ""
                });
                vm.edit = demoIsDefined;
                ftModal.show('demos.demoModal');
            }

            vm.commitDemo = function () {
                vm.edit ? vm.editDemo() : vm.addDemo();
            }

            vm.addDemo = function () {
                var demo = vm.demo;
                demo.modifiedDate = new Date();
                PropertyDemos.createDemo({ propertyId: spacesService.getPropertyUuid() }, { Type: demo.demotype.id, Name: demo.description, Html: demo.HTML }).then(function successCallback(response) {
                    vm.model.demos.push(new Demo(demo.description, demo.demotype.id, demo.modifiedDate, demo.HTML, response));
                }, function errorCallback(response) {
                    alert("Add New Demo Failed - " + response.statusText);
                });
            }

            vm.removeDemo = function (uuid) {
                ftModal.hide("demos.demoModal");
                PropertyDemos.removeDemo({ propertyId: spacesService.getPropertyUuid(), assetDemoUUID: uuid }).then(function successCallback() {
                    _.remove(vm.model.demos, function (demo) { return demo.UUID === uuid; });
                }, function errorCallback(response) {
                    alert("Remove Failed - " + response.statusText);
                });
            }

            vm.editDemo = function () {
                var demo = vm.demo;
                demo.modifiedDate = new Date();
                PropertyDemos.updateDemo({ propertyId: spacesService.getPropertyUuid() }, { AssetDemoUUID: demo.UUID, Type: demo.demotype.id, Name: demo.description, Html: demo.HTML }).then(function successCallback() {
                    vm.model.demos[vm.findDemoIndexByUUID(demo.UUID)] = new Demo(demo.description, demo.demotype.id, new Date(), demo.HTML, demo.UUID);
                }, function errorCallback(response) {
                    alert("Edit Failed - " + response.statusText);
                });
            }


            var REPLACE_TARGET = /(\<head(?:\s[^\>]*)?>)/gi;

            vm.scrape = function (fetchUrl) {
                if (angular.isDefined(fetchUrl)) {
                    vm.demo.HTML = "Loading...";
                    PropertyDemos.scrapeHTML({ propertyId: spacesService.getPropertyUuid(), url: fetchUrl })
                        .then(function (data) {
                            vm.demo.HTML = data.replace(REPLACE_TARGET, "$1" + "<base href=\"" + fetchUrl + "\"/>");
                        }, function (response) {
                            alert('Fetch Failed - ' + response.statusText);
                            vm.demo.HTML = "";
                        });
                }
            };

            vm.injectScript = function () {
                var injection = vm.findDemotypeByID(vm.demo.demotype.id).script;
                var injectionVictim = vm.demo.HTML;

                var scripts = injectionVictim.match(/<script( )?(type="text\/javascript")?>[^>]+>/gmi);
                var scriptIndices = [];
                _.forEach(scripts, function (value, index) {
                    if (value.indexOf("(function(f,l,i,p,t,o)") > -1 || parse.arrayOrDefault(value.match(/flipto/gmi)).length > 1) {
                        scriptIndices.push(index);
                    }
                });
                _.forEach(scriptIndices, function (value) {
                    if (confirm("You are about to replace this script: " + scripts[value] + "\nDo you wish to continue?")) {
                        injectionVictim = _.replace(injectionVictim, scripts[value], "");
                    }
                });

                injectionVictim = injectionVictim.replace(REPLACE_TARGET, "$1" + injection);
                vm.demo.HTML = injectionVictim;
            };

            vm.openCustomDemoPageLivePreview = function (uuid, type) {
                livePreview.customDemoPage({ property: sessionService.getProperty(spacesService.getPropertyUuid()).Slug, type: type.replace(/\s+/g, ''), assetDemoUUID: uuid.toLowerCase() });
            }
        }
    ]);