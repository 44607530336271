/**
 * Created by Roman on 2015-04-03.
 */


angular.module('flipto.components.common.modelFormatters')
    .service('htmlSanitizer', [function() {

        /**
         * Sanitize html
         * @constructor
         */
        function HtmlSanitizer() {

        }

        /**
         * Remove empty trailing paragraphs
         */
        HtmlSanitizer.prototype.removeTrailingParagraphs = function(html) {
            var hasMoreThings = true,
                sanitized = html,
                tmp = html;
            while (hasMoreThings) {
                sanitized = tmp.replace(/<p>(<br\s?\/>)?<\/p>$/gi, '');
                hasMoreThings = sanitized !== tmp;
                if (hasMoreThings) {
                    tmp = sanitized;
                }
            }
            return sanitized;
        };

        HtmlSanitizer.prototype.removeSelectionBoundary = function(html) {
            var hasMoreThings = true,
                sanitized = html,
                tmp = html;
            while (hasMoreThings) {
                sanitized = tmp.replace(/<span id="selectionBoundary_\d+_\d+" class="rangySelectionBoundary">[^<]*<\/span>/gi, '');
                hasMoreThings = sanitized !== tmp;
                if (hasMoreThings) {
                    tmp = sanitized;
                }
            }
            return sanitized;
        };

        /**
         * Removes wrapper tag from html
         * @param html Html string
         * @param wrapper html tag i.e <p>
         * @returns {*}
         */
        HtmlSanitizer.prototype.removeWrapper = function(html, wrapper) {
            var sanitized = html;
            sanitized = sanitized.replace(new RegExp('<' + wrapper + '.*?>(.*?)</' + wrapper + '>', 'gi'), '$1');
            return sanitized;
        };

        /**
         * Replace wrapper tag with another wrapper
         * @param html html text
         * @param wrapper html tag i.e <p>
         * @param replaceWithStart <div class="some-class">
         * @param replaceWithEnd </div>
         * @returns {*}
         */
        HtmlSanitizer.prototype.replaceWrapper = function(html, wrapper, replaceWithStart, replaceWithEnd) {
            var sanitized = html;
            sanitized = sanitized.replace(new RegExp('<' + wrapper + '.*?>(.*?)</' + wrapper + '>', 'gi'), replaceWithStart + '$1' + replaceWithEnd);
            return sanitized;
        };

        /**
         * Trim html
         * @param html
         * @returns {*}
         */
        HtmlSanitizer.prototype.trim = function(html) {
            return angular.isString(html) ? html.trim() : html;
        };

        HtmlSanitizer.prototype.linksNewWindow = function(html) {
            if (angular.isString(html)) {
                const dom = document.createElement('div');
                dom.innerHTML = html;
                const links = dom.querySelectorAll('a');
                links?.forEach(link => link.setAttribute('target', '_blank'));
                const modifiedHtml = dom.innerHTML;
                dom.remove();
                return modifiedHtml;
            }

            return html;
        };

        return new HtmlSanitizer();
    }])
    .directive('ftHtmlSanitize', ['htmlSanitizer', function(htmlSanitizer) {

        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, elem, attrs, ngModel) {

                /*assuming defaults:
                 * wrapper -> p
                 * replacing with -> <div class="flipto-paragraph">...</div>
                 * */
                var options = scope.$eval(attrs.ftHtmlSanitize);
                ngModel.$parsers.push(htmlSanitizer.removeTrailingParagraphs);
                ngModel.$parsers.push(htmlSanitizer.removeSelectionBoundary);

                if (!!options.removeWrapper) {
                    ngModel.$parsers.push(function(html) {
                        return htmlSanitizer.removeWrapper(html, attrs.wrapper || 'p');
                    });
                }

                if (!!options.replaceWrapper) {
                    ngModel.$parsers.push(function(html) {
                        return htmlSanitizer.replaceWrapper(html,
                            attrs.wrapper || 'p',
                            attrs.replaceWithStart || '<div class="flipto-paragraph">',
                            attrs.replaceWithEnd || '</div>'
                        );
                    });
                }

                if (options.linksNewWindow) {
                    ngModel.$parsers.push(function(html) {
                        return htmlSanitizer.linksNewWindow(html);
                    });
                }

                ngModel.$parsers.push(htmlSanitizer.trim);
            }
        };
    }]);
