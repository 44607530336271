import { AbstractControl, AsyncValidator, ValidationErrors, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { Directive } from '@angular/core';
import { ShortUrlService } from '../short-urls.service';
import { map, catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Directive({
  selector: '[shortLinkCodeUnique]',
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: ShortLinkCodeUniqueValidatorDirective, multi: true }]
})
export class ShortLinkCodeUniqueValidatorDirective implements AsyncValidator {
  constructor(private shortUrls: ShortUrlService) { }

  validate(ctrl: AbstractControl): Observable<ValidationErrors | null> {
    return this.shortUrls
      .validateShortCode(ctrl.value)
      .pipe(
        map(code => ({ shortLinkCodeUnique: true })),
        catchError(() => of(null))
      );
  }
}
