/**
 * Created by ostapenko.r on 9/26/2014.
 */
angular.module('flipto.accountv1.SharedFeatures')
    .controller('flipto.account.sections.platform.StylingController',
    ['$scope', 'styling', 'Styling', 'endpointUrl', '$log', 'layerName', 'touchpointType', 'touchpointGroupId', '$sce', 'changesTransformer', 'transformType',
        '_', 'Touchpoint', '$filter', '$rootScope', 'parse', 'IdentityService', 'versionSelection', 'SpacesService', 'SessionService', 'propertyImages', 'ConfirmationImage',
        'ftTasks', 'languageHelper', '$controller',
        function ($scope, styling, Styling, endpointUrl, $log, layerName, touchpointType, touchpointGroupId, $sce, changesTransformer, transformType,
                  _, Touchpoint, $filter, $rootScope, parse, identity, versionSelection, spacesService, sessionService, propertyImages, ConfirmationImage,
                ftTasks, languageHelper, $controller) {
            'use strict';
            var layerName = $scope.layerName = layerName,
                touchpointType = $scope.touchpointType = touchpointType,
                groupId = touchpointGroupId,
                multiEngineMode = styling.hasOwnProperty('bookingEngines'),
                translate = $filter('translate'),
                bookingEngines;

            $scope.identity = identity;
            $scope.$sce = $sce;

            activate();
            function activate() {
                prepare();
                setupVersionManager(styling);
                var upgrade = styling.upgrade;
                delete styling.upgrade;

                sortLanguageData(styling);

                $scope.model = {
                    campaignUuid: groupId,
                    upgradeData: upgrade,
                    isActive: styling.isActive == '1',
                    mainData: styling,
                    styling: styling,
                    defaultBackgroundImageUrl: sessionService.getProperty(spacesService.getPropertyUuid()).BackgroundImageUrl,
                    propertyImages: parse.arrayOrDefault(propertyImages.asset_images),
                    propertyId: spacesService.getPropertyUuid(),
                    isUseDefaultImage: !styling.backgroundImage
                };

                $scope.model.styling = $scope.version.currentIsNewVersion() ? $scope.model.upgradeData : $scope.model.mainData;

                if (multiEngineMode) {
                    $scope.model.bookingEngines = bookingEngines = styling.bookingEngines.item;
                    $scope.model.showEngineId = bookingEngines[0] && bookingEngines[0].id;
                }

                multiEngineMode ? _.forEach(bookingEngines, normalizeStrings) : normalizeStrings(styling);
            }

            function sortLanguageData(styling){
                if(!!styling.languages){
                    languageHelper.sortByDefaultLanguage(styling.languages);
                    styling.languages = parse.arrayOrDefault(styling.languages);
                }
            }

            function normalizeStrings(source) {
                var intProperties = ['desktopWidth', 'mobileWidth'];
                var jsonProperties = ['jsonSettings'];
                var boolProperties = ['generationIsActive'];


                _.forEach(source, function (value, key) {
                    if (_.includes(intProperties, key)) {
                        source[key] = parse.intOrDefault(value);
                    }
                    else if (_.includes(jsonProperties, key)) {
                        source[key] = value.replace(/[\r]/g, '');
                    }
                    else if (_.includes(boolProperties, key)) {
                        source[key] = parse.bool(value);
                    }
                    else if (angular.isString(value)) {
                        source[key] = value.replace(/[\r]/g, '');
                    }
                });
            }

            $scope.updateState = function () {
                Touchpoint.activate({
                    propertyUuid: spacesService.getPropertyUuid(),
                    layer: layerName.replace('-', ''),
                    touchpoint: groupId,
                    touchpointType: touchpointType,
                    isActive: $scope.model.isActive
                }).then(function (model) {
                    $rootScope.$broadcast('touchpoint.statusChanged', model);
                });
            };

            $scope.showBookingEnginesPanel = function () {
                return bookingEngines.length > 1;
            };

            $scope.commit = function (deepDiffChanges) {
                var mainChanges = _.filter(deepDiffChanges, function (change) {
                    var path = change.path.join('.');
                    return path.indexOf('model.upgradeData') == -1;
                });
                var upgradeChanges = _.filter(deepDiffChanges, function (change) {
                    var path = change.path.join('.');
                    return path.indexOf('model.upgradeData') != -1;
                });

                saveData(mainChanges, $scope.version.activatedVersion);
                $scope.model.upgradeData && saveData(upgradeChanges, $scope.model.upgradeData.poststayVersionId);
            };

            $scope.versionChanged = function (oldVersion, newVersion) {
                var changedToNewVersion = newVersion > oldVersion;
                $scope.model.styling = changedToNewVersion ? $scope.model.upgradeData : $scope.model.mainData;
                versionSelection.update(newVersion);
            };

            $scope.activateVersion = function(version){
                Touchpoint.activateVersion({
                    propertyUuid: spacesService.getPropertyUuid(),
                    layerName: layerName.replace('-', ''),
                    groupId: groupId,
                    touchpointType: touchpointType,
                    version: version
                }).then(function(){
                    setupVersionManager({poststayVersionId: version});
                });
            };

            $scope.installVersion = function(version){
                Touchpoint.installVersion({
                    propertyUuid: spacesService.getPropertyUuid(),
                    layerName: layerName.replace('-', ''),
                    groupId: groupId,
                    touchpointType: touchpointType,
                    version: version
                }).then(function () {
                    versionSelection.clear();
                    reloadData();
                });
            };

            $scope.saveEmailImageStyling = function () {
                var model = {
                    desktopWidth: $scope.model.styling.desktopWidth,
                    mobileWidth: $scope.model.styling.mobileWidth,
                    touchpointCSS: $scope.model.styling.touchpointCSS,
                    backgroundImage: $scope.model.styling.backgroundImage
                };
                var url = endpointUrl.getStyleUrl(layerName, touchpointType, groupId, spacesService.getPropertyUuid());
                ftTasks.register("update-email-images");
                Styling.put(url, model)
                    .then(function (response) {
                        if (touchpointType == 'confirmation-email-image') {
                            var data = response.data;
                            sortLanguageData(data);
                            normalizeStrings(data);
                            $scope.model.styling = data;
                            $scope.stylingSnapshot.retake();
                        }
                        ftTasks.finish("update-email-images");
                    });
            };

            $scope.removeMobileWidth = function () {
                delete $scope.model.styling.mobileWidth;
            };

            $scope.updateImage = function (image) {
                $scope.model.styling.backgroundImage = !!image ? image.url : null;
            };

            $scope.refreshConfirmationImage = function (language) {
                var imageUUID = language.imageUUID;
                ConfirmationImage.refresh(groupId, language.code, spacesService.getPropertyUuid())
                    .then(function (newImageUUID) {
                        language.imageUUID = newImageUUID;
                        $scope.stylingSnapshot.retake();
                    })
                    .finally(function () {
                        ftTasks.finish("generate-email-image-" + imageUUID + "-" + language.code);
                    });
            };

            $scope.updateBannerImageStatus = function (signal) {
                if (signal.imageType === 'confirmation' && $scope.model.campaignUuid.toLowerCase() === signal.campaignUuid) {
                    var imageInfo = _.find($scope.model.styling.languages, function (image) {
                        return image.code === signal.languageCode;
                    });
                    if (imageInfo) {
                        imageInfo.imageUUID = signal.imageUuid;
                        imageInfo.isImageProcessed = true;
                        $scope.stylingSnapshot.retake();
                    }
                }
            };

            $scope.setupStylingSnapshot = function (snapshot) {
                $scope.stylingSnapshot = snapshot;
            };

            $scope.onUseBackgroundImage = function () {
                if ($scope.model.isUseDefaultImage) {
                    delete $scope.model.styling.backgroundImage;
                }
            };


            function saveData(deepDiffChanges, version) {
                if (_.isEmpty(deepDiffChanges)) return;
                var fixedPathDiffs = getFixedPathDiffs(deepDiffChanges);
                var changes = multiEngineMode ? changesTransformer(transformType.BookingEngineData, fixedPathDiffs, bookingEngines)
                    : changesTransformer(transformType.FlatData, deepDiffChanges, $scope.model.styling);
                var url = endpointUrl.getStyleUrl(layerName, touchpointType, groupId, spacesService.getPropertyUuid(), version);
                Styling.put(url, changes);
            }

            function reloadData(){
                var url = endpointUrl.getStyleUrl(layerName, touchpointType, groupId, spacesService.getPropertyUuid());
                Styling.get(url).then(function (response) {
                    styling = response.data;
                    activate();
                });
            }

            function getFixedPathDiffs(deepDiffChanges) {
                return _.map(deepDiffChanges, function (change) {
                    var copy = angular.copy(change),
                        path = change.path.join('.'),
                        patchesToCrop = ['model.bookingEngines'];
                    angular.forEach(patchesToCrop, function (toCrop) {
                        if (path.indexOf(toCrop) != -1) {
                            copy.path.splice(0, toCrop.split('.').length);
                        }
                    });
                    return copy;
                });
            }

            $scope.$on('touchpoint.statusChanged', function (event, args) {
                if (touchpointType == args.touchpointType) {
                    $scope.model.isActive = args.isActive;
                }
            });

            $scope.stylingEmailTemplateController = function(){
                return $controller("flipto.account.sections.platform.StylingEmailTemplateController", {
                    $scope: $scope,
                    layerName: layerName,
                    touchpointType: touchpointType,
                    touchpointGroupId: touchpointGroupId
                })
            };


            function setupVersionManager(objectToUpgrade) {
                $scope.version = {
                    currentVersion: versionSelection.get($scope.version && $scope.version.currentVersion, objectToUpgrade.poststayVersionId, objectToUpgrade.installedVersionId),
                    activatedVersion: objectToUpgrade.poststayVersionId,
                    updateInstalled: angular.isDefined(objectToUpgrade.upgrade),
                    availableVersion: objectToUpgrade.lastVersionId,
                    currentIsNewVersion: function () {
                        return $scope.version && $scope.version.updateInstalled && $scope.version.currentVersion === $scope.version.availableVersion;
                    }
                }
            }


            function prepare() {
                styling.installedVersionId && (styling.installedVersionId = parse.intOrDefault(styling.installedVersionId));
                styling.poststayVersionId && (styling.poststayVersionId = parse.intOrDefault(styling.poststayVersionId));
                styling.lastVersionId && (styling.lastVersionId = parse.intOrDefault(styling.lastVersionId));
            }
        }
    ]);
