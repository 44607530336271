import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

export class TimeRange {
    ngbToTime: NgbTimeStruct;
    ngbFromTime: NgbTimeStruct;
    duration: {
        hours: number;
        minutes: number;
        seconds: number;
    };

    constructor(public fromTime: string, public toTime: string) {
        if (toTime) {
            this.ngbToTime = TimeRange.timeStringToNgbTimeStruct(toTime);
        }
        if (fromTime) {
            this.ngbFromTime = TimeRange.timeStringToNgbTimeStruct(fromTime);
        }
        if (this.fromTime && this.toTime) {
            this.duration = this.calcDuration();
        }
    }

    calcDuration() {
        const fromSeconds = TimeRange.timeStructToSeconds(this.ngbFromTime);
        const toSeconds = TimeRange.timeStructToSeconds(this.ngbToTime);
        const diffSeconds = toSeconds - fromSeconds;
        return {
            hours: Math.floor(diffSeconds / 3600),
            minutes: Math.floor((diffSeconds % 3600) / 60),
            seconds: diffSeconds % 60,
        };
    }

    static timeStringToNgbTimeStruct(timeString: string): NgbTimeStruct {
        if (!timeString) {
            return null;
        }
        const timeParts = timeString?.split(':').map(Number);
        return {
            hour: timeParts?.[0] || 0,
            minute: timeParts?.[1] || 0,
            second: timeParts?.[2] || 0 // If seconds are not provided, default to 0
        };
    }

    static ngbTimeStructToTimeString(time: NgbTimeStruct, seconds?: boolean): string {
        const pad = (num: number) => num.toString().padStart(2, '0');
        return time ? (`${pad(time.hour)}:${pad(time.minute)}` + (seconds ? `:${pad(time.second)}` : '')) : null;
    }

    static timeStructToSeconds(time: NgbTimeStruct): number {
        return (time.hour * 3600) + (time.minute * 60) + (time.second || 0);
    }
}

