angular.module('flipto.core.api.rateCodes', [])
    .config(['$provide', function ($provide) {
        $provide.factory('RateCodes', ['$http',
            function ($http) {
                function RateCodes() {
                    this.getRateCodes = function (propertyUUID) {
                        return $http.get('/api/v2/' + propertyUUID + '/properties/' + propertyUUID + '/reservation/exclusions')
                            .then(function (response) {
                                return response.data;
                            });
                    };

                    this.updateRateCodes = function (propertyUUID, rateCodes) {
                        return $http.put('/api/v2/' + propertyUUID + '/properties/' + propertyUUID + '/reservation/exclusions', rateCodes);
                    };
                }
                return new RateCodes();
            }]);
    }]);
