import { Directive, ElementRef, forwardRef, HostListener, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
    selector: '[ftPrependInput]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PrependInputDirective),
            multi: true
        }
    ]
})
export class PrependInputDirective implements ControlValueAccessor, OnInit {
    @Input('ftPrependInput') prepend: string;
    @Input() clearable = true;

    constructor(private input: ElementRef) {
    }

    ngOnInit(): void {
        this.setPlaceholder();
    }

    setPlaceholder() {
        if (!this.input.nativeElement.placeholder) {
            this.input.nativeElement.placeholder = this.prepend;
        }
    }

    @HostListener('focus') onFocus() {
        if (!this.isPrepended) {
            this.value = this.prepend + this.value;
        }
    }

    @HostListener('blur') onBlur() {
        if (this.isPrepended && this.prepend.length === this.value.length) {
            this.value = '';
        }
    }

    @HostListener('input') onInput() {
        this.writeValue(this.value);
    }

    @HostListener('keydown.backspace', ['$event']) onBackspace(e) {
        if (!this.clearable) {
            const selectionStart = this.input.nativeElement.selectionStart;
            const selectionEnd = this.input.nativeElement.selectionEnd;

            if (this.isPrepended && (selectionStart - 1) < this.prepend.length) {
                this.value = this.removeSubstr(this.value, this.prepend.length, selectionEnd);
                e.preventDefault();
            }
        }

    }

    @HostListener('keyup.delete', ['$event']) onDelete(e) {
        if (!this.clearable) {

            const selectionStart = this.input.nativeElement.selectionStart;
            const selectionEnd = this.input.nativeElement.selectionEnd;

            if (this.isPrepended && selectionStart < this.prepend.length) {
                this.value = this.removeSubstr(this.value, this.prepend.length, selectionEnd);
                e.preventDefault();
            }
        }
    }

    @HostListener('keydown', ['$event']) onKeydown(e) {
        if (!this.clearable) {
            const selectionStart = this.input.nativeElement.selectionStart;
            if (this.isPrepended &&
              selectionStart < this.prepend.length &&
              e.code !== 'ArrowRight' &&
              e.code !== 'ArrowLeft') {
                e.preventDefault();
            }
        }
    }


    get isPrepended() {
        return this.value.indexOf(this.prepend) === 0;
    }

    get value() {
        return this.input.nativeElement.value;
    }

    set value(value: string) {
        this.input.nativeElement.value = value;
        this.writeValue(value);
    }


    removeSubstr(value: string, startIndex: number, endIndex: number) {
        if (endIndex > startIndex) {
            return value.replace(value.substring(startIndex, endIndex), '');
        } else {
            return value;
        }
    }

    // Control Value Accessor

    onChange = (value: string) => {
    };

    registerOnTouched(fn: any): void {
    }

    setDisabledState?(isDisabled: boolean): void {
    }

    writeValue(value: string): void {
        this.onChange(value);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

}
