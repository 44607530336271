/**
 * Created by ostapenko.r on 11/24/2014.
 */


angular.module('flipto.accountv1.SharedFeatures')
    .controller('flipto.account.sections.platform.photoAdvocacy.WinnersController',
        [
            '$scope', 'IdentityService', 'PhotoContest', 'contestUuid', '$q', 'ftModal', 'parse', '$filter', '_', 'SpacesService', "$rootScope",
            function ($scope, identity, PhotoContest, contestUuid, $q, ftModal, parse, $filter, _, spacesService, $rootScope) {

                var daysUntilDateFilter = $filter('daysUntilDate'),
                    translate = $filter('translate'),

                    placeToWinnerType = {
                        'first': 1,
                        'second': 2,
                        'third': 3,
                        'runner': 100
                    };

                angular.extend($scope, {
                    translate: translate,
                    identity: identity,
                    model: {
                        contentLoaded: false
                    }
                });

                $scope.isGuestInfoVisible = false;

                $scope.model.paging = { SortBy: 'score', SortDirection: 'desc', Offset: 0, Fetch: 24 };
                $scope.model.hasMoreData = true;
                $scope.model.scrollLoadsResetCounter = 0;
                $scope.model.loadNextPage = function () {
                    $scope.model.paging.Offset += $scope.model.paging.Fetch;
                    return PhotoContest.finalistsWithPaging(spacesService.getPropertyUuid(), contestUuid, $scope.model.paging)
                        .then(function (data) {
                            $scope.model.hasMoreData = data.length == $scope.model.paging.Fetch;
                            $scope.model.finalists.finalists.item = Array.prototype.concat($scope.model.finalists.finalists.item, data);
                            return data;
                        });
                };

                $q.all([
                    PhotoContest.load({ contestId: contestUuid, propertyUuid: spacesService.getPropertyUuid() }),
                    PhotoContest.finalists(spacesService.getPropertyUuid(), contestUuid),
                    PhotoContest.finalistsWithPaging(spacesService.getPropertyUuid(), contestUuid, $scope.model.paging)
                ]).then(function (data) {

                    var contest = data[0],
                        finalists = data[1],
                        pagedFinalists = data[2];

                    finalists.finalists = { item: pagedFinalists };

                    angular.extend($scope.model, {
                        contest: contest,
                        finalists: finalists,
                        contestClosed: parse.bool(contest.isClosed),
                        contestActive: parse.bool(contest.isActive),
                        daysLeft: daysUntilDateFilter(contest.endDate),
                        podium: createPodium(contest, finalists),
                        contentLoaded: true
                    });

                    $scope.model.hasMoreData = data[2].length == $scope.model.paging.Fetch;
                });

                /**
                 * Setup missingWinners $watch
                 */
                $scope.$watch(function () {
                    return $scope.model.podium;
                }, function () {
                    $scope.model.missingWinners = $scope.getMissingWinners(true);
                    $scope.model.hasWinnersMissing = !!$scope.getMissingWinners(true).length;
                }, true);

                /**
                 * Show podium editor popup
                 * @param finalist
                 */
                $scope.openPodiumEditor = function (finalist) {
                    if (!finalist) return;
                    PhotoContest.photo(spacesService.getPropertyUuid(), finalist['@photoUUID'] || finalist).then(function (data) {
                        $scope.model.photo = data;
                        $scope.model.photo.isQuotePositive = $scope.model.photo.quoteRating > 3; /* 3 is a magic number coming from the post-stay isPositive algorithm */
                        $scope.model.photo.totalShares = parse.intOrDefault(data.totalGuestShares) + parse.intOrDefault(data.totalOtherShares);
                        $scope.model.finalist = angular.copy(finalist);
                        ftModal.show('podiumEditor');
                    });
                };

                $scope.showGuestInfo = function () {
                    $scope.isGuestInfoVisible = true;
                };

                $scope.openAdvocatePage = function () {
                    // v1 and v2 sub for this and open own details page
                    $rootScope.$broadcast("curationModal.openDetails", $scope.model.finalist["@guestUUID"]);
                };

                /**
                 * Save podium
                 */
                $scope.savePodium = function () {
                    var model = {},
                        podium = $scope.model.podium;
                    if (!!podium.first) {
                        model.firstPlacePhotoUUID = podium.first['@photoUUID'];
                    }
                    if (!!podium.second) {
                        model.secondPlacePhotoUUID = podium.second['@photoUUID'];
                    }
                    if (!!podium.third) {
                        model.thirdPlacePhotoUUID = podium.third['@photoUUID'];
                    }
                    var runners = _.filter(podium.runnerUps.items, function (runner) {
                        return !!runner;
                    });
                    model.runnerUpPhotoUUIDs = _.map(runners, function (runner) {
                        return runner['@photoUUID']
                    });

                    PhotoContest.updateWinners(spacesService.getPropertyUuid(), contestUuid, model);
                };

                /**
                 * Returns true if contest can be closed
                 * @returns {boolean}
                 */
                $scope.canClose = function () {
                    if ($scope.model.contestClosed) return false;
                    return moment().isAfter($scope.model.contest.votingEndDate);
                };

                /**
                 * Returns array of missing winners
                 * @returns {Array}
                 */
                $scope.getMissingWinners = function (isIncludeRunnerups) {
                    var missingWinners = [];
                    var podium = $scope.model.podium;
                    if (!!podium) {
                        if (podium.first === null) missingWinners.push('1st Place');
                        if (podium.second === null) missingWinners.push('2nd Place');
                        if (podium.third === null) missingWinners.push('3rd Place');
                        var missingRunnerUps = _.filter(podium.runnerUps.items, function (runner) {
                            return runner === null;
                        }).length;
                        if (isIncludeRunnerups && missingRunnerUps > 0) missingWinners.push(missingRunnerUps + ' Runner-up');
                    }
                    return missingWinners;
                };

                /**
                 * Returns number of winner places on the podium
                 * @returns {number}
                 */
                $scope.getNumberOfPlaces = function () {
                    var counter = 0;
                    var podium = $scope.model.podium;
                    if (!podium) return counter;
                    if (angular.isDefined(podium.first)) counter++;
                    if (angular.isDefined(podium.second)) counter++;
                    if (angular.isDefined(podium.third)) counter++;
                    return counter;
                };

                /**
                 * Returns true if podium has at least 1 place defined
                 */
                $scope.isPodiumDefined = function () {
                    return $scope.getNumberOfPlaces() > 0;
                };

                /**
                 * Returns true if contest can be edited
                 * @returns {boolean}
                 */
                $scope.isContestEditable = function () {
                    return !$scope.model.contestClosed && $scope.model.contestActive;
                };

                /**
                 * Close contest
                 */
                $scope.closeContest = function () {
                    if (!$scope.canClose()) return;
                    if (!parse.bool($scope.model.finalists.isWinnerEmailActive)) {
                        alert("Winner email is disabled, please enable it before announcing winners.");
                        return;
                    }
                    PhotoContest.closeContest(spacesService.getPropertyUuid(), contestUuid)
                        .then(function () {
                            $scope.$emit('flipto.photo-contest-closed', contestUuid);
                            $scope.model.contestClosed = true;
                            $scope.model.contestActive = false;
                            ftModal.hide('endContestConfirmation');
                        });
                };

                /**
                 * Clean cheaters from contest
                 */
                $scope.cleanContestCheaters = function () {
                    PhotoContest.cleanContestCheaters(spacesService.getPropertyUuid(), contestUuid, $scope.model.cheaterCleanupCutoffDate)
                        .then(function () {
                            alert("Contest cleaned successfully!");
                        },
                            function () {
                                alert("Error cleaning contest.");
                            });
                };

                /**
                 * Returns true if there are missing winners
                 * @returns {boolean}
                 */
                $scope.hasWinnersMissing = function () {
                    return $scope.model.missingWinners.length > 0;
                };

                /**
                 * Returns true if the photo submitter is an invitee
                 * @returns {boolean}
                 */
                $scope.isInvitee = function () {
                    return $scope.model.finalist && parse.bool($scope.model.finalist["@isInvitee"]);
                };

                $scope.$watch(function () {
                    return $scope.model.podium
                }, syncWinnerType, true);

                /**
                 * Sync winnerType
                 * @param podium
                 */
                function syncWinnerType(podium) {
                    if (!podium || !$scope.model.finalists.finalists) return;
                    // reset winnerType for all finalists
                    _.forEach($scope.model.finalists.finalists.item, function (finalist) {
                        finalist['@winnerTypeID'] = 0;
                    });

                    updateWinnerType(podium.first, 'first');
                    updateWinnerType(podium.second, 'second');
                    updateWinnerType(podium.third, 'third');
                    _.forEach(podium.runnerUps.items, function (runner) {
                        updateWinnerType(runner, 'runner');
                    });
                }

                /**
                 * Update winnerType
                 * @param source
                 * @param place
                 */
                function updateWinnerType(source, place) {
                    if (!source) return;
                    _.forEach($scope.model.finalists.finalists.item, function (finalist) {
                        if (finalist['@photoUUID'] == source['@photoUUID']) {
                            finalist['@winnerTypeID'] = placeToWinnerType[place] || 0;
                        }
                    });
                }

                /**
                 * Returns podium object
                 * @param contest
                 * @param finalists
                 * @returns {{}}
                 */
                function createPodium(contest, finalists) {

                    /**
                     * By convention:
                     * null - incentive defined but winner not defined
                     * undefined - incentive not defined and there can be no winner
                     */
                    var podium = {
                        first: nonNullableTargetOrUndefined(contest.finalistsIncentives.firstWinnerIncentive, finalists.firstPlace ? angular.copy(_.find(finalists.finalists.item, function (e) { return e["@photoUUID"] == finalists.firstPlace["@photoUUID"]; })) : null),
                        second: nonNullableTargetOrUndefined(contest.finalistsIncentives.secondWinnerIncentive, finalists.secondPlace ? angular.copy(_.find(finalists.finalists.item, function (e) { return e["@photoUUID"] == finalists.secondPlace["@photoUUID"]; })) : null),
                        third: nonNullableTargetOrUndefined(contest.finalistsIncentives.thirdWinnerIncentive, finalists.thirdPlace ? angular.copy(_.find(finalists.finalists.item, function (e) { return e["@photoUUID"] == finalists.thirdPlace["@photoUUID"]; })) : null),
                        runnerUps: {
                            total: 0,
                            items: []
                        }
                    };

                    if (!!contest.finalistsIncentives && !!contest.finalistsIncentives.runnerUpsIncentive) {
                        podium.runnerUps.total = parseInt(contest.finalistsIncentives.runnerUpsIncentive['@totalRunnerUps'], 10);
                        if (!!finalists.runnerUps) {
                            podium.runnerUps.items = angular.isArray(finalists.runnerUps.item) ? angular.copy(finalists.runnerUps.item) : [angular.copy(finalists.runnerUps.item)];
                        }
                        for (var i = podium.runnerUps.items.length; i < podium.runnerUps.total; ++i) {
                            podium.runnerUps.items.push(null);
                        }
                    }

                    return podium;
                }

                /**
                 * Return undefined if nullable is null or not null target instead
                 * @param nullable
                 * @param target
                 * @returns {*}
                 */
                function nonNullableTargetOrUndefined(nullable, target) {
                    if (nullable == null) return undefined;
                    return angular.isDefined(target) ? target : null;
                }

            }]);
