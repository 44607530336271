import { Action, createReducer, on } from '@ngrx/store';
import { State } from './state';
import { commit, commitSuccess, create, createSuccess, load, loadFail, loadSuccess } from './actions';

export const reducer = createReducer(
    {
        loading: false,
        creating: false,
        settings: null,
        commitSuccess: false
    } as State,
    on(load, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(loadSuccess, (state, {data}) => {
        return {
            ...state,
            loading: false,
            settings: data,
        };
    }),
    on(loadFail, (state, {error}) => {
        return {
            ...state,
            loading: false,
            error
        };
    }),
    on(commit, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(commitSuccess, (state, {data}) => {
        return {
            ...state,
            loading: false,
            commitSuccess: true,
            settings: data
        };
    }),
    on(create, (state) => {
        return {
            ...state,
            creating: true
        };
    }),
    on(createSuccess, (state, {data}) => {
        return {
            ...state,
            settings: data,
            creating: false,
        };
    }),
);


export function bookingbarReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}
