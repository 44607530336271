
const TEMPLATE_URL = require("./imageSetup.html");

export const NAME = "ftPhotocontestInvitationImageSetup";

export class PhotocontestInvitationImageSetupController {

    onReload: Function | undefined;
    template: string | undefined;

    reload() {
        if (this.onReload) {
            this.onReload();
        }
    }

}

export const OPTIONS = {
    bindings: {
        template: "<",
        onReload: "&?"
    },
    templateUrl: TEMPLATE_URL,
    controller: PhotocontestInvitationImageSetupController,
    controllerAs: "vm"
};
