export enum BookingOptionId {
    Stay = 0,
    StayAndFlight = 1
}

export interface BookingOption {
    bookingOptionId: BookingOptionId;
    bookingEngineTemplateUrl: string;
    isActive: boolean;
    childrenAgesRequired: boolean;
    maxChildrenAge: number;
    languages: {
        label: string;
        languageCode: string;
        description?: string;
        summary: string;
    }[];
    imageUrl?: string;
}
