

declare const angular, moment;

const TEMPLATE_URL = require("./storyModal.html");

export const NAME = "ftStoryModal";

export class StoryModalController {

    static $inject = ["$ngRedux", "PhotoContest", "$rootScope", "ftModal", "$filter", "parse", "$scope", "$attrs", "$sce", "$timeout", "curationModal", 'SessionService'];

    isInitialized: boolean = false;
    propertyUuid: string;
    storyUuid: string;
    story: any;

    isHighlightFace = false;
    maxContentSize = 220;
    isShowTimeline = true;
    isFinalistEmailActive = true;
    property: any;
    recommendation: any;
    isRecommendationExpanded;
    hasOtherRecommendations;
    isQuoteOnly: boolean;

    constructor(public $ngRedux,
        public PhotoContest,
        public $rootScope,
        public ftModal,
        public $filter,
        public parse,
        public $scope,
        public $attrs,
        public $sce,
        public $timeout,
        public curationModal,
        private sessionService) {

        this.propertyUuid = $attrs.propertyUuid;
        this.storyUuid = $attrs.storyUuid;
        this.isRecommendationExpanded = $scope.$eval($attrs.isRecommendationExpanded);
        this.hasOtherRecommendations = $scope.$eval($attrs.hasOtherRecommendations);

        $rootScope.$on("[StoryModal].RecommendationComplete", () => {
            this.recommendation = null;
            this.hasOtherRecommendations = false;
        });

        $rootScope.$on("[Broadcast].FetchRecommendationComplete", () => {
            this.hasOtherRecommendations = false;
            this.recommendation = this.$scope.$eval(this.$attrs.recommendation);
        });

    }

    $onInit() {
        this.property = this.sessionService.getProperty(this.propertyUuid);
        this.recommendation = this.$scope.$eval(this.$attrs.recommendation);
        this.PhotoContest.details(this.propertyUuid, this.storyUuid)
            .then(data => {
                this.story = data;
                this.isInitialized = true;
                this.isQuoteOnly = !this.story.guest.poststay.photo;

                if (this.story.guest.poststay.photo) {
                    angular.extend(this.story.guest.poststay.photo, { degrees: 0 });
                    this.story.guest.poststay.photo.photoUrl = this.$filter("ftAzureStoredPhoto")(this.story.guest.poststay.photo, 'small');
                    this.story.guest.poststay.photo.photoUrlLarge = this.$filter("ftAzureStoredPhoto")(this.story.guest.poststay.photo, 'large');

                    if (this.story.guest.poststay.photo.photoContest) {
                        this.story.guest.poststay.photo.photoContest.ended = moment(this.story.guest.poststay.photo.photoContest.endDate).diff(moment().startOf('day'), 'days') <= 0;
                        this.story.guest.poststay.photo.photoContest.isClosed = this.parse.bool(this.story.guest.poststay.photo.photoContest.isClosed);
                    }
                }
                if (this.story.guest.poststay.quote) {
                    this.story.guest.poststay.quote.isPositive = this.parse.bool(this.story.guest.poststay.quote.isPositive);
                    this.story.guest.poststay.quote.is10Scale = this.parse.bool(this.story.guest.poststay.quote.is10Scale);
                }
            })

        this.curationModal
            .on('photo-updated', this.onPhotoUpdated);
    }

    onPhotoUpdated = (photoUUID) => {
        if (this.story.guest.poststay.photo.uuid == photoUUID) {
            this.story.guest.poststay.photo.isUpdated = true;
            this.story.guest.poststay.photo.photoUrl = this.$filter("ftAzureStoredPhoto")(this.story.guest.poststay.photo, 'small');
            this.story.guest.poststay.photo.photoUrlLarge = this.$filter("ftAzureStoredPhoto")(this.story.guest.poststay.photo, 'large');
        }
    }

    $onDestroy() {
        this.curationModal.off("photo-updated", this.onPhotoUpdated);
    }

    close() {
        this.$scope.$ftFlow.close();
    }

    openProfilePage() {
        this.$rootScope.$broadcast("curationModal.openDetails", this.story.guest.uuid);
    }

    hasAiData() {
        if (!this.story) return false;
        var guest = this.story.guest;
        return guest && guest.poststay && guest.poststay.photo && guest.poststay.photo.ai && guest.poststay.photo.ai.data;
    }

    getTopAiDescription() {
        if (this.hasAiDescriptions()) {
            return this.story.guest.poststay.photo.ai.captions[0].value;
        }
        return null;
    }

    hasAiDescriptions() {
        if (!this.story) return false;
        var guest = this.story.guest;
        return guest && guest.poststay && guest.poststay.photo && guest.poststay.photo.ai && guest.poststay.photo.ai.captions && guest.poststay.photo.ai.captions.length > 0;
    }

    hasAiFaces() {
        if (!this.story) return false;
        var guest = this.story.guest;
        return guest && guest.poststay && guest.poststay.photo && guest.poststay.photo.ai && guest.poststay.photo.ai.faces && guest.poststay.photo.ai.faces.length > 0;
    }

    hasAiTags() {
        if (!this.story) return false;
        var guest = this.story.guest;
        return guest && guest.poststay && guest.poststay.photo && guest.poststay.photo.ai && guest.poststay.photo.ai.tags && guest.poststay.photo.ai.tags.length > 0;
    }

    getAiTags() {
        if (this.hasAiTags()) {
            return this.story.guest.poststay.photo.ai.tags.map(function (tag) {
                return tag.value.replace(/_+$/, "");
            }).join(', ');
        }
        return null;
    }

}


export const DEFINITION = [function () {
    return {
        templateUrl: TEMPLATE_URL,
        controller: StoryModalController,
        controllerAs: "vm",
        bindToController: true,
    }
}];
