/**
 * Created by Roman on 2015-06-23.
 */

angular
	.module('flipto.core.api.groupAdvocacy', [
		'flipto.core.language',
		'flipto.core.parse',
		'flipto.core.lodash',
		'flipto.core.calculate'
	])
	.factory('GroupAdvocacy', [
		'urlFormatter',
		'$http',
		'parse',
		'_',
		'calculate',
		'languageHelper',
		function(urlFormatter, $http, parse, _, calculate, languageHelper) {
			/**
			 * Group advocacy layer api
			 * @constructor
			 */
			function GroupAdvocacy() {}

			/**
			 * Return list of groups for property and specified filter query
			 * @param property
			 * @param query
			 * @returns {*}
			 */
			GroupAdvocacy.prototype.loadGroups = function(property, query) {
				var model = angular.extend({}, {property: property}, query);
				var apiUrl = query.campaignUUID
					? '/api/properties/:property/layers/groupadvocacy/campaigns/:campaignUUID/groups'
					: '/api/properties/:property/layers/groupadvocacy/groups';
				return $http.get(urlFormatter.format(apiUrl, model, true)).then(function(response) {
					return response.data;
				});
			};

			/**
			 * Return a specific group
			 * @param property
			 * @param groupUUID
			 * @param campaignUUID
			 * @returns {*}
			 */
			GroupAdvocacy.prototype.loadGroup = function(property, groupUUID, campaignUUID) {
				var model = angular.extend({}, {property: property, groupUUID: groupUUID, campaignUUID: campaignUUID});
				var apiUrl = '/api/properties/:property/layers/groupadvocacy/campaigns/:campaignUUID/groups/:groupUUID';
				return $http.get(urlFormatter.format(apiUrl, model, true)).then(function(response) {
					return response.data;
				});
			};

			/**
			 * Update specific group
			 * @param propertyUUID
			 * @param campaignUUID
			 * @param groupUUID
			 * @param model
			 * @returns {HttpPromise}
			 */
			GroupAdvocacy.prototype.updateGroup = function(propertyUUID, campaignUUID, groupUUID, model) {
				return $http
					.put(
						urlFormatter.format(
							'/api/properties/:propertyUUID/layers/groupadvocacy/campaigns/:campaignUUID/groups/:groupUUID',
							{
								propertyUUID: propertyUUID,
								campaignUUID: campaignUUID,
								groupUUID: groupUUID
							}
						),
						model
					)
					.then(function(response) {
						return response.data;
					});
			};

			/**
			 * Activate/deactivate specific group
			 * @param groupUUID
			 * @param isActive
			 * @returns {*}
			 */
			GroupAdvocacy.prototype.activateGroup = function(groupUUID, isActive) {
				return $http
					.put(
						urlFormatter.format(
							'/api/properties/layers/groupadvocacy/groups/:groupUUID/activate/:isActive',
							{
								groupUUID: groupUUID,
								isActive: isActive
							}
						)
					)
					.then(function(response) {
						return response.data;
					});
			};

			/**
			 * Load group campaigns
			 * @param propertyUUID
			 * @param startDate
			 * @param endDate
			 */
			GroupAdvocacy.prototype.loadGroupCampaigns = function(propertyUUID, startDate, endDate) {
				var model = {
					propertyUUID: propertyUUID,
					startDate: startDate,
					endDate: endDate
				};
				return $http
					.get(
						urlFormatter.format('/api/properties/:propertyUUID/layers/groupadvocacy/campaigns', model, true)
					)
					.then(function(response) {
						return parseGroupCampaignVariations(parse.arrayOrDefault(response.data));
					});
			};

			/**
			 * Load group campaign
			 * @param campaignUUID
			 */
			GroupAdvocacy.prototype.loadGroupCampaign = function(campaignUUID) {
				return $http
					.get(
						urlFormatter.format('/api/properties/layers/groupadvocacy/campaigns/:campaignUUID', {
							campaignUUID: campaignUUID
						})
					)
					.then(function(response) {
						return parseGroupCampaignVariation(response.data);
					});
			};

			/**
			 * Returns group advocacy stories
			 * @param propertyUUID
			 * @param startDate
			 * @param endDate
			 * @returns {*}
			 */
			GroupAdvocacy.prototype.loadStoryInfo = function(propertyUUID, startDate, endDate) {
				return $http
					.get(
						urlFormatter.format(
							'/api/properties/:propertyUUID/layers/groupadvocacy/stories',
							{
								propertyUUID: propertyUUID,
								startDate: startDate,
								endDate: endDate
							},
							true
						)
					)
					.then(function(response) {
						return response.data;
					});
			};

			/**
			 * Parse group advocacy campaigns list response
			 * @param variations
			 * @returns {*}
			 */
			function parseGroupCampaignVariations(variations) {
				_.each(variations, function(variation) {
					parseGroupCampaignVariation(variation);
				});
				return variations;
			}

			/**
			 * Parse group advocacy campaigns list response
			 * @param variations
			 * @returns {*}
			 */
			function parseGroupCampaignVariation(variation) {
				variation.type = parse.intOrDefault(variation.type);
				variation.order = parse.intOrDefault(variation.order);
				variation.isActive = parse.bool(variation.isActive);
				variation.totalAdvocates = parse.intOrDefault(variation.totalAdvocates);
				variation.totalGroups = parse.intOrDefault(variation.totalGroups);
				variation.totalGuests = parse.intOrDefault(variation.totalGuests);
				variation.totalVisitors = parse.intOrDefault(variation.totalVisitors);
				variation.advocatesEngagement = calculate.percentage(variation.totalAdvocates, variation.totalGuests);
				variation.visitorsShare = calculate.share(variation.totalAdvocates, variation.totalVisitors);
				variation.goals = parse.arrayOrDefault(variation.goals);
				return variation;
			}

			return new GroupAdvocacy();
		}
	]);
