/**
 * Created by roman.kupin on 10/2/2017.
 */

export const REQUIRE_RESULT = Symbol("REQUIRE_RESULT");
export const PROVIDE_RESULT = Symbol("PROVIDE_RESULT");
export const CANCEL_RESULT = Symbol("CANCEL_RESULT");

let POOL = [];
const ONE_MINUTE = 60000;
const HALF_MINUTE = 30000;

setInterval(() => {
    POOL = POOL.filter(awaited => Date.now() - awaited.start <= ONE_MINUTE);
}, HALF_MINUTE);

export function delayedResultMiddleware() {

    return store => next => action => {
        if (!!action[REQUIRE_RESULT] || !!action[PROVIDE_RESULT] || !!action[CANCEL_RESULT]) {

            if (!!action[REQUIRE_RESULT]) {
                const {meta} = action[REQUIRE_RESULT];
                let promise = new Promise((resolve, reject) => {
                    POOL.push({meta, resolve, reject, next, start: Date.now()});
                });
                return promise;
            } else if (!!action[PROVIDE_RESULT]) {
                const {meta} = action[PROVIDE_RESULT];
                const {result} = action;
                let idx = POOL.findIndex((awaited) => Object.is(awaited.meta, meta));
                if (idx != -1) {
                    POOL[idx].resolve(result);
                    POOL.splice(idx, 1);
                }
            } else if (!!action[CANCEL_RESULT]) {
                const {meta} = action[CANCEL_RESULT];
                const {result} = action;
                let idx = POOL.findIndex((awaited) => Object.is(awaited.meta, meta));
                if (idx != -1) {
                    POOL[idx].reject(result);
                    POOL.splice(idx, 1);
                }
            }
        } else {
            return next(action);
        }
    };
}

export namespace apiRequestMiddleware {
    export let $inject = ["$http"];
}
