/**
 * Created by Roman on 2015-04-16.
 */



angular.module('flipto.components.common.infiniteScroll', [])
    .service("infiniteScroll", [function () {
        function InfiniteScrollSync() {

        }

        InfiniteScrollSync.prototype.isListeningToDocumentScroll = false;

        return new InfiniteScrollSync();
    }])
    .directive('ftInfiniteScroll', ['$window', '$rootScope', '$compile', 'infiniteScroll', function ($window, $rootScope, $compile, infiniteScroll) {

        var loadMoreButtonTemplate = '<div class="infinitescroll-load-container" ng-hide="ftInfiniteScrollDisabled || !ftInfiniteScrollHasMoreData"><button class="ft-btn ft-btn-outline-dark" ng-click="unlockScrollLoading()">{{"flipto.account.LoadMore" | translate}}</button></div>';

        return {
            restrict: 'A',
            scope: {
                'ftInfiniteScroll': '&',
                'ftInfiniteScrollDisabled': '=',
                'ftInfiniteScrollHasMoreData': '=',
                'ftInfiniteScrollLoads': '=?',
                'ftInfiniteScrollOptions': '=?'
            },
            link: function (scope, elem) {

                var defaults = {minDistance: 10, maxScrollLoads: 8},
                    windowElement = angular.element($window),
                    loadMore;

                if(scope.ftInfiniteScrollOptions) {
                    defaults.maxScrollLoads = angular.isDefined(scope.ftInfiniteScrollOptions.maxScrollLoads) ? scope.ftInfiniteScrollOptions.maxScrollLoads : defaults.maxScrollLoads;
                }

                scope.ftInfiniteScrollLoads = scope.ftInfiniteScrollLoads || 0;

                scope.$watch(function () {
                    return infiniteScroll.isListeningToDocumentScroll;
                }, function (value) {
                    if(!value) {
                        windowElement.bind('scroll', handler);
                        infiniteScroll.isListeningToDocumentScroll = true;
                    }
                });

                scope.$watch(function () {
                    return scope.ftInfiniteScrollLoads;
                }, function () {
                    if (scope.ftInfiniteScrollLoads >= defaults.maxScrollLoads) {
                        loadMore = $compile(loadMoreButtonTemplate)(scope);
                        elem.append(loadMore);
                    } else if (angular.isDefined(loadMore)) {
                        loadMore.remove();
                    }
                });

                scope.unlockScrollLoading = function () {
                    scope.ftInfiniteScrollLoads = 0;
                    scope.ftInfiniteScroll();
                };

                scope.$on('$destroy', function () {
                    windowElement.unbind('scroll');
                    infiniteScroll.isListeningToDocumentScroll = false;
                });

                /**
                 * Returns viewport height
                 * @returns {*}
                 */
                function viewportHeight() {
                    return $(windowElement).scrollTop() + $(windowElement).height();
                }

                /**
                 * Returns element's last child offset
                 * @returns {top|jQuery}
                 */
                function elementOffset() {
                    var lastElem = $(elem).children().last();
                    return lastElem.length > 0 ? $(lastElem).offset().top : 0;
                }

                /**
                 * Handles scroll event
                 * @returns {*}
                 */
                function handler() {
                    if (!scope.ftInfiniteScrollHasMoreData ||
                        scope.ftInfiniteScrollDisabled ||
                        scope.ftInfiniteScrollLoads >= defaults.maxScrollLoads) return false;

                    var offset = elementOffset();
                    var height = viewportHeight();
                    if(offset != 0) {
                        var distance = offset - height;
                        if (distance <= defaults.minDistance) {
                            scope.ftInfiniteScrollLoads++;
                            if (scope.$$phase || $rootScope.$$phase) {
                                return scope.ftInfiniteScroll();
                            } else {
                                return scope.$apply(scope.ftInfiniteScroll);
                            }
                        }
                    }
                    
                }

            }
        };
    }]);
