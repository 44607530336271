/**
 * Created by ostapenko.r on 9/11/2014.
 */

angular.module('flipto.core.api.image', [])
    .config(['$provide', function ($provide) {

        $provide.factory('Image', ['$http', 'urlFormatter', function ($http, urlFormatter) {

            /**
             * Image service
             * @constructor
             */
            function ImageService() {

                /**
                 * Load property images
                 * @param model
                 * @returns {HttpPromise}
                 */
                this.getPropertyImages = function (model) {
                    return $http.get(urlFormatter.format('/api/:propertyId/properties/:propertyId/images', model))
                        .then(function (response) {
                            return response.data;
                        });
                };

                /**
                 * Upload new property image
                 * @param propertyId, imageType and url
                 * @returns {HttpPromise}
                 */
                this.uploadPropertyImageFromUrl = function (propertyId, imageType, url) {
                    var model = {
                        'propertyUUID': propertyId,
                        'type': imageType,
                        'url': url
                    }
                    return $http.post(urlFormatter.format('/api/:propertyUUID/properties/:propertyUUID/imageFromUrl', model, true)).then(function (response) {
                            return response.data;
                        });
                };
            }
            return new ImageService();
        }]);
    }]);