/**
 * Created by roman.kupin on 1/29/2018.
 */


import * as ContestContainerComponent from "./contestContainer.component";
import {ContestContainerService} from "./contestContainer.service"

declare let angular;
export const CONTEST_CONTAINER_MODULE = angular.module("AccountApp.v2.Components.ContestContainer", []);

export class ComponentConfig {

    static $inject = ["$compileProvider", "$provide"];

    constructor($compileProvider, $provide) {
        $compileProvider.directive(ContestContainerComponent.NAME, ContestContainerComponent.DEFINITION);
        $provide.service(ContestContainerService.Name, ContestContainerService);
    }
}

CONTEST_CONTAINER_MODULE.config(ComponentConfig);