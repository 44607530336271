import { changeConfirmationExperienceFail } from './../confirmation-experience/actions';
import { changeCertificateEmail, changeCertificateEmailFail, changeCertificateEmailSuccess, loadCertificateEmailFail } from './actions';
import { Action, createReducer, on } from "@ngrx/store";
import { loadCertificateEmail, loadCertificateEmailSuccess } from ".";
import { State } from "./state";

export const reducer = createReducer(
    {
        loading: false,
        settings: null,
        commitSuccess: false
    } as State,
    on(loadCertificateEmail, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(loadCertificateEmailSuccess, (state, action) => {
        return {
            ...state,
            loading: false,
            settings: action.data
        };
    }),
    on(loadCertificateEmailFail, (state, {error}) => {
        return {
            ...state,
            loading: false,
            error
        };
    }),
    on(changeCertificateEmail, state => {
        return {
          ...state,
          loading: true
        };
      }),
    on(changeCertificateEmailSuccess, (state, {changes}) => {
        const newSettings = state.settings.map(val => {
            return val.emailType === changes.emailType ? {...val, ...changes} : val;
        });
        return {
            ...state,
            loading: false,
            commitSuccess: true,
            settings: [...newSettings]
        };

        
    }),
    on(changeCertificateEmailFail, (state, { error }) => {
        return {
            ...state,
            loading: false,
            error
        };
    }),
);

export function certificateEmailReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}
