/**
 * Created by ostapenko.r on 10/1/2014.
 */


angular.module('flipto.core.api.settingsDetails', ['flipto.core.language', 'flipto.core.parse'])
    .config(['$provide', function ($provide) {
        $provide.factory('SettingsDetails', ['urlFormatter', '$http', 'parse', function (urlFormatter, $http, parse) {

            /**
             * SettingsDetails service
             * @constructor
             */
            function SettingsDetails() {

                /**
                 * Get branding data for property
                 * @param params
                 * @returns {*}
                 */
                this.get = function (params) {
                    return $http.get(urlFormatter.format('/api/:propertyId/properties/:propertyId/settings/details', params))
                        .then(function (response) {
                            var data = response.data;
                            if (data.avgDailyRate) data.avgDailyRate = parse.int(data.avgDailyRate);
                            if (data.annualRevenue) data.annualRevenue = parse.int(data.annualRevenue);
                            if (data.rooms) data.rooms = parse.int(data.rooms);
                            if (!data.location) data.location = {};
                            if (data.location.latitude) data.location.latitude = parseFloat(data.location.latitude);
                            if (data.location.longitude) data.location.longitude = parseFloat(data.location.longitude);
                            if (!data.accountManager) data.accountManager = {};
                            if (!data.salesPerson) data.salesPerson = {};
                            if (data.isDemographicsActive) data.isDemographicsActive = parse.bool(data.isDemographicsActive);
                            if (data.isPrivacyPageActive) data.isPrivacyPageActive = parse.bool(data.isPrivacyPageActive);
                            if (data.isWebsiteIntegrated) data.isWebsiteIntegrated = parse.bool(data.isWebsiteIntegrated);
                            if (data.isBookingEngineIntegrated) data.isBookingEngineIntegrated = parse.bool(data.isBookingEngineIntegrated);
                            if (data.isExcludeHpcUtmParameters) data.isExcludeHpcUtmParameters = parse.bool(data.isExcludeHpcUtmParameters);
                            if (data.isActive) data.isActive = parse.bool(data.isActive);
                            data.associations = parse.arrayOrDefault(data.associations).map(function (a) {
                                return { company_uuid: a.company_uuid, company_name: a.company_name, permissions: parse.int(a.permissions) };
                            });
                            if (data.type) data.type = parse.int(data.type);

                            return response.data;
                        });
                };

                /**
                 * Update branding data of property
                 * @param params
                 * @param model
                 * @returns {*}
                 */
                this.put = function (params, model) {
                    return $http.put(urlFormatter.format('/api/:propertyId/properties/:propertyId/settings/details', params), model)
                        .then(function (response) {
                            return response.data;
                        });
                };
            }

            return new SettingsDetails();
        }]);
    }]);