import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateAccountComponent } from './components/create-account/create-account.component';
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { ManagementEffects } from './store/management/management.effects';
import { AccountSharedModule } from 'apps/account/src/account-shared';
import { Store, StoreModule } from '@ngrx/store';
import { CreatePropertyComponent } from './components/create-property/create-property.component';
import {
    ACCOUNT_MANAGEMENT_FEATURE_NAME,
    AccountLanguagesEffects,
    loadAccountLanguages,
    loadAvailableAccountLanguages,
    reducers
} from './store';
import { IdentityService } from 'account-hybrid/core/authentication';
import {
    AccountLanguagesComponent
} from 'account-hybrid/common/modules/account-management/components/account-languages/account-languages.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [
        CreateAccountComponent,
        CreatePropertyComponent,
        AccountLanguagesComponent
    ],
  imports: [
    CommonModule,
    FormsModule,
    AccountSharedModule,
    EffectsModule.forFeature([ManagementEffects, AccountLanguagesEffects]),
    StoreModule.forFeature(ACCOUNT_MANAGEMENT_FEATURE_NAME, reducers),
    NgbTooltip
  ],
    exports: [
        CreateAccountComponent,
        CreatePropertyComponent,
        AccountLanguagesComponent
    ]
})
export class AccountManagementModule {
    constructor(private store: Store<any>, private identityService: IdentityService) {
        if (!this.identityService.isAnonymous()) {
            this.store.dispatch(loadAvailableAccountLanguages());
            this.store.dispatch(loadAccountLanguages());
        }
    }
}
