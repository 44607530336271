/**
 * Created by ostapenko.r on 12/12/2014.
 */

angular.module('flipto.core.cacheBuster', []).config([
	'$provide',
	'$filterProvider',
	function($provide, $filterProvider) {
		$provide.factory('CacheBuster', [
			'urlFormatter',
			function(urlFormatter) {
				/**
				 * Cache buster service
				 * @constructor
				 */
				function CacheBuster() {
					/**
					 * Url already has cache buster
					 * @param url
					 * @returns {*|boolean}
					 */
					this.hasBuster = function(url) {
						return url && url.indexOf('cb=') !== -1;
					};

					/**
					 * Add cache buster to url
					 * @param url
					 * @returns {*|string}
					 */
					this.addToUrl = function(url) {
						if (!url) return url;
						var timestamp = new Date().getTime();
						url = this.hasBuster(url)
							? url.replace(/(cb=)[^\&]+/, '$1' + timestamp)
							: urlFormatter.format(url, {cb: timestamp}, true);
						return url;
					};

					/**
					 * Get cache buster parameter
					 * @returns {{cb: number}}
					 */
					this.getCacheBuster = function() {
						return {cb: new Date().getTime()};
					};
				}

				return new CacheBuster();
			}
		]);

		$filterProvider.register('ftCacheBuster', [
			'CacheBuster',
			function(CacheBuster) {
				return function(url) {
					return CacheBuster.addToUrl(url);
				};
			}
		]);
	}
]);
