<div class="ft2-auth-container" *ngIf="!isLinkSent">
    <div class="ft2-form-error" *ngIf="error">
        Error processing request. Please try again later.
    </div>
    <div class="text-content">
        <h1 class="h4">Link is expired!</h1>
        <p>
            Sorry, you came here because of an expired link. We can’t send you to the right place until you
            use a valid link.
        </p>
        <button class="ft2-btn-primary -large -wide"
                (click)="sendLink.next()"
                [ftLoading]="loading$">
            Request a new link
        </button>
    </div>
    <div class="mt-3">
        <a class="ft2-link" [uiSref]="'sign-in'">
            <span class="medium-text">
                Return to Sign in
            </span>
        </a>
    </div>
</div>
<div class="ft2-auth-container" *ngIf="isLinkSent">
    <div class="text-content">
        <h1 class="h4">Success!</h1>
        <p>Thanks. We just sent a new link to your email address.</p>
    </div>
</div>
