/**
 * Created by roman.kupin on 10/07/2014.
 */


angular
    .module("flipto.components.touchpoints")
    .service("touchpointActivator",
    [
        function () {

            var canActivateTouchpoints = ['followup', 'invitation-email', 'finalistsEmail', 'reminderEmail', 'activityEmail',
                'winnerEmail', 'homepageEmail', 'confirmation', 'quote', 'contestPage', 'list', 'homepage', 'mobile',
                'pre-arrival-email', 'confirmation-email-image', 'poststay-email-image', 'reminder-emails', 'reengagement-emails', 'photo-river', 'certificate-reminder-email'];

            /**
             * Returns true if touchpoint can be activated/deactivated using the app
             * @param touchpointType
             * @returns {boolean}
             */
            this.canActivate = function (touchpointType) {
                return canActivateTouchpoints.indexOf(touchpointType) > -1;
            };
        }
    ]);