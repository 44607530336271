<ft-modal-editor-wrapper>
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
    <div class="ft2-form-group">
        <label class="v-center">
            Default nights
            <button *ngIf="tooltip"
                    [ngbTooltip]="tooltip"
                    placement="top auto"
                    class="ft2-btn-info"></button>
        </label>
        <div class="mb-1">
            Must be between min and max nights
        </div>
        <input class="ft2-input -large" type="number"
               #controlsRef
               min="1"
               maxlength="2"
               *ngIf="value?.hasOwnProperty('defaultStayLength')"
               #controlsModelRef="ngModel"
               required
               data-error-name="Default nights"
               name="defaultStayLength"
               [(ngModel)]="value.defaultStayLength">
    </div>
    <div class="ft2-form-group">
        <label>
            Min nights
            <button *ngIf="tooltip"
                    [ngbTooltip]="tooltip"
                    placement="top auto"
                    class="ft2-btn-info"></button>
        </label>
        <input class="ft2-input -large"
               *ngIf="value?.hasOwnProperty('minNights')"
               #controlsRef
               #controlsModelRef="ngModel"
               type="number"
               min="1"
               maxlength="2"
               required
               name="minNights"
               data-error-name="Min nights"
               [(ngModel)]="value.minNights">
    </div>
    <div class="ft2-form-group">
        <label>
            Max nights
            <button *ngIf="tooltip"
                    [ngbTooltip]="tooltip"
                    placement="top auto"
                    class="ft2-btn-info"></button>
        </label>
        <input class="ft2-input -large"
               type="number"
               maxlength="2"
               *ngIf="value?.hasOwnProperty('maxNights')"
               #controlsRef
               #controlsModelRef="ngModel"
               required
               name="maxNights"
               data-error-name="Max nights"
               [(ngModel)]="value.maxNights">
    </div>
    <div class="ft2-form-group">
        <label>
            Required arrive day
            <button *ngIf="tooltip"
                    [ngbTooltip]="tooltip"
                    placement="top auto"
                    class="ft2-btn-info"></button>
        </label>
        <ng-select class="ft2-select -large"
                   [(ngModel)]="selectedSpecificArriveDayOption"
                   [items]="customArriveDaysSelectOptions"
                   (ngModelChange)="onRestrictedArrivalDayChange($event)"
                   [clearable]="false"
                   [searchable]="false"
                   bindLabel="name"
                   bindValue="id"
                   name="selectedArriveSpecificDay">
        </ng-select>
        <div class="mt-2" *ngIf="selectedSpecificArriveDayOption === 1">
            <div class="stay-length h-between ft2-input -large v-center">
                <label class="ft2-checkbox" *ngFor="let day of weekDays; let i = index;">
                    <input type="checkbox"
                           (ngModelChange)="onDayOptionChanged($event, i)"
                           [ngModel]="isArriveDayOptionChecked(i)">
                    <span class="ft2-checkbox_control"></span>
                    <div>
                    <span class="bold-text">
                        {{ day }}
                    </span>
                    </div>
                </label>
            </div>
        </div>
    </div>
    <div class="ft2-form-group">
        <label>
            Required depart day
            <button *ngIf="tooltip"
                    [ngbTooltip]="tooltip"
                    placement="top auto"
                    class="ft2-btn-info"></button>
        </label>
        <ng-select class="ft2-select -large"
                   [(ngModel)]="selectedSpecificDepartDayOption"
                   [items]="customDepartDaysSelectOptions"
                   (ngModelChange)="onRestrictedDepartureDayChange($event)"
                   [clearable]="false"
                   [searchable]="false"
                   bindLabel="name"
                   bindValue="id"
                   name="selectedDepartSpecificDay">
        </ng-select>
        <div class="mt-2" *ngIf="selectedSpecificDepartDayOption === 1">
            <div class="stay-length h-between ft2-input -large v-center">
                <label class="ft2-checkbox" *ngFor="let day of weekDays; let i = index;">
                    <input type="checkbox"
                           (ngModelChange)="onDayOptionChanged($event, i, false)"
                           [ngModel]="isDepartDayOptionChecked(i)">
                    <span class="ft2-checkbox_control"></span>
                    <div>
                    <span class="bold-text">
                        {{ day }}
                    </span>
                    </div>
                </label>
            </div>
        </div>
        <div class="mt-2" *ngIf="selectedSpecificDepartDayOption === 2">
            <input class="ft2-input -large"
                   type="number"
                   min="1"
                   maxlength="2"
                   *ngIf="value?.hasOwnProperty('stayLengthIncrementDays')"
                   #controlsRef
                   #controlsModelRef="ngModel"
                   required
                   name="stayLengthIncrementDays"
                   data-error-name="Nights length increment"
                   [(ngModel)]="value.stayLengthIncrementDays">
            <div class="mt-2" *ngIf="value?.stayLengthIncrementDays">
                Planner must choose to depart {{ value.stayLengthIncrementDays }}, {{ value.stayLengthIncrementDays * 2 }}, {{ value.stayLengthIncrementDays * 3 }}, or any multiple of {{ value.stayLengthIncrementDays }} nights from when they arrive.
            </div>
        </div>
    </div>
    <ng-container *ngFor="let control of controlsModelRef">
        <div class="ft2-form-error mt-2"
             *ngIf="control.invalid && control.touched">
            <ng-container *ngFor="let key of valueObjectKeys">
                <p *ngIf="control.errors.required && control.name === key">
                    {{ getInputAttributeByName(key, 'data-error-name') }} value is required
                </p>
                <p *ngIf="control.errors.min && control.name === key">
                    <ng-container *ngIf="control.value === 0; else minErrorTpl">
                        {{ getInputAttributeByName(key, 'data-error-name') }} can't be 0
                    </ng-container>
                    <ng-template #minErrorTpl>
                        {{ getInputAttributeByName(key, 'data-error-name') }} value can't be less than {{ getInputAttributeByName(key, 'min') }}

                    </ng-template>
                </p>
                <p *ngIf="control.errors.maxlength && control.name === key">
                    {{ getInputAttributeByName(key, 'data-error-name') }} value must be at most {{ getInputAttributeByName(key, 'maxlength') }} characters
                    long
                </p>
                <p *ngIf="control.errors.max && control.name === key">
                    <ng-container *ngIf="control.value === 0; else maxErrorTpl">
                        {{ getInputAttributeByName(key, 'data-error-name') }} can't be 0
                    </ng-container>
                    <ng-template #maxErrorTpl>
                        {{ getInputAttributeByName(key, 'data-error-name') }} value can't be more than {{ getInputAttributeByName(key, 'max') }}
                    </ng-template>
                </p>
            </ng-container>
        </div>
    </ng-container>
    <div class="ft2-form-error mt-2"
         *ngIf="validate(this.value) as formError">
        <p *ngIf="formError?.minNightsOverflow">
            Min nights must be less than max nights
        </p>
        <p *ngIf="formError?.checkDefaultStayLengthIsBetween">
            Default nights must be between min and max nights
        </p>
        <p *ngIf="formError?.atLeastOneArrivalDay">
            Required arrive day must have one or more days selected
        </p>
        <p *ngIf="formError?.atLeastOneDepartureDay">
            Required depart day must have one or more days selected
        </p>
    </div>
</ft-modal-editor-wrapper>
