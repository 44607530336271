export class Parse {

    static bool(source): boolean {
        return source === true || source == "1" || source === "true";
    }

    static boolOrDefault(source, defaultValue): boolean {
        if (typeof (source) !== "undefined" && source != null)
            return source === true || source == "1" || source === "true";
        return defaultValue;
    }

    static int(source): number {
        const parsed = parseInt(source, 10);
        if (isNaN(parsed)) throw `${source} is not a number`;
        return parsed;
    }

    static intOrDefault(source, defaultValue?): number {
        const parsed = parseInt(source, 10);
        return isNaN(parsed) ? defaultValue || 0 : parsed;
    }

    static arrayOrDefault(array) {
        if (!array) return [];
        if (Array.isArray(array)) return array;
        if (Array.isArray(array.item)) return array.item;
        if (Array.isArray(array.datum)) return array.datum;
        return [array];
    };
}

