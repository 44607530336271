<ng-container *ngIf="!simplified; else selector;">
    <section class="ft2-block ft2-previewer">
        <div class="ft2-previewer-header">
        </div>
        <div class="ft2-container ft2-previewer-content">
            <div class="row">
                <div class="col-12">
                    <ng-content></ng-content>
                </div>
            </div>
        </div>
        <ng-container *ngTemplateOutlet="selector"></ng-container>
    </section>
</ng-container>
<ng-template #selector>
    <div class="ft2-previewer-footer v-center f-nowrap"
         *ngIf="languages?.length > 1">
        <p>Preview:</p>
        <ng-select [items]="languages"
                   class="ft2-select -pure"
                   name="'languages'"
                   [searchable]="false"
                   [clearable]="false"
                   [bindValue]="bindValue"
                   [bindLabel]="bindLabel"
                   (change)="onPreviewLanguageChange($event)"
                   [(ngModel)]="selectedLanguage"
                   [compareWith]="compareLanguages">
        </ng-select>
    </div>
</ng-template>
