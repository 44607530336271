
export class SpaceTypeCheckHook {

    static $inject = ["$transitions", 'SpacesService', 'ngrxStore'];

    constructor($transitions, spacesService) {

        // $transitions.onBefore({
        //     to: function (state) {
        //         const params = state.parameters().map(p => p.id).reduce((p, c) => { p[c] = c; return p; }, {});
        //         return !!params.type && !!params.slug;
        //     }
        // }, function (transition) {
        //     const toParams = transition.params();
        //     const attrs = { type: ParamToSpaceTypeMapping[toParams.type], slug: toParams.slug } as SpaceAttributes;
        //     const toSpace = getSpaceBySpaceAttributes(attrs);
        //     if (!toSpace) {
        //         const mixedSpace = getMixedSpaceBySpaceAttributes(attrs)
        //         return transition.router.stateService.target(transition.to().name, getSpaceAttributesFromSpace(mixedSpace));
        //     }
        // });

        $transitions.onBefore({
            to: function (state) {
                return !!state.data && state.data.requiredSpaceType;
            }
        }, function (transition) {
            const spacesRequired = Array.isArray(transition.to().data.requiredSpaceType) ? transition.to().data.requiredSpaceType : [transition.to().data.requiredSpaceType];
            if (!spacesService.current || spacesRequired.indexOf(spacesService.current.type) === -1) {
                return transition.router.stateService.target("home");
            }
        });

    }
}
