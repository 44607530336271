/**
 * Created by ostapenko.r on 9/22/2014.
 */


angular.module('flipto.components.common.modalFieldsEditor', ['flipto.components.common.fieldEditors'])
    .directive('ftModalFieldsEditor', ['$compile', '$q', '$templateCache', '$http', 'fieldEditor', 'ftModal',
        function ($compile, $q, $templateCache, $http, FieldEditor, ftModal) {

            var modalFieldsEditorTemplate = '/app/components/common/modal-fields-editor/modalFieldsEditor.html',
                template = fetchTemplate(modalFieldsEditorTemplate);


            function fetchTemplate(template) {
                return $q.when($templateCache.get(template) || $http.get(template))
                    .then(function (res) {
                        return angular.isObject(res) && res.data || res;
                    });
            }

            return {
                restrict: 'A',
                require: 'ftModalFieldsEditor',
                link: function (scope, element, attrs, controller) {
                    var disabled,
                        source = {
                            fields: attrs.fields || '',
                            trimclipboardformat: attrs.trimclipboardformat || false,
                            readonlytext: attrs.readonlytext || '',
                            fieldsOptions: attrs.fieldsOptions && scope.$eval(attrs.fieldsOptions),
                            tokens: attrs.tokens || '',
                            description: attrs.editorDescription || '',
                            title: attrs.editorTitle || '',
                            type: attrs.editorType,
                            datasource: attrs.datasource,
                            snapshot: attrs.snapshot || attrs.datasource,
                            onDone: attrs.onDone,
                            onCancel: attrs.onCancel,
                            size: attrs.size || ''
                        };
                    attrs.$observe('disabled', function (val) {
                        disabled = val === 'true';
                    });

                    var fieldEditor = new FieldEditor(source);
                    var fieldEditorHtml = fieldEditor.getHtml();

                    $q.when(template)
                        .then(function (contents) {
                            var modalId = ('modal-' + (+Date.now()) + parseInt(Math.random() * 1000, 10));
                            contents = contents.replace('[modal-id]', modalId);
                            contents = contents.replace('[field-editor]', fieldEditorHtml);
                            contents = contents.replace('[modal description]', source.description);
                            contents = contents.replace('[snapshot]', source.snapshot);
                            contents = contents.replace('[modal title]', source.title);
                            contents = contents.replace('[on-done]', source.onDone);
                            contents = contents.replace('[on-cancel]', source.onCancel);
                            contents = contents.replace('[size]', source.size);
                            var contentEl = angular.element(contents);
                            angular.element(document).find('body').append(contentEl);
                            $compile(contentEl)(scope);
                            angular.element(element).bind("click", function () {
                                !disabled && ftModal.show(modalId);
                            });

                        });

                    scope.$on("$destroy", function () {
                        angular.element(element).unbind("click");
                    });

                },
                controller: ['$scope', function ($scope) {

                }]
            };
        }]);
