/**
 * Created by Roman on 2015-05-01.
 */



angular.module("flipto.components.common.textFormatting")
    .filter('USD', ['$filter', function ($filter) {
        var currencyFilter = $filter('currency');
        return function (amount) {
            if (!angular.isDefined(amount) || amount == null) return "";
            return currencyFilter(amount, '') + " USD";
        };
    }]);