/**
 * Created by roman.kupin on 3/9/2018.
 */

import ContextSelectorActions from "../components/context-selector/actions/index";
import {stateGo} from "redux-ui-router";
import {setSinglePropertyContext} from "./startup";
import noop from "../utils/noop";
import {hasPermissionInProperty} from "account-hybrid/core/authentication";
import ContextSelectorGroupActions from "../components/context-selector/context-group/actions";

export function showSinglePropertyContextSelector(isRefreshCurrentState = true) {
    return (dispatch, getState, {$rootScope}) => {
        const state = getState();
        const properties = state.apiData.properties;
        const currentState = state.router.currentState.name;
        if (properties.length === 1 && !!state.ui.context.singleProperty) return;
        return (properties.length > 1 ? dispatch(ContextSelectorActions.show("singlePropertySelector")) : dispatch(setSinglePropertyContext(properties[0].slug)))
            .then(() => {
                const state = getState();
                const org = state.ui.context.organization;
                const singleProperty = state.ui.context.singleProperty;
                // when single context is updated we need to make sure its changed in url and reach out to other components
                isRefreshCurrentState && dispatch(stateGo(currentState, {context: `/${org}/${singleProperty}`}));
                $rootScope.$broadcast("[Context].SetSingle");
            }, noop);
    }
}

export function showSinglePropertyContextSelectorAndEnsurePermissions(permissions?, targetState?) {
    return (dispatch, getState) => {
        if (!!permissions) {
            const state = getState();
            const org = state.ui.context.organization;
            const properties = state.apiData.properties;
            const propertiesFilteredByPermissions = properties.filter(property => hasPermissionInProperty(property.id, permissions));

            if (propertiesFilteredByPermissions.length === 1) {
                return dispatch(stateGo(targetState, {context: `/${org}/${propertiesFilteredByPermissions[0].slug}`}));
            }
        }

        return dispatch(ContextSelectorActions.show("singlePropertySelector"));
    }
}

export function toggle(propertySlug) {
    return (dispatch, getState) => {
        const state = getState();
        if(!state.ui.contextSelector["singlePropertySelector"]) return;
        const propertyContext = state.ui.contextSelector["singlePropertySelector"].contextGroups.find(group => group.name === "properties").contexts.find(context => context.propertySlug === propertySlug);
        return dispatch(ContextSelectorGroupActions.toggleSingle("singlePropertySelector", "properties", propertyContext._id))
    };
}

const All = {showSinglePropertyContextSelector, showSinglePropertyContextSelectorAndEnsurePermissions, toggle};
export default All;