/**
 * Created by Roman on 2014-09-22.
 */

angular.module('flipto.components.fieldEditors')
    .directive('ftHtmlField', [function() {
        return {
            restrict: 'E',
            scope: {
                'readonlytext': '@',
                'languages': '=',
                'trimclipboardformat': '=?',
                'tokens': '=?'
            },
            templateUrl: '/app/components/field-editors/htmlField.html',
            link: function(scope, elem, attrs) {
                scope.textMode = attrs.mode === 'text';
                scope.type = !!attrs.type ? attrs.type : 'text';
                scope.availableTags = attrs.availableTags;
                scope.readonlytext = attrs.readonlytext;

                scope.fields = adjustFields(scope.$eval(attrs.fields) || []);
                scope.required = angular.isDefined(attrs.required);

                scope.toolbar = scope.textMode ? '[[]]' : '[[\'bold\', \'italics\', \'underline\', \'insertLink\', \'ul\', \'ol\', \'html\']]';

                scope.minLength = attrs.ngMinlength && scope.$eval(attrs.ngMinlength) || 0;
                scope.maxLength = attrs.ngMaxlength && scope.$eval(attrs.ngMaxlength) || 1048576;
                scope.sanitizeOptions = {
                    removeTrailingParagraphs: true,
                    removeWrapper: false,
                    linksNewWindow: true
                };
                if (!!attrs.replaceWrapper) {
                    scope.sanitizeOptions.replaceWrapper = true;
                    scope.sanitizeOptions.removeWrapper = false;
                }
                if (!!attrs.removeWrapper) {
                    scope.sanitizeOptions.replaceWrapper = false;
                    scope.sanitizeOptions.removeWrapper = true;
                }
                scope.getUniqueToolbarName = function() {
                    return 'toolbar' + parseInt(Math.random() * 1000, 10) + +(Date.now());
                };


                scope.onPaste = function(html) {
                    if (scope.trimclipboardformat) {
                        return html.replace(/(<([^>]+)>)/ig, '');
                    }
                    return html;
                };

                /**
                 * Returns fields array as objects
                 * @param fields
                 * @returns {Array}
                 */
                function adjustFields(fields) {
                    return _.map(fields, function(field) {
                        if (angular.isString(field) == true) {
                            return { name: field, label: null };
                        }
                        return field;
                    });
                }
            }
        };
    }]);
