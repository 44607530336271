export class ParamsCheckHook {

    static $inject = ["$transitions"];

    constructor($transitions) {

        $transitions.onBefore({
            to: (state) => {
                return state.data && state.data.requiresParams;
            }
        }, (transition) => {
            const params = transition.to().data.requiresParams;
            if (params.some(param => !transition.params()[param])) {
                return transition.router.stateService.target("home");
            }
        });
    }
}
