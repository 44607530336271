import {
    ActionReducerMap,
    MetaReducer,
    createFeatureSelector,
    createSelector
} from '@ngrx/store';
import * as fromAuthentication from './authentication.reducer';
import * as fromSpaces from '../../common/components/spaces/store/reducers/spaces.reducer';
import * as fromContext from './context.reducer';
import { ContextState } from '../context.state';

export interface State {
    appAuthentication: fromAuthentication.State;
    spaces: fromSpaces.State;
    context: ContextState;
}

export const reducers: ActionReducerMap<State> = {
    appAuthentication: fromAuthentication.reducer,
    spaces: fromSpaces.reducer,
    context: fromContext.contextReducer
};

export const selectSpaces = (state: State) => state.spaces;


export const metaReducers: MetaReducer<State>[] = [];





