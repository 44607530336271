import { NgModule } from '@angular/core';
import { HeaderComponent } from './header.component';
import { AccountSharedModule } from 'apps/account/src/account-shared';
import { CommonModule } from '@angular/common';
import { HeaderNavComponent } from './components/header-nav/header-nav.component';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';


@NgModule({
    imports: [
        CommonModule,
        AccountSharedModule,
        UIRouterUpgradeModule.forChild({})
    ],
    declarations: [
        HeaderComponent,
        HeaderNavComponent
    ],
    exports: [
        HeaderComponent,
        HeaderNavComponent
    ]
})
export class HeaderModule {

}
