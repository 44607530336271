export interface ConfirmationExperienceSettings {
    id: string,
    isActive: string,
    languages: any
}

export interface ConfirmationExperienceStylingSettings {
    id: string,
    isActive: string,
    bookingEngines: any
}

export interface ConfirmationExperienceIntegrationSettings {
    id: string,
    isActive: string,
    bookingEngines: any
}

export const ConfirmationExperienceDisplayId = [
    {id: '1', name: 'Integrated - basic sharing component attached to an element '},
    {id: '2', name: 'Fullscreen - basic sharing component overlaid on page'},
    {id: '3', name: 'Immersive - a complete personalized guest experience (New)'}
];

export const ConfirmationTimerActionTypes = [
    {id: '0', name: 'Proceed to main view after timer expires'},
    {id: '1', name: 'Return to confirmation page after timer expires'}
];
