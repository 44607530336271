import { Injectable, Injector, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { AppNotification } from "../models/notification.model";
import { Environment, ENVIRONMENT_TOKEN } from 'account-hybrid/core/environment.service';

@Injectable()
export class NotificationsService {
    constructor(private http: HttpClient,
                @Inject(ENVIRONMENT_TOKEN) private environment: Environment) {
    }


    getNotifications(): Observable<AppNotification[]> {
        return this.http.get<AppNotification[]>(`${this.environment.apiV1BaseUrl}/api/notifications `);
    }

    updateNotificationByUuid(uuid: string, data: Partial<AppNotification>): Observable<AppNotification> {
        return this.http.patch<AppNotification>(`${this.environment.apiV1BaseUrl}/api/notifications/${uuid}`, data);
    }
}

