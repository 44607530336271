import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { DiscoveryComponentStylingViewModel } from '../../../models';

@Component({
  selector: 'ft-discovery-component-styling',
  templateUrl: './styling.component.html'
})
export class StylingComponent {
  @Input() stylingViewModel$: Observable<DiscoveryComponentStylingViewModel>;
  @Input() isLoading$: Observable<boolean>;
  @Input() commitSuccess$: Observable<boolean>;
  @Output() commitAction = new EventEmitter<Partial<DiscoveryComponentStylingViewModel>>();

  constructor() { }

  commit(settings: Partial<DiscoveryComponentStylingViewModel>) {
    this.commitAction.emit(settings);
  }

}