import { Pipe, PipeTransform } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DateUtils } from 'shared';

@Pipe({
    name: 'isDateInRange'
})
export class IsDateInRangePipe implements PipeTransform {
    constructor() {
    }

    transform(range: { start: Date; end: Date }, date: Date | NgbDate, unit: any = 'day'): boolean {
        if (range) {
           return DateUtils.isBetween(range.start, range.end, DateUtils.toDateFormat(date),unit);
        }
        return false;
    }
}
