/**
 * Created by roman.kupin on 1/29/2018.
 */


export class TouchpointContainerService {

    static Name = "TouchpointContainer";
    static $inject = ["$compile", "$rootScope"];

    private _scope: any;
    private _elem: any;

    constructor(private $compile,
                private $rootScope) {
    }

    show(campaignUuid, propertyUuid, touchpointName, dataType = "messaging") {
        this.ensureElement();
        Object.assign(this._scope, {
            $ftTouchpointContainer: {
                campaignUuid,
                propertyUuid,
                touchpointName,
                dataType
            }
        });
    }

    hide() {
        this.ensureElement();
        this._scope.$ftTouchpointContainer = null;
    }

    private ensureElement() {
        if (!this._scope) {
            this.insertElement();
        }
    }

    private insertElement() {
        this._scope = this.$rootScope.$new(true);
        this._elem = this.$compile(`
<ft-touchpoint-container 
    campaign-uuid="$ftTouchpointContainer.campaignUuid" 
    property-uuid="$ftTouchpointContainer.propertyUuid" 
    touchpoint-name="$ftTouchpointContainer.touchpointName" 
    type="$ftTouchpointContainer.dataType" 
    ng-if="!!$ftTouchpointContainer"></ft-touchpoint-container>`)(this._scope);

        document.body.appendChild(this._elem[0]);
        this._scope.$on("$destroy", () => {
            this._elem.remove();
            this._scope = null;
        });
    }

}