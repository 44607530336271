/**
 * Created by ostapenko.r on 10/13/2014.
 */


angular.module('flipto.components.common.panel')
    .directive('ftPanelStatus', [function () {
        return {
            restrict: 'E',
            template: '<div ng-show="!state" class="panel-status">Off</div>',
            scope: {
                'state': '='
            },
            link: function (scope, element) {
            }
        };
    }]);