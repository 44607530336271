/**
 * Created by roman.kupin on 9/21/2017.
 */


import contextSelector from "../components/context-selector/reducers/index";
import context from "./context";
import showcaseStories from "../components/showcase-stories/reducers/index";
import { combineReducers } from "redux";

const uiReducer = combineReducers({contextSelector, context, showcaseStories});

export default uiReducer;
