/**
 * Created by roman.kupin on 9/28/2017.
 */

import * as AccountApiActions from "../../actions/accountApi";


export default function propertiesReducer(state = [], {type, payload}) {
    switch (type) {
        case AccountApiActions.PROPERTIES_FETCH_SUCCESS: {
            return payload.properties;
        }
        default: {
            return state;
        }
    }
}