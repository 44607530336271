import { createAction, props } from '@ngrx/store';

export const setSingleContext = createAction(
    '[Contexts] Set single context',
    props<{ property: string }>()
);
export const setMultipleContext = createAction(
    '[Contexts] Set multiple context',
    props<{ properties: string[] }>()
);
