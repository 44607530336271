/**
 * User: Kupin.R
 * Date: 6/2/14
 */



angular
    .module("flipto.components.goals")
    .factory("goalDescriptor",
        [
            "goalType",
            function (goalType) {

                function getGoalDescriptor(goal) {

                    return getAdvocateDescriptor(goal) || getSocialConnectionsDescriptor(goal) 
                        || getSharingDescriptor(goal) || getParticipationDescriptor(goal) ||
                        getSubmitPhotoDescriptor(goal) || getPhotoContestDescriptor(goal) ||
                        getGroupAdvocacyAttendeeSignupDescriptor(goal);
                }

                function getAdvocateDescriptor(goal) {
                    if (goal.type == 1) {
                        var descriptor = {type: goalType.SpreadTheWord},
                            clicks = parseInt(goal.clicks, 10),
                            signups = parseInt(goal.signups, 10);

                        if (clicks > 0) {
                            descriptor.type = goalType.GetUniqueVisitors;
                            descriptor.value = clicks;
                        } else if (signups > 0) {
                            descriptor.type = goalType.GetSocialConnections;
                            descriptor.value = signups;
                        }
                        return descriptor;
                    }
                }

                function getSocialConnectionsDescriptor(goal) {
                    if (goal.type == 2) {
                        return {type: goalType.SignupAndGet};
                    }
                }

                function getSharingDescriptor(goal) {
                    if (goal.type == 4) {
                        return { type: goalType.ShareAndGet };
                    }
                }

                function getParticipationDescriptor(goal) {
                    if (goal.type == 3) {
                        return { type: goalType.SubmitQuoteAndGet };
                    }
                }

                function getSubmitPhotoDescriptor(goal) {
                    if (goal.type == 6) {
                        return { type: goalType.SubmitPhotoAndGet };
                    }
                }

                function getPhotoContestDescriptor(goal) {
                    if (goal.type == 5) {
                        return { type: goalType.PhotoContestWinner };
                    }
                }

                function getGroupAdvocacyAttendeeSignupDescriptor(goal) {
                    if (goal.type == 7) {
                        return { type: goalType.AttendeeSignup };
                    }
                }

                return function (goal) {
                    return getGoalDescriptor(goal);
                };
            }
        ]);
