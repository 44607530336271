/**
 * Created by roman.kupin on 10/3/2017.
 */

export const PREFIX = "[Contexts]";
export const SET_SINGLE = `${PREFIX}.SetSingle`;
export const SET_MULTIPLE = `${PREFIX}.SetMultiple`;


export function setSingle(property) {
    return (dispatch, getState, {ftCache}) => {
        ftCache.removeAll();
        return dispatch({type: SET_SINGLE, payload: property});
    };
}

export function setMultiple(properties) {
    return (dispatch, getState, {ftCache}) => {
        ftCache.removeAll();
        return dispatch({type: SET_MULTIPLE, payload: properties});
    };
}


const All = {setSingle, setMultiple};
export default All;