/**
 * Created by Roman on 2014-11-18.
 */

angular.module('flipto.components.common.contextualNavigation', ['flipto.core.lodash'])
    .config(['$compileProvider', '$provide', function ($compileProvider, $provide) {

        function Context(name, entity, parent) {
            this.name = name;
            this.entity = entity;
            this.parent = parent;
            this.handler = null;
            this.active = false;
            this.actions = [];
            this.entityGroups = [];
        }

        function ContextSetup(context) {
            this.context = context;
        }

        ContextSetup.prototype.action = function (name, config) {
            var action = new Action(name, config);
            this.context.actions.push(action);
            return this;
        };

        ContextSetup.prototype.removeAction = function (name) {
            _.remove(this.context.actions, { name: name });
            return this;
        };

        ContextSetup.prototype.handler = function (handler) {
            this.context.handler = handler;
            return this;
        };


        ContextSetup.prototype.entityGroup = function (groupName, config) {
            var entityGroup = new EntityGroup(groupName, config);
            this.context.entityGroups.push(entityGroup);
            return entityGroup;
        };

        function Action(name, config) {
            this.name = name;
            this.config = config;
        }

        function Entity(name, config) {
            this.name = name;
            this.config = config;
        }

        function EntityGroup(name, config) {
            this.name = name;
            this.config = config;
            this.entities = [];
        }

        EntityGroup.prototype.entity = function (name, config) {
            var entity = new Entity(name, config);
            this.entities.push(entity);
            return this;
        };


        function ContextualNavigationService(contexts) {
            this.contexts = contexts || [];
            this.current = undefined;
        }

        ContextualNavigationService.prototype.clear = function () {
            this.contexts = [];
            this.current = undefined;
        };

        ContextualNavigationService.prototype.newContext = function (contextName, entity) {
            var context = new Context(contextName, entity);
            this.contexts.push(context);
            return new ContextSetup(context);
        };

        ContextualNavigationService.prototype.newSubContext = function (contextName, entity, parent) {
            var context = new Context(contextName, entity, parent);
            this.contexts.push(context);
            return new ContextSetup(context);
        };

        ContextualNavigationService.prototype.setContext = function (contextName, entity) {
            var context = _.find(this.contexts, {name: contextName});
            if (!context) throw 'Unable to find context';
            if (!!entity) context.entity = entity;
            this.current = context;
        };

        ContextualNavigationService.prototype.destroy = function () {
            this.contexts = [];
            this.current = undefined;
        };

        $provide.provider('contextualNavigation', [function () {

            var contexts = [];

            this.newContext = function (contextName) {
                var context = new Context(contextName);
                contexts.push(context);
                return new ContextSetup(context);
            };

            this.$get = function () {
                return new ContextualNavigationService(contexts);
            };
        }]);


        $compileProvider.directive('ftContextualNavigation', ['contextualNavigation', function (contextualNavigation) {
                return {
                    restrict: 'E',
                    scope: {},
                    templateUrl: '/app/components/common/contextual-navigation/contextualNavigation.html',
                    link: function (scope, elem, attrs) {

                        /**
                         * Watch current navigation context
                         */
                        scope.$watch(function () {
                            return contextualNavigation.current;
                        }, function (context) {
                            scope.context = context;
                        });

                        /**
                         * Handles entity-selected
                         * @param entity
                         */
                        scope.onEntitySelected = function (entity) {
                            entity.config.handler(scope.context);
                        };

                        scope.onContextSelected = function (context) {
                            context.handler(scope.context);
                        };

                        scope.onExecuteAction = function (action) {
                            action.config.handler(scope.context);
                        };

                        scope.setAccountContext = function () {
                            contextualNavigation.setContext('account');
                        };
                    }
                };
            }]
        );

        $compileProvider.directive('ftContextEntitySelector', [function () {
            return {
                restrict: 'E',
                templateUrl: '/app/components/common/contextual-navigation/contextEntitySelector.html',
                scope: {
                    'entityGroups': '=',
                    'onEntitySelected': '&'
                },
                link: function (scope) {

                    /**
                     * Select entity
                     * @param entity
                     */
                    scope.selectEntity = function (entity) {
                        scope.onEntitySelected({entity: entity});
                    };
                }
            };
        }]);

        $compileProvider.directive('ftContextActions', [function () {
            return {
                restrict: 'E',
                templateUrl: '/app/components/common/contextual-navigation/contextActions.html',
                scope: {
                    'context': '=',
                    'onExecuteAction': '&'
                },
                link: function (scope) {
                    scope.execute = function (action) {
                        scope.onExecuteAction({action: action});
                    };
                }
            };
        }]);

        $compileProvider.directive('ftNavigationActiveItem', ['contextualNavigation', '$rootScope', '$transitions',
            function (contextualNavigation, $rootScope, $transitions) {
                return {
                    restrict: 'A',
                    require: 'ftNavigationActiveItem',
                    controller: ['$state', '_', function ($state, _) {
                        this.setupActiveItem = function (context) {
                            if(!context) return;
                            angular.forEach(context.actions, function (action) {
                                action.config.active = $state.includes(action.config.state);
                            });
                            context.active = !_.some(context.actions, {config:{active:true}});
                        };
                    }],
                    link: function (scope, elem, attrs, ftNavigationActiveItem) {

                       /*$rootScope.$on('$stateChangeSuccess', function (event, toState) {
                            ftNavigationActiveItem.setupActiveItem(contextualNavigation.current);
                        });*/
                        $transitions.onSuccess({}, function(){
                            ftNavigationActiveItem.setupActiveItem(contextualNavigation.current);
                        });

                        scope.$watch(function () {
                            return contextualNavigation.current;
                        }, function (context) {
                            if (context) {
                                ftNavigationActiveItem.setupActiveItem(context);
                            }
                        });


                    }
                };
            }]);

    }]);