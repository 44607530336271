/**
 * Created by kevin.tiller on 6/13/2018
 */

export function forbiddenInterceptor ($q) {
    return {
        'responseError': function (response) {
            if (response.status === 403) {
                const responses = ["Oy vey! Who told you that you could do that!",
                                 "Hey! You’re not allowed to do that!",
                                 "Ah ah ah… you didn’t say the magic word!"];
                alert(responses[Math.floor(Math.random() * responses.length)]);
            }
            return $q.reject(response);
        }
    };
}

export namespace forbiddenInterceptor {
    export let $inject = ["$q"];
}