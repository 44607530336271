/**
 * Created by roman.kupin on 2/20/2018.
 */

import adminReportsReducer from "./reducers/index";
import * as AdminReportsComponent from "./adminReports.component";
import { Permissions, LicenseType } from 'account-hybrid/core/authentication';
import { StateConfig } from "./state.config";

declare let angular;

export const MODULE = angular.module("AccountApp.v2.AdminReports", []);
export const REDUCER = { adminReports: adminReportsReducer };
export const PERMISSIONS = Permissions.FliptoAdministration;
export const LICENSE = LicenseType.None;

MODULE.config(StateConfig);
MODULE.component(AdminReportsComponent.NAME, AdminReportsComponent.OPTIONS);

