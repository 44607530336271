/**
 * Created by Roman on 2015-08-19.
 */

angular.module('flipto.core.appLog', ['flipto.core.fixedQueue', 'flipto.core.environment', 'flipto.core.lodash'])
    .provider('AppLogFormatters', [function () {
        var formatters = [];

        /**
         * Returns formatters
         * @type {*[]}
         */
        this.$get = [function () {
            return formatters;
        }];

        /**
         * Push formatter to formatters array
         * @param formatter
         */
        this.push = function (formatter) {
            formatters.push(formatter);
        }
    }])
    .factory('AppLog', ['FixedQueue', 'environment', '_', 'AppLogFormatters', 'fileSaver', function (FixedQueue, environment, _, AppLogFormatters, fileSaver) {

        var instance = new AppLog();

        /**
         * Application log
         * @constructor
         */
        function AppLog() {
            this.log = new FixedQueue(environment.logging.logSize);
            this.formatters = AppLogFormatters || [];
        }

        /**
         * Enqueue entry
         * @param level
         * @param name
         * @param entry
         */
        AppLog.prototype.enqueue = function (level, name, entry) {
            if (environment.logging.enabled) {
                this.log.push({ level: level, name: name, entry: entry });
            }
        };
        angular.forEach(['info', 'warn', 'trace', 'debug', 'error'], function (level) {
            AppLog.prototype[level] = angular.bind(instance, AppLog.prototype.enqueue, level);
        });
        /**
         * Search log entry
         * @param searchFn
         * @returns {*}
         */
        AppLog.prototype.search = function (searchFn) {
            return _.filter(this.log, searchFn);
        };

        /**
         * Dump log
         * @param name=
         */
        AppLog.prototype.dump = function (name) {
            var dump = [];
            var formatters = !!name ? _.filter(this.formatters, function (formatter) {
                return _.includes(formatter.supports, name);
            }) : this.formatters;

            _.forEach(formatters, angular.bind(this, function (formatter) {
                var formatterLogs = _.filter(this.log, function (item) {
                    return _.includes(formatter.supports, item.name);
                });
                formatter.format(formatterLogs, dump);
            }));

            fileSaver.saveContent(dump, 'text/plain', 'dump');
        };

        /**
         * Clear app log
         */
        AppLog.prototype.clear = function () {
            this.log.splice(0, this.log.length);
        };

        window.log = instance;
        return instance;
    }])
    .config(['$provide', function ($provide) {

        $provide
            .decorator('$log', ['$delegate', 'AppLog', function ($delegate, AppLog) {

                var log = {};
                angular.forEach(['info', 'warn', 'trace', 'debug', 'error'], function (type) {
                    log[type] = angular.bind(null, logWrapperFn, type);
                });

                function logWrapperFn(type) {
                    var args = Array.prototype.splice.call(arguments, 1);
                    AppLog[type](args);
                    $delegate[type].apply(null, args);
                }

                return log;
            }]);

    }]);
