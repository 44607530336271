import { createAction, props } from '@ngrx/store';
import { StoryExplorerIntegrationSettings, StoryExplorerStylingSettings } from '../../models/story-explorer';

export const loadStoryExplorer = createAction('[Story Explorer] load',
props<{
  componentGroupUuid: string,
  propertyId: string
}>());

export const loadStoryExplorerSuccess = createAction('[Story Explorer] loadSuccess', props<{ data: StoryExplorerIntegrationSettings }>());
export const loadStoryExplorerFail = createAction('[Story Explorer] loadFail', props<{ error: any }>());

// save changes toogle
export const commitStoryExplorer = createAction('[Story Explorer] commit',
props<{
  componentGroupUuid: string,
  propertyId: string,
  isActive: boolean
}>());
export const commitStoryExplorerSuccess = createAction('[Story Explorer] commitSuccess', props<{ data: StoryExplorerIntegrationSettings }>());
export const commitStoryExplorerFail = createAction('[Story Explorer] commitFail', props<{ error: any }>());

//styling
export const loadStylingStoryExplorer = createAction('[Story Explorer] load styling',
props<{
    componentGroupUuid: string,
    propertyId: string,
}>());

export const loadStylingStoryExplorerSuccess = createAction('[Story Explorer] styling loadSuccess', props<{ data: StoryExplorerStylingSettings }>());
export const loadStylingStoryExplorerFail = createAction('[Story Explorer] styling loadFail', props<{ error: any }>());

// save data campaigns
export const saveStoryExplorer = createAction('[Story Explorer] save data',
props<{
    componentGroupUuid: string,
    propertyId: string,
    changes: StoryExplorerIntegrationSettings | StoryExplorerStylingSettings,
    tab: string
}>());

export const saveStoryExplorerSuccess = createAction('[Story Explorer] save loadSuccess');
export const saveStoryExplorerFail = createAction('[Story Explorer] save loadFail', props<{ error: any }>());
