import { Component, ContentChild, HostListener, Inject, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../classes/modal-editor-base';
import { filter, first } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
    selector: 'ft-modal-editor-wrapper',
    templateUrl: './modal-editor-wrapper.component.html',
    styles: [':host {display: block}']
})
export class ModalEditorWrapperComponent {
    @ContentChild('triggerTmpl', { static: false }) triggerTmpl: TemplateRef<any>;
    @ViewChild('dialogTmpl', { static: false }) dialogTmpl: TemplateRef<any>;
    @Input() canSave = true;
    @Input() hasCloseButton = false;
    @Input() id = null;
    dialogRef: MatDialogRef<any>;
    isLoading = false;
    successSubscription: Subscription;
    errorSubscription: Subscription;

    constructor(protected dialog: MatDialog,
                @Inject(MODAL_EDITOR_TOKEN) public editorBase: ModalEditorBase) {
    }


    @HostListener('click', ['$event'])
    openDialog(e: MouseEvent) {
        e.stopPropagation();
        this.dialogRef = this.dialog.open(this.dialogTmpl, {
            panelClass: 'ft2',
            id: this.id,
            backdropClass: '-dark',
            data: this
        });
        this.dialogRef.afterOpened().subscribe(() => {
            this.editorBase.visibilityChanged(true);

        });
        this.dialogRef.afterClosed().subscribe(() => {
            this.editorBase.visibilityChanged(false);
        });
        this.dialogRef.keydownEvents()
            .pipe(
                filter(event => this.editorBase.visible && (event.key === 'Escape'))
            )
            .subscribe(() => {
                this.editorBase.onCancelChanges();
                this.editorBase.onCancel();
            });
        this.editorBase.updateInitialValue();
    }

    onSave() {
        this.editorBase.applyChanges();
        if (this.editorBase.saveSuccess$) {
            this.isLoading = true;
            this.handleSubscriptions();
        } else {
            this.dialogRef.close();
        }
    }

    handleSubscriptions() {
        this.onSuccess();
        this.onError();
    }

    onSuccess() {
        this.successSubscription = this.editorBase.saveSuccess$.pipe(first()).subscribe(() => {
            this.dialogRef.close();
            this.isLoading = false;
        });
    }

    onError() {
        this.errorSubscription = this.editorBase.error$?.pipe(first()).subscribe(() => {
            this.successSubscription.unsubscribe();
            this.isLoading = false;
        });
    }

    onClose() {
        this.editorBase.onCancelChanges();
        this.editorBase.onCancel();
        this.dialogRef.close();
    }
}
