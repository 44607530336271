import { QueryArrayFormat } from "@flipto/shared-discovery/enums/query-array-fomat.enum";

export class TokenOption {
  format: string = '';
  fallback: string = '';
  encoding: string = 'uri';
  arrayFormat: QueryArrayFormat = QueryArrayFormat.RepeatedValue;
  delimiter: string = ','
  startIndex: number = 1;
}
