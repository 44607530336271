import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentage'
})
export class PercentagePipe implements PipeTransform {

  transform(value: any, total: string): any {
    const percentage = Number(value) / Number(total) * 100;
    return isNaN(percentage) ? 0 : Math.ceil(percentage);
  }

}
