
import { commitFollowupEmail, commitFollowupEmailSuccess, loadFollowupEmail, loadFollowupEmailFail, loadFollowupEmailSuccess, loadStylingFollowupEmail, loadStylingFollowupEmailFail, loadStylingFollowupEmailSuccess, saveFollowupEmail, saveFollowupEmailFail, saveFollowupEmailSuccess } from './actions';
import { Action, createReducer, on } from "@ngrx/store";
import { State } from "./state";

export const reducer = createReducer(
    {
        loading: false,
        settings: null,
        commitSuccess: false,
        stylingSettings: null
    } as State,
    on(loadFollowupEmail, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(loadFollowupEmailSuccess, (state, action) => {
        return {
            ...state,
            loading: false,
            settings: action.data
        };
    }),
    on(loadFollowupEmailFail, (state, {error}) => {
        return {
            ...state,
            loading: false,
            error
        };
    }),
    on(commitFollowupEmail, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(commitFollowupEmailSuccess, (state, {data}) => {
        return {
            ...state,
            loading: false,
            settings: data
        };
    }),
    // styling
    on(loadStylingFollowupEmail, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(loadStylingFollowupEmailSuccess, (state, {data}) => {
        return {
            ...state,
            loading: false,
            stylingSettings: data
        };
    }),
    on(loadStylingFollowupEmailFail, (state, {error}) => {
        return {
            ...state,
            loading: false,
            error
        };
    }),
    // save snapshot
    on(saveFollowupEmail, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(saveFollowupEmailSuccess, (state) => {
        return {
            ...state,
            loading: false
        };
    }),
    on(saveFollowupEmailFail, (state, {error}) => {
        return {
            ...state,
            loading: false,
            error
        };
    })
);

export function followupEmailReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}
