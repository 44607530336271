/**
 * Created by ostapenko.r on 02/25/2015.
 */


angular
    .module('flipto.core.storage')
    .service('sessionStorage',
    [
        function () {

            /**
             * Session storage creation
             * @constructor
             */
            function SessionStorage() {

                this.storage_ = sessionStorage;
            }

            /**
             * Additional functionality
             * @type {{constructor: Function, put: Function, get: Function, delete: Function}}
             */
            SessionStorage.prototype = {

                constructor: SessionStorage,

                /**
                 * Put data into storage
                 * @param key
                 * @param data
                 */
                put: function (key, data) {
                    this.storage_.setItem(key, JSON.stringify(data));
                },

                /**
                 * Get data from storage
                 * @param key
                 */
                get: function (key) {
                    var item = this.storage_.getItem(key);
                    return item && JSON.parse(item) || null;
                },

                /**
                 * Remove data from storage
                 * @param key
                 */
                remove: function (key) {
                    this.storage_.removeItem(key);
                },

                /**
                 * Remove all data from storage
                 */
                clear: function () {
                    this.storage_.clear();
                }
            };


            return new SessionStorage();
        }
    ]);

