/**
 * Created by Roman on 2015-03-17.
 */



angular.module('flipto.components.common.validation')
    .config(['$provide', function ($provide) {

        /*var ANGULAR_URL_REGEXP = /^(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/;*/
        var URL_REGEXP = /^([a-z][a-z0-9\*\-\.]*):\/\/(?:(?:(?:[\w\.\-\+!$&'\(\)*\+,;=]|%[0-9a-f]{2})+:)*(?:[\w\.\-\+%!$&'\(\)*\+,;=]|%[0-9a-f]{2})+@)?(?:(?:[a-z0-9\-\.]|%[0-9a-f]{2})+|(?:\[(?:[0-9a-f]{0,4}:)*(?:[0-9a-f]{0,4})\]))(?::[0-9]+)?(?:[\/|\?](?:[\w#!:\.\?\+=&@!$'~*,;\/\(\)\[\]\-]|%[0-9a-f]{2})*)?$/i;
        var SCHEME_REGEXP = /^(ftp|http|https):\/\//;
        $provide.decorator('inputDirective', ['$delegate', function ($delegate) {

            var directive = $delegate[0];
            var link = directive.link;
            directive.compile = function () {

                return function (scope, el, attrs, ctrls) {

                    var ctrl = ctrls[0];
                    link.pre.apply(this, arguments);

                    /*url specific validation changes*/
                    if (ctrl && el[0].type === 'url') {

                        /*change url regex*/
                        ctrl.$validators.url = function (modelValue, viewValue) {
                            var value = modelValue || viewValue;
                            var isValidUrl = ctrl.$isEmpty(value) || URL_REGEXP.test(value);
                            return isValidUrl;
                        };

                        /*append scheme if applicable*/
                        ctrl.$parsers.push(function (viewValue) {
                            if (!ctrl.$isEmpty(viewValue) && !SCHEME_REGEXP.test(viewValue)) {
                                return ('https://' + viewValue).trim();
                            }
                            return !viewValue ? viewValue : viewValue.trim();
                        });

                        ctrl.$formatters.push(function (viewValue) {
                            if (!ctrl.$isEmpty(viewValue) && !SCHEME_REGEXP.test(viewValue)) {
                                ctrl.$setViewValue(viewValue.trim());
                                return 'https://' + viewValue.trim();
                            }
                            return !viewValue ? viewValue : viewValue.trim();
                        });

                    }

                };
            };

            return $delegate;
        }]);
    }]);
