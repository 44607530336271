/**
 * Created by roman.kupin on 9/27/2017.
 */


import ContextSelectorActions from "../../actions/index";

export const PREFIX = "[ContextSelector].[ContextGroup]";


export function toggleMany(contextSelectorId, groupName, contextId) {
    return {
        type: ContextSelectorActions.CONTEXT_TOGGLE_MANY,
        payload: {contextSelectorId, groupName, contextId}
    };
}

export function toggleSingle(contextSelectorId, groupName, contextId) {
    return {
        type: ContextSelectorActions.CONTEXT_TOGGLE_SINGLE,
        payload: {contextSelectorId, groupName, contextId}
    };
}

export function setSingle(contextSelectorId, groupName, contextId) {
    return {
        type: ContextSelectorActions.CONTEXT_SET_SINGLE,
        payload: {contextSelectorId, groupName, contextId}
    };
}

export function selectAll(contextSelectorId, groupName) {
    return {
        type: ContextSelectorActions.CONTEXT_SELECT_ALL,
        payload: {contextSelectorId, groupName}
    };
}

export function selectNone(contextSelectorId, groupName) {
    return {
        type: ContextSelectorActions.CONTEXT_SELECT_NONE,
        payload: {contextSelectorId, groupName}
    };
}

const All = {toggleMany, toggleSingle, setSingle, selectAll, selectNone};
export default All;