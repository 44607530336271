/**
 * Created by Roman on 2015-04-20.
 */


angular.module('flipto.accountv1.SharedFeatures')
    .controller('flipto.account.sections.stories.LibraryController',
        [
            'tabs', '$controller',
            function (tabs, $controller) {

                var vm = this;
                vm.tabs = tabs;
                vm.criteria = "";

                /**
                 * Change tab
                 * @param newView
                 */
                vm.setView = function (newView) {
                    if (vm.view == newView) return;
                    vm.view = newView;
                };

                vm.setView('photos');

            }
        ]);