/**
 * User: Kupin.R
 * Date: 6/4/14
 */


angular
    .module("flipto.accountv1.SharedFeatures")
    .filter("touchPointName",
    [
        "$filter", "_",
        function ($filter, _) {

            var translate = $filter("translate");
            var namesByType = [
                {type: "confirmation", name: translate("flipto.account.platform.preStay.touchpoints.ConfirmationPage")},
                {type: "landing", name: translate("flipto.account.platform.preStay.touchpoints.LandingPage")},
                {type: "mobile", name: translate("flipto.account.platform.preStay.touchpoints.MobileLandingPage")},
                {type: "followup", name: translate("flipto.account.platform.preStay.touchpoints.FollowupPage") },
                {type: "confirmation-email-image", name: translate("flipto.account.ConfirmationEmailBanner") },

                { type: "invitation-email", name: translate("flipto.account.InvitationEmail") },
                {type: "quote", name: translate("flipto.account.platform.postStay.touchpoints.PostStayProcess") },
                {type: "reminder-emails", name: translate("flipto.account.ReminderEmails") },
                {type: "reengagement-emails", name: translate("flipto.account.ReengagementEmails") },

                {type: "predefinedQuote", name: translate("flipto.account.platform.homepage.touchpoints.PredefinedQuote") },
                {type: "homepage", name: translate("flipto.account.platform.homepage.touchpoints.Homepage") },
                {type: "homepageEmail", name: translate("flipto.account.platform.homepage.touchpoints.EmailPage") },

                {type: "list", name: translate("flipto.account.platform.quoteList.touchpoints.List") },

                {type: "contestPage", name: translate("flipto.account.PhotoExplorer") },
                {type: "finalistsEmail", name: translate("flipto.account.sections.platform.photoAdvocacy.FinalistsEmail") },
                {type: "reminderEmail", name: translate("flipto.account.sections.platform.photoAdvocacy.ReminderEmail") },
                {type: "activityEmail", name: translate("flipto.account.sections.platform.photoAdvocacy.ActivityEmail") },
                {type: "winnerEmail", name: translate("flipto.account.sections.platform.photoAdvocacy.WinnerEmail") },
                {type: "pre-arrival-email", name: translate("flipto.account.PreArrivalEmail") },
                {type: "poststay-email-image", name: translate("flipto.account.PoststayEmailImage") },
                {type: "photo-river", name: translate("flipto.account.PhotoRiver") },

                {type: "certificate-email", name: "Certificate Email" },
                {type: "certificate-reminder-email", name: "Certificate Reminder Email" },

            ];

            function getTouchPointName(touchPoint) {
                var touchPointInfo = _.find(namesByType, {type: touchPoint.type});
                return touchPointInfo ? touchPointInfo.name : touchPoint.type;
            }

            return function (touchPoint) {
                return getTouchPointName(touchPoint);
            };
        }
    ]);
