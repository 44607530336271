import { Inject, Injectable } from '@angular/core';
import { EntityDefinitionService, EntityMetadata, HttpUrlGenerator } from '@ngrx/data';
import { Environment, ENVIRONMENT_TOKEN } from "../../core/environment.service";
import { SpacesService } from "../../common/components/spaces";

@Injectable({
    providedIn: 'root'
})
export class EntityCollectionRegistryService {
    constructor(@Inject(ENVIRONMENT_TOKEN) private environment: Environment,
                private spacesService: SpacesService,
                private eds: EntityDefinitionService,
                private hug: HttpUrlGenerator) {
    }

    register(entityHttpResourceUrl: string, entityName: string, entityMetadata: Partial<EntityMetadata<any>> = {}) {
        entityHttpResourceUrl = this.tokenizeUrl(entityHttpResourceUrl);
        this.eds.registerMetadataMap({ [entityName]: entityMetadata });
        this.hug.registerHttpResourceUrls({
            [entityName]: {
                entityResourceUrl: entityHttpResourceUrl,
                collectionResourceUrl: entityHttpResourceUrl
            }
        });
    }

    tokenizeUrl(url: string): string {
        return url.replace('{apiBaseUrl}', this.environment.apiBaseUrl)
            .replace(new RegExp('{propertyUuid}', 'g'), this.spacesService.getPropertyUuid());
    }
}
