/**
 * Created by ostapenko.r on 8/20/2014.
 */

angular
    .module("flipto.components.common.textFormatting")
    .filter("abbr",
        [ function () {

                function getAbbr(languageName,count){
                    return languageName.substring(0,count).toUpperCase();
                }
                return function (languageName, count) {
                    count = count || 3;
                    return getAbbr(languageName,count);
                };
            }
        ]);
