/**
 * Created by Roman on 2014-09-12.
 */



angular.module('flipto.components.common.focus', ['textAngular'])
    .config(['$compileProvider', function($compileProvider) {

        $compileProvider.directive('ftFocus', ['$timeout', function($timeout) {

            return {
                restrict: 'EAC',
                link: function(scope, element) {
                    $timeout(function() {
                        element[0].focus();
                    });
                }
            };
        }]);

        $compileProvider.directive('ftFocusFirstInput', ['$timeout', 'focus', function($timeout, focus) {
            return {
                restrict: 'EAC',
                link: function(scope, element) {
                    focus.firstInputOf(element[0]);
                }
            };
        }]);
    }])
    .factory('focus', ['textAngularManager', '$timeout',
        function(textAngularManager, $timeout) {

            function tryFocusFirstInput(elem) {
                var firstInput = elem.querySelector('input, textarea');
                if ( !firstInput ) return;
                var input = firstInput.parentElement.querySelector('input[name]');
                if ( !!input ){
                    var editorName = angular.element(input).attr('name');
                    var editor = textAngularManager.retrieveEditor(editorName);
                    editor && $timeout(function() {
                        editor.scope.displayElements.text[0].focus();
                        //editor.scope.displayElements.text.trigger('focus');
                    });
                } else {
                    $timeout(function() {
                        firstInput.focus();
                    });
                }
            }

            function firstInputOf(elem) {
                // using timeout with 50ms delay as some components need to run through compile first before attempting to focus something
                $timeout(function() {
                    tryFocusFirstInput(elem);
                }, !elem.querySelector('input, textarea') ? 50 : 0);
            }

            return {
                firstInputOf: firstInputOf
            };
        }

    ]);
;
