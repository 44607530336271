/**
 * Created by Roman on 2015-07-30.
 */


angular.module('flipto.core.proxy', [])
    .factory('proxy', function () {

        return function proxy(obj, key, fn) {
            var tmp = obj[key];
            obj[key] = function () {
                fn();
                return tmp.apply(tmp, arguments);
            };
        }

    });
