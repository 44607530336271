<div class="ft2-edit-panel"
     [class.-disabled]="disabled"
     [class.-loading]="loading || forceLoading"
     [class.-read-only]="readOnly"
     [class.-static]="static">
    <div class="ft2-edit-panel_label"
         [class.-fluid]="fluidLabel"
         *ngIf="label || summary">
        {{label}}<ng-container *ngIf="showColon">:</ng-container>
        <div class="ft2-edit-panel_summary">
            {{summary}}
        </div>
    </div>
    <ng-content></ng-content>
    <ng-container *ngIf="secondLabel">
        <div class="d-flex col-12">
            <div class="ft2-edit-panel_label"
                [class.-fluid]="fluidLabel">
                {{secondLabel}}<ng-container *ngIf="showColon">:</ng-container>
            </div>
            <div>
                <ng-content select="[secondLabel]"></ng-content>
            </div>
        </div>
    </ng-container>
    <div class="ft2-edit-panel_error" *ngIf="error">
        <small class="v-center">
            {{error?.message || 'Error processing request'}}
        </small>
    </div>
    <span *ngIf="loading || forceLoading" class="ft2-edit-panel_spinner ft2-spinner"></span>
</div>

