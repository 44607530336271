/**
 * Created by ostapenko.r on 3/6/2015.
 */
angular.module('flipto.accountv1.SharedFeatures')
    .controller('flipto.account.sections.platform.StylingReminderEmailsTemplateController',
    ['$scope', 'touchpointGroupId', 'layerName', 'touchpointType', 'Styling', 'ftModal', 'parse', 'SpacesService',
        function ($scope, touchpointGroupId, layerName, touchpointType, Styling, ftModal, parse, spacesService) {
            'use strict';
          var vm = this,
              touchpointGroupId = touchpointGroupId,
              layerName = $scope.layerName = layerName,
              touchpointType = $scope.touchpointType = touchpointType;

            Styling.getEmail({
                propertyUuid: spacesService.getPropertyUuid(),
                layerName : layerName,
                touchpointUUID: touchpointGroupId,
                touchpointType:  touchpointType
            }).then(function(data){
                vm.nonViewEmailTemplates = parse.arrayOrDefault(data.nonViewEmailTemplates);
                vm.afterViewEmailTemplates = parse.arrayOrDefault(data.afterViewEmailTemplates);

                vm.nonViewEmailSelectedTemplate = vm.getSelectedTemplate(vm.nonViewEmailTemplates, data.nonViewEmailtemplateUUID);
                vm.afterViewEmailSelectedTemplate = vm.getSelectedTemplate(vm.afterViewEmailTemplates, data.afterViewEmailtemplateUUID);
            });

            vm.getSelectedTemplate = function(templates, templateUUID) {
                var defaultTemplate = _.find(templates, { isDefaultTemplate: "1" });
                defaultTemplate && (defaultTemplate.name = defaultTemplate.name + ' (Default)');
                return _.find(templates, { id: templateUUID }) || defaultTemplate;
            }

            vm.updateEmailTemplate = function(){
                Styling.putReminderEmails({
                    propertyUuid: spacesService.getPropertyUuid(),
                    layerName : layerName,
                    touchpointUUID: touchpointGroupId,
                    touchpointType:  touchpointType,
                    nonViewEmailTemplateUUID: vm.nonViewEmailSelectedTemplate.isDefaultTemplate === "0" ? vm.nonViewEmailSelectedTemplate.id : "",
                    afterViewEmailTemplateUUID: vm.afterViewEmailSelectedTemplate.isDefaultTemplate === "0" ? vm.afterViewEmailSelectedTemplate.id : ""
                });
            };

        }
    ]);
