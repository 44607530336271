import { IComponentFlowBuilder, FlowStep } from "..";

declare const angular;

export class ShowStoryComponentFlowBuilder implements IComponentFlowBuilder {

    $injector: any;

    constructor(public propertyUuid: string, public storyUuid: string) {
        this.$injector = angular.element(document.querySelector("ft-account-app")).injector();
    }

    createSteps(): FlowStep[] {
        return [
            new FlowStep("StoryModal",
                `<ft-story-modal property-uuid="${this.propertyUuid}" story-uuid="${this.storyUuid}" recommendation="$ftFlow.recommendation" is-recommendation-expanded="$ftFlow.isRecommendationExpanded" has-other-recommendations="$ftFlow.hasOtherRecommendations"></ft-story-modal>`,
                true
            )
        ];
    }

    createTemplate(steps: FlowStep[]): string {
        return `
        <ft-flow>
            ${steps.map(s => `<ft-step name='${s.name}'>${s.template}</ft-step>`).join("")}
        </ft-flow>
        `;
    }
}
