/**
 * Created by roman.kupin on 9/28/2017.
 */

import * as PoststayApiActions from "../../actions/api/poststayApi";


export default function prestayReducer(state = {}, {type, payload}) {
    switch (type) {
        case PoststayApiActions.CAMPAIGNS_FETCH_SUCCESS: {
            return Object.assign(state, payload);
        }
        default: {
            return state;
        }
    }
}