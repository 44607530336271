/**
 * Created by roman.kupin on 9/12/2017.
 */

import AccountApiActions from "../../actions/accountApi";
import { SpaceType } from 'account-hybrid/common/components/spaces';

const TEMPLATE_URL = require("./showcaseStories.html");

export const NAME = "ftShowcaseStories";

export class ShowcaseStoriesComponent {
    static $inject = ["$ngRedux", 'SpacesService', 'SessionService'];

    unsubscribe: any;

    constructor(public $ngRedux, public spacesService, public sessionService) {
        this.unsubscribe = $ngRedux.connect(state => {
            const showcaseStories = state.ui.showcaseStories;
            const photos = state.apiData.randomPhotos;
            const property = this.sessionService.getProperty(state.ui.context.singleProperty);
            return {
                ...showcaseStories,
                photos,
                property
            };
        })(this);
    }

    $onInit() {
        if (this.spacesService.current.type === SpaceType.Company || this.spacesService.current.type === SpaceType.Mixed) {
            this.$ngRedux.dispatch(AccountApiActions.getRandomPhotos(this.spacesService.getCompanyUuid()));
        }
    }

    $onDestroy() {
        this.unsubscribe();
    }
}

export const OPTIONS = {
    templateUrl: TEMPLATE_URL,
    controllerAs: "vm",
    controller: ShowcaseStoriesComponent,
    transclude: true
};
