/**
 * Created by roman.kupin on 11/17/2017.
 */

import {injectLoader, injectTableCharts} from "../googleChartsLoader";

export const NAME = "ftTableChart";

declare let google;

export class TableChartController {

    static $inject = ["$element"];
    static DEFAULT_OPTIONS = {showRowNumber: true, width: '100%', height: '100%'};

    whenChartsReady: Promise<any>;
    container: Node;
    data: any;
    options: any;

    constructor(private $element) {
        this.container = this.$element[0].querySelector("div");
        this.whenChartsReady = injectLoader().then(injectTableCharts);
    }

    $onInit() {
        this.whenChartsReady.then(() => TableChartController.draw(this.data, this.container, this.options));
    }

    $onChanges() {
        this.whenChartsReady.then(() => TableChartController.draw(this.data, this.container, this.options));
    }

    static draw(data, targetElement, options?) {
        if (!data) return;
        let table = new google.visualization.Table(targetElement);
        table.draw(data, options || TableChartController.DEFAULT_OPTIONS);
    }
}

export const OPTIONS = {
    template: `<div class="ft-tablechart"></div>`,
    controllerAs: "vm",
    controller: TableChartController,
    bindings: {
        "data": "<",
        "options": "<?"
    }
};
