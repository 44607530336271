/**
 * Created by max.ackerman on 8/16/2018.
 */


const TEMPLATE_URL = require("./storyHighlight.html");

export const NAME = "ftStoryHighlight";

export class StoryHighlightController {
        
    static $inject = ["$ngRedux"];
    
    story: any;
    onSelected: any;

}
export const OPTIONS = {
    templateUrl: TEMPLATE_URL,
    controllerAs: "vm",
    controller: StoryHighlightController,
    bindings: {
        story: "<",
        onSelected: "&"
    }
};
