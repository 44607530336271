import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountSharedModule } from '../../../../account-shared';
import { ShowcaseStoriesComponent } from './components/showcase-stories.component';
import { StoreModule } from '@ngrx/store';
import { SHOWCASE_STORIES_FEATURE_NAME, showcaseReducer } from './store';
import { EffectsModule } from '@ngrx/effects';
import { showcaseStoriesEffects } from './store/effects';

@NgModule({
    imports: [
        CommonModule,
        AccountSharedModule,
        StoreModule.forFeature(SHOWCASE_STORIES_FEATURE_NAME, showcaseReducer),
        EffectsModule.forFeature(showcaseStoriesEffects)
    ],
    declarations: [ShowcaseStoriesComponent],
    exports: [ShowcaseStoriesComponent]
})
export class ShowcaseStoriesModule {}
