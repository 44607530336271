/**
 * Created by roman.kupin on 9/18/2017.
 */

// import {
//     AreasRegistryProvider, AREAS_REGISTRY, APP_MENU_AREAS_REGISTRY
// } from "./areasRegistry.service";
import * as AreasDirective from "./areas.directive";

declare var angular;
export const AREAS_MODULE = angular.module("Components.Navigation.Areas", []);

// export class ServiceConfig {

//     static $inject = ["$provide"];

//     constructor($provide) {
//         $provide.provider(AREAS_REGISTRY, AreasRegistryProvider);
//         $provide.provider(APP_MENU_AREAS_REGISTRY, AreasRegistryProvider);
//     }
// }

export class ComponentConfig {

    static $inject = ["$compileProvider"];

    constructor($compileProvider) {
        const directives = [AreasDirective];
        directives.forEach(directive => {
            $compileProvider.directive(directive.NAME, () => directive.DEFINITION);
        });
    }
}


// AREAS_MODULE.config(ServiceConfig);
AREAS_MODULE.config(ComponentConfig);