import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class IconsService {
    isIconsRegistered = false;
    iconsFolderPath = 'assets/img/icons/';
    icons = [
        'info',
        'arrow-down',
        'arrow-up',
        'arrow-left',
        'arrow-right',
        'close',
        'code',
        'target',
        'lock',
        'dots',
        'search',
        'file',
        'files',
        'conversations',
        'visitors',
        'planning',
        'share',
        'ft-icn-file-ignore',
        'ft-icn-file-processed',
        'ft-icn-file-upload',
        'ft-icn-email',
        'error',
        'cloud-download',
        'touchpoint',
        'check',
        'grade',
        'stat-clicks',
        'stat-leads',
        'stat-bookings',
        'stat-reach',
        'stat-visitors',
        'stat-photos',
        'stat-quotes',
        'stat-criticalfeedback',
        'plane',
        'icon_cards_social_facebook',
        'icon_cards_social_linkedin',
        'icon_cards_social_twitter',
        'ft-icn-flag',
        'icon-trophy',
        'ft-icn-seedplant',
        'icn-sun',
        'plus',
        'timestamp',
        'caption',
        'quote',
        'photo',
        'quotelist',
        'curate-inprogress',
        'curate-done',
        'icon_curate_done',
        'flipto-logo',
        'rating-stars0',
        'rating-stars1',
        'rating-stars2',
        'rating-stars3',
        'social-icon1',
        'social-icon2',
        'social-icon3',
        'lightbulb',
        'pegman',
        'arrow-open',
        'ft-icn-pencil-outline',
        'arrow-circle-down',
        'arrow-circle-up',
        'ft-icn-arrow-right',
        'ft-icn-arrow-down'
    ];


    constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
    }

    register() {
        this.icons.forEach(icon => {
            this.iconRegistry.addSvgIcon(icon, this.sanitizer.bypassSecurityTrustResourceUrl(`${this.iconsFolderPath}${icon}.svg`));
        });
    }
}
