import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';

@Component({
    selector: 'ft-dropzone-uploader-editor',
    templateUrl: './dropzone-uploader-editor.component.html',
    styleUrls: ['./dropzone-uploader-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => DropzoneUploaderEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: DropzoneUploaderEditorComponent
        }
    ]
})
export class DropzoneUploaderEditorComponent extends ModalEditorBase {
    @Input() accept = '.jpg, .jpeg, .png, .gif';
    @Input() imagePreview = true;
    @Input() multiple = false;
    @Input() removable = false;
    @Input() hasLabel = false;
    @Input() fileKey = 'file';
    @Input() labelKey = 'label';
    @Output() removeClicked: EventEmitter<void> = new EventEmitter();
    // Re-rendering flag to fix rendering bug when selected image after closing dialog still there
    rerender = false;

    constructor(private cdr: ChangeDetectorRef) {
        super();
    }

    onSelect(files: File[]) {
        const file = this.multiple ? files : files[0];
        if (this.hasLabel) {
            this.value$.next({
                ...(this.value || {}),
                [this.fileKey]: file,
            });
        } else {
            this.value$.next(file);
        }
    }

    onLabelChange(label: string) {
        this.value$.next({
            ...(this.value || {}),
            [this.labelKey]: label,
        });
    }

    onRemoveClicked() {
        this.removeClicked.next();
    }

    isInvalid() {
        return this.hasLabel ? !this.value?.[this.fileKey] : !this.value;
    }

    applyChanges() {
        super.applyChanges();
        this.makeRerender();
        this.reset();
    }

    onCancel() {
        super.onCancel();
        this.makeRerender();
        this.reset();
    }

    makeRerender() {
        this.rerender = true;
        setTimeout(() => {
            this.rerender = false;
        });
    }

    reset() {
        this.value = this.initialValue;
    }
}

