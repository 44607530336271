/**
 * Created by ostapenko.r on 12/3/2014.
 */

angular.module('flipto.components.rating', [])
    .directive('ftRating', ['$filter', '$compile', function ($filter, $compile) {

        var translate = $filter('translate'),
            ratingItems = {
                1:  translate('flipto.account.rating.NotGood'),
                2:  translate('flipto.account.rating.Good'),
                3:  translate('flipto.account.rating.Great'),
                4:  translate('flipto.account.rating.Amazing')
            };

        function getCaption(value) {
            return ratingItems[value];
        }


        return {
            restrict: 'E',
            templateUrl: '/app/components/rating/rating.html',
            scope: {
                'stars': '=',
                'onChange': '&?onChange',
                'disabled': '=?disabled'
            },
            link: function (scope, element) {
                scope.ratingItems = ratingItems;
                scope.setCaption = setCaption;

                scope.rate = function(rate){
                    if(scope.disabled) return;
                    if(!!scope.onChange) {
                        var changeRes = scope.onChange({value:rate});
                        if(typeof changeRes !== "undefined" && !!changeRes) {
                            scope.stars = rate;
                        }
                    } else {
                        scope.stars = rate;
                    }
                };
                setCaption();

                function setCaption(value){
                    scope.caption = (value && getCaption(value)) || (scope.stars && getCaption(scope.stars));
                }

            }
        };
    }]);