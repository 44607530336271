import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Transition } from '@uirouter/core';
import { SpacesService } from 'account-hybrid/common/components/spaces';
import { SessionService } from 'account-hybrid/core/authentication';
import { Observable } from 'rxjs';
import { CampaignGuestsSettings } from '../../models/campgain-guests';
import * as fromTouchpoints from '../../store';
import { loadCampaignGuests, saveCampaignGuests } from '../../store/campaign-guests';

@Component({
  selector: 'ft-campaign-guests',
  templateUrl: './campaign-guests.component.html',
  styleUrls: ['./campaign-guests.component.scss']
})
export class CampaignGuestsComponent implements OnInit {
  // touchpoints params
  propertyId:string;
  componentGroupUuid: string;
  options: any;
  campaignGuestsData$: Observable<CampaignGuestsSettings>;
  commitSuccess$: Observable<any>;
  selectedCampgainGuestsLoading$: Observable<boolean>;
  newMessage: string = '';

  constructor(
    private store$: Store<fromTouchpoints.State>,
    private transition: Transition,
    private sessionService: SessionService,
    private spacesService: SpacesService,
  ) { }

  ngOnInit() {
    this.propertyId = this.transition.params().propertyId;
    this.componentGroupUuid = this.transition.params().componentGroupUuid;
    this.options = this.transition.options().custom;

    this.store$.dispatch(loadCampaignGuests({
      componentGroupUuid: this.componentGroupUuid,
      propertyId: this.propertyId,
      state: this.options.state
    }));

    this.campaignGuestsData$ = this.store$.pipe(select(fromTouchpoints.getCampaignGuestsData));
    this.commitSuccess$ = this.store$.pipe(select(fromTouchpoints.getCampaignGuestsCommitSuccess));
    this.selectedCampgainGuestsLoading$ = this.store$.pipe(select(fromTouchpoints.getCampaignGuestsLoading));
  }

  onFormCommit(params, data?) {
    const changes: any = [{
      languageCode: 'en',
      ...data,
      isActive: Number(data.isActive),
      type: Number(data.type)
    }];
    this.store$.dispatch(saveCampaignGuests({
      componentGroupUuid: this.componentGroupUuid,
      propertyId: this.propertyId,
      state: this.options.state,
      changes: data
    }));
  }
}
