import { Component, EventEmitter, forwardRef, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash-es'
import dayjs from 'dayjs';

export interface PeakOffSeasonDateRange {
    propertyPeakDateRangeUuid: string;
    startDate: NgbDate | string;
    endDate: NgbDate | string;
    isOffPeak: boolean;
}


@Component({
    selector: 'ft-peak-off-seasons-editor',
    templateUrl: './peak-off-seasons-editor.component.html',
    styleUrls: ['./peak-off-seasons-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => PeakOffSeasonsEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: PeakOffSeasonsEditorComponent
        }
    ]
})
export class PeakOffSeasonsEditorComponent extends ModalEditorBase implements OnInit {
    @Output() rangeRemoved = new EventEmitter<string>();
    @Output() save = new EventEmitter<{ [key: string]: PeakOffSeasonDateRange[] }>();

    currentDate = new Date();
    baseStartEndDate: NgbDate = new NgbDate(null, null, null);
    dateRangeDefaultValue: PeakOffSeasonDateRange = {
        propertyPeakDateRangeUuid: null,
        startDate: { ...this.baseStartEndDate } as NgbDate,
        endDate: { ...this.baseStartEndDate } as NgbDate,
        isOffPeak: false
    };

    ngOnInit() {
        this.value$
            .subscribe(value => {
                if (!value || !value?.length) {
                    this.initialValue = [];
                }
                if (value?.length) {
                    this.updateInitialValue();
                }
            });
    }

    removeRange(index) {
        this.rangeRemoved.emit(this.value.splice(index, 1)?.propertyPeakDateRangeUuid);
    }

    addRange() {
        if (Array.isArray(this.value)) {
            this.value.push({
                ...this.dateRangeDefaultValue
            });
        }
    }

    applyChanges() {
        if (this.isNgModelExist) {
            this.onChangeFn(this.mapOutputValue(this.value));
        }
        this.save.next(this.mapOutputValue(this.outputValue?.[this.name]) as any);
        this.updateInitialValue();
    }

    mapValue(ranges: PeakOffSeasonDateRange[]) {
        return [...(ranges?.length ? ranges : [])].map(v => {
            const startDate = v.startDate ? dayjs(v.startDate as string) : v.startDate;
            const endDate = v.endDate ? dayjs(v.endDate as string) : v.endDate;
            return {
                ...v,
                startDate: dayjs.isDayjs(startDate) ? new NgbDate(startDate.year(), startDate.month() + 1, startDate.date()) : startDate,
                endDate: dayjs.isDayjs(endDate) ? new NgbDate(endDate.year(), endDate.month() + 1, endDate.date()) : endDate
            };
        });
    }

    mapOutputValue(ranges: PeakOffSeasonDateRange[]): PeakOffSeasonDateRange[] {
        return ranges?.map((range: PeakOffSeasonDateRange) => {
            const startDate = Object.values(range.startDate).some(value => !value) ? null : dayjs().year((range.startDate as NgbDate).year).month((range.startDate as NgbDate).month - 1).date((range.startDate as NgbDate).day).toDate().toDateString();
            const endDate = Object.values(range.endDate).some(value => !value) ? null : dayjs().year((range.endDate as NgbDate).year).month((range.endDate as NgbDate).month - 1).date((range.endDate as NgbDate).day).toDate().toDateString();
            return {
                ...range,
                startDate,
                endDate
            };
        }).filter(range => range.startDate && range.endDate);
    }

    onCancelChanges() {
        this.value = cloneDeep(this.mapOutputValue(this.initialValue));
    }
}


