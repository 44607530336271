/**
 * Created by roman.kupin on 11/07/2014.
 */


angular
    .module("flipto.components.touchpoints")
    .directive("ftTouchpointStatusAction",
    [
        "_",
        function (_) {

            return {
                restrict: "A",
                require: "ftTouchpointContext",
                link: function (scope, element, attrs, touchpointContext) {

                    var actionVisibleTo = [
                        {layerName: "pre-stay", type: "mobile"},
                        {layerName: "pre-stay", type: "followup"},
                        {layerName: "post-stay", type: "email"}
                    ];

                    scope.$watch(
                        touchpointContext.getContext,
                        function (context) {
                            if (context) {
                                angular.element(element)[isActionVisible(context) ? "removeClass" : "addClass"]("ng-hide");
                            }
                        }
                    );

                    /**
                     * Returns true if action should be visible for current layer and touchpoint
                     * @param context
                     * @returns {boolean}
                     */
                    function isActionVisible(context) {
                        return !!_.find(actionVisibleTo, function (action) {
                            return action.layerName.toLowerCase() == context.layerName.toLowerCase()
                                && action.type.toLowerCase() == context.type.toLowerCase();
                        });
                    }

                }
            };
        }
    ]);