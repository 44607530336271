import { createAction, props } from '@ngrx/store';
import { AppError } from 'account-hybrid/core/errors/base.errors';

/**
 * Action to handle internal app error
 */
export const internalAppError = createAction(
    '[ERROR] Internal app error',
    props<{ error: AppError } >());

/**
 * Action to handle internal server error
 */
export const internalServerError = createAction(
    '[ERROR] Internal server error',
    props<{ error: AppError } >());
