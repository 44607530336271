import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NotificationsState } from "../store/notifications.state";

export const getNotificationsState = createFeatureSelector<NotificationsState>('Notifications');

export const getNotifications = createSelector(getNotificationsState, (state: NotificationsState) => {
    return !!state ? state.notifications : [];
});

export const getNewNotifications = createSelector(getNotificationsState, (state: NotificationsState) => {
    return !!state.notifications ? state.notifications.filter(notification => !notification.seenDate) : [];
});

export const getLatestBadge = createSelector(getNotificationsState, (state: NotificationsState) => {
    return !!state ? state.latestBadge : null;
});

export const isNotificationsLoading = createSelector(getNotificationsState,
    (state: NotificationsState) => state && state.loading);

export const getNotificationsError = createSelector(getNotificationsState,
    (state: NotificationsState) => state && state.error);
