import { SpacesActions, SpacesActionTypes } from '../actions/spaces.actions';
import { Company, CompanyType, Property } from 'account-hybrid/core/authentication';
import { CompanySpace, MixedSpace, PropertySpace, SpaceBase, SpacesHistoryItem, SpaceType } from '../..';

declare const IntersectionObserver;

export class VisitedSpace {
    history: SpacesHistoryItem;
    space: SpaceBase;
}

export interface State {
    isIntersectionObserverApiSupported: boolean;
    isVisible: boolean;
    isCancelVisible: boolean;
    isInitialized: boolean;
    searchCriteria: string;
    pageSize: number;
    pageIndex: number;
    properties: Property[];
    companies: Company[];
    allSpaces: SpaceBase[];
    allAccessibleSpaces: SpaceBase[];
    collaborativeSpaces: SpaceBase[];
    filteredSpaces: SpaceBase[];
    history: VisitedSpace[];
}

export const initialState: State = {
    isIntersectionObserverApiSupported: typeof IntersectionObserver !== 'undefined',
    isVisible: false,
    isCancelVisible: true,
    isInitialized: false,
    searchCriteria: sessionStorage.getItem('SpacesSearchCriteria'),
    pageSize: 5,
    pageIndex: 0,
    properties: [],
    companies: [],
    allSpaces: [],
    allAccessibleSpaces: [],
    collaborativeSpaces: [],
    filteredSpaces: [],
    history: []
};

export function spaceLookup(space: SpaceBase, historyItem: SpacesHistoryItem) {
    switch (space.type) {
        case SpaceType.Collaborative:
        case SpaceType.Property:
        case SpaceType.Company: {
            return space.id === historyItem.id;
        }
        case SpaceType.Mixed: {
            return (space as MixedSpace);
        }
    }
}

export function reducer(state = initialState, action: SpacesActions) {
    switch (action.type) {
        case SpacesActionTypes.Initialize: {
            const allSpaces = createSpaces(action.payload);
            const allAccessibleSpaces = allSpaces
                .filter(space => space.type !== SpaceType.Collaborative);
            const collaborativeSpaces = allSpaces.filter(space => space.type === SpaceType.Collaborative)
                .map(space => {
                    const collaborativeCompany = action.payload.companies.find(c => c.UUID === space.id);
                    space.sponsorName = collaborativeCompany.Settings?.SponsorProperty?.Name;
                    space.description = collaborativeCompany.Settings.Description;
                    space.destinationName = collaborativeCompany.Settings?.DestinationDisplayName;
                    return space;
                });
            const filteredSpaces = state.isIntersectionObserverApiSupported ?
                getFilteredAndPagedSpaces(allAccessibleSpaces, null, 0, state.pageSize) :
                getFilteredSpaces(allAccessibleSpaces, null);
            const history = action.payload.history
                .filter(item => !!allSpaces.find(s => s.id === item.id))
                .map(item => {
                    return { history: item, space: allSpaces.find(space => space.id === item.id) } as VisitedSpace;
                }).sort((a, b) => {
                    if (b.history.timesVisited > a.history.timesVisited) {
                        return 1;
                    }
                    if (b.history.timesVisited < a.history.timesVisited) {
                        return -1;
                    }
                    return 0;
                });
            return {
                ...state,
                isInitialized: true, ...action.payload,
                allSpaces,
                allAccessibleSpaces,
                collaborativeSpaces,
                filteredSpaces,
                history
            };
        }
        case SpacesActionTypes.SearchCriteriaChanged: {
            const filteredSpaces = state.isIntersectionObserverApiSupported ?
                getFilteredAndPagedSpaces(state.allAccessibleSpaces, action.payload, 0, state.pageSize) :
                getFilteredSpaces(state.allAccessibleSpaces, action.payload);
            return {
                ...state,
                pageIndex: 0,
                searchCriteria: action.payload,
                filteredSpaces,
                isSearchCriteriaChanging: false
            };
        }
        case SpacesActionTypes.SetIsVisible: {
            return { ...state, isVisible: action.payload };
        }
        case SpacesActionTypes.SetIsCancelVisible: {
            return { ...state, isCancelVisible: action.payload };
        }
        case SpacesActionTypes.FetchNextPage: {
            const nextPageResults = getFilteredAndPagedSpaces(state.allAccessibleSpaces, state.searchCriteria, state.pageIndex + 1, state.pageSize);
            const allFetchedResults = [...state.filteredSpaces, ...nextPageResults];
            return { ...state, pageIndex: state.pageIndex + 1, filteredSpaces: allFetchedResults };
        }
        default:
            return state;
    }
}


function companyToSpace(company: Company, companyProperties: Property[], indirectMatchSearchFields: string[] = []): CompanySpace {
    return {
        id: company.UUID,
        title: company.Name,
        slug: company.Code,
        type: company.Type == CompanyType.Collaborative ? SpaceType.Collaborative : SpaceType.Company,
        directMatchSearchFields: [
            company.Code,
            company.Name
        ],
        indirectMatchSearchFields: [
            ...companyProperties.map(p => p.Name),
            ...companyProperties.map(p => p.Slug),
            ...indirectMatchSearchFields
        ],
        backgroundImageUrl: company.BackgroundImageUrl,
        icon: company.DarkBackgroundLogoUrl || company.LightBackgroundLogoUrl,
        permissions: company.Permissions
    };
}

function propertyToSpace(property: Property, propertyCompanies: Company[]): PropertySpace {
    return {
        id: property.UUID,
        title: property.Name,
        slug: property.Slug,
        type: SpaceType.Property,
        directMatchSearchFields: [
            property.Slug,
            property.Name
        ],
        indirectMatchSearchFields: [
            ...propertyCompanies.map(p => p.Name),
            ...propertyCompanies.map(p => p.Code)
        ],
        backgroundImageUrl: property.BackgroundImageUrl,
        icon: property.DarkBackgroundLogoUrl || property.LightBackgroundLogoUrl,
        permissions: property.Permissions
    };
}

function companyAndPropertyToSpace(company: Company, property: Property, indirectMatchSearchFields: string[] = []): MixedSpace {
    return {
        id: company.UUID, // taking company.UUID as mixed space id
        title: company.Name,
        slug: `${company.Code}-${property.Slug}`,
        type: SpaceType.Mixed,
        directMatchSearchFields: [property.Slug, property.Name, company.Name, company.Code],
        indirectMatchSearchFields,
        backgroundImageUrl: property.BackgroundImageUrl,
        icon: property.DarkBackgroundLogoUrl || property.LightBackgroundLogoUrl,
        property: propertyToSpace(property, [company]),
        company: companyToSpace(company, [property])
    };
}


export function createSpaces(state: {
    companies: Company[],
    properties: Property[],
    history: SpacesHistoryItem[]
}, skipSorting = false) {

    const mixedCompaniesList = state.companies
        .filter(c =>
            c.Type !== CompanyType.Collaborative &&
            (c.ActiveAssociatedPropertiesCount === 1 &&
                c.Associations.length === 1 &&
                c.Associations.every(association => state.properties.find(property => association === property.UUID)?.Name === c.Name)) ||
            c.Associations.some(association => !!state.properties.find(property => association === property.UUID)?.IsVirtual));

    const companiesList = state.companies
        .filter(c => !mixedCompaniesList.some(cc => cc.UUID === c.UUID));
    const propertiesList = state.properties
        .filter(p => !p.IsVirtual && !mixedCompaniesList.some(cc => cc.Associations.length === 1 && cc.Associations.some(cca => cca === p.UUID)));

    const mixedSpaces = mixedCompaniesList
        .map(c => {
            const companyProperties = state.properties.filter(p => c.Associations.some(association => association === p.UUID));
            const property = companyProperties.length === 1 ? companyProperties[0] : companyProperties.find((p => p.IsVirtual));
            const realProperties = companyProperties.filter(p => !p.IsVirtual);


            const associatedNotSingleOffsCompanies = state.companies
                .filter(company =>
                    company.Associations.some(a => a === property.UUID)
                );
            const indirectMatches = [
                ...associatedNotSingleOffsCompanies.map(s => s.Code),
                ...associatedNotSingleOffsCompanies.map(s => s.Name),
                ...realProperties.map(p => p.Name),
                ...realProperties.map(p => p.Slug)
            ];
            return companyAndPropertyToSpace(c, property, indirectMatches);
        });


    const propertiesSpaces = propertiesList.map((property) => {
        const propertyCompaniesForSearch = companiesList.filter(company => !!company.Associations.find(propertyUuid => property.UUID === propertyUuid))
            .filter(p => !!p);
        return propertyToSpace(property, propertyCompaniesForSearch);
    });

    const companiesSpaces = companiesList
        .map(c => {
            const companyPropertiesForSearch = c.Associations.map(propertyUuid => state.properties.find(property => property.UUID === propertyUuid))
                .filter(company => !!company);
            return companyToSpace(c, companyPropertiesForSearch);
        })
        .filter(c => !!c);


    const allSpaces = [
        ...propertiesSpaces,
        ...companiesSpaces,
        ...mixedSpaces
    ];


    allSpaces.forEach(space => {
        const visitedIfApplicable = state.history.find(vs => vs.id === space.id);
        space.timesVisited = !!visitedIfApplicable ? visitedIfApplicable.timesVisited : 0;
    });

    return skipSorting
        ? allSpaces
        : allSpaces.sort((a, b) => {
            if (b.timesVisited > a.timesVisited) {
                return 1;
            }
            if (b.timesVisited < a.timesVisited) {
                return -1;
            }
            return a.title.localeCompare(b.title);
        });
}

function getFilteredSpaces(allSpaces: SpaceBase[], searchCriteria: string): SpaceBase[] {
    if (!searchCriteria) {
        return allSpaces;
    }
    try {
        const reCriteria = new RegExp(`${searchCriteria}`, 'gi');
        return allSpaces.filter(s => s.directMatchSearchFields.some(sf => reCriteria.test(sf)));
    } catch {
        return allSpaces;
    }

}

function isCompanyOrMixedSpace(space: SpaceBase) {
    return space.type === SpaceType.Company || space.type === SpaceType.Mixed;
}

function getFilteredAndPagedSpaces(allSpaces: SpaceBase[], searchCriteria: string, pageIndex: number, pageSize: number): SpaceBase[] {
    const reCriteria = new RegExp(`${searchCriteria}`, 'gi');
    const filtered = !!searchCriteria ?
        allSpaces
            .map(space => {
                return {
                    space,
                    isDirectMatch: space.directMatchSearchFields.some(sf => reCriteria.test(sf)),
                    isIndirectMatch: space.indirectMatchSearchFields.some(sf => reCriteria.test(sf))
                };
            })
            .filter(o => o.isDirectMatch || o.isIndirectMatch)
            .sort((aMatch, bMatch) => {
                if (aMatch.isDirectMatch && bMatch.isDirectMatch) {
                    if (isCompanyOrMixedSpace(aMatch.space) && isCompanyOrMixedSpace(bMatch.space)) {
                        return aMatch.space.title.localeCompare(bMatch.space.title);
                    } else if (isCompanyOrMixedSpace(aMatch.space)) {
                        return -1;
                    } else if (isCompanyOrMixedSpace(bMatch.space)) {
                        return 1;
                    }
                } else if (aMatch.isDirectMatch && !bMatch.isDirectMatch) {
                    return -1;
                } else if (!aMatch.isDirectMatch && bMatch.isDirectMatch) {
                    return 1;
                } else if (!aMatch.isDirectMatch && !bMatch.isDirectMatch) {
                    return aMatch.space.title.localeCompare(bMatch.space.title);
                }
            })
            .map(o => o.space) :
        allSpaces;

    // create new space object to make angular rerender the list to fix lazyload scroll issue
    return filtered.slice(pageSize * pageIndex, pageSize * pageIndex + pageSize)
        .map(space => {
            return { ...space };
        });
}
