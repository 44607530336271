import idGen from './id-generator.util';

export class Context {

    _id: string;

    isActive = false;

    constructor(public name: string) {
        this._id = idGen(name);
    }
}

export class PropertyContext extends Context {
    constructor(public name: string,
                public propertyUuid: string,
                public propertySlug: string,
                public propertyBackgroundImageUrl: string) {
        super(name);
    }
}

export class AccountContext extends Context {
    constructor(public id: string,
                public code: string,
                public name: string,
                public backgroundImageUrl: string) {
        super(name);
    }
}

export class ContextSelectorGroup {
    title: string;
    isActive = false;
    initialActiveContextIds: string[];
    totalSelected = 0;


    constructor(public name: string,
                public titleProvider: Function,
                public contexts: Context[]) {
        this.title = titleProvider(this);
    }
}

export class ContextSelectorSetup {
    isActive = false;

    constructor(public id: string,
                public contextGroups: ContextSelectorGroup[]) {

    }
}
