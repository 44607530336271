/**
 * Created by roman.kupin on 10/07/2014.
 */


angular
    .module("flipto.components.touchpoints")
    .directive("ftTouchpointToggleStatus",
    [
        function () {

            return {
                restrict: "A",
                require: "^ftTouchpointContext",
                link: function (scope, element, attrs, touchpointContext) {

                    angular.element(element).bind("click", function () {
                        touchpointContext.setStatus(!touchpointContext.getContext().isActive);
                    });

                    scope.$on("$destroy", function () {
                        angular.element(element).unbind("click");
                    });
                }
            };
        }
    ]);