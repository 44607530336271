/**
 * Created by Roman on 2014-11-04.
 */


angular.module('flipto.components.common.sidenav', [/*'ngAnimate'*/])
    .config(['$compileProvider', '$provide', function ($compileProvider, $provide) {

        $provide.service('ftSidenav', [function () {
                /**
                 * Sidenav service
                 * @constructor
                 */
                function SidenavService() {
                    var sidenav;

                    /**
                     * Register sidenav
                     * @param instance
                     */
                    this.register = function (instance) {
                        sidenav = instance;
                    };
                    /**
                     * Close sidenav
                     */
                    this.close = function () {
                        if(!!sidenav) sidenav.close();
                    };
                    /**
                     * Open sidenav
                     */
                    this.open = function () {
                        if(!!sidenav) sidenav.open();
                    };
                    /**
                     * Sidenav is opened
                     * @returns {boolean}
                     */
                    this.isOpen = function () {
                        return !!sidenav ? sidenav.isOpen() : false;
                    };
                    /**
                     * Toggle sidenav
                     */
                    this.toggle = function () {
                        if(!!sidenav) sidenav.toggle();
                    };
                }

                return new SidenavService();
            }]
        );
        $compileProvider.directive('ftSidenav', ['$animate', '$timeout', 'ftSidenav', function ($animate, $timeout, ftSidenav) {

            return {
                restrict: 'E',
                controller: ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {

                    ftSidenav.register(this);
                    this.open = function () {
                        $scope.isOpen = true;
                    };
                    this.close = function () {
                        $scope.isOpen = false;
                    };
                    this.isOpen = function () {
                        return !!$scope.isOpen;
                    };
                    this.toggle = function () {
                        $scope.isOpen = !$scope.isOpen;
                    };
                }],
                require: 'ftSidenav',
                scope: {
                    'isOpen': '=?'
                },
                compile: function (tElem, tAttrs) {
                    var sidenav = $('<div class="closed sidenav" tabIndex="-1"></div>');
                    var backdrop = $('<div class="ui-mask-modal"></div>');
                    sidenav.append(tElem.html());
                    tElem.empty();
                    tElem.append(sidenav);
                    tElem.append(backdrop);

                    return function (scope, elem, attrs, ctrl) {

                        scope.$watch('isOpen', setOpen);

                        function setOpen(isOpen) {
                            var parent = elem.parent();
                            parent[isOpen ? 'on' : 'off']('keydown', onKeyDown);
                            $animate[isOpen ? 'addClass' : 'removeClass'](backdrop[0], 'ui-mask-visible');
                            backdrop[isOpen ? 'on' : 'off']('click', close);
                            $animate[isOpen ? 'removeClass' : 'addClass'](sidenav[0], 'closed');
                        }

                        function onKeyDown(ev) {
                            if (ev.which === 27) {
                                close();
                                ev.preventDefault();
                                ev.stopPropagation();
                            }
                        }

                        function close() {
                            $timeout(function () {
                                ctrl.close();
                            });
                        }

                    }
                }
            };
        }]);

    }]);