/**
 * User: Kupin.R
 * Date: 6/2/14
 */



angular
    .module('flipto.components.goals')
    .directive("ftIncentiveSelector",
        [
            "$filter", "$sce", "_", "parse",
            function ($filter, $sce, _, parse) {

                var filter = $filter("filter"),
                    incentiveName = $filter("incentiveName");

                return {
                    restrict: "E",
                    templateUrl: "/app/components/goals/directives/incentiveSelector.html",
                    scope: {
                        "goal": "=",
                        "incentives": "=",
                        "culture": "="
                    },
                    link: function (scope, element) {

                        scope.$watch("incentives", function (incentives) {
                            if (incentives) {
                                scope.availableIncentives = filter(parse.arrayOrDefault(incentives), function (incentive) {
                                    return parse.bool(incentive.isActive) && incentive.languages;
                                });
                            }
                        });

                        scope.$watch("goal", function (goal) {
                            if (goal) {
                                var incentive = _.find(scope.incentives.item, function (incentive) {
                                    return incentive.id == goal.incentiveUUID
                                });
                                if (!incentive) {
                                    incentive = scope.incentives.item[0];
                                    goal.incentiveUUID = incentive && incentive.id;
                                    goal.languages = incentive.languages;
                                }
                                scope.goalName = $sce.trustAsHtml(incentiveName(incentive, scope.culture));
                            }
                        }, true);

                        scope.setIncentive = function (incentive) {
                            scope.goal.incentiveUUID = incentive.id;
                            scope.goal.languages = incentive.languages;
                        };


                    }
                };
            }
        ]);
