import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'ft-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
    @Input() backgroundImageUrl: string | null;
    @Input() blur = false;
    @Input() matte = true;
    @Input() inverse = false;
    @Input() fadeOut: 'dark' | 'light';
    @Input() modifierClass: string | null;

    constructor() {
    }

    ngOnInit() {
    }

}
