/**
 * Created by ostapenko.r on 10/10/2014.
 */


angular
    .module("flipto.core.language", ['flipto.core.lodash'])
    .filter("defaultLanguageOrFirst", ["_", "defaultLanguage", function(_, defaultLanguage) {
        return function(languageArray) {
            if (!angular.isArray(languageArray) || languageArray.length === 0) return null;
            var def = _.find(languageArray, function (language) { return language.code === defaultLanguage; });
            def = !!def ? def : languageArray[0];
            return def;
        }
    }])
    .provider("defaultLanguage", [
        function () {

            var defaultLanguageCode = 'en';
            /**
             * Sets default application language
             * @param string
             */
            this.set = function (languageCode) {
                defaultLanguageCode = languageCode;
            };

            this.$get = function () {
                return defaultLanguageCode;
            };
        }
    ])
    .factory('languageHelper', ['_','defaultLanguage',
        function (_, defaultLanguage) {
            return {
                'sortByDefaultLanguage': function (languages) {
                    if (languages && languages.item) {
                        languages.item = _.sortBy(languages.item, function (lang) {
                            return lang.code === defaultLanguage ? '0' : lang.name
                        })
                    }
                },
                'merge': function (to, from) {
                    _.each(from, function (f) {
                        var existent = _.find(to, {code: f.code});
                        if (!existent) {
                            to.push({code: f.code, name: f.name});
                        } else {
                            existent.name = f.name;
                        }
                    })
                }
            }

        }
    ]
);



