import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { DiscoveryComponentSettings } from '../../models/discovery-component/discovery-component-settings.model';
import { Transition } from '@uirouter/core';
import * as fromTouchpoints from 'account-hybrid/common/components/touchpoints/store';
import { SpacesService } from '../../../spaces/spaces.service';
import { DiscoveryVersions } from 'account-hybrid/features/discovery/enums/discovery-versions.enum';
import {
    selectDiscoveryThemingOptions,
    setCurrentDisplayedDiscoveryVersion
} from 'account-hybrid/features/discovery/store';
import { DiscoveryThemingOption } from 'account-hybrid/features/discovery/models/discovery-theming-option.model';


@Component({
    selector: 'ft-planner-flow-global',
    templateUrl: './planner-flow-global.component.html',
    styleUrls: ['./planner-flow-global.component.scss']
})
export class PlannerFlowGlobalComponent implements OnInit {
    componentGroupUuid: string;
    commitSuccess$: Observable<boolean>;
    isLoading$: Observable<boolean>;
    error$: Observable<any>;
    discoverySettings$: Observable<DiscoveryComponentSettings>;
    selectedIndex = 0;
    discoveryRouterThemeOptions$: Observable<DiscoveryThemingOption[]>;
    displayedVersion: DiscoveryVersions;

    inheritBrandingOptions = [
        { label: `${this.spacesService.current.title} controls styling`, value: false },
        { label: 'Property controls styling', value: true }
    ];

    constructor(private store$: Store<fromTouchpoints.State>,
                private spacesService: SpacesService,
                private transition: Transition) {
    }

    ngOnInit(): void {
        this.discoverySettings$ = this.store$.pipe(
            select(fromTouchpoints.selectDiscoveryComponentSettings)
        );
        this.discoveryRouterThemeOptions$ = this.store$.pipe(select(selectDiscoveryThemingOptions));
        this.displayedVersion = this.transition.params().version;
        if (this.displayedVersion) {
            this.store$.dispatch(setCurrentDisplayedDiscoveryVersion({ version: this.displayedVersion }));

        }
        this.componentGroupUuid = this.transition.params().componentGroupUuid;
        this.isLoading$ = this.store$.pipe(select(fromTouchpoints.selectDiscoveryComponentSettingsIsLoading));
        this.commitSuccess$ = this.store$.pipe(select(fromTouchpoints.selectDiscoveryComponentSettingsCommitSuccess));
        this.error$ = this.store$.pipe(select(fromTouchpoints.selectDiscoveryComponentSettingsLoadingError));
    }

    getInheritBrandingLabel(state: boolean) {
        return this.inheritBrandingOptions.find(({ value }) => {
            return state === value;
        })?.label;
    }

    commit(settings: Partial<DiscoveryComponentSettings>) {
        this.store$.dispatch(fromTouchpoints.commitDiscoveryComponentSettings({
            componentGroupUuid: this.componentGroupUuid,
            settings
        }));
    }
}

