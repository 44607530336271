/**
 * Created by Roman on 2015-03-13.
 */



angular.module('flipto.components.common.modelFormatters')
    .directive('ftCsv', ['_', function (_) {

        return {
            require: 'ngModel',
            link: function (scope, element, attrs, modelCtrl) {

                var separator = attrs.ftCsv || ';';
                modelCtrl.$parsers.push(function (inputValue) {
                    return  _.chain(inputValue.split(separator)).map(function (email) {
                        return email.trim();
                    }).compact().value() || [];
                });
            }
        };
    }]);
