/**
 * Created by Roman on 2014-10-07.
 */


angular.module('flipto.accountv1.SharedFeatures')
    .config(['$compileProvider', function ($compileProvider) {
        $compileProvider.directive('ftFinalistIncentives', ['ftModal', '_', function (ftModal, _) {
            return {
                restrict: 'E',
                templateUrl: '/app/sections/platform/layer/photo-advocacy/contest/finalistIncentives.html',
                scope: {
                    finalistIncentives: '=',
                    propertyIncentives: '=',
                    onSave: '&'
                },
                link: function (scope) {

                    scope.showModal = function () {
                        ftModal.show('finalistsGoalsModal');
                    };

                    scope.save = function () {
                        scope.onSave();
                    };

                    scope.getPropertyIncentive = function (incentive) {
                        if (incentive == null) return '';
                        return _.find(scope.propertyIncentives, { id: incentive.incentiveUUID });
                    };

                }
            };
        }]);
        $compileProvider.directive('ftFinalistGoalSelector', ['_', '$document', function (_, $document) {
            return {
                restrict: 'E',
                template: '<div class="ft-dropdown goal-incentives"><a class="ft-dropdown-toggle goal-name"><i class="icon icon-trophy"></i> <span ng-bind-html="getPropertyIncentive(incentive) | incentiveName"></span> <i class="ft-icn-caret-down"></i></a><ul class="ft-dropdown-menu"><li ng-repeat="propertyIncentive in propertyIncentives | filter: {isActive: true}" ng-click="setIncentive(propertyIncentive)"><a>{{propertyIncentive | incentiveName}}</a></li></ul></div>',
                scope: {
                    incentive: '=',
                    propertyIncentives: '='
                },
                link: function (scope, elem) {

                    var goalIncentivesElement = angular.element(elem[0].querySelector('.goal-incentives'));



                    scope.setIncentive = function (incentive) {
                        scope.incentive.incentiveUUID = incentive.id;
                    };

                    scope.getPropertyIncentive = function (incentive) {
                        if (incentive == null || angular.isUndefined(incentive.incentiveUUID)) return '';
                        return _.find(scope.propertyIncentives, { id: incentive.incentiveUUID });
                    };

                    goalIncentivesElement.bind('click', function (e) {
                        goalIncentivesElement.toggleClass('open');
                        e.stopPropagation();
                    });

                    $document.bind('click', function () {
                        goalIncentivesElement.removeClass('open');
                    });

                    scope.$on('$destroy', function () {
                        goalIncentivesElement.unbind('click');
                        $document.unbind('click');
                    });

                }
            };
        }]);
        $compileProvider.directive('ftRunnerups', ['_', '$document', function (_, $document) {
            return {
                restrict: 'E',
                template: '<div class="ft-dropdown goal-types runnerups"><button class="ft-dropdown-toggle ft-btn ft-btn-outline-dark">{{incentive[\'@totalRunnerUps\']}} <i class="ft-icn-caret-down"></i></button><ul class="ft-dropdown-menu"><li ng-repeat="number in numbers" ng-click="incentive[\'@totalRunnerUps\']=number"><a>{{number}}</a></li></ul></div>',
                scope: {
                    incentive: '='
                },
                link: function (scope, elem) {
                    scope.numbers = _.range(1, 21);

                    var runnerUpsElememt = angular.element(elem[0].querySelector('.runnerups'));

                    runnerUpsElememt.bind('click', function (e) {
                        runnerUpsElememt.toggleClass('open');
                        e.stopPropagation();
                    });

                    $document.bind('click', function () {
                        runnerUpsElememt.removeClass('open');
                    });

                    scope.$on('$destroy', function () {
                        runnerUpsElememt.unbind('click');
                        $document.unbind('click');
                    });
                }
            };
        }]);
    }]);
