/**
 * Created by roman.kupin on 28/08/2014.
 */


angular
    .module('flipto.account.language', ['pascalprecht.translate'])
    .config(['$translateProvider', function ($translateProvider) {

        $translateProvider.translations("en", {
            'language.Configuration': 'Configuration',
            'language.Advanced': 'Advanced',
            'language.GeneralSettings': 'General settings',
            'language.AdvancedSettings': 'Advanced settings',
            'language.Preview': 'Preview',
            'language.PageLocation': 'Page location',
            'language.AddCssSelector': 'Add CSS selector'
        });

        $translateProvider.translations("en", {
            'flipto.account.charts.totalBookClicks': 'Total Book Clicks',
            'flipto.account.charts.totalOpens': 'Total Opens',
            'flipto.account.charts.totalViews': 'Total Views',
            'flipto.account.charts.total': 'Total Views'
        });

        $translateProvider.translations("en", {
            'flipto.account.sections.signIn.Email': 'Email',
            'flipto.account.sections.signIn.Password': 'Password',
            'flipto.account.sections.signIn.SignIn': 'Sign in',
            'flipto.account.sections.signIn.ForgotPassword': 'Forgot Password'
        });

        $translateProvider.translations("en", {
            "flipto.account.platform.layer.preStay.trafficSources": "Traffic sources",
            "flipto.account.platform.layer.preStay.direct": "direct",
            "flipto.account.platform.NotSet": "not set",
            "flipto.account.platform.TotalViews": "Total Views",
            "flipto.account.platform.TotalOpens": "Total Opens",
            "flipto.account.platform.TotalBookClicks": "Total Book Clicks",
            "flipto.account.platform.PreStayAdvocacy": "Pre-stay Advocacy",
            "flipto.account.platform.PostStayAdvocacy": "Post-stay Advocacy",
            "flipto.account.platform.ContestInvitation": "Contest Invitation",
            "flipto.account.platform.WarmLeadNurturing": "Warm Lead Nurturing",
            "flipto.account.platform.Homepage": "Homepage",
            "flipto.account.platform.HomepageComponent": "Homepage Component",
            "flipto.account.platform.PasskeyEvents": "Passkey Events",
            "flipto.account.platform.PhotoAdvocacy": "Story Advocacy",
            "flipto.account.platform.NoUpcomingContests": "There are no upcoming campaigns",
            "flipto.account.platform.ThereAreNoContestAddANew": "There are no campaigns. Add a new contest to get started.",
            "flipto.account.platform.ThereAreNoPastContests": "There are no past campaigns",
            "flipto.account.platform.QuoteList": "Quote List",
            "flipto.account.platform.PendingChanges": "Pending changes",
            "flipto.account.AdvocateEngagement": "Guests who shared originated from",
            "flipto.account.platform.GuestsEngaged": "guests engaged",
            "flipto.account.platform.SocialConnections": "social connections", // !!! DISCONTINUE USE. TODO: swap existing instances with flipto.account.SocialConnections
            "flipto.account.platform.SocialConnectionsReached": "social connections reached",

            "flipto.account.platform.PrestayLayerSummary": "See and increase the impact of your guests when they spread the word about their upcoming stay.",
            "flipto.account.platform.PoststayLayerSummary": "Check out the reach of your happy guests along with the breakdown of their engagement.",
            "flipto.account.platform.PhotoLayerSummary": "Engage your guests with beautiful perpetual storyteller campaigns, curate their creativity, and choose the winners.",
            "flipto.account.platform.HomepageLayerSummary": "Turn lookers into bookers by making your website more personable and your booking engine more compelling.",
            "flipto.account.platform.QuoteListLayerSummary": "Tweak your favorite recent quotes and how they're shown to potential future guests.",
            "flipto.account.platform.CommunicationLayerSummary": "Communicate the right message to the right travelers based on where they are in their journey.",
            "flipto.account.platform.UniqueVisitors": "unique visitors",
            "flipto.account.platform.SignUps": "sign-ups",
            "flipto.account.platform.WarmLeads": "warm leads",
            "flipto.account.platform.BookedRoomNights": "booked room nights",
            "flipto.account.platform.BookedNumbRoomNightsOn": "Booked {{totalRoomNights}} room nights on",
            "flipto.account.platform.ContestInvitee": "Joined campaign via invite on",
            "flipto.account.platform.ApprovedReviews": "approved reviews",
            "flipto.account.platform.PositiveReviews": "positive reviews",
            "flipto.account.platform.Reviews": "reviews",
            "flipto.account.platform.Advocates": "Advocates", // !!! DISCONTINUE USE. TODO: swap existing instances with flipto.account.Advocates
            "flipto.account.platform.GuestsBecameAdvocates": "guests became advocates",
            "flipto.account.platform.GuestsAndFriendsBecameAdvocates": "guests & friends became advocates",
            "flipto.account.platform.LearnMore": "Learn more", // !!! DISCONTINUE USE. TODO: swap existing instances with flipto.account.LearnMore

            "flipto.account.platform.enableLayer.pre-stay": "To add Pre-stay Advocacy to your account, please contact the friendly folks at support@flip.to",
            "flipto.account.platform.enableLayer.post-stay": "To add Post-stay Advocacy to your account, please contact the friendly folks at support@flip.to",
            "flipto.account.platform.enableLayer.photo-advocacy": "To add Story Advocacy to your account, please contact the friendly folks at support@flip.to",
            "flipto.account.platform.enableLayer.homepage": "To add the Homepage layer to your account, please contact the friendly folks at support@flip.to",
            "flipto.account.platform.enableLayer.quote-list": "To add the Quote List layer to your account, please contact the friendly folks at support@flip.to",
            "flipto.account.platform.enableLayer.group-advocacy": "To add Group Advocacy to your account, please contact the friendly folks at support@flip.to",

            "flipto.account.platform.touchpoints.UseGuestPhoto": "Use guest's profile pic if available",
            "flipto.account.platform.touchpoints.ReplaceGuestPhoto": "Use a fixed profile pic",

            "flipto.account.platform.preStay.ChangePriority": 'Change priority',
            "flipto.account.platform.preStay.Save": 'Save',
            "flipto.account.platform.preStay.NewCampaign": 'New campaign',
            "flipto.account.platform.preStay.campaign.SaveCampaign": 'Save Campaign',
            "flipto.account.platform.preStay.campaign.Cancel": 'Cancel',
            "flipto.account.platform.preStay.campaign.ShareMessage": 'Share message',
            "flipto.account.platform.preStay.campaign.Info": 'Info',
            "flipto.account.platform.preStay.campaign.Criteria": 'Criteria',
            "flipto.account.platform.preStay.campaign.Goals": 'Goals',
            "flipto.account.platform.preStay.campaign.GoalsSummary": 'Set the incentive that advocates or warm leads can earn when they reach a certain goal.',
            "flipto.account.platform.preStay.campaign.RoomType": 'Room type',
            "flipto.account.platform.preStay.campaign.DateRange": 'Date range',
            "flipto.account.platform.preStay.campaign.LengthOfStay": 'Length of stay',
            "flipto.account.platform.preStay.campaign.Languages": 'Languages',
            "flipto.account.platform.preStay.campaign.RateCode": 'Rate code',
            "flipto.account.platform.preStay.campaign.Any": 'Any',
            "flipto.account.platform.preStay.campaign.Min": 'Min',
            "flipto.account.platform.preStay.campaign.Max": 'Max',
            "flipto.account.platform.preStay.campaign.Or": 'Or',
            "flipto.account.platform.preStay.campaign.Night": 'Night',
            "flipto.account.platform.preStay.campaign.Nights": 'Nights',
            "flipto.account.platform.preStay.campaign.Guests": 'guests',
            "flipto.account.platform.preStay.campaign.MatchedThis": 'matched this',
            "flipto.account.platform.preStay.campaign.WhileItWasActive": 'while it was active',
            "flipto.account.platform.preStay.campaign.DidNotFallIntoASpecificCampaign": "didn't fall into a specific campaign",
            "flipto.account.platform.preStay.campaign.Advocates": 'advocates', // !!! DISCONTINUE USE. TODO: swap existing instances with flipto.account.Advocates
            "flipto.account.platform.preStay.campaign.AndNMore": 'and {{number}} more',
            "flipto.account.platform.preStay.campaign.MoreThan7": 'More than 7',
            "flipto.account.platform.preStay.campaign.SetUpANewCampaign": 'Set up a new campaign',
            "flipto.account.platform.preStay.campaign.OnceYouSaveThisCampaign": 'Once you save this campaign, it will be OFF by default. You must switch it ON to put it into effect.',
            "flipto.account.platform.preStay.campaign.goals.spreadTheWord": "Spread the word",
            "flipto.account.platform.preStay.campaign.goals.getSocialConnections": "Get # social connections to sign up",
            "flipto.account.platform.preStay.campaign.goals.getUniqueVisitors": "Get # unique visitors to the site",
            "flipto.account.platform.preStay.campaign.goals.descriptionIsNotAvailable": "Goal description is not available",
            "flipto.account.platform.preStay.campaign.goals.Goal": "Goal",
            "flipto.account.platform.preStay.campaign.goals.NewGoal": "New Goal",
            "flipto.account.platform.preStay.campaign.goals.AddGoal": "Add a goal",
            "flipto.account.platform.preStay.campaign.goals.ThereAreNoGoals": "There are no goals",
            "flipto.account.platform.preStay.campaign.goals.Deactivate": "Deactivate",
            "flipto.account.platform.preStay.campaign.goals.Activate": "Activate",
            "flipto.account.platform.preStay.campaign.goals.Delete": "Delete",
            "flipto.account.platform.preStay.campaign.goals.Restore": "Restore",
            "flipto.account.platform.preStay.campaign.goals.MarkedForRemoval": "MARKED TO BE DELETED",
            "flipto.account.platform.Touchpoints": "Touchpoints",
            "flipto.account.platform.Inactive": "Off",
            "flipto.account.platform.preStay.touchpoints.ConfirmationMessage": "Confirmation message",
            "flipto.account.platform.preStay.touchpoints.ConfirmationLink": "Confirmation link",
            "flipto.account.platform.preStay.touchpoints.DefaultHeader": "Default header",
            "flipto.account.platform.preStay.touchpoints.HomepageHeader": "Message that appears after Homepage signup",
            "flipto.account.platform.preStay.touchpoints.JsonSettings": "JSON settings",
            "flipto.account.platform.preStay.touchpoints.NoConfigurationNecessary": "No configuration necessary, we'll take care of everything!",
            "flipto.account.platform.preStay.touchpoints.ConfirmationPage": "Confirmation Experience",
            "flipto.account.platform.preStay.touchpoints.LandingPage": "Friend Landing Experience",
            "flipto.account.platform.preStay.touchpoints.MobileLandingPage": "Friend Landing (mobile)",
            "flipto.account.platform.preStay.touchpoints.FollowupPage": "Follow-up Email + Share Page",
            "flipto.account.platform.preStay.touchpoints.FollowupEmail": "Follow-up Email",
            "flipto.account.platform.preStay.touchpoints.SharePage": "Share Page",
            "flipto.account.platform.preStay.touchpoints.MobilePage": "Mobile Page",
            "flipto.account.platform.preStay.touchpoints.ThankYouPage": "Thank You Page",
            "flipto.account.platform.preStay.touchpoints.ShareMessage": "Share Message",
            "flipto.account.platform.preStay.touchpoints.BookingEngine": "Booking engine",
            "flipto.account.platform.preStay.touchpoints.PropertyCode": "Property code",
            "flipto.account.platform.preStay.touchpoints.FliptoAccountCode": "Flip.to account code",
            "flipto.account.platform.preStay.touchpoints.ExcludedReservationTypes": "Excluded reservation types",
            "flipto.account.platform.preStay.touchpoints.RateCodesSummary": "Certain reservation types can be excluded from being shown the Confirmation Experience based on room type, rate code or hotel code.",
            "flipto.account.platform.preStay.touchpoints.RateCodeAdd": "Add a code",
            "flipto.account.platform.preStay.touchpoints.NoRateCodes": "No reservation types are excluded.",
            "flipto.account.platform.preStay.touchpoints.IntegrationInstructions": "Integration instructions",
            "flipto.account.platform.preStay.touchpoints.Selector": "Page location",
            "flipto.account.platform.preStay.touchpoints.ConfirmationDisplayType": "App mode",
            "flipto.account.platform.preStay.touchpoints.ReopenerHeader": "Minimized View",
            "flipto.account.platform.preStay.touchpoints.MobileReopenerActive": "Minimized View (Mobile)",
            "flipto.account.platform.preStay.touchpoints.DesktopReopenerActive": "Minimized View (Desktop)",
            "flipto.account.platform.preStay.touchpoints.ParentElementSelector": "Page location",
            "flipto.account.platform.preStay.touchpoints.AddMultipleEmails": "To add multiple emails use a semicolon (;) between each entry",
            "flipto.account.platform.preStay.touchpoints.AddRecipientsEmail": "Add recipient's email",
            "flipto.account.platform.preStay.touchpoints.Add": "Add",
            "flipto.account.platform.preStay.touchpoints.Off": "Off",
            "flipto.account.platform.preStay.touchpoints.On": "Off",
            "flipto.account.platform.preStay.touchpoints.SignUp": "Sign-up",
            "flipto.account.platform.preStay.touchpoints.SignUpWithGuestQuote": "Sign-up with guest quote",
            "flipto.account.platform.preStay.touchpoints.AddCode": "Add code",
            "flipto.account.platform.preStay.touchpoints.AdvancedSettings": "Advanced settings",
            "flipto.account.platform.preStay.touchpoints.AdvancedProperties": "Advanced properties",
            "flipto.account.platform.preStay.touchpoints.AddDomSelector": "Add DOM selector",
            "flipto.account.platform.preStay.touchpoints.IntegrationInstructionsFor": "Integration instructions for",
            "flipto.account.platform.preStay.touchpoints.SendInstructions": "Send instructions",
            "flipto.account.platform.preStay.touchpoints.Thanks": "Thanks",
            "flipto.account.platform.preStay.touchpoints.ThanksInstructions": "Thanks instructions",
            "flipto.account.platform.preStay.touchpoints.RedirectMessage": "Redirect message",
            "flipto.account.platform.preStay.touchpoints.SignUpMessage": "Sign-up message",
            "flipto.account.platform.preStay.touchpoints.GuestMessage": "Guest message",
            "flipto.account.platform.preStay.touchpoints.IncentiveMessage": "Incentive message",
            "flipto.account.platform.preStay.touchpoints.RequestMessage": "Request message",
            "flipto.account.platform.preStay.touchpoints.SignUpForm": "Sign-up form",
            "flipto.account.platform.preStay.touchpoints.signupRedirectUrl": "Redirect url",
            "flipto.account.platform.preStay.touchpoints.signupRedirectUrlSummary": "After a guest's friend takes an action on the landing page, he or she can be redirected to the URL below. (Optional)",
            "flipto.account.platform.preStay.touchpoints.landingPageQuoteUrl": "Landing page url - Guest quote",
            "flipto.account.platform.preStay.touchpoints.landingPageQuoteUrlSummary": "An alternative URL can be used when a friend clicks on a link that includes a guest's positive quote.",
            "flipto.account.platform.preStay.touchpoints.landingPageUrl": "Landing page url",
            "flipto.account.platform.preStay.touchpoints.landingPageUrlSummary": "After clicking on a guest's shared link, this is the URL that they would go to.",
            "flipto.account.platform.preStay.touchpoints.TimerHeader": "Timer",
            "flipto.account.platform.preStay.touchpoints.TimerEnabled": "Show timer on intro",
            "flipto.account.platform.preStay.touchpoints.ShowIncentiveOnIntro": "Show incentive on intro",
            "flipto.account.platform.preStay.touchpoints.IncentiveHeader": "Incentive",
            "flipto.account.platform.preStay.touchpoints.AfterTimer": "Page redirect after timer expires",
            "flipto.account.platform.postStay.touchpoints.IntroMessage": "Intro message",
            "flipto.account.platform.postStay.touchpoints.RatingMessage": "Rating message",
            "flipto.account.platform.postStay.touchpoints.GuideMessage": "Guide message",
            "flipto.account.platform.postStay.touchpoints.QuoteMessage": "Quote message",
            "flipto.account.platform.postStay.touchpoints.FeedbackMessage": "Feedback message",
            "flipto.account.platform.postStay.touchpoints.ShareMessage": "Share message",
            "flipto.account.platform.postStay.touchpoints.ShareQuote": "Share quote",
            "flipto.account.platform.postStay.touchpoints.ShareContent": "Share content",
            "flipto.account.platform.postStay.touchpoints.ExternalReviewMessage": "External review message",
            "flipto.account.platform.postStay.touchpoints.ExternalReviewUrl": "External review URL",
            //"flipto.account.platform.postStay.touchpoints.WriteReview": "Write a review",
            "flipto.account.platform.postStay.touchpoints.RequestMessage": "Request message",
            "flipto.account.platform.postStay.touchpoints.ThanksMessage": "Thanks message",

            "flipto.account.platform.postStay.touchpoints.PostStayProcess": "Post-stay Process",
            "flipto.account.platform.postStay.touchpoints.Rating": "Rating",
            "flipto.account.platform.postStay.touchpoints.Quote": "Quote",
            "flipto.account.platform.postStay.touchpoints.FeedbackLowRating": "Feedback (low rating)",
            "flipto.account.platform.postStay.touchpoints.Share": "Share",
            "flipto.account.platform.postStay.touchpoints.Thanks": "Thanks",
            "flipto.account.platform.postStay.touchpoints.ThanksLowRating": "Thanks (low rating)",
            "flipto.account.platform.postStay.touchpoints.IfGuestGaveALowRating": "If guests gave a low rating",
            "flipto.account.platform.postStay.touchpoints.GiveUsYourRating": "Give us your rating",
            "flipto.account.platform.postStay.touchpoints.InYourOwnWords": "In your own words",
            "flipto.account.platform.postStay.touchpoints.ShareYourQuote": "Share your quote",
            "flipto.account.platform.postStay.touchpoints.ThanksForYourTime": "Thanks for your time!",
            "flipto.account.platform.postStay.touchpoints.Integration.Selector": "Selector",
            "flipto.account.platform.postStay.touchpoints.Integration.Configuration": "Configuration",
            "flipto.account.platform.postStay.touchpoints.Integration.Mode": "Mode",
            "flipto.account.platform.postStay.touchpoints.Integration.IsLowRatingExternalReview": "External review for low rating",
            "flipto.account.platform.postStay.touchpoints.Integration.IsNewQuoteProcess": "New quote process",
            "flipto.account.platform.postStay.touchpoints.Integration.IsEnablePhotos": "Enable photos",
            "flipto.account.platform.postStay.touchpoints.Integration.HostedFinalPage": "Hosted – final page",
            "flipto.account.platform.postStay.touchpoints.Integration.HostedFirstPage": "Hosted – first page",
            "flipto.account.platform.postStay.touchpoints.Integration.AfterCheckOut": "After check-out",
            "flipto.account.platform.postStay.touchpoints.Integration.AfterExternalRating": "After external rating",
            "flipto.account.platform.postStay.touchpoints.Integration.QuoteOnly": "Quote only",
            "flipto.account.platform.postStay.touchpoints.Integration.PhotoQuote": "Photo & quote",
            "flipto.account.platform.postStay.touchpoints.Integration.GamefulExperience": "Gameful experience",
            "flipto.account.platform.postStay.touchpoints.Integration.PostStayVersion": "Post-stay version",
            "flipto.account.platform.homepage.touchpoints.Homepage": "Homepage Component",
            "flipto.account.platform.homepage.touchpoints.AdvancedProperties": "Advanced properties",
            "flipto.account.platform.homepage.touchpoints.EmailPage": "Redeem Email",
            "flipto.account.platform.homepage.touchpoints.PredefinedQuote": "Predefined Quote",
            "flipto.account.platform.homepage.touchpoints.WebSite": "WebSite",
            "flipto.account.platform.homepage.touchpoints.QuoteType": "Quote type",
            "flipto.account.platform.homepage.touchpoints.QuoteTypeSummary": "Showcase real guest quotes that have been curated and approved, or create your own fixed quote to display.",
            "flipto.account.platform.homepage.touchpoints.UnlockPanel": "Unlock panel",
            "flipto.account.platform.homepage.touchpoints.UnlockDialog": "Unlock dialog",
            "flipto.account.platform.homepage.touchpoints.UnlockDialogMode": "Mode",
            "flipto.account.platform.homepage.touchpoints.UnlockMessage": "Unlock message",
            "flipto.account.platform.homepage.touchpoints.FixedQuoteProfileImage": "Fixed quote profile image",
            "flipto.account.platform.homepage.touchpoints.FixedQuote": "Fixed quote",
            "flipto.account.platform.homepage.touchpoints.FixedQuoteName": "Fixed quote guest name",
            "flipto.account.platform.homepage.touchpoints.GuestQuote": "Guest quote",
            "flipto.account.platform.homepage.touchpoints.ProfileImage": "Profile image",
            "flipto.account.platform.homepage.touchpoints.AddImage": "Add image",
            "flipto.account.platform.homepage.touchpoints.SignUpPanel": "Sign-up panel",
            "flipto.account.platform.homepage.touchpoints.FirstName": "First name",
            "flipto.account.platform.homepage.touchpoints.LastName": "Last name",
            "flipto.account.platform.homepage.touchpoints.BookNowLink": "Book now link",
            "flipto.account.platform.homepage.touchpoints.BookNowMessage": "Book Now message",
            "flipto.account.platform.homepage.touchpoints.SignupMessage": "Signup message",
            "flipto.account.platform.homepage.touchpoints.TouchpointIntegration": "Touchpoint integration",
            "flipto.account.platform.homepage.touchpoints.HomepageComponentCode": "Homepage Component code",
            "flipto.account.platform.homepage.touchpoints.Json": "JSON",
            "flipto.account.platform.homepage.touchpoints.FliptoAccountCode": "Flip.to account code",
            "flipto.account.platform.homepage.touchpoints.totalViews": "Total Views",
            "flipto.account.platform.homepage.touchpoints.totalOpens": "Total Opens",
            "flipto.account.platform.homepage.touchpoints.totalBookClicks": "Total Book Clicks",
            "flipto.account.platform.homepage.touchpoints.CssForFlipToContent": "CSS for Flip.to content",
            "flipto.account.platform.homepage.touchpoints.CssForPageContent": "CSS for page content",
            "flipto.account.platform.homepage.touchpoints.CssForSignUpDialog": "CSS for sign-up dialog",

            "flipto.account.platform.quoteList.touchpoints.List": "Quotes List",
            "flipto.account.platform.quoteList.touchpoints.NumberOfQuotesToFeature": "Number of quotes to feature",
            "flipto.account.platform.quoteList.touchpoints.QuoteCharacterLimit": "Quote character limit",
            "flipto.account.platform.quoteList.touchpoints.TouchpointCode": "Touchpoint code",
            "flipto.account.platform.quoteList.touchpoints.Json": "JSON",
            "flipto.account.platform.quoteList.touchpoints.FliptoAccountCode": "Flip.to account code",

            "flipto.account.platform.layer.bookingEngine.Other.Hints": "Booking Engine hints will be here!",
            "flipto.account.platform.Deactivate": "Deactivate",
            "flipto.account.platform.Activate": "Activate",
            "flipto.account.platform.touchpoint.ChangeStatusMessage": "Activate/Deactivate touchpoint",
            "flipto.account.platform.Subject": "Subject",
            "flipto.account.platform.Body": "Body",
            "flipto.account.platform.Footer": "Footer",
            "flipto.account.platform.Inbox": "Inbox",
            "flipto.account.platform.EditYourContent": "Edit your content",
            "flipto.account.platform.RedeemMessage": "Redeem Message",


            "flipto.account.platform.GroupAdvocacy": "Group Advocacy",
            "flipto.account.platform.GroupAdvocacyLayerSummary": "Get your group travelers buzzing about their event and their stay.",
            "flipto.account.platform.groupAdvocacy.NewCampaign": 'New group campaign',
            "flipto.account.platform.groupAdvocacy.CurrentPastCampaigns": 'Current & past groups',

            "flipto.account.platform.groupAdvocacy.SetUpANewCampaign": "Set up a new campaign",
            "flipto.account.platform.groupAdvocacy.OnceYouSaveThisCampaign": "Once you save this campaign, it will be OFF by default. You must switch it ON to put it into effect.",
            "flipto.account.platform.groupAdvocacy.Cancel": "Cancel",
            "flipto.account.platform.groupAdvocacy.SaveCampaign": "Save Campaign",
            "flipto.account.platform.groupAdvocacy.Info": "Info",
            "flipto.account.platform.groupAdvocacy.GroupName": "Group name",
            "flipto.account.platform.groupAdvocacy.DisplayName": "Display name",
            "flipto.account.platform.groupAdvocacy.DateRange": "Date range",
            "flipto.account.platform.groupAdvocacy.Configuration": "Configuration",
            "flipto.account.platform.groupAdvocacy.EventId": "Event ID",
            "flipto.account.platform.groupAdvocacy.EventUrl": "Event URL",
            "flipto.account.platform.groupAdvocacy.ShareMessage": "Share Message",
            "flipto.account.platform.groupAdvocacy.AddMessageVariation": "Add message variation",
            "flipto.account.platform.groupAdvocacy.Goals": "Goals",
            "flipto.account.platform.groupAdvocacy.GoalsSummary": "Set the incentive that advocates or warm leads can earn when they reach a certain goal.",

            "flipto.account.platform.GroupAdvocacy.groups.NoGroups": "There are no groups",
            "flipto.account.platform.GroupAdvocacy.groups.MatchedGuests": "guests" /* Acceptance criteria says this should equal "guests" not "matched guests" as per sketch */
        });

        $translateProvider.translations('en', {
            "flipto.account.platform.photoAdvocacy.campaign.GoalsSummary": 'Set the incentive that visitors can earn when they reach a certain goal.',

            'flipto.account.sections.platform.photoAdvocacy.Contests': 'Campaigns',
            'flipto.account.sections.platform.photoAdvocacy.Contest': 'Campaign',
            'flipto.account.platform.photoAdvocacy.ThemedContest': 'Themed Campaign',
            'flipto.account.platform.photoAdvocacy.ContestTheme': 'Contest Theme',
            'flipto.account.platform.photoAdvocacy.ContestThemeDescription': 'Create a contest based on the type of audience you want to invite',
            'flipto.account.sections.platform.photoAdvocacy.Touchpoints': 'Touchpoints',
            'flipto.account.platform.photoAdvocacy.TotalFinalists': 'Total storytellers',
            'flipto.account.platform.photoAdvocacy.TotalPhotos': 'Total photos',
            'flipto.account.platform.photoAdvocacy.TotalReviews': 'Total reviews',
            'flipto.account.platform.photoAdvocacy.FirstPlaceWins': 'First place wins',
            'flipto.account.platform.photoAdvocacy.PlusMorePrizesFor': 'Plus more prizes for',
            'flipto.account.platform.photoAdvocacy.FirstPlace': '1st place',
            'flipto.account.platform.photoAdvocacy.SecondPlace': '2nd place',
            'flipto.account.platform.photoAdvocacy.ThirdPlace': '3rd place',
            'flipto.account.platform.photoAdvocacy.OtherGoals': 'other goals',
            'flipto.account.platform.photoAdvocacy.OtherGoal': 'other goal',
            'flipto.account.sections.platform.photoAdvocacy.Change': "Change",
            'flipto.account.sections.platform.photoAdvocacy.PrizesTerms': "Prizes",
            'flipto.account.sections.platform.photoAdvocacy.ReleaseTerms': "Release Terms",
            'flipto.account.sections.platform.photoAdvocacy.TermsAndConditions': "Terms & Conditions",
            'flipto.account.sections.platform.photoAdvocacy.TermsAndConditionsForCampaigns': "Terms and Conditions for Storyteller Campaigns",
            'flipto.account.sections.platform.photoAdvocacy.PhotoReleaseForCampaigns': "Photo Release for Storyteller Campaigns",
            'flipto.account.sections.platform.photoAdvocacy.ConsentForStorySubmission': "Consent for story submission",
            'flipto.account.sections.platform.photoAdvocacy.ConsentForPhoneNumber': "Consent for phone number",
            'flipto.account.sections.platform.photoAdvocacy.ConsentTerms': "Consent Terms",
            'flipto.account.sections.platform.photoAdvocacy.ContestPage': 'Campaign Page',
            'flipto.account.sections.platform.photoAdvocacy.FinalistsEmail': 'Finalists Email',
            'flipto.account.sections.platform.photoAdvocacy.ReminderEmail': 'Reminder Email',
            'flipto.account.sections.platform.photoAdvocacy.ActivityEmail': 'Activity Email',
            'flipto.account.sections.platform.photoAdvocacy.WinnerEmail': 'Winner Email',
            'flipto.account.sections.platform.photoAdvocacy.NewContest': 'New Campaign',
            'flipto.account.sections.platform.photoAdvocacy.ContestName': 'Campaign name',
            'flipto.account.sections.platform.photoAdvocacy.CloneFromContestDescription': 'Import settings from a previous campaign',
            'flipto.account.sections.platform.photoAdvocacy.DateRange': 'Date range',
            'flipto.account.sections.platform.photoAdvocacy.DateRangeDescription': 'Set the start and end date for this campaign. Voting will be extended up to 7 days following the end date to allow late entries a chance to share and rally votes.',
            'flipto.account.sections.platform.photoAdvocacy.LivePreviewUrl': 'Live Preview URL',
            'flipto.account.sections.platform.photoAdvocacy.InvitationPageURL': 'Invitation Page URL',
            'flipto.account.sections.platform.photoAdvocacy.SetUpDomain': 'Set up a domain to enable invitation page URLs',

            'flipto.account.sections.platform.photoAdvocacy.FinalistRankingDisplaySummary': 'During the course of the campaign, finalists see their scores along with their status, which can be displayed as their current place (i.e. 3rd place) or their current bracket (i.e. Top 5).',
            'flipto.account.sections.platform.photoAdvocacy.FinalistRankingDisplay': 'Finalist ranking display',
            'flipto.account.sections.platform.photoAdvocacy.FinalistRankingType.Exact': 'Display current place (1st place, 2nd place, etc.)',
            'flipto.account.sections.platform.photoAdvocacy.FinalistRankingType.Braket': 'Display current bracket (Top 5, Top 10, etc.)',
            'flipto.account.sections.platform.photoAdvocacy.IsExactPlaceRankingDescription': 'If turned on - visitors will see exact place in photo contest, otherwise rank bracket',
            'flipto.account.sections.platform.photoAdvocacy.AutoInviteGuests': 'Auto-invite guests',
            'flipto.account.sections.platform.photoAdvocacy.AutoInviteGuestsDescription': 'If you would like to automatically invite guests whose stay dates match the campaign date range, set that below. These guests will have a chance to join the campaign during their Post-stay Process.',
            'flipto.account.sections.platform.photoAdvocacy.AutoInviteGuestsLong': 'Auto-invite guests who stay during the campaign date range',
            'flipto.account.sections.platform.photoAdvocacy.AutoInviteSubText': 'Guests will have a chance to join the campaign during their Post-stay Process. Go to the INVITATIONS tab for more ways to reach travelers.',
            'flipto.account.sections.platform.photoAdvocacy.PrivateContest': 'Private Campaign',
            'flipto.account.sections.platform.photoAdvocacy.PrivateContestDescription': 'Hide the campaign from the public while leaving it available for participants and marketing',
            'flipto.account.sections.platform.photoAdvocacy.InvitationGreetingMessage': 'Greeting message',
            'flipto.account.sections.platform.photoAdvocacy.RequestQuotes': 'Request quotes',
            'flipto.account.sections.platform.photoAdvocacy.RequestQuotesDescription': "We like to ask audiences for quotes that might be useful for marketing. Sometimes it might not be right to ask for this content, in that case you can turn it OFF here.",
            'flipto.account.sections.platform.photoAdvocacy.InvitationQuoteRequestMessage': 'Quote message',
            'flipto.account.sections.platform.photoAdvocacy.ContestStyling': 'Campaign-specific styling',
            'flipto.account.sections.platform.photoAdvocacy.ContestStylingSummary': 'Customize the appearance of your Invitation Page as well as the submission process for guests and invitees.',


            'flipto.account.sections.platform.photoAdvocacy.FinalistMessage': 'Finalist message',
            'flipto.account.sections.platform.photoAdvocacy.FriendMessage': 'Friend message',
            'flipto.account.sections.platform.photoAdvocacy.ContestEndsIn': 'Contest ends in',
            'flipto.account.sections.platform.photoAdvocacy.VotingEnds': 'Voting ends',
            'flipto.account.sections.platform.photoAdvocacy.SubmissionsReviewed': 'submissions need review',
            'flipto.account.sections.platform.photoAdvocacy.EndsIn': 'Ends in',
            'flipto.account.sections.platform.photoAdvocacy.ContestHasEnded': 'Contest has ended',
            'flipto.account.sections.platform.photoAdvocacy.ChooseYourWinners': 'Choose your winners!',
            'flipto.account.sections.platform.photoAdvocacy.WinnersAnnounced': 'Winners announced',
            'flipto.account.sections.platform.photoAdvocacy.Days': 'days',
            'flipto.account.sections.platform.photoAdvocacy.Submissions': 'submissions',
            'flipto.account.sections.platform.photoAdvocacy.Submission': 'submission',
            'flipto.account.sections.platform.photoAdvocacy.Finalists': 'storytellers',
            'flipto.account.sections.platform.photoAdvocacy.New': 'New',
            'flipto.account.sections.platform.photoAdvocacy.Invitees': 'invitees',
            'flipto.account.sections.platform.photoAdvocacy.Invitee': 'invitee',
            'flipto.account.sections.platform.photoAdvocacy.Guests': 'guests',
            'flipto.account.sections.platform.photoAdvocacy.Guest': 'guest',
            'flipto.account.sections.platform.photoAdvocacy.FromInvitees': 'from invitees',
            'flipto.account.sections.platform.photoAdvocacy.FromGuests': 'from guests',
            'flipto.account.sections.platform.photoAdvocacy.Curated': 'Curated',
            'flipto.account.sections.platform.photoAdvocacy.SelectWinners': 'Select winners',
            'flipto.account.sections.platform.photoAdvocacy.ReviewFinalists': 'Review your storytellers',
            'flipto.account.sections.platform.photoAdvocacy.TotalPoints': 'total points',
            'flipto.account.sections.platform.photoAdvocacy.ContestDetails': 'Campaign details',
            'flipto.account.sections.platform.photoAdvocacy.InvitationDetails': 'Invitation Details',
            'flipto.account.sections.platform.photoAdvocacy.ShareMessage': 'Share Message',
            'flipto.account.sections.platform.photoAdvocacy.Friend': 'Friend',
            'flipto.account.sections.platform.photoAdvocacy.PrizeMessage': 'Prize Message',
            'flipto.account.sections.platform.photoAdvocacy.FirstPrize': 'First Prize',
            'flipto.account.sections.platform.photoAdvocacy.OtherPrizes': 'Other Prizes',
            'flipto.account.sections.platform.photoAdvocacy.Goals': 'Goals',
            'flipto.account.sections.platform.photoAdvocacy.place.First': '1ST',
            'flipto.account.sections.platform.photoAdvocacy.place.Second': '2ND',
            'flipto.account.sections.platform.photoAdvocacy.place.Third': '3RD',
            'flipto.account.sections.platform.photoAdvocacy.1STPlace': '1ST Place',
            'flipto.account.sections.platform.photoAdvocacy.2NDPlace': '2ND Place',
            'flipto.account.sections.platform.photoAdvocacy.3RDPlace': '3RD Place',
            'flipto.account.sections.platform.photoAdvocacy.1stPlace': '1st Place',
            'flipto.account.sections.platform.photoAdvocacy.2ndPlace': '2nd Place',
            'flipto.account.sections.platform.photoAdvocacy.3rdPlace': '3rd Place',
            'flipto.account.sections.platform.photoAdvocacy.FirstPlace': 'First Place',
            'flipto.account.sections.platform.photoAdvocacy.SecondPlace': 'Second Place',
            'flipto.account.sections.platform.photoAdvocacy.ThirdPlace': 'Third Place',
            'flipto.account.sections.platform.photoAdvocacy.Participant': 'Participant',
            'flipto.account.sections.platform.photoAdvocacy.RunnerUp': 'Runner-up',
            'flipto.account.sections.platform.photoAdvocacy.RunnerUps': 'Runners-up',
            'flipto.account.sections.platform.photoAdvocacy.UpcomingPastContests': 'Upcoming & past contests',
            'flipto.account.sections.platform.photoAdvocacy.PropertyCode': 'Property Code',
            'flipto.account.sections.platform.photoAdvocacy.EditYourContent': 'Edit your content',
            'flipto.account.sections.platform.photoAdvocacy.StyleYourContent': 'Style your content',
            'flipto.account.sections.platform.photoAdvocacy.LandingPageUrl': 'Landing page url',
            'flipto.account.sections.platform.photoAdvocacy.Curation': 'Curation',
            'flipto.account.sections.platform.photoAdvocacy.Caption': 'Caption',
            'flipto.account.sections.platform.photoAdvocacy.Location': 'Location',
            'flipto.account.sections.platform.photoAdvocacy.PhotoDate': 'Photo date',
            'flipto.account.sections.platform.photoAdvocacy.Library': 'Library',
            'flipto.account.sections.platform.photoAdvocacy.Passed': 'Passed',
            'flipto.account.sections.platform.photoAdvocacy.PreviewPost': 'Preview Post',
            'flipto.account.sections.platform.photoAdvocacy.NotAFinalist': 'Not a finalist',
            'flipto.account.sections.platform.photoAdvocacy.Finalist': 'Finalist',
            'flipto.account.sections.platform.photoAdvocacy.Quote': 'Quote',
            'flipto.account.sections.platform.photoAdvocacy.LatestEntries': 'Latest entries',
            'flipto.account.sections.platform.photoAdvocacy.FriendVotes': 'Friend votes',
            'flipto.account.sections.platform.photoAdvocacy.SignUps': 'Sign-ups',
            'flipto.account.sections.platform.photoAdvocacy.FriendShares': 'Friend shares',
            'flipto.account.sections.platform.photoAdvocacy.PhotoContest': 'Storyteller campaign',
            'flipto.account.sections.platform.photoAdvocacy.BookedRoomNightsOn': 'Booked {{totalRoomNights}} room nights on {{startDate}}',
            'flipto.account.sections.platform.photoAdvocacy.IfAdvancedReceiveEmail': 'If advanced, {{first}} will receive an email notification that this story is a finalist and to spread the word to friends & family.',
            'flipto.account.sections.platform.photoAdvocacy.ThisPhotoWasAdvanced': 'This story was advanced to the campaign. An email notification was sent to {{first}}.',
            'flipto.account.sections.platform.photoAdvocacy.ThisPhotoWasPassed': 'This story was not advanced to the campaign.',
            'flipto.account.sections.platform.photoAdvocacy.RateTheGuestsContent': 'Rate the content',
            'flipto.account.sections.platform.photoAdvocacy.PhotoQuote': 'Photo & quote',
            'flipto.account.sections.platform.photoAdvocacy.AdvanceToContest': 'Advance to campaign?',
            'flipto.account.sections.platform.photoAdvocacy.NotReviewed': 'Not reviewed',
            'flipto.account.sections.platform.photoAdvocacy.ThisPhotoWasNotPartOfTheContest': 'No decision was made on this story before the campaign ended.',
            'flipto.account.sections.platform.photoAdvocacy.Photo': 'Photo',
            'flipto.account.sections.platform.photoAdvocacy.MachineVision': 'Machine Vision',
            'flipto.account.sections.platform.photoAdvocacy.Tags': 'Tags',
            'flipto.account.sections.platform.photoAdvocacy.Categories': 'Categories',
            'flipto.account.sections.platform.photoAdvocacy.Format': 'Format',
            'flipto.account.sections.platform.photoAdvocacy.Dimensions': 'Dimensions',
            'flipto.account.sections.platform.photoAdvocacy.Colors': 'Colors',
            'flipto.account.sections.platform.photoAdvocacy.VisionAi': 'Vision AI',
            'flipto.account.sections.platform.photoAdvocacy.FacesAi': 'Faces AI',
            'flipto.account.sections.platform.photoAdvocacy.Person': 'Person',
            'flipto.account.sections.platform.photoAdvocacy.Age': 'Age',
            'flipto.account.sections.platform.photoAdvocacy.Gender': 'Gender',
            'flipto.account.sections.platform.photoAdvocacy.NoAiDataAvailable': "This image has not been processed yet.",
            'flipto.account.sections.platform.photoAdvocacy.NoFacesDetected': "No faces detected",
            'flipto.account.sections.platform.photoAdvocacy.OneFaceDetected': "1 face detected",
            'flipto.account.sections.platform.photoAdvocacy.MultipleFacesDetected': "faces detected",
            "flipto.account.sections.platform.photoAdvocacy.OpenLinksInNewWindow": "Open in new window",
            "flipto.account.sections.platform.photoAdvocacy.OpenLinksInNewWindowMobile": "Open in new window (Mobile)",
            "flipto.account.sections.platform.photoAdvocacy.OpenLinksInNewWindowDesktop": "Open in new window (Desktop)",
            "flipto.account.sections.platform.photoAdvocacy.PageLocationModalSummary": "Enter the CSS selector where you want the component to appear.",
            "flipto.account.sections.platform.photoAdvocacy.InsertionType": "Insertion Type",
            "flipto.account.sections.platform.photoAdvocacy.ExternalLinks": "External links",
            "flipto.account.sections.platform.photoAdvocacy.ExternalLinksPanelSummary": "Desktop only. Mobile always opens in same window.",
            "flipto.account.sections.platform.photoAdvocacy.ExternalLinksModalSummary": "Set the behavior for links on desktop. Links on mobile devices will aways open in the same window.",
            "flipto.account.sections.platform.photoAdvocacy.ViewTimeline": "View {{first}}'s timeline",
            "flipto.account.ShowInfo": "Show {{first}}'s info",
            "flipto.account.sections.platform.photoAdvocacy.CleanUpContestCheaters": "Anti-cheat tool",
            "flipto.account.sections.platform.photoAdvocacy.AntiCheatWarning": "Automatically scan and remove all votes who are obviously cheating (we’ll look for repeat IP and device patterns). Any storytellers who are caught cheating will have their scores normalized to count real votes only. This process cannot be undone and will permanently reduce a cheater’s score.<br>The tool scans from the beginning of the campaign to today. You can optionally scan from a specific start date below.",
            "flipto.account.sections.platform.photoAdvocacy.CutoffDateHeading": "(Optionally) Search only after this date:",
            "flipto.account.sections.platform.photoAdvocacy.AntiCheat": "ANTI-CHEAT",

            "flipto.account.sections.platform.team.newUser": "New team member",

            'flipto.account.PhotoWasAWinner': 'This story was a winner. An email notification was sent to {{first}}',

            'flipto.account.sections.platform.WebsiteUrl': 'Website URL',
            'flipto.account.sections.platform.Accounts': 'Accounts',
            'flipto.account.sections.platform.ExistingProperty': 'Existing property',
            'flipto.account.sections.platform.NewProperty': 'New property',
            'flipto.account.sections.platform.NewAccount': 'New account',
            'flipto.account.sections.platform.Properties': 'Properties',
            'flipto.account.sections.platform.SelectOneOrMoreProperties': 'Select one or more properties',
            'flipto.account.sections.platform.Selected': 'Selected',
            'flipto.account.sections.platform.Collections': 'Collections',
            'flipto.account.sections.platform.NewCollection': 'New Collection',
            'flipto.account.sections.platform.EditCollectionInstructions': 'Select properties and edit the name for this collection',
            'flipto.account.sections.platform.Groups': 'Groups'
        });

        $translateProvider.translations('en', {
            'flipto.components.common.pendingChanges.ConfirmYourChanges': 'Confirm your changes.',
            'flipto.components.common.pendingChanges.ChangesPending': 'You have {{total}} changes pending. If everything looks good, save & publish to apply all changes.',
            'flipto.components.common.pendingChanges.SaveAndPublish': 'Save & publish',
            'flipto.components.common.pendingChanges.CancelAll': 'Cancel all',
            'flipto.components.common.dateRange.SetDate': 'Set date'
        });

        $translateProvider.translations("en", {

            'flipto.components.notifications.weekly_short': "Weekly Activity",
            'flipto.components.notifications.daily_short': "Daily Advocate Content",
            'flipto.components.notifications.daily_photo_short': "Daily Advocate Content & photos",
            'flipto.components.notifications.daily_feedback_short': "Daily Advocate Content & photos",
            'flipto.components.notifications.guest_short': "Guest Arrivals",
            'flipto.components.notifications.future_guest_short': "Future Guest Arrivals",
            'flipto.components.notifications.recommendation_notification_short': "Recommendations",

            'flipto.components.notifications.weekly': "Weekly activity",
            'flipto.components.notifications.daily': "Daily post-stay quotes",
            'flipto.components.notifications.daily_photo': "Daily post-stay update",
            'flipto.components.notifications.daily_feedback': "Daily feedback summary",
            'flipto.components.notifications.guest': "Guests arriving today",
            'flipto.components.notifications.future_guest': "Guests arriving later",
            'flipto.components.notifications.recommendation_notification': "Recommendations",
            'flipto.components.notifications.PhotoContestEnded': "Storyteller Winners Selection",
            'flipto.components.notifications.PhotoContestWinnersAnnounced': "Storyteller Winners Podium",

            'flipto.components.notifications.weekly_description': "Receive a summary of the guest activity and a list of the top advocates with the most social connections for the pas week",
            'flipto.components.notifications.daily_description': "Stay on top of guests post-stay quotes with a daily update",
            'flipto.components.notifications.daily_photo_description': "Stay on top of guests post-stay quotes, photos with a daily update",
            'flipto.components.notifications.daily_feedback_description': "Stay on top of guests feedback with a daily update",
            'flipto.components.notifications.guest_description': "A list of guest arriving today with a Flip.to certificate",
            'flipto.components.notifications.future_guest_description': "A list of guests arriving in a set number of days with a Flip.to certificate",

            "flipto.components.notifications.GuestsArrivingTodayDescription": "A list of guests arriving today with a Flip.to certificate",
            "flipto.components.notifications.GuestsArrivingLaterDescription": "A list of guests arriving soon with a Flip.to certificate.",
            "flipto.components.notifications.WeeklyActivityDescription": "Includes a summary of guest activity and highlights the top advocates with the most social connections.",
            "flipto.components.notifications.DailyPoststayQuotesDescription": "The latest quotes and feedback from guests.",
            "flipto.components.notifications.DailyPoststayUpdateDescription": "The latest quotes & photos from guests.",
            "flipto.components.notifications.DailyFeedbackUpdateDescription": "Recent feedback from guests.",
            "flipto.components.notifications.RecommendationNotificationDescription": "Suggestions, alerts and other recommendations from the Flip.to team.",
            "flipto.components.notifications.PhotoContestEndedDescription": "Reminder to choose winners when a storyteller campaign has ended.",
            "flipto.components.notifications.PhotoContestWinnersAnnouncedDescription": "A snapshot of the winners and the prizes they earned"
        });

        $translateProvider.translations("en", {

            'flipto.components.incentives.general': "Redeem on arrival",
            'flipto.components.incentives.online': "Redeem online",
            'flipto.components.incentives.stash': "Stash",
            'flipto.components.incentives.IncentiveDescriptionIsNotAvailable': "Incentive description is not available",
            'flipto.components.incentives.ProvideStashId': "You'll need to provide a Stash ID to add Stash-based incentives. You can set the ID in the Branding tab in Settings"


        });
        $translateProvider.translations("en", {

            'flipto.components.campaign.AllOtherGuests': "All other guests",
            'flipto.components.campaign.AllVisitors': "All visitors",
            'flipto.components.campaign.AdvocatesEarn': "Advocates earn",
            'flipto.components.campaign.SocialConnectionsEarn': "Social connections earn",
            'flipto.components.campaign.OtherGoal': "Other goal",
            'flipto.components.campaign.Off': "Off"
        });

        $translateProvider.translations("en", {
            "flipto.components.goals.Advocates": "Advocates", // !!! DISCONTINUE USE. TODO: swap existing instances with flipto.account.Advocates
            "flipto.components.goals.AdvocatesSummary": "Set the incentives for your advocates.",
            "flipto.components.goals.NoGoalsHaveBeenSet": "No goals have been set.",
            "flipto.components.goals.NoGoalsHaveBeenSetFor": "",
            "flipto.components.goals.SocialConnections": "social connections", // !!! DISCONTINUE USE. TODO: swap existing instances with flipto.account.SocialConnections
            "flipto.components.goals.Visitors": "visitors", // !!! DISCONTINUE USE. TODO: swap existing instances with flipto.account.Visitors
            "flipto.components.goals.Attendees": "attendees",
            "flipto.components.goals.SocialConnectionsSummary": "Set the incentives for friends of advocates who sign up.",
            "flipto.components.goals.SignupAndGet": "Sign up and get",
            "flipto.components.goals.AttendeeSignup": "Sign up and get",
            "flipto.components.goals.ShareAndGet": "Share and get",
            "flipto.components.goals.SubmitQuoteAndGet": "Submit quote and get",
            "flipto.components.goals.SubmitPhotoAndGet": "Submit photo and get",
            "flipto.components.goals.PhotoContestWinner": "Story contest winner",
            "flipto.components.goals.SpreadTheWord": "Spread the word",
            "flipto.components.goals.GetSocialConnections": "Get # social connections to sign up",
            "flipto.components.goals.GetUniqueVisitors": "Get # unique visitors to the site",
            "flipto.components.goals.FirstPlace": "First Place",
            "flipto.components.goals.SecondPlace": "Second Place",
            "flipto.components.goals.ThirdPlace": "Third Place",
            "flipto.components.goals.RunnerUps": "Runners-up",
            "flipto.components.goals.descriptionIsNotAvailable": "Goal description is not available",
            "flipto.components.goals.Goal": "Goal",
            "flipto.components.goals.NewGoal": "New Goal",
            "flipto.components.goals.AddGoal": "Add a goal",
            "flipto.components.goals.TurnOff": "Turn off",
            "flipto.components.goals.TurnOn": "Turn on",
            "flipto.components.goals.Delete": "Delete",
            "flipto.components.goals.Restore": "Restore",
            "flipto.components.goals.MarkedForRemoval": "MARKED TO BE DELETED",
            "flipto.components.goals.GoalIncentiveIsNotDefined": "Goal's incentive is not defined",
            "flipto.components.goals.IncentiveDescriptionIsNotAvailable": "Incentive description is not available",
            "flipto.components.goals.Participation": "Participation",
            "flipto.components.goals.Sharing": "Sharing",
            "flipto.components.goals.Friends": "Friends", // !!! DISCONTINUE USE. TODO: swap existing instances with flipto.account.Friends
            "flipto.components.goals.GoalsFor": "Goals for",
            "flipto.components.goals.GoalsForFriends": "Goals for friends"

        });

        $translateProvider.translations("en", {
            "flipto.account.sections.admin.accounts.AccountName": "Account name",
            "flipto.account.sections.admin.accounts.AccountUrl": "Account URL"
        });

        $translateProvider.translations("en", {
            "flipto.account.people.details.EditDetails": "Edit guest info",
            "flipto.account.people.details.RemoveDetails": "Delete reservation",
            "flipto.account.people.details.RemoveGuestDetails": "Delete reservation?",
            "flipto.account.people.details.FirstNamePlaceholder": "First Name",
            "flipto.account.people.details.LastNamePlaceholder": "Last Name",
            "flipto.account.people.details.GuestNameHeading": "Guest Name",
            "flipto.account.people.details.ProfileImageHeading": "Profile Image",
            "flipto.account.people.details.EmailHeading": "Email",
            "flipto.account.people.details.EmailPlaceholder": "Email",
            "flipto.account.people.details.StayDateHeading": "Stay Dates",
            "flipto.account.people.details.RemoveImage": "Remove image",
            "flipto.account.people.details.RemoveDescription": "This will permanently remove all information for this guest reservation." + "\n" + "Caution: this action cannot be undone.",
            "flipto.account.people.details.ReasonHeading": "Reason",
            "flipto.account.people.details.SelectAReason": "Select a reason",
            "flipto.account.people.details.ImageWillBeRemoved": "Image will be removed on save.",

            'flipto.account.advocateActivity.prestay.share': 'Pre-stay Advocacy',
            'flipto.account.advocateActivity.quote': 'Post-stay Advocacy',
            'flipto.account.advocateActivity.photo.upload': 'Post-stay Advocacy',
            'flipto.account.advocateActivity.photo.finalist': 'Storyteller Campaign',
            'flipto.account.advocateActivity.photo.vote': 'Storyteller Campaign',
            'flipto.account.advocateActivity.photo.share': 'Storyteller Campaign'

        });

        $translateProvider.translations("en", {
            "flipto.account.settings.Inactive": "Inactive",
            "flipto.account.settings.overview.Notifications": "Notifications",
            "flipto.account.settings.overview.Languages": "Languages",
            "flipto.account.settings.overview.Incentives": "Incentives",
            "flipto.account.settings.overview.Branding": "Branding",
            "flipto.account.settings.overview.Demos": "Demos",
            "flipto.account.settings.notifications.AddRecipientsEmail": "Add recipient's email",
            "flipto.account.settings.notifications.AddMultipleEmails": "To add multiple emails use a semicolon (;) between each entry",
            "flipto.account.settings.notifications.SaveModalDescription": "Click the + button to add an email to the notifications list. Click save when you are done.",
            "flipto.account.settings.notifications.Weekly": "Weekly Activity",
            "flipto.account.settings.notifications.Daily": "Daily Advocate Content",
            "flipto.account.settings.notifications.Guest": "Guest Arrivals",
            "flipto.account.settings.notifications.FutureGuest": "Future Guest Arrivals",
            "flipto.account.settings.notifications.DaysInAdvance": "Days in advance",
            "flipto.account.settings.languages.InstalledLanguages": "Installed languages",
            "flipto.account.settings.languages.AddNewLanguages": "Add new languages",
            "flipto.account.settings.languages.Language": "Language",
            "flipto.account.settings.languages.NewLanguage": "New language",
            "flipto.account.settings.languages.SelectLanguage": "Select language",
            "flipto.account.settings.demos.CreateDemo": "Create Demo",
            "flipto.account.settings.demos.Demo": "Demo",
            "flipto.account.settings.demos.RemoveDemo": "Remove Demo",
            "flipto.account.settings.demos.DemoDescription": "Create a demo for any touchpoint based on source code from a hotel's site.",
            "flipto.account.settings.demos.DescriptionPlaceholder": "Add a description (optional)",
            "flipto.account.settings.demos.URLPlaceholder": "Enter URL to fetch source code or paste it below",
            "flipto.account.settings.demos.DemotypePlaceholder": "Choose a component",
            "flipto.account.settings.demos.FetchLabel": "Fetch",
            "flipto.account.settings.demos.InjectLabel": "Inject Script",
            "flipto.account.settings.demos.ViewDemo": "View demo",
            "flipto.account.settings.incentives.SetUpNewIncentive": "Set up a new incentive.",
            "flipto.account.settings.incentives.SaveNewIncentive": "Save incentive",
            "flipto.account.settings.incentives.NewIncentiveSummary": "You can save your new incentive once you've provided the required fields. Your new incentive will be ON by default, but remember it won't be used publicly until you set a conversion point to use this incentive.",
            "flipto.account.settings.demos.LastEdited": "Last Edited:",
            "flipto.account.settings.incentives.NewIncentive": "New incentive",
            "flipto.account.settings.incentives.FullVersion": "Full version",
            "flipto.account.settings.incentives.ShortVersion": "Short version",
            "flipto.account.settings.incentives.IncentiveName": "Incentive name",
            "flipto.account.settings.incentives.IncentiveNameModalSummary": "The full version should describe the incentive in complete detail. The short version should be easy to read when inserted in a sentence.",
            "flipto.account.settings.incentives.QrCode": "QR Code",
            "flipto.account.settings.incentives.Logo": "Logo",
            "flipto.account.settings.incentives.ShowQrCode": "Show QR Code",
            "flipto.account.settings.incentives.RedeemUrl": "Redeem URL",
            "flipto.account.settings.incentives.ExpirationDate": "Certificate expiration",
            "flipto.account.settings.incentives.OnRelativeDate": "On a relative date",
            "flipto.account.settings.incentives.OnSpecificDate": "On a specific date",
            "flipto.account.settings.incentives.DaysAfterCertificateIsIssued": "days after certificate is issued",
            "flipto.account.settings.incentives.ExpiresOn": "Expires on",
            "flipto.account.settings.incentives.ExpiresDaysAfterCertificateWasIssued": "Expires {{daysValidFor}} days after certificate was issued",
            "flipto.account.settings.incentives.RedeemInstructions": "Redeem instructions",
            "flipto.account.settings.incentives.ShowRedeemButton": "Show redeem button",
            "flipto.account.settings.incentives.SetTheIncentiveName": "Set the incentive name here",
            "flipto.account.settings.incentives.SetTheExpirationDate": "Set the expiration date here",
            "flipto.account.settings.incentives.SetTheRedeemUrl": "Set the redeem url here",
            "flipto.account.settings.incentives.SetTheTerms": "Set the terms & conditions here",
            "flipto.account.settings.incentives.SetTheInstructions": "Set the redeem instructions here",
            "flipto.account.settings.incentives.SetTheLogo": "Set the logo here",
            "flipto.account.settings.incentives.SetTheStashPoints": "Set the stash points here",
            "flipto.account.settings.incentives.OtherOption": "Other option",
            "flipto.account.settings.incentives.CreateNewIncentive": "Click here to set create this incentive",
            "flipto.account.settings.incentives.IncentiveMode": "Incentive mode",
            "flipto.account.settings.incentives.TermsAndConditions": "Terms & Conditions",
            "flipto.account.settings.incentives.ForStaffUse": "For Staff Use",
            "flipto.account.settings.incentives.StashPoints": "Stash points",
            "flipto.account.settings.incentives.EarnedBy": "Earned by {guest} on {date}",
            "flipto.account.settings.incentives.Redeem": "Redeem",
            "flipto.account.settings.incentives.TermConditions": "Term & Conditions",
            "flipto.account.settings.incentives.RedeemText": "Redeem this certificate now. Just click the button on the right and put it to use on the next page.",
            "flipto.account.settings.incentives.ForStaffUseText": "To redeem this voucher, go to {url} and click the redeem button in the lower right corner",
            "flipto.account.settings.incentives.TermsAndConditionsTextPart1": "Offer not applicable to groups",
            "flipto.account.settings.incentives.TermsAndConditionsTextPart2": "Cannot be combined with other discounts offers or promotions. One use per guest per stay",
            "flipto.account.settings.incentives.TermsAndConditionsTextPart3": "Not responsible for lost or stolen certificates. No redeemable for cash. Blackout dates may apply",
            "flipto.account.settings.incentives.SetTags": "Incentive tag",
            "flipto.account.settings.incentives.NoTagsSet": "No tags have been selected.",
            "flipto.account.settings.incentives.IncentiveTagType.1": "Guest Bonus",
            "flipto.account.settings.incentives.IncentiveTagType.1.Description": "applies towards upcoming stay",
            "flipto.account.settings.incentives.IncentiveTagType.2": "Warm Lead Bonus",
            "flipto.account.settings.incentives.IncentiveTagType.2.Description": "applies towards future stay",
            "flipto.account.settings.incentives.IncentiveTagType.4": "Contest Prize",
            "flipto.account.settings.incentives.IncentiveTagType.4.Description": "for winners and runners-up",
            "flipto.account.settings.incentives.IncentiveTagDescription": "Tag this incentive so you can use it throughout your platform.<br>You can select multiple tags. You will not be able to use this incentive if no tags are selected.",
            "flipto.account.settings.Add": "Add",
            'flipto.components.incentives.types.Promoter': 'For sharing',
            'flipto.components.incentives.types.PoststayParticipation': 'For participation',
            'flipto.components.incentives.types.PoststayAdvocate': 'For sharing',
            'flipto.components.incentives.types.PoststayPhotoSubmission': 'For submitting a photo',

            'flipto.account.components.imageLibrary.All': 'All',
            'flipto.account.components.imageLibrary.Logos': 'Logos',
            'flipto.account.components.imageLibrary.Backgrounds': 'Backgrounds',
            'flipto.account.components.imageLibrary.Assets': 'Assets',
            'flipto.account.components.imageLibrary.UseDifferentImage': 'Use a different image',
            'flipto.account.components.imageLibrary.DeleteImage': 'Delete image',
            'flipto.account.components.imageLibrary.ChooseFromImages': 'Choose from images',
            'flipto.account.components.imageLibrary.Library': 'Library',
            'flipto.account.components.imageLibrary.Upload': 'Upload',
            'flipto.account.components.imageLibrary.ImageUrl': 'Image Url',
            'flipto.account.components.imageLibrary.ImageUploadMessage': 'Drop an image here (or click to upload it)',
            'flipto.account.components.imageLibrary.ImageUploadErrorMessage': 'Error uploading your file, please try again.',
            'flipto.account.components.imageLibrary.EnterImageUrl': 'Enter url of image',
            'flipto.account.components.imageLibrary.Go': 'Go',

        });

        $translateProvider.translations("en", {
            'flipto.components.common.date.month1': 'January',
            'flipto.components.common.date.month2': 'February',
            'flipto.components.common.date.month3': 'March',
            'flipto.components.common.date.month4': 'April',
            'flipto.components.common.date.month5': 'May',
            'flipto.components.common.date.month6': 'June',
            'flipto.components.common.date.month7': 'July',
            'flipto.components.common.date.month8': 'August',
            'flipto.components.common.date.month9': 'September',
            'flipto.components.common.date.month10': 'October',
            'flipto.components.common.date.month11': 'November',
            'flipto.components.common.date.month12': 'December',
            'flipto.components.common.date.month1.short': 'Jan',
            'flipto.components.common.date.month2.short': 'Feb',
            'flipto.components.common.date.month3.short': 'Mar',
            'flipto.components.common.date.month4.short': 'Apr',
            'flipto.components.common.date.month5.short': 'May',
            'flipto.components.common.date.month6.short': 'Jun',
            'flipto.components.common.date.month7.short': 'Jul',
            'flipto.components.common.date.month8.short': 'Aug',
            'flipto.components.common.date.month9.short': 'Sep',
            'flipto.components.common.date.month10.short': 'Oct',
            'flipto.components.common.date.month11.short': 'Nov',
            'flipto.components.common.date.month12.short': 'Dec'
        });

        $translateProvider.translations("en", {
            "accountApp.components.defaultText.notProvided": "Default text not provided"
        });

        $translateProvider.translations("en", {
            "flipto.account.reports.Advocates": "Guest arrivals",
            "flipto.account.reports.AdvocatesDescription": "A list of guests who became advocates and are arriving during the queried date range.",
            "flipto.account.reports.Certificates": "Certificates",
            "flipto.account.reports.CertificatesDescription": "A list of people who have received a certificate through your platform",
            "flipto.account.reports.Feedback": "Feedback",
            "flipto.account.reports.FeedbackDescription": "A list of feedback that your guests submitted through your platform",
            "flipto.account.reports.OccupancyByStayDate": "Occupancy (stay date)",
            "flipto.account.reports.OccupancyByStayDateDescription": "A summary of occupancy sold for stay dates that fall in the queried date range.",
            "flipto.account.reports.OccupancyByBookingDate": "Occupancy (booking date)",
            "flipto.account.reports.OccupancyByBookingDateDescription": "A summary of occupancy sold for bookings that were completed in the queried date range.",

            "flipto.account.reports.SignupsStorytellerFriends": "Friends discovered through storytellers",
            "flipto.account.reports.SignupsStorytellerFriendsDescription": "A list of friends who voted for a guest's story in a storyteller campaign.",
            "flipto.account.reports.SignupsStorytellerVisitors": "Visitors discovered through storytellers",
            "flipto.account.reports.SignupsStorytellerVisitorsDescription": "A list of visitors who voted for a guest's story in a storyteller campaign.",
            "flipto.account.reports.SignupsStayAdvocacy": "Friends discovered through advocates - Pre-stay/Post-stay",
            "flipto.account.reports.SignupsStayAdvocacyDescription": "A list of friends who showed interest in your brand through an advocate who shared about their trip.",
            "flipto.account.reports.SignupsInterested": "Visitors who are almost ready to book",
            "flipto.account.reports.SignupsInterestedDescription": "A list of visitors who revealed a strong interest in booking, but haven't committed just yet.",
            "flipto.account.reports.SignupsExpired": "Leads who didn't book",
            "flipto.account.reports.SignupsExpiredDescription": "A list of leads who received a certificate for a future stay but didn't book before it expired.",
            "flipto.account.reports.DiscoveryPlanners": "Planners revealed by Discovery",
            "flipto.account.reports.DiscoveryPlannersDescription": "A list of planners whose plan is more than 10 days old.",
            "flipto.account.reports.DiscoveryPlannersTroubleshooting": "Planners revealed by Discovery",
            "flipto.account.reports.DiscoveryPlannersTroubleshootingDescription": "A list of all planners. For troubleshooting Discovery communication issues.",

            "flipto.account.reports.Stories": "Traveler Stories",
            "flipto.account.reports.StoriesDescription": "A list of content that travelers submitted through your platform.",

            "flipto.account.reports.Sources": "Reservations",
            "flipto.account.reports.SourcesDescription": "A list of guests who made a booking that can be attributed to your platform.",
            "flipto.account.reports.Statistics": "Platform statistics",
            "flipto.account.reports.StatisticsDescription": "General performance metrics for your platform.",
            "flipto.account.reports.StatisticsPreStay": "Prestay platform statistics",
            "flipto.account.reports.StatisticsPreStayDescription": "Prestay performance metrics for your platform.",
            "flipto.account.reports.StatisticsPostStay": "PostStay platform statistics",
            "flipto.account.reports.StatisticsPostStayDescription": "PostStay performance metrics for your platform.",
            "flipto.account.reports.StatisticsPhotoContests": "Story contest statistics",
            "flipto.account.reports.StatisticsPhotoContestsDescription": "Story contest performance metrics for your platform.",
            "flipto.account.reports.StatisticsHomepage": "Homepage statistics",
            "flipto.account.reports.StatisticsHomepageDescription": "Homepage performance metrics for your platform."
        });


        $translateProvider.translations("en", {

            'flipto.account.EditImage1': 'Logo for light backgrounds',
            'flipto.account.EditImage2': 'Logo for dark backgrounds',
            'flipto.account.EditImage3': 'Property photo',
            'flipto.account.EditImage4': 'Share Image',
            'flipto.account.EditImage5': 'Mobile Background Image',
            'flipto.account.rating.NotGood': 'Not Good',
            'flipto.account.rating.Good': 'Good enough to keep around',
            'flipto.account.rating.Great': 'Great, works for marketing',
            'flipto.account.rating.Amazing': 'Amazing, should be highlighted!',

            "flipto.account.Engagement": 'engagement',
            "flipto.account.UniqueSiteVisitors": 'unique site visitors',
            "flipto.account.UniqueVisitors": 'unique visitors',
            "flipto.account.VisitorSignups": 'visitor sign-ups',
            "flipto.account.PerAdvocate": 'per advocate',
            "flipto.account.PropertyDetails": "Property details",
            "flipto.account.BrandStyling": "Brand styling",
            "flipto.account.Brands": "Brands",
            "flipto.account.CertificateStyling": "Certificate styling",
            "flipto.account.ThirdPartyApps": "Third-party apps",
            "flipto.account.Analytics": "Analytics",
            "flipto.account.ZuoraID": "Zuora ID",
            "flipto.account.StashID": "Stash ID",
            "flipto.account.CollectDemographics": "Collect demographics and interests data",
            "flipto.account.CollectDemographicsDisclaimer": "Ensure that your privacy page is configured before enabling data collection",
            "flipto.account.AddDemographicsToPrivacyPage": "Add demographics and interests policy to Privacy Policy",
            "flipto.account.ShareDetails": "Share details",
            "flipto.account.PropertyName": "Property name",
            "flipto.account.PropertyDescription": "Property description",
            "flipto.account.SupportEmail": "Support email",
            "flipto.account.HelpCenter": "Help Center",
            "flipto.account.PrivacyFeature": "Privacy feature",
            "flipto.account.HelpCenterPrivacyFeature": "Display privacy articles in your Help Center",
            "flipto.account.HelpCenterPrivacyFeatureSummary": "This will give your audience the ability to make privacy-related requests through your Help Center.",
            "flipto.account.PrivacySupportEmail": "Support email for privacy requests",
            "flipto.account.PrivacySupportEmailSummary": "Provide this info if you have a Data Protection Officer or specific email for handling privacy matters. Any privacy-related inquiries received on our platform will be forwarded to this address. If none is provided, we'll use your standard support email.",
            "flipto.account.BookingEngineUrl": "Booking engine URL",
            "flipto.account.Name": "Display name",
            "flipto.account.PropertyUrl": "Property URL",
            "flipto.account.Slug": "Slug",
            "flipto.account.Logo": "Logo",
            "flipto.account.Logos": "Logos",
            "flipto.account.LogosSummary": "Displayed on branded pages and printable screens, the appropriate logo will be used based on the surrounding background color or image.",
            "flipto.account.LogoForLightBg": "Logo for light backgrounds",
            "flipto.account.LogoForDarkBg": "Logo for dark backgrounds",
            "flipto.account.HighResolutionRecommended": "High resolution file is recommended.",
            "flipto.account.BackgroundImage": "Background image",
            "flipto.account.BackgroundImageSummary": "Photo 1 should be the best photo that represents your property—we'll display it to your audience whenever an image is needed.",
            "flipto.account.BackgroundImageAdditionalPhotosSummary": "If you provide additional photos, we'll use them to enrich different experiences for your audience.",
            "flipto.account.MobileLandingImage": "Mobile background image",
            "flipto.account.MobileLandingImageSummary": "Shown in the mobile version of the landing page.",
            "flipto.account.ShareImage": "Share image",
            "flipto.account.ShareImageSummary": "Included with the post that guests share with their social connections. Minimum 600 x 315 px recommended.",
            "flipto.account.ZuoraId": "Zuora ID",
            "flipto.account.LogMonthlyUsage": "Log monthly usage",
            "flipto.account.StashId": "Stash ID",
            "flipto.account.Twitter": "Twitter",
            "flipto.account.Integration": "Integration",
            "flipto.account.Location": "Location",
            "flipto.account.NumberOfRooms": "Number of rooms",
            "flipto.account.AverageDailyRate": "Average Daily Rate",
            "flipto.account.AnnualSpend": "Annual spend",
            "flipto.account.GuestUpload": "Import guest list",
            "flipto.account.Access": "Access",
            "flipto.account.SearchCompanies": "Add an organization",
            "flipto.account.SearchUsers": "Add a user",
            "flipto.account.PropertyStatus": "Property status",
            "flipto.account.PropertyStatusWarning": "Closing a property will also attempt to disable layers and touchpoints",
            "flipto.account.GoogleTagManagerContainer": "Hotel's Google Tag Manager container",
            "flipto.account.DefaultConsentPolicy": "Default consent policy",
            "flipto.account.FlipToGoogleTagManagerContainer": "Flip.to managed Google Tag Manager container",
            "flipto.account.PurgeCdn": "Purge CDN",
            "flipto.account.EnableCDN": "Enable CDN",
            "flipto.account.DisableCDN": "Disable CDN",
            "flipto.account.IsWebsiteIntegrated": "Hotel website integration status",
            "flipto.account.IsBookingEngineIntegrated": "Booking Engine integration status",
            "flipto.account.IsExcludeHpcUtmParameters": "Remove UTM parameters from HPC navigation",
            "flipto.account.IsAccountV2Active": "Account V2 available",
            "flipto.account.AccountManager": "Account manager",
            "flipto.account.AssignAM": "Assign an account manager to this property.",
            "flipto.account.SalesPerson": "Sales person",
            "flipto.account.AssignSalesperson": "Assign a salesperson to this property.",
            'flipto.account.SignOut': 'Sign Out',
            'flipto.account.Reports': 'Reports',
            'flipto.account.Account': 'Account',
            'flipto.account.Platform': 'Platform',
            'flipto.account.StreetAddress': 'Street address',
            'flipto.account.City': 'City',
            'flipto.account.StateOrRegion': 'State or region',
            'flipto.account.ZIPOrPostalCode': 'ZIP or postal code',
            'flipto.account.Settings': 'Settings',
            'flipto.account.Accounts': 'Accounts',
            'flipto.account.Admin': 'Admin',
            'flipto.account.Campaign': 'Campaign',
            'flipto.account.SelectCampaign': 'Select campaign',
            'flipto.account.Campaigns': 'Campaigns',
            'flipto.account.GroupCampaigns': 'Group Campaigns',
            'flipto.account.Change': 'Change',
            'flipto.account.WinnersPodium': 'Winners Podium',
            'flipto.account.ChooseWinnersMessage': 'Choose your winners from the storytellers below.',
            'flipto.account.EndPhotoContestMessage': 'Once all winners have been selected, declare winners to end the campaign.',
            'flipto.account.EndPhotoContestContactAMMessage': 'If you would like to end the campaign with fewer winners, please contact your account manager.',
            'flipto.account.ReviewAndEndContest': 'Review & end contest',
            'flipto.account.AnnounceWinners': 'Declare winners',
            'flipto.account.ConcludeCampaign': 'Conclude campaign',
            'flipto.account.AnnounceWinnersAfter': 'You can announce winners after',
            'flipto.account.FinalistRanking': 'Storyteller Ranking',
            'flipto.account.FinalistsCountPluralized': "{'one':'{} Storyteller', 'other': 'Storytellers'}",
            'flipto.account.Finalists': 'Storytellers',
            'flipto.account.Finalist': 'Storyteller',
            'flipto.account.Shared': 'Shared',
            'flipto.account.Times': 'Times',
            'flipto.account.SharedXTimes': 'Shared {{x}} times',
            'flipto.account.FriendsVoted': 'Friends voted',
            'flipto.account.FriendsSignedUp': 'Friends signed up',
            'flipto.account.FriendsShared': 'Friends shared',
            'flipto.account.FriendShares': 'Friend shares',
            'flipto.account.Points': 'Points',
            'flipto.account.promotions.TW': 'Posted to {{totalFollowers}} followers',
            'flipto.account.promotions.FB': 'Shared with {{totalFollowers}} friends',
            'flipto.account.promotions.LI': '{{totalFollowers}} colleagues',
            'flipto.account.Reservation': 'Reservation',
            'flipto.account.ContestHasEnded': 'Contest has ended',
            'flipto.account.ContestWasClosed': 'Contest was closed.',
            'flipto.account.ReplaceCurrentPhoto': 'Replace current story',
            'flipto.account.BumpCurrentPhoto': 'Bump current story down a rank',
            'flipto.account.days': 'days',
            'flipto.account.EndContestMissingPlaces': 'You have not chosen a winner for the following places:',
            'flipto.account.EndContestMissingPlacesStillCanClose': 'You can still end the contest with the current winners, or close this message to return to the podium.',
            'flipto.account.EndContestNotificationMessage': 'Winners will be notified immediately with a link to collect their prize. This action will also conclude this campaign and cannot be undone.',
            'flipto.account.EndContest': 'End Contest',
            'flipto.account.ThereAreNoNewSubmissions': 'There are no new submissions',
            'flipto.account.NewSubmissions': 'New submissions',
            'flipto.account.LatestStories': 'Latest stories',
            'flipto.account.ThereAreNoCuratedSubmissions': 'There are no curated submissions',
            'flipto.account.ThereAreNoFinalists': 'There are no finalists',
            'flipto.account.ThereAreNoGoalsForFinalists': 'There are no goals for finalists defined',
            'flipto.account.ClickToEdit': 'Click to edit',
            'flipto.account.ImageSize': 'Image Size',
            'flipto.account.NoneSet': 'None set',
            'flipto.account.None': 'None',
            'flipto.account.EmailImageWidth': 'Image width for emails',
            'flipto.account.ImageSizeSummary': 'Set the available width for images in your existing email layout. Image height will be based on how the image content fits the provided space.',
            'flipto.account.Height': 'Height',
            'flipto.account.ImagePreviews': 'Image previews',
            'flipto.account.PrearrivalBannerPreview': 'Pre-arrival email banner preview',
            'flipto.account.PoststayBannerPreview': 'Post-stay email banner preview',
            'flipto.account.ConfirmationBannerPreview': 'Confirmation email banner preview',
            'flipto.account.ImagePreviewsSummary': 'Preview what the email image will look like for each language that is set.',
            'flipto.account.ImagePreviewsModalSummary': 'Previews may appear smaller than actual size. Click Refresh if the preview is not up to date with your latest changes.',
            'flipto.account.PreArrivalEmail': 'Banner for Pre-Arrival Email',
            'flipto.account.EmailBanners': 'Email Banners',
            'flipto.account.ConfirmationEmailBanner': 'Banner for Confirmation Email',
            'flipto.account.Loading': 'Loading',
            'flipto.account.ProcessingImage': 'Processing Image',
            'flipto.account.LoadMore': 'Load more',
            'flipto.account.RefreshImage': 'Refresh',
            'flipto.account.ThereAreNoPreviews': 'There are no previews',
            'flipto.account.WhereAdvocatesShared': 'Where advocates shared',
            'flipto.account.advocate': 'advocate',
            'flipto.account.SocialReach': 'Social reach',
            'flipto.account.BookingEngine': 'Booking Engine',
            'flipto.account.FollowupBatchFile': 'Followup Batch File',
            'flipto.account.PrestayEmail': 'Prestay Email',
            'flipto.account.RegisterFollowupLink': 'Register Followup Link',
            'flipto.account.BannerImage': 'Banner image',
            'flipto.account.RegisterReviewLink': 'Register Review Link',
            'flipto.account.ReviewBatchFile': 'Review Batch File',
            'flipto.account.NonView': 'Reminder Email (when not viewed)',
            'flipto.account.AfterView': 'Reminder Email (when viewed)',
            'flipto.account.NoDataForDateRange': 'No data for this date range',
            'flipto.account.GuestsClickedThrough': 'guests clicked through',
            'flipto.account.Sent': 'Sent',
            'flipto.account.NotYetVisited': 'Not yet visited',
            'flipto.account.EmailWasSentOn': 'Email was sent on',
            'flipto.account.ReminderEmailWasSentOn': 'Reminder email was sent on',
            'flipto.account.Viewed': 'Viewed',
            'flipto.account.Clicked': 'Clicked',
            'flipto.account.GuestsResponded': 'Guests responded',
            'flipto.account.ResultingIn': 'Resulting in',
            'flipto.account.PositiveQuotes': 'short and sweet quotes',
            'flipto.account.ExternalReviews': 'external review site visits',
            'flipto.account.NegativeQuotes': 'notes for improvement',
            'flipto.account.Others': 'Others',
            'flipto.account.VisitorsTookAction': 'clicked to unlock incentive',
            'flipto.account.LeadingTo': 'Leading to',
            'flipto.account.DirectBookings': 'book now clicks',
            'flipto.account.CertificateSignUps': 'sign-ups',
            'flipto.account.Quotes': 'quotes',
            'flipto.account.VisitorsViewedThem': 'visitors viewed them',
            'flipto.account.AddAnImage': 'Add an image',
            'flipto.account.EmailImage': 'Email image background',
            'flipto.account.EmailImageSummary': 'Choose an image that relates to the contest in order to make the email more appealing. If no image is set here the property background image is used.',
            'flipto.account.OfRecipients': 'of recipients',
            'flipto.account.Width': 'Width',
            'flipto.account.EmailInvalid': 'The email you entered seems to be invalid. Please re-enter and try again',
            'flipto.account.CanNotAddGoalsBecausePropertyHasNoIncentives': 'You need to define incentives in order to add goals.',
            'flipto.account.SendSampleEmailSuccess': 'Successfully sent sample email',
            'flipto.account.SendSampleEmailError': 'Error while sending sample email',
            'flipto.account.Unavailable': 'unavailable',
            'flipto.account.Connections': 'connections',
            'flipto.account.All': 'All',
            'flipto.account.BookedRoom': 'Booked room',
            'flipto.account.BookedStay': 'Booked a stay',
            'flipto.account.FollowupClick': 'Followup click',
            'flipto.account.PoststayClick': 'Post-stay click',
            'flipto.account.PrearrivalEmailView': 'Pre-arrival email view',
            'flipto.account.ConfirmationEmailView': 'Confirmation email view',
            'flipto.account.PoststayEmailView': 'Poststay email view',
            'flipto.account.Invitee': 'Photo contest invitation',
            'flipto.account.ReservationDate': 'Reservation date',
            'flipto.account.WasGuestFrom': 'was a guest from',
            'flipto.account.LastInteraction': 'Last interaction',
            'flipto.account.SharedVia': 'Shared via',
            'flipto.account.EarnedIncentive': 'Earned incentive',
            'flipto.account.HotelStay': 'Hotel stay',
            'flipto.account.InvitedToPoststay': 'Invited to post-stay process',
            'flipto.account.RemindedAboutPoststay': 'Reminded about post-stay process',
            'flipto.account.EmailWasClicked': 'Email was clicked',
            'flipto.account.EmailWasViewedButNotClicked': 'Email was viewed but not clicked',
            'flipto.account.EmailWasNotViewed': 'Email was not viewed',
            'flipto.account.Rated': 'Rated',
            'flipto.account.OutOf': 'out of',
            'flipto.account.PoststayProcess': 'Poststay Process',
            'flipto.account.InvitationProcess': 'Invitation Process',
            'flipto.account.SharePage': 'Share Page',
            'flipto.account.SubmittedPhoto': 'Submitted story',
            'flipto.account.AdvancedToFinals': 'Advanced to finals',
            'flipto.account.SubmittedQuote': 'Submitted quote',
            'flipto.account.GaveFeedback': 'Gave feedback',
            'flipto.account.Expired': 'Expired',
            'flipto.account.Redeemed': 'Redeemed',
            'flipto.account.NotifiedAsFinalist': 'Notified as finalist',
            'flipto.account.Activity': 'Activity',
            'flipto.account.WonPrize': 'Won a prize',
            'flipto.account.MostRecentActivity': 'Most recent activity',
            'flipto.account.StayDates': 'Stay dates',
            'flipto.account.Cancelled': 'Cancelled',
            'flipto.account.Reservation#': 'Reservation #',
            'flipto.account.BookedOn': 'Booked on',
            'flipto.account.PhotoDetails': 'Photo Details',
            'flipto.account.PhotoReplacement': 'Photo Replacement',
            'flipto.account.Share': 'Share',
            'flipto.account.No': 'No',
            'flipto.account.Yes': 'Yes',
            'flipto.account.Post.facebook': 'Create post',
            'flipto.account.Post.twitter': 'Create post',
            'flipto.account.Post.linkedin': 'Create post',
            'flipto.account.Post.instagram': 'Create post',
            'flipto.account.SharingNotAvailableOnMobile': "Sharing is disabled on mobile. Please use a desktop to share {{first}}'s content.",
            'flipto.account.SharingNotAvailableNotFinalist': "Sharing is available only for stories that have been advanced to the campaign.",
            'flipto.account.CurationShareError': 'Yikes, there seems to have been a problem. Please give it another try.',
            'flipto.account.ShareSuccess': 'Shared successfully.',
            'flipto.account.ReadyToGo': 'Ready to go',
            'flipto.account.ImageCreated': "Your image has been created.",
            'flipto.account.HereIsImageToShare': "Here's the image that will be shared.",
            'flipto.account.ClickShareButtonOr': "Click share when you're ready or",
            "flipto.account.Download": "Download",
            'flipto.account.DownloadImage': "Download image",
            'flipto.account.DownloadThisImage': "download this image",
            'flipto.account.CreatingImage': 'Preparing your image',
            'flipto.account.DownloadImageInstagramInstructions': 'Since Instagram does not support posting from desktop, you must use the Instagram app from a mobile device to complete your broadcast. Save the image below to your mobile device and post it through the app.',

            'flipto.account.ShareOn': 'Share on {{title}}',
            'flipto.account.AddYourMessage': 'Add your message...',
            'flipto.account.BackgroundPhoto': 'Background photo',
            'flipto.account.Template': 'Template',
            'flipto.account.GuestPhoto': 'Guest photo',
            'flipto.account.HotelPhoto': 'Hotel photo',
            'flipto.account.GuestProfilePic': 'Profile pic',
            'flipto.account.GuestName': 'Name',
            'flipto.account.PhotoCaption': 'Caption',
            'flipto.account.DeleteMessage': 'Delete message',
            'flipto.account.Save': 'Save',
            'flipto.account.AddMessageVariation': 'Add message variation',
            'flipto.account.GuestSearchInputPlaceholder': 'Type in a name, email or reservation #',
            'flipto.account.EnterKeyword': 'Enter a keyword',
            'flipto.account.SearchByKeyword': 'Search by keyword',
            'flipto.account.GuestSearchNoResults': 'We couldn\'t find that specific search term.<br />Please try again.',
            'flipto.account.SendCertificate': 'Send certificate',
            'flipto.account.ResendCertificate': 'Resend certificate',
            'flipto.account.SendCertificateSuccessfull': 'The certificate has been emailed to the guest.',
            'flipto.account.SendCertificateFailed': 'There was a problem sending the email. Please try again.',
            'flipto.account.Expires': 'Expires',
            'flipto.account.Edit': 'Edit',
            'flipto.account.Email': 'Email',
            'flipto.account.Send': 'Send',
            'flipto.account.Cancel': 'Cancel',
            'flipto.account.ResetPasswordInstructions': 'Enter your email address below and we\'ll send you a new temporary password.',
            'flipto.account.EmailSent': 'Email sent!',
            'flipto.account.ResetPasswordSuccessInstructions': 'Please check your inbox for your temporary password.',
            'flipto.account.ReturnToSignin': 'Return to Sign in',
            'flipto.account.ResetPasswordError': 'Could not reset password, please try again.',
            'flipto.account.InvalidEmailOrPassword': 'Invalid email or password.',
            'flipto.account.AccountDisabled': 'Your account has been disabled',
            'flipto.account.ContactAdministrator': 'Please contact your administrator.',
            'flipto.account.ChangePasswordInstructions': 'Your password has expired. Let\'s set up a new password for you.',
            'flipto.account.OldPassword': 'Old password',
            'flipto.account.NewPassword': 'New password',
            'flipto.account.ConfirmPassword': 'Confirm password',
            'flipto.account.PasswordInstructions': 'Password should contain both upper-case and lower-case characters, number or special character and be at least 6 characters long.',
            'flipto.account.SigningIn': 'Signing in...',
            'flipto.account.PasswordSaved': 'Password saved!',
            'flipto.account.PasswordSameAsError': 'Your confirm password does not match your new password. Please try again.',
            'flipto.account.ChangePasswordError': 'Error while changing password, please try again.',
            'flipto.account.ChangePasswordPasswordUsedError': 'Reusing one of your recent passwords is not allowed. Please re-enter your new password.',
            'flipto.account.ChangePasswordArgumentError': 'The password you have entered does not match our records. Please verify your password and retry.',
            'flipto.account.ForFriends': 'for friends',
            'flipto.account.ForAttendees': 'for attendees',
            'flipto.account.Router': 'Router',
            'flipto.account.RouterMessage': 'Router message',
            'flipto.account.UnlockMessage': 'Unlock message',
            'flipto.account.HotelSite': 'Hotel Site',
            'flipto.account.PasskeySite': 'Passkey Site',
            'flipto.account.ImFriend': 'I\'m a friend',
            'flipto.account.ImAttendee': 'I\'m an attendee',
            'flipto.account.UnlockOne': 'Unlock one',
            'flipto.account.TouchpointCSS': 'Touchpoint CSS',
            'flipto.account.CSS': 'CSS',
            'flipto.account.ParentCSS': 'Parent CSS',
            'flipto.account.ElementSelector': 'Element page location',
            'flipto.account.DetermineDirection': 'Help us point you in the right direction...',
            'flipto.account.RouterQuestion': 'Are you a friend of {first}\'s or a fellow attendee of the event?',
            'flipto.account.Category': 'Category',
            'flipto.account.SubCategory': 'Sub-category',
            'flipto.account.Import': 'Import',
            'flipto.account.Advanced': 'Advanced',
            'flipto.account.GroupEventsList': 'Group events list',
            'flipto.account.ApiKey': 'External integration API Key',
            'flipto.account.UserPermissionImport': 'User Permission list',
            'flipto.account.DataParseLabel': 'Data parse emails',
            'flipto.account.DataParseModalSummary': 'Add email addresses for team members who should be allowed to send data parse emails to your account.',
            'flipto.account.DataParseEmailAdd': 'Add email address',
            'flipto.account.NoTeamMembersOnList': 'No team members have been added to this list.',
            'flipto.account.ElementPosition': 'Element position',
            'flipto.account.Shares': 'shares',
            'flipto.account.TotalShares': 'total shares',
            'flipto.account.TotalSharesByStorytellersAndFriends': 'total shares by storytellers and their friends',
            'flipto.account.FriendSignups': 'friend sign-ups',
            'flipto.account.FriendsVotedNSignedUp': 'friends voted & signed up',
            "flipto.account.Conversionpoint": 'Conversion point',
            "flipto.account.Variation": 'Variation',
            "flipto.account.Variations": 'Variations',
            "flipto.account.NewVariation": 'New variation',
            "flipto.account.SaveVariation": 'Save variation',
            "flipto.account.VariationName": 'Variation name',
            "flipto.account.SetUpANewVariation": 'Set up a new variation',
            "flipto.account.OnceYouSaveThisVariation": 'Once you save this variation, it will be OFF by default. You must switch it ON to put it into effect.',
            "flipto.account.NoCodesSet": 'No codes have been set.',

            "flipto.account.AccountDetails": "Account details",
            "flipto.account.Configuration": "Configuration",
            "flipto.account.NewGroup": "New group",
            "flipto.account.EditGroup": "Edit group",
            "flipto.account.NewAccount": "New account",
            "flipto.account.NewProperty": "New property",
            "flipto.account.GroupName": "Group name",
            "flipto.account.Group": "Group",
            "flipto.account.Groups": "Groups",
            "flipto.account.SelectAGroup": "Select a group",
            "flipto.account.Properties": "Properties",
            "flipto.account.NoProperties": "No properties",
            "flipto.account.AddProperty": "Add property",
            "flipto.account.AccountName": "Account name",
            "flipto.account.AccountUrl": "Account URL",
            "flipto.account.AccountStatus": "Account status",
            "flipto.account.AccountV2Status": "Account v2",
            "flipto.account.AccountV1Status": "Account v1",
            "flipto.account.SetAsDefault": "Set as default",
            "flipto.account.AccountCode": "Account code",
            "flipto.account.Select": "Select",
            "flipto.account.BookingEngineDateFormat": "Booking engine date format",
            "flipto.account.ThereAreNoGroups": "There are no groups",
            "flipto.account.ThereAreNoProperties": "There are no properties",
            "flipto.account.EmailTemplate": "Email template",
            "flipto.account.ActivateNewVersion": "Activate new version",
            "flipto.account.InstallNewVersion": "Install new version",
            "flipto.account.EditTheNewVersion": "Edit the new version",
            "flipto.account.GoBackToTheOldVersion": "Go back to the old version",
            "flipto.account.OnceInstalledYouWillHaveAChanceToEditAndReviewTheNewVersionBeforeActivatingIt": "Once installed, you'll have a chance to edit and review the new version before activating it.",
            "flipto.account.TheresNewVersionOfThePostStayProcess": "There's a new version of the Post-stay Process.",
            "flipto.account.YoureEditingTheNewVersion": "You're editing the new version.",
            "flipto.account.TheOldVersionIsStillActive": "The old version is still active until you're ready to activate this version.",
            "flipto.account.StartEditingTheNewVersionOfThePostStay": "Start editing the new version of the Post-stay Process.",
            "flipto.account.Intro": "Intro",
            "flipto.account.Rating": "Rating",
            "flipto.account.Contest": "Campaign",
            "flipto.account.QuoteFeedback": "Quote & Feedback",
            "flipto.account.Thanks": "Thanks",
            "flipto.account.IfGuestRatedFromEmail": "If guest rated from email",
            "flipto.account.IntroHighRating": "Intro (high rating)",
            "flipto.account.IntroLowRating": "Intro (low rating)",
            "flipto.account.HiFirst": "Hi {first}!",
            "flipto.account.ThanksFirst": "Thanks, {first}",
            "flipto.account.IntroMessage": "Intro message",
            "flipto.account.ProgressMeter": "Progress meter",
            "flipto.account.GetStarted": "Get started",
            "flipto.account.JoinOurPhotoContest": "Join our storyteller campaign",
            "flipto.account.RewardsDetailsTitle": "Rewards details title",
            "flipto.account.ContestDetails": "Contest details",
            "flipto.account.RewardsDetails": "Rewards details",
            "flipto.account.PickRewards": "Pick up some rewards",
            "flipto.account.NoWay": "No way!",
            "flipto.account.AbsolutelyWill": "Absolutely will!",
            "flipto.account.ContestTitleTheme": "Contest Title",
            "flipto.account.WinnerPlacesAndPrizes": "Winner places and prizes",
            "flipto.account.JoinContestMessage": "Join contest message",
            "flipto.account.HowLikelyAreYouToRecommendUsToFriends": "How likely are you to recommend us to friends?",
            "flipto.account.AddYourWinningPhotoHere": "Add your winning story here",
            "flipto.account.ClickBelowToGrabItFromSomewhereElse": "Click below to grab it from somewhere else.",
            "flipto.account.OrSkipTheContest": "Or skip the contest",
            "flipto.account.Submit": "Submit",
            "flipto.account.ShareMessage": "Share message",
            "flipto.account.SkipSharing": "Skip sharing",
            "flipto.account.ShareQuote": "Share quote",
            "flipto.account.ThanksMessage": "Thanks message",
            "flipto.account.ExternalReviewMessage": "External review message",
            "flipto.account.WriteAReview": "Write a review",
            "flipto.account.LetsGo": "Let\'s go",
            "flipto.account.FeedbackMessage": "Feedback message",
            "flipto.account.KeepOnGoing": "Keep on going",
            "flipto.account.GiveYourFeedback": "Give your feedback",
            "flipto.account.ConfirmActivation": "Confirm activation",
            "flipto.account.Activate": "Activate",
            "flipto.account.QuoteMessage": "Quote message",
            "flipto.account.Photos": "Photos",
            "flipto.account.LivePreview": "Live preview",
            "flipto.account.ShareDisabledInactive": "Sharing is disabled until the contest is live",
            "flipto.account.EmailSample": "Email sample",
            "flipto.account.EnablePhotos": "Enable photos",
            "flipto.account.NoCaptionProvided": "No caption provided",
            "flipto.account.LocationUnknown": "Location unknown",
            "flipto.account.DateUnknown": "Date unknown",
            "flipto.account.ShowLinkToExternalReview": "Show link to external review",
            "flipto.account.SomeSettingsMayBeInheritedFromTheOldVersion": "Some settings may be inherited from the old version and will be locked from editing here",
            "flipto.account.YoureGuestsAreAboutToExperience": "You're guests are about to experience a new and improved Post-stay Process. Once activated, the old version will be removed from your account.",
            "flipto.account.CampaignHasNoGoals": "No goal has been set. Click to view this campaign and set a goal.",

            "flipto.account.DefaultDomainTitle": "Your advocacy hub",
            "flipto.account.DefaultDomainDescription": "We’ve set up a <b>{{domain}}</b> domain for you to feature the stories your guests are sharing with friends, family and travelers around the world. You can start using it immediately, or if you prefer to keep your URL consistent with your primary website, you can set up your own domain below.",
            "flipto.account.DefaultDomainLabel": "Your {{domain}} domain",
            "flipto.account.CustomDomainLabel": "Your custom domain",
            "flipto.account.AddDomain": "Add a domain",
            "flipto.account.AddDomainTitle": "Set up your own domain",
            "flipto.account.AddDomainDescription": "Add a domain to point to your advocacy hub and control how your URL will appear to your guests. This will create a seamless experience with your primary website.",
            "flipto.account.ViewSetupInstructions": "View setup instructions",
            "flipto.account.TestedOn": "Tested on {{date | dateFormat:'MM/DD/YYYY'}}",
            "flipto.account.DomainsSetupInstructionsText": "Please contact the almighty dev team for next steps. We know you want to be self-sufficient but you'll have to ask a developer for help at the moment. Don't worry, we're whipping up a detailed step-by-step guide so you'll be a master CNAME setter upper in no time.",
            "flipto.account.SetupInstructions": "Setup instructions",
            "flipto.account.NoDomainsSetupText": "There are no domains set up. Add a new domain to get started.",
            "flipto.account.Directory": "Directory",
            "flipto.account.AddDomainModalHeaderDescription": "Enter the domain to use as an advocacy hub. You'll need to configure the CNAME record in your DNS and make sure that it matches with what you enter here.",
            "flipto.account.EnterDomainPlaceholder": "Enter a domain (ie. stories.yoursite.com)",
            "flipto.account.DomainExistValidationError": "This domain name has already been used. Please choose a different name.",
            "flipto.account.DomainInvalidValidationError": "This domain is invalid. Please choose a different name.",
            "flipto.account.SelectDomainPropertiesSubHeaderText": "Select the properties that will use this domain as their advocacy hub.",
            "flipto.account.DomainGenericError": "Unable to create new domain due to server error. Please try again.",
            "flipto.account.ClickToSelectDomainMappingText": "No properties selected",
            "flipto.account.DomainTestModalSubHeaderText": "If there's a green checkmark below, your domain is configured correctly and working. If it is not working, please check that your domain matches what you have configured for the CNAME record in your DNS. You can also get in touch with your account manager for further assistance.",
            "flipto.account.TestConfiguration": "Test",
            "flipto.account.DeleteDomain": "Delete this domain",
            "flipto.account.Done": "Done",
            "flipto.account.CancelTest": "Cancel test",
            "flipto.account.Testing": "Testing...",
            "flipto.account.TestingConfiguration": "Testing configuration",
            "flipto.account.RemoveDomainConfirmationText": "You are about to delete this domain. There are {{propertiesNumber}} properties that have been set up to use this domain. Do you wish to continue? Caution: This action cannot be undone.",
            "flipto.account.Delete": "Delete",
            "flipto.account.PhotoExplorerUrl": "Story Explorer URL",
            "flipto.account.OldUrl": "Old URL",
            "flipto.account.PhotoExplorer": "Story Explorer",
            "flipto.account.NoDomainConfigured": "There was a problem with this domain.",
            "flipto.account.BannerSize": "Banner size",
            "flipto.account.BannerSizeDescription": "Set the width of the banner (measured in px). The height will adjust to fit the content. You can also add a mobile banner if your email has a responsive layout for mobile devices.",
            "flipto.account.WidthDesktop": "Width for desktop",
            "flipto.account.WidthMobile": "Width for mobile",
            "flipto.account.AddMobileBanner": "Add a mobile banner",
            "flipto.account.BannerIsInUseMessage": "A custom banner is currently in use. Some settings will be locked from editing here.",
            "flipto.account.platform.BannerHeading": "Banner heading",
            "flipto.account.platform.BannerText": "Banner message",
            "flipto.account.platform.BannerButtonText": "Banner button",
            "flipto.account.platform.ClickToPreview": "Click to preview",
            "flipto.account.UseDefaultImage": "Use default image (Property image)",
            "flipto.account.SlugShortInstructions": "Do not modify this slug after the property is live.",
            "flipto.account.SlugLongInstructions": "The slug is used in the account app to identify the property, and in the platform it is used to identify property-related assets. It is important that the slug is not modified after the property is live or bad things can happen. Use dashes to separate words. Avoid spaces and special characters (! @ # $ % ^)",
            "flipto.account.ReminderEmails": "Reminder Emails",
            "flipto.account.ReminderNonViewTab": "If invitation was not viewed",
            "flipto.account.ReminderAfterViewTab": "If invitation was not clicked through",
            "flipto.account.ReminderEmailsTip": "Send a reminder email to guests who received the Invitation Email but did not click through to the Post-stay Process. <strong>Pro tip:</strong> A guest will never receive more than one followup email for Post-stay Advocacy (reminder or re-engagement) so you can be sure they won’t feel spammed.",
            "flipto.account.ReengagementEmailsTip": "Send a re-engagement email to guests who visited the Post-stay Process but did not complete every step. <strong>Pro tip:</strong> A guest will never receive more than one follow-up email for Post-stay Advocacy (reminder or re-engagement) so you can be sure they won’t feel spammed.",
            "flipto.account.ReengagementEmailsRule": "This touchpoint requires Story Explorer to be turned ON.",
            "flipto.account.InvitationEmail": "Post-stay Invitation Email",
            "flipto.account.ReengagementEmails": "Re-engagement Emails",
            "flipto.account.ReengagementNoQuoteTab": "If quote was not submitted",
            "flipto.account.ReengagementNoPhotoTab": "If photo was not submitted",
            "flipto.account.PoststayEmailImage": "Banner for Post-stay Email",
            "flipto.account.PhotoRiver": "Story River", //TODO: swap all instances of flipto.account.PhotoRiver with flipto.account.StoryRiver
            "flipto.account.StoryRiver": "Story River",
            "flipto.account.Heading": "Heading",
            "flipto.account.Message": "Message",
            "flipto.account.Description": "Description",
            "flipto.account.GalleryLinkMessage": "Gallery link message",
            "flipto.account.Desktop": "Desktop",
            "flipto.account.Mobile": "Mobile",
            "flipto.account.ShowBookNowButtonInNav": "Show &ldquo;book now&rdquo; button in navigation",
            "flipto.account.BookNow": "Book now",
            "flipto.account.ShowFooter": "Show footer",
            "flipto.account.FooterContent": "Footer content",
            "flipto.account.LandingPageConfigurationSubHeading": "Choose where to send audiences after they click on a guest’s share link.",
            "flipto.account.LandingPageIntegratedDescription": "Integrate the landing page into your existing website–all you’ll need to add to your site code is a simple Flip.to script.",
            "flipto.account.LandingPageCustomDescription": "Let us set up a completely custom page - just contact your Account Manager to get started.",
            "flipto.account.LandingPageHostedDescription": "We’ll host a rich landing experience that shows friends what’s in store for your guest’s upcoming trip and highlights captivating stories from your library.",
            "flipto.account.Status": "Status",
            "flipto.account.HostedExperience": "Hosted experience",
            "flipto.account.SwitchToHosted": "Switch to hosted experience?",
            "flipto.account.SwitchToHostedDescription": "Your guests' friends will be directed to the hosted experience. The current experience will automatically be turned off.",
            "flipto.account.IntegratedExperience": "Integrated experience",
            "flipto.account.SwitchToIntegrated": "Switch to integrated experience?",
            "flipto.account.SwitchToIntegratedDescription": "Your guests' friends will be directed to the integrated experience. The current experience will automatically be turned off.",
            "flipto.account.CustomExperience": "Custom experience",
            "flipto.account.SwitchToCustom": "Switch to custom experience?",
            "flipto.account.SwitchToCustomDescription": "Your guests' friends will be directed to the custom experience. The current experience will automatically be turned off.",
            "flipto.account.MakeSwitch": "Make the switch",
            "flipto.account.LandingPage": "Landing page",
            "flipto.account.Create": "Create",
            "flipto.account.LandingPageFor": "Landing page for {{name}}:",
            "flipto.account.CustomizedPage": "Customized page",
            "flipto.account.CustomizedPageFor": "Customized page for {{name}}",
            "flipto.account.CustomizedPageDescription": "Create an entirely custom page using your own HTML",
            "flipto.account.CustomizedPageDescriptionFor": "Create an entirely custom page using your own HTML. You can set up a unique page for each language, or leave it blank and it will default to the code you provide for {{name}}.",
            "flipto.account.Subject": "Subject",
            "flipto.account.Body": "Body",
            "flipto.account.Footer": "Footer",
            "flipto.account.CertificateEmail": "Certificate Email",
            "flipto.account.CertificateReminder": "Certificate Reminder",
            "flipto.account.Communication": "Communication",
            "flipto.account.Roles": "Roles",
            "flipto.account.GeneralManager": "General Manager",
            "flipto.account.AssignGM": "Set the General Manager to represent your org when communicating with travelers on websites and email. This overrides the Communication Point Person.",
            "flipto.account.DirectorCustomerService": "Communications Point Person",
            "flipto.account.AssignDCS": "Choose a team member to be the friendly face for your org when communicating with travelers on websites and email.",
            "flipto.account.ContestContent": "Contest content",
            "flipto.account.ContestContentSummary": "This is the message that will appear for the {contest-content} token. It will only be included with the email if you have Story Explorer active.",
            "flipto.account.CallToAction": "Call to action",
            "flipto.account.CallToActionSummary": "This is the text that will appear in the main call to action of the email.",
            "flipto.account.IsPhotoTableEnabled": "Photo table enabled",
            "flipto.account.IsPhotoTableEnabledSummary": "This flag denotes whether the photo table will be displayed when available on the email.",

            "flipto.account.CertificateEmailHeading": "The Certificate Email will be sent to travelers who complete an action and earn a bonus. The email is personalized for different kinds of travelers based on how they've been introduced to your brand.",
            "flipto.account.CertificateEmailCarouselContent.AnonymousPhotoSignupCertificate": "A <b>website visitor</b> showed some love for a guest's story.",
            "flipto.account.CertificateEmailCarouselContent.FriendPhotoSignupCertificate": "A <b>friend</b> voted for a guest's story.",
            "flipto.account.CertificateEmailCarouselContent.FriendPrestayHomepageSignupCertificate": "A <b>friend</b> learned about a guest's upcoming trip.",
            "flipto.account.CertificateEmailCarouselContent.FriendPoststayHomepageSignupCertificate": "A <b>friend</b> learned about a guest's recent stay.",
            "flipto.account.CertificateEmailCarouselContent.GuestPrestayPromotionCertificate": "A <b>guest</b> shared about an upcoming stay.",
            "flipto.account.CertificateEmailCarouselContent.GuestPoststayPromotionCertificate": "A <b>guest</b> shared about a recent stay.",
            "flipto.account.CertificateEmailCarouselContent.GuestPhotoFinalistCertificate": "A <b>participant</b> was approved for a storyteller campaign<br>(Optional incentive for finalists, can be set up in your campaign)",
            "flipto.account.ReminderEmailHeading": "The Certificate Reminder will be sent to travelers who have not used their certificate. The email is personalized for different kinds of travelers based on how they've been introduced to your brand.",
            "flipto.account.CertificateEmailCarouselContent.AnonymousPhotoSignupCertificateReminder": "A <b>website visitor</b> showed some love for a guest's story.",
            "flipto.account.CertificateEmailCarouselContent.FriendPhotoSignupCertificateReminder": "A <b>friend</b> voted for a guest's story.",
            "flipto.account.CertificateEmailCarouselContent.FriendPrestayHomepageSignupCertificateReminder": "A <b>friend</b> learned about a guest's upcoming trip.",
            "flipto.account.CertificateEmailCarouselContent.FriendPoststayHomepageSignupCertificateReminder": "A <b>friend</b> learned about a guest's recent stay.",
            "flipto.account.CertificateEmailCarouselContent.GuestPoststayPromotionCertificateReminder": "A <b>guest</b> shared about a recent stay.",
            "flipto.account.CertificateEmailCarouselContent.GuestPhotoFinalistCertificateReminder": "A <b>participant</b> was approved for a storyteller campaign<br>(Optional incentive for finalists, can be set up in your campaign)",
            "flipto.account.CertificateEmailCarouselContent.DefaultCertificate": "A <b>traveler</b> earned a certificate<br>(Generic message that can be sent to anyone)",
            "flipto.account.CertificateEmailCarouselContent.DefaultCertificateReminder": "A <b>traveler</b> earned a certificate<br>(Generic message that can be sent to anyone)",

            "flipto.account.InvitationBackground": "Invitation background",
            "flipto.account.InvitationTitle": "Invite travelers to share their story",
            "flipto.account.InvitationSubtitle": "Reach out to potential storytellers and get more submissions through your Invitation Page. Edit your invitation below, or broadcast to your audience on the social channels below.",
            "flipto.account.CustomizeYourInvitationPage": "Customize your Invitation Page",
            "flipto.account.CustomizeYourInvitationPageDescription": "Set up the best experience for your audience. Edit the details below and click Live preview to see what it will look like.",
            "flipto.account.Refresh": "Refresh",
            "flipto.account.Shuffle": "Shuffle",
            "flipto.account.ContestInvitationDefaultShareMessage": "Our storyteller campaign is now underway! Click through and share a favorite moment for a shot at some great prizes.",
            "flipto.account.FacebookContestInvitationPostInstructions": "We'll post your message and an image to Facebook on your behalf. The image will appear as a link with a unique Invitation Page URL for this audience.",
            "flipto.account.TwitterContestInvitationPostInstructions": "We'll post your message and an image to Twitter on your behalf. A unique link to the Invitation Page will be added to the end of your message.",
            "flipto.account.LinkedinContestInvitationPostInstructions": "We'll post your message and an image to LinkedIn on your behalf. The image will appear as a link with a unique Invitation Page URL for this audience.",
            "flipto.account.Stories": "Stories",
            "flipto.account.People": "People",
            "flipto.account.SearchGuests": "Search by name, email, or reservation #",
            "flipto.account.PropertyPhotos": "Property Photos",
            "flipto.account.OtherMedia": "Other Media",
            "flipto.account.Remove": "Remove",
            "flipto.account.Guest": "Guest",
            "flipto.account.Guests": "Guests",
            "flipto.account.Advocate": "Advocate",
            "flipto.account.Advocates": "Advocates",
            "flipto.account.BuddingAdvocate": "Budding advocate",
            "flipto.account.BuddingAdvocates": "Budding advocates",
            "flipto.account.Storyteller": "Storyteller",
            "flipto.account.Storytellers": "Storytellers",
            "flipto.account.WeddingStorytellers": "Wedding storytellers",
            "flipto.account.TeamStorytellers": "Team storytellers",
            "flipto.account.Friend": "Friend",
            "flipto.account.Friends": "Friends",
            "flipto.account.Visitor": "Visitor",
            "flipto.account.Visitors": "Visitors",
            "flipto.account.SocialConnections": "Social connections",
            "flipto.account.FriendsReached": "Friends reached",
            "flipto.account.PeopleReached": "People reached",
            "flipto.account.TravelersReached": "Travelers reached",
            "flipto.account.WarmLeads": "Warm leads",
            "flipto.account.WarmLeadsEarned": "Warm leads earned",
            "flipto.account.Clarity": "Clarity",
            "flipto.account.Library": "Library",
            "flipto.account.LearnMore": "Learn more",
            "flipto.account.Broadcast": "Broadcast",
            "flipto.account.Recommended": "Recommended",

            // Audiences
            "flipto.account.GuestsAndFriends": "Guests & friends",
            "flipto.account.WebsiteVisitors": "Website visitors",
            "flipto.account.FansAndFollowers": "Fans & followers",
            "flipto.account.Wedding": "Wedding",
            "flipto.account.Team": "Team",
            // Audiences - Storytellers (guests, wedding, team)
            "flipto.account.AfterYourGuestsStay": "After your guest's stay...",
            "flipto.account.TurnGuestsIntoPowerfulStorytellers": "Turn guests into powerful storytellers",
            "flipto.account.CocreateNewExperiencesWithYourGuests": "Co-create new experiences with your guests that introduce your hotel to new audiences in the best way imaginable.",
            "flipto.account.LetYourGuestsStoriesPowerAConversionMachine": "Let your guests' stories power a conversion machine that introduces their most memorable moments at your hotel to a captive audience of friends and family who are just one degree away.",
            "flipto.account.YoullGrowYourReachAndEarnNewWarmLeadsAlmostInstantly": "You'll grow your reach and earn new warm leads almost instantly. And since your storytellers will be actively promoting their own stories, you'll reap the benefits with minimal effort.",
            "flipto.account.EngageGuestsWhoHaveHadPositiveMemoriesWithYourBrand": "Engage guests who have had positive memories with your brand and they will play a key role in co-creating entirely new experiences for your traveler audience. Create a new campaign to get started.",
            "flipto.account.AfterYourGuestsWeddingEvent": "After your guest's wedding event...",
            "flipto.account.TurnWeddingGuestsIntoPowerfulStorytellers": "Turn wedding guests into powerful storytellers",
            "flipto.account.ForYourTeamByYourTeam": "For your team. By your team...",
            "flipto.account.DiscoverStoriesFromYourTeam": "Discover insightful stories from your team.",
            "flipto.account.TeamBuildingAndManyMoreBenefits": "Team-building and many more benefits are abound just by letting your team share their personal stories.",
            "flipto.account.LetYourTeamStoriesGiveAGlimpse": "Let your team members give a glimpse at life in hospitality, through stories told from their perspective.",
            "flipto.account.CreateACampaignForYourTeam": "Storyteller campaigns are great for sharing fun stories within your team as well capturing moments from a company event. You can limit it to fellow team members and their friends and family, or open it up to share with your wider travel audience.",
            "flipto.account.GetYourTeamInvolvedToTellTheirStories": "Get your team involved to tell their stories and let their personalities shine. Create a new campaign to get started.",
            // Audiences - Budding advocates
            "flipto.account.BeforeYourGuestsStay": "Before your guest's stay...",
            "flipto.account.TurnGuestsIntoBuddingAdvocates": "Turn guests into budding advocates",
            "flipto.account.KickStartYourGuestsPathToAdvocacyEvenBeforeTheirTrip": "Kick start your guest's path to advocacy even before their trip. They'll share their excitement as they introduce your hotel to friends and family.",
            "flipto.account.DontBeFooledByTheNameABuddingAdvocateHasTremendousPotential": "Don't be fooled by the name, a budding advocate has tremendous potential to direct more travelers to your hotel. A little excitement about their upcoming trip goes a long way with Advocacy, and turns into an impactful introduction to your hotel for travelers in their circle of friends and family.",
            "flipto.account.TheValueOfBuddingAdvocates": "The value of budding advocates",
            // Audiences - Story River (guests, wedding)
            "flipto.account.ForTravelersInPlanningMode": "For travelers in planning mode...",
            "flipto.account.ForTravelersWhoArePlanningTheirWedding": "For travelers who are planning their wedding...",
            "flipto.account.InspireWithStoryRiver": "Inspire with Story River",
            "flipto.account.ShowYourWebsiteVisitorsAuthenticTravelStories": "Show your website visitors authentic travel stories that resonate with their travel aspirations.",
            "flipto.account.ASteadyFlowOfCurrentAuthenticStoriesShowsWebsiteVisitors": "A steady flow of current, authentic stories shows website visitors the real side of your hotel. Because there's a story for everyone, it's the best way to get visitors to imagine themselves at your hotel.",
            "flipto.account.VisitorsSawYourStoryRiver": "visitors saw your Story River",
            "flipto.account.CheckedOutAStory": "checked out a story",
            // Audiences - HPC
            "flipto.account.UnlockFutureAdvocatesWithHPC": "Unlock future advocates with HPC",
            "flipto.account.GiveVisitorsWhoAreNotReadyToBookNowAnEasyWayBack": "Give visitors who are not ready to 'Book Now' an easy way back for when they've decided, plus get them primed for advocacy.",
            "flipto.account.MostWebsiteVisitorsComeToYourSiteToResearch": "Most website visitors come to your site to research their options, and only a fraction are ready to book. For the majority, the Homepage Component provides an opportunity to unlock a bonus when they are ready, giving your visitor a compelling reason to come back and book direct.",
            "flipto.account.VisitorsSawYourHPC": "visitors saw your HPC",
            // Audiences - Fans & followers
            "flipto.account.BroadcastWorthy": "Broadcast-worthy",
            "flipto.account.ShareASeasonalTravelStory": "Share a seasonal travel story",
            "flipto.account.GetTravelersThinkingAboutYourHotelWithTimelyStories": "Get travelers thinking about your hotel with timely stories from real guests.",
            "flipto.account.HereAreTheHighestRatedStoriesFromGuestsWhoStayedAroundTheSameTime": "Here are the highest rated stories from guests who stayed around the same time last year. They're perfect for showing your audience all there is to enjoy during this season.",
            "flipto.account.ShareAGuestStory": "Share a guest story",
            "flipto.account.HighlightAStoryFromACurrentCampaign": "Highlight a story from a current campaign",
            "flipto.account.YouHaveNothingToBroadcastAtTheMoment": "You have nothing new to broadcast at the moment. Please check again in a few days.",
            "flipto.account.AfterYouReceiveSomeNewStoriesWellBringYouTheHighestRated": "After you receive some new stories, we'll bring you the highest rated stories that you can share to your fans and followers.",
            "flipto.account.AfterYouReceiveSomeNewStoriesWellBringYouTheHighestRatedFromCampaigns": "After you receive some new stories, we'll bring you the highest rated stories from current campaigns that you can share to your fans and followers.",
            "flipto.account.HereAreTheHighestRatedStoriesFromYourLibrary": "Here are the highest rated stories from your library that you haven't shared before. Stories provide great content for your fans, revealing an authentic view of your guest experience and rekindling memories for travelers who've stayed with you before.",
            "flipto.account.HereAreTheHighestRatedStoriesFromYourCurrentCampaigns": "Here are the highest rated stories from your current campaign that you haven't shared before. Show your fans the latest and greatest from your guests and encourage them to explore all the stories and vote for their favorites.",
            "flipto.account.ToSeeAllYourStoriesGoToYour": "To see all your stories, go to your",
            "flipto.account.InviteTravelersToTellTheirOwnStory": "Invite travelers to tell their own story",
            "flipto.account.HereAreTheLatestCampaignsThatAreRunning": "Here are the latest campaigns that are running. If your campaign can use a boost, invite your fans to participate and share their story.",
            // Variation modal
            "flipto.account.YourGuestsAndTheirFriendsCanEarnIncentives": "Incentivize your guests to become advocates and their friends to become warm leads.",
            "flipto.account.YourWebsiteVisitorsCanEarnIncentives": "Incentivize your website visitors to become warm leads.",
            "flipto.account.OnlyTheFirstVariationBelowThatFitsYourGuestsProfile": "Only the first variation below that fits your guest's profile will apply to their journey.",
            "flipto.account.DisplayingStatsFor": "Displaying stats for",


            "flipto.account.SampleScreenshot": "Sample screenshot",

            "flipto.account.ThisFeatureIsOff": "This feature is OFF",
            "flipto.account.ContactYourAccountManagerToLaunchThisFeature": "Contact your account manager to launch this feature.",
            "flipto.account.ThisFeatureIsNotActive": "This feature is not active",
            "flipto.account.ContactYourAccountManagerToActivateThisFeature": "Contact your account manager to activate this feature.",

            "flipto.account.CreateANewCampaign": "Create a new campaign",
            "flipto.account.CreateANewVariation": "Create a new variation"

        });
    }]);
