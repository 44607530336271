/**
 * Created by Roman on 2016-05-05.
 */


import * as signalR from '@microsoft/signalr';


angular.module("flipto.core.signalr", [])
    .provider("emailBannerUpdates", [function () {

        var signalrUrl;

        this.setSignalrUrl = function (url) {
            signalrUrl = url;
        };

        this.$get = ["$rootScope", function ($rootScope) {
            return new EmailBannerUpdatesService(signalrUrl, $rootScope);
        }];


        function EmailBannerUpdatesService(url, $rootScope) {
            this.url = url;
            this.$rootScope = $rootScope;
        }

        EmailBannerUpdatesService.prototype.startReceiving = function () {
            if (!this.connection || (this.connection.state !== 'Connected' && this.connection.state !== 'Connecting')) {
                this.connection = new signalR.HubConnectionBuilder().withUrl(this.url).build();
                this.connection.on('completed', angular.bind(this, this.completed));
                this.connection.start();
            }
        };

        EmailBannerUpdatesService.prototype.stopReceiving = function () {
            if (!!this.connection) {
                this.connection.stop();
            }
        };

        EmailBannerUpdatesService.prototype.completed = function (signal) {
            this.$rootScope.$broadcast("accountapp.email-banner-updates.completed", signal);
        };

    }]);
