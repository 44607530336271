
export function tokenInjectorInterceptor() {
    return {
        request: function (config) {
            if (config.url.indexOf("/api") !== -1) {
                config.withCredentials = true;
            }
            return config;
        }
    };
}

export namespace tokenInjectorInterceptor {
    export let $inject = [
    ];
}