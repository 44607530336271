/**
 * Created by roman.kupin on 9/25/2017.
 */
import {ContextSelectorService} from "./contextSelector.service";
import ContextSelectorActions from "./actions/index";
import {ContextSelectorSetup} from "./contextSelector.setup";

export const NAME = "ftContextSelector";

export class ContextSelectorController {

    static $inject = ["$ngRedux", "ftModal", 'IdentityService', "$rootScope"];

    private unsubscribeActions: Function;
    unsubscribeWatcherFn: Function;
    _id: string;
    contextSelectorSetup: ContextSelectorSetup;
    searchTerm: string = "";
    isFliptoStaff: boolean;
    isCancelVisible: boolean = true;
    contextGroups: Array<any>;

    constructor(public $ngRedux,
                public ftModal,
                public identity,
                public $rootScope) {
        this.isFliptoStaff = identity.isFliptoStaff();
    }

    set id(id: string) {
        this._id = id;
        this.unsubscribeActions = this.$ngRedux.connect(null/*(state) => {
            const contextGroups = state.ui.contextSelector[id].contextGroups;
            return {
                contextGroups
            };
        }*/, ContextSelectorActions)(this);
        this.unsubscribeWatcherFn = this.$rootScope.$watch(() => {
            const state = this.$ngRedux.getState();
            const contextSelectorSetup = <ContextSelectorSetup>state.ui.contextSelector[id];
            return !!contextSelectorSetup && contextSelectorSetup.isActive;
        }, (isActive) => {
            if (isActive) {
                this.ftModal.show(`modal-${id}`);
            } else {
                this.ftModal.hide(`modal-${id}`);
                this.searchTerm = "";
            }
        });
    }

    $onDestroy() {
        this.unsubscribeActions();
        this.unsubscribeWatcherFn();
    }

}

export const DEFINITION = ["$q", "$templateCache", "$http", "$compile", ContextSelectorService.Name, function ($q, $templateCache, $http, $compile, contextSelectorService) {
    return {
        scope: true,
        bindToController: true,
        controllerAs: "$ftContextSelector",
        controller: ContextSelectorController,
        require: "ftContextSelector",
        compile: function (tElem, tAttrs) {

            let html = tElem.html();
            tElem.html(contextSelectorService.Template.replace("[id]", `modal-${tAttrs.id}`).replace("[content]", html));

            return function (scope, elem, attrs, ctrl: ContextSelectorController) {
                ctrl.id = attrs.id;

                scope.$watch(() => scope.$eval(attrs.cancelVisible), (isCancelVisible) => {
                    ctrl.isCancelVisible = isCancelVisible;
                });
            }
        }

    };
}];
