export function sortDefaultAccountLanguages(items: any[]) {
    if (Array.isArray(items)) {
        return items?.sort((a, b) => {
            if (a?.languageCode === 'en') return -1;
            if (b?.languageCode === 'en') return 1;
            return a?.languageName?.localeCompare(b?.languageName);
        });
    }
    return items;
}
