/**
 * Created by Roman on 2014-09-12.
 */



angular.module('flipto.components.common',
    [
        'flipto.components.common.focus',
        'flipto.components.common.navigation',
        'flipto.components.common.panel',
        'flipto.components.common.dateRange',
        'flipto.components.common.datePicker',
        'flipto.components.common.snapshot',
        'flipto.components.common.modal',
        'flipto.components.common.codeEditor',
        'flipto.components.common.switch',
        'flipto.components.common.objectList',
        'flipto.components.common.enter',
        'flipto.components.common.listEditor',
        'flipto.components.common.pendingChanges',
        'flipto.components.common.fieldEditors',
        'flipto.components.common.modalFieldsEditor',
        'flipto.components.common.languageSelector',
        'flipto.components.common.select',
        'flipto.components.common.navigationPane',
        'flipto.components.common.modelFormatters',
        'flipto.components.common.list',
        'flipto.components.common.sidenav',
        'flipto.components.common.contextualNavigation',
        'flipto.components.common.rotate',
        'flipto.components.common.shortcuts',
        'flipto.components.common.images',
        'flipto.components.common.placeholder',
        'flipto.components.common.stats',
        'flipto.components.common.textFormatting',
        'flipto.components.common.map',
        'flipto.components.common.date',
        'flipto.components.common.sortable',
        'flipto.components.common.download',
        'flipto.components.common.validation',
        'flipto.components.common.brackets',
        'flipto.components.common.disabled',
        'flipto.components.common.changesFilters',
        'flipto.components.common.notifications',
        'flipto.components.common.infiniteScroll',
        'flipto.components.common.userAgent',
        'flipto.components.common.fileUpload',
        'flipto.components.common.spinner',
        'flipto.components.common.faceHighlighter',
        'flipto.components.common.eventsRelated',
        'flipto.components.common.carousel',
        'flipto.components.common.iframeContent',
        'flipto.components.common.loadingAnimation',
        'flipto.components.common.uiAce',
        'flipto.components.common.scroll',
        'flipto.components.common.modelOperations'
    ]);