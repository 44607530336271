/**
 * Created by Roman on 2015-03-13.
 */


angular.module('flipto.components.common.modelFormatters')
    .directive('ftPlainText', function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, modelCtrl) {
                var plainText = scope.$eval(attrs.ftPlainText);
                var enabled = plainText === '' || angular.isUndefined(plainText) || plainText === true;

                enabled && modelCtrl.$parsers.push(function (inputValue) {
                    return inputValue.replace(/<[^>]+>/gmi, ' ');
                });
            }
        };
    });
