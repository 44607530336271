/**
 * Created by Roman on 2014-11-28.
 */


angular.module('flipto.accountv1.SharedFeatures')
    .directive('ftWinnerSummary', ['$animate', '$compile', '$filter', function ($animate, $compile, $filter) {

        var translate = $filter('translate'),
            summaryTemplate = '<div class="score count">{{score}}</div>' +
                '<div class="photo-info winner" ng-class="{\'first-place\': winnerType == 1, \'second-place\': winnerType == 2, \'third-place\': winnerType == 3, \'runner-up\': winnerType == 100}">' +
                '<ft-image src="profileImage" ft-profile-photo></ft-image>' +
                '<div class="ft-label"><i class="icon"></i>{{getTypeDescription(winnerType)}}</div>' +
                '</div>',
            winnerTypeMap = {
                0: '',
                1: translate('flipto.account.sections.platform.photoAdvocacy.1STPlace'),
                2: translate('flipto.account.sections.platform.photoAdvocacy.2NDPlace'),
                3: translate('flipto.account.sections.platform.photoAdvocacy.3RDPlace'),
                100: translate('flipto.account.sections.platform.photoAdvocacy.RunnerUp')
            };

        function getTypeDescription(type) {
            return winnerTypeMap[type];
        }

        return {
            restrict: 'EA',
            link: function (scope, elem, attrs) {

                scope.$watch(function () {
                    return scope.$eval(attrs.ftWinnerSummary);
                }, showSummary, true);
                var summary;

                function showSummary(finalist) {
                    if(!!summary) $animate.leave(summary);
                    if (!finalist)return;

                    var childScope = angular.extend(scope.$new(true), {
                        profileImage: finalist['@profileImageUrl'],
                        winnerType: finalist['@winnerTypeID'],
                        score: finalist['@score'],
                        getTypeDescription: getTypeDescription
                    });

                    summary = $compile(summaryTemplate)(childScope);
                    $animate.enter(summary, elem);
                }
            }
        };
    }])
    .filter('winnerPlace', ['parse', '$filter', function (parse, $filter) {

        var translate = $filter('translate');
        var map = {
            1: translate('flipto.account.sections.platform.photoAdvocacy.1STPlace'),
            2: translate('flipto.account.sections.platform.photoAdvocacy.2NDPlace'),
            3: translate('flipto.account.sections.platform.photoAdvocacy.3RDPlace'),
            100: translate('flipto.account.sections.platform.photoAdvocacy.RunnerUp')
        };

        return function (place) {
            return map[parse.int(place)];
        }
    }]);