/**
 * Created by Roman on 2014-09-23.
 */

angular.module('flipto.components.textAngularTools', ['textAngular'])
    .config(['$compileProvider', function ($compileProvider) {

        $compileProvider.directive('ftAppendInsertTokenTool', ['$compile', '$document', 'textAngularManager', function ($compile, $document, textAngularManager) {

            var toolbarTemplate = '<ul class="ft-dropdown-menu ft-toolbar-menu"><li ng-repeat="token in tokens"><a ng-click="$event.preventDefault(); insertToken(token);">{{token.text || token}}</a></li></ul>';
            return {
                restrict: 'A',
                compile: function (tElem) {
                    var targetTagName = tElem[0].tagName.toLowerCase();
                    if (targetTagName !== 'text-angular-toolbar') throw 'Element is not supported';

                    return function (scope, elem, attrs) {

                        if (angular.isDefined(scope.tokens) === false ||
                            scope.tokens.length === 0) return;

                        var toolId = 'insertToken' + (Math.floor(Math.random() * 10000)),
                            toolScope;
                        textAngularManager.addToolToToolbar(toolId, {
                            display: '<div class="insert-token ft-dropdown opens-left" ft-click-class toggle-class="active"><div class="ft-dropdown-toggle">Insert Token <i class="ft-icn-caret-down"></i></div></div>',
                            action: function (deffer) {
                                if (!toolScope) {
                                    var editor = this.$editor();
                                    var toolbarElement = this.$element;
                                    toolScope = angular.extend(scope.$new(true), {
                                        tokens: scope.tokens,
                                        visible: true,
                                        insertToken: function (token) {
                                            if (angular.isString(token)) {
                                                editor.wrapSelection('insertHTML', '{' + token + '}', true);
                                            } else if (angular.isObject(token) && !!token.wrapper) {
                                                editor.wrapSelection('insertHTML', '<' + token.wrapper + '>{' + token.text + '}</' + token.wrapper + '>', true);
                                            }
                                        }
                                    });
                                    toolbarElement.append($compile(toolbarTemplate)(toolScope));
                                }
                                return true;
                            }
                        }, attrs.name);

                        scope.$on('$destroy', function () {
                            textAngularManager.removeTool(toolId);
                        });

                    }
                }
            };
        }
        ]);

        $compileProvider.directive('ftShowToolbarOnFocus', ['$compile', '$document', '$timeout', '$rootScope', function ($compile, $document, $timeout, $rootScope) {
            return {
                restrict: 'A',
                compile: function (tElem, tAttrs) {
                    var targetTagName = tElem[0].tagName.toLowerCase();
                    if (targetTagName !== 'text-angular-toolbar') throw 'Element is not supported';
                    tElem.addClass('ng-hide');

                    return function (scope, elem, attrs) {
                        var isFocused = false;
                        scope.$watch(function () {
                            return elem.attr('class');
                        }, function (newValue, oldValue) {
                            var focus = newValue ? newValue.indexOf('focussed') !== -1 : false;
                            if (isFocused === focus) return;

                            isFocused = focus;
                            var timeout = isFocused ? 0 : 130;

                            $timeout(function () {
                                if (isFocused) {
                                    elem.removeClass('ng-hide');
                                    $rootScope.$broadcast('textToolbar.focused', elem);
                                }
                            }, timeout);
                        });

                        scope.$on('textToolbar.focused', function (e, focusedElem) {
                            if (focusedElem !== elem) {
                                elem.addClass('ng-hide');
                            }
                        });

                    }
                }
            };
        }
        ]);

    }]);