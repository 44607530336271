/**
 * Created by Roman on 2014-10-07.
 */


angular
    .module('flipto.components.common.dateRange')
    .filter('dateFormat', [function () {

        var defaultFormat = 'MMM D';
        return function (date, format, isUtc) {
            isUtc = angular.isDefined(isUtc) ? isUtc : true;
            format = format || defaultFormat;
            var momentDate = isUtc ? moment.utc(date) : moment(date);
            return momentDate.format(format);
        }
    }]);