/**
 * Created by Kevin Tiller on 2/19/2017
 */


angular.module('flipto.accountv1.SharedFeatures')
    .controller('flipto.account.sections.admin.reports.AdminReportsController', [
        '_', 'Reports', 'dateSerializer', 'snapshot', 'urlFormatter', 'IdentityService', 'SpacesService',
        function (_, Reports, dateSerializer, snapshot, urlFormatter, identity, spacesService) {

            var vm = this;
            vm.identity = identity;
            angular.extend(vm, {
                snapshot: {
                    start: snapshot.get().start, end: snapshot.get().end
                },
                reports: []
            });


            vm.dateRangeChanged = function (dateRange) {
                vm.snapshot.start = dateRange.fromDate;
                vm.snapshot.end = dateRange.toDate;
                vm.reports.forEach(function(report) {
                    report.url = vm.getUrl(report.id);
                })
            };

            vm.getUrl = function (id) {
                var params = {
                    reportUUID: id,
                    startDate: vm.snapshot.start && dateSerializer(vm.snapshot.start),
                    endDate: vm.snapshot.end && dateSerializer(vm.snapshot.end)
                };

                return urlFormatter.format('/api/admin/report', params, true);
            };

            vm.onFilterChanged = function(report) {
                report.url = vm.getUrl(report.id);
            };

            Reports.getAdminReports()
                .then(function (reportResponse) {
                    angular.forEach(reportResponse.data, function (report) {
                        vm.reports.push({
                            id: report.UUID,
                            name: report.Name,
                            description: report.Description,
                            url: vm.getUrl(report.UUID)
                        });
                    });

                }, function (takeAction) {
                    alert("Unable to retrieve admin reports at this time");
                });/*
            Reports.getGaJson().then(function (response) {
                //alert(JSON.stringify(response.data));
            });
            Reports.getAuthToken()
                .then(function (response) {
                    gapi.analytics.ready(function () {
                        // Step 3: Authorize the user.

                        //var CLIENT_ID = '639278798175-7ecuk3ppjlgthjlg3h7sg2h96nt5f6lg.apps.googleusercontent.com';

                        //gapi.analytics.auth.authorize({
                        //    container: 'auth-button',
                        //    clientid: CLIENT_ID,
                        //});
                        gapi.analytics.auth.authorize({'serverAuth': {'access_token': response.data}});

                        // Step 4: Create the view selector.

                        var viewSelector = new gapi.analytics.ViewSelector({
                            container: 'view-selector'
                        });

                        // Step 5: Create the timeline chart.

                        var timeline = new gapi.analytics.googleCharts.DataChart({
                            reportType: 'ga',
                            query: {
                                'dimensions': 'ga:date',
                                'metrics': 'ga:sessions',
                                'start-date': '30daysAgo',
                                'end-date': 'yesterday',
                            },
                            chart: {
                                type: 'LINE',
                                container: 'timeline'
                            }
                        });

                        // Step 6: Hook up the components to work together.

                        viewSelector.execute();

                        viewSelector.on('change', function (ids) {
                            var newIds = {
                                query: {
                                    ids: ids
                                }
                            }
                            timeline.set(newIds).execute();
                        });
                    });
                }, function (takeAction) {
                    //alert("Unable to retrieve Google Analytics data at this time");
                });*/
        }
    ]);
