import {
    loadCampaignGuestsSuccess,
    loadCampaignGuestsFail,
    loadCampaignGuests,
    saveCampaignGuests,
    saveCampaignGuestsSuccess,
    saveCampaignGuestsFail
} from './actions';
import { Action, createReducer, on } from "@ngrx/store";
import { State } from "./state";

export const reducer = createReducer(
    {
        loading: false,
        settings: null,
        commitSuccess: false
    } as State,
   // styling
   on(loadCampaignGuests, (state) => {
    return {
        ...state,
        loading: true
    };
    }),
    on(loadCampaignGuestsSuccess, (state, {data}) => {
        return {
            ...state,
            loading: false,
            settings: data
        };
    }),
    on(loadCampaignGuestsFail, (state, {error}) => {
        return {
            ...state,
            loading: false,
            error
        };
    }),
    on(saveCampaignGuests, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(saveCampaignGuestsSuccess, (state) => {
        return {
            ...state,
            loading: false
        };
    }),
    on(saveCampaignGuestsFail, (state, {error}) => {
        return {
            ...state,
            loading: false,
            error
        };
    })
);

export function campaignGuestsReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}
