/**
 * Created by ostapenko.r on 9/3/2014.
 */


angular.module('flipto.core.api.bookingEngines', [])
    .config(['$provide', function ($provide) {

        $provide.factory('BookingEngines', ['urlFormatter', '$http', function (urlFormatter, $http) {

            /**
             * BookingEngines service
             * @constructor
             */
            function BookingEngines() {

                /**
                 * Activate booking engine
                 * @param engine
                 * @returns {*}
                 */
                this.activate = function (propertyUUID, engine) {
                    return $http.put(urlFormatter.format('/api/' + propertyUUID + '/properties/layers/prestay/confirmation/bookingengines/:id/activate/:isActive', engine))
                        .then(function (response) {
                            return response.data;
                        });
                };

                /**
                 * Get available booking engines
                 * @returns {*}
                 */
                this.get = function () {
                    return $http.get('/api/bookingengines')
                        .then(function (response) {
                            return response.data;
                        });
                };

            }

            return new BookingEngines();
        }]);
    }]);