/**
 * Created by roman.kupin on 2/15/2017.
 */



angular.module('flipto.accountv1.SharedFeatures')
    .controller('flipto.account.sections.people.FeedbackController',
        [
            'tabs', 'Guests', '$scope', 'disableScrollWhenLocationChanges', '_', 'snapshot', "$state", 'SpacesService',
            function(tabs, Guests, $scope, disableScrollWhenLocationChanges, _, snapshot, $state, spacesService) {

                var vm = this;
                vm.tabs = tabs;
                vm.scrollDisabled = false;
                vm.isProcessingRequest = false;
                vm.feedbacks = [];
                var currentSnapshot = snapshot.get();

                vm.filters = [
                    {
                        name: 'Most recent',
                        query: {
                            SortBy: 'create_date',
                            SortDirection: 'desc',
                            Offset: 0,
                            Fetch: 24,
                            startDate: currentSnapshot.start,
                            endDate: currentSnapshot.end
                        }
                    },
                    {
                        name: 'Lowest rating',
                        query: {
                            SortBy: 'internal_rating_type_id',
                            SortDirection: 'asc',
                            Offset: 0,
                            Fetch: 24,
                            startDate: currentSnapshot.start,
                            endDate: currentSnapshot.end
                        }
                    },
                    {
                        name: 'Highest rating',
                        query: {
                            SortBy: 'internal_rating_type_id',
                            SortDirection: 'desc',
                            Offset: 0,
                            Fetch: 24,
                            startDate: currentSnapshot.start,
                            endDate: currentSnapshot.end
                        }
                    }
                ];

                /**
                 * Load photos
                 * @param query
                 */
                vm.load = function(query) {
                    vm.scrollDisabled = true;
                    vm.isProcessingRequest = true;
                    vm.currentQuery = query;
                    return Guests.feedback(spacesService.getPropertyUuid(), query)
                        .then(function(data) {
                            if ( vm.currentQuery !== query ) return;
                            var total = angular.isArray(data.stories) && data.stories.length > 0 ? data.stories[0].totalCount : 0;
                            vm.hasMoreData = total > (vm.filter.query.Offset + vm.filter.query.Fetch);
                            return data;
                        })
                        .finally(function() {
                            if ( vm.currentQuery !== query ) return;
                            vm.scrollDisabled = false;
                            vm.isProcessingRequest = false;
                        });
                };

                /**
                 * Load next page
                 */
                vm.loadNextPage = function() {
                    vm.filter.query.Offset += vm.filter.query.Fetch;
                    vm.load(angular.extend({}, vm.filter.query))
                        .then(function(data) {
                            vm.feedbacks.push.apply(vm.feedbacks, data.stories);
                        });
                };

                /**
                 * Change filter
                 * @param newFilter
                 */
                vm.changeFilter = function(newFilter) {
                    vm.scrollLoadsResetCounter = 0;
                    vm.feedbacks = [];
                    vm.filter = angular.copy(newFilter);
                    vm.load(angular.extend({}, vm.filter.query))
                        .then(function(data) {
                            if(!!data) {
                                vm.feedbacks = angular.isArray(data.stories) ? data.stories : [];
                                vm.ratings = adjustRatings(data.ratings);
                            }
                        });
                };

                vm.getTotalRatings = function() {
                    return _.reduce(vm.ratings, function(memo, rating) {
                        return memo + rating.total;
                    }, 0);
                };

                vm.getTotal = function() {
                    if ( vm.feedbacks.length > 0 ) return vm.feedbacks[0].totalCount;
                    return 0;
                };

                vm.details = function(guestFeedback) {
                    $state.go('flipto.property.overview.people.feedback.details', {advocateUUID: guestFeedback.promoter.guestUUID});
                };

                vm.dateRangeChanged = function(dateRange) {
                    vm.filter.query.startDate = dateRange.fromDate;
                    vm.filter.query.endDate = dateRange.toDate;

                    _.each(vm.filters, function(filter) {
                        filter.query.startDate = dateRange.fromDate;
                        filter.query.endDate = dateRange.toDate;
                    });

                    vm.filter.query.Offset = 0;
                    vm.changeFilter(vm.filter);
                };

                vm.disableScrollWhenLocationChanges = function() {
                    var scrollState = vm.scrollDisabled,
                        thisPage;
                    $scope.$watch(function() {
                        return $state.current;
                    }, function(current) {
                        if ( !thisPage ) thisPage = current;
                        if ( thisPage.name != current.name ){
                            scrollState = vm.scrollDisabled;
                            vm.scrollDisabled = !vm.scrollDisabled;
                        } else {
                            vm.scrollDisabled = scrollState;
                        }
                    })
                };

                vm.changeFilter(vm.filters[0]);
                disableScrollWhenLocationChanges && vm.disableScrollWhenLocationChanges();

                function adjustRatings(rawRatings) {
                    return _.map(rawRatings, function(raw) {
                        return {rating: raw.rating, percentage: raw.percentage, total: raw.ratingTotal};
                    });
                }

            }
        ]);
