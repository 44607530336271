import { loadPoststayProcess, loadPoststayProcessFail, loadPoststayProcessSuccess} from './actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { JourneysApiService } from '../../services/journeys-api.service';
import { BannerEmailSettings, BannerEmailStylingSettings } from '../../models/banner-email';
import { PoststayProcessSettings } from '../../models/poststay-process';

@Injectable()
export class PoststayProcessEffects {
    constructor(private actions$: Actions,
        private store$: Store<any>,
        private journeysApiService: JourneysApiService) {
    }

    load$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadPoststayProcess),
            mergeMap(({componentGroupUuid, propertyId}) =>
                this.journeysApiService.getTouchpointPoststayProcessData(componentGroupUuid, propertyId)
                    .pipe(
                        map((settings: PoststayProcessSettings) => {
                            return loadPoststayProcessSuccess({data: settings});
                        }),
                        catchError(error => of(loadPoststayProcessFail({error})))
                    ))
        )
    );

    // commit$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(commitBannerEmail),
    //         mergeMap(({componentGroupUuid, propertyId, isActive}) =>
    //             this.journeysApiService.commitTouchpointBannerEmail(componentGroupUuid, propertyId, isActive)
    //                 .pipe(
    //                     map((settings: BannerEmailSettings) => {
    //                         return commitBannerEmailSuccess({data: settings});
    //                     }),
    //                     catchError(error => of(commitBannerEmailFail({error})))
    //                 ))
    //     )
    // );

    // loadStyling$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(loadStylingBannerEmail),
    //         mergeMap(({componentGroupUuid, propertyId, typeRequest, state}) =>
    //             this.journeysApiService.getStylingBannerEmailData(componentGroupUuid, propertyId, typeRequest, state)
    //                 .pipe(
    //                     map((stylingSettings: BannerEmailStylingSettings) => {
    //                         return loadStylingBannerEmailSuccess({data: stylingSettings});
    //                     }),
    //                     catchError(error => of(loadStylingBannerEmailFail({error})))
    //                 ))
    //     )
    // );
}
