<div class="ft-sidebar-tabs" [ngClass]="classes">
    <div class="container">
        <div class="row">
            <div class="col-3">
                <ul class="ft-sidebar-tabs_nav">
                    <ng-container *ngFor="let item of tabs; let i = index">
                        <li *ngIf="!item.sectionName"
                            [class.-active]="activeTabIndex === i"
                            (click)="activeTabIndex = i; ">
                            {{item.name}}
                        </li>
                        <li *ngIf="item.sectionName">
                            {{ item.sectionName }}
                        </li>
                    </ng-container>
                </ul>
            </div>
            <div class="col-9">
                <div *ngFor="let item of tabs; let i= index">
                    <div *ngIf="activeTabIndex === i">
                        <ng-container *ngTemplateOutlet="item.template"></ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
