<section class="ft2">
    <ft-pending-changes [hasChanges]="hasChanges"
                        (cancel)="onCancel()"
                        (commit)="onSave()"
                        [loading$]="loading"
                        [disabled]="!form.valid">
    </ft-pending-changes>
    <form class="ft2-block"
          #form="ngForm"
          ngForm>
        <div class="ft2-container mb-3">
            <div class="row">
                <div class="col-4 mb-sm-2 col-md-12" *ngIf="languages?.length > 1">
                    <ft-language-preview-panel [languages]="languages"
                                               bindLabel="languageName"
                                               bindValue="languageCode"
                                               (previewLanguageChange)="onPreviewLanguageSelected($event)"
                                               [simplified]="true">
                    </ft-language-preview-panel>
                </div>
                <div
                    [ngClass]="{'col-8': languages?.length > 1, 'col-12': languages?.length <= 1}"
                    class="col-md-12">
                    <div class="row">
                        <div class="col-12" *ngIf="error">
                            <div class="ft2-form-error">
                                {{ error }}
                            </div>
                        </div>
                        <div class="col-12">
                            <p>
                                These properties are mapped to your org, connecting your platforms so you can work
                                collaborately to win over your audiences.
                            </p>
                        </div>
                    </div>
                    <div class="row v-center mt-5"
                         *ngIf="isFliptoStaff">
                        <div class="col-6">
                            <ft-select-editor title="Add property" [options]="properties"
                                              (save)="onAddProperty($event)"
                                              bindLabel="name"
                                              bindValue="propertyUuid">
                                <button class="ft2-btn-default -large"
                                        *ngIf="isFliptoStaff">
                                    <i class="ft-icn-plus"></i>&nbsp;Add property
                                </button>
                            </ft-select-editor>
                        </div>
                    </div>
                    <div *ngIf="associations?.length">
                        <div [ngClass]="{'new-association': cityGroup[0].justAdded}"
                             *ngFor="let cityGroup of associations | groupAssociation; index as i">
                            <div class="association mt-6 v-center f-wrap">
                                <p class="new-association-title mb-3 base-text" *ngIf="cityGroup[0].justAdded">
                                    Set how you want the property to work with your platform here. When you're ready,
                                    hit save &
                                    publish and these properties will be organized below by city. You can add more
                                    properties to set
                                    up at the same time.
                                </p>
                                <div class="full-width v-center mb-3" *ngIf="!cityGroup[0].justAdded">
                                    <div class="flex v-center">

                                        <div class="solid-text small-text mb-0"
                                             *ngIf="cityGroup[0].mappedCity">
                                            {{ cityGroup[0].mappedCity }}
                                        </div>
                                        <div class="solid-text small-text mb-0"
                                             *ngIf="!cityGroup[0].mappedCity && cityGroup[0].city">
                                            {{ cityGroup[0].city }}
                                        </div>
                                        <div class="solid-text small-text mb-0"
                                             *ngIf="!cityGroup[0].city && !cityGroup[0].mappedCity">
                                            Regional
                                        </div>
                                        <div class="solid-text small-text muted-text pl-2"
                                             *ngIf="cityGroup[0].mappedCity">
                                            ({{ cityGroup[0].city }})
                                        </div>
                                        <ft-input-editor title="Custom destination name"
                                                         [name]="'customDestionationName' + i"
                                                         *ngIf="cityGroup[0].mappedCity || cityGroup[0].city"
                                                         [(ngModel)]="cityGroup[0].mappedCity"
                                                         summary="Set a custom name to display for this destination, or leave it blank if you want to keep the default name that comes from the property’s address."
                                                         class="ml-1">
                                            <button class="ft2-btn-text">
                                                <mat-icon class="tiny-text ml-1">edit</mat-icon>
                                            </button>
                                        </ft-input-editor>
                                    </div>
                                    <div class="flex-auto ml-2">
                                        <hr class="ft2-hr full-width">
                                    </div>
                                </div>
                                <ft-tree-node
                                    [isCollapsed]="association?.justAdded ? false : true"
                                    [modifierClass]="'properties-associations'"
                                    *ngFor="let association of cityGroup">
                                    <div class="v-center h-between full-width">
                            <span class="bold-text">
                                {{ association.name }}
                            </span>
                                    </div>
                                    <ng-template #node>
                                        <div class="ft2-edit-panel-group mb-2 pb-0">
                                            <ft-custom-editor
                                                title="Status"
                                                #customEditorRef>
                                                <ft-edit-panel label="Status">
                                                    <div>
                                                        {{getLabel(association.isActive, propertyAssociationStatus) }}
                                                    </div>
                                                </ft-edit-panel>
                                                <div content>
                                                    <div class="ft2-form-group">
                                                        <ng-select
                                                            class="ft2-select -large"
                                                            [clearable]="false"
                                                            [searchable]="false"
                                                            bindLabel="label"
                                                            bindValue="value"
                                                            [name]="'isActive' + association.propertyUuid"
                                                            [required]="true"
                                                            [items]="propertyAssociationStatus"
                                                            [(ngModel)]="association.isActive">
                                                        </ng-select>
                                                        <button
                                                            class="ft2-properties-association_action-button ft2-second-button ft2-btn-light -large -outline mr-3"
                                                            (click)="toggleRemoveAssociationModal(association)">
                                                            Remove property
                                                        </button>
                                                    </div>
                                                </div>
                                            </ft-custom-editor>
                                            <ft-custom-editor
                                                title="Booking engine routing"
                                                (editorOpened)="onRoutingEditorOpened(association, 'bookingEngineCode')"
                                                (cancel)="cancelField(association, 'bookingEngineCode', true)">
                                                <ft-edit-panel label="Booking engine routing">
                                                    <div>
                                                        {{getBookingEngineCodeValue(association, selectedPreviewLanguage?.languageCode)}}
                                                    </div>
                                                </ft-edit-panel>
                                                <div content>
                                                    <div class="ft2-form-group property-association">
                                                        <ng-select
                                                            class="ft2-select -large"
                                                            [clearable]="false"
                                                            [searchable]="false"
                                                            bindLabel="label"
                                                            bindValue="value"
                                                            [name]="'isUsePropertyBookingEngineTemplateUrl' + association.propertyUuid"
                                                            [required]="true"
                                                            [(ngModel)]="association.isUsePropertyBookingEngineTemplateUrl">
                                                            <ng-option [value]="true">
                                                                Route to property's booking engine
                                                            </ng-option>
                                                            <ng-option [value]="false"
                                                                       [disabled]="!(integrationViewModel.languageData | languagePreview: 'bookingEngineTemplateUrl' : selectedPreviewLanguage?.languageCode)">
                                                                Route to your booking engine
                                                                <div
                                                                    *ngIf="!(integrationViewModel.languageData | languagePreview: 'bookingEngineTemplateUrl' : selectedPreviewLanguage?.languageCode)"
                                                                    class="medium-text">
                                                                    You can choose this option after you set up your
                                                                    booking engine.
                                                                </div>
                                                            </ng-option>
                                                        </ng-select>
                                                        <div *ngIf="!association.isUsePropertyBookingEngineTemplateUrl"
                                                             class="mt-3">
                                                            <ng-container *ngIf="association | languageData: 'languages' as data">
                                                                <ng-container *ngFor="let item of data.languages; let i = index; let first = first;">
                                                                    <ng-container *ngIf="item?.languageCode === 'en'">
                                                                        <div class="ft2-input-container -pure"
                                                                             *ngIf="isVariationActive(item?.languageCode)">
                                                                            <div class="d-flex f-nowrap v-center">
                                                                                <input
                                                                                    class="ft2-input -large"
                                                                                    [ngClass]="{'mt-2': i > 0 && !first}"
                                                                                    type="text"
                                                                                    placeholder="Property's booking engine code"
                                                                                    [required]="true"
                                                                                    [name]="'bookingEngineCode' + association.propertyUuid + i"
                                                                                    [(ngModel)]="item.bookingEngineCode">
                                                                            </div>
                                                                        </div>
                                                                        <div *ngIf="isVariationActive(item?.languageCode) && integrationViewModel.languageData | languagePreview: 'bookingEngineTemplateUrl' : item?.languageCode"
                                                                             class="ft2-edit-dialog_summary mt-3">
                                                                            Here's an example of the URL: <br>
                                                                            {{prepareUrl((integrationViewModel.languageData | languagePreview: 'bookingEngineTemplateUrl' : item?.languageCode), item?.bookingEngineCode)}}
                                                                        </div>
                                                                    </ng-container>
                                                                </ng-container>
                                                                <!-- @todo think about language variations in custom editors -->
<!--                                                                <div class="ft2-edit-dialog_footer-control" style="position: absolute; margin-top: 23px;">-->
<!--                                                                    <ft-language-variations *ngIf="languages?.length"-->
<!--                                                                                            [fieldName]="'bookingEngineCode'"-->
<!--                                                                                            [languages]="languages"-->
<!--                                                                                            [value]="data.languages"-->
<!--                                                                                            (activeVariationsChange)="onActiveVariationChanged($event)">-->
<!--                                                                    </ft-language-variations>-->
<!--                                                                </div>-->
                                                            </ng-container>
                                                        </div>
                                                        <div
                                                            *ngIf="association.isUsePropertyBookingEngineTemplateUrl && (missionControlDetailsSettings?.languages?.item | languagePreview: 'bookingEngineTemplateUrl' : 'en')"
                                                            class="ft2-edit-dialog_summary mt-3">
                                                            Here's an example of the URL:<br>
                                                            {{prepareUrl(missionControlDetailsSettings?.languages?.item | languagePreview: 'bookingEngineTemplateUrl' : 'en', association?.languages | languagePreview: 'bookingEngineCode' : 'en')}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </ft-custom-editor>
<!--                        Commented out section due to future updates in language variations approach -->
<!--                                            <ft-custom-editor-->
<!--                                                title="Booking engine routing"-->
<!--                                                (editorOpened)="onRoutingEditorOpened(association, 'bookingEngineCode')"-->
<!--                                                (cancel)="cancelField(association, 'bookingEngineCode', true)">-->
<!--                                                <ft-edit-panel label="Booking engine routing">-->
<!--                                                    <div>-->
<!--                                                        {{getBookingEngineCodeValue(association)}}-->
<!--                                                    </div>-->
<!--                                                </ft-edit-panel>-->
<!--                                                <div content>-->
<!--                                                    <div class="ft2-form-group property-association">-->
<!--                                                        <ng-select-->
<!--                                                            class="ft2-select -large"-->
<!--                                                            [clearable]="false"-->
<!--                                                            [searchable]="false"-->
<!--                                                            bindLabel="label"-->
<!--                                                            bindValue="value"-->
<!--                                                            [name]="'isUsePropertyBookingEngineTemplateUrl' + association.propertyUuid"-->
<!--                                                            [required]="true"-->
<!--                                                            [(ngModel)]="association.isUsePropertyBookingEngineTemplateUrl">-->
<!--                                                            <ng-option [value]="true">-->
<!--                                                                Route to property's booking engine-->
<!--                                                            </ng-option>-->
<!--                                                            <ng-option [value]="false"-->
<!--                                                                       [disabled]="!(integrationViewModel.languageData | languagePreview: 'bookingEngineTemplateUrl' : selectedPreviewLanguage?.languageCode)">-->
<!--                                                                Route to your booking engine-->
<!--                                                                <div-->
<!--                                                                    *ngIf="!(integrationViewModel.languageData | languagePreview: 'bookingEngineTemplateUrl' : selectedPreviewLanguage?.languageCode)"-->
<!--                                                                    class="medium-text">-->
<!--                                                                    You can choose this option after you set up your-->
<!--                                                                    booking engine.-->
<!--                                                                </div>-->
<!--                                                            </ng-option>-->
<!--                                                        </ng-select>-->
<!--                                                        <div *ngIf="!association.isUsePropertyBookingEngineTemplateUrl"-->
<!--                                                             class="mt-3">-->
<!--                                                            <ng-container *ngIf="association | languageData: 'languages' as data">-->
<!--                                                                <ng-container *ngFor="let item of data.languages; let i = index; let first = first;">-->
<!--                                                                    <div class="ft2-input-container -pure"-->
<!--                                                                         *ngIf="isVariationActive(item?.languageCode)">-->
<!--                                                                        <div class="d-flex f-nowrap v-center">-->
<!--                                                                            <input-->
<!--                                                                                class="ft2-input -large"-->
<!--                                                                                [ngClass]="{'mt-2': i > 0 && !first}"-->
<!--                                                                                type="text"-->
<!--                                                                                placeholder="Property's booking engine code"-->
<!--                                                                                [required]="true"-->
<!--                                                                                [name]="'bookingEngineCode' + association.propertyUuid + i"-->
<!--                                                                                [(ngModel)]="item.bookingEngineCode">-->
<!--                                                                            <span class="ft2-input-language"-->
<!--                                                                                [ngClass]="{ '-clickable': isValueRemovable(association, i) }"-->
<!--                                                                                *ngIf="(activeVariationsCount > 1)">-->
<!--                                                                                        {{ item?.languageName }}-->
<!--                                                                                <button *ngIf="isValueRemovable(association, i)"-->
<!--                                                                                        class="ft2-btn-close -inline -round" (click)="removeValue(association, i, 'bookingEngineCode')">-->
<!--                                                                                </button>-->
<!--                                                                            </span>-->
<!--                                                                        </div>-->
<!--                                                                    </div>-->
<!--                                                                    <div *ngIf="isVariationActive(item?.languageCode) && integrationViewModel.languageData | languagePreview: 'bookingEngineTemplateUrl' : item?.languageCode"-->
<!--                                                                         class="ft2-edit-dialog_summary mt-3">-->
<!--                                                                            Here's an example of the URL: <br>-->
<!--                                                                            {{prepareUrl((integrationViewModel.languageData | languagePreview: 'bookingEngineTemplateUrl' : item?.languageCode), item?.bookingEngineCode)}}-->
<!--                                                                    </div>-->
<!--                                                                </ng-container>-->
<!--                                                                &lt;!&ndash; @todo think about language variations in custom editors &ndash;&gt;-->
<!--                                                                <div class="ft2-edit-dialog_footer-control" style="position: absolute; margin-top: 23px;">-->
<!--                                                                    <ft-language-variations *ngIf="languages?.length"-->
<!--                                                                                            [fieldName]="'bookingEngineCode'"-->
<!--                                                                                            [languages]="languages"-->
<!--                                                                                            [value]="data.languages"-->
<!--                                                                                            (activeVariationsChange)="onActiveVariationChanged($event)">-->
<!--                                                                    </ft-language-variations>-->
<!--                                                                </div>-->
<!--                                                            </ng-container>-->
<!--                                                        </div>-->
<!--                                                        <div-->
<!--                                                            *ngIf="association.isUsePropertyBookingEngineTemplateUrl && (missionControlDetailsSettings?.languages?.item | languagePreview: 'bookingEngineTemplateUrl' : selectedPreviewLanguage?.languageCode)"-->
<!--                                                            class="ft2-edit-dialog_summary mt-3">-->
<!--                                                            Here's an example of the URL:<br>-->
<!--                                                            {{prepareUrl(missionControlDetailsSettings?.languages?.item | languagePreview: 'bookingEngineTemplateUrl' : selectedPreviewLanguage?.languageCode, association?.languages | languagePreview: 'bookingEngineCode' : selectedPreviewLanguage?.languageCode)}}-->
<!--                                                        </div>-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </ft-custom-editor>-->
                                            <ft-custom-editor
                                                title="Website URL routing"
                                                (cancel)="cancelField(association, 'websiteUrl', true)">
                                                <ft-edit-panel label="Website URL routing">
                                                    <div>
                                                        {{getLandingPageValue(association)}}
                                                    </div>
                                                </ft-edit-panel>
                                                <div content>
                                                    <div class="ft2-form-group">
                                                        <ng-select
                                                            class="ft2-select -large"
                                                            [clearable]="false"
                                                            [searchable]="false"
                                                            bindLabel="label"
                                                            bindValue="value"
                                                            [required]="true"
                                                            [name]="'isUsePropertyWebsiteUrl' + association.propertyUuid"
                                                            [items]="inheriteWebsiteUrlOptions"
                                                            [(ngModel)]="association.isUsePropertyWebsiteUrl"
                                                            (ngModelChange)="changeField($event, association, 'websiteUrl', true)">
                                                        </ng-select>
                                                        <ng-container *ngFor="let translation of association.languages">
                                                            <div *ngIf="!association.isUsePropertyWebsiteUrl"
                                                                [class.-pure]="!getLanguageNameForTranslation(translation)"
                                                                class="ft2-input-container mt-3">
                                                                <div class="d-flex f-nowrap v-center">
                                                                    <input
                                                                        [class.-pure]="getLanguageNameForTranslation(translation)"
                                                                        class="ft2-input -large"
                                                                        type="text"
                                                                        [required]="true"
                                                                        placeholder="https://website.com/path"
                                                                        [name]="'landingPageUrl' + association.propertyUuid + translation.languageCode"
                                                                        [(ngModel)]="translation.websiteUrl">
                                                                    <span class="ft2-input-language"
                                                                          *ngIf="getLanguageNameForTranslation(translation) && languages?.length > 1">
                                                                    {{ getLanguageNameForTranslation(translation) }}
                                                                </span>
                                                                </div>
                                                                <div
                                                                    *ngIf="translation.websiteUrl == null && missionControlDetailsSettings?.url"
                                                                    class="ft2-edit-dialog_summary mt-3">
                                                                    <p>Here's an example of the URL:</p>
                                                                    <p>{{missionControlDetailsSettings?.url}}</p>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </ft-custom-editor>
                                            <ft-custom-editor title="Discovery settings"
                                                              (cancel)="cancelField(association, 'isHiddenInRouter') && cancelField(association, 'isPlannerFlowDisabled')">
                                                <ft-edit-panel label="Discovery settings">
                                                    <div>
                                                        {{getLabel(association.isHiddenInRouter, discoveryRouterVisibilityOptions)}}
                                                        <ng-container *ngIf="!association.isHiddenInRouter">
                                                            {{ association.isPlannerFlowDisabled ? 'but' : 'and' }} {{ getLabel(association.isPlannerFlowDisabled, discoveryPlannerFlowVisibilityOptions) | lowercase }}
                                                        </ng-container>
                                                    </div>
                                                </ft-edit-panel>
                                                <div content>
                                                    <div class="ft2-form-group">
                                                        <ng-select class="ft2-select"
                                                                   [items]="discoveryRouterVisibilityOptions"
                                                                   [required]="false"
                                                                   [searchable]="false"
                                                                   [clearable]="false"
                                                                   [name]="'isHiddenInRouter' + association.propertyUuid"
                                                                   bindLabel="label"
                                                                   bindValue="value"
                                                                   [(ngModel)]="association.isHiddenInRouter">
                                                        </ng-select>
                                                    </div>

                                                    <ng-select class="ft2-select"
                                                               *ngIf="!association.isHiddenInRouter"
                                                               [items]="discoveryPlannerFlowVisibilityOptions"
                                                               [required]="false"
                                                               [searchable]="false"
                                                               [clearable]="false"
                                                               [name]="'isPlannerFlowDisabled ' + association.propertyUuid"
                                                               bindLabel="label"
                                                               bindValue="value"
                                                               [(ngModel)]="association.isPlannerFlowDisabled">
                                                    </ng-select>
                                                </div>
                                            </ft-custom-editor>
                                        </div>
                                    </ng-template>
                                </ft-tree-node>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </form>
</section>
<ng-template #dialogTmpl>
    <div class="flex full-width v-center h-center">
        <div class="ft2-container -narrow">
            <div class="col-12 text-center">
                <p class="modal-heading">Remove this organization?</p>
            </div>
            <div class="col-12 text-center pt-4">
                <p class="modal-summary">This will also remove any settings associated with this org across your
                    platform. There is no undo for this action.</p>
            </div>
            <div class="col-12 h-center pt-5">
                <button class="ft2-btn-primary mr-2 -large"
                        *ngIf="isFliptoStaff"
                        [mat-dialog-close]="true">
                    Remove
                </button>
                <button class="ft2-btn-default -large" [mat-dialog-close]="false">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</ng-template>
