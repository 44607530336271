

import { BroadcastModalService } from "./broadcastModal.service";
import * as StoryImageSetupComponent from "./story/imageSetup.component";
import * as PhotocontestInvitationImageSetupComponent from "./photocontest-invitation/imageSetup.component";
import * as ChooseNetworkComponent from "./chooseNetwork.component";
import * as ShareImageReadyComponent from "./shareImageReady.component";
import * as ChooseGoalComponent from "./chooseGoal.component";

declare const angular;
export const BROADCAST_MODULE = angular.module("AccountApp.v2.Components.Broadcast", []);

export class BroadcastConfig {

    static $inject = ["$compileProvider"];

    constructor($compileProvider) {
        $compileProvider.component(StoryImageSetupComponent.NAME, StoryImageSetupComponent.OPTIONS);
        $compileProvider.component(PhotocontestInvitationImageSetupComponent.NAME, PhotocontestInvitationImageSetupComponent.OPTIONS);
        $compileProvider.component(ChooseNetworkComponent.NAME, ChooseNetworkComponent.OPTIONS);
        $compileProvider.component(ShareImageReadyComponent.NAME, ShareImageReadyComponent.OPTIONS);
        $compileProvider.component(ChooseGoalComponent.NAME, ChooseGoalComponent.OPTIONS);
    }
}

BROADCAST_MODULE.config(BroadcastConfig);
BROADCAST_MODULE.service(BroadcastModalService.Name, BroadcastModalService);
