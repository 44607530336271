import { BroadcastStoryFlowController } from "./broadcastStoryFlowController";
import { ShareType } from "../../broadcast/shareType";
import { StoryDescriptor } from "../../broadcast/storyDescriptor";
import { SOCIAL_NETWORKS } from "../../../api/socialNetwork";

export class BroadcastStoryFromRecommendationFlowController extends BroadcastStoryFlowController {

    static STEP_NAME_IMAGE_SETUP = "ImageSetup";
    static STEP_NAME_CHOOSE_NETWORK = "ChooseNetwork";
    static STEP_NAME_SHARE_IMAGE_READY = "ShareImageReady";
    static STEP_NAME_CREATE_IMAGE_PROGRESS = "CreateImageProgress";

    static $inject = [
        "$injector",

        "renderer",
        "propertyUuid",
        "storyUuid",
        "recommendation",
        "steps"
    ];

    constructor(
        public $injector,

        public renderer,
        public propertyUuid,
        public storyUuid,
        public recommendation,
        public steps
    ) {
        super(
            $injector,

            renderer,
            propertyUuid,
            storyUuid,
            recommendation,
            steps
        );

        this.ftAzureStoredPhoto = this.$filter("ftAzureStoredPhoto");
        const state = this.$ngRedux.getState();
        this.property = this.SessionService.getProperty(this.propertyUuid);
        this.fullContext = `/${state.ui.context.organization}/${state.ui.context.singleProperty}`;

        this.$q.all([this.PhotoContest.details(this.propertyUuid, this.recommendation.recommendation_data.campaign_promoter_uuid),
        this.Layers.photocontest({ propertyId: this.propertyUuid })])
            .then(datas => {
                const story = datas[0],
                    photoContestLayer = datas[1];

                this.setShareType(BroadcastStoryFromRecommendationFlowController.RecommendationTypeToShareTypeMap[this.recommendation.recommendation_type_id]);

                this.contests = this.parse.arrayOrDefault(photoContestLayer.contests);
                
                this.$q.all([
                    !!story.guest.poststay.photo ? this.PhotoContest.load({ contestId: story.guest.poststay.photo.photoContest.uuid, propertyUuid: this.propertyUuid }) : null,
                    this.shareType == ShareType.PhotoContestInvitation ? this.PhotoContest.load({ contestId: this.recommendation.custom_data.contestUuid, propertyUuid: this.propertyUuid }) : null
                ])
                    .then(datas => {
                        this.contest = datas[0];
                        this.inviteToContest = datas[1];
                        this.story = story;
                        this.storyDescriptor = new StoryDescriptor(this.parse, story);
                        this.isInitialized = true;

                        // create bunch of models for components
                        this.shareImageUrl = this.recommendation.custom_data.shareImageUrl ? this.recommendation.custom_data.shareImageUrl : null;
                        this.imageSetupOptions = this.recommendation.custom_data.imageSetupOptions;
                        this.storySharedTo = story.socialNetworksSharing.filter(n => this.parse.intOrDefault(n.totalShareTimes) > 0).map(n => this.parse.intOrDefault(n.socialNetworkType));

                        const isImageReady = !!this.shareImageUrl;
                        if (isImageReady) {
                            this.network = SOCIAL_NETWORKS.map(n => Object.assign({}, n, { isSharedTo: !!this.storySharedTo.find(s => s == n.type) })).find(n => n.type == this.recommendation.custom_data.socialNetworkType);
                            this.shareUrl = this.getShareUrl();
                        } else {
                            this.moveTo(BroadcastStoryFlowController.STEP_NAME_CHOOSE_NETWORK);
                        }
                    });
            })

    }

    close() {
        this.ftModal.destroy("account-v2.BroadcastRecommendedStory");
        super.close();
    }

    returnToImageSetup() {
        this.moveTo(this.shareType == ShareType.Story ? BroadcastStoryFlowController.STEP_NAME_STORY_IMAGE_SETUP : BroadcastStoryFlowController.STEP_NAME_PHOTOCONTEST_INVITATION_IMAGE_SETUP);
    }

}


