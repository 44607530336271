/**
 * Created by Roman on 2015-06-15.
 */

angular.module('flipto.components.common.validation')
    .factory('passwordValidator', [function () {

        /**
         * Returns true if there is at least 6 characters
         * @param value
         * @returns {boolean|*}
         */
        function hasMinimumLength(value) {
            return new RegExp('^.{6,}$', 'gi').test(value);
        }

        /**
         * Returns true if there is a digit or a special character
         * @param value
         * @returns {boolean|*}
         */
        function hasNumberOrSpecialCharacter(value) {
            return new RegExp("[\\W\\d]+", 'gi').test(value);
        }

        /**
         * Returns true if there are no spaces
         * @param value
         * @returns {boolean}
         */
        function hasNoSpaces(value) {
            return !(new RegExp('[\\s]+', 'gi').test(value));
        }

        /**
         * Returns true if there is an upper-case character
         * @param value
         * @returns {boolean|*}
         */
        function hasUppercaseCharacters(value) {
            return new RegExp('[A-Z]+', 'g').test(value);
        }

        /**
         * Returns true if there is a lower-case character
         * @param value
         * @returns {boolean|*}
         */
        function hasLowercaseCharacters(value) {
            return new RegExp('[a-z]+', 'g').test(value);
        }

        return function (value) {
            return hasMinimumLength(value) &&
                hasNumberOrSpecialCharacter(value) && hasNoSpaces(value) &&
                hasLowercaseCharacters(value) &&
                hasUppercaseCharacters(value);
        }
    }])
    .directive('password', ['passwordValidator', function (passwordValidator) {
        return {
            require: 'ngModel',
            link: function (scope, elem, attrs, ngModel) {
                ngModel.$parsers.unshift(validate);

                scope.$watch(attrs.password, function () {
                    ngModel.$setViewValue(ngModel.$viewValue);
                });

                function validate(value) {
                    var isValid = passwordValidator(value);
                    ngModel.$setValidity('password', isValid);
                    return isValid ? value : undefined;
                }
            }
        };
    }]);