/**
 * User: Kupin.R
 * Date: 5/20/14
 */



angular
    .module("flipto.core.urlFormatter",
        [
            "flipto.core.lodash",
            "flipto.core.serializers"
        ])
    .service('urlFormatter', ['_', 'dateSerializer', function (_, dateSerializer) {

        /**
        * Builds url from strings that are sent in
        * @param url
        * @param paths
        * @returns {string}
        */
        this.combineUrlPath = function (url) {
            var paths = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                paths[_i - 1] = arguments[_i];
            }
            return paths.reduce(function (result, part) { return "" + (result.slice(-1) === "/" ? result : result + "/") + (part.slice(0, 1) === "/" ? part.slice(1) : part); }, url);
        };

        /**
         * Updates query part of url based on arguments
         * @param url
         * @param query
         * @returns {*}
         */
        this.updateQuery = function (url, query) {
            var queryStartIndex = url.indexOf("?");
            if (queryStartIndex === -1) return this.format(url, query, true);
            for (var key in query) {
                var keyIndex = url.indexOf(key, queryStartIndex);
                if (keyIndex !== -1) {
                    //update
                    url = url.replace(new RegExp(key + "=([^&]+)"), key + "=" + query[key]);
                } else {
                    // append
                    url = this.format(url, { key: query[key] }, true);
                }
            }

            return url;
        };

        /**
         * Format url, if appendSearch is true - adds all arguments that are not in url string to query
         * @param url
         * @param params
         * @param appendSearch
         * @returns {*}
         */
        this.format = function (url, params, appendSearch) {

            var pairs = _.toPairs(params),
                search = '';

            _.forEach(pairs, function (pair) {
                var name = pair[0],
                    value = pair[1];

                appendToSearchIfNecessary(name, value);
                url = url.replace(new RegExp(':' + name, 'gi'), encodeURIComponent(value));
            });


            function urlHasKey(key) {
                return url.indexOf(':' + key) !== -1;
            }

            function valueCanBeAppendedToSearch(value) {
                return angular.isNumber(value) ||
                    angular.isString(value) ||
                    angular.isDate(value) ||
                    typeof (value) === "boolean";
            }

            function appendToSearchIfNecessary(key, value) {
                var appendParamToSearch = appendSearch && !urlHasKey(key) &&
                    valueCanBeAppendedToSearch(value);

                search += appendParamToSearch ? String.prototype.concat(key, '=', encodeURIComponent(serializeIfNecessary(value)), '&') : '';
            }

            function serializeIfNecessary(value) {
                if (angular.isDate(value)) return dateSerializer(value);
                return value;
            }

            if (appendSearch && search.length > 1) {
                url += url.indexOf("?") !== -1 ? ("&" + search.substring(0, search.length - 1)) : ("?" + search.substring(0, search.length - 1));
            }

            return url;
        };

        this.appendToQueryString = function (url, params) {
            if (!url) return null;
            if (!params) return url;
            return url + (url.indexOf('?') > -1 ? '&' : '?') + params;
        }
    }]);