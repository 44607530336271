/**
 * Created by ostapenko.r on 1/19/2015.
 */
angular
    .module('flipto.components.campaigns')
    .constant('CampaignType', {
        'None': 0,
        'Custom': 30,
        'AllGuests': 255
    });
