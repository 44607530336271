/**
 * Created by kevin.tiller on 6/3/2018. (copied from prestay)
 */

export const PREFIX = "[PHOTO_ADVOCACY_API]";

export const CAMPAIGNS_FETCH = `${PREFIX}.CampaignsFetch`;
export const CAMPAIGNS_FETCH_SUCCESS = `${CAMPAIGNS_FETCH}Success`;
export const CAMPAIGNS_FETCH_ERROR = `${CAMPAIGNS_FETCH}Error`;


export function getCampaigns(propertyUUID, startDate?, endDate?) {
    return (dispatch, getState, {$http, urlFormatter}) => {
        dispatch({type: CAMPAIGNS_FETCH});
        return $http.get(urlFormatter.format(`/api/${propertyUUID}/properties/${propertyUUID}/layers/photoadvocacy/campaigns`, {
                StartDate: startDate,
                EndDate: endDate
            }, true))
            .then(response => {
                dispatch({type: CAMPAIGNS_FETCH_SUCCESS, payload: response.data});
            }, error => {
                dispatch({type: CAMPAIGNS_FETCH_ERROR, payload: error});
                throw CAMPAIGNS_FETCH_ERROR;
            })
    }
}

const All = {getCampaigns};
export default All;