import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { catchError, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DownloadService {
    loadingStateMap = new Map<string, boolean>();
    loadingStateUpdateSubject$ = new Subject();

    constructor(private http: HttpClient) {
    }

    downloadFile(url: string, name?: string) {
        this.setLoadingState(url);
        this.http.get(url, { responseType: 'blob', observe: 'response' })
            .pipe(
                catchError(e => {
                    this.removeLoadingState(url);
                    throw e;
                })
            )
            .subscribe(res => {
                this.removeLoadingState(url);
                let filename = this.getFileNameFromHeaders(res);
                if (name) {
                    filename = `${name}.${filename.split('.').pop()}`
                }
                saveAs(res.body, filename);
            });
    }

    getFileNameFromHeaders(response: any) {
        const contentDisposition = typeof response.headers === 'function'
            ? response.headers()?.['content-disposition'] // Angular.js
            : response.headers?.get('content-disposition'); // Angular
        return contentDisposition?.match(/filename="?([^";]+)/)[1]
            ?? response.url.split('/').pop();
    }

    download(url: string) {
        return new Promise((resolve, reject) => {
            this.http.get(url, { responseType: 'blob', observe: 'response' }).subscribe(response => {
                resolve({
                    content: response.body,
                    filename: this.getFileNameFromHeaders(response)
                });
            }, error => reject(error));
        });
    }

    setLoadingState(key: string) {
        this.loadingStateMap.set(key, true);
        this.loadingStateUpdateSubject$.next(null);
    }

    removeLoadingState(key: string) {
        this.loadingStateMap.delete(key);
        this.loadingStateUpdateSubject$.next(null);
    }
}
