/**
 * Created by Roman on 2014-09-15.
 */


angular.module('flipto.components.common.panel', [])
    .directive('ftPanel', [function () {
        return {
            restrict: 'E',
            transclude: true,
            templateUrl: '/app/components/common/panel/panel.html',
            scope: {
                'title': '@title',
                'disabled': '=',
                'raiseOnClick': '&onClick'
            },
            link: function (scope, element, attrs) {

                scope.hideStatus = angular.isDefined(attrs.hideStatus) || false;

                element.bind('click', function () {
                    scope.$apply(function () {
                        scope.raiseOnClick();
                    });
                });

                scope.$on('$destroy', function () {
                    element.unbind('click');
                });
            }
        };
    }]);