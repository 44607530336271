/**
 * Created by ostapenko.r on 1/20/2015.
 */

angular
    .module('flipto.components.common.date', ['flipto.core.moment'])
    .filter('month', ['$filter', function ($filter) {
        var translate = $filter('translate'),
            monthPrefix = "flipto.components.common.date.month";
        return function (numberOfMonth) {
            if (angular.isNumber(numberOfMonth) || (angular.isString(numberOfMonth) && numberOfMonth)) {
                return translate(monthPrefix + numberOfMonth)
            }
        }
    }])
    .filter('toMonth', ['moment', function (moment) {
        return function (month) {
            var date = new moment();
            date.month(month);
            return date.format('MMMM');
        }
    }])
    .filter('monthShort', ['$filter', function ($filter) {
        var translate = $filter('translate'),
            monthPrefix = "flipto.components.common.date.month";
        return function (numberOfMonth) {
            if (angular.isNumber(numberOfMonth) || (angular.isString(numberOfMonth) && numberOfMonth)) {
                return translate(monthPrefix + numberOfMonth + '.short');
            }
        }
    }])
    .filter('toUtc', ['moment', function (moment) {
        return function (epochDate) {
            return moment(epochDate).utc();
        }
    }])
    .filter('isNowInRange', ['moment', function (moment) {
        return function (start, end) {
            var now = moment();
            return now.isAfter(start) && now.isBefore(end);
        };
    }]);