/**
 * Created by Roman on 2014-09-15.
 */

angular.module('flipto.components.common.dateRange')
    .service('snapshot', ['sessionStorage', 'predefinedDateRanges', function (sessionStorage, predefinedDateRanges) {

        var defaultRange = {
            label: 'Past 30 Days',
            start: predefinedDateRanges['Past 30 Days'][0],
            end: predefinedDateRanges['Past 30 Days'][1]
        };
        return {
            get: function () {
                var range = sessionStorage.get('accountApp.snapshot');
                if(range){
                    range.start = new Date(range.start);
                    range.end = new Date(range.end);
                }
                return range || defaultRange;
            },
            set: function (snapshot) {
                sessionStorage.put('accountApp.snapshot', snapshot);
            }
        };
    }]);