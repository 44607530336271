import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfilePicComponent } from '@flipto/shared/src/lib/components/images/profile-pic/profile-pic.component';
import { SharedDirectivesModule } from '@flipto/shared/src/lib/directives/shared-directives.module';

@NgModule({
    declarations: [
        ProfilePicComponent
    ],
    imports: [
        CommonModule,
        SharedDirectivesModule
    ],
    exports: [
        ProfilePicComponent,
        SharedDirectivesModule
    ]
})
export class SharedComponentsModule {
}
