/**
 * User: Kupin.R
 * Date: 5/28/14
 */



angular
    .module("flipto.components.goals")
    .directive("ftCampaignGoals",
        [
            "$modal", "$filter", "$sce", 'ftModal',
            function ($modal, $filter, $sce, ftModal) {

                var incentiveNameFilter = $filter("incentiveName"),
                    goalName = $filter("goalName");

                return {
                    restrict: "E",
                    templateUrl: "/app/components/goals/directives/campaignGoals.html",
                    scope: {
                        "campaign": "=",
                        "propertyIncentives": "=",
                        "culture": "=",
                        "layer": "=",
                        "onSaved": "&"
                    },

                    link: function (scope, element, attrs) {
                        scope.$watch("campaign", init);
                        scope.$watch("propertyIncentives", init);

                        scope.readOnly = false;
                        if (typeof attrs.disabled !== "undefined" && attrs.disabled === "" || attrs.disabled === "true" || attrs.disabled === true)
                            scope.readOnly = true;

                        scope.goalName = function (goal) {
                            return $sce.trustAsHtml(goalName(goal, scope.culture));
                        };

                        scope.incentiveName = function (goal) {
                            return $sce.trustAsHtml(incentiveNameFilter(goal, scope.culture));
                        };

                        scope.editGoals = function (goalTypes, initialIncentiveTag) {
                            if (!scope.readOnly) {
                                scope.types = goalTypes;
                                scope.incentiveTag = initialIncentiveTag;
                                ftModal.show('editCampaignGoalsModal');
                            }
                        };

                        // Do these actualy do anything?
                        scope.advocateGoalsFilter = function (goal) {
                            return goal && goal.isActive && (goal.type === 3 || goal.type === 4 || goal.type === 6);
                        };

                        scope.groupAdvocacySocialConnectionsFilter = function (goal) {
                            return goal && goal.isActive && (goal.type === 2 || goal.type === 7);
                        };
                        scope.groupAdvocacyAdvocatesFilter = function (goal) {
                            return goal && goal.isActive && (goal.type === 1);
                        };

                        function init() {
                            if (scope.campaign && scope.propertyIncentives) {
                                scope.campaign.goals = scope.campaign.goals || { item: [] };
                                scope.campaignGoals = scope.campaign.goals.item;
                            }
                        }
                    }
                };
            }
        ]
    );
