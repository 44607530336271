import { ElementRef, forwardRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgModel, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputFieldType } from '../../forms/editors/types/input-field.type';
import _ from 'lodash';
import { ModalEditorBase, MODAL_EDITOR_TOKEN } from '../../forms/editors/classes/modal-editor-base';

@Component({
  selector: 'ft-list-element',
  templateUrl: './list-element.component.html',
  styleUrls: ['./list-element.component.scss'],
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        multi: true,
        useExisting: forwardRef(() => ListElementComponent)
    },
    {
      provide: MODAL_EDITOR_TOKEN,
      useExisting: ListElementComponent
    }
  ]
})
export class ListElementComponent extends ModalEditorBase implements OnInit, OnChanges {
  @ViewChild('input', { read: NgModel, static: false }) input: NgModel;
  @ViewChild('inputElement', { static: false }) inputElement: ElementRef;
  @Input() controlName = 'formControl';
  @Input() email: boolean;
  @Input() maxlength: number;
  @Input() minlength: number;
  @Input() min: number;
  @Input() max: number;
  @Input() pattern: string;
  @Input() patternValidationText: string;
  @Input() type: InputFieldType = 'text';
  @Input() placeholder = '';
  newValue: string;

  @Input() caseSensitive = false;
  @Input() allowDuplicates = false;
  hasDuplicatesError = false;


  constructor() {
    super();
  }

  addItem() {
      if (this.input.valid && this.newValue) {
          this.validateDuplicates();
          if (!this.hasDuplicatesError) {
              this.value = [...this.value, this.newValue];
              this.onChangeFn(this.value);
              this.newValue = null;
          }
      }
  }

  validateDuplicates() {
      if (!this.allowDuplicates) {
          this.hasDuplicatesError = false;
          this.value.forEach(item => {
              if (this.caseSensitive) {
                  if (item === this.newValue) {
                      this.hasDuplicatesError = true;
                  }
              } else {
                  if (item.toLowerCase() === this.newValue.toLowerCase()) {
                      this.hasDuplicatesError = true;
                  }
              }
          });
      }
  }

  removeItem(itemIndex) {
      this.value = this.value.filter((val, index) => itemIndex !== index);
  }

  ngOnInit(): void {
      if (this.type === 'url') {
          this.patternValidationText = 'Value is not valid url.';
          this.pattern = 'https?://.+';
      }
  }

  ngOnChanges(changes: SimpleChanges) {
      if (!!changes.type) {
          this.email = changes.type.currentValue === 'email';
      }
  }
}
