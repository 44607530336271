/**
 * Created by ostapenko.r on 10/13/2014.
 */
angular.module('flipto.components.common.navigationPane')
    .directive('ftNavPaneItem', [function () {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: '/app/components/common/navigation-pane/navigationPaneItem.html',
            require: '^ftNavPane',
            scope: {
                'screenName': '@',
                'title': '@'
            },
            link: function (scope, element, attrs, paneController) {
                scope.screen = paneController.getScreen();

                attrs.$observe("default", function () {
                    var isDefault = getIsDefault();
                    if (isDefault) {
                        scope.screen = scope.screen || {};
                        scope.screen.name = scope.screenName;
                    }
                });

                function getIsDefault() {
                    if (!angular.isDefined(attrs.default)) return false;
                    if (!attrs.default.length) return true;
                    return scope.$eval(attrs.default);
                }
            }
        };
    }])
    .directive('ftNavPaneItemStatus', ['$compile', function ($compile) {

        var template = '<span class="panel-status" style="display: inline-block; position: relative; top: 0px; right: 0px; margin-left: 10px;" ng-class="{on: status, off: !status}" ng-if="!isShowOnOnly || status">{{status | onOff}}</span>';

        return {
            restrict: 'A',
            link: function (scope, elem, attrs) {

                var isShowOnOnly = angular.isDefined(attrs.showOnOnly),
                    statusElem,
                    elemScope;

                attrs.$observe('ftNavPaneItemStatus', function (status) {
                    if (angular.isDefined(status)) {
                        var isOn = scope.$eval(status);
                        addStatusElem(isOn);
                        syncStatus(isOn);
                    }
                });

                function addStatusElem(status) {
                    if (!statusElem) {
                        elemScope = angular.extend(scope.$new(true), {
                            status: status,
                            isShowOnOnly: isShowOnOnly
                        });
                        statusElem = $compile(template)(elemScope);
                        elem.append(statusElem);
                    }
                }

                function syncStatus(status) {
                    elemScope.status = status;
                }

            }
        };
    }]);
