/**
 * Created by roman.kupin on 9/25/2017.
 */


import * as ContextSelectorDirective from "./contextSelector.directive";
import * as ContextGroupDirective from "./context-group/contextGroup.directive";
import {ContextSelectorService} from "./contextSelector.service";

declare var angular;
export const CONTEXT_SELECTOR_MODULE = angular.module("AccountApp.v2.Components.ContextSelector", []);

export class ComponentConfig {

    static $inject = ["$compileProvider"];

    constructor($compileProvider) {
        const components = [];
        components.forEach(component => {
            $compileProvider.component(component.NAME, component.OPTIONS);
        });

        const directives = [ContextSelectorDirective, ContextGroupDirective];
        directives.forEach(directive => {
            $compileProvider.directive(directive.NAME, directive.DEFINITION);
        });
    }
}

export class ServiceConfig {

    static $inject = ["$provide"];

    constructor($provide) {
        $provide.service(ContextSelectorService.Name, ContextSelectorService);
    }
}

CONTEXT_SELECTOR_MODULE.config(ComponentConfig);
CONTEXT_SELECTOR_MODULE.config(ServiceConfig);

const CONTEXT_SELECTOR_TEMPLATE_URL = require("./contextSelector.html");
CONTEXT_SELECTOR_MODULE.run([ContextSelectorService.Name, "$templateCache", "$http", "$q", (contextSelectorService, $templateCache, $http, $q) => {

    $q.when($templateCache.get(CONTEXT_SELECTOR_TEMPLATE_URL) || $http.get(CONTEXT_SELECTOR_TEMPLATE_URL, {cache: $templateCache}))
        .then(template => {
            contextSelectorService.Template = template;
        });

}]);