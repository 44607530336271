
angular
    .module('flipto.accountv1.SharedFeatures')
    .controller('flipto.account.sections.settings.NotificationsController', [
        '$scope', '$sce', 'SettingsNotifications', 'User', '_', '$log', 'changesTransformer', 'transformType', 'IdentityService', 'SpacesService', 'livePreview', 'notifications', '$filter', 'snapshot', '$window',
        function ($scope, $sce, SettingsNotifications, User, _, $log, changesTransformer, transformType, identity, spacesService, livePreview, notifier, $filter, snapshot, $window) {
            'use strict';
            var vm = this,
                availableNotificationTypes = [48, 49, 47, 43, 44, 56, 75, 77],
                notifications;
            vm.$sce = $sce;
            vm.identity = identity;
            vm.users = [];
            vm.selectedNotification = {};
            vm.notifications = [];
            vm.languages = [];
            vm.emailScenarios = [];
            vm.selectedEmailScenario = {};
            vm.marketingCampaigns = [];
            vm.selectedMarketingCampaign = {};
            vm.selectedLanguage = {};
            vm.sampleEmailUseQueues = true;
            vm.emailListSearch = "";

            vm.snapshot = snapshot.get();
            vm.selectedSearchOptionName = "Email";
            vm.allEmailCategorySearch = { id: null, name: "All" };
            vm.emailSearchCategory = vm.allEmailCategorySearch;
            vm.dateFilter = { startDate: new Date(snapshot.get().start), endDate: new Date(snapshot.get().end) };


            SettingsNotifications.get({ propertyId: spacesService.getPropertyUuid() }).then(function (data) {
                notifications = data.notifications;
                _.forEach(notifications, function (notification) {
                    // Remove all configured notification types from available notification types
                    _.pull(availableNotificationTypes, notification.type);

                    // For future certificate email, parse the spanDays field from string to int
                    if (notification.type === 49) {
                        notification.spanDays = parseInt(notification.spanDays) || 0;
                    }
                });

                // For each remaining notification type, add a stand-in object to the received data
                _.forEach(availableNotificationTypes, function (type) {
                    var defaultNotification = {
                        type: type,
                        userUUIDs: []
                    }
                    if (type === 49) {
                        defaultNotification.spanDays = 3;
                    }
                    notifications.push(defaultNotification);
                });

                // Set model data on vm
                vm.notifications = _.sortBy(notifications, 'type');
                vm.languages = data.languages;

                vm.users = _.keyBy(data.users, 'userUUID');

                // Set up sample email data on vm
                vm.emailScenarios = _.filter(data.emailScenarios, function (scenario) { return scenario.id !== 0; });
                vm.selectedEmailScenario = vm.emailScenarios[0];
                vm.sampleEmailRecipients = identity.user.email;


                // Set up marketing campigns on vm
                vm.marketingCampaigns = data.marketingCampaigns;
                vm.selectedMarketingCampaign = !!vm.marketingCampaigns && !!vm.marketingCampaigns.length ? vm.marketingCampaigns[0] : null;
            });


            // ********************************* //
            // Handle Notification
            // ********************************* //
            vm.visibleUsers = function (userUUID) {
                return !!vm.users[userUUID];
            };

            vm.getSuggestedUsers = function (text) {
                if (!text.length) return [];
                var re = new RegExp("\.*" + text + "\.*", "gi");
                var isFlipTo = vm.identity.isFliptoStaff();
                var users = _.pickBy(vm.users, function (aUser) {
                    return (re.test(aUser.first) || re.test(aUser.last) || re.test(aUser.email)) && !_.includes(vm.selectedNotification.userUUIDs, aUser.userUUID) && (isFlipTo || !aUser.IsFlipTo);
                });
                return Object.values(users);
            };

            vm.commit = function () {
                SettingsNotifications.put({ propertyId: spacesService.getPropertyUuid() }, vm.notifications.map(function (n) { return { type: n.type, spanDays: n.spanDays, user_uuids: n.userUUIDs }; }));
            };

            vm.select = function (notification) {
                vm.selectedNotification = notification;
            };


            // ************************** //
            // Sample Email Sending
            // ************************** //
            vm.selectEmailScenario = function (scenario) {
                vm.selectedEmailScenario = scenario;
            };

            vm.sendSampleEmail = function () {
                livePreview.sendTestEmail({
                    propertyUuid: spacesService.getPropertyUuid(),
                    emailType: parseInt(vm.selectedEmailScenario.id),
                    languageCode: vm.selectedLanguage.code,
                    recipientEmail: vm.sampleEmailRecipients,
                    isUseQueues: vm.sampleEmailUseQueues
                })
                    .then(showEmailSendingSuccess, showEmailSendingError);
            };

            function showEmailSendingSuccess() {
                notifier.show($filter('translate')('flipto.account.SendSampleEmailSuccess'));
            }

            function showEmailSendingError(response) {
                console.error("Email Sending Error", response);
                let message = $filter('translate')('flipto.account.SendSampleEmailError');
                if(response.data){
                    message = message + " - " + response.data;
                }
                notifier.show(message);
            }


            // ************************** //
            // Email Event Search
            // ************************** //
            vm.dateRangeChanged = function (dateRange) {
                vm.dateFilter.startDate = dateRange.fromDate;
                vm.dateFilter.endDate = dateRange.toDate;
            };

            vm.updateEmailSearchCategory = function (emailScenario) {
                vm.emailSearchCategory = emailScenario;
            };

            vm.findCategoryName = function (categoryId) {
                vm.emailScenarios.forEach(function (scenario) {
                    if (categoryId === Number(scenario.id)) { return scenario.name; }
                });
                return;
            };

            vm.openEmail = function (emailUUID) {
                var window = $window.open('');
                SettingsNotifications.getEmail({ propertyId: spacesService.getPropertyUuid(), emailUUID: emailUUID })
                    .then(function (data) {
                        window.document.write(data);
                        window.document.close();
                    });
            };

            vm.searchEmailList = function () {
                var searchRequest = {
                    propertyId: spacesService.getPropertyUuid(),
                    startDate: vm.dateFilter.startDate.toISOString(),
                    endDate: vm.dateFilter.endDate.toISOString(),
                    email: vm.emailListSearch === "" ? null : vm.emailListSearch,
                    scenario: vm.emailSearchCategory.id
                };
                SettingsNotifications.searchEmails(searchRequest)
                    .then(function (data) {
                        vm.emailList = data;
                    });
            };


            // ************************** //
            // Marketing Campaign
            // ************************** //
            vm.selectMarketingCampaign = function (marketingCampaign) {
                vm.selectedMarketingCampaign = marketingCampaign;
            };

            vm.startMarketingCampaign = function () {
                SettingsNotifications.startCampaign({
                    propertyId: spacesService.getPropertyUuid(),
                    marketingCampaignUuid: vm.selectedMarketingCampaign.id
                })
                .then(showCampaignStartSuccess, showCampaignStartError);
            };

            function showCampaignStartSuccess() {
                notifier.show("successfully started campaign");
            }

            function showCampaignStartError() {
                notifier.show("error starting the campaign");
            }
        }
    ]);
