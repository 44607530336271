

declare const angular;

export interface IRenderer {
    add(content: string);
    remove();
    show(...args);
    hide(...args);
}

export class PluggableElementRenderer implements IRenderer {

    static $inject = ["scope", "$compile", "ftModal"];

    element;

    constructor(public scope,
        public $compile,
        public ftModal) {
    }

    add(content: string) {
        if (!!this.element) return;
        this.element = this.$compile(content)(this.scope);

        document.body.appendChild(this.element[0]);
        this.scope.$on("$destroy", () => {
            this.element.remove();
            this.scope = null;
        });
    }

    remove() {
        if (!this.element) return;
        this.scope.$destroy();
        this.scope = null;
        this.element.remove();
        this.element = null;
    }
    
    show() {
        throw "Not implemented";
    }
    hide() {
        throw "Not implemented";
    }

}
