import { createAction, props } from '@ngrx/store';
import { ComponentSettingsBase } from '../../models/component-settings-base';

export const load = createAction('[Discovery BookNow] load', props<{ componentGroupUuid: string }>());
export const loadSuccess = createAction('[Discovery BookNow] loadSuccess', props<{ data: ComponentSettingsBase[] }>());
export const loadFail = createAction('[Discovery BookNow] loadFail', props<{ error: any }>());
export const selectComponent = createAction('[Discovery BookNow] selectComponent', props<{ componentUuid: string }>());

export const commit = createAction('[Discovery BookNow] commit', props<{ componentGroupUuid: string, data: Partial<ComponentSettingsBase> }>());
export const commitSuccess = createAction('[Discovery BookNow] commitSuccess', props<{ data: ComponentSettingsBase }>());
export const commitFail = createAction('[Discovery BookNow] commitFail', props<{ error: any }>());

export const create = createAction('[Discovery BookNow] create', props<{ componentGroupUuid: string, data: Partial<ComponentSettingsBase> }>());
export const createSuccess = createAction('[Discovery BookNow] createSuccess', props<{ data: ComponentSettingsBase }>());
export const createFail = createAction('[Discovery BookNow] createFail', props<{ error: any }>());
