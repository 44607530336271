import { Component, forwardRef, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';
import { ColorEvent } from 'ngx-color';

@Component({
    selector: 'ft-color-editor',
    templateUrl: './color-editor.component.html',
    styleUrls: ['./color-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ColorEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: ColorEditorComponent
        }
    ],
    encapsulation: ViewEncapsulation.None
})
export class ColorEditorComponent extends ModalEditorBase {
    pickerVisible = false;

    constructor() {
        super();
    }

    onChangeComplete(event: ColorEvent) {
        this.value = event.color?.hex;
    }

    showPicker() {
        if (!this.value) {
            this.value = '#fffff';
        }
        this.pickerVisible = true;
    }

    hidePicker() {
        this.pickerVisible = false;
    }
}
