/**
 * Created by ostapenko.r on 11/26/2014.
 */



angular.module('flipto.components.common.rotate', [])
    .directive('ftRotate', function () {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var transition = 'transform 1s'

                element.css({
                    '-moz-transition': transition,
                    '-webkit-transition': transition,
                    'transition': transition
                });

                scope.$watch(function(){
                    return scope.$eval(attrs.degrees);
                }, function(degree){
                    var r = 'rotate(' + degree + 'deg)';
                    element.css({
                        '-moz-transform': r,
                        '-webkit-transform': r,
                        '-o-transform': r,
                        '-ms-transform': r
                    });
                });
            }
        }
    });