/**
 * Created by Roman on 2015-04-03.
 */



angular.module('flipto.components.common.notifications', [])
    .service('notifications', ['$window', function ($window) {

        /**
         * In-app notifications
         * @constructor
         */
        function Notifications() {

        }

        Notifications.prototype.show = function (message) {
            $window.alert(message);
        };

        return new Notifications();

    }]);
