/**
 * Created by Roman on 2015-12-29.
 */


angular.module('flipto.core.api.domains', [])
    .config(['$provide', function ($provide) {

        $provide.factory('Domains', ['urlFormatter', '$http', 'SpacesService', function (urlFormatter, $http, spacesService) {

            /**
             * Domains service
             * @constructor
             */
            function Domains() {
                /**
                 * Get domains
                 * @param propertyUUID
                 * @returns {*}
                 */
                this.getCompanyDomains = function (propertyUUID) {
                    return $http.get(urlFormatter.format('/api/' + spacesService.getCompanyUuid() + '/subdomain', {propertyUUID: propertyUUID}))
                        .then(function (response) {
                            return response.data;
                        });
                };

                /**
                 * Create new domain
                 * @param host
                 * @returns {*}
                 */
                this.createDomain = function (host) {
                    return $http.post('/api/' + spacesService.getCompanyUuid() + '/subdomain', {Host: host})
                        .then(function (response) {
                            return response.data;
                        })
                };

                /**
                 * Update domain
                 * @param model
                 * @returns {*}
                 */
                this.updateDomain = function (model) {
                    return $http.put('/api/' + spacesService.getCompanyUuid() + '/subdomain', model)
                        .then(function (response) {
                            return response.data;
                        })
                };

                /**
                 * Verify domain
                 * @param domain
                 * @returns {*}
                 */
                this.verify = function (domain) {
                    return $http.get(urlFormatter.format('/api/' + spacesService.getCompanyUuid() + '/subdomain/verify', {hostToVerify: domain}, true))
                        .then(function (response) {
                            return response.data;
                        })
                };

                /**
                 * Remove domain
                 * @param subdomainUUID
                 * @returns {*}
                 */
                this.remove = function (subdomainUUID) {
                    return $http.delete(urlFormatter.format('/api/' + spacesService.getCompanyUuid() + '/subdomain', {subdomainUUID: subdomainUUID}, true))
                        .then(function (response) {
                            return response.data;
                        })
                }

            }

            return new Domains();
        }]);
    }]);