import { Directive, Input, ElementRef } from '@angular/core';


@Directive({
    selector: '[ftDisabled]'
})
export class DisabledDirective {

    constructor(private elem: ElementRef) {}

    @Input("ftDisabled")
    set isDisabled(val: boolean) {
        let isDisabled = typeof val === "undefined" || val;
        const elem = (this.elem.nativeElement as HTMLElement);
        elem.style?.setProperty('pointer-events',  isDisabled ? 'none' : '');
        if (isDisabled) {
            elem.setAttribute('disabled', 'disabled');
        } else {
            elem.removeAttribute('disabled');
        }
    }

}
