/**
 * Created by Roman on 2014-10-30.
 */


angular
    .module("flipto.components.imageLibrary")
    .config(['$compileProvider', function ($compileProvider) {
        $compileProvider.directive('ftImageUpload', ['IdentityService', function (identity) {
            return {
                restrict: 'EA',
                templateUrl: '/app/components/image-library/imageUpload.html',
                scope: {
                    'uploadUrl': '=',
                    'onImageUploaded': '&',
                    'onImageUploading': '&'
                },
                link: function (scope, elem) {
                    function getCookie(key) {
                        var cookieArray, cookie, i, index, name;
                        var currentCookieString = document.cookie;
                        var lastCookieString = '';
                        var lastCookies = {};
                    
                        if (currentCookieString !== lastCookieString) {
                            lastCookieString = currentCookieString;
                            cookieArray = lastCookieString.split('; ');
                            lastCookies = {};                    
                            for (i = 0; i < cookieArray.length; i++) {
                                cookie = cookieArray[i];
                                index = cookie.indexOf('=');
                                if (index > 0) { //ignore nameless cookies
                                    name = decodeURIComponent(cookie.substring(0, index));
                                    // the first value that is seen for a cookie is the most
                                    // specific one.  values for the same cookie name that
                                    // follow are for less specific paths.
                                    if (typeof lastCookies[name] === "undefined") {
                                    lastCookies[name] = decodeURIComponent(cookie.substring(index + 1));
                                    }
                                }
                            }
                        }
                        return lastCookies[key];
                    }
                    scope.$watch('uploadUrl', function (uploadUrl) {
                        if (uploadUrl) {
                            new Dropzone(elem.children()[0], {
                                url: scope.uploadUrl, 
                                headers: {"X-XSRF-TOKEN": getCookie("XSRF-TOKEN")},
                                maxFiles: 1,
                                previewsContainer: false,
                                init: function () {

                                    this.on('addedfile', function () {
                                        scope.$apply(function () {
                                            scope.onImageUploading && scope.onImageUploading();
                                            scope.uploadFailed = false;
                                        });
                                    });

                                    this.on('success', function (file, response) {
                                        scope.$apply(function () {
                                            scope.onImageUploaded && scope.onImageUploaded({image: response});
                                        });
                                    });

                                    this.on('error', function () {
                                        scope.$apply(function () {
                                            scope.uploadFailed = true;
                                            scope.onImageUploaded && scope.onImageUploaded();
                                        });
                                        // this.removeAllFiles();
                                    });

                                    this.on('maxfilesexceeded', function (file) {
                                        this.removeAllFiles();
                                        this.addFile(file);
                                        /*this.removeFile(file);*/
                                    });
                                }
                            });
                        }
                    });
                }
            };
        }]);
    }]);