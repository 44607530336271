/**
 * Created by roman.kupin on 10/07/2014.
 */


angular
    .module("flipto.components.touchpoints")
    .directive("ftTouchpointContext",
        [
            function () {

                return {
                    restrict: "A",
                    priority: 5,
                    require: "ftTouchpointContext",
                    scope: {},
                    link: function (scope, element, attrs, touchpointContext) {

                        attrs.$observe("ftTouchpointContext", function (touchpoint) {
                            if (touchpoint) {
                                touchpointContext.setupContext(JSON.parse(touchpoint));
                            }
                        });

                    },
                    controller: [
                        "$scope", "Touchpoint", "_",
                        function ($scope, Touchpoint, _) {

                            var groupUpdate = [
                                { layerName: "pres-tay", type: "confirmation" },
                                { layerName: "post-stay", type: "quote" },
                                { layerName: "homepage", type: "home" }
                            ];

                            /**
                             * Setup touchpoint context
                             * @param touchpoint
                             */
                            this.setupContext = function (touchpoint) {
                                if (touchpoint && touchpoint.layerName) {
                                    $scope.model = {
                                        propertyUuid: touchpoint.propertyUuid,
                                        groupId: touchpoint.groupId,
                                        type: touchpoint.type,
                                        isActive: touchpoint.isActive,
                                        layerName: touchpoint.layerName,
                                        feature: touchpoint.feature
                                    };
                                }
                            };

                            /**
                             * Returns context model
                             * @returns {$scope.model|*}
                             */
                            this.getContext = function () {
                                return $scope.model;
                            };

                            /**
                             * Returns true if status is active
                             * @returns {$scope.model|*|boolean}
                             */
                            this.getStatus = function () {
                                return $scope.model && $scope.model.isActive || false;
                            };

                            /**
                             * Sets touchpoint.isActive field
                             * @param isActive
                             */
                            this.setStatus = function (isActive) {
                                $scope.model.isActive = isActive;
                                Touchpoint[isGroupUpdate() ? "setGroupStatus" : "setStatus"]($scope.model);
                            };


                            /**
                             * Returns true if isActive change should update the whole group
                             * @returns {boolean}
                             */
                            function isGroupUpdate() {
                                return !!_.find(groupUpdate, { layerName: $scope.model.layerName.toLowerCase(), type: $scope.model.type.toLowerCase() });
                            }

                        }
                    ]

                };
            }
        ]);