/**
 * Created by roman.kupin on 3/16/2018.
 */
import * as Constants from "../../../features/advocacy/group/audiences/constants/index";
import dayjs from "dayjs"
const TEMPLATE_URL = require("./createContestModal.html");

export const NAME = "ftCreateContestModal";

export class CreateContestModalComponent {

    private contest = {
        startDate: dayjs(),
        endDate: dayjs().add(3, 'month'),
        name: "",
        sourceContestName: null,
        sourceContestUUID: null
    };
    private sourceContests;
    private clonableContests: Array<any>;
    private propertyUuid: string;
    private onCreated: Function;
    private onCreateFailed: Function;
    private theme: any;
    private contestConstants = Constants.Contests;

    static $inject = ["PhotoContest"];

    constructor(private PhotoContest) {
    }

    $onInit() {
        const emptyContest = {UUID: null, name: "Do not copy"};
        this.sourceContests = this.clonableContests.slice();
        this.sourceContests.unshift(emptyContest);
        this.selectSourceContest(emptyContest);
    }

    create = () => {
        const newContestPromise = this.contest.sourceContestUUID
            ? this.PhotoContest.clone(
                this.propertyUuid,
                {
                    startDate: this.contest.startDate.format('YYYY-MM-DD'),
                    endDate: this.contest.endDate.format('YYYY-MM-DD'),
                    newName: this.contest.name,
                    oldContestUUID: this.contest.sourceContestUUID,
                    theme: this.theme
                })
            : this.PhotoContest.create(
                this.propertyUuid,
                {
                    startDate: this.contest.startDate.format('YYYY-MM-DD'),
                    endDate: this.contest.endDate.format('YYYY-MM-DD'),
                    theme: this.theme,
                    isPrivate: this.theme === this.contestConstants.team && true,
                    languages: [
                        {
                            languageCode: 'en',
                            name: this.contest.name,
                            guestMessage: '',
                            followerMessage: ''
                        }
                    ]
                });
        newContestPromise.then(contestUuid => {
            this.onCreated({contestUuid});
        }, () => {
            this.onCreateFailed();
        });
    };

    selectSourceContest = (contest) => {
        this.contest.sourceContestName = contest.name;
        this.contest.sourceContestUUID = contest.UUID;
    };
}

export const OPTIONS = {
    templateUrl: TEMPLATE_URL,
    controllerAs: "vm",
    controller: CreateContestModalComponent,
    bindings: {
        propertyUuid: "<",
        clonableContests: "<",
        onCreated: "&",
        onCreateFailed: "&",
        theme: "<"
    }
};
