/**
 * Created by roman.kupin on 11/17/2017.
 */

import { injectLoader, injectCoreCharts } from "../googleChartsLoader";

export const NAME = "ftBarChart";

declare let google;

export class BarChartController {

    static $inject = ["$element"];
    static DEFAULT_OPTIONS = {};

    whenChartsReady: Promise<any>;
    container: Node;
    data: any;
    options: any;
    columns: any;

    constructor(private $element) {
        this.container = this.$element[0].querySelector("div");
        this.whenChartsReady = injectLoader().then(injectCoreCharts);
        this.whenChartsReady.then(() => BarChartController.draw(this.data, this.container, this.options, this.columns));
    }

    $onInit() {
        this.whenChartsReady.then(() => BarChartController.draw(this.data, this.container, this.options, this.columns));
    }

    $onChanges() {
        this.whenChartsReady.then(() => BarChartController.draw(this.data, this.container, this.options, this.columns));
    }

    static draw(data, targetElement, options?, columns?) {
        if (!data) return;
        const arrayToDataTable = google.visualization.arrayToDataTable([
            ['', ...data.Rows.map(r => r[0])],
            ['', ...data.Rows.map(r => parseInt(r[1], 10))]
        ]);
        const view = new google.visualization.DataView(arrayToDataTable);
        const opts = {
            width: 600,
            height: 100,
            legend: {
                position: 'none'
            },
            hAxis: {
                ticks: []
            },
            series: [
                {/*color: 'black', */visibleInLegend: false },
                {/*color: 'red', */visibleInLegend: false }
            ],
            isStacked: 'percent'
        };
        let chart = new google.visualization.BarChart(targetElement);
        chart.draw(view, opts || BarChartController.DEFAULT_OPTIONS);
    }
}

export const OPTIONS = {
    template: `<div class="ft-barchart"></div>`,
    controllerAs: "vm",
    controller: BarChartController,
    bindings: {
        "data": "<",
        "options": "<?"
    }
};
