/**
 * Created by Roman on 2015-04-28.
 */

angular.module('flipto.components.curation', [
    'flipto.components.common',
    'flipto.core.api.photoAdvocacy',
    'flipto.core.inherits',
    'flipto.core.lodash',
    'flipto.core.cacheBuster',
    'flipto.core.moment',
    'flipto.core.parse',
    'flipto.core.appLog',
    'flipto.core.environment'
]);
