<ft-modal-editor-wrapper>
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
    <div class="ft2-exclude-editor" #excludeEditor [style]="{'min-width': setMinWidth(excludeEditor)}">
        <div class="ft2-exclude-editor_col">
            <p class="solid-text medium-text mb-1">{{ leftLabel }}</p>
            <ul class="ft2-exclude-editor_list">
                <li class="ft2-exclude-editor-item" *ngFor="let item of leftSideItems" (click)="toggleFlag(item)">
                    <div class="full-width">
                        {{ item[bindLabel] }}
                        <ng-container *ngIf="bindCount">({{ item[bindCount] }})</ng-container>
                        <br>
                        <small *ngIf="item[bindSubLabel]">
                            {{ item[bindSubLabel] }}
                        </small>
                    </div>
                    <mat-icon class="ft2-exclude-editor-item_icon text-gray-400 icon-xs ml-auto"
                              svgIcon="arrow-right"></mat-icon>
                </li>
                <li class="text-muted ft2-exclude-editor-item -disabled"
                    *ngIf="searchCriteria && !leftSideItems.length">
                    No search results
                </li>
            </ul>
        </div>
        <div class="ft2-exclude-editor_col">
            <p class="solid-text medium-text mb-1">{{ rightLabel }}</p>
            <ul class="ft2-exclude-editor_list">
                <li class="ft2-exclude-editor-item pl-2" *ngFor="let item of rightSideItems" (click)="toggleFlag(item)">
                    <mat-icon class="ft2-exclude-editor-item_icon text-gray-400 icon-xs mr-2"
                              svgIcon="arrow-left"></mat-icon>
                    <div class="full-width">
                        {{ item[bindLabel] }}
                        <ng-container *ngIf="bindCount">({{ item[bindCount] }})</ng-container>
                        <br>
                        <small *ngIf="item[bindSubLabel]">
                            {{ item[bindSubLabel] }}
                        </small>
                    </div>
                </li>
                <li class="text-muted ft2-exclude-editor-item -disabled"
                    *ngIf="searchCriteria && !rightSideItems.length">
                    No search results
                </li>
            </ul>
        </div>
        <div class="ft2-input-wrapper full-width">
            <mat-icon class="ft2-input-icon icon-sm text-gray-400 ml-2" svgIcon="search"></mat-icon>
            <input class="ft2-exclude-editor_search ft2-input -large"
                   [(ngModel)]="searchCriteria"
                   [placeholder]="placeholder"
                   type="text">
        </div>
    </div>
</ft-modal-editor-wrapper>
