/**
 * Created by ostapenko.r on 03/19/2015.
 */

angular.module('flipto.core.livePreview', []).config([
	'$provide',
	function($provide) {
		$provide.factory('livePreview', [
			'urlFormatter',
			'$window',
			'$http',
			'environment',
			function(urlFormatter, $window, $http, environment) {
				/**
				 * Live preview service
				 * @constructor
				 */
				function LivePreview() {
					/**
					 * Send preview redeem email
					 * @param model
					 * @returns {HttpPromise}
					 */
					this.redeemEmail = function(model) {
						return $http.post(
							urlFormatter.format(
								'/api/:propertyUUID/properties/layers/homepage/touchpoints/:touchpointUUID/emails/homepage/sendemail',
								model,
								true
							)
						);
					};

					/**
					 * Send followup email
					 * @param model
					 * @returns {HttpPromise}
					 */
					this.followupEmail = function(model) {
						return $http.post(
							urlFormatter.format(
								'/api/:propertyUUID/properties/layers/prestay/touchpoints/:touchpointUUID/emails/followup/sendemail',
								model,
								true
							)
						);
					};

					/**
					 * Send poststay email
					 * @param model
					 * @returns {HttpPromise}
					 */
					this.poststayEmail = function(model) {
						return $http.post(
							urlFormatter.format(
								'/api/:propertyUUID/properties/layers/poststay/touchpoints/:touchpointUUID/emails/invitation-email/sendemail',
								model,
								true
							)
						);
					};

					/**
					 * Send poststay nonview email
					 * @param model
					 * @returns {HttpPromise}
					 */
					this.poststayNonViewEmail = function(model) {
						return $http.post(
							urlFormatter.format(
								'/api/:propertyUUID/properties/layers/poststay/touchpoints/:touchpointUUID/emails/nonview/sendemail',
								model,
								true
							)
						);
					};

					/**
					 * Send poststay afterview email
					 * @param model
					 * @returns {HttpPromise}
					 */
					this.poststayAfterViewEmail = function(model) {
						return $http.post(
							urlFormatter.format(
								'/api/:propertyUUID/properties/layers/poststay/touchpoints/:touchpointUUID/emails/afterview/sendemail',
								model,
								true
							)
						);
					};

					/**
					 * Send poststay no quote
					 * @param model
					 * @returns {HttpPromise}
					 */
					this.poststayNoQuoteEmail = function(model) {
						return $http.post(
							urlFormatter.format(
								'/api/:propertyUUID/properties/layers/poststay/touchpoints/:touchpointUUID/emails/no-quote/sendemail',
								model,
								true
							)
						);
					};

					/**
					 * Send poststay no photo
					 * @param model
					 * @returns {HttpPromise}
					 */
					this.poststayNoPhotoEmail = function(model) {
						return $http.post(
							urlFormatter.format(
								'/api/:propertyUUID/properties/layers/poststay/touchpoints/:touchpointUUID/emails/no-photo/sendemail',
								model,
								true
							)
						);
					};

					/**
					 * Send photo-advocacy finalist email
					 * @param model
					 * @returns {HttpPromise}
					 */
					this.finalistEmail = function(model) {
						return $http.post(
							urlFormatter.format(
								'/api/:propertyUUID/properties/layers/photo-advocacy/touchpoints/:touchpointUUID/emails/finalist/sendemail',
								model,
								true
							)
						);
					};

					/**
					 * Send photo-advocacy finalist reminder email
					 * @param model
					 * @returns {HttpPromise}
					 */
					this.reminderEmail = function(model) {
						return $http.post(
							urlFormatter.format(
								'/api/:propertyUUID/properties/layers/photo-advocacy/touchpoints/:touchpointUUID/emails/finalistreminder/sendemail',
								model,
								true
							)
						);
					};

					/**
					 * Send photo-advocacy finalist activity email
					 * @param model
					 * @returns {HttpPromise}
					 */
					this.activityEmail = function(model) {
						return $http.post(
							urlFormatter.format(
								'/api/:propertyUUID/properties/layers/photo-advocacy/touchpoints/:touchpointUUID/emails/finalistactivity/sendemail',
								model,
								true
							)
						);
					};

					/**
					 * Send photo-advocacy winner email
					 * @param model
					 * @returns {HttpPromise}
					 */
					this.winnerEmail = function(model) {
						return $http.post(
							urlFormatter.format(
								'/api/:propertyUUID/properties/layers/photo-advocacy/touchpoints/:touchpointUUID/emails/winnernotification/sendemail',
								model,
								true
							)
						);
					};

					/**
					 * Send test email
					 * @param model
					 * @returns {HttpPromise}
					 */
					this.sendTestEmail = function(model) {
						return $http.post(
							urlFormatter.format(
								'/api/:propertyUuid/properties/:propertyUuid/communication/send-sample-email',
								model,
								true
							)
						);
					};

					/**
					 * Show test email html
					 * @param model
					 */
					this.showTestEmail = function(model) {
						var window = $window.open('');
						var url = urlFormatter.format(
							'/api/:propertyUuid/properties/:propertyUuid/communication/get-sample-email-html',
							model,
							true
						);
						$http.get(url).then(
							function(response) {
								window.document.write(response.data);
								window.document.close();
							},
							function() {
								window.close();
							}
						);
					};

					/**
					 * Open confirmation page live preview
					 * @param model
					 * @returns {*}
					 */
					this.confirmation = function(model) {
						var url = urlFormatter.combineUrlPath(
							environment.v1BaseUrl,
							urlFormatter.format(
								'/demo/prestay/confirmation?cid=:fullCode&lang=:code&debug=1',
								model,
								true
							)
						);
						$window.open(url);
					};

					/**
					 * Open landing page live preview
					 * @param model
					 * @returns {*}
					 */
					this.landing = function(model) {
						var url = urlFormatter.format(':code-i?debug=1', model);
						$window.open(model.domain + url);
					};

					/**
					 * Open mobile landing page live preview
					 * @param model
					 * @returns {*}
					 */
					this.mobileLanding = function(model) {
						var url = urlFormatter.format(':code-i?mobile=true', model);
						$window.open(model.domain + url);
					};

					/**
					 * Open followup page live preview
					 * @param model
					 * @returns {*}
					 */
					this.followup = function(model) {
						var url = urlFormatter.format(':code/share', model);
						$window.open(model.domain + url);
					};

					/**
					 * Show poststay live preview
					 */
					this.poststay = function(model) {
						var window = $window.open('');
						$http
							.get(
								urlFormatter.format(
									'/api/:propertyUUID/properties/:propertyUUID/layers/poststay/preview-url',
									model,
									true
								)
							)
							.then(function(response) {
								window.location = response.data;
							});
					};

					/**
					 * Open photo river page live preview
					 * @param model
					 * @returns {*}
					 */
					this.photoRiver = function(model) {
						var url = urlFormatter.combineUrlPath(
							environment.v1BaseUrl,
							urlFormatter.format('/demo/photo-river?c=:fullCode&language=:code&debug=1', model)
						);
						$window.open(url);
					};

					/**
					 * Open privacy page live preview
					 * @param model
					 * @returns {*}
					 */
					this.privacyPage = function(model) {
						var url = urlFormatter.combineUrlPath(
							environment.v1BaseUrl,
							urlFormatter.format('/demo/privacy/:fullCode', model)
						);
						$window.open(url);
					};

					/**
					 * Open custom demo page live preview
					 * @param model
					 * @returns {*}
					 */
					this.customDemoPage = function(model) {
						model.debug = 1;
						var url = urlFormatter.combineUrlPath(
							environment.v1BaseUrl,
							urlFormatter.format('/demo/custom/:assetDemoUUID', model, true)
						);
						$window.open(url);
					};
				}

				return new LivePreview();
			}
		]);
	}
]);
