const loadAuthChildren = () => import('../features/authentication/authentication.module').then(mod => mod.AuthenticationModule);

export const GLOBAL_STATES = [
    {
        name: 'sign-in.**',
        url: '/sign-in',

        loadChildren: loadAuthChildren
    },
    {
        name: 'recover-password.**',
        url: '/recover-password',
        loadChildren: loadAuthChildren
    },
    {
        name: 'change-password.**',
        url: '/change-password',
        loadChildren: loadAuthChildren
    },
    {
        name: 'create-password.**',
        url: '/create-password',
        loadChildren: loadAuthChildren
    },
    {
        name: 'profile.**',
        url: '/profile',
        loadChildren: loadAuthChildren
    }
];


export const ADMIN_STATES = [
    {
        name: 'admin-area.**',
        url: '/admin-area',
        loadChildren: () => import('../features/admin-area/admin-area.module').then(mod => mod.AdminAreaModule)
    }
];
export const PROPERTY_SPACE_STATES = [
    {
        name: 'advocacy-new.**',
        url: '/{type:\\w}/{slug}/advocacy-new',
        params: {
            type: { dynamic: true },
            slug: { dynamic: true }
        },
        loadChildren: () => import('../features/advocacy/advocacy.module').then(mod => mod.AdvocacyModule)
    },
    {
        name: 'discovery.**',
        url: '/{type:\\w}/{slug}/discovery',
        params: {
            type: { dynamic: true },
            slug: { dynamic: true }
        },
        loadChildren: () => import('../features/discovery/discovery.module').then(mod => mod.DiscoveryModule)
    },
    {
        name: 'mission-control.**',
        url: '/{type:\\w}/{slug}/mission-control',
        params: {
            type: { dynamic: true },
            slug: { dynamic: true }
        },
        loadChildren: () => import('../features/mission-control/mission-control.module').then(mod => mod.MissionControlModule)
    },
    {
        name: 'reports.**',
        url: '/{type:\\w}/{slug}/reports',
        params: {
            type: { dynamic: true },
            slug: { dynamic: true }
        },
        loadChildren: () => import('../features/reports/reports.module').then(mod => mod.ReportsModule)
    },
    {
        name: 'incentives.**',
        url: '/{type:\\w}/{slug}/incentives',
        params: {
            type: { dynamic: true },
            slug: { dynamic: true }
        },
        loadChildren: () => import('../features/incentives/incentives.module').then(mod => mod.IncentivesModule)
    },
    {
        name: 'hub.**',
        url: '/{type:\\w}/{slug}',
        params: {
            type: { dynamic: true },
            slug: { dynamic: true }
        },
        loadChildren: () => import('../features/hub/hub.module').then(mod => mod.HubModule)
    },
    {
        name: 'experiences.**',
        url: '/{type:\\w}/{slug}/experiences',
        params: {
            type: { dynamic: true },
            slug: { dynamic: true }
        },
        data: {
            isFliptoStaff: true,
        },
        loadChildren: () => import('../features/experiences/experiences.module').then(mod => mod.ExperiencesModule)
    }
];
export const MIXED_SPACE_STATES = [
    {
        name: 'advocacy-new.**',
        url: '/{type:\\w}/{slug}/advocacy-new',
        params: {
            type: { dynamic: true },
            slug: { dynamic: true }
        },
        loadChildren: () => import('../features/advocacy/advocacy.module').then(mod => mod.AdvocacyModule)
    },
    {
        name: 'discovery.**',
        url: '/{type:\\w}/{slug}/discovery',
        params: {
            type: { dynamic: true },
            slug: { dynamic: true }
        },
        loadChildren: () => import('../features/discovery/discovery.module').then(mod => mod.DiscoveryModule)
    },
    {
        name: 'account-settings.**',
        url: '/{type:\\w}/{slug}/account-settings',
        params: {
            type: { dynamic: true },
            slug: { dynamic: true }
        },
        loadChildren: () => import('../features/account-settings/account-settings.module').then(mod => mod.AccountSettingsModule)
    },
    {
        name: 'team.**',
        url: '/{type:\\w}/{slug}/team',
        params: {
            type: { dynamic: true },
            slug: { dynamic: true }
        },
        loadChildren: () => import('../features/team/team.module').then(mod => mod.TeamModule)
    },
    {
        name: 'mission-control.**',
        url: '/{type:\\w}/{slug}/mission-control',
        params: {
            type: { dynamic: true },
            slug: { dynamic: true }
        },
        loadChildren: () => import('../features/mission-control/mission-control.module').then(mod => mod.MissionControlModule)
    },
    {
        name: 'reports.**',
        url: '/{type:\\w}/{slug}/reports',
        params: {
            type: { dynamic: true },
            slug: { dynamic: true }
        },
        loadChildren: () => import('../features/reports/reports.module').then(mod => mod.ReportsModule)
    },
    {
        name: 'incentives.**',
        url: '/{type:\\w}/{slug}/incentives',
        params: {
            type: { dynamic: true },
            slug: { dynamic: true }
        },
        loadChildren: () => import('../features/incentives/incentives.module').then(mod => mod.IncentivesModule)
    },
    {
        name: 'hub.**',
        url: '/{type:\\w}/{slug}',
        params: {
            type: { dynamic: true },
            slug: { dynamic: true }
        },
        loadChildren: () => import('../features/hub/hub.module').then(mod => mod.HubModule)
    },
    {
        name: 'experiences.**',
        url: '/{type:\\w}/{slug}/experiences',
        params: {
            type: { dynamic: true },
            slug: { dynamic: true }
        },
        data: {
            isFliptoStaff: true,
        },
        loadChildren: () => import('../features/experiences/experiences.module').then(mod => mod.ExperiencesModule)
    },
];
export const COMPANY_SPACE_STATES = [
    {
        name: 'advocacy-new.**',
        url: '/{type:\\w}/{slug}/advocacy-new',
        loadChildren: () => import('../features/advocacy/advocacy.module').then(mod => mod.AdvocacyModule)
    },
    {
        name: 'reports.**',
        url: '/{type:\\w}/{slug}/reports',
        loadChildren: () => import('../features/reports/reports.module').then(mod => mod.ReportsModule),
        params: {
            type: { dynamic: true },
            slug: { dynamic: true }
        },
    },
    {
        name: 'discovery.**',
        url: '/{type:\\w}/{slug}/discovery',
        params: {
            type: { dynamic: true },
            slug: { dynamic: true }
        },
        loadChildren: () => import('../features/discovery/discovery.module').then(mod => mod.DiscoveryModule)
    },
    {
        name: 'account-settings.**',
        url: '/{type:\\w}/{slug}/account-settings',
        params: {
            type: { dynamic: true },
            slug: { dynamic: true }
        },
        loadChildren: () => import('../features/account-settings/account-settings.module').then(mod => mod.AccountSettingsModule)
    },
    {
        name: 'team.**',
        url: '/{type:\\w}/{slug}/team',
        params: {
            type: { dynamic: true },
            slug: { dynamic: true }
        },
        loadChildren: () => import('../features/team/team.module').then(mod => mod.TeamModule)
    },
    {
        name: 'hub.**',
        url: '/{type:\\w}/{slug}',
        params: {
            type: { dynamic: true },
            slug: { dynamic: true }
        },
        loadChildren: () => import('../features/hub/hub.module').then(mod => mod.HubModule)
    }
];
export const COLLABORATIVE_COMPANY_SPACE_STATES = [
    {
        name: 'collaborative.**',
        url: '/{type:\\w}/{slug}',
        params: {
            type: { dynamic: true },
            slug: { dynamic: true }
        },
        loadChildren: () => import('../features/collaborative/collaborative.module').then(mod => mod.CollaborativeModule)
    },
    {
        name: 'account-settings.**',
        url: '/{type:\\w}/{slug}/account-settings',
        params: {
            type: { dynamic: true },
            slug: { dynamic: true }
        },
        loadChildren: () => import('../features/account-settings/account-settings.module').then(mod => mod.AccountSettingsModule)
    },
    {
        name: 'team.**',
        url: '/{type:\\w}/{slug}/team',
        params: {
            type: { dynamic: true },
            slug: { dynamic: true }
        },
        loadChildren: () => import('../features/team/team.module').then(mod => mod.TeamModule)
    },
    {
        name: 'members.**',
        url: '/{type:\\w}/{slug}/members',
        params: {
            type: { dynamic: true },
            slug: { dynamic: true }
        },
        loadChildren: () => import('../features/members/members.module').then(mod => mod.MembersModule)
    }
];
