

angular.module('flipto.components.common.modelOperations')
    .directive('ftAddArrayItem', [function () {
        return {
            restrict: 'A',
            require: ['^form', 'ngModel'],
            link: function (scope, element, attrs, ctrls) {

                var formCtrl = ctrls[0],
                    ngModelCtrl = ctrls[1];

                formCtrl.$$element.on("submit", function () {
                    if(formCtrl.$valid) {
                        scope.$apply(function(){
                            const array = scope.$eval(attrs.ftAddArrayItem);
                            if(array.indexOf(ngModelCtrl.$viewValue) === -1) {
                                array.push(ngModelCtrl.$viewValue);
                            }
                        });
                    }
                });

                scope.$on("$destroy", function(){
                    formCtrl.$$element.off("submit");
                });
            }
        };
    }])