
export const NAME = "ftBroadcastChooseGoal";

export class BroadcastChooseGoalController {

    story;
    contests;

    isQuoteOnly = false;

    onInviteToStory;
    onInviteToContest;
    onInviteToWebsite: any;

    $onInit() {
        if (!!this.story) {
            this.isQuoteOnly = !this.story.guest.poststay.photo;
        }
    }

    $onChanges() {
        if (!!this.story) {
            this.isQuoteOnly = !this.story.guest.poststay.photo;
        }
    }

    inviteToGuestPage() {
        if (this.onInviteToStory) {
            this.onInviteToStory();
        }
    }

    inviteToContest(contest) {
        if (this.onInviteToContest) {
            this.onInviteToContest({ contest });
        }
    }

    inviteToWebsite() {
        if (this.onInviteToWebsite) {
            this.onInviteToWebsite();
        }
    }

}


export const OPTIONS = {
    bindings: {
        story: "<?",
        contests: "<?",
        onInviteToStory: "&?",
        onInviteToContest: "&?",
        onInviteToWebsite: "&?"
    },
    template: `
            <div class="ft-goal-options" ng-if="!vm.isQuoteOnly">
                <div class="ft-option generate-leads"
                    ng-if="!!vm.story"
                    ng-click="vm.inviteToGuestPage()">
                    <div class="ft-option-header">
                        <div class="ft-v-align-container">
                            <div class="ft-v-align-content">
                                <div class="ft-supertitle">Get warm leads through</div>
                                <div class="ft-option-title">Story Explorer</div>
                            </div>
                        </div>
                    </div>
                    <div class="ft-option-body ft-text-minus-1">
                        <p>Inspire your audience to take interest in a future stay while they check out this and other stories.</p>
                    </div>
                </div>
                <div class="ft-option generate-storytellers"
                    ng-repeat="contest in vm.contests"
                    ng-if="(contest.isActive | parse:'bool') && !(contest.isClosed | parse:'bool') && (contest.endDate | daysUntilDate) >= 1"
                    ng-click="vm.inviteToContest(contest)">
                    <div class="ft-option-header">
                        <div class="ft-v-align-container">
                            <div class="ft-v-align-content">
                                <div class="ft-supertitle">Invite people to join</div>
                                <div class="contest-title">{{contest.name}}</div>
                                <div class="contest-date-range">{{contest.startDate | dateRange:contest.endDate}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="ft-option-body ft-text-minus-1">
                        <p>Discover new storytellers and engage friends & family with their best travel moments.</p>
                    </div>
                </div>
            </div>
            <div class="ft-goal-options" ng-if="vm.isQuoteOnly">
                <div class="ft-option generate-leads1"
                    ng-click="vm.inviteToWebsite()">
                    <div class="ft-option-header">
                        <div class="ft-v-align-container">
                            <div class="ft-v-align-content">
                                <div class="ft-supertitle">Attract visitors to</div>
                                <div class="ft-option-title">Your website</div>
                            </div>
                        </div>
                    </div>
                    <div class="ft-option-body ft-text-minus-1">
                        <p>Show your audience what your recent travelers have to say about their experience and provide a link to check out your website.</p>
                    </div>
                </div>
            </div>
    `,
    controller: BroadcastChooseGoalController,
    controllerAs: "vm"
};
