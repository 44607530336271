/**
 * Created by Roman on 2016-10-06.
 */


angular.module("flipto.components.common.carousel", [])
    .directive("ftCarousel", [
        function() {
            return {
                restrict: "E",
                transclude: true,
                templateUrl: "/app/components/common/carousel/carousel.html",
                scope: {
                    'navigated': "&onNavigated"
                },
                link: function(scope) {

                    scope.select = function(item) {
                        angular.forEach(scope.items, function(i) { i.isVisible = false; });
                        item.isVisible = true;
                        scope.navigated({ association: item.association });
                    };

                    scope.left = function() {
                        if (scope.canGoLeft()) {
                            scope.items[scope.index].isVisible = false;
                            scope.index--;
                            scope.items[scope.index].isVisible = true;
                            scope.navigated({ association: scope.items[scope.index].association });
                        }
                    };

                    scope.right = function() {
                        if (scope.canGoRight()) {
                            scope.items[scope.index].isVisible = false;
                            scope.index++;
                            scope.items[scope.index].isVisible = true;
                            scope.navigated({ association: scope.items[scope.index].association });
                        }
                    };

                    scope.canGoLeft = function() {
                        return scope.index > 0;
                    };

                    scope.canGoRight = function() {
                        return scope.index < scope.items.length - 1;
                    };


                },
                controller: ["$scope", function($scope) {
                    $scope.items = [],
                        $scope.index = 0;

                    this.addItem = function(item) {
                        item.isVisible = !$scope.items.length;
                        $scope.items.push(item);
                    };
                }]
            };
        }
    ])
    .directive("ftCarouselItem", [
        function() {
            return {
                restrict: "E",
                transclude: true,
                scope: true,
                template: "<span ng-transclude ng-show=\"item.isVisible\"></span>",
                require: "^ftCarousel",
                link: function(scope, elem, attrs, ctrl) {
                    var association = scope.$eval(attrs.associateWith);
                    scope.item = { element: elem, association: association };
                    ctrl.addItem(scope.item);
                }
            };
        }
    ]);