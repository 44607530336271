/**
 * Created by roman.kupin on 2/24/2017.
 */



angular.module("flipto.components.ratingsMeter", [])
    .directive("ftRatingsMeter", [function () {

        return {
            restrict: "E",
            templateUrl: "/app/components/ratings-meter/ratingsMeter.html",
            scope: {
                "ratings": "="
            }
        };
    }]);