import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, mergeMap, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { select, Store } from "@ngrx/store";
import { NotificationsService } from "../services/notifications.service";
import { AppNotification } from "../models/notification.model";
import * as fromNotifications from "../store";
import {
    loadNotifications,
    loadNotificationsFailure,
    loadNotificationsSuccess
} from "../store/notifications.actions";
import {
    loadLatestBadge,
    setLatestBadge,
    updateSeenDateOfNotifications,
    updateSeenDateOfNotificationsSuccess
} from "../store";
import moment from 'moment';


@Injectable()
export class NotificationsEffects {
    loadNotifications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadNotifications),
            mergeMap(
                () => this.notificationsService.getNotifications()
                    .pipe(
                        map((notifications: AppNotification[]) => {
                            notifications.forEach(notification => {
                                notification.isScheduled = moment(notification.startDate).isAfter();
                            });
                            return loadNotificationsSuccess({notifications});
                        }),
                        catchError(error => {
                            return of(loadNotificationsFailure(error));
                        })
                    )
            )
        )
    );

    loadNotificationsSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadNotificationsSuccess),
            map((payload) => payload.notifications),
            filter((notifications) => !!notifications && !!notifications.length),
            switchMap((notifications: AppNotification[]) => {
                let latestBadge = null;
                const latestNotifications = notifications
                    .filter((n) => n.startDate && !n.seenDate);
                if (latestNotifications.length) {
                    latestBadge = latestNotifications[0].badge;
                }
                return of(setLatestBadge({latestBadge}));
            })
        )
    );

    updateSeenDateOfNotifications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateSeenDateOfNotifications),
            withLatestFrom(
                this.store.pipe(select(fromNotifications.getNewNotifications))
            ),
            mergeMap(
                ([{seenDate}, notifications]) => {
                    return forkJoin(notifications.map((notification) => this.notificationsService.updateNotificationByUuid(notification.uuid, {seenDate}))).pipe(
                        map((updatedNotifications: AppNotification[]) => {
                            return updateSeenDateOfNotificationsSuccess({notifications: updatedNotifications});
                        }),
                        catchError(error => {
                            return of(loadNotificationsFailure(error));
                        })
                    );
                }
            )
        )
    );


    getLatestBadge$ = createEffect(() =>
            this.actions$.pipe(
                ofType(loadLatestBadge),
                tap(() => this.store.dispatch(fromNotifications.loadNotifications())),
                switchMap(() => this.store.select(fromNotifications.getNewNotifications)),
            ),
        {dispatch: false}
    );

    constructor(private actions$: Actions,
                private store: Store<fromNotifications.NotificationsState>,
                private notificationsService: NotificationsService) {
    }
}


