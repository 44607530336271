<div 
  [hidden]="isHiddenCuration()"
  class="ft2-curation-banner-container" *ngIf="!isStoriesDetailPage">
  <div
    class="ft2-container p-5 ft2-curation-banner ft2-striped-background">
    <div
      class="ft2-curation-alert-bg"
      *ngIf="curationHandlerService.getTotalNotCurated(photosCuration) !== 0">
      <div class="ft2-curation-alert-bg-photos">
          <div
            class="ft2-curation-alert-bg-photos-photo"
            *ngFor="let story of photosCuration | slice:0:5" [ftBackgroundImage]="story.photo.photoUUID | ftAzureImageUrl"
          ></div>
      </div>
   </div>
    <p class="huge-text">Curate your newest stories</p>
    <div>
      <span *ngIf="!!totalNotCurrated?.photos">
        {{totalNotCurrated.photos === 1 ? totalNotCurrated.photos + ' photo' : totalNotCurrated.photos + ' photos'}}
      </span>
      <span *ngIf="!!curationHandlerService.getTotalNotCurated(quotesCuration)">
        {{curationHandlerService.getTotalNotCurated(quotesCuration) === 1 ? curationHandlerService.getTotalNotCurated(quotesCuration) + ' quote' : curationHandlerService.getTotalNotCurated(quotesCuration) + ' quotes'}}
      </span>
    </div>
    <div class="mt-5">
      <ft-curation-modal
        [data]="data$ | async"
        [notCuratedStory]="notCuratedStory"
        [totalNumber]="totalNumber"
        (setRate)="updateRating($event)"
        (next)="updateData($event)"
        (close)="updateStories.emit(); loadStoriesIsCurationPhotos(filters[0].query)"
        (advance)="advanceToFinal($event)">
          <button class="ft2-btn-danger uppercase -huge" (click)="loadCurationModalData(currentIndex)">
            <mat-icon class="mr-2" svgIcon="timestamp"></mat-icon> Start curating
          </button>
      </ft-curation-modal>
      <p class="mt-2 medium-text muted-text">
        Or <a class="underline" (click)="navigateToStories()">view your curation list</a>
      </p>
    </div>
  </div>
</div>

<ft-curation-modal
  *ngIf="isStoriesDetailPage"
  [data]="data$ | async"
  [showTimeline]="showTimeline"
  [notCuratedStory]="notCuratedStory"
  [totalNumber]="0"
  (setRate)="updateRating($event)"
  (next)="updateData($event)"
  (advance)="advanceToFinal($event)">
    <div (click)="loadCurationModalData()">
      <ng-content></ng-content>
    </div>
</ft-curation-modal>
