import { createAction, props } from '@ngrx/store';
import { PhotoDtoModel } from 'account-hybrid/common/models/photo-dto.model';

export const loadRandomShowcasePhotos = createAction(
    '[Showcase] Load Random Showcase Photos',
    props<{
        companyUuid: string;
    }>()
);

export const loadRandomShowcasePhotosSuccess = createAction(
    '[Showcase] Load Random Showcase Photos Success',
    props<{
        photos: PhotoDtoModel[];
    }>()
);

export const loadRandomShowcasePhotosFailure = createAction(
    '[Showcase] Load Random Showcase Photos Failure',
    props<{
        error: any;
    }>()
);
