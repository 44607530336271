/**
 * Created by ostapenko.r on 2/3/2015.
 */

angular.module('flipto.core.api.reports', []).config([
	'$provide',
	function($provide) {
		$provide.factory('Reports', [
			'$http',
			'urlFormatter',
			'SpacesService',
			function($http, urlFormatter, spacesService) {
				/**
				 * Reports service
				 * @constructor
				 */
				function Reports() {
					/**
					 * Download report
					 * @param id
					 * @param model
					 * @returns {*}
					 */
					this.download = function(id, model) {
						model.id = id;
						return $http
							.get(urlFormatter.format('/api/' + spacesService.getCompanyUuid() +'/account/reports/:id', model, true))
							.then(function(response) {
								return {
									content: response.data,
									fileName: response.headers('content-disposition').match(/filename="(.*)"/)[1]
								};
							});
					};

					/**
					 * Get Array of all admin reports
					 * @returns {*}
					 */
					this.getAdminReports = function() {
						return $http.get(urlFormatter.format('/api/admin/reports', {}, true)).then(function(response) {
							return response;
						});
					};

					/**
					 * Get GA auth token
					 * @returns {*}
					 */
					this.getAuthToken = function() {
						return $http.get(urlFormatter.format('/api/' + spacesService.getCompanyUuid() +'/admin/ga/auth', {}, true)).then(function(response) {
							return response;
						});
					};
					this.getGaJson = function() {
						return $http
							.get(urlFormatter.format('/api/' + spacesService.getCompanyUuid() +'/admin/ga/report', {}, true))
							.then(function(response) {
								return response;
							});
					};
				}

				return new Reports();
			}
		]);
	}
]);
