<ft-modal-editor-wrapper [canSave]="canSave">
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
    <ng-container *ngFor="let step of stepTemplates; let i = index;">
        <ng-container *ngIf="i === activeStepIndex">
            <ng-container *ngTemplateOutlet="step"></ng-container>
        </ng-container>
    </ng-container>
    <div class="ft2-edit-dialog_footer" *ngIf="!canSave">
        <button class="ft2-btn-primary -large px-6"
                type="submit"
                (click)="onNext()"
                [disabled]="visible && isInvalid()">
            Next
        </button>
    </div>
</ft-modal-editor-wrapper>
