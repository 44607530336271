import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { DiscoveryComponentType } from '../../enums';
import { IDiscoveryInjectableComponentSettings, IDiscoveryInjectableComponentMessaging } from '../../interfaces';
import { DiscoveryApiService } from '../../services/discovery-api.service';

import * as fromTouchpoints from '../touchpoints.state';
import * as fromActions from './discovery-injectable-component.actions';
import * as fromDiscoveryComponentActions from '../discovery-component/discovery-component.actions';
import { DiscoveryComponentPlannerFlowViewModel, DiscoveryComponentSettings } from '../../models';
import { selectCurrentDisplayedDiscoveryVersion } from 'account-hybrid/features/discovery/store';

@Injectable()
export class DiscoveryInjectableComponentEffects {

    loadDiscoveryInjectableComponentSettings$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.loadDiscoveryInjectableComponentSettings),
        withLatestFrom(this.store$.pipe(select(selectCurrentDisplayedDiscoveryVersion))),
        mergeMap(([{
            componentGroupUuid,
            componentType
        }, version]) => this.discoveryApiService.getDiscoveryInjectableComponentSettings(componentGroupUuid, componentType, version).pipe(
            map(
                (injectableComponentSettings) => fromActions.loadDiscoveryInjectableComponentSettingsSuccess({
                    injectableComponentSettings,
                    componentType
                })
            ),
            catchError(error => of(fromActions.loadDiscoveryInjectableComponentSettingsFailure({ error })))
        ))
    ));

    commitDiscoveryInjectableComponentSettings$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.commitDiscoveryInjectableComponentSettings),
        withLatestFrom(this.store$, this.store$.pipe(select(selectCurrentDisplayedDiscoveryVersion))),
        mergeMap(([{ componentGroupUuid, componentType, settings }, storeState, version]) => {
            const actionArr = [];
            if (!!settings.plannerFlowData) {
                const discoSettings: Partial<DiscoveryComponentSettings> = {
                    plannerFlowViewModel: settings.plannerFlowData as DiscoveryComponentPlannerFlowViewModel
                };
                actionArr.push(fromDiscoveryComponentActions.commitDiscoveryComponentSettings({
                    componentGroupUuid,
                    settings: discoSettings,
                    version
                }));
            }
            let componentUuid = null;
            if (componentType === DiscoveryComponentType.BookingBar) {
                componentUuid = storeState.touchpoints?.discoveryInjectableComponents?.selectedBookingBarComponentUuid;
            } else if (componentType === DiscoveryComponentType.BookingButton) {
                componentUuid = storeState.touchpoints?.discoveryInjectableComponents?.selectedBookingButtonComponentUuid;
            } else if (componentType === DiscoveryComponentType.DiscoveryBar) {
                componentUuid = storeState.touchpoints?.discoveryInjectableComponents?.selectedDiscoveryBarComponentUuid;
            }
            return this.discoveryApiService
                .commitDiscoveryInjectableComponentSettings(componentGroupUuid, componentType, {
                    componentUuid,
                    ...settings
                }, version).pipe(
                    mergeMap((response: Partial<IDiscoveryInjectableComponentSettings<IDiscoveryInjectableComponentMessaging>>) => {
                        actionArr.push(fromActions.commitDiscoveryInjectableComponentSettingsSuccess({
                            componentType,
                            settings: response
                        }));
                        return actionArr;
                    }),
                    catchError(error => of(fromActions.commitDiscoveryInjectableComponentSettingsFailure({ error })))
                );
        })
    ));

    createDiscoveryInjectableComponentSettings$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.createDiscoveryInjectableComponentSettings),
        mergeMap(({
                      componentGroupUuid,
                      componentType,
                      settings
                  }) => this.discoveryApiService.createDiscoveryInjectableComponentSettings(componentGroupUuid, componentType, settings).pipe(
            mergeMap((response: Partial<IDiscoveryInjectableComponentSettings<IDiscoveryInjectableComponentMessaging>>) => [
                fromActions.createDiscoveryInjectableComponentSettingsSuccess({ componentType, settings: response })
            ]),
            catchError(error => of(fromActions.createDiscoveryInjectableComponentSettingsFailure({ error })))
        ))
    ));

    constructor(
        private actions$: Actions,
        private store$: Store<fromTouchpoints.State>,
        private discoveryApiService: DiscoveryApiService
    ) {
    }
}
