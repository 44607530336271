import { Pipe, PipeTransform } from '@angular/core';
import { ConfirmationExperienceDisplayId } from '../models/confirmation-experience';

@Pipe({
  name: 'confirmationDisplayType'
})
export class ConfirmationDisplayTypePipe implements PipeTransform {
  confirmationExperienceDisplayId = ConfirmationExperienceDisplayId;

  transform(value: any): any {
    let displayType = this.confirmationExperienceDisplayId.find(displayType => displayType.id == value);
    return displayType ? displayType.name : '';
  }

}
