/**
 * Created by ostapenko.r on 2/5/2015.
 */

angular.module('flipto.components.common.download', ['flipto.components.common.tasks'])
    .config(['$compileProvider', function ($compileProvider) {

        $compileProvider.directive('ftDownload', ['fileSaver', 'fileDownloader', 'ftTasks', function (fileSaver, fileDownloader, ftTasks) {
            return {
                restrict: 'E',
                templateUrl: '/app/components/common/download/download.html',
                scope: {
                    'taskName': '@'
                },
                link: function (scope, element, attr) {
                    var contentType = '';

                    switch (attr.type) {
                        case 'csv':
                            contentType += 'text/csv;charset=utf-8';
                            break;
                        case 'pdf':
                            contentType += 'pdf;base64';
                            break;
                        default:
                            contentType += 'text/csv;charset=utf-8'
                    }

                    scope.download = function () {
                        if (scope.taskName) { ftTasks.register(scope.taskName); }
                        fileDownloader.download(attr.url)
                            .then(function (data) {
                                fileSaver.saveContent(data.content, contentType, attr.filename || data.filename);
                                if (scope.taskName) { ftTasks.finish(scope.taskName); }

                            }, function () {
                                if (scope.taskName) { ftTasks.finish(scope.taskName); }
                            });
                    };

                }
            };
        }]);
    }]);