import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { filter, take } from 'rxjs/operators';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';
import { OccupancyAllowanceType } from '../../../models/occupancy-allowance-type.enum';


@Component({
    selector: 'ft-occupancy-editor',
    templateUrl: './occupancy-editor.component.html',
    styleUrls: ['./occupancy-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => OccupancyEditor)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: OccupancyEditor
        }
    ]
})
export class OccupancyEditor extends ModalEditorBase implements OnInit {
    @Input() isPropertySpace = false;
    lastMaxChildren = 10;
    occupancyAllowanceOptions = Object.values(OccupancyAllowanceType)
        .filter(i => isNaN(Number(i)))
        .map((item: string) => ({ 
            text: item,
            value: OccupancyAllowanceType[item],
            checked: true,
            enabled: OccupancyAllowanceType[item] !== OccupancyAllowanceType.Kids
        }));
    occupancyAllowanceEnum = OccupancyAllowanceType;


    get isChildrenAllowed(): boolean {
        return this.occupancyAllowanceOptions.some(item => item.value === OccupancyAllowanceType.Kids && item.checked);
    }

    ngOnInit() {
        this.value$
            .pipe(
                filter(value => !!value),
                take(1)
            )
            .subscribe(() => {
                this.updateInitialValue();
            });
    }

    onEditorVisible() {
        this.value$
            .pipe(
                filter(value => !!value),
                take(1)
            )
            .subscribe(value => {
                const kidsOption = this.occupancyAllowanceOptions.find(item => item.value === OccupancyAllowanceType.Kids)
                kidsOption.checked = (value && value.maxChildren > 0);
            });
    }

    onOccupancyAllowanceChanged(enabledFlag: boolean, option: { value: OccupancyAllowanceType }) {
        switch (option.value){
            case OccupancyAllowanceType.Kids: {
                const tempMaxChildren = this.lastMaxChildren;
                this.lastMaxChildren = this.value.maxChildren;
                this.value = {
                    ...this.value,
                    maxChildren: enabledFlag ? tempMaxChildren : 0
                }
            }
        }
    }

    applyChanges() {
        super.applyChanges();
        this.updateInitialValue();
    }

    isInvalid() {
        if (this.controlsModelRef?.length) {
            return this.controlsModelRef.some(control => control.invalid) ||
                this.checkMinAdultsOverflow(this.value) ||
                this.checkMinChildrenOverflow(this.value) ||
                this.checkDefaultAdultsOverflow(this.value) ||
                this.checkDefaultChildrenOverflow(this.value);
        }
    }

    validate(value): ValidationErrors {
        if (this.checkMinAdultsOverflow(value)) {
            return { minAdultsOverflow: true };
        }
        if (this.checkMinChildrenOverflow(value)) {
            return { minChildrenOverflow: true };
        }
        if (this.checkDefaultAdultsOverflow(value)) {
            return { defaultAdultsOverflow: true };
        }
        if (this.checkDefaultChildrenOverflow(value)) {
            return { defaultChildrenOverflow: true };
        }
        return null;
    }

    checkMinAdultsOverflow(value): boolean {
        return this.isOver(value?.minAdults, value?.maxAdults);
    }

    checkDefaultAdultsOverflow(value): boolean {
        return this.isOver(value?.defaultAdults, value?.maxAdults) || (value?.defaultAdults < value?.minAdults);
    }

    checkMinChildrenOverflow(value): boolean {
        return this.isOver(value?.minChildren, value?.maxChildren);
    }

    checkDefaultChildrenOverflow(value): boolean {
        return this.isOver(value?.defaultChildren, value?.maxChildren) || (value?.defaultChildren < value?.minChildren);
    }

    isOver(x: number, y: number){
        return y > 0 && x > y;
    }
}
