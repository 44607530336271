/**
 * Created by Roman on 2015-01-29.
 */


angular.module('flipto.components.states', ['flipto.core.lodash'])
    .config(['$provide', '$compileProvider', function ($provide, $compileProvider) {

        $provide.factory('ftStates', [function () {

            /**
             * States service
             * @constructor
             */
            function StatesService() {

                /**
                 * States array
                 * @type {Array}
                 */
                this.states = [];

                /**
                 * Active states array
                 * @type {Array}
                 */
                this.activeStates = [];
            }

            /**
             * Add state
             * @param state
             */
            StatesService.prototype.addState = function (state) {
                if (!state || !angular.isString(state.name)) throw 'Invalid state object';
                //if (!!this.getState(state.name)) throw 'State ' + state.name + ' already exists';
                if (!this.getState(state.name)) this.states.push(state);
            };

            /**
             * Remove state
             * @param stateName
             */
            StatesService.prototype.removeState = function (stateName) {
                _.remove(this.states, function (item) {
                    return item.name === stateName;
                });
                _.remove(this.activeStates, function (item) {
                    return item.name === stateName;
                });
            };

            /**
             * Activate state
             * @param stateName
             */
            StatesService.prototype.activate = function (stateName) {
                var state = this.getState(stateName);
                if (!state) throw 'State ' + stateName + ' does not exist';

                var siblings = this.getSiblings(state);
                _.remove(this.activeStates, angular.bind(this, function (activeState) {
                    return  !!_.find(siblings, function (sibling) {
                        if (activeState.name === state.name) return false;
                        return activeState.name === sibling.name;
                    })
                }));

                this.activeStates.push(state);
            };

            /**
             * Return state by name
             * @param stateName
             */
            StatesService.prototype.getState = function (stateName) {
                return _.find(this.states, function (item) {
                    return item.name === stateName;
                });
            };

            /**
             * Returns true if state is active
             * @param stateName
             */
            StatesService.prototype.isActive = function (stateName) {
                return !!_.find(this.activeStates, function (state) {
                    return state.name === stateName;
                })
            };

            /**
             * Return siblings for the state
             * @param state
             * @returns {*}
             */
            StatesService.prototype.getSiblings = function (state) {
                return _.filter(this.states, {group: state.group});
            };


            return new StatesService();
        }]);

        $compileProvider.directive('ftState', ['ftStates', '$animate', function (ftStates, $animate) {

            return {
                restrict: 'A',
                link: function (scope, elem, attrs) {

                    elem.addClass('state state-inactive');
                    var state = {
                        name: attrs.ftState,
                        group: attrs.group
                    };

                    ftStates.addState(state);
                    if (angular.isDefined(attrs.active)) {
                        ftStates.activate(state.name);
                    }

                    scope.$watch(function () {
                        return ftStates.isActive(state.name);
                    }, function (isActive) {
                        elem[isActive ? 'removeClass' : 'addClass']('state-inactive');
                        elem[isActive ? 'addClass' : 'removeClass']('state-active');
                    });

                    scope.$on('$destroy', function () {
                        ftStates.removeState(state.name);
                    });

                }
            };
        }]);

        $compileProvider.directive('ftSetState', ['ftStates', function (ftStates) {

            return {
                restrict: 'A',
                link: function (scope, elem, attrs) {

                    elem.on('click', function () {
                        scope.$apply(function () {
                            ftStates.activate(attrs.ftSetState);
                        });
                    });

                    scope.$on('$destroy', function () {
                        elem.unbind('click');
                    });
                }
            };
        }]);

        $compileProvider.directive('ftStateClass', ['ftStates', function (ftStates) {
            return {
                restrict: 'A',
                link: function (scope, elem, attrs) {

                    var committedClasses = [];
                    attrs.$observe('ftStateClass', stateClassWatcher);
                    scope.$watchCollection(function () {
                        return ftStates.activeStates;
                    }, function () {
                        stateClassWatcher(attrs.ftStateClass);
                    });

                    /**
                     * Remove/add classes to element if depending on expression evaluation
                     * @param param
                     */
                    function stateClassWatcher(param) {
                        var tmpClasses = [];
                        var isInverse = angular.isDefined(attrs.inverse);
                        angular.forEach(scope.$eval(param), function (expression, className) {
                            if (ftStates.isActive(expression) === !isInverse) {
                                tmpClasses.push(className);
                            }
                        });

                        angular.forEach(committedClasses, function (className) {
                            elem.removeClass(className);
                        });

                        angular.forEach(tmpClasses, function (className) {
                            elem.addClass(className);
                        });

                        committedClasses = tmpClasses;
                    }
                }
            };
        }]);

        $compileProvider.directive('ftSwapStates', ['ftStates', '_', function (ftStates, _) {
            return {
                restrict: 'A',
                link: function (scope, elem, attrs) {

                    var states = _.map(attrs.ftSwapStates.split(','), function (state) {
                            return state.trim();
                        }),
                        currentState = attrs.active;
                    if (states.length !== 2) throw 'Exactly 2 states must be provided, separated by coma';

                    ftStates.activate(currentState);
                    elem.on('click', function () {
                        scope.$apply(swapStates);
                    });

                    /**
                     * Swap two states
                     */
                    function swapStates() {
                        currentState = states[1] === currentState ? states[0] : states[1];
                        ftStates.activate(currentState);
                    }

                }
            };
        }]);


    }]);
