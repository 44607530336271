/**
 * Created by roman.kupin on 12/4/2017.
 */


export function joinWith(words, separator) {
    return `${words.join(separator)}${separator}`;
}

export const NAME = "joinWith";
export const FACTORY = [() => {
    return joinWith;
}];


