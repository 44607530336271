import { Transition } from '@uirouter/core';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromTouchpoints from '../../store';
import { loadPoststayProcess } from '../../store/poststay-process';

@Component({
  selector: 'app-poststay-process',
  templateUrl: './poststay-process.component.html',
  styleUrls: ['./poststay-process.component.scss']
})
export class PoststayProcessComponent implements OnInit {
  propertyId: string;
  componentGroupUuid: string;
  selectedPoststayProcessComponent$: Observable<any>;
  stylingConfirmationExperienceComponent$: Observable<any>;
  integrationConfirmationExperienceComponent$: Observable<any>;
  selectedPoststayProcessLoading$: Observable<boolean>;
  rateCodes$: Observable<string[]>;
  commitSuccess$: Observable<any>;
  selectedIndex = 0;

  constructor(
    private store$: Store<fromTouchpoints.State>,
    private transition: Transition,
  ) { }

  ngOnInit() {
    this.propertyId = this.transition.params().propertyId;
    this.componentGroupUuid = this.transition.params().componentGroupUuid;

    this.store$.dispatch(loadPoststayProcess({componentGroupUuid: this.componentGroupUuid, propertyId: this.propertyId}));
    // this.store$.dispatch(loadStylingConfirmationExperience({componentGroupUuid: this.componentGroupUuid, propertyId: this.propertyId}));
    // this.store$.dispatch(loadIntegrationConfirmationExperience({componentGroupUuid: this.componentGroupUuid, propertyId: this.propertyId}));

    this.selectedPoststayProcessComponent$ = this.store$.pipe(select(fromTouchpoints.getPoststayProcessData));
    // this.stylingConfirmationExperienceComponent$ = this.store$.pipe(select(fromTouchpoints.getStylingConfirmationExperienceData));
    // this.integrationConfirmationExperienceComponent$ = this.store$.pipe(select(fromTouchpoints.getIntegrationConfirmationExperienceData));
    this.selectedPoststayProcessLoading$ = this.store$.pipe(select(fromTouchpoints.getPoststayProcessLoading));
    this.commitSuccess$ = this.store$.pipe(select(fromTouchpoints.getPoststayProcessCommitSuccess));
  }

  commit(isActive) {
    // this.store$.dispatch(commitConfirmationExperience({
    //     componentGroupUuid: this.componentGroupUuid,
    //     propertyId: this.propertyId,
    //     isActive: isActive
    // }));
  }

  onFormCommit(params) {
    const changes: any = [{
      languageCode: 'en',
      ...params,
    }];
    // this.store$.dispatch(changeConfirmationExperience({componentGroupUuid: this.componentGroupUuid, propertyId: this.propertyId, changes}));
  }
}
