/**
 * Created by ostapenko.r on 12/8/2014.
 */

angular.module('flipto.components.common.placeholder', [])
    .config(['$compileProvider', function ($compileProvider) {
        $compileProvider.directive('ftPlaceholder', ['$filter', function ($filter) {
            var commentedHtmlRegExp = /^<!--[\s\S]*?-->$/g;
            return {
                restrict: 'AE',
                link: function (scope, element, attrs) {
                    var tagName = element[0].tagName.toLowerCase();
                    var text = attrs.ftPlaceholder || attrs.text || $filter('translate')('flipto.account.ClickToEdit')
                    var placeholder = "<span class='placeholder'>"+text+"</span>"
                    if(tagName === ['ft-placeholder']){
                        element.append(placeholder);
                    }else{
                        scope.$watch(function(){
                            return element.html();
                        }, function(html){
                            if(!html || commentedHtmlRegExp.test(html.trim())){
                                element.append(placeholder)
                            };
                        });
                    }
                }
            };
        }]);

        $compileProvider.directive('ftNoneSetPlaceholder', ['$filter', function ($filter) {
            var text =  $filter('translate')('flipto.account.NoneSet')
            return {
                restrict: 'AE',
                link: function (scope, element, attrs) {
                    var tagName = element[0].tagName.toLowerCase();
                    var placeholder = "<span class='placeholder'>"+text+"</span>"
                    if(tagName === ['ft-placeholder']){
                        element.append(placeholder);
                    }else{
                        scope.$watch(function(){
                            return element.html();
                        }, function(html){
                            if(!html){
                                element.append(placeholder)
                            }
                        });
                    }
                }
            };
        }]);

    }]);