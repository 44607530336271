import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RemoteImageService {

  private static checkImageExistenceImpl(imageUrl: string, subscriber: Subscriber<boolean>) {
    const image = new Image();
    image.onload = () => subscriber.next(true);
    image.onerror = () => {
        subscriber.next(false);
        console.error(`Failed to load image: ${imageUrl}`);
    };
    image.src = imageUrl;
  }

  checkExistence(imageUrl: string): Observable<boolean> {
    return new Observable<boolean>(subscriber => {
      if (!imageUrl) {
        subscriber.next(false);
      } else {
        RemoteImageService.checkImageExistenceImpl(imageUrl, subscriber);
      }
    });
  }

}
