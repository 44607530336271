/**
 * Created by roman.kupin on 9/18/2017.
 */


declare const Waypoint;
export const NAME = "ftSections";

export interface ISectionSetup {
    isActive: boolean;
    name: string;
    title: string
}

export class SectionsController {

    registry: ISectionSetup[] = [];
    activeSection: ISectionSetup;

    setup(sections: ISectionSetup[]) {
        this.registry = sections;
    }

    activate(section: ISectionSetup | string) {
        if (typeof section === "string") {
            section = this.registry.find(rs => rs.name === section);
        }
        this.registry.forEach(rs => rs.isActive = false);
        section.isActive = true;
        this.activeSection = section;
        setTimeout(() => Waypoint.refreshAll(), 1000);
    }

    isSectionActive(sectionName): boolean {
        if (!this.registry.length) return false;
        const section = this.registry.find(rs => rs.name === sectionName);
        return section.isActive;
    }

    getActiveSection() {
        return this.registry.find(s => s.isActive);
    }
}


export const DEFINITION = {
    scope: false,
    controller: SectionsController,
    controllerAs: "$ftSections",
    bindToController: true,
    require: "ftSections",
    link: function SectionsLinkFn(scope, elem, attrs, ctrl) {
        scope.$watch(() => scope.$eval(attrs.hasOwnProperty("ftSections") ? attrs.ftSections : attrs.registry), (val) => {
            ctrl.setup(val);
            if (!!attrs.onAfterInit) {
                scope.$eval(attrs.onAfterInit, { registry: ctrl.registry, controller: ctrl });
            }
        }, true);
    }
};

