<ft-modal-editor-wrapper>
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
    <ng-container *ngIf="value">
        <div class="ft2-form-group">
            <label>Status</label>
            <ng-select [(ngModel)]="value.isActive"
                       [clearable]="false"
                       [searchable]="false"
                       [bindValue]="'value'"
                       [bindLabel]="'label'"
                       [items]="settingOptions"
                       name="isExperiencesActive"
                       class="ft2-select -large full-width">
            </ng-select>
        </div>
        <ng-container *ngIf="value.isActive">
            <div class="ft2-form-group">
                <label>Collections included</label>
                <div class="ft2-edit-dialog_modal -options-list pl-0 pr-0">
                    <div class="h-between ft2-input -pure v-center">
                        <label class="ft2-checkbox -disabled">
                            <input type="checkbox" disabled checked>
                            <span class="ft2-checkbox_control"></span>
                            <span class="bolt-text">{{ propertyName || 'Org' }} collection</span>
                        </label>
                    </div>
                    <div class="px-3 mt-2">
                        <label class="solid-text small-text">OccasionGenius</label>
                    </div>
                    <div class="h-between ft2-input -pure v-center"
                         *ngFor="let option of permissionsOptions; let i = index">
                        <label class="ft2-checkbox">
                            <input type="checkbox"
                                   [(ngModel)]="option.checked"
                                   (ngModelChange)="onExperiencesLicensePermissionChange(option)">
                            <span class="ft2-checkbox_control"></span>
                            <span class="bold-text">{{ option.text }}</span>
                        </label>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</ft-modal-editor-wrapper>
