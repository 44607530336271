import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CurationState } from './curation.state';
import { CURATION_FEATURE_NAME } from './feature-name.constant';

const selectCurationModalState = createFeatureSelector<CurationState>(CURATION_FEATURE_NAME);
export const selectCurationModalData = createSelector(selectCurationModalState, state => state.data);
export const selectCurationModalLoading = createSelector(selectCurationModalState, state => state.loading);
export const selectAdvanceToCampaignLoading = createSelector(selectCurationModalState, state => state.advanceToCampaignLoaded);
//share
export const selectBroadcastData = createSelector(selectCurationModalState, state => state.bradcastData);
//photocontest
export const selectPhotocontestData = createSelector(selectCurationModalState, state => state.photocontestData);
// send recommandation
export const selectRecommandationSended = createSelector(selectCurationModalState, state => state.recommandationSended);
export const selectRecommandationSendedError = createSelector(selectCurationModalState, state => state.certificateLoaded);
// stories is curation photos
export const selectStoriesIsCurationPhotos = createSelector(selectCurationModalState, state => state.storiesIsCurationPhotos);
// stories is curation quotes
export const selectStoriesIsCurationQuotes = createSelector(selectCurationModalState, state => state.storiesIsCurationQuotes);