/**
 * Created by roman.kupin on 6/27/2018.
 */


angular.module('flipto.components.common.validation')
    .directive("ftValidationChecklist", [

        "$compile", "ftTasks",

        function($compile, ftTasks) {

            var template = "<div class=\"ft-alert is-actionable\">\n" +
                "        <div class=\"ft-alert-content\">\n" +
                "            <h3 class=\"ft-heading\">{{title}}</h3>\n" +
                "            <p>\n" +
                "                {{summary}}\n" +
                "            </p>\n" +

                "            <div class=\"ft-validation-checklist\">\n" +
                "                <ul>\n" +
                "                    <li ng-repeat=\"item in checklist\" ng-class=\"{'checked': item.isValid() }\"><i class=\"ft-checklist-bullet ft-icn-check-light\"></i>{{item.text}}</li>    \n" +
                "                </ul>\n" +
                "            </div>\n" +
                "            <div class=\"ft-alert-actions\" ft-disabled=\"isAnyInvalid()\">\n" +
                "                <a class=\"ft-btn ft-btn-plus-1 ft-btn-default alert-confirm save\"\n" +
                "                   ft-disable-while-task-pending=\"{{'validation-checklist-' + uniqueId}}\"" +
                "                   ft-loading-anim-task-pending=\"{{'validation-checklist-' + uniqueId}}\"" +
                "                   ng-click=\"saveClicked()\"" +
                "                    ng-class=\"{'disabled': isAnyInvalid()}\"><span ft-hide-while-task-pending=\"{{'validation-checklist-' + uniqueId}}\">{{saveText}}</span></a>\n" +
                "            </div>\n" +
                "        </div>\n" +
                "</div>";

            return {
                restrict: "E",
                require: "?ftSnapshot",
                compile: function(tElem) {

                    var checklistItemsElements = tElem[0].querySelectorAll("checklist-item");

                    tElem.empty();

                    return function(scope, elem, attrs, ftSnapshot) {

                        var uniqueId = attrs.id;

                        var checklistElemScope = scope.$new(true);
                        angular.extend(checklistElemScope, {
                            uniqueId: uniqueId,
                            title: attrs.title,
                            summary: attrs.summary,
                            saveText: attrs.saveText || "Save",
                            errorText: attrs.errorText || "Failed to process the request.",
                            onSave: scope.$eval(attrs.onSave),
                            checklist: Array.prototype.slice.call(checklistItemsElements).map(function(elem) {
                                return {
                                    text: elem.getAttribute("text"),
                                    isValid: function() {
                                        return scope.$eval(elem.getAttribute("is-valid"));
                                    }
                                };
                            }),
                            saveClicked: function() {
                                ftTasks.register('validation-checklist-' + uniqueId);
                                var saveResult = checklistElemScope.onSave(!!ftSnapshot ? ftSnapshot.getTracker().pendingChanges : null);

                                if ( saveResult.then ){
                                    saveResult.finally(function() {
                                        ftTasks.finish('validation-checklist-' + uniqueId);
                                    });
                                    saveResult.catch(function() {
                                        alert(checklistElemScope.errorText);
                                    });
                                } else {
                                    ftTasks.finish('validation-checklist-' + uniqueId);
                                }
                            },
                            isAnyInvalid: function() {
                                return checklistElemScope.checklist.some(function(item) {
                                    return !item.isValid();
                                })
                            }
                        });

                        var checklistElem = $compile(template)(checklistElemScope);
                        elem.append(checklistElem);

                    }
                }
            };
        }

    ]);
