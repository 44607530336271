import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: "joinWith"
})
export class JoinWithPipe implements PipeTransform {
    transform(words: string[], separator: string) {
        if (!words || !Array.isArray(words)) return words;
        return `${words.join(separator)}${separator}`;
    }
}