import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Transition } from '@uirouter/core';
import * as fromTouchpoints from 'account-hybrid/common/components/touchpoints/store';
import { Observable } from 'rxjs';
import { DiscoveryComponentSettings, DiscoveryComponentStylingViewModel } from '../../models';

@Component({
    selector: 'ft-re-engagement-experience',
    templateUrl: './re-engagement-experience.component.html'
})
export class ReEngagementExperienceComponent implements OnInit {
    componentGroupUuid: string;
    commitSuccess$: Observable<boolean>;
    isLoading$: Observable<boolean>;
    error$: Observable<any>;
    stylingViewModel$: Observable<DiscoveryComponentStylingViewModel>;

    constructor(
      private store$: Store<fromTouchpoints.State>,
      private transition: Transition
    ) {
    }

    ngOnInit(): void {
        this.componentGroupUuid = this.transition.params().componentGroupUuid;

        this.stylingViewModel$ = this.store$.pipe(
          select(fromTouchpoints.selectDiscoveryComponentSettingsStylingViewModel)
        );

        this.isLoading$ = this.store$.pipe(select(fromTouchpoints.selectDiscoveryComponentSettingsIsLoading));
        this.commitSuccess$ = this.store$.pipe(select(fromTouchpoints.selectDiscoveryComponentSettingsCommitSuccess));
        this.error$ = this.store$.pipe(select(fromTouchpoints.selectDiscoveryComponentSettingsLoadingError));

    }

    commit(settings: Partial<DiscoveryComponentSettings>) {
        this.store$.dispatch(fromTouchpoints.commitDiscoveryComponentSettings({
            componentGroupUuid: this.componentGroupUuid,
            settings
        }));
    }
}
