/**
 * Created by roman.kupin on 2/15/2017.
 */



angular.module('flipto.accountv1.SharedFeatures')
    .controller('flipto.account.sections.people.DirectoryController',
        [
            'tabs', 'SpacesService', 'Guests', 'page', '$scope', '$rootScope', 'disableScrollWhenLocationChanges', "$state", 'IdentityService',
            function (tabs, spacesService, Guests, page, $scope, $rootScope, disableScrollWhenLocationChanges, $state, identity) {

                var vm = this;
                vm.identity = identity;
                vm.tabs = tabs;
                vm.scrollDisabled = false;
                vm.isProcessingRequest = false;
                vm.searchText = '';
                vm.advocates = [];

                vm.filters = [
                    {
                        name: 'Most recent',
                        query: {SortBy: 'create_date', SortDirection: 'desc', Offset: 0, Fetch: 25}
                    },
                    {
                        name: 'Top advocates',
                        query: {SortBy: '', SortDirection: 'desc', Offset: 0, Fetch: 25}
                    }
                ];

                /**
                 * Load photos
                 * @param query
                 */
                vm.load = function (query) {
                    vm.scrollDisabled = true;
                    vm.isProcessingRequest = true;
                    vm.currentQuery = query;
                    return Guests.guests(spacesService.getPropertyUuid(), query)
                        .then(function (data) {
                            if(vm.currentQuery !== query) return;
                            var total = angular.isArray(data.advocates) && data.advocates.length > 0 ? data.advocates[0].totalCount : 0;
                            vm.hasMoreData = total > (vm.filter.query.Offset + vm.filter.query.Fetch);
                            return data;
                        })
                        .finally(function () {
                            if(vm.currentQuery !== query) return;
                            vm.scrollDisabled = false;
                            vm.isProcessingRequest = false;
                        });
                };

                /**
                 * Load next page
                 */
                vm.loadNextPage = function () {
                    vm.filter.query.Offset += vm.filter.query.Fetch;
                    vm.load(angular.extend({}, vm.filter.query, {SearchText: vm.searchText}))
                        .then(function (data) {
                            vm.advocates.push.apply(vm.advocates, data.advocates);
                        });
                };

                /**
                 * Load page
                 */
                function loadAdvocates() {
                    vm.load(angular.extend({}, vm.filter.query, { SearchText: vm.searchText }))
                        .then(function (data) {
                            vm.advocates = data.advocates;
                        });
                }

                /**
                 * Change filter
                 * @param newFilter
                 */
                vm.changeFilter = function (newFilter) {
                    vm.scrollLoadsResetCounter = 0;
                    vm.advocates = [];
                    vm.filter = angular.copy(newFilter);
                    vm.load(angular.extend({}, vm.filter.query, {SearchText: vm.searchText}))
                        .then(function (data) {
                            vm.advocates = angular.isArray(data.advocates) ? data.advocates : [];
                        });
                };

                vm.setCriteria = function (criteria) {
                    vm.searchText = criteria;
                    vm.filter.query.Offset = 0;
                    vm.scrollLoadsResetCounter = 0;
                    vm.advocates = [];
                    vm.load(angular.extend({}, vm.filter.query, {SearchText: vm.searchText}))
                        .then(function (data) {
                            vm.advocates = angular.isArray(data.advocates) ? data.advocates : [];
                        });
                };

                vm.clearSearch = function () {
                    vm.criteria = '';
                    vm.setCriteria(vm.criteria);
                };

                vm.selectKeyword = function (keyword) {
                    vm.criteria = keyword;
                    vm.setCriteria(keyword);
                };

                vm.getTotal = function () {
                    if (vm.advocates.length > 0) return vm.advocates[0].totalCount;
                    return 0;
                };

                vm.details = function (guest) {
                    page.open('flipto.property.overview.people.directory.details', {advocateUUID: guest.campaignPromoterUUID});
                };

                vm.disableScrollWhenLocationChanges = function () {
                    var scrollState = vm.scrollDisabled,
                        thisPage;
                    $scope.$watch(function () {
                        return $state.current;
                    }, function (current) {
                        if (!thisPage) thisPage = current;
                        if (thisPage.name != current.name) {
                            scrollState = vm.scrollDisabled;
                            vm.scrollDisabled = !vm.scrollDisabled;
                        } else {
                            vm.scrollDisabled = scrollState;
                        }
                    })
                };

                vm.changeFilter(vm.filters[0]);
                disableScrollWhenLocationChanges && vm.disableScrollWhenLocationChanges();

                $rootScope.$on("reservationCanceled", vm.clearSearch);
            }
        ]);
