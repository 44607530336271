/**
 * Created by Roman on 2014-11-11.
 */



angular.module('flipto.core.serializers', [])
    .config(['$provide', function ($provide) {
        $provide.factory('dateSerializer', [function () {

            function serializeDate(date) {
                if (!date) return null;
                return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
            }

            return function (date) {
                return serializeDate(date);
            }
        }]);
    }]);