export enum PhotoContestThemes {
  Standard = 0,
  Wedding = 1,
  Team = 2
}

export const PhotoContestThemesToInviteSuffix = new Map<PhotoContestThemes, string>([
  [PhotoContestThemes.Standard, "guest-storytellers"],
  [PhotoContestThemes.Wedding, "wedding-storytellers"],
  [PhotoContestThemes.Team, "team-storytellers"]
]);