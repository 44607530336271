/**
 * Created by roman.kupin on 9/8/2017.
 */

import 'v1/core/api/index';
import 'v1/core/app-log/appLog';
import 'v1/core/array/arrayUtils';
import 'v1/core/cache/cache';
import 'v1/core/cache-buster/cacheBuster';
import 'v1/core/calculate/calculate';
import 'v1/core/changes-tracker/changesTracker';
import 'v1/core/company/company';
import 'v1/core/cssbeautify/module';
import 'v1/core/deep-diff/deepDiff';
import 'v1/core/enums/module';
import 'v1/core/enums/confirmationDisplayType';
import 'v1/core/enums/confirmationTimerActionTypes';
import 'v1/core/enums/insertionType';
import 'v1/core/enums/overlayType';
import 'v1/core/environment/environment';
import 'v1/core/fixed-queue/fixedQueue';
import 'v1/core/inherits/inherits';
import 'v1/core/interceptors/interceptors';
import 'v1/core/jsbeautify/module';
import 'v1/core/language/language';
import 'v1/core/livePreview/livePreview';
import 'v1/core/lodash/module';
import 'v1/core/log-formatters/logFormatters';
import 'v1/core/urlFormatter/urlFormatter';
import 'v1/core/moment/moment';
import 'v1/core/parse/parse';
import 'v1/core/proxy/proxy';
import 'v1/core/serializers/serializers';
import 'v1/core/signalr/emailBannerUpdates';
import 'v1/core/storage/localStorage';
import 'v1/core/storage/sessionStorage';
import 'v1/core/user/user';
import 'v1/core/user/fullName.filter';
import 'v1/core/utils/pasteHtmlAtCaret';
import 'v1/core/inject/inject.directive';

import 'v1/core/routing/module';

declare let angular;

angular.module('v1imports.core', [
    'flipto.core.api',
    'flipto.core.appLog',
    'flipto.core.array',
    'flipto.core.cache',
    'flipto.core.cacheBuster',
    'flipto.core.calculate',
    'flipto.core.changesTracker',
    'flipto.core.company',
    'flipto.core.cssbeautify',
    'flipto.core.DeepDiff',
    'flipto.core.enums',
    'flipto.core.environment',
    'flipto.core.fixedQueue',
    'flipto.core.inherits',
    'flipto.core.interceptors',
    'flipto.core.jsbeautify',
    'flipto.core.language',
    'flipto.core.livePreview',
    'flipto.core.lodash',
    'flipto.core.logFormatters',
    'flipto.core.urlFormatter',
    'flipto.core.moment',
    'flipto.core.parse',
    'flipto.core.proxy',
    'flipto.core.serializers',
    'flipto.core.signalr',
    'flipto.core.storage',
    'flipto.core.user',
    'flipto.core.utils.pasteHtmlAtCaret',
    'flipto.core.inject',
    'flipto.routing'
]);
