/**
 * Created by ostapenko.r on 8/7/2014.
 */

angular
    .module("flipto.components.touchpoints")
    .directive("ftTouchpointGroupStatusAction",
    [
        "_",
        function (_) {

            return {
                restrict: "A",
                require: "ftTouchpointContext",
                link: function (scope, element, attrs, touchpointContext) {

                    var actionVisibleTo = [
                        {layerName: "pre-stay", type: "confirmation"},
                        {layerName: "post-stay", type: "quote"},
                        {layerName: "homepage", type: "homepage"}
                    ];

                    scope.$watch(
                        touchpointContext.getContext,
                        function (context) {
                            if (context) {
                                angular.element(element)[isActionVisible(context) ? "removeClass" : "addClass"]("ng-hide");
                            }
                        }
                    );

                    /**
                     * Returns true if action should be visible for current layer and touchpoint
                     * @param context
                     * @returns {boolean}
                     */
                    function isActionVisible(context) {
                        return !!_.find(actionVisibleTo, function (action) {
                            return action.layerName.toLowerCase() == context.layerName.toLowerCase() && action.type.toLowerCase() == context.type.toLowerCase();
                        });
                    }

                }
            };
        }
    ]);