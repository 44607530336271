import { IdentityService } from 'account-hybrid/core/authentication';

export class PermissionsCheckHook {

    static $inject = ["$transitions", "IdentityService"];

    constructor($transitions, identity: IdentityService) {

        $transitions.onBefore({
            to: function (state) {
                return state.data && state.data.isFliptoStaff;
            }
        }, function (transition) {
            if (!identity.isFliptoStaff()) return transition.router.stateService.target("home");
            return true;
        });

        $transitions.onBefore({
            to: function (state) {
                return state.data && state.data.requiredPermissions;
            }
        }, function (transition) {
            const permissionsRequired = transition.to().data.requiredPermissions;
            return identity.hasPermission(permissionsRequired) ? true : transition.router.stateService.target("home");
        });

    }
}
