/**
 * Created by Roman on 2015-04-20.
 */


angular.module('flipto.accountv1.SharedFeatures')
    .directive('ftGuestCard', [function () {

        return {
            restrict: 'E',
            templateUrl: '/app/sections/people/guestCard.html',
            scope: {
                'type': '@',
                'guest': '=',
                'onSelected': '&'
            },
            link: function (scope) {

                scope.select = function (guest) {
                    scope.onSelected({guest: guest});
                }
            }
        };
    }]);
