import { StoryExplorerStylingSettings, StoryExplorerIntegrationSettings } from './../models/story-explorer';
import { InvitationEmailStylingSettings } from './../models/invitation-email';
import { Observable, of } from 'rxjs';
import { ConfirmationExperienceSettings, ConfirmationExperienceStylingSettings } from './../models/confirmation-experience';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Environment, ENVIRONMENT_TOKEN } from "account-hybrid/core/environment.service";
import { CertificateEmailSettings } from '../models/certificate-email';
import { FollowupEmailSettings, FollowupEmailStylingSettings } from '../models/followup-email';
import { BannerEmailSettings, BannerEmailStylingSettings, BannerPrearrivalEmailSettings } from '../models/banner-email';
import { InvitationEmailSettings } from '../models/invitation-email';
import { PoststayProcessSettings } from '../models/poststay-process';
import { FriendLandingIntegrationSettings, FriendLandingSettings, FriendLandingStyleSettings } from '../models/friend-landing';
import { CampaignGuestsSettings } from '../models/campgain-guests';

@Injectable()
export class JourneysApiService {
    constructor(private http: HttpClient,
        @Inject(ENVIRONMENT_TOKEN) private environment: Environment) {
    }

    // Confirmation modal
    getTouchpointConfirmationData(componentGroupUuid: string, propertyId: string) {
        const url = `${this.environment.apiV1BaseUrl}api/${componentGroupUuid}/properties/layers/prestay/touchpoints/${propertyId}/confirmation/data`;
        return this.http.get<ConfirmationExperienceSettings>(url);
    }

    getStylingConfirmationData(componentGroupUuid: string, propertyId: string) {
        const url = `${this.environment.apiV1BaseUrl}api/${componentGroupUuid}/properties/layers/prestay/touchpoints/${propertyId}/confirmation/styling`;
        return this.http.get<ConfirmationExperienceStylingSettings>(url);
    }

    getIntegrationConfirmationData(componentGroupUuid: string, propertyId: string) {
        const url = `${this.environment.apiV1BaseUrl}api/${componentGroupUuid}/properties/layers/prestay/touchpoints/${propertyId}/confirmation/integration`;
        return this.http.get<ConfirmationExperienceStylingSettings>(url);
    }

    updateIntegrationConfirmationData(componentGroupUuid: string, propertyId: string, params) {
        const url = `${this.environment.apiV1BaseUrl}api/${componentGroupUuid}/properties/layers/prestay/touchpoints/${propertyId}/confirmation/integration`;
        return this.http.put(url, params);
    }

    getConfirmationRateCodes(propertyUUID: string): Observable<string[]> {
        const url = `${this.environment.apiV1BaseUrl}api/v2/${propertyUUID}/properties/${propertyUUID}/reservation/exclusions`;
        return this.http.get<string[]>(url);
    };

    updateConfirmationRateCodes(propertyUUID: string, rateCodes: string[]) {
        const url = `${this.environment.apiV1BaseUrl}api/v2/${propertyUUID}/properties/${propertyUUID}/reservation/exclusions`;
        return this.http.put<string[]>(url, rateCodes);
    };

    commitTouchpointConfirmationSettings(componentGroupUuid: string, propertyId: string, isActive: boolean) {
        const url = `${this.environment.apiV1BaseUrl}api/${componentGroupUuid}/properties/layers/touchpoints/${propertyId}/activate/${isActive}`;
        return this.http.put(url, {});
    }

    changeTouchpointConfirmationData(componentGroupUuid: string, propertyId: string, changes, tab: string) {
        const url = `${this.environment.apiV1BaseUrl}api/${componentGroupUuid}/properties/layers/prestay/touchpoints/${propertyId}/confirmation/${tab}`;
        return this.http.put(url, changes);
    }

    getBookingEngines() {
        return this.http.get(`${this.environment.apiV1BaseUrl}api/bookingengines`);
    }

    // certificate email
    getTouchpointCertificateEmailData(propertyId: string) {
        const url = `${this.environment.apiV1BaseUrl}api/${propertyId}/properties/${propertyId}/communication/emails-data`;
        return this.http.get<CertificateEmailSettings[]>(url);
    }

    changeTouchpointCertificateEmailData(propertyId: string, changes) {
        const url = `${this.environment.apiV1BaseUrl}api/${propertyId}/properties/${propertyId}/communication/emails-data`;
        return this.http.put(url, changes);
    }

// Follow-up email/ template messaging and styling touchpoint
    getTouchpointFollowupEmailData(componentGroupUuid: string, propertyId: string, typeRequest: string, state: string) {
        const url = `${this.environment.apiV1BaseUrl}api/${componentGroupUuid}/properties/layers/${state}/touchpoints/${propertyId}/emails/${typeRequest}/data`;
        return this.http.get<FollowupEmailSettings>(url);
    }

    commitTouchpointFollowupEmail(componentGroupUuid: string, propertyId: string, isActive: boolean) {
        const url = `${this.environment.apiV1BaseUrl}api/${componentGroupUuid}/properties/layers/prestay/touchpoints/${propertyId}/emails/followup/activate/${isActive}`;
        return this.http.put(url, {});
    }

    getStylingFollowupEmailData(componentGroupUuid: string, propertyId: string, typeRequest: string, state: string) {
        const url = `${this.environment.apiV1BaseUrl}api/${componentGroupUuid}/properties/layers/${state}/touchpoints/${propertyId}/emails/${typeRequest}/styling`;
        return this.http.get<FollowupEmailStylingSettings>(url);
    }

    // save changes snapshot
    saveTouchpointFollowupEmail(componentGroupUuid: string, propertyId: string, typeRequest: string, changes: any, tab: string) {
        const url = `${this.environment.apiV1BaseUrl}api/${componentGroupUuid}/properties/layers/prestay/touchpoints/${propertyId}/emails/${typeRequest}/${tab}`;
        return this.http.put(url, changes);
    }

// Banner email touchpoint
    getTouchpointBannerEmailData(componentGroupUuid: string, propertyId: string, typeRequest: string, state: string) {
        const url = `${this.environment.apiV1BaseUrl}api/${componentGroupUuid}/properties/layers/${state}/touchpoints/${propertyId}/${typeRequest}/data`;
        return this.http.get<BannerEmailSettings>(url);
    }

    commitTouchpointBannerEmail(componentGroupUuid: string, propertyId: string, isActive: boolean) {
        const url = `${this.environment.apiV1BaseUrl}api/${componentGroupUuid}/properties/layers/prestay/touchpoints/${propertyId}/confirmation-email-image/activate/${isActive}`;
        return this.http.put(url, {});
    }

    getStylingBannerEmailData(componentGroupUuid: string, propertyId: string, typeRequest: string, state: string) {
        const url = `${this.environment.apiV1BaseUrl}api/${componentGroupUuid}/properties/layers/${state}/touchpoints/${propertyId}/${typeRequest}/styling`;
        return this.http.get<BannerEmailStylingSettings>(url);
    }

    getTouchpointStylingBannerPrearrivalEmailData(componentGroupUuid: string, propertyId: string) {
        const url = `${this.environment.apiV1BaseUrl}api/${componentGroupUuid}/properties/layers/photo-advocacy/touchpoints/${propertyId}/pre-arrival-email/styling`;
        return this.http.get<any>(url);
    }

    // save changes snapshot
    saveTouchpointBannerEmail(componentGroupUuid: string, propertyId: string, typeRequest: string, changes: any) {
        const url = `${this.environment.apiV1BaseUrl}api/${componentGroupUuid}/properties/layers/prestay/touchpoints/${propertyId}/${typeRequest}/styling`;
        return this.http.put(url, changes);
    }

// Invitation touchpoint
    getTouchpointInvitationEmailData(componentGroupUuid: string, propertyId: string, typeRequest: string, state: string) {
        const url = `${this.environment.apiV1BaseUrl}api/${componentGroupUuid}/properties/layers/${state}/touchpoints/${propertyId}/emails/${typeRequest}/data`;
        return this.http.get<InvitationEmailSettings>(url);
    }

    commitTouchpointInvitationEmail(componentGroupUuid: string, propertyId: string, isActive: boolean) {
        const url = `${this.environment.apiV1BaseUrl}api/${componentGroupUuid}/properties/layers/poststay/touchpoints/${propertyId}/emails/invitation-email/activate/${isActive}`;
        return this.http.put(url, {});
    }

    getStylingInvitationEmailData(componentGroupUuid: string, propertyId: string, typeRequest: string, state: string) {
        const url = `${this.environment.apiV1BaseUrl}api/${componentGroupUuid}/properties/layers/${state}/touchpoints/${propertyId}/emails/${typeRequest}/styling`;
        return this.http.get<InvitationEmailStylingSettings>(url);
    }

    // post stay process
    // messaging
    getTouchpointPoststayProcessData(componentGroupUuid: string, propertyId: string) {
        const url = `${this.environment.apiV1BaseUrl}api/${componentGroupUuid}/properties/layers/poststay/touchpoints/${propertyId}/data`;
        return this.http.get<PoststayProcessSettings>(url);
    }

    // story explorer modal
    getTouchpointStylingStoryExplorerData(componentGroupUuid: string, propertyId: string) {
        const url = `${this.environment.apiV1BaseUrl}api/${componentGroupUuid}/properties/layers/photo-advocacy/touchpoints/${propertyId}/styling`;
        return this.http.get<StoryExplorerStylingSettings>(url);
    }

    getTouchpointIntegrationStoryExplorerData(componentGroupUuid: string, propertyId: string) {
        const url = `${this.environment.apiV1BaseUrl}api/${componentGroupUuid}/properties/layers/photo-advocacy/touchpoints/${propertyId}/integration`;
        return this.http.get<StoryExplorerIntegrationSettings>(url);
    }

    saveTouchpointIntegrationStoryExplorerData(componentGroupUuid: string, propertyId: string, changes: any, tab: string) {
        const url = `${this.environment.apiV1BaseUrl}api/${componentGroupUuid}/properties/layers/photo-advocacy/touchpoints/${propertyId}/${tab}`;
        return this.http.put(url, changes);
    }

    // Friend landing modal
    getTouchpointFriendLanding(componentGroupUuid: string, propertyId: string, typeRequest: string, state: string, tab: string) {
        let url = '';
        if (!typeRequest) {
            url = `${this.environment.apiV1BaseUrl}api/${componentGroupUuid}/properties/layers/${state}/touchpoints/${propertyId}/${tab}`
        } else {
            url = `${this.environment.apiV1BaseUrl}api/${componentGroupUuid}/properties/layers/${state}/touchpoints/${propertyId}/${typeRequest}/${tab}`;
        }

        return this.http.get<FriendLandingSettings | FriendLandingStyleSettings | FriendLandingIntegrationSettings>(url);
    }

    saveTouchpointFriendLanding(componentGroupUuid: string, propertyId: string, typeRequest: string, state: string, changes: any, tab: string) {
        let url = '';
        if (!typeRequest) {
            url = `${this.environment.apiV1BaseUrl}api/${componentGroupUuid}/properties/layers/${state}/touchpoints/${propertyId}/${tab}`
        } else {
            url = `${this.environment.apiV1BaseUrl}api/${componentGroupUuid}/properties/layers/${state}/touchpoints/${propertyId}/${typeRequest}/${tab}`;
        }

        return this.http.put(url, changes);
    }

    // campaign guests modal (all other guests)
    getTouchpointCampaignGuestslData(componentGroupUuid: string, propertyId: string, state: string) {
        const url = `${this.environment.apiV1BaseUrl}api/${componentGroupUuid}/properties/layers/${state}/campaigns/${propertyId}`;
        return this.http.get<CampaignGuestsSettings>(url);
    }

    // save changes snapshot
    saveCampaignGuestsMessages(componentGroupUuid: string, propertyId: string, state: string, changes: CampaignGuestsSettings) {
        const url = `${this.environment.apiV1BaseUrl}api/${componentGroupUuid}/properties/layers/${state}/campaigns/${propertyId}`;
        return this.http.put(url, changes);
    }
}
