export class BitFlags {
    static getBit(num: number, pos: number) {
        return !!(num & (1 << pos));
    }
    static setBit(num: number, pos: number) {
        return num | (1 << pos);
    }
    static clearBit(num: number, pos: number) {
        return num & (~(1 << pos));
    }
    static updateBit(num :number, pos: number, val: 0|1) {
        return (num & (~(1 << pos))) | (val << pos);
    }
}
