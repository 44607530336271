/**
 * Created by Roman on 2014-09-21.
 */


angular.module('flipto.components.common.pendingChanges', ['pascalprecht.translate', 'flipto.components.common.snapshot'])
    .config(['$compileProvider', function ($compileProvider) {

        $compileProvider.directive('ftPendingChangesPanel', [function () {

            var params = ['onSave', 'onDone', 'filter', 'inactive', 'ftSnapshot', 'setupSnapshotProxy'];

            return {
                restrict: 'E',
                templateUrl: '/app/components/common/pending-changes/pendingChanges.html',
                compile: function (tElem, attrs) {
                    var pendingChanges = angular.element(tElem[0].querySelector('[ft-pending-changes]'));
                    angular.forEach(params, function (attr) {
                        var snakeCase = attr.replace(/([A-Z])/g, function ($1) {
                            return "-" + $1.toLowerCase();
                        });
                        attrs[attr] && pendingChanges.attr(snakeCase, attrs[attr]);
                        tElem.removeAttr(snakeCase);
                    });

                    return angular.noop;
                }

            }
        }]);

        $compileProvider.directive('ftPendingChanges', ['$q', function ($q) {
            return {
                restrict: 'A',
                require: ['ftSnapshot', 'ftPendingChanges'],
                controller: [function () {
                    var snapshot;

                    this.register = function (value) {
                        snapshot = value;
                    };

                    this.getTracker = function () {
                        return snapshot.getTracker();
                    };
                }],
                link: function (scope, elem, attrs, controllers) {

                    var snapshot = controllers[0],
                        ftPendingChanges = controllers[1];
                    if (!snapshot.getTracker())return;


                    ftPendingChanges.register(snapshot);

                    var tracker = snapshot.getTracker();
                    var filter = attrs.filter && scope.$eval(attrs.filter);
                    var onSave = attrs.onSave && scope.$eval(attrs.onSave);
                    var onCancel = attrs.onCancel && scope.$eval(attrs.onCancel);

                    /*todo: verify me*/
                    /*todo: ignore formatting
                     * '↵'.charCodeAt(0) --->  8629
                     * */

                    /**
                     * Returns true if change is DiffDelete
                     * @param change
                     * @returns {boolean}
                     */
                    function changeIsDelete(change) {
                        return change.kind === 'D';
                    }

                    /**
                     * Returns true if change is DiffDelete in DiffArray
                     * @param change
                     * @returns {boolean}
                     */
                    function changeIsDeleteInArray(change){
                        return change.kind === 'A' && change.item.kind === 'D';
                    }

                    /**
                     * Returns true if change is: new OR edit AND not empty object
                     * @param change
                     * @returns {boolean|*}
                     */
                    function changeIsNotEmptyObject(change) {
                        return (change.kind === 'N' || change.kind == 'E') && angular.isObject(change.rhs) && _.isEmpty(change.rhs) == false;
                    }

                    /**
                     * Returns true if change is: new OE edit AND string|number|bool
                     * @param change
                     * @returns {boolean|*}
                     */
                    function changeIsSimpleType(change) {
                        return (change.kind === 'N' || change.kind == 'E') && (angular.isString(change.rhs) || angular.isNumber(change.rhs) || typeof (change.rhs) === 'boolean');
                    }

                    /**
                     * Returns true for DiffArray - not empty object || simpleType
                     * @param change
                     * @returns {boolean|*}
                     */
                    function changeIsNotEmptyValueInArray(change) {
                        return (change.kind === 'A' && change.item.kind === 'N') &&
                            (
                                (angular.isObject(change.item.rhs) && _.filter(change.item.rhs, function (value, key) {
                                    return key.toString().indexOf('$') !== 0
                                }).length === 0) ||
                                (
                                    (angular.isObject(change.item.rhs) && _.isEmpty(change.item.rhs) == false) ||
                                    angular.isString(change.item.rhs) || angular.isNumber(change.item.rhs) || typeof (change.item.rhs) === 'boolean')
                                );
                    }

                    function changesFilter(change) {
                        return changeIsDelete(change) ||
                            changeIsDeleteInArray(change) ||
                            changeIsNotEmptyObject(change) ||
                            changeIsSimpleType(change) ||
                            changeIsNotEmptyValueInArray(change);
                    }


                    attrs.$observe('inactive', function (inactive) {
                        var saveElem = angular.element(elem[0].querySelector('.save'));
                        inactive === 'true' ? saveElem.addClass('disabled') : saveElem.removeClass('disabled');
                    });

                    scope.$watch(function () {
                        return tracker.pendingChanges;
                    }, function (changes) {
                        var filteredChanges = _.filter(!!filter ? _.filter(changes, filter) : changes, changesFilter);
                        scope.pendingChanges = filteredChanges && filteredChanges.length || 0;
                    }, true);

                    angular.element(elem[0].querySelector('.save')).bind('click', function () {
                        scope.$apply(function () {
                            /*
                                if onSave isnt defined - resp is true
                                if onSave doesn't return anything - resp is true
                                otherwise we assume resp is either promise or truthy object and wrap in in a promise
                                when promise is resolved - we commit snapshot
                            */
                            var resp = !!onSave ? onSave(tracker.pendingChanges) : true;
                            if(!angular.isDefined(resp)) resp = true;
                            $q.when(resp, function(){
                                snapshot.commit();
                            });
                        });
                    });

                    angular.element(elem[0].querySelector('.cancel')).bind('click', function () {
                        scope.$apply(function () {
                            snapshot.rollback();
                            onCancel && onCancel();
                        });
                    });

                    scope.$on('$destroy', function () {
                        angular.element(elem[0].querySelector('.save')).unbind('click');
                        angular.element(elem[0].querySelector('.cancel')).unbind('click');
                    });
                }
            };
        }]);

    }]);