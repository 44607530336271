angular.module('flipto.components.common.tasks')
    .config(['$provide', '$compileProvider',
        function ($provide, $compileProvider) {
            $compileProvider.directive('ftLoadingAnimTaskPending', ['ftTasks', '$compile', '$animate', function (ftTasks, $compile, $animate) {
                return function (scope, elem, attrs) {
                    var taskScope = {};
                    taskScope.task = attrs.ftLoadingAnimTaskPending;
                    var loadingAnimation = $compile('<span class="loading-animation"><ft-spinner></ft-spinner></span>')(angular.extend(scope.$new(true), taskScope)),
                        lastChild = elem[0].childNodes && elem[0].childNodes[elem[0].childNodes.length - 1];

                    scope.$watch(function () {
                        return ftTasks.isPending(taskScope.task);
                    }, function (isPending) {
                        $animate[!!isPending ? 'enter' : 'leave'](loadingAnimation, elem, lastChild);
                    });
                };
            }]);
        }
    ]);