import { StoryDescriptor } from "../storyDescriptor";

const TEMPLATE_URL = require("./imageSetup.html");

export const NAME = "ftStoryImageSetup";

export class BroadcastImageSetupController {

    static $inject = ["$filter", "$injector"];

    story: any;
    property: any;
    options;
    sharing: any;

    quoteSize = 220;
    captionSize = 220;

    ftAzureStoredPhoto: any;
    backgroundPhoto;

    storyDescriptor: StoryDescriptor;

    constructor(
        public $filter,
        public $injector
    ) {
        this.ftAzureStoredPhoto = $filter("ftAzureStoredPhoto");
    }

    $onInit() {
        this.setup();
    }

    $onChanges() {
        this.setup();
    }

    setup() {
        if (!this.story || !this.options) return;

        this.storyDescriptor = this.$injector.instantiate(StoryDescriptor, { story: this.story });
        this.backgroundPhoto = this.options.isShowGuestPhoto ?
            this.ftAzureStoredPhoto(this.story.guest.poststay.photo, 'small') :
            this.property.BackgroundImageUrl;
        if (this.options.isShowProfilePic && !this.story.guest.profileImageUrl) {
            this.options.isShowProfilePic = false;
        }
    }

}


export const OPTIONS = {
    bindings: {
        story: "<",
        property: "<",
        options: "="
    },
    templateUrl: TEMPLATE_URL,
    controller: BroadcastImageSetupController,
    controllerAs: "vm"
};
