/**
 * Created by Roman on 2015-03-31.
 */


angular.module('flipto.components.common.disabled', [])
    .directive('ftDisabled', function () {

        return {
            restrict: 'A',
            link: function (scope, elem, attrs) {

                scope.$watch(function () {
                    return  scope.$eval(attrs.ftDisabled);
                }, function (disabled) {
                    var isDisabled = !angular.isDefined(disabled) || disabled;
                    elem.css('pointer-events', isDisabled ? 'none' : '');
                    isDisabled && elem.attr('disabled', 'disabled');
                    !isDisabled && elem.removeAttr('disabled');
                });
            }
        };
    });
