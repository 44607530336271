/**
 * Created by roman.kupin on 2/20/2018.
 */


import * as AdminReportsComponent from "./adminReports.component";


export class StateConfig {

    static $inject = ["$stateRegistryProvider"];

    constructor($stateRegistryProvider) {
        $stateRegistryProvider.register({
            name: "admin-area.reports",
            url: "/reports",
            component: AdminReportsComponent.NAME
        });

    }

}