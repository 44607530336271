import { Component, ViewChild, forwardRef, Input, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MODAL_EDITOR_TOKEN } from '../../../classes/modal-editor-base';
import { ModalEditorBase } from '../../../classes/modal-editor-base';
import { InputFieldType } from '../../../types/input-field.type';


@Component({
    selector: 'ft-input-editor',
    templateUrl: './input-editor.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => InputEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: InputEditorComponent
        }
    ]
})
export class InputEditorComponent extends ModalEditorBase implements OnChanges {
    @ViewChild('inputElement', { static: false }) inputElement: ElementRef;
    @Input() email: boolean;
    @Input() maxlength: number;
    @Input() minlength: number;
    @Input() min: number;
    @Input() max: number;
    @Input() pattern: string;
    @Input() patternValidationText: string;
    @Input() type: InputFieldType = 'text';


    constructor() {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!!changes.type) {
            this.email = changes.type.currentValue === 'email';
        }

        if (this.type === 'url') {
            this.patternValidationText = 'Value is not valid url.';
            this.pattern = 'https?://.+';
        }
    }

    onModelChange(value: any) {
        if (this.isNgModelExist) {
            if (this.type === 'number') {
                this.value = parseInt(value, 10);
            } else {
                this.value = value;
            }
        } else {
            this.value = value;
        }
    }
}


