/**
 * Created by ostapenko.r on 9/3/2014.
 */


angular.module('flipto.core.api.campaign', ['flipto.core.language', 'flipto.core.parse', 'flipto.core.lodash', 'flipto.core.cache'])
    .config(['$provide', function ($provide) {

        $provide.factory('Campaign', ['urlFormatter', '$http', 'languageHelper', 'parse', 'updateGoalsSerializer', '_', 'CampaignCache',
            function (urlFormatter, $http, languageHelper, parse, updateGoalsSerializer, _, CampaignCache) {

                /**
                 * Campaign service
                 * @constructor
                 */
                function Campaign() {

                    /**
                     * Get campaign by layer name and id
                     * @param model
                     * @returns {*}
                     */
                    this.get = function (model) {
                        return $http.get(urlFormatter.format('/api/:propertyUuid/properties/layers/:layerName/campaigns/:id', model), {cache: CampaignCache})
                            .then(function (response) {
                                setupCampaign(response.data);
                                return response.data;
                            });
                    };


                    /**
                     * Udpate campaign
                     * @param model.propertyUUID
                     * @param model.layerName
                     * @param model.id The id of the campaign
                     * @param changes
                     * @returns {*}
                     */
                    this.update = function (model, changes) {
                        CampaignCache.removeAll();
                        var data,
                            changesPack = angular.copy(changes),
                            url = '/api/:propertyUuid/properties/layers/:layerName/campaigns/:id';

                        data = model.layerName == 'poststay' ? {goals: convertGoalsToApiFormat(changesPack.goals)}
                            : convertCampaignToApiFormat(changesPack);

                        return $http.put(urlFormatter.format(url, model), data)
                            .then(function (response) {
                                setupCampaign(response.data);
                                return response.data;
                            });
                    };

                    /**
                     * Saves sort orders for all active custom property's variations
                     * @param model.propertyUUID
                     * @param model.layerName
                     * @param changes Array of campaigns unique identifier
                     * @returns {*}
                     */
                    this.reorder = function (model, changes) {
                        CampaignCache.removeAll();
                        return $http.put(urlFormatter.format('/api/:propertyUUID/properties/:propertyUUID/layers/:layerName/campaigns/reorder', model), changes)
                            .then(function (response) {
                                return response.data;
                            });
                    };

                    /**
                     * Create custom campaign
                     * @param model.propertyUUID
                     * @param model.layerName
                     * @param changes
                     * @returns {*}
                     */
                    this.create = function (model, changes) {
                        var data = convertCampaignToApiFormat(angular.copy(changes));
                        return $http.post(urlFormatter.format('/api/:propertyUUID/properties/:propertyUUID/layers/:layerName/campaigns/create', model), data)
                            .then(function (response) {
                                return response.data;
                            });
                    };

                    /**
                     * Activates or deactivates custom variation
                     * @param model.id The id of the campaign
                     * @param model.isActive
                     * @returns {*}
                     */
                    this.activate = function (model) {
                        CampaignCache.removeAll();
                        return $http.put(urlFormatter.format('/api/:propertyUuid/properties/layers/:layerName/campaigns/:id/activate/:isActive', model))
                            .then(function (response) {
                                return response.data;
                            });
                    };


                    /**
                     * Update goals of campaign
                     * @param model
                     * @param changes
                     * @returns {*}
                     */
                    this.updateGoals = function (model, changes) {
                        CampaignCache.removeAll();
                        return $http.put(urlFormatter.format('/api/:propertyUuid/properties/layers/:layerName/campaigns/:id/goals', model), changes)
                            .then(function (response) {
                                return response.data;
                            });
                    };

                    /**
                     * Returns empty campaign object
                     * @returns {*}
                     */
                    this.emptyCampaign = function () {
                        var campaign = {criteria: {}};
                        setupCampaignEmptyCollections(campaign);
                        campaign.messages = parse.arrayOrDefault(campaign.messages);
                        return campaign;
                    };

                    function setupCampaign(campaign) {
                        batchConvert(campaign, ['type', 'isActive'], parse.intOrDefault);
                        campaign.criteria = campaign.criteria || {};
                        setupCampaignEmptyCollections(campaign);
                        languageHelper.sortByDefaultLanguage(campaign.languages);
                        campaign.messages = parse.arrayOrDefault(campaign.messages);
                        _.each(campaign.messages, function (message) {
                            message.isActive = parse.bool(message.isActive);
                            message.languages = parse.arrayOrDefault(message.languages);
                            languageHelper.sortByDefaultLanguage(message.languages);
                            _.each(campaign.languages.item, function (language) {
                                var languageItem = _.find(message.languages, {code: language.code});
                                if (languageItem) {
                                    languageItem.name = language.name
                                } else {
                                    message.languages.push({
                                        name: language.name,
                                        code: language.code
                                    });
                                }
                            })
                        });

                        angular.forEach(campaign.goals.item, setupGoal);
                        angular.forEach(campaign.criteria.stayLengths.item, setupLengthOfStay);
                    }


                    function setupGoal(goal) {
                        batchConvert(goal, ['clicks', 'signups', 'type', 'isActive'], parse.intOrDefault);
                    }

                    function setupLengthOfStay(lengthOfStay) {
                        batchConvert(lengthOfStay, ['minNights', 'maxNights'], parse.intOrDefault);
                    }

                    function setupCampaignEmptyCollections(campaign) {
                        setupEmptyCollections(campaign, ['languages', 'messages', 'goals']);
                        setupEmptyCollections(campaign.criteria, ['roomTypes', 'rateCodes', 'dateRanges', 'languages', 'stayLengths', 'categories', 'secondaryCategories']);
                    }

                    function setupEmptyCollections(object, collectionNames) {
                        angular.forEach(collectionNames, function (collectionName) {
                            object[collectionName] = object[collectionName] || {item: []};
                        })
                    }

                    function batchConvert(object, properties, converter) {
                        angular.forEach(properties, function (property) {
                            object[property] = converter(object[property]);
                        })
                    }

                    function convertCampaignToApiFormat(campaign) {
                        if (!campaign) return;

                        angular.forEach(campaign.messages, function (message) {
                            if (message.id) {
                                message.messageUUID = message.id;
                                delete message.id;
                            }
                            message.languages = _.filter(message.languages, function (language) {
                                return language.text
                            });

                            message.languages = _.map(message.languages, function (language) {
                                return {text: language.text, languageCode: language.code};
                            });
                        });
                        campaign.messages = {item: campaign.messages};

                        angular.forEach(campaign.criteria, function (criteria, key) {
                            campaign[key] = {item: _.filter(criteria.item, function (item) {
                                return !_.isEmpty(item)
                            })};
                        });
                        campaign.goals = convertGoalsToApiFormat(campaign.goals);
                        delete campaign.criteria;
                        return campaign;
                    }

                    function convertGoalsToApiFormat(goals) {
                        goals.item = _.compact(goals.item);
                        goals.item = _.map(goals.item, function (goal) {
                            return updateGoalsSerializer.createGoalModel(goal, goal.type);
                        });
                        return goals;
                    }
                }

                return new Campaign();
            }]);
    }])
    .factory('CampaignCache', ['$cacheFactory', 'CacheExpiration', function ($cacheFactory, CacheExpiration) {
        var cache = $cacheFactory('CampaignCache');
        CacheExpiration('CampaignCache');
        return cache;
    }]);