<ft-custom-editor title="New Account"
                  (cancel)="creatorClose.next(null)"
                  (save)="onSave($event)"
                  [saveSuccess$]="success$"
                  [error$]="error$">
    <div content>
        <div class="ft2-form-group">
            <label>Account name</label>
            <input class="ft2-input -large"
                   type="text"
                   maxlength="200"
                   ngModel
                   name="name"
                   required>
        </div>
        <div class="ft2-form-group">
            <label>Account url</label>
            <input class="ft2-input -large"
                   type="url"
                   maxlength="100"
                   ngModel
                   name="url"
                   ftPrependInput="https://"
                   pattern="https?://.+"
                   #urlControl="ngModel"
                   required>
        </div>
        <div class="ft2-form-error mt-2" *ngIf="urlControl.invalid && urlControl.touched">
            <p>Value is not valid url.</p>
        </div>
        <div class="ft2-form-group">
            <label>Type</label>
            <ng-select [items]="companyTypes"
                       class="ft2-select -large mb-3"
                       name="type"
                       bindValue="value"
                       bindLabel="label"
                       #companyTypeControl="ngModel"
                       [searchable]="false"
                       [clearable]="false"
                       [ngModel]="companyTypes[0].value">
            </ng-select>
        </div>

        <div class="ft2-form-group" *ngIf="companyTypeControl.value === collaborativeCompanyType">
            <label>Collaborative type</label>
            <ng-select [items]="collaborativeTypes"
                       class="ft2-select -large mb-3"
                       name="collaborativeTypeId"
                       bindValue="value"
                       bindLabel="label"
                       [searchable]="false"
                       [clearable]="false"
                       [ngModel]="collaborativeTypes[0].value">
            </ng-select>
        </div>
    </div>
    <ng-content></ng-content>
</ft-custom-editor>

