<header class="ft-touchpoint-header">
    <div class="ft-touchpoint-header_container">
        <button class="ft-btn-close" [uiSref]="'^'"></button>
        <ft-permissions [ftPermissions]="'FliptoAdministration'" [hide]="false">
            <div class="ft-touchpoint-header_title ft-text-plus-3">
                <mat-slide-toggle *ngIf="!!activeToggleEnabled; else titleOnly" labelPosition="before"
                    [disabled]="!canActivate" (ngModelChange)="activeToggled.next($event)" [ngModel]="isActive">
                    <span (click)="$event.preventDefault(); $event.stopPropagation();" style="cursor: default;">
                        {{name}}
                    </span>
                </mat-slide-toggle>
                <ng-template #titleOnly>
                    {{name}}
                </ng-template>
            </div>
        </ft-permissions>
    </div>
</header>