<ft-modal-editor-wrapper>
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
    <div class="ft2-editor-panel h-end" *ngIf="tokens.length">
        <ng-select class="ft2-select -small -pure"
                   [items]="tokens"
                   [bindLabel]="'name'"
                   [bindValue]="'value'"
                   [(ngModel)]="selectedToken"
                   [clearable]="false"
                   [searchable]="false"
                   [placeholder]="'Insert token'"
                   (ngModelChange)="onTokenSelected($event)">
        </ng-select>
    </div>
    <textarea class="ft2-textarea"
              [ngModel]="value"
              (ngModelChange)="onModelChange($event)"
              [placeholder]="placeholder"
              [required]="required"
              [minlength]="minlength"
              [maxlength]="maxlength"
              [pattern]="pattern"
              [name]="name"
              #textareaElement
              #control="ngModel">
    </textarea>
    <div class="ft2-form-error mt-2" *ngIf="control.invalid && control.touched">
        <p *ngIf="control.errors.required">
            Value is required.
        </p>
        <p *ngIf="control.errors.minlength">
            Value must be at least {{minlength}} characters long.
        </p>
        <p *ngIf="control.errors.maxlength">
            Value must be at most {{maxlength}} characters long.
        </p>
        <p *ngIf="control.errors.email">
            Value is not valid email address.
        </p>
        <p *ngIf="control.errors.pattern">
            {{patternValidationText ? patternValidationText : 'Value does not match pattern.' + pattern}}
        </p>
    </div>
    <button
        *ngIf="secondButton"
        class="ft2-edit-dialog_footer-control ft2-second-button ft2-btn-light -large -outline mr-3"
        (click)="action.emit()">
        {{secondButton}}
    </button>
</ft-modal-editor-wrapper>
