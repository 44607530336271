/**
 * Created by roman.kupin on 9/28/2017.
 */

import * as GAReportsApiActions from "../../actions/gaReportsApi";


export default function gaReportsReducer(state = {}, {type, payload}) {
    switch (type) {
        case GAReportsApiActions.TRAFFIC_SOURCES_FETCH_SUCCESS: {
            return Object.assign({}, state, {trafficSources: payload});
        }
        case GAReportsApiActions.GENDER_FETCH_SUCCESS: {
            return Object.assign({}, state, {gender: payload});
        }
        case GAReportsApiActions.VISITORS_BY_COUNTRY_FETCH_SUCCESS: {
            return Object.assign({}, state, {visitorsByCountry: payload});
        }
        case GAReportsApiActions.VISITORS_BY_REGION_FETCH_SUCCESS: {
            return Object.assign({}, state, {visitorsByRegion: payload});
        }
        default: {
            return state;
        }
    }
}