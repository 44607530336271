import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { changeCertificateEmail, changeCertificateEmailFail, changeCertificateEmailSuccess, loadCertificateEmail, loadCertificateEmailFail, loadCertificateEmailSuccess } from '.';
import { CertificateEmailSettings } from '../../models/certificate-email';
import { JourneysApiService } from '../../services/journeys-api.service';
import { loadConfirmationExperienceFail, loadConfirmationExperienceSuccess } from '../confirmation-experience';

@Injectable()
export class CertificateEmailEffects {
    constructor(private actions$: Actions,
        private store$: Store<any>,
        private journeysApiService: JourneysApiService) {
    }

    load$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadCertificateEmail),
            mergeMap(({componentGroupUuid}) =>
                this.journeysApiService.getTouchpointCertificateEmailData(componentGroupUuid)
                    .pipe(
                        map((settings: CertificateEmailSettings[]) => {
                            return loadCertificateEmailSuccess({data: settings});
                        }),
                        catchError(error => of(loadCertificateEmailFail({error})))
                    ))
        )
    );

    changeCertificateEmail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(changeCertificateEmail),
            withLatestFrom(this.store$),
            mergeMap(
                ([{ componentGroupUuid, changes }]) => this.journeysApiService.changeTouchpointCertificateEmailData(
                    componentGroupUuid, changes
                ).pipe(map(() => {
                    return changeCertificateEmailSuccess({ changes });
                }),
                    catchError(error => {
                        return of(changeCertificateEmailFail(error));
                    })
                )
            )
        )
    );
}
