/**
 * Created by Roman on 2014-09-18.
 */


angular.module('flipto.components.common.modal', [/*'ngAnimate', */'ngSanitize', 'mgcrea.ngStrap', 'flipto.components.common.snapshot'])
    .config(['$modalProvider', '$provide', '$compileProvider', function($modalProvider, $provide, $compileProvider) {

        angular.extend($modalProvider.defaults, {
            html: true
        });

        $provide.service('ftModal', ['$modal', '$q', '$timeout', '$rootScope', function($modal, $q, $timeout, $rootScope) {

            /**
             * Created modals array
             * @type {Array}
             */
            var modals = [];

            /**
             * Returns true if there are any modals active at the time
             * @returns {boolean}
             */
            this.isAnyActive = function() {
                return _.some(modals, function(modalWrapper) {
                    return modalWrapper.modal.$isShown;
                });
            };

            /**
             * Create modal and add it to modals array
             * @param id
             * @param options
             * @returns {*}
             */
            this.create = function(id, options) {
                var modal = $modal(options);
                modals.push({id: id, modal: modal});
                return modal;
            };

            /**
             * Remove modal
             * @param modalId
             */
            this.destroy = function(modalId) {
                var removed = _.remove(modals, {id: modalId});
                if ( removed.length > 0 ){
                    removed[0].modal.destroy();
                    angular.forEach(document.querySelectorAll("ft-modal[id='" + modalId + "']"), function(el) {
                        el.remove()
                    });
                }
            };

            /**
             * Show modal
             * @param modalId
             */
            this.show = function(modalId) {
                var defer = $q.defer();
                $timeout(function() {
                    var modal = findModal(modalId);
                    var fn = $rootScope.$watch(function() {
                        return modal.$isShown;
                    }, function(isShown) {
                        if ( !!isShown ){
                            $timeout(defer.resolve);
                            fn();
                        }
                    });
                    modal.show();
                });
                return defer.promise;
            };

            /**
             * Hide modal
             * @param modalId
             */
            this.hide = function(modalId) {
                var defer = $q.defer();
                $timeout(function() {
                    var modal = findModal(modalId);
                    if ( !modal ){
                        $timeout(defer.resolve);
                        return;
                    }
                    var fn = $rootScope.$watch(function() {
                        return modal.$isShown;
                    }, function(isShown) {
                        if ( !isShown ){
                            $timeout(defer.resolve);
                            fn();
                        }
                    });
                    modal.hide();
                });
                return defer.promise;
            };

            /**
             * Returns modal wrapper by id
             * @param modalId
             * @returns {*|modal|$.modal|$.fn.modal.$.modal}
             */
            this.get = function(modalId) {
                var modal = findModal(modalId);
                return !!modal ? {
                    done: modal.$scope.done,
                    cancel: modal.$scope.cancel,
                    instance: modal
                } : null;
            };

            /**
             * Returns modal by modalId
             * @param modalId
             * @returns {*|modal|$.modal|$.fn.modal.$.modal}
             */
            function findModal(modalId) {
                var searchResult = _.find(modals, {id: modalId});
                return !!searchResult ? searchResult.modal : null;
            }
        }]);
        $compileProvider.directive('ftModal', [
            '$compile', 'ftTasks', '$templateCache', '$http', '$q', '$document', 'ftModal', '$timeout', 'focus', "$rootScope",
            function($compile, ftTasks, $templateCache, $http, $q, $document, ftModal, $timeout, focus, $rootScope) {

                var templateTypes = {};
                templateTypes["v1"] = "/app/components/common/modal/modal_v1.html";
                templateTypes["v2"] = "/app/components/common/modal/modal_v2.html";

                var templates = {};
                templates["v1"] = fetchTemplate(templateTypes.v1);
                templates["v2"] = fetchTemplate(templateTypes.v2);

                var availableSizes = ['xs', 'sm', 'md', 'lg', 'xl', 'full'],
                    defaults = {
                        headerText: '',
                        doneText: 'Save',
                        doneVisible: true,
                        size: 'sm',
                        footerAlign: 'right',
                        cancelVisible: true,
                        footerVisible: true,
                        footerCancelText: 'Cancel',
                        footerCancelVisible: false,
                        focusFirstInput: true
                    };

                /**
                 * Fetch template
                 * @param template
                 * @returns {*}
                 */
                function fetchTemplate(template) {
                    return $q.when($templateCache.get(template) || $http.get(template))
                        .then(function(res) {
                            return angular.isObject(res) && res.data || res;
                        });
                }

                return {
                    restrict: 'E',
                    require: '?ftSnapshot',
                    compile: function(tElem, tAttrs) {

                        /**
                         * Ensure modalId
                         * @type {*|string}
                         */
                        var uniqueId = tElem.attr('id') || ('modal-' + (+Date.now()) + parseInt(Math.random() * 1000, 10));
                        tElem.attr('id', uniqueId);
                        /**
                         * Clean modal element and append template as soon as its created and compiled
                         */
                        var html = tElem.html();
                        tElem.empty();

                        var donePressed = false;

                        return function(scope, elem, attrs, snapshot) {

                            /**
                             * Modal instance
                             */
                            var modal,
                                modalScope,
                                focusFirstInput = angular.isDefined(attrs.focusFirstInput) ? scope.$eval(attrs.focusFirstInput) : defaults.focusFirstInput;

                            var onDone,
                                onCancel;

                            scope.$watch(function() {
                                return attrs.onDone && scope.$eval(attrs.onDone);
                            }, function(fn) {
                                onDone = fn;
                            });

                            scope.$watch(function() {
                                return attrs.onCancel && scope.$eval(attrs.onCancel);
                            }, function(fn) {
                                onCancel = fn;
                            });

                            attrs.$observe('headerText', function(headerText) {
                                if ( modalScope ){
                                    modalScope.options.headerText = headerText;
                                }
                            });
                            attrs.$observe('headerDescription', function(headerDescription) {
                                if ( modalScope ){
                                    modalScope.options.headerDescription = headerDescription;
                                }
                            });

                            scope.$watch(function() {
                                return scope.$eval(attrs.cancelVisible)
                            }, function(cancelVisible) {
                                if ( modalScope && angular.isDefined(cancelVisible) ){
                                    modalScope.options.cancelVisible = cancelVisible;
                                }
                            });

                            // Hide the outer uniqueId here as this is post-binding of angular expressions
                            var uniqueId = attrs.id,
                                templateType = angular.isDefined(attrs.templateType) ? attrs.templateType : "v1";

                            /**
                             * Once template is ready to be processed
                             */
                            $q.when(templates[templateType])
                                .then(function(contents) {

                                    /**
                                     * Replace modal template's content placeholder with template setup in modal
                                     */
                                    contents = contents.replace('[modal-content]', html);
                                    /**
                                     * Need to put it in $templateCache so it can be loaded by $modal service
                                     */
                                    $templateCache.put(uniqueId, contents);

                                    var options = angular.extend({}, defaults);
                                    angular.forEach(['headerText', 'doneText', 'footerCancelText', 'headerDescription', 'footerAlign'], function(key) {
                                        if ( attrs[key] ){
                                            options[key] = attrs[key];
                                        }
                                    });
                                    angular.forEach(['doneVisible', 'cancelVisible', 'footerVisible', 'footerCancelVisible', 'id'], function(key) {
                                        if ( attrs[key] ){
                                            options[key] = scope.$eval(attrs[key]);
                                        }
                                    });
                                    options.id = uniqueId;

                                    if ( availableSizes.indexOf(attrs.size) !== -1 ){
                                        options.size = attrs.size;
                                    }

                                    function callOnClose() {
                                        if ( !!attrs.onClose ){
                                            var _onClose = scope.$eval(attrs.onClose);
                                            if ( typeof _onClose === "function" ){
                                                _onClose();
                                            }
                                        }
                                    }

                                    /**
                                     * Create modal scope, inherited from modal's element scope and extended with options
                                     */
                                    modalScope = angular.extend(scope.$new(false), {$ftModal: {options: options}}, {options: options});
                                    modalScope.$ftModal.done = modalScope.done = function() {
                                        ftTasks.register("modal-on-done-task-" + uniqueId);
                                        donePressed = true;
                                        var result;
                                        if ( snapshot ){
                                            var tracker = snapshot.getTracker();
                                            result = onDone && onDone(tracker.pendingChanges);
                                        } else {
                                            result = onDone && onDone();
                                        }

                                        callOnClose();

                                        if ( result && _.isFunction(result.then) ){
                                            result.then(function() {
                                                ftModal.hide(uniqueId);
                                                ftTasks.finish("modal-on-done-task-" + uniqueId);
                                            }, function() {
                                                alert("Yikes, there seems to have been a problem. Please give it another try.");
                                                ftTasks.finish("modal-on-done-task-" + uniqueId);
                                            });
                                        } else {
                                            ftModal.hide(uniqueId);
                                            ftTasks.finish("modal-on-done-task-" + uniqueId);
                                        }
                                        $rootScope.$broadcast('modal-' + uniqueId + '.done', modal);

                                    };

                                    modalScope.$ftModal.cancel = modalScope.cancel = function() {
                                        callOnClose();
                                        ftModal.hide(uniqueId)
                                            .then(function() {
                                                if ( snapshot ) snapshot.rollback();
                                            })
                                            .then(function() {
                                                $rootScope.$broadcast('modal-' + uniqueId + '.cancel', modal);
                                            });
                                    };

                                    // remove same id modal if any
                                    var existingModal = ftModal.get(uniqueId);
                                    if ( existingModal ){
                                        ftModal.destroy(uniqueId);
                                    }

                                    /**
                                     * Create modal instance
                                     * @type {Object}
                                     */
                                    modal = ftModal.create(uniqueId, {
                                        scope: modalScope,
                                        title: attrs.headerText,
                                        templateUrl: uniqueId,
                                        prefixEvent: 'modal-' + uniqueId,
                                        show: false,
                                        keyboard: false,
                                        backdrop: 'static',
                                        container: ('*[id=\'' + uniqueId + '\']')
                                    });

                                    /**
                                     * Move element to body
                                     */
                                    angular.element($document[0].body).append(elem);

                                    $rootScope.$broadcast('modal-' + uniqueId + '.create', modal);
                                });

                            scope.$on('modal-' + uniqueId + '.show', function() {
                                if ( snapshot ) snapshot.retake();

                                if ( focusFirstInput ){
                                    focus.firstInputOf(elem[0]);
                                }
                                donePressed = false;
                            });

                            scope.$on('modal-' + uniqueId + '.hide', function() {
                                if ( !donePressed ){
                                    if ( snapshot ) snapshot.rollback();
                                    onCancel && onCancel();
                                    $timeout(function() {
                                    }, 0);
                                }
                            });

                            /*
                             * Hide modal without timeout upon location change
                             * */
                            scope.$on('$locationChangeStart', function(evt, newUrl, oldUrl) {
                                if ( newUrl !== oldUrl ){
                                    var modalWrapper = ftModal.get(uniqueId);
                                    if ( modalWrapper ){
                                        modalWrapper.instance.hide();
                                    }
                                }
                            });

                            /**
                             * Destroy modal when scope is being destroyed
                             */
                            scope.$on('$destroy', function() {
                                ftModal.destroy(uniqueId);
                                if ( elem ) elem.remove();
                                if ( modalScope ) modalScope.$destroy();
                                $templateCache.remove(uniqueId);
                                modalScope = null;
                                modal = null;
                            });

                        }
                    }
                };
            }]);
        $compileProvider.directive('ftTargetsModal', ['ftModal', 'ftTasks', function(ftModal, ftTasks) {
            return {
                link: function(scope, elem, attrs) {
                    var hasDisableIfTaskPending = !!attrs.ftNoTargetsModalIfTaskPending;

                    function handleClick() {
                        if ( !hasDisableIfTaskPending || !ftTasks.isPending(attrs.ftNoTargetsModalIfTaskPending) ){
                            ftModal.show(attrs.ftTargetsModal);
                        }
                    }

                    elem.on('click', handleClick);

                    scope.$on('$destroy', function() {
                        elem.off('click', handleClick);
                    });
                }
            };
        }]);

        $compileProvider.directive('ftCloseModal', ['ftModal', function(ftModal) {
            return {
                link: function(scope, elem, attrs) {
                    elem.bind('click', function() {
                        ftModal.hide(attrs.ftCloseModal);
                    });

                    scope.$on('$destroy', function() {
                        elem.unbind('click');
                    });
                }
            };
        }]);

        $compileProvider.directive('ftModalBackground', ['$compile', function($compile) {

            var template = '<div class="bg-image-blur hide-with-modal" ft-background-image="{{backgroundUrl}}"></div><div class="bg-matte hide-with-modal"></div>';

            return {
                restrict: 'A',
                link: function(scope, elem, attrs) {

                    var modalId = attrs.id,
                        newScope = scope.$new(true),
                        background = $compile(template)(newScope);

                    attrs.$observe('ftModalBackground', function(value) {
                        angular.extend(newScope, {backgroundUrl: value});
                    });

                    scope.$on('modal-' + modalId + '.show', function() {
                        elem.prepend(background);
                    });

                    scope.$on('modal-' + modalId + '.hide', function() {
                        background.remove();
                    });

                }
            };
        }]);
    }]);
