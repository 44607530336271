export class ExperienceVenueDto {
    googlePlaceId?: string;
    languageData: ExperienceVenueLanguageData[];
    venueUuid?: string;
    externalVenueUuid?: string;
    timezone?: number;
    latitude?: number;
    longitude?: number;
    isExcluded?: boolean;
    count?: number;
    customVenueName?: string;
}

export class ExperienceVenueLanguageData {
    languageCode: string;
    name: string;
    streetAddress: string;
    city: string;
    region: string;
    postalCode: string;
    country: string;
    area?: string;
    customVenueName?: string;
}

export class ExperienceVenue extends ExperienceVenueDto {
    name: string;
    streetAddress: string;
    city: string;
    region: string;
    postalCode: string;
    country: string;
    area?: string;
    customVenueName?: string;


    static dtoToPlain(dto: ExperienceVenueDto): ExperienceVenue {
        if (dto) {
            const venueLanguageData = dto?.languageData?.find((data) => data.languageCode === 'en') || dto?.languageData?.[0];

            const translations = venueLanguageData ? {
                name: venueLanguageData?.name,
                streetAddress: venueLanguageData?.streetAddress,
                city: venueLanguageData?.city,
                region: venueLanguageData?.region,
                postalCode: venueLanguageData?.postalCode,
                country: venueLanguageData?.country,
                area: venueLanguageData?.area
            } : {};

            return Object.assign(
                new ExperienceVenue(),
                dto,
                translations
            );
        }
    }
}
