/**
 * Created by ostapenko.r on 9/26/2014.
 */


angular.module('flipto.components.fieldEditors')
    .config(['$provide', '$compileProvider', function ($provide, $compileProvider) {

        var directives = [
            {name: 'ftCssField', syntax: 'css'},
            {name: 'ftJsField', syntax: 'javascript'},
            {name: 'ftJsonField', syntax: 'json'}
        ];

        angular.forEach(directives, function (directive) {
            $compileProvider.directive(directive.name, [
                function () {
                    return {
                        restrict: 'E',
                        scope: {
                            'datasource': '='
                        },
                        template: '<div ng-repeat="field in fields">  <div class="ft-label" ng-bind="field.label"></div><ft-code-editor syntax="' + directive.syntax + '" code="datasource[field.name]"></ft-code-editor></div>',
                        link: function (scope, elem, attrs) {
                            scope.fields = adjustFields(scope.$eval(attrs.fields) || []);

                            /**
                             * Returns fields array as objects
                             * @param fields
                             * @returns {Array}
                             */
                            function adjustFields(fields) {
                                return _.map(fields, function (field) {
                                    if (angular.isString(field)) {
                                        return {name: field, label: null};
                                    }
                                    return field;
                                });
                            }

                        }
                    };
                }
            ]);
        });
    }]);