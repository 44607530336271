/**
 * Created by roman.kupin on 2/9/2018.
 */


const TEMPLATE_URL = require("./campaignContainer.component.html");


declare let angular;
declare let _;
export const NAME = "ftCampaignContainer";

export const DEFINITION = [
    "$compile", "$templateCache", "$controller", "$injector", "ftModal", "CampaignContainer", "$timeout",
    function CampaignContainerComponent($compile, $templateCache, $controller, $injector, ftModal, CampaignContainer, $timeout) {

        return {
            compile: function () {
                return function ($scope, elem, attrs) {

                    let containerTemplate,
                        containerElement;

                    function watchArgs(datas) {

                        const propertyUuid = datas[0],
                            campaignUuid = datas[1],
                            layerName = datas[2],
                            isCreating = datas[3];

                        if (isCreating === false && (!propertyUuid || !campaignUuid || !layerName)) return;
                        if (isCreating === true && (!propertyUuid || !layerName)) return;

                        const injectables = {
                            $scope,
                            campaignId: campaignUuid,
                            layerName,
                            isCreating,
                            property: {id: propertyUuid},
                            campaignCreatedCallback: (campaign) => {
                                // switch to editing
                                injectables.campaignId = campaign.id.toUpperCase();
                                injectables.isCreating = false;
                                injectCampaignDetails(layerName);
                            },
                            campaignCreateCancelCallback: () => {
                                // close campaign details
                                ftModal.hide("accountv2.CampaignContainer");
                            }
                        };
                        $scope.$ftCampaignHeaderController = () => $injector.invoke(["$rootScope", "$stateParams", "$q", "$compile", "Campaign", "CampaignType", "$state", "parse",
                            function ($rootScope, $stateParams, $q, $compile, Campaign, CampaignType, $state, parse) {

                                if (!isCreating) {
                                    Campaign.get({
                                        propertyUuid: propertyUuid,
                                        id: campaignUuid,
                                        layerName: layerName.replace('-', '')
                                    }).then(function (response) {

                                        angular.extend($scope, {
                                            $ftCHC: {
                                                campaign: response,
                                                isActive: parse.bool(response.isActive),
                                                campaignType: /*isPhotoAdvocacyCampaign ? 6 : */null,
                                                canActivate: function () {
                                                    return response.type !== CampaignType.AllGuests;
                                                },
                                                updateState: function () {
                                                    const model = {
                                                        propertyUuid: propertyUuid,
                                                        id: campaignUuid,
                                                        isActive: $scope.$ftCHC.isActive,
                                                        layerName: layerName.replace('-', '')
                                                    };
                                                    Campaign.activate(model).then(function () {
                                                        $rootScope.$broadcast('campaign.statusChanged', model);
                                                    });
                                                }
                                            }
                                        });

                                        $rootScope.$on('campaign.changed', function (event, campaign) {
                                            $scope.$ftCHC.campaign = campaign;
                                        });
                                    });
                                } else {
                                    angular.extend($scope, {
                                        $ftCHC: {
                                            isCreating: isCreating
                                        }
                                    });
                                }

                            }]);
                        $scope.$ftCampaignController = () => $controller("flipto.account.sections.platform.CampaignController", injectables);

                        if (!containerElement) {
                            containerTemplate = $templateCache.get(TEMPLATE_URL);
                            containerElement = $compile(containerTemplate)($scope);
                            elem.append(containerElement);
                        }

                        ftModal.show("accountv2.CampaignContainer");
                        injectCampaignDetails(layerName);
                    }

                    function injectCampaignDetails(layerName) {
                        $timeout(() => {
                            const tabContainer = angular.element(containerElement[0].querySelector("[container]"));
                            tabContainer.empty();
                            const touchpointTemplate = $templateCache.get(`/app/sections/platform/layer/${layerName}/campaign/campaign.html`);
                            tabContainer.append($compile(`
                                <div ng-controller="$ftCampaignController">
                                    ${touchpointTemplate}                    
                                </div>`)($scope));

                        });
                    }

                    $scope.$watchGroup([
                        () => $scope.$eval(attrs.propertyUuid),
                        () => $scope.$eval(attrs.campaignUuid),
                        () => $scope.$eval(attrs.layerName),
                        () => $scope.$eval(attrs.isCreating),
                    ], watchArgs);

                    $scope.destroyContainer = function () {
                        ftModal.hide("accountv2.CampaignContainer");
                        CampaignContainer.hide();
                    };

                }
            }
        }
    }
];

