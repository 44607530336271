/**
 * Created by roman.kupin on 10/2/2017.
 */



declare const angular;

export const NAME = "ftClickClass";

export const DEFINITION = [function () {
    return {
        link: function (scope, elem, attrs) {

            const $addClass = attrs.addClass;
            const $toggleClass = attrs.toggleClass;
            const $removeClass = attrs.removeClass;
            // const isIgnoreTargetElClick = angular.isDefined(attrs.ignoreTargetClick);
            const documentEl = angular.element(window.document);
            const targetEl = !!attrs.selector ? angular.element(window.document.querySelector(attrs.selector)) : elem;
            // let openedAt;

            function handler() {
                // if (event.target != elem[0] && isIgnoreTargetElClick && targetEl[0].contains(event.target)) return;
                const isElemOrItsChild = event.target == elem[0] || targetEl[0].contains(event.target);
                if (isElemOrItsChild) {
                    targetEl.addClass($addClass);
                    targetEl.removeClass($removeClass);
                    targetEl[0].classList.toggle($toggleClass);
                } else {
                    targetEl.removeClass($removeClass);
                    targetEl.removeClass($toggleClass);
                }
            }

            documentEl.on("click", handler);


            scope.$on("$destroy", () => {
                documentEl.off("click", handler);
            });

        }
    };
}];