import { Action, createReducer, on } from "@ngrx/store";
import {
    changeConfirmationExperience,
    changeConfirmationExperienceFail,
    changeConfirmationExperienceSuccess,
    commitConfirmationExperience,
    commitConfirmationExperienceSuccess,
    loadConfirmationExperience,
    loadConfirmationExperienceFail,
    loadConfirmationExperienceSuccess,
    loadIntegrationConfirmationExperience,
    loadIntegrationConfirmationExperienceFail,
    loadIntegrationConfirmationExperienceSuccess,
    loadStylingConfirmationExperience,
    loadStylingConfirmationExperienceFail,
    loadStylingConfirmationExperienceSuccess,
} from "./actions";
import { State } from "./state";

export const reducer = createReducer(
    {
        loading: false,
        settings: null,
        commitSuccess: false,
        stylingSettings: null,
        integrationSettings: null
    } as State,
    // messaging
    on(loadConfirmationExperience, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(loadConfirmationExperienceSuccess, (state, {data}) => {
        return {
            ...state,
            loading: false,
            settings: data
        };
    }),
    on(loadConfirmationExperienceFail, (state, {error}) => {
        return {
            ...state,
            loading: false,
            error
        };
    }),
    // styling
    on(loadStylingConfirmationExperience, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(loadStylingConfirmationExperienceSuccess, (state, {data}) => {
        return {
            ...state,
            loading: false,
            stylingSettings: data
        };
    }),
    on(loadStylingConfirmationExperienceFail, (state, {error}) => {
        return {
            ...state,
            loading: false,
            error
        };
    }),
    // integration
    on(loadIntegrationConfirmationExperience, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(loadIntegrationConfirmationExperienceSuccess, (state, {data}) => {
        return {
            ...state,
            loading: false,
            integrationSettings: data
        };
    }),
    on(loadIntegrationConfirmationExperienceFail, (state, {error}) => {
        return {
            ...state,
            loading: false,
            error
        };
    }),
    on(commitConfirmationExperience, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(commitConfirmationExperienceSuccess, (state, {data}) => {
        return {
            ...state,
            loading: false,
            settings: data
        };
    }),
    on(changeConfirmationExperience, state => {
        return {
          ...state,
          loading: true
        };
      }),
    on(changeConfirmationExperienceSuccess, (state, {changes}) => {
        return {
            ...state,
            loading: false,
            commitSuccess: true,
            settings: {
                ...state.settings,
                languages: {
                    item: [{
                        ...state.settings.languages.item[0],
                        data: {
                            ...state.settings.languages.item[0].data,
                            ...changes[0]
                        }
                    }]
                }
            }
        };
    }),
    on(changeConfirmationExperienceFail, (state, { error }) => {
    return {
        ...state,
        loading: false,
        error
    };
    }),
)

export function confirmationExperienceReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}
