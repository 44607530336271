/**
 * Created by roman.kupin on 1/31/2018.
 */

import {resolveLayer} from "./touchpointTemplate";
import {PhotoAdvocacyTouchpointsNames, PoststayTouchpointsNames, HomepageTouchpointsNames} from "./touchpoints";

function getMessagingInjectables({$injector, $scope, campaignUuid, propertyUuid, touchpointName, dataType}) {
    const layerName = resolveLayer(touchpointName);
    return new Promise((resolve, reject) => {
        $injector.invoke(['Messaging', 'endpointUrl', 'languageHelper', function (Messaging, endpointUrl, languageHelper) {
            const url = endpointUrl.getDataUrl(layerName, touchpointName, campaignUuid, propertyUuid);
            return Messaging.get(url).then(function (response) {
                if (response.data) {
                    languageHelper.sortByDefaultLanguage(response.data.languages);
                    response.data.upgrade && languageHelper.sortByDefaultLanguage(response.data.upgrade.languages);
                }
                return response.data;
            });
        }]).then(data => resolve({
            $scope,
            messaging: data,
            layerName,
            touchpointType: touchpointName,
            touchpointGroupId: campaignUuid
        }));
    });
}

function getStylingInjectables({$injector, $scope, campaignUuid, propertyUuid, touchpointName, dataType}) {
    const layerName = resolveLayer(touchpointName);
    return new Promise((resolve, reject) => {
        Promise.all([
            $injector.invoke(['Styling', 'endpointUrl', function (Styling, endpointUrl) {
                const url = endpointUrl.getStyleUrl(layerName, touchpointName, campaignUuid, propertyUuid);
                return Styling.get(url).then(function (response) {
                    return response.data;
                });
            }]),
            $injector.invoke(['Image', function (Image) {
                return Image.getPropertyImages({propertyId: propertyUuid});
            }])])
            .then(datas => {
                resolve({
                    $scope,
                    styling: datas[0],
                    propertyImages: datas[1],
                    layerName,
                    touchpointType: touchpointName,
                    touchpointGroupId: campaignUuid
                })
            });
    });
}

function getStylingEmailTemplateInjectables({$injector, $scope, campaignUuid, propertyUuid, touchpointName, dataType}) {
    const layerName = resolveLayer(touchpointName);
    return new Promise((resolve, reject) => {
        resolve({
            $scope,
            layerName,
            touchpointType: touchpointName,
            touchpointGroupId: campaignUuid
        })
    });
}

function getIntegrationInjectables({$injector, $scope, campaignUuid, propertyUuid, touchpointName, dataType}) {
    const layerName = resolveLayer(touchpointName);
    return new Promise((resolve, reject) => {

        $injector.invoke(['Integration', 'endpointUrl', function (Integration, endpointUrl) {
            const url = endpointUrl.getIntegrationUrl(layerName, touchpointName, campaignUuid, propertyUuid);
            return Integration.get(url).then(function (response) {
                return response.data;
            });
        }]).then(data => {
            resolve({
                $scope,
                integration: data,
                layerName,
                touchpointType: touchpointName,
                touchpointGroupId: campaignUuid
            })
        });


    });
}

export async function getControllerInjectables(args) {
    switch (args.dataType) {
        case "messaging": {
            return getMessagingInjectables(args);
        }
        case "styling": {
            if (args.touchpointName == PoststayTouchpointsNames.InvitationEmail)
                return getStylingEmailTemplateInjectables(args);
            return getStylingInjectables(args);
        }
        case "integration": {
            return getIntegrationInjectables(args);
        }
    }
}

export async function getWarmLeadNurturingControllerInjectables(args) {
    const {touchpointName, $scope, propertyUuid} = args;
    return new Promise(resolve => {

        if(touchpointName === "certificate-email") {
            resolve({
                $scope,
                propertyUuid,
                touchpointType: touchpointName,
                emailTypes: {
                    AnonymousPhotoSignupCertificate: 11,
                    FriendPhotoSignupCertificate: 7,
                    FriendPrestayHomepageSignupCertificate: 1,
                    FriendPoststayHomepageSignupCertificate: 3,
                    GuestPrestayPromotionCertificate: 13,
                    GuestPoststayPromotionCertificate: 22,
                    GuestPhotoFinalistCertificate: 80,
                    DefaultCertificate: 36
                }
            })
        }

        if(touchpointName === "certificate-reminder-email") {
            resolve({
                $scope,
                touchpointType: touchpointName,
                propertyUuid,
                emailTypes: {
                    AnonymousPhotoSignupCertificateReminder: 12,
                    FriendPhotoSignupCertificateReminder: 8,
                    FriendPrestayHomepageSignupCertificateReminder: 2,
                    FriendPoststayHomepageSignupCertificateReminder: 4,
                    GuestPoststayPromotionCertificateReminder: 23,
                    GuestPhotoFinalistCertificateReminder: 81,
                    DefaultCertificateReminder: 37
                }
            })
        }


    });
}

export function getTouchpointController(args) {
    const {$controller, injectables, touchpointName, dataType} = args;

    switch (dataType) {
        case "messaging": {
            if(touchpointName == HomepageTouchpointsNames.Homepage) {
                return () => $controller("flipto.account.sections.platform.homepage.HomepageMessagingController", injectables);
            }
            return () => $controller("flipto.account.sections.platform.MessagingController", injectables);
        }
        case "styling": {
            if (touchpointName == PoststayTouchpointsNames.InvitationEmail ||
                touchpointName == PhotoAdvocacyTouchpointsNames.FinalistsEmail ||
                touchpointName == PhotoAdvocacyTouchpointsNames.ReminderEmail ||
                touchpointName == PhotoAdvocacyTouchpointsNames.ActivityEmail ||
                touchpointName == PhotoAdvocacyTouchpointsNames.WinnerEmail ||
                touchpointName == HomepageTouchpointsNames.HomepageEmail) {
                return () => $controller("flipto.account.sections.platform.StylingEmailTemplateController as vm", injectables);
            }
            if (touchpointName == PoststayTouchpointsNames.ReminderEmails) {
                return () => $controller("flipto.account.sections.platform.StylingReminderEmailsTemplateController as vm", injectables);
            }
            if (touchpointName == PoststayTouchpointsNames.ReengagementEmails) {
                return () => $controller("flipto.account.sections.platform.StylingReengagementEmailsTemplateController as vm", injectables);
            }
            return () => $controller("flipto.account.sections.platform.StylingController", injectables);
        }
        case "integration": {
            return () => $controller("flipto.account.sections.platform.IntegrationController", injectables);
        }
        default: {
            return null;
        }
    }
}

export function getWarmLeadNurturingController(args) {
    const {$controller, injectables} = args;
    return () => $controller("flipto.account.sections.platform.EmailsDataController as vm", injectables);
}