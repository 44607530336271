/**
 * Created by roman.kupin on 9/21/2017.
 */

import {router} from 'redux-ui-router';
import ui from "./ui";
import apiData from "./api-data/index";
import {createViewsReducer} from "./views";
import { combineReducers } from 'redux';


export function createRootReducer(features) {
    if(!!features.length) {
        return combineReducers({
            router,
            ui,
            apiData,
            views: createViewsReducer(features)
        });
    }

    return combineReducers({
        router,
        ui,
        apiData
    });
}

