import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'ft-modal-wrapper',
    templateUrl: './modal-wrapper.component.html',
    styleUrls: ['./modal-wrapper.component.scss']
})
export class ModalWrapperComponent implements OnDestroy, OnInit {

    constructor(private renderer: Renderer2,
                @Inject(DOCUMENT) private document: Document) {
    }

    ngOnInit(): void {
        this.renderer.addClass(this.document.body, 'no-scroll');
    }

    ngOnDestroy(): void {
        this.renderer.removeClass(this.document.body, 'no-scroll');
    }
}
