import { ContextSelectorState } from '../context-selector.state';
import { createReducer, on } from '@ngrx/store';
import {
    addNew,
    discardChanges,
    hide,
    selectAll,
    selectGroup, selectNone,
    setSingle,
    show,
    toggleMany,
    toggleSingle
} from '../actions';

const initialState: ContextSelectorState = {
    singlePropertySelector: null,
    multiplePropertySelector: null
};

export const contextSelectorReducer = createReducer(initialState,
    on(addNew, (state, action) => ({
        ...state,
        [action.payload.id]: action.payload
    })),
    on(show, (state, action) => {
        const newState = {
            ...state
        };
        newState[action.payload.contextSelectorId].isActive = true;
        newState[action.payload.contextSelectorId].contextGroups.forEach((contextGroup) => {
            contextGroup.initialActiveContextIds = contextGroup.contexts
                .filter(context => context.isActive)
                .map(context => context._id);
            contextGroup.totalSelected = contextGroup.initialActiveContextIds.length;
        });
        return newState;
    }),
    on(discardChanges, (state, action) => {
        const newState = {
            ...state
        };
        newState[action.payload.contextSelectorId].contextGroups.forEach((contextGroup) => {
            contextGroup.contexts.forEach(context => {
                context.isActive = !!contextGroup.initialActiveContextIds.find(id => context._id === id);
            });
        });
        return newState;
    }),
    on(hide, (state, action) => {
        const newState = {
            ...state
        };
        newState[action.payload.contextSelectorId].isActive = false;
        return newState;
    }),
    on(selectGroup, (state, action) => {
        const newState = {
            ...state
        };
        newState[action.payload.contextSelectorId].contextGroups.filter(group => group.name === action.payload.groupName).forEach(group => group.isActive = true);
        return newState;
    }),
    on(toggleMany, (state, action) => {
        const newState = {
            ...state
        };
        const contextGroups = newState[action.payload.contextSelectorId].contextGroups
            .filter(group => group.name === action.payload.groupName);
        const contexts = contextGroups
            .map(group => group.contexts)
            .reduce(i => i);
        contexts
            .filter(context => context._id === action.payload.contextId)
            .forEach(context => context.isActive = !context.isActive);

        contextGroups.forEach(contextGroup => {
            contextGroup.totalSelected = contextGroup.contexts.filter(context => context.isActive).length;
        });

        return newState;
    }),
    on(toggleSingle, (state, action) => {
        const newState = {
            ...state
        };
        const contexts = newState[action.payload.contextSelectorId].contextGroups
            .filter(group => group.name === action.payload.groupName)
            .map(group => group.contexts)
            .reduce(i => i);
        contexts
            .filter(context => context._id === action.payload.contextId)
            .forEach(context => context.isActive = !context.isActive);
        contexts
            .filter(context => context._id !== action.payload.contextId)
            .forEach(context => context.isActive = false);
        return newState;
    }),
    on(setSingle, (state, action) => {
        const newState = {
            ...state
        };
        const contexts = newState[action.payload.contextSelectorId].contextGroups
            .filter(group => group.name === action.payload.groupName)
            .map(group => group.contexts)
            .reduce(i => i);
        contexts
            .filter(context => context._id === action.payload.contextId)
            .forEach(context => context.isActive = true);
        contexts
            .filter(context => context._id !== action.payload.contextId)
            .forEach(context => context.isActive = false);
        return newState;
    }),
    on(selectAll, (state, action) => {
        const newState = {
            ...state
        };
        const contextGroups = newState[action.payload.contextSelectorId].contextGroups
            .filter(group => group.name === action.payload.groupName);
        contextGroups.map(group => group.contexts)
            .reduce(i => i)
            .forEach(context => context.isActive = true);
        contextGroups.forEach(contextGroup => {
            contextGroup.totalSelected = contextGroup.contexts.filter(context => context.isActive).length;
        });
        return newState;
    }),
    on(selectNone, (state, action) => {
        const newState = {
            ...state
        };
        const contextGroups = newState[action.payload.contextSelectorId].contextGroups
            .filter(group => group.name === action.payload.groupName)
        contextGroups.map(group => group.contexts)
            .reduce(contexts => contexts)
            .forEach(context => context.isActive = false);

        contextGroups.forEach(contextGroup => {
            contextGroup.totalSelected = 0;
        });

        return newState;
    })
);
