/**
 * User: Kupin.R
 * Date: 5/21/14
 */



angular
    .module('flipto.components.campaigns')
    .directive('ftCampaignModeSelector',
    [
        'CampaignMode',
        function (CampaignMode) {

            return {
                replace: true,
                restrict: 'E',
                template: '<select ng-model="mode" ng-options="cm.value as cm.name for cm in model.options"></select>',
                scope: {
                    'mode': '='
                },
                link: function (scope) {

                    angular.extend(scope, {
                        model: {
                            options: [
                                {name: 'Daily unique visitors', value: CampaignMode.DailyUniqueVisitors},
                                {name: 'Daily signups', value: CampaignMode.DailySignups}
                            ]
                        }
                    });
                }
            };
        }
    ]);
