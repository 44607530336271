/**
 * Created by Roman on 2014-11-25.
 */


angular.module('flipto.components.podium', ['flipto.components.common'])
    .config(['$compileProvider', function ($compileProvider) {

        function PodiumController(_) {

            this.isEditable;
            this.podium;
            this.compareBy;

            /**
             * Set winner
             * @param finalist
             * @param place
             */
            this.setWinner = function (finalist, place) {
                this.podium[place] = finalist;
            };

            /**
             * Set runner
             * @param finalist
             * @param index
             */
            this.setRunner = function (finalist, index) {
                this.podium.runnerUps.items[index] = finalist;
            };

            /**
             * Bump down winners
             * @param finalist
             * @param place
             */
            this.bumpDown = function (finalist, place) {
                if (place === 'runner') throw 'Not supported';
                var podium = this.podium;
                if (place === 'second') {
                    angular.isDefined(podium.third) && (podium.third = podium.second);
                } else if (place === 'first') {
                    angular.isDefined(podium.third) && (podium.third = podium.second);
                    angular.isDefined(podium.second) && (podium.second = podium.first);
                }
                podium[place] = finalist;
            };

            this.move = function (finalist, toPlace) {
                if (toPlace === 'runner') {
                    this.remove(finalist);

                } else {
                    this.remove(finalist);
                    this.podium[toPlace] = finalist;
                }
            };

            this.remove = function (finalist) {
                var place = this.getPlace(finalist);
                if (!place) return;
                var podium = this.podium;
                if (place !== 'runner') {
                    podium[place] = null;
                } else {
                    _.forEach(podium.runnerUps.items, function (runner, index) {
                        if (areEquals(runner, finalist)) {
                            podium.runnerUps.items[index] = null;
                        }
                    });
                }
            };

            this.getPlace = function (finalist) {
                var podium = this.podium;
                if (areEquals(podium.first, finalist)) return 'first';
                if (areEquals(podium.second, finalist)) return 'second';
                if (areEquals(podium.third, finalist)) return 'third';
                if (_.find(podium.runnerUps.items, function (runner) {
                    return areEquals(runner, finalist);
                })) return 'runner';

                return null;
            };

            this.swap = function (winnerA, winnerB) {
                var placeA = this.getPlace(winnerA);
                var placeB = this.getPlace(winnerB);
                this.move(winnerA, placeB);
                this.move(winnerB, placeA);
            };

            /**
             * Returns true if place is taken
             * @param place
             * @returns {boolean}
             */
            this.isPlaceTaken = function (place) {
                return place === 'runner' ? false : !!this.podium[place];
            };

            /**
             * Returns true if place is taken by finalist
             * @param place
             * @returns {boolean}
             */
            this.isPlaceTakenByFinalist = function (finalist, place) {
                return areEquals(this.podium[place], finalist);
            };

            /**
             * Returns true if finalist already on podium
             * @param finalist
             * @returns {*|boolean}
             */
            this.isExists = function (finalist) {
                var podium = this.podium;
                var alreadyExists = areEquals(podium.first, finalist) || areEquals(podium.second, finalist) || areEquals(podium.third, finalist)
                    || !!_.find(podium.runnerUps.items, function (runner) {
                        return areEquals(runner, finalist);
                    });
                return alreadyExists;
            };

            var me = this;
            /**
             * Compare two finalists using compareBy key provided
             * @param first
             * @param second
             * @returns {boolean}
             */
            function areEquals(first, second) {
                if (first === second) return true;
                if (first != null && second != null)
                    return first[me.compareBy] === second[me.compareBy];
                return false;
            }

        }
        PodiumController.$inject = ["_"];

        $compileProvider.component('ftPodium', {
            bindings: {
                'podium': '=',
                "isEditable": "=",
                "compareBy": "@"
            },
            controller: PodiumController,
            controllerAs: "vm"
        }
        );

        $compileProvider.directive('ftSetWinner', ['$animate', '$compile', '$document', '_', function ($animate, $compile, $document, _) {

            var concurrencyResolutionMenuTemplate = '<ul class="podium-alert-menu ft-dropdown-menu"><li><a ng-click="replaceCurrent(); $event.stopPropagation();" translate>flipto.account.ReplaceCurrentPhoto</a></li><li><a ng-click="bumpCurrentDown(); $event.stopPropagation();" translate>flipto.account.BumpCurrentPhoto</a></li><li><a ng-click="swapWithCurrent(); $event.stopPropagation();">Swap with current story</a></li></ul>';

            return {
                restrict: 'A',
                require: '^ftPodium',
                link: function (scope, elem, attrs, podium) {

                    var place = attrs.placeType,
                        menuScope = angular.extend(scope.$new(true), {
                            isMenuVisible: false,
                            replaceCurrent: function () {
                                menuScope.isMenuVisible = false;
                                podium.remove(finalist);
                                podium.setWinner(finalist, place);
                            },
                            bumpCurrentDown: function () {
                                menuScope.isMenuVisible = false;
                                podium.remove(finalist);
                                podium.bumpDown(finalist, place);
                            },
                            swapWithCurrent: function () {
                                menuScope.isMenuVisible = false;
                                podium.swap(currentWinner, finalist)
                            }
                        }),
                        finalist,
                        currentWinner,
                        menuElem;

                    /**
                     * Grab finalist
                     */
                    scope.$watch(function () {
                        return scope.$eval(attrs.ftSetWinner);
                    }, function (value) {
                        finalist = value;
                    });

                    scope.$watch(function () {
                        return scope.$eval(attrs.ftPodiumWinner);
                    }, function (value) {
                        currentWinner = value;
                    });

                    /**
                     * Show/hide menu
                     */
                    menuScope.$watch('isMenuVisible', function (isVisible) {
                        if (isVisible) {
                            menuElem = $compile(concurrencyResolutionMenuTemplate)(menuScope);
                            $animate.enter(menuElem, elem[0].querySelector('.podium-photo'));
                        } else if (!!menuElem) {
                            $animate.leave(menuElem);
                        }
                    });

                    /**
                     * Handle click and add finalist to winners
                     */
                    elem.on('click', function (e) {
                        e.stopPropagation();
                        scope.$apply(function () {
                            if (podium.isEditable === false) return;
                            // if same slot - remove finalist
                            if (_.isEqual(currentWinner, finalist)) {
                                podium.remove(finalist);
                            } else {
                                var strategy = place === 'runner' ? setRunner : setWinner;
                                strategy(finalist, place);
                            }
                        });
                    });

                    /**
                     * Handle document click and hide menu
                     */
                    $document.on('click', function (e) {
                        e.stopPropagation();
                        scope.$apply(function () {
                            menuScope.isMenuVisible = false;
                        });
                    });

                    /**
                     * Set finalist as a winner (1,2,3 places)
                     */
                    function setWinner() {
                        if (podium.isPlaceTaken(place) === false) {
                            podium.remove(finalist);
                            podium.setWinner(finalist, place);
                        } else if (podium.isPlaceTakenByFinalist(finalist, place) === false) {
                            menuScope.isMenuVisible = true;
                        }
                    }

                    /**
                     * Set finalist as a runnerUp
                     */
                    function setRunner() {
                        podium.remove(finalist);
                        podium.setRunner(finalist, scope.$index);
                    }

                }
            };
        }]);

        $compileProvider.directive('ftPodiumWinner', [function () {
            return {
                restrict: 'EA',
                link: function (scope, elem, attrs) {

                    scope.$watch(function () {
                        return scope.$eval(attrs.ftPodiumWinner);
                    }, function (winner) {
                        toggleVisibility(angular.isDefined(winner));
                        //toggleDisabled(winner == null);
                    });

                    function toggleVisibility(isVisible) {
                        elem[isVisible ? 'removeClass' : 'addClass']('ng-hide');
                    }

                    function toggleDisabled(isDisabled) {
                        elem[isDisabled ? 'addClass' : 'removeClass']('disabled');
                    }
                }
            };
        }]);

        $compileProvider.directive('ftWinnerCaption', ['$animate', '$compile', '$filter', function ($animate, $compile, $filter) {

            var translate = $filter('translate'),
                captions = {
                    'small': {
                        'first': 'flipto.account.sections.platform.photoAdvocacy.place.First',
                        'second': 'flipto.account.sections.platform.photoAdvocacy.place.Second',
                        'third': 'flipto.account.sections.platform.photoAdvocacy.place.Third',
                        'runner': 'flipto.account.sections.platform.photoAdvocacy.RunnerUp'
                    },
                    'normal': {
                        'first': 'flipto.account.sections.platform.photoAdvocacy.1STPlace',
                        'second': 'flipto.account.sections.platform.photoAdvocacy.2NDPlace',
                        'third': 'flipto.account.sections.platform.photoAdvocacy.3RDPlace',
                        'runner': 'flipto.account.sections.platform.photoAdvocacy.RunnerUp'
                    }
                };

            return {
                restrict: 'EA',
                link: function (scope, elem, attrs) {

                    var caption;
                    attrs.$observe('ftWinnerCaption', function (captionType) {
                        if (!!caption) $animate.leave(caption);
                        if (angular.isDefined(attrs.placeType) === false) return;
                        var childScope = angular.extend(scope.$new(true), { type: captionType });
                        caption = $compile('<div class="winner-slot-caption-{{type}}">' + translate(captions[captionType][attrs.placeType]) + '</div>')(childScope);
                        $animate.enter(caption, elem[0].querySelector('.caption'));
                    });
                }
            };
        }]);

        $compileProvider.directive('ftPodiumPhoto', [function () {
            var template = '<div class="photo-matte"></div>' +
                '<div class="podium-photo-board">' +
                '<div class="icon"></div>' +
                '<span class="caption"></span>' +
                '</div>';

            return {
                restrict: 'A',
                compile: function (element, attrs) {
                    var photoElements = angular.element(template);
                    var imageElement = angular.element(element.children()[0]);
                    imageElement.addClass('podium-photo');
                    imageElement.append(photoElements);

                    return function (scope, elem, attrs) {
                    }
                }
            };
        }]);


    }]);