
export class StoryDescriptor {

    static $inject = ["parse", "story"];

    constructor(
        public parse,
        public story) { }

    isLandscape(): boolean {
        return this.story && this.story.guest && this.story.guest.poststay && this.story.guest.poststay.photo && this.parse.intOrDefault(this.story.guest.poststay.photo.photoWidth) > this.parse.intOrDefault(this.story.guest.poststay.photo.photoHeight);
    }

    isRtl(): boolean {
        return this.story && this.story.guest && this.story.guest.isRtl && !!+this.story.guest.isRtl;
    }

    hasPositiveQuote() {
        var guest = this.story.guest;
        return guest && guest.poststay && guest.poststay.quote && guest.poststay.quote.notesPositive && this.parse.bool(guest.poststay.quote.isPositive);
    }

    isHotelRatedNegativeQuote() {
        var guest = this.story.guest;
        return guest && guest.poststay && guest.poststay.quote && guest.poststay.quote['rating'] <= 1;
    }

    isHotelRatedNegativeCaption() {
        var guest = this.story.guest;
        return guest && guest.poststay && guest.poststay.photo && guest.poststay.photo['captionRating'] <= 1;
    }

    isHotelRatedNeutralQuote() {
        var guest = this.story.guest;
        return guest && guest.poststay && guest.poststay.quote && guest.poststay.quote['rating'] == 2;
    }

    isCaptionBetterThanQuote() {
        var guest = this.story.guest;
        if (!this.hasCaption()) {
            return false;
        }
        if (!this.hasPositiveQuote()) {
            return true;
        }
        var quoteRating = guest && guest.poststay && guest.poststay.quote && guest.poststay.quote['rating']
            ? guest.poststay.quote['rating']
            : 0;
        var captionRating = guest && guest.poststay && guest.poststay.photo && guest.poststay.photo['captionRating']
            ? guest.poststay.photo['rating']
            : 0;
        return quoteRating < captionRating;
    }

    isHotelRatedNeutralCaption() {
        var guest = this.story.guest;
        return guest && guest.poststay && guest.poststay.photo && guest.poststay.photo['captionRating'] == 2;
    }

    hasCaption() {
        var guest = this.story.guest;
        return guest && guest.poststay && guest.poststay.photo && guest.poststay.photo.caption && guest.poststay.photo.caption.length > 0;
    }

    hasPhoto(): boolean {
        var guest = this.story.guest;
        return guest && guest.poststay && !!guest.poststay.photo;
    };
}
