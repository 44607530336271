/**
 * Created by ostapenko.r on 7/24/2014.
 */
angular
    .module("flipto.accountv1.SharedFeatures")
    .filter("notificationDescription",
    [
        "$filter", "_",
        function ($filter, _) {

            var translate = $filter("translate");
            var descriptionsByType = [
                { type: 48, name: translate("flipto.components.notifications.GuestsArrivingTodayDescription") },
                { type: 49, name: translate("flipto.components.notifications.GuestsArrivingLaterDescription") },
                { type: 47, name: translate("flipto.components.notifications.WeeklyActivityDescription") },
                { type: 43, name: translate("flipto.components.notifications.DailyPoststayUpdateDescription") },
                { type: 44, name: translate("flipto.components.notifications.DailyFeedbackUpdateDescription") },
                { type: 56, name: translate("flipto.components.notifications.RecommendationNotificationDescription") },
                { type: 75, name: translate("flipto.components.notifications.PhotoContestEndedDescription") },
                { type: 77, name: translate("flipto.components.notifications.PhotoContestWinnersAnnouncedDescription") }
            ];

            return function (notificationType) {
                var notificationInfo = _.find(descriptionsByType, {type: notificationType});
                return notificationInfo ? notificationInfo.name : notificationType;
            };
        }
    ]);