/**
 * Created by ostapenko.r on 8/29/2014.
 */


angular.module('flipto.core.api.property', ['flipto.core.parse'])
    .config(['$provide', function ($provide) {

        $provide.factory('Property', ['urlFormatter', '$http', 'parse', function (urlFormatter, $http, parse) {

            /**
             * Property service
             * @constructor
             */
            function Property() {

                /**
                 * Get list of properties
                 * @returns {*}
                 */

                this.getCompanyProperties = function () {
                    return $http.get('/api/properties?isShowAll=false')
                        .then(function (response) {
                            return parse.arrayOrDefault(response.data);
                        });
                };

                /**
                 * Get property overview
                 * @param params
                 * @returns {*}
                 */

                this.overview = function (params) {
                    return $http.get(urlFormatter.format('/api/:type/:id/overview', params, true))
                        .then(function (response) {
                            return response.data;
                        });
                };


                /**
                 * Create new property
                 * @param name
                 * @param url
                 * @returns {*}
                 */
                this.create = function (companyUuid, name, url) {
                    return $http.post(urlFormatter.format('/api/v2/:companyUuid/companies/:companyUuid/properties', { companyUuid: companyUuid }), { name: name, url: url })
                        .then(function (response) {
                            return response.data;
                        });
                };

                /**
                 * Get emails data
                 * @param propertyUuid
                 * @returns {*}
                 */
                this.getEmailsData = function (propertyUuid) {
                    return $http.get(urlFormatter.format('/api/:propertyUuid/properties/:propertyUuid/communication/emails-data', { propertyUuid: propertyUuid }))
                        .then(function (response) {
                            return response.data;
                        });
                };

                /**
                 * Save emails data
                 * @param propertyUuid
                 * @param model
                 * @returns {*}
                 */
                this.saveEmailsData = function (propertyUuid, model) {
                    return $http.put(urlFormatter.format('/api/:propertyUuid/properties/:propertyUuid/communication/emails-data', { propertyUuid: propertyUuid }), model)
                        .then(function (response) {
                            return response.data;
                        });
                };

                /**
                 * Update property settings
                 * @param propertyUuid
                 * @param settings
                 * @returns {*}
                 */
                this.updateSettings = function (propertyUuid, settings) {
                    return $http.put(urlFormatter.format('/api/:propertyUuid/properties/:propertyUuid/settings', { propertyUuid: propertyUuid }), settings)
                        .then(function (response) {
                            return response.data;
                        })
                };

                this.getAdvocacyGuestJourney = function (propertyUuid) {
                    return $http.get(urlFormatter.format('/api/v2/:propertyUuid/properties/:propertyUuid/advocacy/guestjourney', { propertyUuid: propertyUuid }))
                        .then(function (response) {
                            return response.data;
                        })
                }

            }

            return new Property();
        }]);
    }]);