import { Pipe, PipeTransform } from '@angular/core';
import { Parse } from 'shared';

@Pipe({
  name: 'parseValue'
})
export class ParseValuePipe implements PipeTransform {

  transform(value: any, type: string): any {
    switch (type) {
      case 'bool':
        return Parse.bool(value);
      case 'array':
        return Parse.arrayOrDefault(value);
      case 'number':
        const number = Number(value);
        return Parse.intOrDefault(number);
    }
  }

}
