import { createAction, props } from '@ngrx/store';
import { CreateAccountDto } from '../../models/create-account.dto';
import { Company, Property } from '../../../../../core/authentication/models/user';
import { CreatePropertyDto } from '../../models/create-property.dto';


export const createAccount = createAction(
  '[Account Management] Create Account',
  props<{ data: CreateAccountDto }>()
);

export const createAccountFailure = createAction(
  '[Account Management] Create Account Failure',
  props<{ error: any }>()
);

export const createAccountSuccess = createAction(
  '[Account Management]  Create Account Success',
  props<{ createdCompany: Company }>()
);

export const createProperty = createAction(
  '[Account Management] Create Property',
  props<{ data: CreatePropertyDto }>()
);

export const createPropertyFailure = createAction(
  '[Account Management] Create Property Failure',
  props<{ error: any }>()
);

export const createPropertySuccess = createAction(
  '[Account Management]  Create Property Success',
  props<{ createdProperty: Property }>()
);
