/**
 * Created by ostapenko.r on 1/16/2015.
 */


angular
    .module('flipto.components.common.dateRange')
    .filter("dateRange", ["moment", function (moment) {
        var formatNoYear = "MMM D",
            formatWithYear = "MMM D, YYYY";

        return function (start, end) {
            var startMoment = moment.utc(start),
                endMoment = moment.utc(end);

            var isSameYear = startMoment.year() === endMoment.year();
            var startDateFormatted = isSameYear ? startMoment.format(formatNoYear) : startMoment.format(formatWithYear),
                endDateFormatted = endMoment.format(formatWithYear);

            return startDateFormatted + " - " + endDateFormatted;
        }
    }]);