/**
 * Created by Roman on 2014-11-24.
 */



angular.module('flipto.components.common.shortcuts', [])
    .config(['$compileProvider', function ($compileProvider) {

        $compileProvider.directive('ftShortcuts', ['$animate', '$compile', function ($animate, $compile) {

            var template = '<div class="shortcuts"><button ng-repeat="shortcut in shortcuts" class="{{shortcut.name}}" ng-show="shortcut.isVisible()" ng-click="shortcut.execute(); $event.stopPropagation();">{{shortcut.name}}</button></div>';

            return {
                restrict: 'A',
                require: 'ftShortcuts',
                controller: ["$scope", function ($scope) {
                    $scope.isVisible = false;

                    this.show = function () {
                        $scope.isVisible = true;
                    };

                    this.hide = function () {
                        $scope.isVisible = false;
                    };
                }],
                link: function (scope, elem, attrs, ctrl) {

                    var shortcuts,
                        shortcutsElement;

                    scope.$watch(function () {
                        shortcuts = scope.$eval(attrs.ftShortcuts);
                        return shortcuts;
                    }, createShortcuts);
                    scope.$watch('isVisible', createShortcuts);

                    elem.on('mouseenter', function () {
                        scope.$apply(ctrl.show);
                    });

                    elem.on('mouseleave', function () {
                        scope.$apply(ctrl.hide);
                    });

                    function createShortcuts() {
                        if (scope.isVisible) {
                            shortcutsElement = $compile(template)(angular.extend(scope.$new(true), { shortcuts: shortcuts }));
                            $animate.enter(shortcutsElement, elem);
                        } else if (!!shortcutsElement) {
                            $animate.leave(shortcutsElement);
                        }
                    }

                }
            };
        }]);
    }]);