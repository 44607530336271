<ft-modal-wrapper>
  <ft-touchpoint-header
    name="Post-stay Process"
    [canActivate]="true"
    [isActive]="(selectedPoststayProcessComponent$ | async)?.isActive == 1"
    (activeToggled)="commit($event)"
    >
  </ft-touchpoint-header>
  <mat-tab-group
    class="mat-tab-group-nav"
    mat-align-tabs="center"
    animationDuration="0ms"
    [(selectedIndex)]="selectedIndex">
    <mat-tab label="messaging">
      <ng-template matTabContent>
        <ft-snapshot-form
          *ngIf="selectedPoststayProcessComponent$ | async | clone as data"
          [loading$]="selectedPoststayProcessLoading$"
          [commitSuccess$]="commitSuccess$"
          (commit)="onFormCommit($event)">
          <section class="ft2-section">
            <div class="ft2-container">
              <form ngForm>
                <ft-sidebar-tabs [classes]="['ft2-journey-editor-nav']">
                  <ft-sidebar-tab name="Intro">
                  </ft-sidebar-tab>

                  <ft-sidebar-tab name="Rating">
                  </ft-sidebar-tab>

                  <ft-sidebar-tab name="Quote & Feedback">
                  </ft-sidebar-tab>

                  <ft-sidebar-tab name="Campaign">
                  </ft-sidebar-tab>

                  <ft-sidebar-tab name="Share">
                  </ft-sidebar-tab>

                  <ft-sidebar-tab name="Thanks">
                  </ft-sidebar-tab>

                  <ft-sidebar-tab sectionName="If guests gave a low rating"></ft-sidebar-tab>

                  <ft-sidebar-tab name="Feedback (low rating)">
                  </ft-sidebar-tab>

                  <ft-sidebar-tab name="Thanks (low rating)">
                  </ft-sidebar-tab>

                  <ft-sidebar-tab sectionName="If guest rated from email"></ft-sidebar-tab>

                  <ft-sidebar-tab name="Intro (high rating)">
                  </ft-sidebar-tab>

                  <ft-sidebar-tab name="Intro (low rating)">
                  </ft-sidebar-tab>
                </ft-sidebar-tabs>
              </form>
            </div>
          </section>
        </ft-snapshot-form>
      </ng-template>
    </mat-tab>
    <!-- <mat-tab label="styling">
      <ng-template matTabContent>
        <ft-snapshot-form
          *ngIf="stylingConfirmationExperienceComponent$ | async | clone as styleData"
          [loading$]="[selectedConfirmationExperienceLoading$]"
          [commitSuccess$]="[commitSuccess$]"
          (commit)="onFormCommit($event)">
          <section class="ft2-section">
            <div class="ft2-container">
              <div class="row">
                <div class="col-12">
                  <form ngForm>
                    
                  </form>
                </div>
              </div>
            </div>
          </section>
        </ft-snapshot-form>
      </ng-template>
    </mat-tab>
    <mat-tab label="integration">
      <ng-template matTabContent>
        <section class="ft2" *ngIf="integrationConfirmationExperienceComponent$ | async | clone as integrationData">
          <form class="ft2-block"
                #form="ngForm"
                ngForm>
              <ft-loading [loading$]="selectedConfirmationExperienceLoading$"></ft-loading>
              <div class="ft2-container">
                  <div class="row">
                  </div>
              </div>
          </form>
        </section>
      </ng-template>
    </mat-tab> -->
  </mat-tab-group>
</ft-modal-wrapper>
