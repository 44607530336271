/**
 * Created by ostapenko.r on 7/24/2014.
 */
angular
    .module("flipto.accountv1.SharedFeatures")
    .filter("notificationName",
    [
        "$filter", "_",
        function ($filter, _) {

            var translate = $filter("translate");
            var namesByType = [
                { type: 48, name: translate("flipto.components.notifications.guest") },
                { type: 49, name: translate("flipto.components.notifications.future_guest") },
                { type: 47, name: translate("flipto.components.notifications.weekly") },
                { type: 43, name: translate("flipto.components.notifications.daily_photo") },
                { type: 44, name: translate("flipto.components.notifications.daily_feedback") },
                { type: 56, name: translate("flipto.components.notifications.recommendation_notification") },
                { type: 75, name: translate("flipto.components.notifications.PhotoContestEnded") },
                { type: 77, name: translate("flipto.components.notifications.PhotoContestWinnersAnnounced") }
            ];

            return function (notificationType) {
                var notificationInfo = _.find(namesByType, { type: notificationType });
                return notificationInfo ? notificationInfo.name : notificationType;
            };
        }
    ]);