import {
    loadBannerEmailSuccess,
    loadBannerEmailFail,
    loadBannerEmail,
    commitBannerEmail,
    commitBannerEmailSuccess,
    loadStylingBannerEmail,
    loadStylingBannerEmailSuccess,
    loadStylingBannerEmailFail,
    saveBannerEmail,
    saveBannerEmailSuccess,
    saveBannerEmailFail
} from './actions';
import { Action, createReducer, on } from "@ngrx/store";
import { State } from "./state";

export const reducer = createReducer(
    {
        loading: false,
        settings: null,
        commitSuccess: false,
        stylingSettings: null
    } as State,
    on(loadBannerEmail, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(loadBannerEmailSuccess,  (state, {data}) => {
        return {
            ...state,
            loading: false,
            settings: data
        };
    }),
    on(loadBannerEmailFail, (state, {error}) => {
        return {
            ...state,
            loading: false,
            error
        };
    }),
    // commit
    on(commitBannerEmail, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(commitBannerEmailSuccess, (state, {data}) => {
        return {
            ...state,
            loading: false,
            settings: data
        };
    }),
    // styling
    on(loadStylingBannerEmail, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(loadStylingBannerEmailSuccess, (state, {data}) => {
        return {
            ...state,
            loading: false,
            stylingSettings: data
        };
    }),
    on(loadStylingBannerEmailFail, (state, {error}) => {
        return {
            ...state,
            loading: false,
            error
        };
    }),
    // save snapshot
    on(saveBannerEmail, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(saveBannerEmailSuccess, (state) => {
        return {
            ...state,
            loading: false
        };
    }),
    on(saveBannerEmailFail, (state, {error}) => {
        return {
            ...state,
            loading: false,
            error
        };
    })
);

export function bannerEmailReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}
