import {
    Component,
    EventEmitter,
    Input,
    OnChanges, OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { NgbTimepicker, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { TimeRange } from '../../models/time-range.model';

@Component({
    selector: 'ft-simple-timerange-picker',
    templateUrl: './simple-timerange-picker.component.html',
    styleUrls: ['./simple-timerange-picker.component.scss'],
})
export class SimpleTimerangePickerComponent implements OnChanges, OnInit {
    ngbFromTime: NgbTimeStruct;
    ngbToTime: NgbTimeStruct;
    @Input('from') fromTime: string;
    @Input('to') toTime: string;
    @Input() fromLabel: string;
    @Input() fromPlaceholder = 'Enter time';
    @Input() toLabel: string;
    @Input() toPlaceholder = 'Enter time';
    @Input() divider: boolean;
    @Input() seconds: boolean;
    @Output() changed = new EventEmitter<TimeRange>();
    _timeRange: TimeRange;
    @ViewChild('tp') timepicker: NgbTimepicker;

    constructor() {}

    set timeRange(timeRange: TimeRange) {
        this._timeRange = timeRange;
        this.fromTime = timeRange?.fromTime;
        this.toTime = timeRange?.toTime;
    }

    get timeRange(): TimeRange {
        return this._timeRange;
    }

    ngOnInit() {
        this.initTimes();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('fromTime') || changes.hasOwnProperty('toTime')) {
            this.initTimes();
        }
    }

    initTimes() {
        this.ngbFromTime = TimeRange.timeStringToNgbTimeStruct(this.fromTime);
        this.ngbToTime = TimeRange.timeStringToNgbTimeStruct(this.toTime);
        this.onRangeSelected();
    }

    onRangeSelected() {
        const fromTime = TimeRange.ngbTimeStructToTimeString(this.ngbFromTime, this.seconds);
        const toTime = TimeRange.ngbTimeStructToTimeString(this.ngbToTime, this.seconds);
        this.timeRange = new TimeRange(fromTime, toTime);
        this.changed.next(this.timeRange);
    }
}
