import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SpaceBase, MixedSpace } from './models/space';
import { SpaceType } from './models/space-type';

interface SpacesHistoryItem { timesVisited: number; type: SpaceType; id: string; }
export { SpacesHistoryItem };

@Injectable({
    providedIn: 'root'
})
export class SpacesHistory {

    private subj = new Subject();
    visited$ = this.subj.asObservable();

    constructor() {
        this.subj.next(this.storedHistory);
    }

    addNew(space: SpaceBase) {
        const history = this.storedHistory;
        const idx = history.findIndex(i => i.id === space.id && i.type === space.type);
        if (idx !== -1) {
            const existingSpace = history.splice(idx, 1)[0];
            existingSpace.timesVisited++;
            history.push(existingSpace);
        } else {
            history.push({ timesVisited: 1, type: space.type, id: space.id } as SpacesHistoryItem);
        }
        localStorage.setItem('SpacesHistory', JSON.stringify(history));
        this.subj.next(history);
    }

    private get storedHistory(): SpacesHistoryItem[] {
        return storedHistory();
    }

    get history(): SpacesHistoryItem[] {
        return this.storedHistory;
    }

    get(pastEvent: number = -1): SpacesHistoryItem | null {
        const history = this.storedHistory;
        if (!history.length || Math.abs(pastEvent) > history.length) { return null; }
        return history[history.length - Math.abs(pastEvent)];
    }
}

export function storedHistory(): SpacesHistoryItem[] {
    return (JSON.parse(localStorage.getItem('SpacesHistory')) || []) as SpacesHistoryItem[];
}
