/**
 * Created by Roman on 2015-04-20.
 */



angular.module('flipto.core.api.advocates', ['flipto.core.parse', 'flipto.core.lodash', 'flipto.core.moment'])
    .config(['$provide', function ($provide) {

        $provide.factory('Advocates', ['urlFormatter', '$http', 'parse', '_', function (urlFormatter, $http, parse, _) {


            /**
             * Advocates service
             * @constructor
             */
            function Advocates() {

                function transformListResponse(data) {
                    if (!data) return data;
                    var obj = {};
                    obj.advocates = parse.arrayOrDefault(data.advocates);
                    return obj;
                }

                /**
                 * Return list of advocates for property and specified filter query
                 * @param property
                 * @param query
                 * @returns {*}
                 */
                this.list = function (property, query) {
                    var model = angular.extend({}, { property: property }, query);
                    return $http.get(urlFormatter.format('/api/properties/:property/advocates', model, true))
                        .then(function (response) {
                            return response.data;
                        })
                        .then(transformListResponse);
                };

                /**
                 * Add others category if applicable
                 * @param promotions
                 * @returns {*}
                 */
                function fixPromotions(promotions) {
                    var other = _.reduce(promotions, function (memo, promotion) {
                        if (!_.includes(['fb', 'li', 'tw'], promotion.title)) {
                            memo.totalFollowers += promotion.totalFollowers;
                        }
                        return memo;
                    }, { title: 'Others', totalFollowers: 0 });
                    if (other.totalFollowers != 0) {
                        promotions.push(other);
                    }
                    return promotions;
                }

                function transformDetailsResponse(data) {

                    data.totalFollowers = parse.intOrDefault(data.totalFollowers);
                    data.totalVisitors = parse.intOrDefault(data.totalVisitors);
                    data.totalSignups = parse.intOrDefault(data.totalSignups);

                    // add recent activity type
                    var activityType;
                    if (data.poststay && data.poststay.photo) {
                        if (data.poststay.photo.shareDate) activityType = 'photo.share';
                        else if (data.poststay.photo.voteDate) activityType = 'photo.vote';
                        else if (data.poststay.photo.finalistDate) activityType = 'photo.finalist';
                        else if (data.poststay.photo.createDate) activityType = 'photo.upload';
                    }
                    else if (data.poststay && data.poststay.quote) {
                        if (data.poststay.quote.createDate) activityType = 'quote';
                    }
                    else if (data.prestay) {
                        if (data.prestay.epochDate) activityType = 'prestay.share';
                    }

                    data.lastActivityType = activityType;

                    if (data.prestay) {
                        data.prestay.promotions = parse.arrayOrDefault(data.prestay.promotions);
                        _.each(data.prestay.promotions, function (promotion) {
                            promotion.totalFollowers = parse.intOrDefault(promotion.totalFollowers);
                        });
                        fixPromotions(data.prestay.promotions);

                        data.prestay.incentives = parse.arrayOrDefault(data.prestay.incentives);
                        _.each(data.prestay.incentives, function (incentive) {
                            incentive.epochExpirationDate = parse.int(incentive.epochExpirationDate);
                            incentive.utcExpirationDate = moment(incentive.epochExpirationDate).utc();
                        });
                    }

                    if (data.poststay) {
                        data.poststay.promotions = parse.arrayOrDefault(data.poststay.promotions);
                        _.each(data.poststay.promotions, function (promotion) {
                            promotion.totalFollowers = parse.intOrDefault(promotion.totalFollowers);
                        });
                        fixPromotions(data.poststay.promotions);

                        data.poststay.incentives = parse.arrayOrDefault(data.poststay.incentives);
                        _.each(data.poststay.incentives, function (incentive) {
                            incentive.epochExpirationDate = parse.int(incentive.epochExpirationDate);
                            incentive.utcExpirationDate = moment(incentive.epochExpirationDate).utc();
                        });
                    }

                    if (data.photoContest) {
                        data.photoContest.photos = parse.arrayOrDefault(data.photoContest.photos);
                        data.photoContest.emailsSent = parse.arrayOrDefault(data.photoContest.emailsSent);

                        _.each(data.photoContest.photos, function (photo) {
                            photo.promotions = parse.arrayOrDefault(photo.promotions);
                            photo.friendPromotions = parse.arrayOrDefault(photo.friendPromotions);
                            if(photo.winner){
                                photo.winner.epochIncentiveExpirationDate = parse.int(photo.winner.epochIncentiveExpirationDate);
                                photo.winner.utcExpirationDate = moment(photo.winner.epochIncentiveExpirationDate).utc();
                            }

                            fixPromotions(photo.promotions);
                            fixPromotions(photo.friendPromotions);
                        });
                    }

                    return data;
                }

                /**
                 * Return advocate details
                 */
                this.details = function (property, advocate) {
                    var model = { property: property, advocateUUID: advocate };
                    return $http.get(urlFormatter.format('/api/:property/properties/:property/advocates/:advocateUUID', model, false))
                        .then(function (response) {
                            return response.data;
                        })
                        .then(transformDetailsResponse);
                };

                this.requestPromotionCertificate = function (companyCode, guestUUID) {
                    var model = { companyCode: companyCode, guestUUID: guestUUID };
                    return $http.post(urlFormatter.format('/api/promoter-certificates/request-verification', model, true))
                        .then(function (response) {
                            return response.data;
                        });
                };

                this.save = function (propertyId, guestUUID, advocate) {
                    var model = { property: propertyId, guestUUID: guestUUID };
                    return $http.put(urlFormatter.format('/api/:property/properties/:property/advocates/:guestUUID', model, false), advocate)
                        .then(function (response) {
                            return response.data;
                        });
                }

                this.delete = function (propertyId, guestUUID, reason) {
                    var model = { property: propertyId, guestUUID: guestUUID };
                    return $http.put(urlFormatter.format('/api/:property/properties/:property/advocates/:guestUUID', model, false), _.assign({
                        PersonUUID: guestUUID
                    }, reason)).then(function (response) {
                        return response.data;
                    });
                }

                /**
                 * Search list of advocates
                 * @param property
                 * @param query
                 * @returns {*}
                 */
                this.search = function (property, query) {
                    var model = angular.extend({}, { property: property }, query);
                    return $http.get(urlFormatter.format('/api/:property/properties/:property/guests', model, true))
                        .then(function (response) {
                            return response.data;
                        })
                        .then(function (data) {
                            return parse.arrayOrDefault(data)
                        });
                };

                /**
                 * Search stories
                 * @param property
                 * @param query
                 * @returns {*}
                 */
                this.searchStories = function (property, query) {
                    var model = angular.extend({}, { property: property }, {searchTerm: query});
                    return $http.get(urlFormatter.format('/api/:property/properties/:property/photos', model, true))
                        .then(function (response) {
                            return response.data;
                        })
                        .then(function (data) {
                            var tmp = data;
                            tmp.advocates = parse.arrayOrDefault(tmp.advocates);
                            _.each(tmp.advocates, function (a) {
                                a.ai_data = parse.arrayOrDefault(a.ai_data);
                            });
                            return tmp;
                        });
                };
            }

            return new Advocates();
        }]);
    }]);
