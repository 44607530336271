import { Pipe, PipeTransform } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DateUtils } from 'shared';

@Pipe({
    name: 'ngbDateToDate'
})
export class NgbDateToDatePipe implements PipeTransform {
    transform(date: NgbDate): Date {
        return DateUtils.ngbDateToDate(date);
    }
}
