import { Component } from '@angular/core';
import { IdentityService } from '../../../../core/authentication/services/identity.service';
import { UserData } from '../../models/user-data';
import { select, Store } from '@ngrx/store';
import * as fromAuthentication from '../../store/index';
import { ChangePasswordDto } from '../../models/change-password-dto';
import { Observable } from 'rxjs';
import { StateService } from '@uirouter/core';


@Component({
  selector: 'ft-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  userData$: Observable<UserData> = this.store.pipe(select(fromAuthentication.getUserData));

  changePasswordLoading$: Observable<boolean> = this.store.pipe(select(fromAuthentication.isChangePasswordLoading));
  changeUserLoading$: Observable<boolean> = this.store.pipe(select(fromAuthentication.isChangeUserLoading));
  uploadProfilePictureLoading$: Observable<boolean> = this.store.pipe(select(fromAuthentication.isUploadProfilePictureLoading));

  changePasswordError$: Observable<any> = this.store.pipe(select(fromAuthentication.getChangePasswordError));
  changeUserError$: Observable<any> = this.store.pipe(select(fromAuthentication.getChangeUserError));
  uploadProfilePictureError$: Observable<any> = this.store.pipe(select(fromAuthentication.getUploadProfilePictureError));
  files: File[];

  closeButtonVisible = false;

  constructor(public identityService: IdentityService,
              private stateService: StateService,
              private store: Store<fromAuthentication.AuthenticationState>) {
    this.closeButtonVisible = !!this.getBackUrl();
  }

  goBack() {
    document.location.href = this.getBackUrl();
  }

  changeUser(profileData: Partial<UserData>) {
    this.store.dispatch(fromAuthentication.changeUser({ data: profileData }));
  }

  changePassword(data: ChangePasswordDto) {
    this.store.dispatch(fromAuthentication.changePassword({ data }));
  }

  uploadProfilePicture(files: File[]) {
    this.store.dispatch(fromAuthentication.uploadProfilePicture({ file: files[0] }));
  }

  getBackUrl() {
    const params = new URLSearchParams(this.stateService.params);
    return params.get('backUrl');
  }
}
