/**
 * Created by roman.kupin on 10/3/2017.
 */

declare let angular;

export const NAME = "ftPageClassHook";
export const SNAKECASE_NAME = "ft-page-class-hook";

export const DEFINITION = ["$state", function ($state) {
    return {
        link: function (scope, elem) {

            let current;

            scope.$watch(() => $state.current, (newState) => {
                drop(current);
                add(newState);
                saveNew(newState);
            });


            function drop(state) {
                if (!!state && !!state.data && !!state.data.pageClass) {
                    elem.removeClass(state.data.pageClass);
                }
            }

            function add(state) {
                if (!!state && !!state.data && !!state.data.pageClass) {
                    elem.addClass(state.data.pageClass);
                }
            }

            function saveNew(state) {
                if (!!state && !!state.data && !!state.data.pageClass) {
                    current = state;
                }
            }
        }
    };
}];
