/**
 * User: Kupin.R
 * Date: 5/29/14
 */


angular
    .module("flipto.components.goals")
    .directive("ftEditCampaignGoals",
        [
            "_", function (_) {

                return {
                    restrict: "E",
                    templateUrl: "/app/components/goals/directives/editCampaignGoals.html",
                    scope: {
                        "goals": "=",
                        "incentives": "=",
                        "culture": "=",
                        "filter": "&",
                        "types": "=",
                        "incentiveTag": "="
                    },
                    link: function (scope) {
                        //watching for incentives and then filtering them based on the tag.
                        scope.$watchCollection('incentives', function (incentives) {
                            if (scope.incentiveTag) {
                                // filter incentives by proper tag
                                scope.filteredByTagIncentives = { item: [] };                 
                                scope.incentives.item.forEach(function (incentive) {
                                    if ((incentive.categoryFlags & scope.incentiveTag) === scope.incentiveTag) {
                                        scope.filteredByTagIncentives.item.push(incentive);
                                    }
                                });
                            }
                        });
                        scope.$watchCollection('goals', function (goals) {
                            if (angular.isArray(scope.types)) {
                                scope.filteredGoals = _.filter(goals, function (goal) {
                                    return goal && _.includes(scope.types, goal.type);
                                });
                            }
                        });

                        /**
                         * Return true if property has no incentives
                         * @returns {boolean}
                         */
                        scope.hasNoIncentives = function () {
                            return !scope.incentives || !angular.isArray(scope.incentives.item) ||
                                _.isEmpty(scope.incentives.item);
                        };

                        /**
                         * Adds new goal
                         */
                        scope.addNewGoal = function () {
                            scope.goals.push({
                                isActive: 1,
                                type: scope.types[0]
                            });
                        };

                        /**
                         * Deactivate goal
                         * @param goal
                         */
                        scope.deactivate = function (goal) {
                            goal.isActive = 0;
                        };

                        /**
                         * Activate goal
                         * @param goal
                         */
                        scope.activate = function (goal) {
                            goal.isActive = 1;
                        };

                        /**
                         * Remove goal
                         * @param goal
                         */
                        scope.remove = function (goal) {
                            scope.goals.splice(scope.goals.indexOf(goal), 1);
                        };

                    }
                };
            }
        ]);
