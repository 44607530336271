/**
 * Created by max.ackerman on 8/21/2018.
 */


export const NAME = "ftPaging";

export class PagingController {

    static $inject = [];

    onPageChange: any;
    pageIndex: number = 1;

    constructor() {

    }

    next() {
        this.pageIndex++;
        this.onPageChange({page: this.pageIndex});
    }

    prev() {
        this.pageIndex--;
        this.onPageChange({page: this.pageIndex});
    }

}
export const DEFINITION = {
    scope: {
        onPageChange: "&",
    },
    require: "ftPaging",
    controllerAs: "$ftPaging",
    controller: PagingController,
    bindToController: true
}
