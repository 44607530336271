declare let environmentSettings;
declare let __webpack_public_path__;
declare let ASSET_PATH;
__webpack_public_path__ = !!ASSET_PATH ? ASSET_PATH : (!!environmentSettings ? environmentSettings.CDN_BASE_URL : '') + 'dist/v2-hybrid/';

import { enableProdMode, NgZone } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { UIRouter, UrlService } from '@uirouter/core';
import { APP_MODULE } from './app/angularjs.module';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { getFeaturesAsync } from 'v2/app/features/index';
import { createRootReducer } from 'v2/app/reducers/rootReducer';
if (environment.production) {
  enableProdMode();
}

// kill old sessions
if (!!localStorage.getItem("flipto.user") || !!localStorage.getItem("flipto.impersonate")) {
  ["flipto.session", "flipto.impersonate", "flipto.user", "flipto.impersonateToken", "flipto.token"]
    .forEach(key => localStorage.removeItem(key));
}

if (!!sessionStorage.getItem('flipto.session')) {
  sessionStorage.removeItem('flipto.session');
}

const app = document.createElement('ft-account-app');
document.body.appendChild(app);

APP_MODULE.config(() => { });
export async function loadAccountFeatures($ocLazyLoad, $ngRedux) {
  await getFeaturesAsync()
    .then(features => {
      features.forEach(feature => {
        $ocLazyLoad.load({ name: feature.MODULE.name, rerun: true });
      });

      $ngRedux.replaceReducer(
        createRootReducer(features),
      );
    });
}

(async () => {
  await getFeaturesAsync()
    .then(mod => {
      platformBrowserDynamic().bootstrapModule(AppModule)
        .then(async (platformRef) => {
          // Intialize the Angular Module
          // get() the UIRouter instance from DI to initialize the router
          const router = platformRef.injector.get(UIRouter);
          const urlService: UrlService = router.urlService;

          // Instruct UIRouter to listen to URL changes
          function startUIRouter() {
            urlService.listen();
            urlService.sync();
          }

          platformRef.injector.get(NgZone).run(() => {
            const $ocLazyLoad = platformRef.injector.get('$ocLazyLoad');
            const $ngRedux = platformRef.injector.get('$injector').get('$ngRedux');

            mod.forEach(feature => {
              $ocLazyLoad.load({ name: feature.MODULE.name, rerun: true });
            });

            $ngRedux.replaceReducer(
              createRootReducer(mod),
            );

            setTimeout(() => startUIRouter());
          });
        })
        .catch(err => {
          console.error(err);
        });

    });

})();
