/**
 * Created by Roman on 2014-11-11.
 */



angular.module('flipto.core.api.account', ['flipto.core.parse'])
    .config(['$provide', function ($provide) {

        $provide.factory('Account', ['urlFormatter', '$http', 'parse', 'SpacesService', function (urlFormatter, $http, parse, spacesService) {

            /**
             * Account service
             * @constructor
             */
            function Account() {
                /**
                 * Returns account overview
                 * @param model
                 * @returns {*}
                 */
                this.overview = function (model) {
                    return $http.get(urlFormatter.format('/api/' + spacesService.getCompanyUuid() + '/overview', model, true))
                        .then(function (response) {
                            return response.data;
                        });
                };

                /**
                 * Returns account settings
                 * @returns {*}
                 */
                this.settings = function () {
                    return $http.get('/api/' + spacesService.getCompanyUuid() + '/settings')
                        .then(function (response) {
                            response.data.company['@is_active'] = parse.bool(response.data.company['@is_active']);
                            response.data.company['@is_account_v1_enabled'] = parse.bool(response.data.company['@is_account_v1_enabled']);
                            response.data.company['@is_account_v2_enabled'] = parse.bool(response.data.company['@is_account_v2_enabled']);
                            response.data.company['@is_account_v2_default'] = parse.bool(response.data.company['@is_account_v2_default']);
                            return response.data;
                        });
                };

                /**
                 * Update account data
                 * @param model
                 * @returns {*}
                 */
                this.update = function (model) {
                    return $http.post('/api/' + spacesService.getCompanyUuid() + '/accounts/update', model)
                        .then(function (response) {
                            return response.data;
                        });
                };



                /**
                 * Activate/deactivate account
                 * @param active
                 * @returns {*}
                 */
                this.activate = function (active) {
                    return $http.post('/api/' + spacesService.getCompanyUuid() + '/accounts/activate/' + active)
                        .then(function (response) {
                            return response.data;
                        });
                };

                this.saveFeatureFlag = function (flagUuid, value) {
                    return $http.put(urlFormatter.format('/api/v2/:companyUuid/companies/:companyUuid/feature-flags/:flagUuid', { companyUuid: spacesService.getCompanyUuid(), flagUuid: flagUuid }), value)
                        .then(function (response) {
                            return response.data;
                        });
                };

            }

            return new Account();
        }]);
    }]);