<ft-modal-wrapper>
  <ft-touchpoint-header [name]="'Re-engagement Experience'" [activeToggleEnabled]='false'>
  </ft-touchpoint-header>
  <mat-tab-group class="mat-tab-group-nav" mat-align-tabs="center" animationDuration="0ms">
    <mat-tab label="Styling">
      <ng-template matTabContent>
        <ft-permissions [ftPermissions]="'FliptoAdministration'" [hide]="false">
          <ng-container *ngIf="stylingViewModel$ | async | clone as data">
            <ft-snapshot-form (commit)="commit({stylingViewModel: $event})" [loading$]="isLoading$"
              [commitSuccess$]="commitSuccess$">
              <div class="ft2-container">
                <div class="ft2-block -small">
                  <p>
                    This CSS applies to the re-engagement overlay and toaster.
                  </p>
                </div>
                <div class="ft2-block -small">
                  <form ngForm>
                    <!-- Re-engagement style. -->
                    <ft-code-editor [replaceInvalidSymbols]="true" [language]="'css'" [title]="'CSS'" name="reengagementCss"
                      [(ngModel)]="data.reengagementCss" [required]="false">
                      <ft-edit-panel label="CSS" [loading]="isLoading$ | async">
                        <div class="col-8" [innerHTML]="data.reengagementCss | ftPlaceholder"></div>
                      </ft-edit-panel>
                    </ft-code-editor>
                    <!-- End Re-engagement style -->
                  </form>
                </div>
              </div>
            </ft-snapshot-form>
          </ng-container>
        </ft-permissions>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</ft-modal-wrapper>
