<ft-modal-editor-wrapper>
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
    <div class="ft2-input -large v-center mb-4"
         *ngIf="!rules?.length">
        No dates have been set.
    </div>
    <div class="ft2-schedule-editor ft2-form-group flex f-md-wrap"
         *ngFor="let rule of rules; let i = index;">
        <div class="row">
            <div class="col-4 pr-0">
                <ng-select class="ft2-select -no-border"
                           [searchable]="false"
                           [clearable]="false"
                           (ngModelChange)="onChange(rule, i, 'freq', $event)"
                           [ngModel]="rule.options.freq">
                    <ng-option [value]="rruleFrequency.DAILY">
                        Once
                    </ng-option>
                    <ng-option [value]="rruleFrequency.YEARLY">
                        Recurring annually
                    </ng-option>
                    <ng-option [value]="rruleFrequency.MONTHLY">
                        Recurring monthly
                    </ng-option>
                    <ng-option [value]="rruleFrequency.WEEKLY">
                        Recurring weekly
                    </ng-option>
                </ng-select>
            </div>
            <div class="col-8 pr-2">
                <ng-container
                    *ngIf="rule.options.freq === rruleFrequency.DAILY || rule.options.freq === rruleFrequency.YEARLY">
                    <div class="d-flex full-width pt-1">
                        <div class="fd-column">
                            <div class="uppercase tiny-text muted-text bold-text">From</div>
                            <input class="ft2-input -no-border pl-0 pb-2"
                                   [ngModel]="rule.options.dtstart"
                                   [maxDate]="toNgbDate(rule.options.until)"
                                   (ngModelChange)="onChange(rule, i, 'dtstart', $event)"
                                   placeholder="Start date"
                                   readonly
                                   datepickerClass="ft-daterange-picker-calendar"
                                   ngbDatepicker
                                   firstDayOfWeek="7"
                                   (click)="dpStart.open()"
                                   #dpStart="ngbDatepicker"
                                   [dayTemplate]="t"
                                   [navigation]="'arrows'">
                        </div>
                        <div class="fd-column">
                            <div class="uppercase tiny-text muted-text bold-text">To</div>
                            <input class="ft2-input -no-border pl-0 pb-2"
                                   [minDate]="toNgbDate(rule.options.dtstart)"
                                   [ngModel]="rule.options.until"
                                   (ngModelChange)="onChange(rule, i, 'until', $event)"
                                   placeholder="End date"
                                   readonly
                                   datepickerClass="ft-daterange-picker-calendar"
                                   ngbDatepicker
                                   firstDayOfWeek="7"
                                   (click)="dpEnd.open()"
                                   #dpEnd="ngbDatepicker"
                                   [dayTemplate]="t"
                                   [navigation]="'arrows'">
                        </div>
                    </div>
                    <hr/>
                    <div>
                    </div>
                </ng-container>
                <ng-container *ngIf="rule.options.freq === rruleFrequency.MONTHLY">
                </ng-container>
            </div>
        </div>

        <!--        <button class="daterange_remove"></button>-->
    </div>
    <button class="ft2-btn-light -outline -float-left -large">
        + Add date range
    </button>
</ft-modal-editor-wrapper>
<ng-template #t let-date let-focused="focused" let-disabled="disabled">
          <span class="custom-day"
                [class.disabled]="disabled"
                [class.focused]="focused">
            {{ date.day }}
          </span>
</ng-template>
