import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: "initialLetters"
})
export class InitialLettersPipe implements PipeTransform {
    transform(words: string[]) {
        if (!words || !Array.isArray(words)) return words;
        return words.filter(w => !!w).map(w => w.substring(0, 1));
    }
}