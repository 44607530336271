/**
 * Created by roman.kupin on 9/8/2017.
 */


import "v1/components/common/brackets/brackets";
import "v1/components/common/carousel/carousel";
import "v1/components/common/changes-filters/changesFilters";
import "v1/components/common/code-editor/codeEditor";
import "v1/components/common/contextual-navigation/module";
import "v1/components/common/contextual-navigation/contextsConfig";
import "v1/components/common/date/month.filter";
import "v1/components/common/date-range/module";
import "v1/components/common/date-range/dateFormat.filter";
import "v1/components/common/date-range/datePicker";
import "v1/components/common/date-range/dateRange";
import "v1/components/common/date-range/dateRange.filter";
import "v1/components/common/date-range/daysUntilDate.filter";
import "v1/components/common/date-range/predefinedDateRanges";
import "v1/components/common/date-range/snapshot";
import "v1/components/common/disabled/disabled";
import "v1/components/common/download/download";
import "v1/components/common/enter/enter";
import "v1/components/common/events-related/module";
import "v1/components/common/events-related/forwardClick";
import "v1/components/common/face-highlighter/faceHighlighter";
import "v1/components/common/field-editors/module";
import "v1/components/common/field-editors/fieldEditors";
import "v1/components/common/file-upload/fileUpload";
import "v1/components/common/focus/focus";
import "v1/components/common/iframe-content/iframeContent";
import "v1/components/common/images/images";
// import "v1/components/common/images/backgroundImage";
import "v1/components/common/images/image";
import "v1/components/common/infinite-scroll/infiniteScroll";
import "v1/components/common/language-selector/languageSelector";
import "v1/components/common/language-selector/languagesToCSV.filter";
import "v1/components/common/list/module";
import "v1/components/common/list/hideWrapper";
import "v1/components/common/list/list";
import "v1/components/common/list/removable";
import "v1/components/common/list-editor/module";
import "v1/components/common/list-editor/addToList";
import "v1/components/common/list-editor/addToListByEnter";
import "v1/components/common/list-editor/addToListBySubmit";
import "v1/components/common/list-editor/clearAfterAdd";
import "v1/components/common/list-editor/listEditor";
import "v1/components/common/loading-animation/loadingAnimation";
import "v1/components/common/map/map";
import "v1/components/common/modal/modal";
import "v1/components/common/modal-fields-editor/modalFieldsEditor";
import "v1/components/common/model-formatters/module";
import "v1/components/common/model-formatters/availableTags";
import "v1/components/common/model-formatters/csv";
import "v1/components/common/model-formatters/decode";
import "v1/components/common/model-formatters/htmlSanitize";
import "v1/components/common/model-formatters/numbersOnly";
import "v1/components/common/model-formatters/plainText";
import "v1/components/common/navigation/navigation";
import "v1/components/common/navigation-pane/navigationPane";
import "v1/components/common/navigation-pane/navigationPaneItem";
import "v1/components/common/notifications/notifications";
import "v1/components/common/object-list/module";
import "v1/components/common/object-list/objectList";
import "v1/components/common/panel/panel";
import "v1/components/common/panel/panelStatus";
import "v1/components/common/pending-changes/pendingChanges";
import "v1/components/common/placeholder/placeholder";
import "v1/components/common/rotate/rotate";
import "v1/components/common/select/select";
import "v1/components/common/shortcuts/shortcuts";
import "v1/components/common/sidenav/sidenav";
import "v1/components/common/snapshot/snapshot";
import "v1/components/common/scroll/scroll";
import "v1/components/common/sortable/sortable";
import "v1/components/common/spinner/spinner";
import "v1/components/common/stats/stat";
import "v1/components/common/switch/switch";
import "v1/components/common/text-formatting/module";
import "v1/components/common/text-formatting/abbreviation";
import "v1/components/common/text-formatting/capitalize";
import "v1/components/common/text-formatting/direction";
import "v1/components/common/text-formatting/location";
import "v1/components/common/text-formatting/onOff";
import "v1/components/common/text-formatting/quot";
import "v1/components/common/text-formatting/truncate";
import "v1/components/common/text-formatting/USD";
import "v1/components/common/ui-ace/uiAce.directive";
import "v1/components/common/user-agent/module";
import "v1/components/common/validation/module";
import "v1/components/common/validation/emailCsv";
import "v1/components/common/validation/inputUrl.decorator";
import "v1/components/common/validation/password";
import "v1/components/common/validation/sameAs";
import "v1/components/common/validation/validationChecklist.directive";
import "v1/components/common/tasks/tasks.module";
import 'v1/components/common/tasks/disableWhileTaskPending.directive';
import 'v1/components/common/tasks/finishTask.directive';
import 'v1/components/common/tasks/hideWhileTaskPending.directive';
import 'v1/components/common/tasks/loadingAnimTaskPending.directive';
import 'v1/components/common/tasks/showWhileTaskPending.directive';
import 'v1/components/common/tasks/startTask.directive';
import 'v1/components/common/tasks/tasks.service';
import 'v1/components/common/model-operations/module';
import 'v1/components/common/model-operations/addArrayItem.directive';
import 'v1/components/common/model-operations/removeArrayItem.directive';



import "v1/components/common/module";

import 'v1/components/common/paging/paging.module';

declare const angular;

export const TypeScriptCommonComponents = angular.module('flipto.components.common.typescript', [
    "flipto.components.common.paging"
]);

//require("v1Assets/css/components/_modal.css");