/**
 * Created by roman.kupin on 6/13/2018.
 */

import * as CurateGuest from "./curateGuest.state.config";
import * as CurateAll from "./curateAll.state.config";
import * as FeedbackAll from "./feedbackAll.state.config";
import * as Timeline from "./timeline.state.config";
import * as Recommendation from "./recommendation.state.config";
import * as Home from "./home.state.config";
import * as Invitation from "./invitation.state.config";

const GLOBAL_STATES = [
    CurateGuest.StateConfig,
    CurateAll.StateConfig,
    FeedbackAll.StateConfig,
    Timeline.StateConfig,
    Recommendation.StateConfig,
    Home.StateConfig,
    Invitation.StateConfig
];

export default GLOBAL_STATES;
