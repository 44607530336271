/**
 * Created by Roman on 2016-03-25.
 */

angular.module('flipto.core.api.preStay', []).config([
	'$provide',
	function($provide) {
		$provide.factory('PreStay', [
			'$http',
			'urlFormatter',
			function($http, urlFormatter) {
				/**
				 * PoreStay service
				 * @constructor
				 */
				function PreStay() {
					/**
					 * Load campaigns
					 * @param propertyUUID
					 * @param startDate
					 * @param endDate
					 */
					this.loadCampaigns = function(propertyUUID, startDate, endDate) {
						var model = {
							propertyUUID: propertyUUID,
							startDate: startDate,
							endDate: endDate
						};
						return $http
							.get(
								urlFormatter.format(
									'/api/:propertyUUID/properties/:propertyUUID/layers/prestay/campaigns',
									model,
									true
								)
							)
							.then(function(response) {
								return response.data;
							});
					};

					/**
					 * Returns stories
					 * @param propertyUUID
					 * @param startDate
					 * @param endDate
					 * @returns {*}
					 */
					this.loadStoryInfo = function(propertyUUID, startDate, endDate) {
						return $http
							.get(
								urlFormatter.format(
									'/api/:propertyUUID/properties/:propertyUUID/layers/prestay/stories',
									{
										propertyUUID: propertyUUID,
										startDate: startDate,
										endDate: endDate
									},
									true
								)
							)
							.then(function(response) {
								return response.data;
							});
					};
				}

				return new PreStay();
			}
		]);
	}
]);
