/**
 * Created by Roman on 2015-08-19.
 */

angular.module('flipto.core.environment', [])
    .provider('environment', [function () {

        var defaults = {
                debugOAuth: false,
                FliptoHubDomain: 'hub.flipto.com',
                CurationShareContentSize: 220,
                logging: {
                    enabled: false,
                    logSize: 1000
                },
                v1BaseUrl: "https://manage.flip.to",
                version: "2.2.0"
            },
            settings;

        configure(defaults);
        this.configure = configure;
        this.$get = [function () {
            return settings;
        }];

        /**
         * Configure environment
         * @param newConfig
         */
        function configure(newConfig) {
            settings = angular.extend(settings || {}, newConfig);
        }
    }]);
