/**
 * Created by Roman on 2015-05-01.
 */



angular.module("flipto.components.common.textFormatting")
    .filter('location', function () {

        function addSection(text, p0, p1) {
            if (!p0 && !p1) return text;
            if (text.length) text += ", ";
            if (p0) text += p0;
            if (p0 && p1) text += " ";
            if (p1) text += p1;
            return text;
        }

        function getLocationFormatted(location) {
            var str = "";
            str = addSection(str, location.streetAddress);
            str = addSection(str, location.city);
            str = addSection(str, location.region, location.postalCode);
            str = addSection(str, location.countryName);

            return str;
        }

        return function (location) {
            if (!location) return "";
            return getLocationFormatted(location);
        }
    });