import { NgModule } from '@angular/core';
import { UserImageRendererComponent } from './user-image-renderer/user-image-renderer.component';
import { GridSearchFilterComponent } from './filters/grid-search-filter/grid-search-filter.component';
import { GridSelectFilterComponent } from './filters/grid-select-filter/grid-select-filter.component';
import { DropdownFilterComponent } from './filters/dropdown-filter/dropdown-filter.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccountSharedModule } from 'apps/account/src/account-shared';
import { AgGridModule } from 'ag-grid-angular';

@NgModule({
    declarations: [
        UserImageRendererComponent,
        GridSearchFilterComponent,
        GridSelectFilterComponent,
        DropdownFilterComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        AccountSharedModule,
        AgGridModule.withComponents([
            UserImageRendererComponent,
            GridSearchFilterComponent,
            GridSelectFilterComponent,
            DropdownFilterComponent,
        ]),
    ],
    exports: [
        UserImageRendererComponent,
        GridSearchFilterComponent,
        GridSelectFilterComponent,
        DropdownFilterComponent,
    ]
})
export class CommonGridModule { }
