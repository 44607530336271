<ft-modal-wrapper>
    <ft-touchpoint-header [name]="'Planner flow'" [activeToggleEnabled]="false">
    </ft-touchpoint-header>

    <mat-tab-group class="mat-tab-group-nav" mat-align-tabs="center" animationDuration="0ms"
                   [(selectedIndex)]="selectedIndex">
        <mat-tab label="Messaging">
            <ng-template matTabContent>
                <ft-permissions [ftPermissions]="'FliptoAdministration'" [hide]="false">
                    <ft-snapshot-form [loading$]="isLoading$" [commitSuccess$]="commitSuccess$"
                                      (commit)="commitMessagingTab($event)">
                        <section class="ft2-block -small">
                            <div class="ft2-container">
                                <form ngForm>
                                    <ng-container *ngIf="messaging">
                                        <ng-container *ngIf="languages$ | async as languages">
                                            <ft-language-preview-panel [languages]="languages"
                                                                       *ngIf="languages.length > 1"
                                                                       [selectedLanguage]="selectedPreviewLanguage"
                                                                       (previewLanguageChange)="onPreviewLanguageChange($event)"
                                                                       [simplified]="true">
                                            </ft-language-preview-panel>
                                        </ng-container>
                                        <ft-multiple-textarea-editor title="Guest policy message"
                                                                     summary="This optional message will be displayed when your audience is asked how many adults and kids are traveling with them."
                                                                     name="headcountStepDisclosure"
                                                                     [fields]="[{ name: 'headcountStepDisclosure', label: '', languageName: 'languageName' }]"
                                                                     [validationDefinition]="[{required: false, maxLength: 300, type: 'text'}]"
                                                                     [(ngModel)]="messaging">
                                            <ft-edit-panel label="Guest policy message">
                                                <div
                                                    [innerHTML]="(messaging | languagePreview: 'headcountStepDisclosure' : selectedPreviewLanguage?.languageCode) | ftPlaceholder"></div>
                                            </ft-edit-panel>
                                        </ft-multiple-textarea-editor>
                                        <ft-multiple-textarea-editor title="Intro step greeting"
                                                                     summary="This greeting will be displayed as a quote from the communication point person when we ask your audience to reveal who they are. This is a great way to add your own personality to the greeting. If left blank, we'll display a default message."
                                                                     name="introGreetingText"
                                                                     [tokens]="greetingsMessageTokens"
                                                                     [maxlength]="300"
                                                                     [required]="false"
                                                                     [fields]="[{ name: 'introGreetingText', label: '', languageName: 'languageName' }]"
                                                                     [(ngModel)]="messaging">
                                            <ft-edit-panel label="Intro step greeting">
                                                <div
                                                    [innerHTML]="(messaging | languagePreview: 'introGreetingText' : selectedPreviewLanguage?.languageCode) | ftPlaceholder"></div>
                                            </ft-edit-panel>
                                        </ft-multiple-textarea-editor>

                                        <ft-html-editor title="Aspirational pitch message"
                                                        summary="This message will be displayed as a quote from the communication point person when we showcase your property on collaborating Discovery websites. This is a great way to add your own personality to your pitch message. If left blank, we'll display a default message."
                                                        name="brandWelcomeText"
                                                        [maxlength]="450"
                                                        [required]="false"
                                                        [fields]="[{ name: 'brandWelcomeText', label: '', languageName: 'languageName' }]"
                                                        [(ngModel)]="messaging">
                                            <ft-edit-panel label="Aspirational pitch message">
                                                <div
                                                    [innerHTML]="(messaging | languagePreview: 'brandWelcomeText' : selectedPreviewLanguage?.languageCode) | ftPlaceholder"></div>
                                            </ft-edit-panel>
                                        </ft-html-editor>
                                    </ng-container>
                                </form>
                            </div>
                        </section>
                    </ft-snapshot-form>
                </ft-permissions>
            </ng-template>
        </mat-tab>
        <mat-tab label="Styling">
            <ng-template matTabContent>
                <ft-permissions [ftPermissions]="'FliptoAdministration'" [hide]="false">
                    <ft-snapshot-form (commit)="commit({styling: $event})" [loading$]="isLoading$"
                                      [commitSuccess$]="commitSuccess$">
                        <form ngForm>
                            <ng-container *ngIf="stylingViewModel$ | async | clone as data">
                                <section class="ft2-block -small">
                                    <div class="ft2-container">
<!--                                        <ft-select-editor title="Theme"-->
<!--                                                          [(ngModel)]="data.plannerFlowTheme"-->
<!--                                                          [placeholder]="'Select theme'"-->
<!--                                                          name="plannerFlowTheme"-->
<!--                                                          [bindLabel]="'name'"-->
<!--                                                          [bindValue]="'value'"-->
<!--                                                          [options]="plannerFlowThemeOptions$ | async">-->
<!--                                            <ft-edit-panel label="Theme">-->
<!--                                                <ng-container *ngFor="let theme of plannerFlowThemeOptions$ | async">-->
<!--                                                    <ng-container-->
<!--                                                        *ngIf="data?.plannerFlowTheme == theme?.value">-->
<!--                                                        {{ theme?.name | ftPlaceholder }}-->
<!--                                                    </ng-container>-->
<!--                                                </ng-container>-->
<!--                                            </ft-edit-panel>-->
<!--                                        </ft-select-editor>-->
                                        <ft-code-editor [replaceInvalidSymbols]="true" [language]="'css'" [title]="'CSS'" name="plannerFlowCss"
                                                        [(ngModel)]="data.plannerFlowCss">
                                            <ft-edit-panel label="CSS" [loading]="isLoading$ | async"
                                                           [error]="error$ | async">
                                                <div class="col-8"
                                                     [innerHTML]="data.plannerFlowCss | ftPlaceholder"></div>
                                            </ft-edit-panel>
                                        </ft-code-editor>
                                    </div>
                                </section>
                            </ng-container>
                        </form>
                    </ft-snapshot-form>
                </ft-permissions>
            </ng-template>
        </mat-tab>
        <mat-tab label="Integration">
            <ng-template matTabContent>
                <ft-permissions [ftPermissions]="'FliptoAdministration'" [hide]="false">
                    <ft-snapshot-form [loading$]="isLoading$" [commitSuccess$]="commitSuccess$"
                                      (commit)="commit({integration: $event})">
                        <section class="ft2-block -small">
                            <div class="ft2-container">
                                <form ngForm>
                                    <ng-container *ngIf="integration$ | async | clone as data">
                                        <ft-select-editor title="Headcount step"
                                                          [(ngModel)]="data.isHeadcountStepEnabled"
                                                          name="isHeadcountStepEnabled"
                                                          [options]="discoveryPropertySettingOptions">
                                            <ft-edit-panel [disabled]="true"
                                                           *ngIf="(isFlightBookingOptionActive$ | async)"
                                                           summary="Headcount step is turned On because your platform is configured with flight settings."
                                                           label="Headcount step">
                                                <ng-container *ngFor="let item of discoveryPropertySettingOptions">
                                                    <div *ngIf="item.value === data.isHeadcountStepEnabled">
                                                        {{ item.label | ftPlaceholder }}
                                                    </div>
                                                </ng-container>
                                            </ft-edit-panel>
                                            <ft-edit-panel *ngIf="!(isFlightBookingOptionActive$ | async)"
                                                           label="Headcount step">
                                                <ng-container *ngFor="let item of discoveryPropertySettingOptions">
                                                    <div *ngIf="item.value === data.isHeadcountStepEnabled">
                                                        {{ item.label | ftPlaceholder }}
                                                    </div>
                                                </ng-container>
                                            </ft-edit-panel>
                                        </ft-select-editor>
                                        <ft-select-editor title="Flight steps"
                                                          *ngIf="(isFlightBookingOptionActive$ | async) && (displayedVersion > discoveryVersionsEnum.V1)"
                                                          [(ngModel)]="data.isFlightStepsEnabled"
                                                          name="isFlightStepsEnabled"
                                                          [options]="discoveryPropertySettingOptions">
                                            <ft-edit-panel label="Flight steps">
                                                <ng-container *ngFor="let item of discoveryPropertySettingOptions">
                                                    <div *ngIf="item.value === data.isFlightStepsEnabled">
                                                        {{ item.label | ftPlaceholder }}
                                                    </div>
                                                </ng-container>
                                            </ft-edit-panel>
                                        </ft-select-editor>
                                        <ng-container *ngIf="displayedVersion > discoveryVersionsEnum.V1">
                                            <ft-select-editor title="Display stories in timeline"
                                                              [(ngModel)]="data.isGuestStoriesFallbackEnabled"
                                                              name="isGuestStoriesFallbackEnabled"
                                                              [options]="discoveryGuestStoriesFallbackOptions">
                                                <ft-edit-panel label="Display stories in timeline"
                                                               summary="Show guest stories alongside selected dates on the linear calendar if there are no experiences to display. If no guest stories are available, property photos will be displayed instead.">
                                                    <ng-container
                                                        *ngFor="let item of discoveryGuestStoriesFallbackOptions">
                                                        <div *ngIf="item.value === data.isGuestStoriesFallbackEnabled">
                                                            {{ item.label | ftPlaceholder }}
                                                        </div>
                                                    </ng-container>
                                                </ft-edit-panel>
                                            </ft-select-editor>
                                        </ng-container>
                                        <ft-toggle-editor name="isRoomsEnabled"
                                                          title="Rooms field"
                                                          summary="Planners will be asked how many rooms they want."
                                                          label="Status"
                                                          [(ngModel)]="data.isRoomsEnabled">
                                            <ft-edit-panel label="Rooms field">
                                                <div>
                                                    {{ data.isRoomsEnabled | ftOnOffToggle }}
                                                </div>
                                            </ft-edit-panel>
                                        </ft-toggle-editor>
                                        <ft-custom-editor title="Communications Point Person for Discovery"
                                                          summary="Choose a team member to be the friendly face for your org when communicating with travelers on websites and email.">
                                            <ft-edit-panel label="Communications Point Person for Discovery">
                                                <ng-container *ngFor="let user of (users$ | async)">
                                                    <ng-container
                                                        *ngIf="data?.signatureCompanyUserUuid === user?.CompanyUserUuid">
                                                        <div>
                                                            {{ (data.isCommunicationPointPersonTitleVisible ? user?.displayName : user?.FullName) | ftPlaceholder }}
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </ft-edit-panel>
                                            <div content>
                                                <ng-select class="ft2-select -large mb-3"
                                                           [(ngModel)]="data.signatureCompanyUserUuid"
                                                           [placeholder]="'Select person'"
                                                           [name]="'signatureCompanyUserUuid'"
                                                           [clearable]="false"
                                                           [searchable]="false"
                                                           [items]="users$ | async"
                                                           [bindValue]="'CompanyUserUuid'"
                                                           [bindLabel]="'displayName'">
                                                </ng-select>
                                                <div class="ft2-input -large v-center h-between"
                                                     *ngIf="isCommunicationUserSelected(data)">
                                                    <label>Always display title</label>
                                                    <mat-slide-toggle
                                                        name="isCommunicationPointPersonTitleVisible"
                                                        [(ngModel)]="data.isCommunicationPointPersonTitleVisible">
                                                    </mat-slide-toggle>
                                                </div>
                                            </div>
                                        </ft-custom-editor>

                                    </ng-container>
                                </form>
                            </div>
                        </section>
                    </ft-snapshot-form>
                </ft-permissions>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</ft-modal-wrapper>
