/**
 * Created by ostapenko.r on 9/25/2014.
 */
angular.module('flipto.components.changesTransformer', ['flipto.core.inherits', 'flipto.core.lodash'])
    .constant('transformType', {
        FlatData: 1,
        ArrayData: 2,
        MultiLanguageData: 3,
        BookingEngineData: 4,
        BookingEngineIntegrationData: 5,
        NotificationData: 6,
        MixedData: 7,
        PhotoContestPageIntegration: 8,
        HomepageMixedData: 9,
        AccountData: 10
    })
    .factory("changesTransformer",
    [
        "inherits", "transformType", "_",
        function (inherits, transformType, _) {


            function Transformer(changes, source) {
                this.changes = changes;

                this.source = source;

                this.results = [];

                this.result = {};
            }


            Transformer.prototype.create = function () {
                throw "Not implemented";
            };
            Transformer.prototype.transform = function () {
                throw "Not implemented";
            };


            function ArrayDataTransformer(changes, source) {
                ArrayDataTransformer.superClass.constructor.call(this, changes, source);
                this.sourceKey = 'id';
                this.destinationKey = 'id';


                this.arrayNestingDepth = 0;

            }

            inherits(FlatDataTransformer, ArrayDataTransformer);
            ArrayDataTransformer.prototype.transform = function () {
                var self = this;
                _.forEach(self.changes, function (change) {
                    if (change.kind !== 'E' && change.kind !== 'N') return;

                    var index = change.path[self.arrayNestingDepth],
                        sourceKeyValue = self.source[index][self.sourceKey],
                        data = {},
                        arrayData,
                        notExist;

                    data[self.destinationKey] = sourceKeyValue;

                    arrayData = _.find(self.results, data) || {};
                    notExist = _.isEmpty(arrayData);


                    var sourceName = _.last(change.path);
                    var map = _.find(self.mapper, {source: sourceName});
                    var fieldName = map && map.destination || sourceName;

                    if (change.kind === 'E') {
                        arrayData[fieldName] = change.rhs;
                    } else if (change.kind === 'N') {
                        _.isObject(change.rhs) ? angular.extend(arrayData, change.rhs) : arrayData[fieldName] = change.rhs;
                    }

                    if (notExist) {
                        arrayData[self.destinationKey] = sourceKeyValue;
                        _.forEach(self.mapper, function (map) {
                            arrayData[map.destination] = arrayData[map.destination] || self.source[index][map.source];
                        });

                        self.results.push(arrayData);
                    }
                });
                return self.results;
            };
            function MultiLanguageDataTransformer(changes, source) {
                MultiLanguageDataTransformer.superClass.constructor.call(this, changes, source);
                this.sourceKey = 'code';
                this.destinationKey = 'languageCode';
            }

            inherits(ArrayDataTransformer, MultiLanguageDataTransformer);
            function BookingEngineDataTransformer(changes, source) {
                BookingEngineDataTransformer.superClass.constructor.call(this, changes, source);
                this.sourceKey = 'id';
                this.destinationKey = 'bookingEngineUUID';
            }

            inherits(ArrayDataTransformer, BookingEngineDataTransformer);
            function NotificationDataTransformer(changes, source) {
                NotificationDataTransformer.superClass.constructor.call(this, changes, source);
                this.sourceKey = 'type';
                this.destinationKey = 'type';
                this.mapper.push({ source: 'userUUIDs', destination: 'userUUIDs' });
            }

            inherits(ArrayDataTransformer, NotificationDataTransformer);
            function IntegrationDataTransformer(changes, source) {
                IntegrationDataTransformer.superClass.constructor.call(this, changes, source);
                this.mapper.push({ source: 'type', destination: 'bookingEngineTypeID' });
            }

            inherits(BookingEngineDataTransformer, IntegrationDataTransformer);
            IntegrationDataTransformer.prototype.transform = function () {
                BookingEngineDataTransformer.superClass.transform.call(this);
                var self = this;
                _.forEach(self.changes, function (change) {

                    if (!_.includes(change.path, 'codes')) return;

                    var index = change.path[0],
                        sourceKeyValue = self.source[index][self.sourceKey],
                        filter = {},
                        arrayData,
                        notExist;

                    filter[self.destinationKey] = sourceKeyValue;

                    arrayData = _.find(self.results, filter) || {};
                    notExist = _.isEmpty(arrayData);
                    arrayData.codes = arrayData.codes || {newCodes: [], deleteCodes: []};

                    if (change.kind === 'E' && _.last(change.path) === 'code') {
                        arrayData.codes.deleteCodes.push(change.lhs);
                        arrayData.codes.newCodes.push(change.rhs);
                    } else if (change.item && change.item.kind === 'N') {
                        arrayData.codes.newCodes.push(change.item.rhs.code);
                    } else if (change.item && change.item.kind === 'D') {
                        arrayData.codes.deleteCodes.push(change.item.lhs.code);
                    }

                    var codeDiffs = _.union(_.difference(arrayData.codes.deleteCodes, arrayData.codes.newCodes),
                        _.difference(arrayData.codes.newCodes, arrayData.codes.deleteCodes));

                    _.remove(arrayData.codes.deleteCodes, function(code){
                        return !_.includes(codeDiffs, code);
                    });
                    _.remove(arrayData.codes.newCodes, function(code){
                        return !_.includes(codeDiffs, code);
                    });

                    if (notExist) {
                        arrayData[self.destinationKey] = sourceKeyValue;
                        _.forEach(self.mapper, function (map) {
                            arrayData[map.destination] = arrayData[map.destination] || self.source[index][map.source];
                        });
                        self.results.push(arrayData);
                    }
                });
                return self.results;
            };

            /*
             * START
             * */




            function MixedDataTransformer(changes, source) {
                MixedDataTransformer.superClass.constructor.call(this, changes, source);
                this.arrayNestingDepth = 2;
            }

            inherits(MultiLanguageDataTransformer, MixedDataTransformer);

            MixedDataTransformer.prototype.transform = function () {
                var self = this;
                var originalSource = self.source;
                var originalChanges = self.changes;
                self.source = originalSource.languages.item;
                self.changes = _.filter(originalChanges, function (change) {
                    return change.path[0] === 'languages';
                });

                var arrayResult = MixedDataTransformer.superClass.transform.call(self);

                self.result = _.isEmpty(arrayResult) ? self.result : {languages: arrayResult};

                self.source = originalSource;
                self.changes = _.filter(originalChanges, function (change) {
                    return change.path[0] !== 'languages';
                });

                _.forEach(self.changes, function (change) {
                    if (change.kind !== 'E' && change.kind !== 'N') return;
                    var fieldName = _.last(change.path);

                    if (change.kind === 'E') {
                        self.result[fieldName] = change.rhs;
                    } else if (change.kind === 'N') {
                        _.isObject(change.rhs) ? angular.extend(self.result, change.rhs) : self.result[fieldName] = change.rhs;
                    }
                });
                return self.result;
            };


            function HomepageMixedDataTransformer(changes, source) {
                HomepageMixedDataTransformer.superClass.constructor.call(this, changes, source);

            }

            inherits(MixedDataTransformer, HomepageMixedDataTransformer);

            HomepageMixedDataTransformer.prototype.transform = function () {
                var self = this;
                self.result = HomepageMixedDataTransformer.superClass.transform.call(self);

                if(_.has(self.result, 'languages')){
                    var languages = [];
                    _.forEach(self.result.languages, function(language){
                        var languageCopy = angular.copy(language);
                        var item = {};
                        item[self.destinationKey] = languageCopy[self.destinationKey];
                        delete languageCopy[self.destinationKey];
                        item.data = languageCopy;
                        languages.push(item);
                    });
                    self.result.languages = {item: languages};
                }
                return self.result;
            };


            /*
             * END
             * */

            function FlatDataTransformer(changes, source) {
                FlatDataTransformer.superClass.constructor.call(this, changes, source);

                /**
                 * Map property names from source
                 * object to object which will be passed to api
                 */
                this.mapper = [];
            }

            inherits(Transformer, FlatDataTransformer);
            FlatDataTransformer.prototype.transform = function () {
                var self = this;
                _.forEach(self.changes, function (change) {
                    if (change.kind !== 'E' && change.kind !== 'N') return;

                    var sourceName = _.last(change.path);
                    var map = _.find(self.mapper, {source: sourceName});
                    var fieldName = map && map.destination || sourceName;


                    if (change.kind === 'E') {
                        self.result[fieldName] = change.rhs;
                    } else if (change.kind === 'N') {
                        _.isObject(change.rhs) ? angular.extend(self.result, change.rhs) : self.result[fieldName] = change.rhs;
                    }
                });
                return self.result;
            };

            function AccountDataTransformer(changes, source) {
                AccountDataTransformer.superClass.constructor.call(this, changes, source);
                this.mapper.push({source: '@code', destination: 'code'});
                this.mapper.push({source: '@date_format', destination: 'dateFormat'});
                this.mapper.push({source: '@is_active', destination: 'isActive'});
                this.mapper.push({source: '@is_account_v1_enabled', destination: 'isAccountV1Enabled'});
                this.mapper.push({source: '@is_account_v2_enabled', destination: 'isAccountV2Enabled'});
                this.mapper.push({source: '@is_account_v2_default', destination: 'isAccountV2Default'});
                this.mapper.push({source: '@name', destination: 'name'});
                this.mapper.push({source: '@url', destination: 'url'});
                this.mapper.push({source: '@date_format', destination: 'dateFormat'});
            }

            inherits(FlatDataTransformer, AccountDataTransformer);

            function PhotoContestIntegrationDataTransformer(changes, source) {
                PhotoContestIntegrationDataTransformer.superClass.constructor.call(this, changes, source);
            }

            inherits(Transformer, PhotoContestIntegrationDataTransformer);
            PhotoContestIntegrationDataTransformer.prototype.transform = function () {
                var self = this;
                self.result = {
                    isBookNowActive: self.source.isBookNowActive,
                    isFooterActive: self.source.isFooterActive,
                    codes: {
                        deleteCodes: [],
                        newCodes: []
                    }
                };

                _.forEach(self.changes, function (change) {
                    if (change.kind == 'A') {

                        if (change.item.kind == 'N') {
                            self.result.codes.newCodes.push(change.item.rhs.code);
                        }
                        if (change.item.kind == 'D') {
                            self.result.codes.deleteCodes.push(change.item.lhs.code);
                        }
                    } else if (change.kind == 'E' &&  _.last(change.path) === 'code') {
                        self.result.codes.deleteCodes.push(change.lhs);
                        self.result.codes.newCodes.push(change.rhs);
                    }
                });
                var codeDiffs = _.union(_.difference(self.result.codes.deleteCodes, self.result.codes.newCodes),
                    _.difference(self.result.codes.newCodes, self.result.codes.deleteCodes));

                    _.remove(self.result.codes.deleteCodes, function(code){
                        return !_.includes(codeDiffs, code);
                    });
                    _.remove(self.result.codes.newCodes, function(code){
                        return !_.includes(codeDiffs, code);
                    });

                return self.result;
            };

            /**
             * Creates transformer factory by type
             * @param type
             * @param options
             * @returns {*}
             */
            function resolveFactory(type, changes, source) {
                switch (type) {
                    case transformType.MultiLanguageData:
                    {
                        return new MultiLanguageDataTransformer(changes, source);
                    }
                    case transformType.ArrayData:
                    {
                        return new ArrayDataTransformer(changes, source);
                    }
                    case transformType.FlatData:
                    {
                        return new FlatDataTransformer(changes, source);
                    }
                    case transformType.BookingEngineData:
                    {
                        return new BookingEngineDataTransformer(changes, source);
                    }
                    case transformType.BookingEngineIntegrationData:
                    {
                        return new IntegrationDataTransformer(changes, source);
                    }
                    case transformType.NotificationData:
                    {
                        return new NotificationDataTransformer(changes, source);
                    }
                    case transformType.MixedData:
                    {
                        return new MixedDataTransformer(changes, source);
                    }
                    case transformType.PhotoContestPageIntegration:
                    {
                        return new PhotoContestIntegrationDataTransformer(changes, source);
                    }
                    case transformType.HomepageMixedData:{
                        return new HomepageMixedDataTransformer(changes, source);
                    }
                    case transformType.AccountData:{
                        return new AccountDataTransformer(changes, source);
                    }
                    default:
                    {
                        throw "Not supported density precision";
                    }
                }
            }

            /**
             * Transform deepDiff changes format to api format
             * @params {transformType} type
             * @params {Object} changes
             * @params {Array} source
             */
            return function (type, changes, source) {
                var factory = resolveFactory(type, changes, source);
                return factory.transform();
            };
        }
    ]);