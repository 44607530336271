
export enum SocialNetwork {
    Unknown = 0,
    Facebook = 1,
    Twitter = 2,
    LinkedIn = 3,
    Instagram = 5,
    Pinterest = 6
}

export enum SocialNetworkTitle {
    Facebook = "fb",
    Twitter = "tw",
    LinkedIn = "li",
    Instagram = "ig"
}

export enum SocialNetworkName {
    Facebook = "facebook",
    Twitter = "twitter",
    LinkedIn = "linkedin",
    Instagram = "instagram"
}

export const SOCIAL_NETWORKS = [
    { type: SocialNetwork.Facebook, title: SocialNetworkTitle.Facebook, name: SocialNetworkName.Facebook },
    { type: SocialNetwork.Twitter, title: SocialNetworkTitle.Twitter, name: SocialNetworkName.Twitter },
    { type: SocialNetwork.LinkedIn, title: SocialNetworkTitle.LinkedIn, name: SocialNetworkName.LinkedIn },
    { type: SocialNetwork.Instagram, title: SocialNetworkTitle.Instagram, name: SocialNetworkName.Instagram }
];
