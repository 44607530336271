/**
 * Created by roman.kupin on 9/13/2017.
 */

import * as AppComponent from "../app.component";

export class ComponentConfig {

    static $inject = ["$compileProvider"];

    constructor($compileProvider) {
        const components = [AppComponent];
        components.forEach(component => {
            $compileProvider.component(component.NAME, component.OPTIONS);
        });
    }
}
