import { createAction, props } from '@ngrx/store';
import { InvitationEmailSettings, InvitationEmailStylingSettings } from '../../models/invitation-email';

export const loadinvitationEmail = createAction('[Invitation Email] load',
props<{
  componentGroupUuid: string,
  propertyId: string,
  typeRequest: any,
  state: any
}>());

export const loadinvitationEmailSuccess = createAction('[Invitation Email] loadSuccess', props<{ data: InvitationEmailSettings }>());
export const loadinvitationEmailFail = createAction('[Invitation Email] loadFail', props<{ error: any }>());

// save changes toogle
export const commitInvitationEmail = createAction('[Invitation Email] commit',
props<{
  componentGroupUuid: string,
  propertyId: string,
  isActive: boolean
}>());
export const commitInvitationEmailSuccess = createAction('[Invitation Email] commitSuccess', props<{ data: InvitationEmailSettings }>());
export const commitInvitationEmailFail = createAction('[Invitation Email] commitFail', props<{ error: any }>());

//styling
export const loadStylingInvitationEmail = createAction('[Invitation Email] load styling',
props<{
  componentGroupUuid: string,
  propertyId: string,
  typeRequest: any,
  state: any
}>());

export const loadStylingInvitationEmailSuccess = createAction('[Invitation Email] styling loadSuccess', props<{ data: InvitationEmailStylingSettings }>());
export const loadStylingInvitationEmailFail = createAction('[Invitation Email] styling loadFail', props<{ error: any }>());
