import { Experiment, ExperimentResponse } from "../models/experiment.model";
import dayjs from "dayjs";

interface ExperimentFactoryOptions {
    nullableEndDate?: boolean;
}

class ExperimentFactory {
    createExperiment(experiment: ExperimentResponse, index = 0, options?: ExperimentFactoryOptions): Experiment {
        const is_active = experiment.start_date && !experiment.end_date;
        const uuid = experiment.uuid;
        const name = index ? `Experiment ${index}` : null;
        const start_date = dayjs(experiment.start_date).format('YYYY-MM-DD');
        const end_date = experiment.end_date
            ? dayjs(experiment.end_date).format('YYYY-MM-DD')
            : options?.nullableEndDate
                ? null
                : dayjs().format('YYYY-MM-DD');
        const percentage = [Math.round(experiment.percentage), 100 - Math.round(experiment.percentage)];
        const duration = dayjs(experiment.end_date).diff(experiment.start_date, 'days');
        return {
            uuid,
            name,
            start_date,
            end_date,
            percentage,
            duration,
            is_active
        };
    }

    createExperiments(experiments: ExperimentResponse[], options?: ExperimentFactoryOptions): Experiment[] {
        return experiments.map((experiment, index) => {
            return this.createExperiment(experiment, index + 1, options);
        });
    }

    groupExperiments(experiments: ExperimentResponse[], options?: ExperimentFactoryOptions): Record<string, Experiment[] > {
        return experiments.reduce((acc, exp) => {
            acc[exp.component_group_uuid] = [
                ...(acc[exp.component_group_uuid] || []),
                this.createExperiment(exp, (acc[exp.component_group_uuid]?.length || 0) + 1, options),
            ]
            return acc;
        }, {});
    }
}

export const experimentsFactory = new ExperimentFactory();
