
/**
 * Created by roman.kupin on 9/30/2017.
 */

export function v1ApiUrlInterceptor(environment) {
    return {
        request: function (config) {
            if (/^\/api\/(?!v2).*/gi.test(config.url)) {
                config.url = `${environment.apiV1BaseUrl.replace(/\/$/, "")}${config.url}`;
            } else if (/\/api\/v2.*/gi.test(config.url) && !/http(s?):\/\/.*\/api\/v2/gi.test(config.url)) {
                config.url = `${environment.apiBaseUrl.replace(/\/$/, "")}${config.url.replace(/([\/]?)api\/v2/gi, "")}`;
            }
            return config;
        }
    };
}

export namespace v1ApiUrlInterceptor {
    export let $inject = ["environment"];
}
