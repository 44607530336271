import {
    commitFriendLanding,
    commitFriendLandingSuccess,
    loadFriendLanding,
    loadFriendLandingFail,
    loadFriendLandingSuccess,
    loadIntegrationFriendLanding,
    loadIntegrationFriendLandingFail,
    loadIntegrationFriendLandingSuccess,
    loadStylingFriendLanding,
    loadStylingFriendLandingFail,
    loadStylingFriendLandingSuccess
} from './actions';
import { Action, createReducer, on } from "@ngrx/store";

import { State } from "./state";

export const reducer = createReducer(
    {
        loading: false,
        settings: null,
        commitSuccess: false,
        stylingSettings: null,
        integrationSettings: null
    } as State,
    // messaging
    on(loadFriendLanding, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(loadFriendLandingSuccess, (state, {data}) => {
        return {
            ...state,
            loading: false,
            settings: data
        };
    }),
    on(loadFriendLandingFail, (state, {error}) => {
        return {
            ...state,
            loading: false,
            error
        };
    }),
    // styling
    on(loadStylingFriendLanding, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(loadStylingFriendLandingSuccess, (state, {data}) => {
        return {
            ...state,
            loading: false,
            stylingSettings: data
        };
    }),
    on(loadStylingFriendLandingFail, (state, {error}) => {
        return {
            ...state,
            loading: false,
            error
        };
    }),
    // integration
    on(loadIntegrationFriendLanding, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(loadIntegrationFriendLandingSuccess, (state, {data}) => {
        return {
            ...state,
            loading: false,
            integrationSettings: data
        };
    }),
    on(loadIntegrationFriendLandingFail, (state, {error}) => {
        return {
            ...state,
            loading: false,
            error
        };
    }),
    on(commitFriendLanding, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(commitFriendLandingSuccess, (state, {data}) => {
        return {
            ...state,
            loading: false,
            settings: data
        };
    }),
    // on(changeConfirmationExperience, state => {
    //     return {
    //       ...state,
    //       loading: true
    //     };
    //   }),
    // on(changeConfirmationExperienceSuccess, (state, {changes}) => {
    //     return {
    //         ...state,
    //         loading: false,
    //         commitSuccess: true,
    //         settings: {
    //             ...state.settings,
    //             languages: {
    //                 item: [{
    //                     ...state.settings.languages.item[0],
    //                     data: {
    //                         ...state.settings.languages.item[0].data,
    //                         ...changes[0]
    //                     }
    //                 }]
    //             }
    //         }
    //     };
    // }),
    // on(changeConfirmationExperienceFail, (state, { error }) => {
    // return {
    //     ...state,
    //     loading: false,
    //     error
    // };
    // }),
)

export function friendLandingReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}
