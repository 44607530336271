/**
 * Created by Roman on 2015-03-13.
 */



angular.module('flipto.components.common.modelFormatters',
    [
        'flipto.core.lodash'
    ]);
