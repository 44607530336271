import { Component } from '@angular/core';
import { IFilterAngularComp } from 'ag-grid-angular';
import { IDoesFilterPassParams, IFilterParams, RowNode } from 'ag-grid-community';

@Component({
    selector: 'ft-grid-search-filter',
    templateUrl: './grid-search-filter.component.html',
    styleUrls: ['./grid-search-filter.component.scss']
})
export class GridSearchFilterComponent implements IFilterAngularComp {
    private params: IFilterParams;
    private valueGetter: (rowNode: RowNode) => any;
    public compareParam;
    text: any;

    agInit(params: IFilterParams): void {
        this.params = params;
        this.valueGetter = params.valueGetter;
        this.compareParam = params.context?.compareParam;
    }

    isFilterActive(): boolean {
        return !!this.text;
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean {
        if (!this.text) return true;
        return (!!this.compareParam ? this.valueGetter(params.node)[this.compareParam] : this.valueGetter(params.node)).toLowerCase().includes(this.text.toLowerCase())
    }

    getModel(): any {
        return { value: this.text };
    }

    setModel(model: any): void {
        this.text = model ? model.value : '';
    }

    onSearch(newValue): void {
        if (this.text !== newValue) {
            this.text = newValue;
            this.params.filterChangedCallback();
        }
    }
}
