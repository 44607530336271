import { Injectable } from '@angular/core';

@Injectable()
export class DateSerializer {
    static serialize(date: Date | string) {
        let tmpDate = date;
        if (!tmpDate) return null;
        if (typeof tmpDate === "string") tmpDate = new Date(tmpDate);
        return tmpDate.getFullYear() + '-' + (tmpDate.getMonth() + 1) + '-' + tmpDate.getDate();
    }
}