/**
 * Created by ostapenko.r on 12/12/2014.
 */
angular.module('flipto.core.api.platform', []).config([
	'$provide',
	function($provide) {
		$provide.factory('Platform', [
			'$http',
			'urlFormatter',
			function($http, urlFormatter) {
				/**
				 * Platform service
				 * @constructor
				 */
				function Platform() {
					/**
					 * Returns platform info for brand
					 * @returns {*}
					 */
					this.brand = function(model) {
						return $http
							.get(urlFormatter.format('/api/brands/:id/platform', model))
							.then(function(response) {
								return response.data === 'null' ? [] : response.data;
							});
					};

					/**
					 * Returns platform info for property
					 * @returns {*}
					 */
					this.property = function(model) {
						return $http
							.get(urlFormatter.format('/api/:id/properties/:id/platform', model))
							.then(function(response) {
								return response.data;
							});
					};
				}

				return new Platform();
			}
		]);
	}
]);
