/**
 * Created by roman.kupin on 10/3/2017.
 */



declare let angular;

export const NAME = "ftInitializedContent";

export const DEFINITION = [function () {
    return {
        scope: {
            "isInitialized": "="
        },
        transclude: true,
        template:
            `
            <div ft-loading-animation="{trigger: !isInitialized, message: '{{'flipto.account.Loading' | translate}}', display: 'block'}"></div>
            <ng-transclude ng-show="isInitialized"></ng-transclude>
        `,
        link: function (scope, elem, attrs) {
            if (angular.isDefined(attrs.hideLoading) && !!scope.$eval(attrs.hideLoading)) {
                let loadingAnimationContainer = elem.find("div")[0];
                loadingAnimationContainer.remove();
                loadingAnimationContainer = null;
            }
        }
    };
}];
