import { createAction, props } from '@ngrx/store';
import { ComponentGroup } from "../models/component-group.model";

export const loadComponentsGroups = createAction('[Components Groups] Load Groups');

export const loadComponentGroupsFailure = createAction(
    '[Components Groups] Load Groups Failure',
    props<{ error: any }>()
);

export const loadComponentGroupsSuccess = createAction(
    '[Components Groups]  Load Groups Success',
    props<{ componentGroups: ComponentGroup[] }>()
);

export const selectComponentGroup = createAction(
    '[Components Groups]  Select Group',
    props<{ selectedComponentGroup: ComponentGroup }>()
);

export const createComponentGroup = createAction(
    '[Components Groups]  Create Group',
    props<{ name: string, componentGroupType: number }>()
);

export const createComponentGroupSuccess = createAction(
    '[Components Groups]  Create Group Success',
    props<{ componentGroup: ComponentGroup }>()
);

export const createComponentGroupFailure = createAction(
    '[Components Groups]  Create Group Failure',
    props<{ error: any }>()
);
