/**
 * Created by Roman on 2014-09-21.
 */


angular.module('flipto.core.changesTracker', ['flipto.core.DeepDiff', 'flipto.core.lodash'])
    .config(['$provide', function ($provide) {


        $provide.factory('changesTracker', ['DeepDiff', '_', '$rootScope', function (DeepDiff, _, $rootScope) {

            /**
             * Keeps track of object's properties
             * @constructor
             */
            function ChangesTracker() {

                /**
                 * Tracker id
                 * @type {*|Number}
                 */
                this.id = parseInt(Math.random() * 1000);

                /**
                 * Initial values
                 * @type {Object}
                 */
                this.snapshot = null;
                /**
                 * Changes array
                 * @type {Array.<Object>}
                 */
                this.pendingChanges = [];

                /**
                 * Object that contains original data
                 * @type {Object|null}
                 */
                this.scope = null;

                /**
                 * Array of stop watch functions
                 * @type {Array.<Function>}
                 */
                this.stopWatches = [];

                /**
                 * Default depth for snapshot
                 * @type {number}
                 */
                this.snapshotMaxDepth = 0;
            }

            /**
             * Setup tracking
             * @param scope
             * @param csvKeys
             */
            ChangesTracker.prototype.track = function (scope, csvKeys) {
                this.scope = scope;
                this.snapshot = null;
                this.pendingChanges = [];
                this.stopWatches = [];

                _.forEach(csvKeys.split(','), angular.bind(this, function (key) {
                    key = key.trim();
                    this.snapshot = this.createSnapshot(this.scope, key);

                    var self = this;
                    /**
                     * Setup $watch on property and store initial value only
                     */
                    this.stopWatches.push(this.scope.$watch(key, function (value) {
                        if (!angular.isDefined(value)) return;
                        if (!angular.isDefined(self.getProperty(self.snapshot, key))) {
                            self.snapshot = self.createSnapshot(self.scope, key);
                        } else {

                            var pendingChanges = self.pendingChanges,
                                diffs = self.getChanges(key, self.snapshot, self.scope);

                            /*remove pending changes related to key*/
                            _.remove(pendingChanges, function (change) {
                                return !change.path ||
                                    change.path.join('.').indexOf(key) != -1;
                            });

                            /*merge diffs & pending changes*/
                            var changes = pendingChanges.concat(diffs);
                            pendingChanges.splice(0, pendingChanges.length);
                            pendingChanges.push.apply(pendingChanges, changes);
                        }
                    }, true));
                }));
            };

            /**
             * Stop tracking
             */
            ChangesTracker.prototype.stopTracking = function () {
                $rootScope.$broadcast('flipto.changesTracker.stop', { tracker: this });
                angular.forEach(this.stopWatches, function (stopWatch) {
                    stopWatch();
                });
                this.scope = null;
                this.snapshot = null;
                this.stopWatches = [];
                this.pendingChanges.splice(0, this.pendingChanges.length);
            };

            /**
             * Rollback all changes
             * @param rolledbackChanges
             */
            ChangesTracker.prototype.rollback = function (rolledbackChanges) {
                var changes = rolledbackChanges || angular.copy(this.pendingChanges);

                _.forEach(changes.reverse(), angular.bind(this, function (change) {
                    var target = this.scope, source = this.snapshot;
                    var changeExists = angular.isDefined(_.find(this.pendingChanges, function (pendingChange) {
                        return angular.equals(change, pendingChange);
                    }));
                    if (changeExists) {
                        DeepDiff.revertChange(target, source, change);
                    }
                }));

                _.remove(this.pendingChanges, angular.bind(this, function (pendingChange) {
                    return angular.isDefined(_.find(changes, function (change) {
                        return angular.equals(pendingChange, change);
                    }))
                }));

                if (angular.isDefined(rolledbackChanges) === false) {
                    $rootScope.$broadcast('flipto.changesTracker.rollback', {
                        tracker: this,
                        changes: changes
                    });
                }
            };

            /**
             * Commit changes
             * @param committedChanges
             */
            ChangesTracker.prototype.commit = function (committedChanges) {
                var changes = committedChanges || angular.copy(this.pendingChanges);

                _.forEach(changes, angular.bind(this, function (change) {
                    var target = this.snapshot, source = this.scope;
                    var changeExists = angular.isDefined(_.find(this.pendingChanges, function (pendingChange) {
                        return angular.equals(change, pendingChange);
                    }));
                    if (changeExists) {
                        DeepDiff.applyChange(target, source, change);
                    }
                }));

                _.remove(this.pendingChanges, angular.bind(this, function (pendingChange) {
                    return angular.isDefined(_.find(changes, function (change) {
                        return angular.equals(pendingChange, change);
                    }))
                }));

                if (angular.isDefined(committedChanges) === false) {
                    $rootScope.$broadcast('flipto.changesTracker.commit', {
                        tracker: this,
                        changes: changes
                    });
                }
            };

            /**
             * Find changes. Since DeepDiff does not support lookup on prototypes -
             * this function calls itself recursively passing prototypes if any.
             * Output is an array with differences in DeepDiff format.
             * @param searchPath
             * @param origin
             * @param source
             * @param changes=
             * @returns {Array}
             */
            ChangesTracker.prototype.getChanges = function (searchPath, origin, source, changes) {
                changes = changes || [];
                var diffs = _.filter(DeepDiff.diff(origin, source, function (path, key) {
                    /*no angular properties & constructor*/
                    return key.toString().indexOf('$') === 0 || key.toString() == 'constructor';
                }), function (change) {
                    /*filter by searchPath if applies*/
                    return !change.path || change.path.join('.').indexOf(searchPath) != -1;
                });
                !!diffs && changes.push.apply(changes, diffs);
                if (!!Object.getPrototypeOf(origin) && !!Object.getPrototypeOf(source)) {
                    return this.getChanges(searchPath, Object.getPrototypeOf(origin), Object.getPrototypeOf(source), changes);
                }
                return changes;
            };

            /**
             * Create snapshot.
             * Uses __proto__ to set prototype, which has poor support in browsers.
             * Object.setPrototypeOf is not supported atm
             * @param source
             * @param key
             * @returns {*}
             */
            ChangesTracker.prototype.createSnapshot = function (source, key) {
                var chain = [],
                    terminateKey = key.split('.')[0],
                    depth = 0;

                while (angular.isDefined(source) && source != null) {
                    var proto = Object.getPrototypeOf(Object.create(source));
                    var clearInstance = this.clearBeforeTracking(_.assign({}, proto));
                    chain.push(angular.copy(clearInstance));

                    if (source.hasOwnProperty(terminateKey)) {
                        if (this.snapshotMaxDepth < depth) {
                            this.snapshotMaxDepth = depth;
                        }
                    }
                    if ((this.snapshotMaxDepth == 0 ? 4 : this.snapshotMaxDepth) <= depth++) break;
                    source = Object.getPrototypeOf(source);
                }

                for (var i = 0; i < chain.length - 1; i++) {
                    chain[i].__proto__ = chain[i + 1];
                }

                return chain[0];
            };

            /**
             * Remove unnecessary properties, including those on prototype
             * @param source
             * @returns {*}
             */
            ChangesTracker.prototype.clearBeforeTracking = function (source) {
                for (var key in source) {
                    if (key.indexOf('$') !== -1 ||
                        angular.isFunction(source[key])) {
                        delete source[key];
                    }
                    // remove forms
                    if (typeof source[key] === "object" && source[key].$name) {
                        delete source[key];
                    }
                }
                return source;
            };

            /**
             * Returns property by key
             * @param object
             * @param key
             * @returns {*}
             */
            ChangesTracker.prototype.getProperty = function (object, key) {
                if (angular.isDefined(object) === false) return undefined;
                var index;
                if ((index = key.indexOf('.')) !== -1) {
                    var objectProperty = key.slice(0, index);
                    return this.getProperty(object[objectProperty], key.substring(index + 1));
                } else {
                    return object[key];
                }
            };

            /**
             * Changes tracker factory
             * @constructor
             */
            function ChangesTrackersFactory() {
                this.trackers = [];
            }

            /**
             * Create instance and start tracking
             * @param scope
             * @param csvKeys
             * @returns {ChangesTracker}
             */
            ChangesTrackersFactory.prototype.create = function (scope, csvKeys) {

                if (angular.isObject(scope) === false || angular.isFunction(scope.$watch) === false)
                    throw new Error('scope is not an object');
                if (angular.isString(csvKeys) === false || csvKeys.length === 0)
                    throw new Error('csvKeys is not defined');

                var tracker = new ChangesTracker();
                this.trackers.push(tracker);

                var self = this;
                scope.$on('flipto.changesTracker.rollback', function (event, args) {
                    _.forEach(self.trackers, function (tracker) {
                        tracker.rollback(args.changes);
                    })
                });

                scope.$on('flipto.changesTracker.commit', function (event, args) {
                    _.forEach(self.trackers, function (tracker) {
                        tracker.commit(args.changes);
                    })
                });

                scope.$on('flipto.changesTracker.stop', function (event, tracker) {
                    _.remove(self.trackers, tracker);
                });

                tracker.track(scope, csvKeys);

                return tracker;
            };

            return new ChangesTrackersFactory();
        }]);


    }]);