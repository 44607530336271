<div class="ft-custom-links-section">
  <div class="ft-section-info ft-tooltip opens-center">
    <p class="ft-heading ft-emphasis">Setting up your own outreach for email, print, or other channel?</p>
    <p>
      Custom broadcast links are made specifically for use in your outreach.
      <button
      class="ft-link ft-link-underline"
      (click)="newShortUrlModal.show()"
      >
        Create a link
      </button>&nbsp;<i class="ft-icn-info ft-tooltip-toggle" (click)="tooltip1.show()"></i>
    </p>
    <ft-tooltip #tooltip1>
      <ng-template>
        Broadcast links are dynamic&mdash;they'll always route to the current, active campaign for your target audience so you'll never send people to outdated experiences.
      </ng-template>
    </ft-tooltip>
  </div>

  <div class="ft-custom-links-list scroll-block">

      <div class="ft-custom-link ft-tooltip opens-right" *ngFor="let link of links$ | async">
        <div class="ft-row">
          <div class="ft-col-50">
            <div class="ft-custom-link-name">{{ link.displayName }}</div>
          </div>
          <div class="ft-col-50">
            <div class="ft-custom-link-URL url-text">
              https://go.flip.to/{{ link.shortCode }} <i class="ft-icn-info ft-tooltip-toggle" (click)="tooltip2.show()"></i>
            </div>
          </div>
        </div>
        <ft-tooltip #tooltip2>
            <ng-template>
              This link sends visitors to: <span class="url-text">{{ link.destinationUrl }}</span>
            </ng-template>
        </ft-tooltip>
      </div>

  </div>
</div>

<ft-modal #newShortUrlModal id="photoContest.newShortUrl" [doneVisible]="false">
  <ng-template>
    <short-url-wizard [params]="setupOptions" (done)="onShortUrlWizardComplete($event)"></short-url-wizard>
  </ng-template>
</ft-modal>
