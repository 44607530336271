import { Observable } from 'rxjs';
import { commitFollowupEmail, loadFollowupEmail, loadStylingFollowupEmail, saveFollowupEmail } from './../../store/followup-email/actions';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Transition } from '@uirouter/core';
import { JourneysApiService } from '../../services/journeys-api.service';
import * as fromTouchpoints from '../../store';
import { FollowupEmailSettings, FollowupEmailStylingSettings } from '../../models/followup-email';

@Component({
  selector: 'ft-followup-email',
  templateUrl: './followup-email.component.html',
  styleUrls: ['./followup-email.component.scss']
})
export class FollowupEmailComponent implements OnInit {
  // touchpoints params
  propertyId:string;
  componentGroupUuid: string;
  options: {
    touchpointTitle: string,
    type: string,
    state: string
  };

  selectedIndex = 0;
  commitSuccess$: Observable<any>;
  selectedFollowupEmailComponent$: Observable<FollowupEmailSettings>;
  selectedFollowupEmailLoading$: Observable<boolean>;
  stylingFollowupEmailComponent$: Observable<FollowupEmailStylingSettings>;

  constructor(
    private store$: Store<fromTouchpoints.State>,
    private transition: Transition
  ) { }

  ngOnInit() {
    this.propertyId = this.transition.params().propertyId;
    this.componentGroupUuid = this.transition.params().componentGroupUuid;
    this.options = this.transition.options().custom;

    this.store$.dispatch(loadFollowupEmail({
      componentGroupUuid: this.componentGroupUuid,
      propertyId: this.propertyId,
      typeRequest: this.options.type,
      state: this.options.state
    }));
    this.store$.dispatch(loadStylingFollowupEmail({
      componentGroupUuid: this.componentGroupUuid,
      propertyId: this.propertyId,
      typeRequest: this.options.type,
      state: this.options.state
    }));

    this.commitSuccess$ = this.store$.pipe(select(fromTouchpoints.getFollowupEmailCommitSuccess));
    this.selectedFollowupEmailComponent$ = this.store$.pipe(select(fromTouchpoints.getFollowupEmailData));
    this.selectedFollowupEmailLoading$ = this.store$.pipe(select(fromTouchpoints.getFollowupEmailLoading));
    this.stylingFollowupEmailComponent$ = this.store$.pipe(select(fromTouchpoints.getStylingFollowupEmailData));
  }

  commit(isActive) {
    this.store$.dispatch(commitFollowupEmail({
        componentGroupUuid: this.componentGroupUuid,
        propertyId: this.propertyId,
        isActive: isActive
    }));
  }

  onFormCommit(params, tab) {
    const changes: any = [{
      languageCode: 'en',
      ...params,
    }];
    this.store$.dispatch(saveFollowupEmail({
      componentGroupUuid: this.componentGroupUuid,
      propertyId: this.propertyId,
      typeRequest: this.options.type,
      changes: params,
      tab: tab
    }));
  }

  isRemider() {
    return this.options.type === 'reminder-emails';
  }

  selectedTemplate(data) {
    return data.templates ? data.templates.item.find(el => el.id === data.templateUUID)?.name : data.nonViewEmailTemplates.item.find(el => el.isDefaultTemplate === '1')?.name;
  }
}
