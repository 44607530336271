<div [hidden]="hidden" class="ft-daterange-picker-container" [class.-inline]="inlineMode" [class.-full-width]="fullWidth">
    <ng-container *ngIf="fromDate || toDate; else placeholder">
        <ng-container *ngIf="valueTmpl">
            <ng-container *ngTemplateOutlet="valueTmpl"></ng-container>
        </ng-container>
        <div *ngIf="!valueTmpl" class="text-center">
            <div
                [class.-dark]="darkMode"
                [class.-inline]="inlineMode"
                class="ft-daterange-picker-content"
                [ngClass]="{'-season huge-text': hasSeasonRange}">
                <span *ngIf="durationVisible">
                    {{durationLabel}}
                </span>
                <hr *ngIf="durationVisible && datesVisible">
                <span class="muted-text" *ngIf="datesVisible">
                    {{datesLabel}}
                </span>
            </div>
        </div>
    </ng-container>
    <ng-template #placeholder>
        <div *ngIf="!placeholderTmpl" [class.-dark]="darkMode" [class.-inline]="inlineMode"
             class="ft-daterange-picker-content">
            <p>Select date range</p>
        </div>
        <ng-container *ngIf="placeholderTmpl">
            <ng-container *ngTemplateOutlet="placeholderTmpl"></ng-container>
        </ng-container>
    </ng-template>
    <div class="ft-daterange-picker"
         [class.-dark]="darkMode"
         [ngClass]="['-' +verticalAppearance, '-' + horizontalAppearance]"
         *ngIf="isRangePickerVisible"
         [@toggleRangePicker]="isRangePickerVisible"
         [exclude]="excludeClickOutside"
         [excludeBeforeClick]="!!excludeClickOutside"
         (clickOutside)="onCancel()">
         <ng-container *ngIf="!hasSeasonRange">
            <div class="ft-daterange-picker_inner">
                <div class="ft-daterange-picker_controls">
                    <ul class="ft-daterange-selector">
                        <li>
                            <input id="7-days-range" [(ngModel)]="pastDays" (ngModelChange)="changePastDays()" type="radio" [value]="7">
                            <label for="7-days-range">Past 7 days</label>
                        </li>
                        <li>
                            <input id="30-days-range" [(ngModel)]="pastDays" (ngModelChange)="changePastDays()" type="radio"
                                   [value]="30">
                            <label for="30-days-range">Past 30 days</label>
                        </li>
                        <li>
                            <input id="90-days-range" [(ngModel)]="pastDays" (ngModelChange)="changePastDays()" type="radio"
                                   [value]="90">
                            <label for="90-days-range">Past 90 days</label>
                        </li>
                    </ul>
                    <div class="p-2">
                        <label (click)="openCalendar(); navigateToEndDate();" class="ft-daterange-picker_label">
                            Custom range
                        </label>
                        <div class="ft-daterange-picker_input">
                            <div *ngIf="fromInputError" class="ft-daterange-picker_tooltip" (clickOutside)="fromInputError = null">{{fromInputError}}</div>
                            <label for="dateFromInput">From</label>
                            <input #dateFromInput
                                   type="date"
                                   id="dateFromInput"
                                   class="ft2-input -small"
                                   [class.-dark]="darkMode"
                                   (click)="openCalendar(); navigateToStartDate();"
                                   [value]="ngbFromDate | ngbDate:dateFormat"
                                   (keyup.space)="setInputFromDate(dateFromInput.value)"
                                   (keyup.enter)="setInputFromDate(dateFromInput.value)"
                                   (blur)="setInputFromDate(dateFromInput.value)">
                        </div>
                        <div class="ft-daterange-picker_input">
                            <div *ngIf="toInputError" class="ft-daterange-picker_tooltip" (clickOutside)="toInputError = null">{{toInputError}}</div>
                            <label for="dateToInput">To</label>
                            <input #dateToInput
                                   type="date"
                                   id="dateToInput"
                                   class="ft2-input -small"
                                   [class.-dark]="darkMode"
                                   (click)="openCalendar(); navigateToEndDate();"
                                   [value]="ngbToDate | ngbDate:dateFormat"
                                   (keyup.space)="setInputToDate(dateToInput.value)"
                                   (keyup.enter)="setInputToDate(dateToInput.value)"
                                   (blur)="setInputToDate(dateToInput.value)">
                        </div>
                        <div class="mt-2" *ngIf="isCalendarVisible">
                            <button (click)="onRangeSelected(); $event.stopImmediatePropagation()" [disabled]="!ngbToDate || !ngbFromDate" class="ft2-btn-primary mr-1">
                                Apply
                            </button>
                            <button (click)="onCancel(); $event.stopImmediatePropagation()" class="ft2-btn-shade" [class.-dark]="darkMode">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
                <div class="ft-daterange-picker-calendar" [class.-dark]="darkMode" [hidden]="!isCalendarVisible" [@toggleCalendar]="isCalendarVisible">
                    <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
                                    [navigation]="'arrows'" outsideDays="hidden" [firstDayOfWeek]="7">
                        <ng-template #t let-date let-focused="focused">
                            <div class="custom-day"
                                 [ngbTooltip]="isDisabled(date) ? dateTooltip : ''"
                                 [triggers]="'hover'"
                                 [placement]="['bottom', 'auto']"
                                 [tooltipClass]="'tooltip-small'"
                                 [openDelay]=200
                                 [class.disabled]="isDisabled(date)"
                                 [class.focused]="focused"
                                 [class.range]="isRange(date)"
                                 [class.faded]="isHovered(date) || isInside(date)"
                                 (mouseenter)="onHover(date)"
                                 (mouseleave)="hoveredDate = null">
                                {{ date.day }}
                            </div>
                            <ng-template #dateTooltip>
                                <div class="mb-1" *ngIf="isLimitedByRange(date)">
                                    {{validationErrors.range}}
                                </div>
                                <div class="mb-1" *ngIf="isLimitedByPast(date)">
                                    {{validationErrors.past}}
                                </div>
                                <div class="mb-1" *ngIf="isLimitedByFuture(date)">
                                    {{validationErrors.future}}
                                </div>
                                <div class="mb-1" *ngIf="isLimitedByAfterDate(date)">
                                    {{validationErrors.afterDate}}
                                </div>
                            </ng-template>
                        </ng-template>
                    </ngb-datepicker>
                </div>
            </div>
         </ng-container>
         <ng-container *ngIf="hasSeasonRange">
            <div class="ft-daterange-picker_inner">
                <div class="ft-daterange-picker_controls">
                    <ul class="ft-daterange-selector">
                        <li>
                            <input
                                id="30-days-range"
                                [(ngModel)]="seasonDateType"
                                (ngModelChange)="changeSeasonDaysRange()"
                                type="radio"
                                [value]="1">
                            <label for="30-days-range">Past 30 days</label>
                        </li>
                        <li>
                            <input
                                id="current-season"
                                [(ngModel)]="seasonDateType"
                                (ngModelChange)="changeSeasonDaysRange(dayjs())"
                                type="radio"
                                [value]="2">
                            <label for="current-season">
                                {{dayjs() | seasonRange}}
                            </label>
                        </li>
                        <li>
                            <input
                                id="past-season"
                                [(ngModel)]="seasonDateType"
                                (ngModelChange)="changeSeasonDaysRange(dayjs().subtract(3, 'month'))"
                                type="radio"
                                [value]="3">
                            <label for="past-season">{{dayjs().subtract(3, 'month') | seasonRange}}</label>
                        </li>
                        <li>
                            <input
                                id="current-season-last-year"
                                [(ngModel)]="seasonDateType"
                                (ngModelChange)="changeSeasonDaysRange(dayjs().subtract(1, 'year'))"
                                type="radio"
                                [value]="4">
                            <label for="current-season-last-year">
                                {{dayjs().subtract(1, 'year') | seasonRange}}
                            </label>
                        </li>
                        <li>
                            <input
                                id="current-year"
                                [(ngModel)]="seasonDateType"
                                (ngModelChange)="changeSeasonDaysRange()"
                                type="radio"
                                [value]="5">
                            <label for="current-year">{{currentYear}}</label>
                        </li>
                        <li>
                            <input
                                id="past-year"
                                [(ngModel)]="seasonDateType"
                                (ngModelChange)="changeSeasonDaysRange()"
                                type="radio"
                                [value]="6">
                            <label for="past-year">{{currentYear - 1}}</label>
                        </li>
                    </ul>
                </div>
            </div>
         </ng-container>
    </div>
</div>
