/**
 * Created by roman.kupin on 12/4/2017.
 */

import Parse from "../../utils/parse";

/**
 * Initial letters filter.
 * @param words
 * @returns {any[]}
 */
export function initialLetters(words) {
    return sanitizeInput(words).map(w => w.substring(0, 1));
}

export function joinWith(separator, ...words) {
    return sanitizeInput(words).join(separator);
}

/**
 * Sanitize input for filters
 * @param input
 * @returns {T[]}
 */
function sanitizeInput(input) {
    return Parse.arrayOrDefault(input).filter(w => !!w)
}


export const NAME = "initialLetters";
export const FACTORY = [() => {
    return initialLetters;
}];



