<ft-modal-editor-wrapper>
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
<!--    <div class="ft2-editor-panel">-->
<!--    </div>-->
    <div class="ft2-color-picker" (click)="showPicker()" (clickOutside)="hidePicker()">
        <div class="ft2-color-picker_palette" [style.background-color]="value">
        </div>
        <input type="text" [(ngModel)]="value" class="ft2-color-picker_input ft2-input">
        {{value}}
        <color-chrome *ngIf="pickerVisible"
                      class="ft2-color-picker_options"
                      [disableAlpha]="true"
                      [color]="value"
                      (onChange)="onChangeComplete($event)">
        </color-chrome>
    </div>
</ft-modal-editor-wrapper>
