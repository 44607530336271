<ft-modal-wrapper>
  <ft-touchpoint-header
    [name]="isReminder ? 'Certificate Reminder Email' : 'Certificate Email'"
    [activeToggleEnabled]="false"
    >
  </ft-touchpoint-header>
  <ft-snapshot-form
    *ngIf="certificateEmailComponent$ | async | clone as data"
    [loading$]="[selectedcertificateEmailLoading$]"
    [commitSuccess$]="[commitSuccess$]"
    (commit)="onFormCommit($event, getActiveEmail(data))">
    <section class="ft2-container mt-9">
      <p *ngIf="!isReminder">
        The Certificate Email will be sent to travelers who complete an action and earn a bonus. 
        The email is personalized for different kinds of travelers based on how they've been introduced to your brand.
      </p>
  
      <p *ngIf="isReminder">
        The Certificate Reminder will be sent to travelers who have not used their certificate. 
        The email is personalized for different kinds of travelers based on how they've been introduced to your brand.
      </p>
      <ft-carousel
        [items]="items"
      ></ft-carousel>
  
      <div class="ft2-preview">
        <div class="preview-chrome">
          <span>Inbox</span>
        </div>
        <div class="preview-content">
          <form ngForm>
            <span class="muted-text">Subject: </span>
            <ft-input-editor
              title="Subject"
              [(ngModel)]="getActiveEmail(data).languages[0].subject"
              required
              name="subject">
              <span [innerHTML]="getActiveEmail(data).languages[0].subject"></span>
            </ft-input-editor>
            <hr>
            <ft-html-editor
              title="Body"
              [tokens]="tokensPerType[getActiveEmail(data).emailType]"
              name="body"
              [(ngModel)]="getActiveEmail(data).languages[0].body">
              <div [innerHTML]="getActiveEmail(data).languages[0].body"></div>
            </ft-html-editor>
            <span class="muted-text">
              {{'{sender-firstname}'}} from {{'{property}'}}
            </span>
            <div class="ft2-edit-panel text-center align-center f-column -static">
              <span>{{'{incentive-name}'}}</span>
              <button class="ft2-btn mt-4">
                View your certificate
              </button>
            </div>
            <div class="text-center">{{'{certificate-expiration}'}}</div>
          </form>
        </div>
        <div class="preview-options">
          <a class="launch-preview">
            Email sample
          </a>
          <a class="launch-preview">
            Preview HTML
          </a>
        </div>
      </div>
    </section>
  </ft-snapshot-form>
</ft-modal-wrapper>
