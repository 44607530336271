/**
 * Created by Roman on 2014-11-27.
 */

angular.module('flipto.components.common.images', ['flipto.core.parse']).config([
	'$filterProvider',
	'$compileProvider',
	function($filterProvider, $compileProvider) {
		$filterProvider.register('ftAzureStoredPhoto', [
			'urlFormatter',
			'environment',
			function(urlFormatter, environment) {
				return function(photo, size) {
					if (!photo) return null;
					var photoUUID = photo['@imageUUID'] || photo['imageUUID'] || photo['@photoUUID'] || photo['photoUUID'] || photo['id'] || photo['uuid'] || photo;
					if (!photoUUID) return null;
					var hasTrailingSlash = environment.AzureCdnBaseUrl.lastIndexOf('/') === environment.AzureCdnBaseUrl.length - 1;
					var path = urlFormatter.format('public/:photoId:size.jpg', {
						photoId: photoUUID.toLowerCase(),
						size: !!size ? '-' + size : ''
					});
					return environment.AzureCdnBaseUrl + (hasTrailingSlash ? '' : '/') + path;
				};
			}
		]);
	}
]);
