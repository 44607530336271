import { PropertySettingsState } from './property-settings.state';
import { createReducer, on } from '@ngrx/store';
import {
    loadDiscoveryPropertySettings,
    loadDiscoveryPropertySettingsFailure,
    loadDiscoveryPropertySettingsSuccess,
    saveDiscoveryPropertySettings,
    saveDiscoveryPropertySettingsFailure,
    saveDiscoveryPropertySettingsSuccess
} from './property-settings.actions';

const initialState: PropertySettingsState = {
    settings: {
        styling: {
            plannerFlowCss: null
        },
        emailSchedule: {
            initialDelayInMinutes: null,
            reminderDelayInMinutes: null
        }
    },
    loaded: false,
    loading: false,
    commitSuccess: false,
    error: null
};

export const propertySettingsReducer = createReducer(initialState,
    on(loadDiscoveryPropertySettings, state => ({...state, loaded: false, loading: true, error: null})),
    on(loadDiscoveryPropertySettingsSuccess, (state, action) => ({...state, settings: {...state.settings, ...action?.settings}, loaded: true, loading: false, error: null})),
    on(loadDiscoveryPropertySettingsFailure, (state, action) => ({...state, loaded: false, loading: false, error: action.error, commitSuccess: false})),
    on(saveDiscoveryPropertySettings, state => ({
        ...state,
        loading: true,
        loaded: false,
        commitSuccess: false
    })),
    on(saveDiscoveryPropertySettingsSuccess, (state, action) => ({
        ...state,
        settings: action.settings,
        loading: false,
        loaded: true,
        commitSuccess: true
    })),
    on(saveDiscoveryPropertySettingsFailure, (state, action) => ({...state, loaded: false, loading: false, error: action.error, commitSuccess: false})),
)
