/**
 * Created by roman.kupin on 9/26/2017.
 */

import { REQUIRE_RESULT, CANCEL_RESULT } from "../../../middleware/delayedResult.middleware";

const PREFIX = "[ContextSelector]";
export const ADD_NEW = `${PREFIX}.AddNew`;
export const SHOW = `${PREFIX}.Show`;
export const HIDE = `${PREFIX}.Hide`;
export const DISCARD_CHANGES = `${PREFIX}.DiscardChanges`;
export const SELECT_GROUP = `${PREFIX}.SelectGroup`;
export const CONTEXT_TOGGLE_MANY = `${PREFIX}.ContextToggleMany`;
export const CONTEXT_TOGGLE_SINGLE = `${PREFIX}.ContextToggleSingle`;
export const CONTEXT_SET_SINGLE = `${PREFIX}.ContextSetSingle`;
export const CONTEXT_SELECT_ALL = `${PREFIX}.ContextSelectAll`;
export const CONTEXT_SELECT_NONE = `${PREFIX}.ContextSelectNone`;
export const CONTEXT_GROUP_UPDATE = `${PREFIX}.ContextGroupUpdate`;


export function addNew(contextSelectorSetup) {
    return {
        type: ADD_NEW,
        payload: contextSelectorSetup
    };
}

export function show(contextSelectorId) {
    return (dispatch) => {
        dispatch({
            type: SHOW,
            payload: {contextSelectorId}
        });

        return new Promise((resolve, reject) => {
            dispatch({
                [REQUIRE_RESULT]: {
                    meta: contextSelectorId
                }
            }).then((result) => {
                resolve(result);
            }, (error) => {
                error && reject(error);
            });
        });
    };
}

export function hide(contextSelectorId) {
    return {
        type: HIDE,
        payload: {contextSelectorId}
    };
}

export function discardAndClose(contextSelectorId) {
    return (dispatch) => {
        dispatch({
                type: DISCARD_CHANGES,
                payload: {contextSelectorId}
            }
        );
        return dispatch({
            type: HIDE,
            payload: {contextSelectorId}
        });
    }
}

export function close(contextSelectorId) {
    return (dispatch) => {
        dispatch({
            [CANCEL_RESULT]: {
                meta: contextSelectorId
            }
        });

        return dispatch({
            type: HIDE,
            payload: {contextSelectorId}
        });
    };
}

export function selectGroup(contextSelectorId, groupName) {
    return {
        type: SELECT_GROUP,
        payload: {contextSelectorId, groupName}
    };
}

export function updateContextSelectorGroup(contextSelectorId, groupName, contexts) {
    return {
        type: CONTEXT_GROUP_UPDATE,
        payload: {contextSelectorId, groupName, contexts}
    };
}

/*
export function selectContext(contextSelectorId, groupName, contextId) {
    return {
        type: SELECT_GROUP,
        payload: {contextSelectorId, groupName, contextId}
    };
}*/


const All = {
    addNew,
    show,
    hide,
    close,
    selectGroup,
    discardAndClose,
    updateContextSelectorGroup,
    ADD_NEW,
    SHOW,
    HIDE,
    DISCARD_CHANGES,
    SELECT_GROUP,
    CONTEXT_TOGGLE_MANY,
    CONTEXT_TOGGLE_SINGLE,
    CONTEXT_SET_SINGLE,
    CONTEXT_SELECT_ALL,
    CONTEXT_SELECT_NONE,
    CONTEXT_GROUP_UPDATE
};
export default All;
