/**
 * Created by Roman on 2015-04-28.
 */



angular.module('flipto.components.curation')
    .service('curationModal', ['$rootScope', '$compile', '$filter', 'ftModal', 'PhotoContest', 'moment', 'inherits', 'parse', 'environment', 'IdentityService',
        function ($rootScope, $compile, $filter, ftModal, PhotoContest, moment, inherits, parse, environment, identity) {


            var ftAzureStoredPhoto = $filter('ftAzureStoredPhoto');

            /**
             * Encapsulate curation modal methods
             * @constructor
             */
            function CurationModalService() {
            }

            inherits(EventEmitter, CurationModalService);

            CurationModalService.prototype.instance = null;
            CurationModalService.prototype.curationInProgress = false;


            CurationModalService.prototype.openFromStoryFlow = function (property, guestUUID, onClose) {
                return this.new(property, guestUUID, true, null, null, null, onClose);
            }

            /**
             * Open curation modal for specified photoUUID
             * @param property
             * @param guestUUID
             * @param chunk
             * @param bulkTotal
             * @param loadNextChunkFn
             */
            CurationModalService.prototype.new = function (property, guestUUID, isShowTimeline, chunk, bulkTotal, loadNextChunkFn, onClose) {
                if (this.curationInProgress) {
                    return;
                }
                this.curationInProgress = true;
                this.property = property;

                var isBulkCuration = angular.isArray(chunk) && angular.isFunction(loadNextChunkFn);

                this.loadDetails(property.UUID, guestUUID)
                    .then(angular.bind(this, function () {

                        this.scope.model.isShowTimeline = isShowTimeline && identity.hasPeoplePermissions();
                        if (isBulkCuration) {
                            this.scope.model.chunk = chunk;
                            this.scope.model.bulkTotal = bulkTotal;
                            this.scope.model.loadNextChunkFn = loadNextChunkFn;
                        }
                        var directiveTemplate = '<ft-curation-modal company-code="model.companyCode" property="model.property" guest="model.guest" social-networks-sharing="model.socialNetworksSharing" max-content-size="model.maxContentSize" is-show-timeline="model.isShowTimeline" is-finalist-email-active="model.isFinalistEmailActive"';
                        if (isBulkCuration) {
                            directiveTemplate += 'bulk="model.bulkTotal"';
                        }
                        if(!!onClose) {
                            this.scope.model.onClose = onClose;
                            directiveTemplate += ' on-close="model.onClose()"'
                        }
                        directiveTemplate += '></ft-curation-modal>';

                        $compile(directiveTemplate)(this.scope);
                        var deRegFn = this.scope.$on('modal-curation.create', function () {
                            ftModal.show('curation');
                            deRegFn();
                        });
                        this.scope.$on('modal-curation.cancel', angular.bind(this, function () {
                            this.scope.$destroy();
                            this.scope = null;
                        }));
                        this.scope.$on('$destroy', angular.bind(this, function () {
                            this.curationInProgress = false;
                        }));
                        this.scope.$on('$locationChangeSuccess', angular.bind(this, function () {
                            this.curationInProgress && ftModal.hide('curation')
                                .then(angular.bind(this, function () {
                                    ftModal.destroy('curation');
                                    this.scope.$destroy();
                                    this.scope = null;
                                }));
                        }));

                    }), angular.bind(this, function () {
                        this.curationInProgress = false;
                    }));

                return this;
            };

            CurationModalService.prototype.moveNext = function () {
                if (this.scope.model.bulkTotal === 0) return;
                this.scope.model.bulkTotal -= 1;
                if (this.scope.model.chunk.length === 0) {
                    this.scope.model.loadNextChunkFn()
                        .then(angular.bind(this, function (newChunk) {
                            if (newChunk.length > 0) {
                                this.scope.model.chunk.push.apply(this.scope.model.chunk, newChunk);
                                var nextGuestUUID = this.scope.model.chunk.splice(0, 1)[0];
                                this.loadDetails(this.property.UUID, nextGuestUUID);
                            }
                        }));
                } else {
                    var nextGuestUUID = this.scope.model.chunk.splice(0, 1)[0];
                    this.loadDetails(this.property.UUID, nextGuestUUID);
                }
            };

            CurationModalService.prototype.loadDetails = function (propertyId, guestUUID) {
                return PhotoContest.details(propertyId, guestUUID)
                    .then(angular.bind(this, function (data) {

                        var guest = data.guest,
                            socialNetworksSharing = data.socialNetworksSharing,
                            decisionWasTaken = false;
                        guest.totalRoomNights = moment(guest.endDate).diff(guest.startDate, 'days');

                        if (guest.poststay.photo) {
                            angular.extend(guest.poststay.photo, { degrees: 0 });
                            guest.poststay.photo.photoUrl = ftAzureStoredPhoto(guest.poststay.photo, 'small');
                            if (guest.poststay.photo.photoContest) {
                                guest.poststay.photo.photoContest.ended = moment(guest.poststay.photo.photoContest.endDate).diff(moment().startOf('day'), 'days') <= 0;
                                guest.poststay.photo.photoContest.isClosed = parse.bool(guest.poststay.photo.photoContest.isClosed);
                            }
                            decisionWasTaken = guest.poststay.photo.isPassed || guest.poststay.photo.isFinalist;
                        }

                        if (!this.scope) {
                            this.scope = angular.extend($rootScope.$new(true), { model: {} });
                        }

                        this.scope.model.companyCode = data.companyCode;
                        this.scope.model.property = this.property;
                        this.scope.model.guest = guest;
                        this.scope.model.socialNetworksSharing = socialNetworksSharing;
                        this.scope.model.decisionWasTaken = decisionWasTaken;
                        this.scope.model.maxContentSize = environment.CurationShareContentSize;
                        this.scope.model.isFinalistEmailActive = data.isFinalistEmailActive;

                    }));
            };


            return new CurationModalService();
        }]);
