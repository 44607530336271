export class DiscoveryComponentIntegrationViewModel {
    trafficPercentage: number;
    defaultWebsiteUrl: string;
    bookingEngineTemplateUrl: string; // this one is for global planner flow
    prefix: string;
    code: string;
    signatureUserUuid: string;
    signatureText: string;
    facadeDelayInMs: number;
    isBrowserBackButtonNavActive: boolean;
    languageData: {
        bookingEngineTemplateUrl: string;
        languageCode: string;
    }[];
}
