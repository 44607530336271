/**
 * Created by ostapenko.r on 7/24/2014.
 */
angular
    .module("flipto.components.incentives")
    .filter("incentiveMode",
    [
        "$filter", "_",
        function ($filter, _) {

            var translate = $filter("translate");
            var namesByType = [
                {mode: "0", name: translate("flipto.components.incentives.general")},
                {mode: "1", name: translate("flipto.components.incentives.online")},
                {mode: "2", name: translate("flipto.components.incentives.stash")}
            ];

            function getName(mode) {
                var incentiveInfo = _.find(namesByType, {mode: mode});
                return incentiveInfo && incentiveInfo.name;
            }

            return function (incentive) {
                return getName(incentive);
            };
        }
    ]);