import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import isoweek from 'dayjs/plugin/isoWeek'

export class DateRange {
    toDate: Date;
    fromDate: Date;
    duration: number;

    constructor(fromDate: string | Date | dayjs.Dayjs, toDate: string | Date | dayjs.Dayjs) {
        dayjs.extend(quarterOfYear);
        dayjs.extend(isoweek);

        if (toDate) {
            this.toDate = dayjs(toDate).toDate();
        }
        if (fromDate) {
            this.fromDate = dayjs(fromDate).toDate();
        }
        if (this.fromDate && this.toDate) {
            this.duration = this.calcDuration();
        }
    }

    static forPast90Days(): DateRange {
        return new DateRange(dayjs().subtract(90, 'day').toDate(), dayjs().subtract(1, 'day').toDate());
    }

    static forPast60Days(): DateRange {
        return new DateRange(dayjs().subtract(60, 'day').toDate(), dayjs().subtract(1, 'day').toDate());
    }

    static forPast30Days(): DateRange {
        return new DateRange(dayjs().subtract(30, 'day').toDate(), dayjs().subtract(1, 'day').toDate());
    }

    static forPast7Days(): DateRange {
        return new DateRange(dayjs().subtract(7, 'day').toDate(), dayjs().subtract(1, 'day').toDate());
    }

    static forNext9Weeks(): DateRange {
        return new DateRange(dayjs().startOf('isoWeek').toDate(), dayjs().startOf('isoWeek').add(10, 'weeks').add(-1, 'days').toDate());
    }

    static forNext8Weeks(): DateRange {
        return new DateRange(dayjs().startOf('isoWeek').toDate(), dayjs().startOf('isoWeek').add(9, 'weeks').add(-1, 'days').toDate());
    }

    static forSeason(date): DateRange {
        return new DateRange(date.startOf('quarter').subtract(1, 'month').toDate(), date.endOf('quarter').subtract(1, 'month').toDate());
    }

    static forCurrentYear(): DateRange {
        return new DateRange(dayjs().startOf('year').toDate(), dayjs().endOf('year').toDate());
    }

    static forPastYear(): DateRange {
        return new DateRange(dayjs().subtract(1, 'year').startOf('year').toDate(), dayjs().subtract(1, 'year').endOf('year').toDate());
    }

    static forYearToDate(date: string | Date | dayjs.Dayjs = dayjs().subtract(1, 'day')): DateRange {
        return new DateRange(dayjs().startOf('year').toDate(), dayjs(date).startOf('day').toDate());
    }

    calcDuration(): number {
        return dayjs(this.toDate).diff(this.fromDate, 'days') + 1;
    }

    timezoneOffset() {
        this.fromDate.setHours(0, -this.fromDate.getTimezoneOffset(), 0, 0);
        this.toDate.setHours(0, -this.toDate.getTimezoneOffset(), 0, 0);
    }

    toParams(isTimezoneOffset: boolean = false) {
        if (isTimezoneOffset) {
            this.timezoneOffset();
        }

        return {
            StartDate: this.fromDate.toISOString().substring(0,10),
            EndDate: this.toDate.toISOString().substring(0,10)
        };
    }
}

