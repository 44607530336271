<ft-modal-editor-wrapper>
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
    <div class="ft2-form-group">
        <label>Who is allowed on property?</label>
        <div class="ft2-input -large v-center">
            <label class="ft2-checkbox" style="width:33%;"
                   [ngClass]="{'-disabled': isPropertySpace && option.value === occupancyAllowanceEnum.Adults}"
                   *ngFor="let option of occupancyAllowanceOptions; let i = index;">
                <input type="checkbox"
                       [disabled]="option.enabled"
                       (ngModelChange)="onOccupancyAllowanceChanged($event, option)"
                       [(ngModel)]="option.checked">
                <span class="ft2-checkbox_control"></span>
                <div>
                <span class="bold-text">
                    {{ option.text }}
                </span>
                </div>
            </label>
        </div>
    </div>

    <div class="ft2-form-group"
         *ngIf="value?.hasOwnProperty('maxOccupancyPerRoom')">
        <label>
            Max people per room
        </label>
        <input class="ft2-input -large"
               [(ngModel)]="value.maxOccupancyPerRoom"
               name="maxOccupancyPerRoom"
               placeholder="Enter max people count"
               required
               min="0"
               #controlsRef
               #controlsModelRef="ngModel"
               data-error-name="Max people per room"
               type="number">
    </div>
    <div class="row no-gutters">
        <div class="col-4 no-gutters">
            <div class="ft2-form-group"
                 *ngIf="value?.hasOwnProperty('defaultAdults')">
                <label>
                    <span class="d-sm-none">Default</span> adults
                </label>
                <input class="ft2-input -large -square-right"
                       [(ngModel)]="value.defaultAdults"
                       name="defaultAdults"
                       placeholder="Enter default adults count"
                       required
                       min="1"
                       #controlsRef
                       #controlsModelRef="ngModel"
                       data-error-name="Default adults"
                       type="number">
            </div>
        </div>
        <div class="col-4">
            <div class="ft2-form-group"
                 *ngIf="value?.hasOwnProperty('minAdults')">
                <label>
                    Min
                </label>
                <input class="ft2-input -large -square"
                       [(ngModel)]="value.minAdults"
                       name="minAdults"
                       placeholder="Enter min adults count"
                       required
                       min="1"
                       #controlsRef
                       #controlsModelRef="ngModel"
                       data-error-name="Min adults"
                       type="number">
            </div>
        </div>
        <div class="col-4">
            <div class="ft2-form-group"
                 *ngIf="value?.hasOwnProperty('maxAdults')">
                <label>
                    Max
                </label>
                <input class="ft2-input -large -square-left"
                       [(ngModel)]="value.maxAdults"
                       name="maxAdults"
                       placeholder="Enter max adults count"
                       required
                       min="1"
                       #controlsRef
                       #controlsModelRef="ngModel"
                       data-error-name="Max adults"
                       type="number">
            </div>
        </div>
    </div>

    <ng-container *ngIf="isChildrenAllowed">
        <div class="row no-gutters">
            <div class="col-4">
                <div class="ft2-form-group"
                     *ngIf="value?.hasOwnProperty('defaultChildren')">
                    <label>
                        <span class="d-sm-none">Default</span> kids
                    </label>
                    <input class="ft2-input -large -square-right"
                           [(ngModel)]="value.defaultChildren"
                           name="defaultChildren"
                           placeholder="Enter default kids count"
                           required
                           min="0"
                           #controlsRef
                           #controlsModelRef="ngModel"
                           data-error-name="Default kids"
                           type="number">
                </div>
            </div>
            <div class="col-4">
                <div class="ft2-form-group"
                     *ngIf="value?.hasOwnProperty('minChildren')">
                    <label>
                        Min
                    </label>
                    <input class="ft2-input -large -square"
                           [(ngModel)]="value.minChildren"
                           name="minChildren"
                           placeholder="Enter min kids count"
                           required
                           min="0"
                           #controlsRef
                           #controlsModelRef="ngModel"
                           data-error-name="Min kids"
                           type="number">
                </div>

            </div>
            <div class="col-4">
                <div class="ft2-form-group"
                     *ngIf="value?.hasOwnProperty('maxChildren')">
                    <label>
                        Max
                    </label>
                    <input class="ft2-input -large -square-left"
                           [(ngModel)]="value.maxChildren"
                           name="maxChildren"
                           placeholder="Enter max kids count"
                           required
                           min="1"
                           #controlsRef
                           #controlsModelRef="ngModel"
                           data-error-name="Max kids"
                           type="number">
                </div>
            </div>
        </div>
    </ng-container>
    <div class="ft2-form-group" *ngIf="value?.hasOwnProperty('maxRooms')">
        <label>
            Max rooms allowed per booking
        </label>
        <input class="ft2-input -large"
               [(ngModel)]="value.maxRooms"
               name="maxRooms"
               placeholder="Enter max people count"
               required
               min="0"
               #controlsRef
               #controlsModelRef="ngModel"
               data-error-name="Max rooms per booking"
               type="number">
    </div>

    <ng-container *ngFor="let control of controlsModelRef">
        <div class="ft2-form-error mt-2"
             *ngIf="control.invalid && control.touched">
            <ng-container *ngFor="let key of valueObjectKeys">
                <p *ngIf="control.errors.required && control.name === key">
                    {{ getInputAttributeByName(key, 'data-error-name') }} value is required
                </p>
                <p *ngIf="control.errors.min && control.name === key">
                    <ng-container *ngIf="control.value === 0; else minErrorTpl">
                        {{ getInputAttributeByName(key, 'data-error-name') }} can't be 0
                    </ng-container>
                    <ng-template #minErrorTpl>
                        {{ getInputAttributeByName(key, 'data-error-name') }} value can't be less
                        than {{ getInputAttributeByName(key, 'min') }}
                    </ng-template>
                </p>
                <p *ngIf="control.errors.maxlength && control.name === key">
                    {{ getInputAttributeByName(key, 'data-error-name') }} value must be at
                    most {{ getInputAttributeByName(key, 'maxlength') }} characters
                    long
                </p>
                <p *ngIf="control.errors.max && control.name === key">
                    <ng-container *ngIf="control.value === 0; else maxErrorTpl">
                        {{ getInputAttributeByName(key, 'data-error-name') }} can't be 0
                    </ng-container>
                    <ng-template #maxErrorTpl>
                        {{ getInputAttributeByName(key, 'data-error-name') }} value can't be more
                        than {{ getInputAttributeByName(key, 'max') }}
                    </ng-template>
                </p>
            </ng-container>
        </div>
    </ng-container>
    <div class="ft2-form-error mt-2"
         *ngIf="validate(this.value) as formError">
        <p *ngIf="formError?.minAdultsOverflow">
            Min adults must be less than max adults
        </p>
        <p *ngIf="formError?.defaultAdultsOverflow">
            Default adults must be between min and max adults
        </p>
        <p *ngIf="formError?.minChildrenOverflow">
            Min kids must be less than max kids
        </p>
        <p *ngIf="formError?.defaultChildrenOverflow">
            Default kids must be between min and max kids
        </p>
    </div>
</ft-modal-editor-wrapper>
