import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { internalAppError, internalServerError } from 'account-hybrid/core/store/actions/error.action';

/**
 * Effect class for error effects
 */
@Injectable()
export class ErrorEffects {
    /**
     * Handle internal app effect
     */
    handleInternalAppError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(internalAppError),
                tap(a => {
                    alert('Sorry, there was a problem saving. Please try again in a few minutes.');
                    console.log('Internal app error: ', a.error);
                }),
            ),
        { dispatch: false },
    );

    /**
     * Handle internal error effect
     */
    handleInternalServerError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(internalServerError),
                tap(a => {
                    alert('Sorry, there was a problem saving. Please try again in a few minutes.');
                    console.log('Internal server error: ', a.error);
                }),
            ),
        { dispatch: false },
    );

    /**
     * Service constructor
     * @param actions$ actions$
     */
    constructor(
        private actions$: Actions,
    ) {}
}
