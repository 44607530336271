import { Injectable } from '@angular/core';
import { NgbDate, NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';


export const dateToNgbDate = (date: string | Date) => {
    if (!date) {
        return null;
    }
    const [year, month, day] = dayjs(date).format('YYYY-MM-DD').split('-');

    return new NgbDate(
        +year,
        +month,
        +day
    );
};

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class NgbCustomAdapter extends NgbDateAdapter<Date> {

    fromModel(value: Date | null): NgbDateStruct | null {
        return dateToNgbDate(value);
    }

    toModel(date: NgbDateStruct | null): Date | null {
        return date ? new Date(date.year, date.month - 1, date.day) : null;
    }
}
