import { Action, createReducer, on } from '@ngrx/store';
import { hideAppMenu, showAppMenu } from "./app-menu.actions";
import { AppMenuState } from "./app-menu.state";


export const appMenuInitialState: AppMenuState = {
    visible: false,
};

const reducer = createReducer(
    appMenuInitialState,
    on(showAppMenu, (state) => {
        return {
            ...state,
            visible: true
        };
    }),
    on(hideAppMenu, (state) => {
        return {
            ...state,
            visible: false
        };
    })
);

export function appMenuReducer(state: AppMenuState | undefined, action: Action) {
    return reducer(state, action);
}


