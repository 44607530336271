/**
 * Created by roman.kupin on 10/30/2017.
 */

import { SpaceType } from 'account-hybrid/common/components/spaces/models/space-type';
import { CompanyType, UserType } from 'account-hybrid/core/authentication/models/user';
import { MixedSpace, getCurrentSpaceFromPath } from 'account-hybrid/common/components/spaces/models/space';
import * as AdminReports from "./admin-reports/adminReports.module";
import { getSession, LicenseType, Permissions, hasPermissionInProperty, hasPermissionInCompany } from 'account-hybrid/core/authentication';


const forAdmin = [
    AdminReports
];

/*
 * Loads app features dynamically based on company type & user permissions
 * ANY FEATURE AVAILABLE IN ANY PROPERTY IS ENABLED!
 */
export async function getFeaturesAsync() {
    const session = getSession();
    if (!session) return [];
    const currentSpace = getCurrentSpaceFromPath();
    if (!currentSpace && session.UserType !== UserType.Admin) return [];
    if (!currentSpace && session.UserType === UserType.Admin) return forAdmin;

    switch (currentSpace.type) {
        case SpaceType.Company: {
            const company = session.Companies.find(c => c.UUID === currentSpace.id);
            if (company.Type == CompanyType.Group) {
                return import("./group.features")
                    .then(f => f.forCompanySpace)
                    .then(features => features.filter(feature => (feature.PERMISSIONS === Permissions.None || session.UserType === UserType.Admin ||
                        hasPermissionInCompany(currentSpace.id, feature.PERMISSIONS)) &&
                        (feature.LICENSE === LicenseType.None || company.Licenses.some(cl => cl == feature.LICENSE))));
            } else if (company.Type == CompanyType.Cooperative) {
                return import("./coop.features")
                    .then(f => f.forCompanySpace)
                    .then(features => features.filter(feature => (feature.PERMISSIONS === Permissions.None || session.UserType === UserType.Admin ||
                        hasPermissionInCompany(currentSpace.id, feature.PERMISSIONS)) &&
                        (feature.LICENSE === LicenseType.None || company.Licenses.some(cl => cl == feature.LICENSE))));
            }
        }
        case SpaceType.Property: {
            return import("./group.features")
                .then(f => f.forPropertySpace)
                .then(features => features.filter(feature => (feature.PERMISSIONS === Permissions.None || session.UserType === UserType.Admin ||
                    hasPermissionInProperty(currentSpace.id, feature.PERMISSIONS)) &&
                    (feature.LICENSE === LicenseType.None/* dont have company and cant get license || companyLicenses.some(cl => cl == feature.LICENSE)*/)));
        }
        case SpaceType.Mixed: {
            const company = session.Companies.find(c => c.UUID === (currentSpace as MixedSpace).company.id);
            if (company.Type == CompanyType.Group) {
                return import("./group.features")
                    .then(f => f.forMixedSpace)
                    .then(features => features.filter(feature => (feature.PERMISSIONS === Permissions.None || session.UserType === UserType.Admin ||
                        hasPermissionInProperty((currentSpace as MixedSpace).property.id, feature.PERMISSIONS)) &&
                        (feature.LICENSE === LicenseType.None || company.Licenses.some(cl => cl == feature.LICENSE))));
            } else if (company.Type == CompanyType.Cooperative) {
                return import("./coop.features")
                    .then(f => f.forMixedSpace)
                    .then(features => features.filter(feature => (feature.PERMISSIONS === Permissions.None || session.UserType === UserType.Admin ||
                        hasPermissionInProperty((currentSpace as MixedSpace).property.id, feature.PERMISSIONS)) &&
                        (feature.LICENSE === LicenseType.None || company.Licenses.some(cl => cl == feature.LICENSE))));
            }
        }
        case SpaceType.Collaborative: {
            const company = session.Companies.find(c => c.UUID === currentSpace.id);
            return import("./collaborative.features")
                    .then(f => f.forCompanySpace)
                    .then(features => features.filter(feature => (feature.PERMISSIONS === Permissions.None || session.UserType === UserType.Admin ||
                        hasPermissionInCompany(currentSpace.id, feature.PERMISSIONS)) &&
                        (feature.LICENSE === LicenseType.None || company.Licenses.some(cl => cl == feature.LICENSE))));;
        }
    }

}
