import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, withLatestFrom, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { load, loadSuccess, loadFail, commit, commitSuccess, commitFail } from './actions';
import { PlannerflowSettings } from "account-hybrid/common/components/touchpoints/models/plannerflow-settings";
import { DiscoveryApiService } from "account-hybrid/common/components/touchpoints/services/discovery-api.service";
import { selectCurrentDisplayedDiscoveryVersion } from 'account-hybrid/features/discovery/store';


@Injectable()
export class PlannerflowEffects {
    load$ = createEffect(() =>
        this.actions$.pipe(
            ofType(load),
            withLatestFrom(this.store$.pipe(select(selectCurrentDisplayedDiscoveryVersion))),
            mergeMap(([{componentGroupUuid}, version]) =>
                this.discoveryApiService.getDiscoverySettings(componentGroupUuid, version)
                    .pipe(
                        map((settings: PlannerflowSettings) => {
                            return loadSuccess({data: settings});
                        }),
                        catchError(error => of(loadFail({error})))
                    ))
        )
    );

    commit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(commit),
            withLatestFrom(this.store$.pipe(select(selectCurrentDisplayedDiscoveryVersion))),
            mergeMap(([{data, componentGroupUuid}]) =>
                this.discoveryApiService.updateDiscoverySettings(componentGroupUuid, data)
                    .pipe(
                        map(settings => {
                            return commitSuccess({data: settings});
                        }),
                        catchError(error => of(commitFail({error})))
                    ))
        )
    );

    constructor(private actions$: Actions,
                private store$: Store<any>,
                private discoveryApiService: DiscoveryApiService) {
    }
}

