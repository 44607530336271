import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { LoadingComponent } from "./loading.component";
import { LoadingDirective } from './loading.directive';


@NgModule({
    declarations: [
        LoadingComponent,
        LoadingDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
    ],
    exports: [
        LoadingComponent,
        LoadingDirective
    ]
})
export class LoadingModule {
}

