/**
 * Created by roman.kupin on 2/9/2018.
 */

const TEMPLATE_URL = require("./contestContainer.component.html");


declare let angular;
declare let _;
export const NAME = "ftContestContainer";

export const DEFINITION = [
    "$compile", "$templateCache", "$controller", "$injector", "ftModal", "ContestContainer", "$timeout", "$ngRedux", "$state", 'emailBannerUpdates',
    function CampaignContainerComponent($compile, $templateCache, $controller, $injector, ftModal, ContestContainer, $timeout, $ngRedux, $state, emailBannerUpdates) {

        return {
            compile: function () {
                return function ($scope, elem, attrs) {

                    let containerTemplate,
                        containerElement;

                    let contestsTabs = [
                        { state: "^.submissions", name: 'submissions', title: 'Submissions', isActive: false },
                        { state: "^.winners", name: 'winners', title: 'Winners', isActive: false },
                        { state: "^.details", name: 'details', title: 'Details', isActive: false },
                        { state: "^.invitations", name: 'invitations', title: 'Invitations', isActive: false }
                    ];

                    $scope.contestsTabs = contestsTabs;

                    function watchArgs(datas) {

                        const propertyUuid = datas[0],
                            contestUuid = datas[1];

                        let activeSection = datas[2];

                        contestsTabs.forEach(tab => {
                            tab.isActive = tab.name === activeSection;
                        });

                        $scope.sectionChanged = function (section) {
                            activeSection = section.name;
                            contestsTabs.forEach(tab => {
                                tab.isActive = tab.name === section.name;
                            });
                            injectSection(activeSection);
                            // $state.router.urlService.listen(false);
                            // $state.go(section.state, $state.router.stateService.params);
                        };

                        $scope.isV2 = true;
                        emailBannerUpdates.startReceiving();

                        const injectables = {
                            $scope,
                            contestUuid: contestUuid,
                            onReviewFinalists: () => {
                                $scope.sectionChanged(contestsTabs[1]);
                            }
                        };
                        $scope.$ftContestHeaderController = () => $injector.invoke(['$rootScope', '$q', 'PhotoContest', '_', 'parse', '$timeout',
                            function ($rootScope, $q, PhotoContest, _, parse, $timeout) {

                                function getIsEnabled(response) {
                                    const languages = parse.arrayOrDefault(response.languages);
                                    const langEn = _.find(languages, function (lang) {
                                        return lang.code === "en"
                                    });
                                    if (!langEn.contestName) return false;
                                    if (!langEn.followerMessage) return false;
                                    if (!langEn.guestMessage) return false;
                                    if (!langEn.releaseTerms) return false;
                                    if (!langEn.terms) return false;

                                    if (!response.startDate) return false;
                                    if (!response.endDate) return false;

                                    return !!response.finalistsIncentives && !!response.finalistsIncentives.firstWinnerIncentive;
                                }

                                PhotoContest.load({ contestId: contestUuid, propertyUuid: propertyUuid })
                                    .then(function (response) {

                                        angular.extend($scope, {
                                            $ftCHC: {
                                                isActive: response.isActive == 1,
                                                language: _.find(response.languages.item, { code: 'en' }),
                                                isEnabled: getIsEnabled(response),
                                                updateState: function () {
                                                    if(!$scope.$ftCHC.isActive && !window.confirm("Warning! Disabling this contest will: \n  \u2022 Remove these photo submissions from Story Explorer and Story River.\n  \u2022 Disable sharing and voting by storytellers who joined the campaign.\n  \u2022 Prevent these stories from being used in other areas of your platform.")){
                                                        $scope.$ftCHC.isActive = true;
                                                        return;
                                                    }
                                                    PhotoContest.activate(propertyUuid, contestUuid, $scope.$ftCHC.isActive).then(() => {
                                                        $timeout(() => $rootScope.$broadcast('flipto.photo-contest-activated'), 50);
                                                    });
                                                }
                                            }
                                        });
                                        $scope.$on('flipto.photo-contest-changed', function (evnt, contest) {
                                            const englishContest = _.find(contest.languages.item, { code: 'en' });
                                            $scope.$ftCHC.language.contestName = englishContest.contestName;
                                            $scope.$ftCHC.isEnabled = getIsEnabled(contest);
                                        });
                                    }
                                    );
                            }]);
                        $scope.$ftContestController = () => {
                            switch (activeSection) {
                                case "submissions": {
                                    return $controller("flipto.account.sections.platform.photoAdvocacy.SubmissionsController", injectables);
                                }
                                case "winners": {
                                    return $controller("flipto.account.sections.platform.photoAdvocacy.WinnersController", injectables);
                                }
                                case "invitations":
                                case "details": {
                                    return $controller("flipto.account.sections.platform.photoAdvocacy.DetailsController", injectables);
                                }
                            }
                        };

                        if (!containerElement) {
                            containerTemplate = $templateCache.get(TEMPLATE_URL);
                            containerElement = $compile(containerTemplate)($scope);
                            elem.append(containerElement);
                        }

                        ftModal.show("accountv2.ContestContainer");
                        injectSection(activeSection);
                    }


                    function injectSection(sectionName) {
                        $timeout(() => {
                            const container = angular.element(containerElement[0].querySelector("[container]"));
                            container.empty();
                            const template = $templateCache.get(`/app/sections/platform/layer/photo-advocacy/contest/${sectionName}.html`);
                            container.append($compile(`
                                                    <div ng-controller="$ftContestController">
                                                        ${template}
                                                    </div>`)($scope));

                        });
                    }

                    $scope.$watchGroup([
                        () => $scope.$eval(attrs.propertyUuid),
                        () => $scope.$eval(attrs.contestUuid),
                        () => $scope.$eval(attrs.activeSection)
                    ], watchArgs);


                    $scope.close = function () {
                        if (!!attrs.onClose) {
                            $scope.$eval(attrs.onClose);
                        }
                        $scope.destroyContainer();
                        emailBannerUpdates.stopReceiving();
                    };

                    $scope.destroyContainer = function () {
                        ftModal.hide("accountv2.ContestContainer");
                        ContestContainer.hide();
                    };


                }
            }
        }
    }
];

