import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from "@angular/forms";
import { PasswordStrengthService } from "../services/password-strength.service";

@Directive({
    selector: '[ftPasswordStrength]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PasswordStrengthValidatorDirective),
            multi: true
        }
    ]
})
export class PasswordStrengthValidatorDirective {
    @Input() set minLength(value: number) {
        this.passwordStrengthService.config.minLength = value;
    }

    @Input() set maxLength(value: any) {
        this.passwordStrengthService.config.maxLength = value;
    }

    @Input() set minNumbersLength(value: any) {
        this.passwordStrengthService.config.minNumbersLength = value;
    }

    @Input() set minSpecialsLength(value: any) {
        this.passwordStrengthService.config.minSpecialsLength = value;
    }

    @Input() set minUppercaseLength(value: any) {
        this.passwordStrengthService.config.minUppercaseLength = value;
    }

    @Input() set minLowercaseLength(value: any) {
        this.passwordStrengthService.config.minLowercaseLength = value;
    }

    @Input() set numberOrSpecials(value: any) {
        this.passwordStrengthService.config.numberOrSpecials = value;
    }

    @Input() set noSpaces(value: any) {
        this.passwordStrengthService.config.noSpaces = value;
    }

    constructor(private passwordStrengthService: PasswordStrengthService) {
    }

    passwordStrengthMatches(value: string): boolean {
        return this.passwordStrengthService.numbersSpecialsMatches(value)
            && this.passwordStrengthService.uppercaseMatches(value)
            && this.passwordStrengthService.lowercaseMatches(value)
            && this.passwordStrengthService.lengthMatches(value)
            && this.passwordStrengthService.noSpaceMatches(value);
    }

    validate(c: AbstractControl): ValidationErrors | null {
        return this.passwordStrengthMatches(c.value) ? null : {
            passwordStrength: true
        };
    }
}
