import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ftOnOffToggle'
})
export class OnOffTogglePipe implements PipeTransform {

    transform(value: boolean): string {
        return value ? 'On' : 'Off';
    }
}
