/**
 * Created by roman.kupin on 28/08/2014.
 */


angular.module('flipto.core.api.user', ['flipto.core.user'])
    .config(['$provide', function ($provide) {

        $provide.factory('User', ['urlFormatter', '$http', 'IdentityService', '$q', 'parse', 'environment', function (urlFormatter, $http, identity, $q, parse, environment) {

            /**
             * User service
             * @constructor
             */
            function User() {
                /**
                 * Login
                 * @param model
                 * @returns {*}
                 */
                this.login = function (model) {
                    return $http.post('/api/user/login', model)
                        .then(function (response) {
                            return response.data;
                        });
                };
                /**
                 * Logout
                 * @returns {*}
                 */
                this.logout = function () {
                    return $http.post('/api/auth/logout');
                };

                /**
                 * Un-impersonate
                 * @returns {*}
                 */
                this.unImpersonate = function () {
                    return $http.post('/api/user/unimpersonate')
                        .then(function () {
                            identity.logout();
                        });
                };

                /**
                 * Info
                 * @returns {*}
                 */
                this.info = function () {
                    return $http.get('/api/user/info')
                        .then(function (response) {
                            return response.data;
                        });
                };

                /**
                 * Get list of properties
                 * @returns {*}
                 */
                this.getProperties = function () {
                    return $http.get('/api/user/properties')
                        .then(function (response) {
                            return parse.arrayOrDefault(response.data);
                        });
                };

                /**
                 * Get list of collections
                 * @returns {*}
                 */
                this.getCollections = function () {
                    return $http.get('/api/user/collections')
                        .then(function (response) {
                            return parse.arrayOrDefault(response.data);
                        });
                };

                /**
                 * Create a collection
                 * @returns {*}
                 */
                this.createCollection = function (model) {
                    return $http.post('/api/user/collections', model)
                        .then(function (response) {
                            return response.data;
                        });
                };

                /**
                 * Update a collection
                 * @returns {*}
                 */
                this.updateCollection = function (model) {
                    return $http.put('/api/user/collections', model)
                        .then(function (response) {
                            return response.data;
                        });
                };

                /**
                 * Delete a collection
                 * @returns {*}
                 */
                this.deleteCollection = function (model) {
                    return $http.delete(urlFormatter.format('/api/user/collections/:collectionUUID', model, true))
                        .then(function (response) {
                            return response.data;
                        });
                };

                /**
                 * Reset password
                 * @param email
                 * @returns {*}
                 */
                this.resetPassword = function (email) {
                    return $http.post('/api/user/reset-password', {email: email})
                        .then(function (response) {
                            return response.data;
                        });
                };

                /**
                 * Change password
                 * @param model
                 * @returns {*}
                 */
                this.changePassword = function (model) {
                    return $http.post('/api/user/change-password', model)
                        .then(function (response) {
                            return response.data;
                        })
                };

                /**
                 * Search keyword
                 * @param propertyUUID
                 * @param search
                 * @returns {*}
                 */
                this.searchKeyword = function (propertyUUID, search) {
                    return $http.get(urlFormatter.format('/api/user/search', { propertyUUID: propertyUUID, search: search }, true))
                        .then(function (response) {
                            return response.data;
                        });
                };

            }

            return new User();
        }]);

    }]);