/**
 * Created by Roman on 2014-10-15.
 */


angular
    .module("flipto.core.user")
    .config(['$filterProvider', function ($filterProvider) {

        $filterProvider.register('fullName', [function () {

            function getFirstName(source) {
                return source.first || source.FirstName;
            }

            function getLastName(source) {
                return source.last || source.LastName;
            }

            function formatName(source, isInitialOnly) {
                var name = getFirstName(source);
                if (getLastName(source)) name += ' ' + (isInitialOnly ? (getLastName(source).substring(0, 1) + ".") : getLastName(source));
                return name;
            }

            return function (source, isInitialOnly) {
                if (!source) return '';
                if (!getFirstName(source) && !getLastName(source)) return '';
                if (angular.isDefined(isInitialOnly) === false) isInitialOnly = false;
                return formatName(source, isInitialOnly);
            };
        }]);
    }]);