import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SpacesService } from '../../../components/spaces';
import { Environment, ENVIRONMENT_TOKEN } from '../../../../core/environment.service';
import { Observable } from 'rxjs';
import { Language } from '../../../models/language.model';
import { LanguageDto } from '../../../models/language-dto.model';
import { map } from 'rxjs/operators';
import { CompanyLanguage } from '../../../models/company-language.model';
import { PropertyLanguageDto } from 'account-hybrid/common/models/property-language.dto';
import { PropertyLanguage } from 'account-hybrid/common/models/property-language.model';

@Injectable({
    providedIn: 'root'
})
export class AccountLanguagesService {
    constructor(private http: HttpClient,
                private spacesService: SpacesService,
                @Inject(ENVIRONMENT_TOKEN) private environment: Environment) {
    }

    getAccountLanguages(): Observable<CompanyLanguage[] | PropertyLanguage[]> {
        // @todo BK: languages combined from company and property spaces, probably it's better to bound separate languages pages to same store too.
        const url = this.spacesService.isPropertySpace() || this.spacesService.isMixedSpace()
            ? `${this.environment.apiV1BaseUrl}api/${this.spacesService.getPropertyUuid()}/properties/${this.spacesService.getPropertyUuid()}/settings/languages`
            : `${this.environment.apiBaseUrl}/${this.spacesService.getCompanyUuid()}/company/${this.spacesService.getCompanyUuid()}/languages`;
        return this.http.get<CompanyLanguage[]>(url)
            .pipe(
                map(languages => this.spacesService.isPropertySpace() || this.spacesService.isMixedSpace()
                    ? this.mapPropertyLanguages((languages as unknown as PropertyLanguageDto))
                    : this.mapCompanyLanguages(languages)
                )
            );
    }

    mapCompanyLanguages(languages: CompanyLanguage[]): CompanyLanguage[] {
        return languages?.map((language: CompanyLanguage) => ({
            ...language,
            languageCode: language?.languageCode ?? (language as any)?.code,
            languageName: language?.languageName ?? (language as any)?.name
        }));
    }

    mapPropertyLanguages(languages: PropertyLanguageDto): PropertyLanguage[] {
        return languages?.item?.map(language => ({
            ...language,
            languageCode: language?.languageCode ?? language?.code,
            languageName: language?.languageName ?? language?.name
        }))
    }

     createLanguage(language: Language): Observable<any> {
        const usePropertyLanguages = this.spacesService.isPropertySpace() || this.spacesService.isMixedSpace()
        const url = usePropertyLanguages
            ? `${this.environment.apiV1BaseUrl}api/${this.spacesService.getPropertyUuid()}/properties/${this.spacesService.getPropertyUuid()}/settings/languages`
            : `${this.environment.apiBaseUrl}/${this.spacesService.getCompanyUuid()}/company/${this.spacesService.getCompanyUuid()}/languages`;
        return usePropertyLanguages ? this.http.put(url, { newLanguages: [language] }) : this.http.post(url, [language]);
    }

    updateLanguages(languages: Language[]): Observable<any> {
        const usePropertyLanguages = this.spacesService.isPropertySpace() || this.spacesService.isMixedSpace()
        const url = usePropertyLanguages
            ? `${this.environment.apiV1BaseUrl}api/${this.spacesService.getPropertyUuid()}/properties/${this.spacesService.getPropertyUuid()}/settings/languages`
            : `${this.environment.apiBaseUrl}/${this.spacesService.getCompanyUuid()}/company/${this.spacesService.getCompanyUuid()}/languages`;
        return this.http.put(url, usePropertyLanguages ? { languages } : languages);
    }

    removeLanguage(languageCode: string): Observable<any> {
        const usePropertyLanguages = this.spacesService.isPropertySpace() || this.spacesService.isMixedSpace()
        const url = usePropertyLanguages
            ? `${this.environment.apiV1BaseUrl}api/${this.spacesService.getPropertyUuid()}/properties/${this.spacesService.getPropertyUuid()}/settings/languages/${languageCode}`
            : `${this.environment.apiBaseUrl}/${this.spacesService.getCompanyUuid()}/company/${this.spacesService.getCompanyUuid()}/languages/${languageCode}`;
        return this.http.delete(url);
    }

    getAvailableLanguages(): Observable<CompanyLanguage[]> {
        const url = `${this.environment.apiV1BaseUrl}api/languages`;
        return this.http.get<LanguageDto[]>(url).pipe(map(languages => languages?.map(language => ({
            languageCode: language.code,
            languageName: language.name,
            companyUuid: this.spacesService.getCompanyUuid(),
            isRtl: language.isRtl
        }))));
    }
}
