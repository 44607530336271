/**
 * Created by roman.kupin on 9/21/2017.
 */

import * as AccountApiActions from "../../../actions/accountApi";


function getLayout(num) {
    if (num >= 5) return 5;
    if (num >= 3) return 3;
    return 1;
}

export default function showcaseStoriesReducer(state = {
    isInitialized: false,
    layout: 1
}, action) {

    switch (action.type) {
        case AccountApiActions.RANDOM_PHOTOS_FETCH: {
            return Object.assign({}, state, {isInitialized: false});
        }
        case AccountApiActions.RANDOM_PHOTOS_FETCH_SUCCESS: {

            let layout = 1;
            const guestPhotosCount = action.payload.filter(photo => photo.IsGuestPhoto === true).length;
            const propertyPhotosCount = action.payload.filter(photo => photo.IsGuestPhoto === false).length;

            if (guestPhotosCount >= 3) layout = getLayout(guestPhotosCount);
            else layout = getLayout(propertyPhotosCount);

            return Object.assign({}, state, {isInitialized: true, layout});
        }
        case AccountApiActions.RANDOM_PHOTOS_FETCH_ERROR: {
            return Object.assign({}, state, {isInitialized: true, layout: 1});
        }
        default: {
            return state;
        }
    }
}