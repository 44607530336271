import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeNodeComponent } from './components/tree-node/tree-node.component';
import { TreeComponent } from './components/tree/tree.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
    declarations: [
        TreeComponent,
        TreeNodeComponent
    ],
    imports: [
        CommonModule,
        DragDropModule,
        MatIconModule
    ],
    exports: [
        TreeComponent,
        TreeNodeComponent
    ]
})
export class TreeModule {
}
