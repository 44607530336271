angular.module('flipto.core.api.dataParse', [])
    .config(['$provide', function ($provide) {
        $provide.factory('DataParse', ['urlFormatter', '$http', 'SpacesService',
            function (urlFormatter, $http, spacesService) {
                function DataParse() {
                    this.getDataParseEmails = function () {
                        return $http.get('/api/v2/' + spacesService.current.id + '/companies/' + spacesService.current.id + '/email-digest')
                            .then(function (response) {
                                return response.data;
                            });
                    };

                    this.updateDataParseEmails = function (emails) {
                        return $http.put('/api/v2/' + spacesService.current.id + '/companies/' + spacesService.current.id + '/email-digest', emails);
                    };
                }
                return new DataParse();
            }]);
    }]);