import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './reducers/authentication.reducer';
import { UserType } from '../core/authentication';

export const selectAuthenticationState = createFeatureSelector<State>('appAuthentication');
export const selectSessionState = createSelector(
    selectAuthenticationState,
    state => state.session
);
export const selectIsFliptoStaff = createSelector(
    selectSessionState,
    session => session.UserType === UserType.Admin
);
