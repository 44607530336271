/**
 * Created by Roman on 2016-04-11.
 */

angular.module('flipto.core.api.confirmationImage', ['flipto.core.parse']).config([
	'$provide',
	function($provide) {
		$provide.factory('ConfirmationImage', [
			'urlFormatter',
			'$http',
			'parse',
			function(urlFormatter, $http, parse) {
				function ConfirmationImage() {
					this.updateBackground = function(touchpointUUID, image, propertyUuid) {
						return $http
							.put(
								urlFormatter.format(
									'/api/' + propertyUuid + '/properties/layers/prestay/touchpoints/:touchpointUUID/confirmation-email-image/styling/background',
									{touchpointUUID: touchpointUUID}
								),
								{
									backgroundImage: image
								}
							)
							.then(function(response) {
								return response.data;
							});
					};

					this.refresh = function(touchpointUUID, languageCode, propertyUuid) {
						var url = urlFormatter.format(
							'/api/' + propertyUuid + '/properties/layers/prestay/touchpoints/:touchpointUUID/confirmation-email-image/refresh',
							{
								touchpointUUID: touchpointUUID,
								languageCode: languageCode
							},
							true
						);
						return $http.post(url).then(function(response) {
							return response.data;
						});
					};
				}

				return new ConfirmationImage();
			}
		]);
	}
]);
