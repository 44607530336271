
export interface IShareImageActions {
    createImageTemplate(model);
    createImage(model);
    downloadImage(imageUrl);
}

export class LeadShareImageActions implements IShareImageActions {

    static $inject = ["PhotoContest", "fileSaver", 'SpacesService'];

    constructor(
        public PhotoContest,
        public fileSaver,
        private spacesService) { }

    createImageTemplate(model: any) {
        throw new Error("Method not implemented.");
    }

    createImage(model: any) {
        return this.PhotoContest.createCurationShareImage(this.spacesService.getPropertyUuid(), model);
    }

    downloadImage(imageUrl: any) {
        return this.PhotoContest.downloadShareImage(this.spacesService.getPropertyUuid(), imageUrl)
            .then(data => {
                this.fileSaver.saveBlob(data.content, data.fileName);
            });
    }
}

export class InvitationShareImageActions implements IShareImageActions {

    static $inject = ["PhotoContest", "fileSaver", 'SpacesService'];

    constructor(
        public PhotoContest,
        public fileSaver,
        private spacesService) { }

    createImageTemplate(model) {
        return this.PhotoContest.getInvitationShareImageTemplate(this.spacesService.getPropertyUuid(), model);
    }

    createImage(model: any) {
        return this.PhotoContest.createInvitationShareImage(this.spacesService.getPropertyUuid(), model);
    }

    downloadImage(imageUrl: any) {
        return this.PhotoContest.downloadShareImage(this.spacesService.getPropertyUuid(), imageUrl)
            .then(data => {
                this.fileSaver.saveBlob(data.content, data.fileName);
            });
    }
}