/**
 * Created by Roman on 2016-04-12.
 */


angular.module("flipto.components.emailImagesPreview", ["flipto.core.lodash"])
    .config(["$compileProvider", function ($compileProvider) {

        EmailImagesPreviewController.$inject = ["$scope", "emailBannerUpdates", "parse", "$filter", "_"];
        function EmailImagesPreviewController(scope, emailBannerUpdates, parse, $filter, _) {

            var defaultLanguageOrFirst = $filter("defaultLanguageOrFirst");

            this.$onInit = function () {
                this.setupImagePrefix();

                scope.$on("accountapp.email-banner-updates.completed", angular.bind(this, function (e, signal) {
                    this.onImageUpdateReceived({signal: signal});
                }));

                scope.$watch("vm.images", angular.bind(this, function (images) {
                    if (angular.isArray(images) && images.length > 0) {
                        this.previewImage = defaultLanguageOrFirst(images);
                        if (!this.previewImage || !parse.bool(this.previewImage.isImageProcessed)) {
                            this.previewImage = _.find(images, function (image) {
                                return parse.bool(image.isImageProcessed);
                            });
                        }
                        var anyNotReadyYetImages = true /*_.some(images, function (image) {
                         return parse.bool(image.isImageRecordExist) && !parse.bool(image.isImageProcessed);
                         })*/;
                        if (anyNotReadyYetImages && this.connectToHub) {
                            emailBannerUpdates.startReceiving();
                        }
                    }
                }));
            };

            this.$onDestroy = function () {
                if(this.connectToHub) {
                    emailBannerUpdates.stopReceiving();
                }
            };

            this.setupImagePrefix = function () {
                // for some reason we use large for confirmation & non for prearrival
                switch (this.emailImageType) {
                    case 'confirmation':
                        this.headerTextKey = 'flipto.account.ConfirmationBannerPreview';
                        this.cdnSuffixForDesktopImage = 'large';
                        break;
                    case 'prearrival':
                        this.headerTextKey = 'flipto.account.PrearrivalBannerPreview';
                        this.cdnSuffixForDesktopImage = '';
                        break;
                    case 'poststay':
                        this.headerTextKey = 'flipto.account.PoststayBannerPreview';
                        this.cdnSuffixForDesktopImage = 'large';
                        break;
                }
            }
        }

        $compileProvider.component("ftEmailImagesPreview", {
            bindings: {
                "images": "=",
                "emailImageType": "@",
                "campaignUuid": "=",
                "onImageUpdateReceived": "&",
                "onRefresh": "&",
                "connectToHub": "<"
            },
            templateUrl: "/app/components/email-images-preview/emailImagesPreview.html",
            controller: EmailImagesPreviewController,
            controllerAs: "vm"
        });

    }]);