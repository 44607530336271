export const SANITIZE_OPTIONS = {
    allowedAttributes: {
        '*': ['alt', 'class', 'height', 'href', 'rel', 'src', 'tabindex', 'target', 'title', 'width']
    },
    allowedClasses: {},
    allowedSchemes: ['http', 'https', 'mailto'],
    allowedTags: [
        'a', 'b', 'br', 'div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'hr', 'i', 'img', 'li', 'ol', 'p', 'span', 'u', 'ul'
    ],
    filter: null,
    transformText: sanitizeText
};

function sanitizeText(text: string) {
    // forbidden by XML 1.0 specifications
    return text.replace('/((?:[\0-\x08\x0B\f\x0E-\x1F\uFFFD\uFFFE\uFFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF]))/g', '');
}
