/**
 * Created by roman.kupin on 9/18/2017.
 */

import * as SectionsDirective from "./sections.directive";
import * as SectionDirective from "./section.directive";
import * as SectionTriggerDirective from "./sectionTrigger.directive";

declare var angular;
export const SECTIONS_MODULE = angular.module("Components.Navigation.Sections", []);

export class ComponentConfig {

    static $inject = ["$compileProvider"];

    constructor($compileProvider) {
        const directives = [SectionsDirective, SectionDirective, SectionTriggerDirective];
        directives.forEach(directive => {
            $compileProvider.directive(directive.NAME, () => directive.DEFINITION);
        });
    }

}

SECTIONS_MODULE.config(ComponentConfig);