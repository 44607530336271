import { loadStoryExplorerFail, loadStoryExplorerSuccess, commitStoryExplorer, commitStoryExplorerSuccess, loadStylingStoryExplorer, loadStylingStoryExplorerSuccess, loadStylingStoryExplorerFail } from './actions';

import { Action, createReducer, on } from "@ngrx/store";
import { loadStoryExplorer } from ".";
import { State } from "./state";

export const reducer = createReducer(
    {
        loading: false,
        settings: null,
        commitSuccess: false,
        stylingSettings: null
    } as State,
    on(loadStoryExplorer, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(loadStoryExplorerSuccess,  (state, {data}) => {
        return {
            ...state,
            loading: false,
            settings: data
        };
    }),
    on(loadStoryExplorerFail, (state, {error}) => {
        return {
            ...state,
            loading: false,
            error
        };
    }),
    // commit
    on(commitStoryExplorer, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(commitStoryExplorerSuccess, (state, {data}) => {
        return {
            ...state,
            loading: false,
            settings: data
        };
    }),
    // styling
    on(loadStylingStoryExplorer, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(loadStylingStoryExplorerSuccess, (state, {data}) => {
        return {
            ...state,
            loading: false,
            stylingSettings: data
        };
    }),
    on(loadStylingStoryExplorerFail, (state, {error}) => {
        return {
            ...state,
            loading: false,
            error
        };
    }),
);

export function storyExplorerReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}
