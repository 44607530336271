import {
    Component,
    ViewChild,
    forwardRef,
    Input,
    OnChanges,
    SimpleChanges,
    ElementRef,
    OnInit,
    OnDestroy
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MODAL_EDITOR_TOKEN } from '../../../classes/modal-editor-base';
import { ModalEditorBase } from '../../../classes/modal-editor-base';
import dayjs from 'dayjs';
import { takeUntil } from 'rxjs/operators';


@Component({
    selector: 'ft-bookable-range-editor',
    templateUrl: './bookable-range-editor.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => BookableRangeEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: BookableRangeEditorComponent
        }
    ]
})
export class BookableRangeEditorComponent extends ModalEditorBase implements OnInit {
    @ViewChild('inputElement', { static: false }) inputElement: ElementRef;
    rangeSelectModel = false;
    pattern = '^\\d+$';
    minMonth = 0;

    get bookableDate() {
        return dayjs().add(this.value, 'M').toDate();
    }

    ngOnInit() {
        this.value$.pipe(
            takeUntil(this.destroySubj)
        )
            .subscribe(value => {
                if (value && !this.rangeSelectModel) {
                    this.rangeSelectModel = true;
                }
            });
    }

    onSelectChange(value) {
        if (value && !this.value) {
            this.value = 12;
        } else {
            this.value = 0;
        }
    }

    onCancelChanges() {
        if (this.initialValue === 0) {
            this.value = this.initialValue;
            this.rangeSelectModel = false;
        } else {
            super.onCancelChanges();
        }
    }
}


