/**
 * Created by Roman on 2015-02-05.
 */


angular.module('flipto.core.calculate', ['flipto.core.parse'])
    .config(['$provide', '$filterProvider', function ($provide, $filterProvider) {

        $provide.factory('calculate', ['parse', function (parse) {

            /**
             * Encalsulates calculations
             * @constructor
             */
            function CalculateService() {

            }

            /**
             * Calculate percentage of total
             * @param value
             * @param total
             */
            CalculateService.prototype.percentage = function (value, total) {
                var percentage = parse.intOrDefault(value) / parse.intOrDefault(total) * 100;
                return isNaN(percentage) ? 0 : percentage;
            };

            /**
             * Calculate share of total
             * @param value
             * @param total
             */
            CalculateService.prototype.share = function (value, total) {
                var share = parse.intOrDefault(value) / parse.intOrDefault(total);
                return isNaN(share) ? 0 : share;
            };

            return new CalculateService();

        }]);

        $filterProvider.register('percentage', ['calculate', function (calculate) {
            return function (value, total) {
                return calculate.percentage(value, total);
            };
        }]);

        $filterProvider.register('share', ['calculate', function (calculate) {
            return function (value, total) {
                return calculate.share(value, total);
            };
        }])
    }]);
