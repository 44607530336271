/**
 * Created by roman.kupin on 11/9/2017.
 */

import * as ShowcaseStoriesComponent from "./showcaseStories.component";

declare let angular;
export const SHOWCASESTORIES_MODULE = angular.module("AccountApp.v2.Components.ShowcaseStories", []);

export class ComponentConfig {

    static $inject = ["$compileProvider"];

    constructor($compileProvider) {
        const components = [ShowcaseStoriesComponent];
        components.forEach(component => {
            $compileProvider.component(component.NAME, component.OPTIONS);
        });
    }
}

SHOWCASESTORIES_MODULE.config(ComponentConfig);