/**
 * Created by Roman on 2014-10-15.
 */


angular.module('flipto.core.api.admin', [])
    .config(['$provide', function ($provide) {

        $provide.factory('Admin', ['urlFormatter', '$http', function (urlFormatter, $http) {

            /**
             * Admin service
             * @constructor
             */
            function Admin() {
                this.accounts = function () {
                    return $http.get('/api/admin/accounts')
                        .then(function (response) {
                            return response.data;
                        });
                };

                /**
                 * Impersonate company
                 * @param model
                 * @returns {*}
                 */
                this.impersonate = function (model) {
                    return $http.post(urlFormatter.format('/api/admin/createaccesstoken/:companyUUID', model))
                        .then(function (response) {
                            return response.data;
                        });
                };

                /**
                 * Creates new account
                 * @param data
                 * @returns {*}
                 */
                this.createAccount = function (data) {
                    return $http.post('/api/accounts/create', data)
                        .then(function (response) {
                            return response.data;
                        });
                };
            }

            return new Admin();
        }]);
    }]);