import { FlowStep } from ".";

declare const angular;
export const NAME = "ftStep";

export class StepController {

    static $inject = ["$scope", "$attrs", "$element", "$transclude", "$compile"];

    name: string;
    step: FlowStep;

    constructor(
        public $scope,
        public $attrs,
        public $element,
        public $transclude,
        public $compile) {
        this.name = $attrs.name;
        $transclude($scope, function (tElem, tScope) {
            $element.append($compile(`<div ng-if="$ftStep.step.isActive">${tElem[0].outerHTML}</div>`)(tScope));
        });

        //$scope.$watch(() => this.step.isActive, () => console.log(this.step));
    }

    $onInit() {
        this.step = this.$scope.$ftFlow.steps.find(s => s.name === this.name);
    }
}


export const DEFINITION = [function () {
    return {
        transclude: true,
        controller: StepController,
        controllerAs: "$ftStep",
        bindToController: true,
        scope: true
    }
}];