/**
 * User: Kupin.R
 * Date: 5/20/14
 */



angular
    .module("flipto.core.cssbeautify", [])
    .factory("cssBeautify",
        [
            "$window",
            function ($window) {
                return $window.css_beautify;
            }
        ]);
