/**
 * Created by roman.kupin on 2/15/2017.
 */



angular.module('flipto.accountv1.SharedFeatures')
    .controller('flipto.account.sections.stories.PhotosController',
        [
            'Guests', '$filter', 'curationModal', '_', '$q', 'isCuration', '$scope', 'criteria', "$rootScope", 'SpacesService', 'SessionService',
            function(Guests, $filter, curationModal, _, $q, isCuration, $scope, criteria, $rootScope, spacesService, sessionService) {

                var vm = this,
                    ftAzureStoredPhoto = $filter('ftAzureStoredPhoto');
                vm.isCuration = isCuration;
                vm.scrollDisabled = false;
                vm.isProcessingRequest = false;
                vm.searchText = criteria || '';
                vm.criteria = criteria || vm.criteria;
                vm.stories = [];
                vm.isRecentOnly = true;

                var libraryFilters = [
                        {
                            name: 'Most recent',
                            query: {SortBy: 'create_date', SortDirection: 'desc', Offset: 0, Fetch: 24}
                        },
                        {
                            name: 'Highest rating',
                            query: {SortBy: 'photoRating', SortDirection: 'desc', Offset: 0, Fetch: 24}
                        }
                    ],
                    curationFilters = [
                        {
                            name: 'Current',
                            query: {
                                SortBy: 'create_date',
                                SortDirection: 'desc',
                                Offset: 0,
                                Fetch: 6,
                                isCurrentOnly: true
                            }
                        },
                        {
                            name: 'Older',
                            query: {
                                SortBy: 'create_date',
                                SortDirection: 'desc',
                                Offset: 0,
                                Fetch: 6,
                                isCurrentOnly: false
                            }
                        }
                    ];

                vm.filters = vm.isCuration ? curationFilters : libraryFilters;

                /**
                 * Load photos
                 * @param query
                 */
                vm.load = function(query) {
                    vm.scrollDisabled = true;
                    vm.isProcessingRequest = true;
                    vm.currentQuery = query;
                    var loader = vm.isCuration ? Guests.unratedStoriesPhotos : Guests.photos;
                    return loader(spacesService.getPropertyUuid(), query)
                        .then(function(data) {
                            if ( vm.currentQuery !== query ) return;
                            var total = angular.isArray(data.stories) && data.stories.length > 0 ? data.stories[0].totalCount : 0;
                            vm.hasMoreData = total > (vm.filter.query.Offset + vm.filter.query.Fetch);
                            return data;
                        })
                        .finally(function() {
                            if ( vm.currentQuery !== query ) return;
                            vm.scrollDisabled = false;
                            vm.isProcessingRequest = false;
                        });
                };

                /**
                 * Load next page
                 */
                vm.loadNextPage = function() {
                    if ( !vm.isCuration ){
                        vm.filter.query.Offset += vm.filter.query.Fetch;
                    } else {
                        var notCurated = _.filter(vm.stories, function(item) {
                            return item.isCurated !== true;
                        });
                        vm.filter.query.Offset = notCurated.length;
                    }
                    vm.setPageSizeForCurationRecentPhotos();
                    return vm.load(angular.extend({}, vm.filter.query, {SearchText: vm.searchText}))
                        .then(function(data) {
                            addPhotoUrl(data.stories);
                            vm.stories.push.apply(vm.stories, data.stories);
                            $scope.$emit("curation-controller.counters.contest-submissions", {
                                total: vm.getTotal(),
                                isRecentOnly: vm.isRecentOnly
                            });
                            return data.stories;
                        });
                };

                /**
                 * Set pagesize to 24 for all subsequent pages on curation
                 */
                vm.setPageSizeForCurationRecentPhotos = function() {
                    if ( vm.isCuration ){
                        vm.filter.query.Fetch = 24;
                    }
                };

                /**
                 * Change filter
                 * @param newFilter
                 */
                vm.changeFilter = function(newFilter) {
                    vm.scrollLoadsResetCounter = 0;
                    vm.stories = [];
                    vm.filter = angular.copy(newFilter);
                    return vm.load(angular.extend({}, vm.filter.query, {SearchText: vm.searchText}))
                        .then(function(data) {
                            vm.stories = angular.isArray(data.stories) ? data.stories : [];
                            addPhotoUrl(vm.stories);
                            $scope.$emit("curation-controller.counters.contest-submissions", {
                                total: vm.getTotal(),
                                isRecentOnly: vm.isRecentOnly
                            });
                            return vm.stories;
                        });
                };

                vm.setCriteria = function(criteria) {
                    vm.searchText = criteria;
                    vm.filter.query.Offset = 0;
                    vm.scrollLoadsResetCounter = 0;
                    vm.stories = [];
                    vm.load(angular.extend({}, vm.filter.query, {SearchText: vm.searchText}))
                        .then(function(data) {
                            vm.stories = angular.isArray(data.stories) ? data.stories : [];
                            addPhotoUrl(vm.stories);
                            $scope.$emit("curation-controller.counters.contest-submissions", {
                                total: vm.getTotal(),
                                isRecentOnly: vm.isRecentOnly
                            });
                            return vm.stories;
                        });
                };

                vm.clearSearch = function() {
                    vm.criteria = '';
                    vm.setCriteria(vm.criteria);
                };

                vm.selectKeyword = function(keyword) {
                    vm.criteria = keyword;
                    vm.setCriteria(keyword);
                };

                vm.getSuggestedKeywords = function(text) {
                    if ( !text.length ) return [];
                    return Guests.searchKeyword(spacesService.getPropertyUuid(), text)
                        .then(function(data) {
                            return data;
                        });
                };

                vm.getCommonKeywords = function() {
                    return Guests.getCommonKeywords(spacesService.getPropertyUuid());
                };

                vm.openStory = function(story, isCuration) {
                    if ( isCuration ){
                        vm.curate(story);
                    } else {
                        $rootScope.$broadcast("[StoryModal].OpenStory", {
                            propertyUuid: spacesService.getPropertyUuid(),
                            storyUuid: story.promoter.guestUUID,
                            isOpenLibrary: false
                        });
                    }
                };

                vm.curate = function(story) {
                    curationModal.new(sessionService.getProperty(spacesService.getPropertyUuid()), story.promoter.guestUUID, true);
                };

                /**
                 * Show advocate details
                 * @param story
                 */
                vm.curateBulk = function(story) {
                    var chunk = vm.getChunk(story);
                    var total = vm.getTotalNotCurated() - (!story.isCurated ? 1 : 0);
                    curationModal.new(sessionService.getProperty(spacesService.getPropertyUuid()), story.promoter.guestUUID, true, chunk, total, vm.nextChunk);
                };

                vm.getChunk = function(story) {
                    var notCurated = _.filter(vm.stories, function(item) {
                        return item.isCurated !== true;
                    });
                    var notCuratedWithoutSelected = _.filter(notCurated, function(item) {
                        return item.promoter.guestUUID !== story.promoter.guestUUID;
                    });
                    return vm.getCurationChunkFromStories(notCuratedWithoutSelected);
                };

                vm.getTotalNotCurated = function() {
                    if ( !vm.stories || !vm.stories.length ) return 0;
                    var curated = _.filter(vm.stories, function(item) {
                        return item.isCurated === true;
                    });
                    return vm.stories[0].totalCount - curated.length;
                };

                vm.curateBulkFromEmailLink = function(guestUUID) {
                    var notCurated = _.filter(vm.stories, function(item) {
                        return item.isCurated !== true;
                    });
                    var curated = _.filter(vm.stories, function(item) {
                        return item.isCurated === true;
                    });
                    var chunk = vm.getCurationChunkFromStories(notCurated);
                    var total = vm.stories[0].totalCount - 1 - curated.length;
                    curationModal.new(sessionService.getProperty(spacesService.getPropertyUuid()), guestUUID, true, chunk, total, vm.nextChunk);
                };

                vm.getNotCurated = function() {
                    return _.filter(vm.stories, function(item) {
                        return item.isCurated !== true;
                    });
                };

                vm.nextChunk = function() {
                    vm.setPageSizeForCurationRecentPhotos();
                    return vm.load(angular.extend({}, vm.filter.query, {SearchText: vm.searchText}))
                        .then(function(data) {
                            addPhotoUrl(data.stories);
                            vm.stories.push.apply(vm.stories, data.stories);
                            $scope.$emit("curation-controller.counters.contest-submissions", {
                                total: vm.getTotal(),
                                isRecentOnly: vm.isRecentOnly
                            });
                            return data.stories;
                        })
                        .then(function(stories) {
                            return vm.getCurationChunkFromStories(stories);
                        });
                };

                /**
                 * Returns total number of stories
                 * @returns {*}
                 */
                vm.getTotal = function() {
                    if ( !!vm.stories && vm.stories.length > 0 ){
                        var curated = _.filter(vm.stories, function(story) {
                            return story.isCurated;
                        }).length;
                        return vm.stories[0].totalCount - curated;
                    }

                    return 0;
                };

                vm.loadOlderSubmissions = function() {
                    vm.scrollLoadsResetCounter = 0;
                    vm.stories = [];
                    vm.filter = angular.copy(vm.filters[1]);
                    return vm.load(angular.extend({}, vm.filter.query, {SearchText: vm.searchText}))
                        .then(function(data) {
                            return angular.isArray(data.stories) ? data.stories : [];
                        });
                };

                vm.showOlderSubmissions = function() {
                    vm.stories = vm.olderStories;
                    delete vm.olderStories;
                    addPhotoUrl(vm.stories);
                    vm.isRecentOnly = false;
                    $scope.$emit("curation-controller.counters.contest-submissions", {
                        total: vm.getTotal(),
                        isRecentOnly: vm.isRecentOnly
                    });
                };

                curationModal.on("curated", function(guestUUID) {
                    var stories = _.filter(vm.stories, function(story) {
                        return story.promoter.guestUUID == guestUUID;
                    });
                    _.each(stories, function(story) {
                        story.isCurated = true;
                    });
                    if ( stories.length > 0 ){
                        clearCurated();
                        attemptToGetOlderSubmissions();
                    }
                    $scope.$emit("curation-controller.counters.contest-submissions", {
                        total: vm.getTotal(),
                        isRecentOnly: vm.isRecentOnly
                    });
                });

                curationModal
                    .on('photo-updated', function(photoUUID) {
                        var stories = _.filter(vm.stories, function(story) {
                            return story.photo && story.photo.photoUUID == photoUUID;
                        });
                        _.each(stories, function(story) {
                            if ( story.photo ){
                                story.photo.isUpdated = true;
                            }
                        });
                        addPhotoUrl(stories);
                    });

                $scope.$on("bulk-curation.start", function(evnt, args) {
                    if ( args.hasQuoteOnly ) return;
                    vm.curateBulkFromEmailLink(args.advocateUUID);
                });

                if ( vm.isCuration ){
                    vm.changeFilter(vm.filters[0])
                        .then(function(stories) {
                            if ( stories.length === 0 ){
                                vm.loadOlderSubmissions()
                                    .then(function(olderStories) {
                                        vm.olderStories = olderStories;
                                        vm.hasOlderStories = olderStories.length > 0;
                                    })
                            }
                        });
                } else {
                    $q.all([vm.changeFilter(vm.filters[0]), vm.getCommonKeywords()])
                        .then(function(datas) {
                            vm.commonKeywords = datas[1];
                        });
                }

                vm.getCurationChunkFromStories = function(stories) {
                    return _.map(stories, function(story) {
                        return story.promoter.guestUUID
                    });
                }

                function addPhotoUrl(stories) {
                    _.each(stories, function(story) {
                        if ( story.photo ){
                            story.photo.photoUrl = ftAzureStoredPhoto(story.photo, 'small');
                        }
                    });
                }

                function clearCurated() {
                    if ( !vm.hasMoreData ){
                        var hasAnythingElseToCurate = _.some(vm.stories, function(story) {
                            return !story.isCurated;
                        });
                        if ( !hasAnythingElseToCurate ){
                            vm.stories.splice(0, vm.stories.length);
                        }
                    }
                }

                function attemptToGetOlderSubmissions() {
                    if ( vm.isRecentOnly && vm.stories.length == 0 ){
                        vm.loadOlderSubmissions()
                            .then(function(olderStories) {
                                vm.olderStories = olderStories;
                                vm.hasOlderStories = olderStories.length > 0;
                            })
                    } else if ( !vm.isRecentOnly && vm.stories.length == 0 ){
                        vm.hasOlderStories = false;
                    }
                }
            }
        ]);

