/**
 * Created by ostapenko.r on 7/22/2014.
 */

angular
    .module("flipto.components.common.languageSelector", ['flipto.core.language'])
    .directive("ftLanguageSelector",
    [
        '_', 'defaultLanguage', function (_, defaultLanguage) {
        return {
            restrict: "E",
            templateUrl: '/app/components/common/language-selector/languageSelector.html',
            scope: {
                languages: '=',
                selectedLanguage: '=',
                onChange: '&?'
            },
            link: function (scope, element, attrs) {

                scope.$watchCollection('languages', function () {
                    if (_.isEmpty(scope.selectedLanguage)) {
                        scope.selectedLanguage = _.find(scope.languages, { code: defaultLanguage });
                    }
                });

                scope.selectLanguage = function (language) {
                    scope.selectedLanguage = language;
                    scope.onChange && scope.onChange({language: language});
                };
            }
        }
    }
    ]);