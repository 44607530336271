<ft-modal-editor-wrapper>
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
    <ng-container *ngIf="customContent">
        <div class="ft2-edit-panel -disabled">
            <ng-container *ngTemplateOutlet="customContent"></ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="value?.length && fields?.length">
        <ng-container *ngFor="let model of value; let i = index; trackBy: trackByFn">
            <ng-container *ngFor="let field of fields">
                <ng-container
                    *ngIf="(languageVariationsView && isVariationActive(model?.languageCode))">
                    <ng-container
                        *ngTemplateOutlet="editorTmpl; context: { model: model, field: field, i: i, isVariation: true }"></ng-container>
                </ng-container>

                <ng-container
                    *ngIf="!languageVariationsView">
                    <ng-container
                        *ngTemplateOutlet="editorTmpl; context: { model: model, field: field, i: i, isVariation: false }"></ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-template #editorTmpl let-field="field" let-model="model" let-i="i" let-isVariation="isVariation">
        <div class="ft2-input-container"
             [ngClass]="{'-pure': !model[field?.label] && !model[field?.languageName]}">
            <div *ngIf="model[field?.label]"
                 (click)="ref?.focus()"
                 class="ft2-input-label">
                {{ model[field.label] }}
            </div>
            <div class="d-flex f-nowrap v-center">
                <input class="ft2-input -large"
                       [ngClass]="{'-pure': model[field?.label] || model[field?.languageName]}"
                       #ref
                       #input="ngModel"
                       (ngModelChange)="onModelChange($event, i, field.name)"
                       [ngModel]="model[field.name]"
                       [ngModelOptions]="{standalone: true}"
                       [minlength]="(validationDefinition && validationDefinition[i])?.minLength || (validationDefinition && validationDefinition[0]?.minLength)"
                       [maxlength]="(validationDefinition && validationDefinition[i])?.maxLength || (validationDefinition && validationDefinition[0]?.maxLength)"
                       [min]="(validationDefinition && validationDefinition[i])?.min || (validationDefinition && validationDefinition[0]?.min)"
                       [max]="(validationDefinition && validationDefinition[i])?.max || (validationDefinition && validationDefinition[0]?.max)"
                       [placeholder]="placeholder"
                       [required]="(validationDefinition && validationDefinition[i])?.required || (validationDefinition && validationDefinition[0]?.required)"
                       [pattern]="(validationDefinition && validationDefinition[i])?.pattern || (validationDefinition && validationDefinition[0]?.pattern)"
                       [type]="(validationDefinition && validationDefinition[i])?.type || (validationDefinition && validationDefinition[0]?.type)"
                       [name]="(validationDefinition && validationDefinition[i])?.name || (validationDefinition && validationDefinition[0]?.name)">
                <span class="ft2-input-language"
                      [ngClass]="{ '-clickable': isValueRemovable(i) && isVariation }"
                      *ngIf="(activeVariationsCount > 1 && isVariation) || (value?.length > 1 && !isVariation)">
                            {{ model[field?.languageName] || languageNameByCode(model.languageCode) }}
                    <button *ngIf="isValueRemovable(i) && isVariation"
                            class="ft2-btn-close -inline -round" (click)="removeValue(i, field.name)">
                    </button>
                </span>
            </div>
        </div>

        <div class="ft2-form-error mt-2"
             *ngIf="input?.invalid && input?.touched">
            <p *ngIf="input.errors.required">
                Value is required.
            </p>
            <p *ngIf="input.errors.minlength">
                Value must be at least {{ validationDefinition[i]?.minLength || validationDefinition[0]?.minLength }} characters long.
            </p>
            <p *ngIf="input.errors.maxlength">
                Value must be at most {{ validationDefinition[i]?.maxLength || validationDefinition[0]?.maxLength }} characters long.
            </p>
            <p *ngIf="input.errors.email">
                Value is not valid email address.
            </p>
            <p *ngIf="input.errors.pattern">
                {{
                    validationDefinition[i]?.patternValidationText || validationDefinition[0]?.patternValidationText
                        ? validationDefinition[i]?.patternValidationText || validationDefinition[0]?.patternValidationText
                        : 'Value doesn\'t not match pattern.' + validationDefinition[i]?.pattern || validationDefinition[0]?.pattern
                }}
            </p>
            <p *ngIf="input.errors.min">
                Value can't be less then {{ validationDefinition[i]?.min || validationDefinition[0]?.min }}
            </p>
            <p *ngIf="input.errors.max">
                Value can't be more then {{ validationDefinition[i]?.max || validationDefinition[0]?.max }}
            </p>
        </div>
    </ng-template>
    <div class="ft2-edit-dialog_footer-control" *ngIf="languageVariationsView">
        <ft-language-variations *ngIf="languages?.length"
                                [fieldName]="fields?.[0]?.name"
                                [languages]="languages"
                                [value]="value"
                                (activeVariationsChange)="onActiveVariationChanged($event)">
        </ft-language-variations>
    </div>
</ft-modal-editor-wrapper>
