<ft-authentication-wrapper>
    <ng-container *ngIf="(tokenVerificationLoading$ | async) else verified">
        <p>Verification...</p>
    </ng-container>
    <ng-template #verified>
        <ng-template #changePassword>
            <form class="ft2-auth-container" #form="ngForm" (ngSubmit)="submit()">
                <h1 class="h5 mb-5">Set a new password</h1>
                <ft-change-password-errors [error]="resetPasswordError$ | async"></ft-change-password-errors>
                <div class="ft2-input-wrapper">
                    <input class="ft2-input"
                           #input
                           type="password"
                           name="password"
                           placeholder="New password"
                           ftPasswordStrength
                           [(ngModel)]="password">
                    <ft-password-toggle class="ft2-input-icon" [inputRef]="input"></ft-password-toggle>
                    <ft-password-strength-checklist [inputRef]="input">
                    </ft-password-strength-checklist>
                </div>
                <div class="mt-2">
                    <button class="ft2-btn-primary -large -wide"
                            [disabled]="form.invalid"
                            [ftLoading]="resetPasswordLoading$">
                        Save
                    </button>
                </div>
            </form>
        </ng-template>
        <ft-request-link *ngIf="(tokenVerificationError$ | async) else changePassword"
                         [error]="(forgotPasswordError$ | async)"
                         [loading$]="forgotPasswordLoading$"
                         [isLinkSent]="(forgotPasswordState$ | async) === forgotPasswordStates.EmailSent"
                         (sendLink)="requestLink()">
        </ft-request-link>
    </ng-template>
</ft-authentication-wrapper>

