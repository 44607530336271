import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Transition } from '@uirouter/core';
import { JourneysApiService } from '../../services/journeys-api.service';
import { commitInvitationEmail, loadinvitationEmail, loadStylingInvitationEmail } from '../../store/invitation-email';
import * as fromTouchpoints from '../../store';
import { Observable } from 'rxjs';
import { InvitationEmailSettings, InvitationEmailStylingSettings } from '../../models/invitation-email';

@Component({
  selector: 'ft-invitation-email',
  templateUrl: './invitation-email.component.html',
  styleUrls: ['./invitation-email.component.scss']
})
export class InvitationEmailComponent implements OnInit {
  propertyId: string;
  componentGroupUuid: string;
  options: any;

  selectedInvitationEmailComponent$: Observable<InvitationEmailSettings>;
  stylingInvitationEmailComponent$: Observable<InvitationEmailStylingSettings>;
  selectedInvitationEmailLoading$: Observable<boolean>;
  commitSuccess$: Observable<any>;
  selectedIndex = 0;
  messageTokens = [
    {
      name: 'first',
      value: '{first}'
    },
    {
      name: 'last',
      value: '{last}'
    },
    {
      name: 'property',
      value: '{property}'
    },
    {
      name: 'participation-incentive-short',
      value: '{participation-incentive-short}'
    },
    {
      name: 'share-incentive-short',
      value: '{share-incentive-short}'
    },
    {
      name: 'photo-contest-name',
      value: '{photo-contest-name}'
    },
    {
      name: 'photo-contest-winner-incentive-short',
      value: '{photo-contest-winner-incentive-short}'
    }
  ];

  constructor(
    private store$: Store<fromTouchpoints.State>,
    private transition: Transition,
    private journeysApiService: JourneysApiService,
  ) { }

  ngOnInit() {
    this.propertyId = this.transition.params().propertyId;
    this.componentGroupUuid = this.transition.params().componentGroupUuid;
    this.options = this.transition.options().custom;

    this.store$.dispatch(loadinvitationEmail({
      componentGroupUuid: this.componentGroupUuid,
      propertyId: this.propertyId,
      typeRequest: this.options.type,
      state: this.options.state
    }));
    this.store$.dispatch(loadStylingInvitationEmail({
      componentGroupUuid: this.componentGroupUuid,
      propertyId: this.propertyId,
      typeRequest: this.options.type,
      state: this.options.state
    }));

    this.selectedInvitationEmailComponent$ = this.store$.pipe(select(fromTouchpoints.getInvitationEmailData));
    this.stylingInvitationEmailComponent$ = this.store$.pipe(select(fromTouchpoints.getStylingInvitationEmaillData));
    this.selectedInvitationEmailLoading$ = this.store$.pipe(select(fromTouchpoints.getInvitationEmailLoading));
    this.commitSuccess$ = this.store$.pipe(select(fromTouchpoints.getInvitationEmailCommitSuccess));
  }

  commit(isActive) {
    this.store$.dispatch(commitInvitationEmail({
        componentGroupUuid: this.componentGroupUuid,
        propertyId: this.propertyId,
        isActive: isActive
    }));
  }

  onFormCommit(params) {
    const changes: any = [{
      languageCode: 'en',
      ...params,
    }];
    // this.store$.dispatch(changeConfirmationExperience({componentGroupUuid: this.componentGroupUuid, propertyId: this.propertyId, changes}));
  }

  selectedTemplate(data) {
    return data.templates.item.find(el => el.isDefaultTemplate === '1').name;
  }

}
