<ft-modal-editor-wrapper #modalRef>
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
    <div class="ft-dropzone-uploader-editor_content">
        <ft-dropzone-uploader
                *ngIf="!rerender"
                [transparent]="true"
                [label]="'Drop an image here (or click to upload it)'"
                [uploadedFileUrl]="hasLabel ? value?.[fileKey] : value"
                [multiple]="multiple"
                [accept]="accept"
                [imagePreview]="imagePreview"
                (selected)="onSelect($event)">
        </ft-dropzone-uploader>
        <input *ngIf="hasLabel"
               class="ft2-input -large mt-4"
               [ngModel]="value?.[labelKey]"
               (ngModelChange)="onLabelChange($event)"
               placeholder="Label or short description (optional)">
    </div>
    <button *ngIf="removable"
            class="ft2-edit-dialog_footer-control ft2-btn-light -outline -large px-4 mr-2"
            (click)="onRemoveClicked();modalRef.onClose();">
        Remove
    </button>
</ft-modal-editor-wrapper>
