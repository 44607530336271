<div class="ft2-html-editor" [class.-plain]="plain">
    <div class="ft2-html-editor-container">
        <div class="ft2-html-editor-panel" [class.-inactive]="activeEditorIndex === null" *ngIf="!plain">
            <select class="ft2-select -small -pure mr-2" [style.width]="'110px'" *ngIf="tokens" [(ngModel)]="selectedToken" (ngModelChange)="onTokenSelected($event)">
                <option [ngValue]="null" disabled>
                    Insert token
                </option>
                <option *ngFor="let token of tokens" [ngValue]="token.value">
                    {{token.name}}
                </option>
            </select>
            <button class="ft2-btn-pure p-1" type="button"
                    *ngIf="!plain"
                    [class.text-secondary]="isSourceCodeVisible"
                    (click)="changeFormat()">
                <mat-icon class="icon-md">code</mat-icon>
            </button>
        </div>
        <div class="ft2-html-editor-wrapper"
             [ngClass]="{'-border-top': last, '-has-label': !!field?.label, '-plain': plain && !field?.label}"
             *ngFor="let field of fields; let i = index; let last = last;">
            <ng-container *ngIf="model">
                <ng-container *ngTemplateOutlet="editorsTemplate; context: {model: model[field.name], field: field, index: i}">
                </ng-container>
            </ng-container>
        </div>
        <div *ngIf="!fields" class="ft2-html-editor-wrapper">
            <ng-container *ngTemplateOutlet="editorsTemplate; context: {model: model, index: 0}">
            </ng-container>
        </div>


        <ng-template #editorsTemplate let-model="model" let-field="field" let-i="index">
            <div class="ft2-html-editor_label" *ngIf="field">
                {{field.label}}
            </div>
            <quill-editor
                    *ngIf="!isSourceCodeVisible && visible"
                    [ngModel]="model"
                    class="text-content"
                    (ngModelChange)="onModelChanged($event, field)"
                    [sanitize]="true"
                    [trackChanges]="'all'"
                    [placeholder]="placeholder"
                    [modules]="quillConfig"
                    [formats]="formats"
                    (onBlur)="onBlur(i)"
                    (onFocus)="onFocus(i)"
                    (onEditorCreated)="onQuillEditorCreated($event, i)">
                <div quill-editor-toolbar
                     [hidden]="isSourceCodeVisible"
                     [class.inactive]="activeEditorIndex !== i">
                    <span class="ql-formats" *ngIf="!plain">
                        <button class="ql-bold" [title]="'Bold'"></button>
                        <button class="ql-italic" [title]="'Italic'"></button>
                        <button class="ql-underline" [title]="'Underline'"></button>
                        <button class="ql-link" [title]="'Link'"></button>
                        <button class="ql-list" value="ordered"></button>
                        <button class="ql-list" value="bullet"></button>
                    </span>
                </div>
            </quill-editor>
            <ngx-monaco-editor
                    *ngIf="isSourceCodeVisible"
                    (focus)="onFocus(i)"
                    (paste)="trimUnbreakableSpaceOnPaste($event, field)"
                    (blur)="onBlur(i)"
                    (init)="monacoEditorCreated($event, i);"
                    [ngModel]="model"
                    (ngModelChange)="onModelChanged($event, field)"
                    [options]="monacoOptions">
            </ngx-monaco-editor>
        </ng-template>
    </div>
    <ng-container *ngIf="!fields">
        <div>
            <input [ngModel]="model"
                   [required]="required"
                   [minlength]="minlength"
                   [maxlength]="maxlength"
                   #input="ngModel"
                   type="hidden">
            <div class="ft2-form-error"
                 *ngIf="input.invalid">
                <p>Contains an error:</p>
                <div *ngIf="input.errors.required">
                    Value is required.
                </div>
                <div *ngIf="input.errors.minlength">
                    Value must be at least {{minlength}} characters long.
                </div>
                <div *ngIf="input.errors.maxlength">
                    Value must be at most {{maxlength}} characters long.
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!!fields">
        <div *ngFor="let field of fields; let i=index;">
            <ng-container *ngIf="model">
                <input [ngModel]="model[field.name]"
                       [required]="required"
                       [minlength]="minlength"
                       [maxlength]="maxlength"
                       #input="ngModel"
                       type="hidden">
                <div class="ft2-form-error"
                     *ngIf="input.invalid">
                    <p>{{field.label}} contains an error:</p>
                    <div *ngIf="input.errors.required">
                        Value is required.
                    </div>
                    <div *ngIf="input.errors.minlength">
                        Value must be at least {{minlength}} characters long.
                    </div>
                    <div *ngIf="input.errors.maxlength">
                        Value must be at most {{maxlength}} characters long.
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <p class="text-right solid-text muted-text p-1 pb-2 mr-2" *ngIf="model?.language">
        <small class="uppercase muted-text">{{model.language}}</small>
    </p>
    <ng-container *ngIf="!model?.language">
        <ng-container *ngFor="let field of fields; let first = first;">
            <p class="text-right solid-text muted-text p-1 pb-2 mr-2"
               *ngIf="first">
                <small class="uppercase muted-text">{{model && model[field.languageName]}}</small>
            </p>
        </ng-container>
    </ng-container>
</div>
