/**
 * Created by roman.kupin on 10/3/2017.
 */



export const NAME = "ftSection";

export const DEFINITION = {
    scope: true,
    transclude: true,
    template: `
        <ng-transclude ng-if="$ftSection.isActive"></ng-transclude>
    `,
    link: function SectionLinkFn(scope, elem, attrs, ctrl) {
        scope.$watch(() => scope.$ftSections && scope.$ftSections.registry.find(section => section.name === (attrs.ftSection || attrs.name)), (section) => {
            //elem[section.isActive ? "removeClass" : "addClass"]("ng-hide");
            scope.$ftSection = section;
        }, true);
    }
};

