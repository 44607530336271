/**
 * Created by Roman on 2014-09-30.
 */


angular.module('flipto.core.api.photoAdvocacy', ['flipto.core.language', 'flipto.core.parse'])
    .config(['$provide', function ($provide) {

        $provide.factory('PhotoContest', ['urlFormatter', '$http', 'languageHelper', 'parse', '$rootScope', 'fileDownloader', function (urlFormatter, $http, languageHelper, parse, $rootScope, fileDownloader) {

            function PhotoContest() {
                this.load = function (model) {
                    return $http.get(urlFormatter.format('/api/:propertyUuid/properties/layers/photocontest/contests/:contestId/data', model))
                        .then(function (response) {
                            languageHelper.sortByDefaultLanguage(response.data.languages);
                            return response.data;
                        });
                };
                this.past = function (propertyId) {
                    return $http.get(urlFormatter.format('/api/:propertyUUID/properties/layers/:propertyUUID/photocontest/contests/load/past', { 'propertyUUID': propertyId }))
                        .then(function (response) {
                            return response.data;
                        });
                };
                this.future = function (propertyId) {
                    return $http.get(urlFormatter.format('/api/:propertyUUID/properties/layers/:propertyUUID/photocontest/contests/load/future', { propertyUUID: propertyId }))
                        .then(function (response) {
                            return response.data;
                        });
                };

                this.update = function (propertyUuid, contestId, model) {
                    return $http.put(urlFormatter.format('/api/:propertyUuid/properties/layers/photocontest/contests/:contestId/data', { contestId: contestId, propertyUuid: propertyUuid }), model)
                        .then(function (response) {
                            return response.data;
                        });
                };

                this.submissions = function (propertyUuid, contestId) {
                    return $http.get(urlFormatter.format('/api/:propertyUuid/properties/layers/photocontest/contests/:contestId/submissions', { contestId: contestId, propertyUuid: propertyUuid }))
                        .then(function (response) {
                            if (!response.data) return;
                            var contest = response.data;
                            setupContest(contest);
                            contest.submissions && angular.forEach(contest.submissions.item, setupSubmission);
                            return contest;
                        });
                };

                this.submissionsWithPaging = function (propertyUuid, contestId, paging) {
                    var params = angular.extend({ contestId: contestId, propertyUuid: propertyUuid }, paging);
                    return $http.get(urlFormatter.format('/api/:propertyUuid/properties/layers/photocontest/contests/:contestId/submissions-paging', params, true))
                        .then(function (response) {
                            if (!response.data) return;
                            var contest = response.data;
                            setupContest(contest);
                            contest.submissions && angular.forEach(contest.submissions.item, setupSubmission);
                            return contest;
                        });
                };

                this.photo = function (propertyUuid, photoId) {
                    return $http.get(urlFormatter.format('/api/:propertyUuid/properties/layers/photocontest/contests/photos/:photoId', { 'photoId': photoId, propertyUuid: propertyUuid }))
                        .then(function (response) {
                            setupPhoto(response.data);
                            return response.data;
                        });
                };

                this.finalists = function (propertyUuid, contestId) {
                    return $http.get(urlFormatter.format('/api/:propertyUuid/properties/layers/photocontest/contests/:contestId/finalists', { contestId: contestId, propertyUuid: propertyUuid }))
                        .then(function (response) {
                            return response.data;
                        });
                };

                this.finalistsWithPaging = function (propertyUuid, contestId, paging) {
                    var params = angular.extend({ contestId: contestId, propertyUuid: propertyUuid }, paging);
                    return $http.get(urlFormatter.format('/api/:propertyUuid/properties/layers/photocontest/contests/:contestId/finalists-paging', params, true))
                        .then(function (response) {
                            return response.data;
                        });
                };

                this.create = function (propertyId, model) {
                    return $http.post(urlFormatter.format('/api/:propertyId/properties/:propertyId/layers/photocontest/contests/create', { propertyId: propertyId }), model)
                        .then(function (response) {
                            return response.data.replace(/\"/gi, '');
                        });
                };

                this.clone = function (propertyId, model) {
                    return $http.post(urlFormatter.format('/api/:propertyId/properties/:propertyId/layers/photocontest/contests/clone', { propertyId: propertyId }), model)
                        .then(function (response) {
                            return response.data.replace(/\"/gi, '');
                        });
                };

                this.activate = function (propertyUuid, contestId, isActive) {
                    return $http.put(urlFormatter.format('/api/:propertyUuid/properties/layers/photocontest/contests/:contestId/activate/:isActive', { contestId: contestId, isActive: isActive, propertyUuid: propertyUuid }))
                        .then(function (response) {
                            return response.data;
                        });
                };

                this.updateWinners = function (propertyUuid, contestId, winners) {
                    return $http.put(urlFormatter.format('/api/:propertyUuid/properties/layers/photocontest/contests/:contestId/winners/update', { contestId: contestId, propertyUuid: propertyUuid }), winners)
                        .then(function (response) {
                            return response.data;
                        });
                };

                this.closeContest = function (propertyUuid, contestId) {
                    return $http.put(urlFormatter.format('/api/:propertyUuid/properties/layers/photocontest/contests/:contestId/close', { contestId: contestId, propertyUuid: propertyUuid }))
                        .then(function (response) {
                            return response.data;
                        });
                };

                this.cleanContestCheaters = function (propertyUuid, contestId, cutoffDate) {
                    return $http.put(urlFormatter.format('/api/:propertyUuid/properties/layers/photocontest/contests/:contestId/cleanCheaters', { contestId: contestId, cutoffDate: cutoffDate, propertyUuid: propertyUuid }, true))
                        .then(function (response) {
                            return response.data;
                        });
                };

                /**
                 * Rate photo
                 * @param photoUUID
                 * @param rating
                 * @returns {*}
                 */
                this.ratePhoto = function (propertyUuid, photoUUID, rating) {
                    return $http.put(urlFormatter.format('/api/:propertyUuid/properties/layers/photo-advocacy/contests/photos/:photoUUID/internal-rating/set', { photoUUID: photoUUID, propertyUuid: propertyUuid }), rating)
                        .then(function (response) {
                            return response.data;
                        });
                };

                /**
                 * Rate caption
                 * @param photoUUID
                 * @param rating
                 * @returns {*}
                 */
                this.rateCaption = function (propertyUuid, photoUUID, rating) {
                    return $http.put(urlFormatter.format('/api/:propertyUuid/properties/layers/photo-advocacy/contests/photos/:photoUUID/caption-rating/set', { photoUUID: photoUUID, propertyUuid: propertyUuid }), rating)
                        .then(function (response) {
                            return response.data;
                        });
                };

                /**
                 * Rate quote
                 * @param propertyUUID
                 * @param guestUUID
                 * @param rating
                 * @returns {*}
                 */
                this.rateQuote = function (propertyUUID, guestUUID, rating) {
                    return $http.put(urlFormatter.format('/api/:propertyUUID/properties/:propertyUUID/guests/:guestUUID/quote/rating', { propertyUUID: propertyUUID, guestUUID: guestUUID }), rating)
                        .then(function (response) {
                            return response.data;
                        });
                };

                this.setPhotoInfo = function (propertyUuid, photoId, model) {
                    return $http.put(urlFormatter.format('/api/:propertyUuid/properties/layers/photo-advocacy/contests/photos/:photoId/contest-photo-info/set', { photoId: photoId, propertyUuid: propertyUuid }), model)
                        .then(function (response) {
                            return response.data;
                        });
                };

                this.reprocessPhoto = function(propertyUuid, photoUuid){
                    return $http.post(urlFormatter.format('/api/:propertyUuid/properties/layers/photo-advocacy/contests/photos/:photoUuid/reprocess', { photoUuid: photoUuid, propertyUuid: propertyUuid }))
                        .then(function (response) {
                            return response.data;
                        });
                }

                this.details = function (propertyUUID, guestUUID) {
                    return $http.get(urlFormatter.format('/api/:propertyUUID/properties/:propertyUUID/guests/:guestUUID/curation', { propertyUUID: propertyUUID, guestUUID: guestUUID }))
                        .then(function (response) {
                            return angular.copy(response.data);
                        })
                        .then(function (curation) {
                            if (curation.guest.poststay.photo) {
                                setupSubmission(curation.guest.poststay.photo);
                            }
                            curation.isFinalistEmailActive = parse.bool(curation.isFinalistEmailActive);
                            curation.socialNetworksSharing = parse.arrayOrDefault(curation.socialNetworksSharing);

                            // Post-processing to make the ai tags more usable
                            if (curation.guest.poststay.photo) {
                                var photo = curation.guest.poststay.photo;
                                photo.ai = photo.ai || {};

                                // Loop through tag categories
                                for (var i = 0; i < photo.ai.aiTagCategories.length; i++) {
                                    var category = photo.ai.aiTagCategories[i];

                                    // Save any tags found in a new property named after the category attribute
                                    if (category.aiTags) {
                                        curation.guest.poststay.photo.ai[category['@category']] = parse.arrayOrDefault(category.aiTags);
                                    }
                                }
                                delete curation.guest.poststay.photo.ai.aiTagCategories;

                                // Also parse the list of faces
                                photo.ai.faces = parse.arrayOrDefault(photo.ai.faces);
                            }
                            return curation;
                        });
                };

                this.advanceToFinal = function (propertyUuid, photoId) {
                    return $http.put(urlFormatter.format('/api/:propertyUuid/properties/layers/photo-advocacy/contests/photos/:photoId/finalist/set', { photoId: photoId, propertyUuid: propertyUuid }), true)
                        .then(function (response) {
                            return response.data;
                        });
                };

                this.pass = function (propertyUuid, photoId) {
                    return $http.put(urlFormatter.format('/api/:propertyUuid/properties/layers/photo-advocacy/contests/photos/:photoId/finalist/set', { photoId: photoId, propertyUuid: propertyUuid }), false)
                        .then(function (response) {
                            return response.data;
                        });
                };

                this.updateBackgroundImage = function (propertyUuid, contestId, image) {
                    return $http.put(urlFormatter.format('/api/:propertyUuid/properties/layers/photocontest/contests/:contestId/update-background-image', { contestId: contestId, propertyUuid: propertyUuid }), {
                        backgroundImage: image
                    })
                        .then(function (response) {
                            return response.data;
                        });
                };

                this.generatePreArrivalEmailImage = function (propertyUuid, contestId, culture) {
                    return $http.post(urlFormatter.format('/api/:propertyUuid/properties/layers/photo-advocacy/contests/:contestId/generate-pre-arrival-image', { contestId: contestId, languageCode: culture, propertyUuid: propertyUuid }, true))
                        .then(function (response) {
                            return response.data;
                        });
                };

                this.generatePoststayEmailImage = function (propertyUuid, contestId, culture) {
                    return $http.post(urlFormatter.format('/api/:propertyUuid/properties/layers/photo-advocacy/contests/:contestId/generate-poststay-image', { contestId: contestId, languageCode: culture, propertyUuid: propertyUuid }, true))
                        .then(function (response) {
                            return response.data;
                        });
                };

                /**
                 * Issue create curation share image request
                 * @param model
                 * @returns {*}
                 */
                this.createCurationShareImage = function (propertyUuid, model) {
                    return $http.post(urlFormatter.format('/api/' + propertyUuid + '/social-network-sharing/create-curation-image', model), model)
                        .then(function (response) {
                            return response.data;
                        });
                };

                this.createInvitationShareImage = function (propertyUuid, model) {
                    return $http.post(urlFormatter.format('/api/' + propertyUuid + '/social-network-sharing/create-contest-invitation-image', model), model)
                        .then(function (response) {
                            return response.data;
                        });
                };

                this.createInvitationShareImageFromTemplate = function (propertyUuid, model) {
                    return $http.post(urlFormatter.format('/api/' + propertyUuid + '/social-network-sharing/create-contest-invitation-image-from-template', model), model)
                        .then(function (response) {
                            return response.data;
                        });
                };

                this.getInvitationShareImageTemplate = function (propertyUuid, model) {
                    return $http.post(urlFormatter.format('/api/' + propertyUuid + '/social-network-sharing/contest-invitation-image-template', model), model)
                        .then(function (response) {
                            return response.data;
                        });
                };

                /**
                 * Download curation share image
                 * @param imageUrl
                 * @returns {*}
                 */
                this.downloadShareImage = function (propertyUuid, imageUrl) {
                    return $http.get(urlFormatter.format('/api/:propertyUuid/social-network-sharing/download-image', { imageUrl: imageUrl, propertyUuid: propertyUuid }, true), { responseType: 'blob' })
                        .then(function (response) {
                            return {
                                content: response.data,
                                fileName: fileDownloader.getFileNameFromHeaders(response)
                            };
                        })
                };
            }

            function setupContest(contest) {
                var intProperties = ['totalFinalists', 'totalFinalistsScore', 'totalNewPhotoSubmissions', 'totalInviteePhotoSubmissions', 'totalPhotoSubmissions', 'totalRunnerUps', 'totalWinners'];
                _.forEach(intProperties, function (property) {
                    contest[property] = parse.intOrDefault(contest[property]);
                });
                languageHelper.sortByDefaultLanguage(contest.languages);
                return contest;
            }

            function setupPhoto(photo) {
                var intProperties = ['quoteInternalRating', 'quoteRating'];
                _.forEach(intProperties, function (property) {
                    photo[property] = parse.intOrDefault(photo[property]);
                });
                return photo;
            }


            function setupSubmission(submission) {
                var boolProperties = ['isNewSubmission', 'isUpdated', 'isFinalist', 'isPassed'];
                _.forEach(boolProperties, function (property) {
                    submission[property] = parse.bool(submission[property]);
                });
                return submission;
            }


            return new PhotoContest();

        }]);

    }]);


