/**
 * Created by roman.kupin on 7/30/2018.
 */

declare const angular;
const TEMPLATE_URL = require("./expandableContent.html");

export const NAME = "ftExpandableContent";


export class ExpandableContentDirectiveController {

    static $inject = ["$timeout", "$element", "$transclude", "$compile"];

    constructor(public $timeout,
        public $element,
        public $transclude,
        public $compile) {

        $transclude(function (tElem, tScope) {
            const panels = Array.from(tElem[0].querySelectorAll("panel"));
            if (!!panels.length) {
                const secondaryAreaEl = angular.element(`<div class="ft-secondary-area"></div>`)[0];

                panels.forEach((panel:any) => {
                    const panelEl = angular.element(`<div class="ft-panel"><div class="ft-panel-bg"></div><div class="ft-panel-content"></div></div>`)[0];
                    Array.from(panel.attributes).forEach((attr: { name: string, value: string }) => {
                        if (attr.name === "class") {
                            panelEl.classList.add(...attr.value.split(" "));
                        } else {
                            panelEl.setAttribute(attr.name, attr.value);
                        }
                    });

                    Array.from(panel.children).forEach(childEl => {
                        panelEl.querySelector(".ft-panel-content").appendChild(childEl);
                    });
                    secondaryAreaEl.appendChild(panelEl);
                });

                $element[0].querySelector(".ft-expandable-content").appendChild($compile(secondaryAreaEl)(tScope)[0]);
            }
        }, null, "secondary");
    }

    onAfterExpanded: Function | undefined;
    onAfterCollapsed: Function | undefined;
    expandableBgImage: string | undefined;
    collapsedBgImage: string | undefined;

    isExpanded = false;
    isBeingCollapsed = false;

    expand() {
        this.isExpanded = true;
        this.executeCallback(this.onAfterExpanded);
    }

    collapse($event) {
        $event.stopPropagation();
        this.isExpanded = false;
        this.isBeingCollapsed = true;
        this.executeCallback(this.onAfterCollapsed);
        this.$timeout(() => {
            this.isBeingCollapsed = false;
        }, 2000);
    }

    private isCallbackDefined(callback: any) : boolean {
        return typeof(callback) === "function";
    }

    private executeCallback(callback: any) {
        if(this.isCallbackDefined(callback)) {
            callback();
        }
    }

}

export const DEFINITION = [function () {
    return {
        templateUrl: TEMPLATE_URL,
        transclude: {
            collapsed: "collapsed",
            primary: "?primary",
            secondary: "?secondary",
            custom: "?custom"
        },
        scope: {
            expandableBgImage: "<?",
            collapsedBgImage: "<?",
            onAfterExpanded: "&?",
            onAfterCollapsed: "&?"
        },
        controller: ExpandableContentDirectiveController,
        controllerAs: "vm",
        bindToController: true
    }
}];
