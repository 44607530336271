import { FlowStep } from "..";
import { BroadcastModalService } from "../../broadcast/broadcastModal.service";

export class StoryModalFlowController {

    static $inject = [
        "renderer",
        "ftModal",
        "curationModal",
        "StoryModal",
        "BroadcastModal",

        "propertyUuid",
        "storyUuid",
        "steps",
        "recommendation",
        "isRecommendationExpanded",
        "hasOtherRecommendations"
    ];

    data: any;

    constructor(
        public renderer,
        public ftModal,
        public curationModal,
        public StoryModal,
        public BroadcastModal: BroadcastModalService,

        public propertyUuid,
        public storyUuid,
        public steps: FlowStep[],
        public recommendation,
        public isRecommendationExpanded: boolean,
        public hasOtherRecommendations: boolean) { }

    close() {
        this.renderer.remove();
    }

    curation(storyUuid, property) {
        this.curationModal.openFromStoryFlow(property, storyUuid);
    }

    broadcast(storyUuid, propertyUuid) {
        this.BroadcastModal.broadcastStoryFromStoryModal(propertyUuid, storyUuid);
    }

    recommend(storyUuid, propertyUuid) {
        this.BroadcastModal.buildBroadcastStoryRecommendation(propertyUuid, storyUuid);
    }

    next() {
        const idx = this.steps.findIndex(s => s.isActive);
        this.steps.forEach((s, i) => s.isActive = i === idx + 1);
    }
}