/**
 * Created by ostapenko.r on 1/16/2015.
 */

angular.module('flipto.components.common.map', [])
    .filter('map', function () {
        return function (items, propName) {
            if (angular.isArray(items)) {
                return items.map(function (item) {
                    return item[propName];
                });
            }
        };
    })
    .filter('reverse', function () {
        return function (items) {
            if (angular.isArray(items)) {
                return items.slice().reverse();
            }
        };
    });