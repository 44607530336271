/**
 * Created by roman.kupin on 11/10/2017.
 */

import { IGoToQuery } from "../api/toQuery.interface";


export const PREFIX = "[GA_REPORTS_API]";

export const TRAFFIC_SOURCES_FETCH = `${PREFIX}.TrafficSources`;
export const TRAFFIC_SOURCES_FETCH_SUCCESS = `${TRAFFIC_SOURCES_FETCH}Success`;
export const TRAFFIC_SOURCES_FETCH_ERROR = `${TRAFFIC_SOURCES_FETCH}Error`;

export const GENDER_FETCH = `${PREFIX}.Gender`;
export const GENDER_FETCH_SUCCESS = `${GENDER_FETCH}Success`;
export const GENDER_FETCH_ERROR = `${GENDER_FETCH}Error`;

export const VISITORS_BY_COUNTRY_FETCH = `${PREFIX}.VisitorsByCountry`;
export const VISITORS_BY_COUNTRY_FETCH_SUCCESS = `${VISITORS_BY_COUNTRY_FETCH}Success`;
export const VISITORS_BY_COUNTRY_FETCH_ERROR = `${VISITORS_BY_COUNTRY_FETCH}Error`;

export const VISITORS_BY_REGION_FETCH = `${PREFIX}.VisitorsByRegion`;
export const VISITORS_BY_REGION_FETCH_SUCCESS = `${VISITORS_BY_REGION_FETCH}Success`;
export const VISITORS_BY_REGION_FETCH_ERROR = `${VISITORS_BY_REGION_FETCH}Error`;

export const AGE_BRACKET_FETCH = `${PREFIX}.AgeBracket`;
export const AGE_BRACKET_FETCH_SUCCESS = `${AGE_BRACKET_FETCH}Success`;
export const AGE_BRACKET_FETCH_ERROR = `${AGE_BRACKET_FETCH}Error`;

export const INTERESTS_FETCH = `${PREFIX}.Interests`;
export const INTERESTS_FETCH_SUCCESS = `${INTERESTS_FETCH}Success`;
export const INTERESTS_FETCH_ERROR = `${INTERESTS_FETCH}Error`;

export const PROPERTY_CONVERSIONS_FETCH = `${PREFIX}.PropertyConversions`;
export const PROPERTY_CONVERSIONS_FETCH_SUCCESS = `${PROPERTY_CONVERSIONS_FETCH}Success`;
export const PROPERTY_CONVERSIONS_FETCH_ERROR = `${PROPERTY_CONVERSIONS_FETCH}Error`;

export const INTEGRATED_PARTNERS_FETCH = `${PREFIX}.PropertyConversions`;
export const INTEGRATED_PARTNERS_FETCH_SUCCESS = `${INTEGRATED_PARTNERS_FETCH}Success`;
export const INTEGRATED_PARTNERS_FETCH_ERROR = `${INTEGRATED_PARTNERS_FETCH}Error`;

export const REVENUE_FETCH = `${PREFIX}.Revenue`;
export const REVENUE_FETCH_SUCCESS = `${REVENUE_FETCH}Success`;
export const REVENUE_FETCH_ERROR = `${REVENUE_FETCH}Error`;

export const FILTER_REVENUE_SOURCE_FETCH = `${PREFIX}.FilterRevenueSource`;
export const FILTER_REVENUE_SOURCE_FETCH_SUCCESS = `${FILTER_REVENUE_SOURCE_FETCH}Success`;
export const FILTER_REVENUE_SOURCE_FETCH_ERROR = `${FILTER_REVENUE_SOURCE_FETCH}Error`;

export const COUNTRY_REVENUE_FETCH = `${PREFIX}.CountryRevenue`;
export const COUNTRY_REVENUE_FETCH_SUCCESS = `${COUNTRY_REVENUE_FETCH}Success`;
export const COUNTRY_REVENUE_FETCH_ERROR = `${COUNTRY_REVENUE_FETCH}Error`;

// export function getTrafficSources(companyUUID, startDate, endDate) {
//     return (dispatch, getState, { $http, environment }) => {
//         dispatch({ type: TRAFFIC_SOURCES_FETCH });
//         return $http.get(`${environment.apiBaseUrl}/ga-reports/traffic-sources?companyUUID=${companyUUID}&startDate=${startDate}&endDate=${endDate}`)
//             .then(response => {
//                 dispatch({ type: TRAFFIC_SOURCES_FETCH_SUCCESS, payload: response.data });
//                 return response.data;
//             }, error => {
//                 dispatch({ type: TRAFFIC_SOURCES_FETCH_ERROR, payload: error });
//                 throw TRAFFIC_SOURCES_FETCH_ERROR;
//             })
//     }
// }

// export function getGender(companyUUID, startDate, endDate) {
//     return (dispatch, getState, { $http, environment }) => {
//         dispatch({ type: GENDER_FETCH });
//         return $http.get(`${environment.apiBaseUrl}/ga-reports/gender?companyUUID=${companyUUID}&startDate=${startDate}&endDate=${endDate}`)
//             .then(response => {
//                 dispatch({ type: GENDER_FETCH_SUCCESS, payload: response.data });
//                 return response.data;
//             }, error => {
//                 dispatch({ type: GENDER_FETCH_ERROR, payload: error });
//                 throw GENDER_FETCH_ERROR;
//             })
//     }
// }

// export function getVisitorsByCountry(companyUUID, startDate, endDate) {
//     return (dispatch, getState, { $http, environment }) => {
//         dispatch({ type: VISITORS_BY_COUNTRY_FETCH });
//         return $http.get(`${environment.apiBaseUrl}/ga-reports/visitors-by-country?companyUUID=${companyUUID}&startDate=${startDate}&endDate=${endDate}`)
//             .then(response => {
//                 dispatch({ type: VISITORS_BY_COUNTRY_FETCH_SUCCESS, payload: response.data });
//                 return response.data;
//             }, error => {
//                 dispatch({ type: VISITORS_BY_COUNTRY_FETCH_ERROR, payload: error });
//                 throw VISITORS_BY_COUNTRY_FETCH_ERROR;
//             })
//     }
// }

// export function getVisitorsByRegion(companyUUID, country, startDate, endDate) {
//     return (dispatch, getState, { $http, environment }) => {
//         dispatch({ type: VISITORS_BY_REGION_FETCH });
//         return $http.get(`${environment.apiBaseUrl}/ga-reports/visitors-by-region?companyUUID=${companyUUID}&country=${encodeURIComponent(country)}&startDate=${startDate}&endDate=${endDate}`)
//             .then(response => {
//                 dispatch({ type: VISITORS_BY_REGION_FETCH_SUCCESS, payload: response.data });
//                 return response.data;
//             }, error => {
//                 dispatch({ type: VISITORS_BY_REGION_FETCH_ERROR, payload: error });
//                 throw VISITORS_BY_REGION_FETCH_ERROR;
//             })
//     }
// }

// export function getAgeBracket(companyUUID, startDate, endDate) {
//     return (dispatch, getState, { $http, environment }) => {
//         dispatch({ type: AGE_BRACKET_FETCH });
//         return $http.get(`${environment.apiBaseUrl}/ga-reports/age-bracket?companyUUID=${companyUUID}&startDate=${startDate}&endDate=${endDate}`)
//             .then(response => {
//                 dispatch({ type: AGE_BRACKET_FETCH_SUCCESS, payload: response.data });
//                 return response.data;
//             }, error => {
//                 dispatch({ type: AGE_BRACKET_FETCH_ERROR, payload: error });
//                 throw AGE_BRACKET_FETCH_ERROR;
//             })
//     }
// }

// export function getInterests(companyUUID, startDate, endDate) {
//     return (dispatch, getState, { $http, environment }) => {
//         dispatch({ type: INTERESTS_FETCH });
//         return $http.get(`${environment.apiBaseUrl}/ga-reports/interests?companyUUID=${companyUUID}&startDate=${startDate}&endDate=${endDate}`)
//             .then(response => {
//                 dispatch({ type: INTERESTS_FETCH_SUCCESS, payload: response.data });
//                 return response.data;
//             }, error => {
//                 dispatch({ type: INTERESTS_FETCH_ERROR, payload: error });
//                 throw INTERESTS_FETCH_ERROR;
//             })
//     }
// }

// export function getPropertyConversions(companyUUID, startDate, endDate) {
//     return (dispatch, getState, { $http, environment }) => {
//         dispatch({ type: PROPERTY_CONVERSIONS_FETCH });
//         return $http.get(`${environment.apiBaseUrl}/ga-reports/conversion-by-property?companyUUID=${companyUUID}&startDate=${startDate}&endDate=${endDate}`)
//             .then(response => {
//                 dispatch({ type: PROPERTY_CONVERSIONS_FETCH_SUCCESS, payload: response.data });
//                 return response.data;
//             }, error => {
//                 dispatch({ type: PROPERTY_CONVERSIONS_FETCH_ERROR, payload: error });
//                 throw PROPERTY_CONVERSIONS_FETCH_ERROR;
//             })
//     }
// }

export function getIntegratedPartners(companyUUID) {
    return (dispatch, getState, { $http, environment }) => {
        dispatch({ type: INTEGRATED_PARTNERS_FETCH });
        return $http.get(`${environment.apiBaseUrl}/ga-reports/integrated-partners?companyUUID=${companyUUID}`)
            .then(response => {
                dispatch({ type: INTEGRATED_PARTNERS_FETCH_SUCCESS, payload: response.data });
                return response.data;
            }, error => {
                dispatch({ type: INTEGRATED_PARTNERS_FETCH_ERROR, payload: error });
                throw INTEGRATED_PARTNERS_FETCH_ERROR;
            })
    }
}


export class RevenueFilter implements IGoToQuery {

    gaSources: string[];
    gaMediums: string[];
    gaCampaigns: string[];
    locations: string[];
    isSplitByProperty: boolean;

    constructor();
    constructor(gaSources: string[], gaMediums: string[], gaCampaigns: string[], locations: string[], isSplitByProperty: boolean);
    constructor(gaSources: string[] = [], gaMediums: string[] = [], gaCampaigns: string[] = [], locations: string[] = [], isSplitByProperty: boolean = true) {
        this.gaSources = gaSources;
        this.gaMediums = gaMediums;
        this.gaCampaigns = gaCampaigns;
        this.locations = locations;
        this.isSplitByProperty = isSplitByProperty;
    }

    toQuery(): string {
        const query = [];
        if (!!this.gaCampaigns.length) query.push(`gaCampaigns=${encodeURIComponent(this.gaCampaigns.join(","))}`);
        if (!!this.gaSources.length) query.push(`gaSources=${encodeURIComponent(this.gaSources.join(","))}`);
        if (!!this.gaMediums.length) query.push(`gaMediums=${encodeURIComponent(this.gaMediums.join(","))}`);
        if (!!this.locations.length) query.push(`locations=${encodeURIComponent(this.locations.join(","))}`);
        query.push(`isSplitByProperty=${encodeURIComponent(this.isSplitByProperty.toString())}`);
        return query.join("&");
    }


    static Clone(filter: RevenueFilter): RevenueFilter {
        return new RevenueFilter(filter.gaSources.slice(), filter.gaMediums.slice(), filter.gaCampaigns.slice(), filter.locations.slice(), filter.isSplitByProperty);
    }

}

export function getRevenue(companyUUID, startDate, endDate, filter: RevenueFilter) {
    return (dispatch, getState, { $http, environment }) => {
        dispatch({ type: REVENUE_FETCH });
        return $http.get(`${environment.apiBaseUrl}/ga-reports/revenue?companyUUID=${companyUUID}&startDate=${startDate}&endDate=${endDate}&${filter.toQuery()}`)
            .then(response => {
                dispatch({
                    type: REVENUE_FETCH_SUCCESS,
                    payload: { response: response.data, isSplitByProperty: filter.isSplitByProperty }
                });
                return response.data;
            }, error => {
                dispatch({ type: REVENUE_FETCH_ERROR, payload: error });
                throw REVENUE_FETCH_ERROR;
            });
    }
}

const All =
    {
        // getTrafficSources,
        // getGender,
        // getVisitorsByCountry,
        // getVisitorsByRegion,
        // getAgeBracket,
        // getInterests,
        // getPropertyConversions,
        getIntegratedPartners,
        REVENUE_FETCH, REVENUE_FETCH_SUCCESS
    };
export default All;
