
import * as FlowComponent from "./flow.component";
import * as StepComponent from "./step.component";

declare const angular;
export const FLOW_MODULE = angular.module("AccountApp.v2.Components.Flow", []);

export class FlowConfig {

    static $inject = ["$compileProvider"];

    constructor($compileProvider) {
        const components = [FlowComponent/*, StepComponent*/];
        components.forEach(component => {
            $compileProvider.component(component.NAME, component.OPTIONS);
        });

        $compileProvider.directive(StepComponent.NAME, StepComponent.DEFINITION);
    }
}

FLOW_MODULE.config(FlowConfig);
