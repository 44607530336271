/**
 * Created by roman.kupin on 4/25/2018.
 */

angular
    .module("flipto.accountv1.SharedFeatures")
    .controller("flipto.account.sections.settings.PrivacyController", [
        "SettingsPrivacy", "SpacesService", 'IdentityService',
        function (SettingsPrivacy, spacesService, identity) {
            var vm = this;
            vm.identity = identity;
            vm.load = function () {
                return SettingsPrivacy.get(spacesService.getPropertyUuid());
            };

            vm.commitChanges = function (changes) {
                SettingsPrivacy.put(spacesService.getPropertyUuid(), changes);
            };

            vm.togglePrivacy = function () {
                vm.commitChanges({ IsPrivacyEnabled: vm.model.is_privacy_enabled });
            };

            vm.savePrivacyEmail = function () {
                vm.commitChanges({ PrivacyEmail: vm.model.privacy_email });
            };

            vm.savePrivacyUrl = function (changes) {
                var languageChanges = [];
                vm.model.languages.item.forEach(function (language) {
                    languageChanges.push({languageCode: language.code, privacyUrl: language.privacyUrl});
                });
                vm.commitChanges({ languages: languageChanges });
            };

            vm.load()
                .then(function (data) {
                    angular.extend(vm, { model: data });
                });
        }
    ]);