/**
 * Created by Roman on 2014-10-30.
 */


angular
    .module("flipto.components.imageLibrary")
    .config(['$compileProvider', function ($compileProvider) {
        $compileProvider.directive('ftImagePreview', [function () {
            return {
                restrict: 'E',
                require: '?^ftImageLibrary',
                templateUrl: '/app/components/image-library/imagePreview.html',
                scope: {
                    'image': '=',
                    'onUseDifferentImage': '&'
                },
                link: function (scope, elem, attrs, ctrl) {

                    /**
                     * Use different image
                     */
                    scope.useDifferentImage = function () {
                        if (!!ctrl) {
                            ctrl.clearImageSelection();
                        }
                        scope.onUseDifferentImage();
                    };
                }
            };
        }]);
    }]);