/**
 * Created by Roman on 2016-09-02.
 */

angular.module('flipto.components.common.eventsRelated')
    .directive('ftForwardClick', [function () {
        return {
            restrict: "A",
            link: function (scope, elem, attrs) {

                elem.bind("click", function (event) {
                    if (angular.isDefined(attrs.ftForwardClick) && document.querySelector(attrs.ftForwardClick)) {
                        document.querySelector(attrs.ftForwardClick).click();
                    }
                    event && event.stopPropagation();
                });

                scope.$on("$destroy", function () {
                    elem.unbind("click");
                });
            }
        };
    }]);;
