/**
 * User: Kupin.R
 * Date: 5/29/14
 */



angular
    .module("flipto.components.goals")
    .filter("goalDescription",
        [
            "$filter",
            function ($filter) {

                var descriptionIsNotAvailable = $filter("translate")("flipto.components.goals.descriptionIsNotAvailable"),
                    defaultCulture = "en";

                function getDescription(goal, culture) {
                    if (goal.languages) {
                        var language = _.find(goal.languages.item, {code: culture}) || _.find(goal.languages.item, {code: defaultCulture});
                        if (language) {
                            return language.formalText;
                        }
                    }
                    return descriptionIsNotAvailable;
                }

                return function (goal, culture) {
                    return getDescription(goal, culture);
                }
            }
        ]);
