<ft-custom-editor title="New Property"
                  (save)="onSave($event)"
                  [saveSuccess$]="success$"
                  [error$]="error$">
    <div content>
        <div class="ft2-form-group">
            <label>Property name</label>
            <input class="ft2-input -large"
                   type="text"
                   maxlength="100"
                   ngModel
                   name="name"
                   required>
        </div>
        <div class="ft2-form-group">
            <label>Property url</label>
            <input class="ft2-input -large"
                   type="url"
                   maxlength="500"
                   ngModel
                   name="url"
                   ftPrependInput="https://"
                   pattern="https?://.+"
                   #urlControl="ngModel"
                   required>
        </div>
        <div class="ft2-form-error mt-2" *ngIf="urlControl.invalid && urlControl.touched">
            <p>Value is not valid url.</p>
        </div>
        <div class="ft2-form-group">
            <label>Type</label>
            <ng-select [items]="propertyTypes"
                       class="ft2-select -large mb-3"
                       name="type"
                       bindValue="value"
                       bindLabel="label"
                       [searchable]="false"
                       [clearable]="false"
                       [ngModel]="propertyTypes[0].value">
            </ng-select>
        </div>
    </div>
    <ng-content></ng-content>
</ft-custom-editor>

