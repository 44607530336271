/**
 * Created by kevin.tiller on 6/12/2018.
 */

import ContextSyncActions from "../../actions/singlePropertyContext";
import { getPropertyContextsBasedOnSpace } from '../../actions/startup';

const TEMPLATE_URL = require("./contextSelectorLink.html");

export const NAME = "ftContextSelectorLink";

export class ContextSelectorLinkController {

    static $inject = ["$ngRedux", "SessionService", "SpacesService"];

    unsubscribe: any;
    properties: Array<any>;
    isMulti: boolean = false;

    constructor(private $ngRedux,
        sessionService,
        spacesService) {

        this.unsubscribe = $ngRedux.connect(state => {
            const company = sessionService.getCompany(spacesService.getCompanyUuid());
            const properties = getPropertyContextsBasedOnSpace(spacesService.current, sessionService);
            const singleProperty = properties.find(property => property.propertyUuid === spacesService.getPropertyUuid());

            const multipleProperties = state.ui.context.multipleProperties;
            return {
                company,
                singleProperty,
                properties,
                multipleProperties,
                firstInMultipleProperties: !!multipleProperties && !!multipleProperties.length ? properties.find(p => p.propertyUuid === multipleProperties[0]) : null
            };
        }, Object.assign({}, null, ContextSyncActions))(this);
    }

    $onDestroy() {
        this.unsubscribe();
    }
}
export const OPTIONS = {
    templateUrl: TEMPLATE_URL,
    controllerAs: "vm",
    controller: ContextSelectorLinkController,
    bindings: {
        isMulti: "<"
    }
};
