import { Component, Input, OnInit } from '@angular/core';
import { SignInDto } from "../../models/sign-in-dto";
import * as fromAuthentication from "../../store";
import { Observable } from "rxjs";
import { select, Store } from "@ngrx/store";
import { SignInStates } from "../../models/sign-in-states.enum";
import { SignInError } from "../../models/sign-in-error";
import { SignInErrorMessages } from "../../models/sign-in-error-messages.enum";


@Component({
    selector: 'ft-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
    @Input() redirectTo: string;
    loading$: Observable<boolean>;
    error$: Observable<SignInError>;
    state$: Observable<SignInStates>;
    signInStates = SignInStates;
    signInErrorMessages = SignInErrorMessages;

    constructor(private store: Store<fromAuthentication.AuthenticationState>) {
        this.loading$ = this.store.pipe(select(fromAuthentication.isSignInLoading));
        this.error$ = this.store.pipe(select(fromAuthentication.getSignInError));
        this.state$ = this.store.pipe(select(fromAuthentication.getSignInState));
    }

    ngOnInit() {
        this.store.dispatch(fromAuthentication.setSignInRedirectTo({redirectTo: this.redirectTo}));
    }

    signIn(formValue: SignInDto) {
        this.store.dispatch(fromAuthentication.signIn({data: formValue}));

    }

    changeState(state: SignInStates) {
        this.store.dispatch(fromAuthentication.setSignInState({signInState: state}));
    }
}
