/**
 * Created by kevin tiller on 2/25/2016.
 */

angular
    .module("flipto.accountv1.SharedFeatures")
    .filter("confirmationDisplayType",
    [
        "$filter", "_", "confirmationDisplayTypes",
        function ($filter, _, confirmationDisplayTypes) {

            var translate = $filter("translate");

            function getTypeName(type) {
                var displayType = _.find(confirmationDisplayTypes, function (displayType) { return displayType.id == type; }); // Coerce string to number
                if (displayType) {
                    return translate(displayType.name);
                }
                return '';
            }

            return function (type) {
                return getTypeName(type);
            };
        }
    ]);
