/**
 * Created by roman.kupin on 9/28/2017.
 */

import * as PrestayApiActions from "../../actions/api/prestayApi";


export default function prestayReducer(state = {}, {type, payload}) {
    switch (type) {
        case PrestayApiActions.CAMPAIGNS_FETCH_SUCCESS: {
            return Object.assign(state, payload);
        }
        default: {
            return state;
        }
    }
}