/**
 * Created by ostapenko.r on 3/6/2015.
 */
angular.module('flipto.accountv1.SharedFeatures')
    .controller('flipto.account.sections.platform.StylingEmailTemplateController',
        ['$scope', 'touchpointGroupId', 'layerName', 'touchpointType', 'Styling', 'ftModal', 'SpacesService',
            function ($scope, touchpointGroupId, layerName, touchpointType, Styling, ftModal, spacesService) {
                'use strict';
                var vm = this,
                    touchpointGroupId = touchpointGroupId,
                    layerName = $scope.layerName = layerName,
                    touchpointType = $scope.touchpointType = touchpointType;

                Styling.getEmail({
                    propertyUuid: spacesService.getPropertyUuid(),
                    layerName: layerName,
                    touchpointUUID: touchpointGroupId,
                    touchpointType: touchpointType
                }).then(function (data) {
                    vm.templates = data.templates.item;
                    var defaultTemplate = _.find(vm.templates, {isDefaultTemplate: "1"});
                    defaultTemplate && (defaultTemplate.name = defaultTemplate.name + ' (Default)');
                    vm.selectedTemplate = _.find(vm.templates, {id: data.templateUUID}) || defaultTemplate;
                })

                vm.updateEmailTemplate = function () {
                    Styling.putEmail({
                        propertyUuid: spacesService.getPropertyUuid(),
                        layerName: layerName,
                        touchpointUUID: touchpointGroupId,
                        touchpointType: touchpointType,
                        templateUUID: vm.selectedTemplate.isDefaultTemplate === "0" ? vm.selectedTemplate.id : ""
                    });
                };

                vm.showEmailTemplateModal = function () {
                    if (vm.templates && vm.templates.length > 1) {
                        ftModal.show('emailTemplateModal');
                    }
                };

                vm.selectTemplate = function (template) {
                    vm.selectedTemplate = angular.copy(template);
                };
            }
        ]);
