import { Transition } from '@uirouter/core';
import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromTouchpoints from '../../store';
import { Observable } from 'rxjs';
import { LanguagesItem, TouchpointOptions } from '../../models/friend-landing';
import {
  changeFriendLanding,
  commitFriendLanding,
  loadFriendLanding,
  loadIntegrationFriendLanding,
  loadStylingFriendLanding
} from '../../store/friend-landing';

@Component({
  selector: 'ft-friend-landing',
  templateUrl: './friend-landing.component.html',
  styleUrls: ['./friend-landing.component.scss']
})
export class FriendLandingComponent implements OnInit {
  @ViewChild("customTemplate", { read: ViewContainerRef }) container: ViewContainerRef;
  componentRef: ComponentRef<any>;
  // touchpoints params
  propertyId:string;
  componentGroupUuid: string;
  options: TouchpointOptions;
  selectedIndex = 0;

  commitSuccess$: Observable<any>;
  selectedFriendLandingLoading$: Observable<boolean>;
  selectedFriendLandingComponent$: Observable<any>;
  stylingFriendLandingComponent$: Observable<any>;
  integrationFriendLandingComponent$: Observable<any>;

  constructor(
    private store$: Store<fromTouchpoints.State>,
    private transition: Transition,
    private resolver: ComponentFactoryResolver
  ) { }

  ngOnInit() {
    this.propertyId = this.transition.params().propertyId;
    this.componentGroupUuid = this.transition.params().componentGroupUuid;
    this.options = this.transition.options().custom;

    if (this.options.matNav.message) {
      this.store$.dispatch(loadFriendLanding({
        componentGroupUuid: this.componentGroupUuid,
        propertyId: this.propertyId,
        typeRequest: this.options.type,
        state: this.options.state
      }));
      this.selectedFriendLandingComponent$ = this.store$.pipe(select(fromTouchpoints.getFriendLandignData));
    }

    if (this.options.matNav.style) {
      this.store$.dispatch(loadStylingFriendLanding({
        componentGroupUuid: this.componentGroupUuid,
        propertyId: this.propertyId,
        typeRequest: this.options.type,
        state: this.options.state
      }));
      this.stylingFriendLandingComponent$ = this.store$.pipe(select(fromTouchpoints.getStylingFriendLandignData));
    }
    
    if (this.options.matNav.integration) {
      this.store$.dispatch(loadIntegrationFriendLanding({
        componentGroupUuid: this.componentGroupUuid,
        propertyId: this.propertyId,
        typeRequest: this.options.type,
        state: this.options.state
      }));
      this.integrationFriendLandingComponent$ = this.store$.pipe(select(fromTouchpoints.getIntegrationFriendLandignData));
    }
    
    this.selectedFriendLandingLoading$ = this.store$.pipe(select(fromTouchpoints.getFriendLandignLoading));
    this.commitSuccess$ = this.store$.pipe(select(fromTouchpoints.getFriendLandignCommitSuccess));
  }

  commit(isActive) {
    this.store$.dispatch(commitFriendLanding({
        componentGroupUuid: this.componentGroupUuid,
        propertyId: this.propertyId,
        isActive: isActive
    }));
  }

  onFormCommit(params, tab) {
    const changes: any = [{
      languageCode: 'en',
      ...params,
    }];
    this.store$.dispatch(changeFriendLanding({
      componentGroupUuid: this.componentGroupUuid,
      propertyId: this.propertyId,
      typeRequest: this.options.type,
      state: this.options.state,
      changes: changes,
      tab: tab
    }));
  }

  allowToggleLandingPageType(landingPageType: number, items: LanguagesItem[]): boolean {
    switch (landingPageType) {
      case 1:
        return !!items.find(val => {
          return !!val.data.landingPageUrl;
        });
      case 2:
        return !!items.find(val => {
          return !!val.data.landingPageHtml;
        });
      case 3:
        return true;
    }
  }

  createComponent(matNav, data) {
    setTimeout(() => {
      if (this.container) {
        this.container.clear();

        const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(matNav.template);
        this.componentRef = this.container.createComponent(factory);
        this.componentRef.instance.matNav = matNav;
        this.componentRef.instance.data = data;
      }
    });
  }

  selectTab(event, matNav, data) {
    if (event.index === 2) {
      this.createComponent(matNav, data);
    }
  }
}
