/**
 * Created by ostapenko.r on 12/10/2014.
 */


angular.module('flipto.components.common.stats', [])
    .config(['$compileProvider', function ($compileProvider) {

        $compileProvider.directive('ftStat', [function () {
            return {
                restrict: 'E',
                templateUrl: '/app/components/common/stats/stat.html',
                scope:{
                    'value': '@',
                    'label': '@'
                },
                link: function (scope, elem, attrs) {

                }
            }
        }]);


    }]);