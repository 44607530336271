/**
 * Created by ostapenko.r on 1/12/2015.
 */

angular.module('flipto.components.campaigns')
    .directive('ftCampaignList', ['_', 'parse', function (_, parse) {

        return {
            restrict: 'E',
            templateUrl: '/app/components/campaigns/directives/campaignListDirective.html',
            scope: {
                'sortMode': '=',
                'campaigns': '=',
                'layerType': '@',
                'onCampaignSelected': '&',
                'onCampaignSortChanged': '&?'
            },
            link: function (scope) {

                /**
                 * Select campaign
                 * @param campaign
                 */
                scope.selectCampaign = function (campaign) {
                    if(scope.sortMode) return;
                    scope.onCampaignSelected({id: campaign.id});
                };

                scope.canBeMovedDown = function(campaign) {
                    var currentIndex = scope.campaigns.indexOf(campaign);
                    var nextCampaign = scope.campaigns[currentIndex+1];
                    return nextCampaign && parse.bool(nextCampaign.isActive) && nextCampaign.type !== 255;
                };

                scope.sortChanged = function(campaigns){
                    var campaignIds = _.chain(campaigns).filter(function(campaign){
                        return parse.bool(campaign.isActive) && campaign.type !== 255;
                    }).map(function(campaign){
                        return campaign.id;
                    }).value();
                    scope.onCampaignSortChanged && scope.onCampaignSortChanged({campaignIds: campaignIds});
                };

                scope.$on('campaign.statusChanged', function (event, args) {
                    var campaign = _.find(scope.campaigns, {id: args.id});
                    campaign.isActive = args.isActive;
                    campaign._isActiveOrder = -args.isActive;
                    scope.campaigns = _.sortBy(scope.campaigns, ['_isActiveOrder', 'type']);
                });


            }
        };
    }
    ]);
