/**
 * Created by ostapenko.r on 3/10/2015.
 */

angular.module('flipto.core.api.styling', []).config([
	'$provide',
	function($provide) {
		$provide.factory('Styling', [
			'$http',
			'urlFormatter',
			function($http, urlFormatter) {
				var touchpointUrlMap = {
					finalistsEmail: 'finalist',
					reminderEmail: 'finalistreminder',
					activityEmail: 'finalistactivity',
					winnerEmail: 'winnernotification',
					homepageEmail: 'homepage'
				};

				/**
				 * Styling service
				 * @constructor
				 */
				function Styling() {
					/**
					 * Get all available XSLT templates
					 * for touchpoint email page
					 * @params model
					 * @returns {*}
					 */
					this.getEmail = function(model) {
						var params = getParams(model);
						return $http
							.get(
								urlFormatter.format(
									'/api/:propertyUuid/properties/layers/:layerName/touchpoints/:touchpointUUID/emails/:emailName/styling',
									params
								)
							)
							.then(function(response) {
								return response.data;
							});
					};

					/**
					 * Save XSLT template
					 * for touchpoint email page
					 * @params model
					 * @returns {*}
					 */
					this.putEmail = function(model) {
						var params = getParams(model);
						return $http
							.put(
								urlFormatter.format(
									'/api/:propertyUuid/properties/layers/:layerName/touchpoints/:touchpointUUID/emails/:emailName/styling',
									params
								),
								{templateUUID: model.templateUUID}
							)
							.then(function(response) {
								return response.data;
							});
					};

					this.putReminderEmails = function(model) {
						var params = getParams(model);
						return $http
							.put(
								urlFormatter.format(
									'/api/:propertyUuid/properties/layers/:layerName/touchpoints/:touchpointUUID/emails/:emailName/styling',
									params
								),
								{
									nonViewEmailTemplateUUID: model.nonViewEmailTemplateUUID,
									afterViewEmailTemplateUUID: model.afterViewEmailTemplateUUID
								}
							)
							.then(function(response) {
								return response.data;
							});
					};

					this.putReengagementEmails = function(model) {
						var params = getParams(model);
						return $http
							.put(
								urlFormatter.format(
									'/api/:propertyUuid/properties/layers/:layerName/touchpoints/:touchpointUUID/emails/:emailName/styling',
									params
								),
								{
									noQuoteEmailTemplateUUID: model.noQuoteEmailTemplateUUID,
									noPhotoEmailTemplateUUID: model.noPhotoEmailTemplateUUID
								}
							)
							.then(function(response) {
								return response.data;
							});
					};

					function getParams(model) {
						return {
							layerName:
								model.layerName === 'photo-advocacy'
									? model.layerName
									: model.layerName.replace('-', ''),
							emailName: touchpointUrlMap[model.touchpointType] || model.touchpointType,
							touchpointUUID: model.touchpointUUID
						};
					}

					this.get = $http.get;
					this.put = $http.put;
				}

				return new Styling();
			}
		]);
	}
]);
