import {
    AfterViewInit,
    Component,
    ContentChild,
    ContentChildren,
    DoCheck,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { isEmpty, isEqual, transform } from 'lodash-es';
import { combineLatest, merge, Observable } from 'rxjs';
import { delay, filter, takeWhile, tap } from 'rxjs/operators';
import { ChecklistRule } from '../../../validation-checklist';

@Component({
    selector: 'ft-snapshot-form',
    templateUrl: './snapshot-form.component.html',
    styleUrls: ['./snapshot-form.component.scss']
})
export class SnapshotFormComponent implements AfterViewInit, DoCheck, OnInit, OnDestroy {
    @ContentChildren(NgModel, { descendants: true }) formControls;
    @ContentChild(NgForm) form: NgForm;
    initialValue: any;
    changes: any;
    totalChanges: number;
    @Output() commit = new EventEmitter();
    @Output() draftCommit = new EventEmitter();
    @Output() cancel = new EventEmitter();
    @Output() changesCount = new EventEmitter<number>();
    @Output() viewInit = new EventEmitter<boolean>();
    public onCommitSuccess$: Observable<any>;

    @Input() set commitSuccess$(commitSuccess$: Observable<any> | Array<Observable<any>>) {
        this.onCommitSuccess$ = Array.isArray(commitSuccess$) ? merge(...commitSuccess$) : commitSuccess$;
    }

    @Input() loading$: Observable<boolean> | Array<Observable<boolean>>;
    @Input() useFlattenStrategy: boolean;
    @Input() disabled = false;
    @Input() showCounter = true;
    @Input() draftSaveVisible = false;
    @Input() cancelVisible = true;
    @Input() publishVisible = true;
    @Input() draftSaveButtonLabel = 'Save draft';
    @Input() message: string;
    @Input() validationRules: ChecklistRule[] = [];
    @Input() canCommit = true;
    @Input() alwaysVisible = false;
    @Input() cancelConfirmation = false;


    isAlive = true;
    isInitialized = false;

    constructor() {
    }

    ngOnInit(): void {
        if (this.onCommitSuccess$) {
            this.handleCommitSuccess();
        }

        if (this.loading$) {
            this.handleLoading();
        }
    }

    ngAfterViewInit(): void {
        if (this.form) {
            this.viewInit.emit(true);
            this.watchForm();

            this.form.onReset = () => {
                // requires form.onReset() method to be called directly in place where reset needed, otherwise will not work
                this.initSnapshot();
            };
        } else {
            console.error(`Flipto - Please provide form inside <ft-snapshot-form>. Example: <form ngForm> ... </form>`);
        }
    }

    watchForm() {
        this.form.valueChanges.pipe(
            takeWhile(() => this.isAlive),
            filter(() => this.isInitialized)
        ).subscribe((formValue) => {
            this.changes = this.computeChanges(this.useFlattenStrategy ? this.unflattenFormValues(formValue) : formValue, this.initialValue);
        });
    }

    flattenFormValues(data: object): object {
        const result = {};

        function recurse(cur, prop) {
            if (Object(cur) !== cur) {
                result[prop] = cur === undefined ? null : cur;
            } else if (Array.isArray(cur)) {
                for (let i = 0; i < cur.length; i++)
                    recurse(cur[i], prop ? prop + '[' + i + ']' : '' + i);
                if (cur.length === 0)
                    result[prop] = [];
            } else {
                let isEmpty = true;
                // tslint:disable-next-line: forin
                for (const p in cur) {
                    isEmpty = false;
                    recurse(cur[p], prop ? prop + '.' + p : p);
                }
                if (isEmpty)
                    result[prop] = {};
            }
        }

        recurse(data, '');
        return result;
    }

    unflattenFormValues(data: object): object {
        if (Object(data) !== data || Array.isArray(data)) {
            return data;
        }
        const regex = /\.?([^.\[\]]+)|\[(\d+)\]/g;
        const resultholder = {};
        // tslint:disable-next-line: forin
        for (const p in data) {
            let cur = resultholder;
            let prop = '';
            let m: any;
            while (m = regex.exec(p)) {
                cur = cur[prop] || (cur[prop] = (m[2] ? [] : {}));
                prop = m[2] || m[1];
            }
            cur[prop] = data[p];
        }
        return resultholder[''] || resultholder;
    }

    ngDoCheck() {
        if (!isEmpty(this.form?.value) && !this.isInitialized && !this.loading$) {
            this.initSnapshot();
        }
    }

    ngOnDestroy() {
        this.isAlive = false;
    }

    onCancel() {
        this.form.setValue(this.useFlattenStrategy ? this.flattenFormValues(this.initialValue) : this.initialValue);
        this.totalChanges = 0;
        this.changesCount.next(this.totalChanges);
        this.cancel.next('cancel');
    }

    onCommit() {
        this.commit.next(this.changes);
    }

    onDraftCommit() {
        this.draftCommit.next(this.changes);
    }

    initSnapshot() {
        this.totalChanges = 0;
        this.changesCount.next(this.totalChanges);
        this.initialValue = this.useFlattenStrategy ? this.unflattenFormValues(this.form?.value) : this.form?.value;
        this.isInitialized = true;
    }

    handleCommitSuccess() {
        this.onCommitSuccess$.pipe(
            takeWhile(() => this.isAlive),
            filter((isSuccess) => this.isInitialized && !!isSuccess),
            delay(1)
        ).subscribe(() => {
            this.initSnapshot();
        });
    }

    handleLoading() {
        const loadings$ = Array.isArray(this.loading$) ? this.loading$ : [this.loading$];
        const loaded$ = combineLatest(loadings$).pipe(
            filter((isLoadings: boolean[]) => isLoadings.every(isLoading => isLoading === false))
        );

        loaded$.pipe(
            takeWhile(() => !this.isInitialized),
            delay(1)
        ).subscribe(() => {
            this.initSnapshot();
        });
    }

    // This function gets two objects and returns difference of first argument (object) comparing to second (base)
    // It allow us to know about form changes and theirs amount
    // Example: computeChanges({name: Jane, last: Doe}, {name: John, last: Doe}) will return {name: John} and will set totalChanges to 1.

    computeChanges(object, base) {
        this.totalChanges = 0;
        const changes = (o1, o2, countChanges = true) => { // recursive function that loops object's properties then looking and returning changes
            return transform(o1, (result, value, key) => { // this method transforms object to a new accumulator object which is the result of running each of its own enumerable string keyed properties thru iteratee, with each invocation potentially mutating the accumulator object. More info: https://lodash.com/docs/4.17.15#transform
                if (!isEqual(value, o2[key])) { // compares if object's and base object's property is equal
                    if (Array.isArray(value) && Array.isArray(o2[key])) { // if changed property is array
                        this.totalChanges++;
                        result[key] = value;
                    } else if (value != null && typeof value === 'object' && o2[key] != null && typeof o2[key] === 'object') { // if changed property is object
                        this.totalChanges++;
                        result[key] = changes(value, o2[key], false); // since changed property is object we are keep looking for other changes in other object's properties
                    } else { // if changed property is string key
                        result[key] = value;
                        if (countChanges) {
                            this.totalChanges++;
                        }
                    }
                }
                this.changesCount.next(this.totalChanges);
            });
        };
        return changes(object, base);
    }
}
