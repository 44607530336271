import { SOCIAL_NETWORKS } from "../../api/socialNetwork";


const TEMPLATE_URL = require("./chooseNetwork.html");
export const NAME = "ftBroadcastChooseNetwork";

export class BroadcastChooseNetworkController {

    onOpenShareModal: Function | undefined;
    sharedTo;
    socialNetworks;

    $onInit() {
        if (!!this.sharedTo) {
            this.socialNetworks = SOCIAL_NETWORKS.map(n => Object.assign({}, n, { isSharedTo: !!this.sharedTo.find(s => s == n.type) }))
        }
    }

    $onChange() {
        if (!!this.sharedTo) {
            this.socialNetworks = SOCIAL_NETWORKS.map(n => Object.assign({}, n, { isSharedTo: !!this.sharedTo.find(s => s == n.type) }))
        }
    }

    shareTo(network) {
        if (this.onOpenShareModal) {
            this.onOpenShareModal({ network });
        }
    }
}


export const OPTIONS = {
    bindings: {
        sharedTo: "<",
        onOpenShareModal: "&?"
    },
    templateUrl: TEMPLATE_URL,
    controller: BroadcastChooseNetworkController,
    controllerAs: "vm"
};
