/**
 * Created by Roman on 2015-07-30.
 */


angular.module('flipto.core.cache', ['flipto.core.lodash', 'flipto.core.proxy'])
    .factory('CacheExpiration', ['$interval', '_', '$cacheFactory', 'proxy', function ($interval, _, $cacheFactory, proxy) {

        var timers = [],
            timeout = 60000/*1 min*/,
            delay = 10000/*10 sec*/;

        $interval(function clearCache() {
            _.each(timers, function (timer) {
                if (timer.accessed + timeout <= Date.now()) {
                    timer.cache.removeAll();
                }
            });
        }, delay);

        /**
         * Sliding expiration for timer
         * @param timer
         */
        function reset(timer) {
            timer.accessed = Date.now();
        }

        /**
         * Remove timer
         * @param name
         */
        function remove(name) {
            _.remove(timers, function (timer) {
                return timer.key === name;
            });
        }

        return function CacheExpiration(name) {
            var cache = $cacheFactory.get(name);
            if (!cache) throw new Error('Could not find cache object');
            var timer = {key: name, cache: cache, accessed: Date.now()};
            proxy(timer.cache, 'get', angular.bind(null, reset, timer));
            proxy(timer.cache, 'put', angular.bind(null, reset, timer));
            proxy(timer.cache, 'removeAll', angular.bind(null, reset, timer));
            proxy(timer.cache, 'destroy', angular.bind(null, remove, name));
            timers.push(timer);
            return timer.cache;
        }
    }]);
