import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReportBadgeComponent } from "./report-badge.component";
import { AccountSharedModule } from "apps/account/src/account-shared";
import { MatIconModule } from '@angular/material/icon';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
    declarations: [
        ReportBadgeComponent
    ],
    imports: [
        CommonModule,
        AccountSharedModule,
        MatIconModule,
        NgbTooltipModule
    ],
    exports: [
        ReportBadgeComponent
    ]
})
export class ReportBadgeModule {
    constructor() {
    }
}

