/**
 * Created by Roman on 2015-04-03.
 */


angular.module('flipto.components.common.changesFilters', [])
    .directive('ftChangesFilters', [function () {

        /*function updateDiff(diff, rhs) {
            if (diff.kind === 'E') {
                return new diff.constructor(diff.path, diff.lhs, rhs);
            } else if (diff.kind === 'N') {
                return new diff.constructor(diff.path, rhs);
            }
        }*/

        return {
            restrict: 'A',
            require: 'ftChangesFilters',
            controller: function () {
                var vm = this,
                    filters = [];

                vm.push = function (filter) {
                    filters.push(filter);
                };

                vm.applyTo = function (diffs) {
                    angular.forEach(filters, function (filter) {
                        filter(diffs);
                    });
                };

            }
        };
    }]);
