import { createAction, props } from '@ngrx/store';
import {
    ConfirmationExperienceIntegrationSettings,
    ConfirmationExperienceSettings,
    ConfirmationExperienceStylingSettings
} from '../../models/confirmation-experience';

// messaging
export const loadConfirmationExperience = createAction('[Confirmation Experience] load',
props<{
    componentGroupUuid: string,
    propertyId: string
}>());

export const loadConfirmationExperienceSuccess = createAction('[Confirmation Experience] loadSuccess', props<{ data: ConfirmationExperienceSettings }>());
export const loadConfirmationExperienceFail = createAction('[Confirmation Experience] loadFail', props<{ error: any }>());

//styling
export const loadStylingConfirmationExperience = createAction('[Confirmation Experience] load styling',
props<{
    componentGroupUuid: string,
    propertyId: string
}>());

export const loadStylingConfirmationExperienceSuccess = createAction('[Confirmation Experience] styling loadSuccess', props<{ data: ConfirmationExperienceStylingSettings }>());
export const loadStylingConfirmationExperienceFail = createAction('[Confirmation Experience] styling loadFail', props<{ error: any }>());

//integration
export const loadIntegrationConfirmationExperience = createAction('[Confirmation Experience] load integration',
props<{
    componentGroupUuid: string,
    propertyId: string
}>());

export const loadIntegrationConfirmationExperienceSuccess = createAction('[Confirmation Experience] integration loadSuccess', props<{ data: ConfirmationExperienceIntegrationSettings }>());
export const loadIntegrationConfirmationExperienceFail = createAction('[Confirmation Experience] integration loadFail', props<{ error: any }>());

// change toogle
export const changeConfirmationExperience = createAction('[Confirmation Experience] Change Confirmation Experience',
  props<{
    componentGroupUuid: string,
    propertyId: string,
    changes: Partial<ConfirmationExperienceSettings>,
    tab: string
}>());
export const changeConfirmationExperienceSuccess = createAction('Confirmation Experience] changeSuccess',
props<{ changes: any }>());
export const changeConfirmationExperienceFail = createAction('[Confirmation Experience] changeFail',
props<{ error: any }>());

// save changes
export const commitConfirmationExperience = createAction('[Confirmation Experience] commit',
    props<{
        componentGroupUuid: string,
        propertyId: string,
        isActive: boolean
    }>());
export const commitConfirmationExperienceSuccess = createAction('[Confirmation Experience] commitSuccess', props<{ data: ConfirmationExperienceSettings }>());
export const commitConfirmationExperienceFail = createAction('[Confirmation Experience] commitFail', props<{ error: any }>());
