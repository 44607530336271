/**
 * User: Kupin.R
 * Date: 5/20/14
 */



angular
    .module("flipto.core.lodash", [])
    .service("_",
        [
            "$window",
            function ($window) {
                return $window._;
            }
        ]);
