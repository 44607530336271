import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { IFilterAngularComp } from 'ag-grid-angular';
import { GridApi, IDoesFilterPassParams, IFilterParams, RowNode } from 'ag-grid-community';

declare const _;


@Component({
    selector: 'ft-grid-select-filter',
    templateUrl: './grid-select-filter.component.html',
    styleUrls: ['./grid-select-filter.component.scss']
})
export class GridSelectFilterComponent implements IFilterAngularComp {
    private params: IFilterParams;
    private valueGetter: (rowNode: RowNode) => any;
    public options: any[];
    selectedOption = null;
    compareParam: string;

    @ViewChild('input', {read: ViewContainerRef, static: true}) public input;

    agInit(params: IFilterParams): void {
        this.params = params;
        this.valueGetter = params.valueGetter;
        this.compareParam = params.context && params.context.compareParam;
        this.setFilterOptionsFromDatasource(params.api);
    }

    setFilterOptionsFromDatasource(api: GridApi) {
        this.options = [];
        api.forEachNode((node) => {
            const options = this.getOptionsFromRowNode(node);
            this.options = _.union(this.options, options);
        });
    }

    getOptionsFromRowNode(node) {
        const nodeValue = this.valueGetter(node);
        const options = Array.isArray(nodeValue) ? nodeValue : [nodeValue];
        return options.map(item => {
            return item && this.compareParam ? item[this.compareParam] : item;
        });
    }

    isFilterActive(): boolean {
        return !!this.selectedOption;
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean {
        const options = this.getOptionsFromRowNode(params.node);
        return _.some(options, (value) => {
            return this.selectedOption === value;
        });
    }

    getModel(): any {
        return {value: this.selectedOption};
    }

    setModel(model: any): void {
        this.selectedOption = model ? model.value : '';
    }

    onSelect(selectedOption) {
        this.selectedOption = selectedOption;
        this.params.filterChangedCallback();
    }
}
