import { Action, createReducer, on } from '@ngrx/store';
import { commit, commitSuccess, load, loadFail, loadSuccess } from './actions';
import { State } from './state';

export const reducer = createReducer(
    {
        loading: false,
        settings: null,
        commitSuccess: false,
    } as State,
    on(load, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(commit, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(loadSuccess, (state, {data}) => {
        return {
            ...state,
            loading: false,
            settings: data
        };
    }),
    on(loadFail, (state, {error}) => {
        return {
            ...state,
            loading: false,
            error
        };
    }),
    on(commitSuccess, (state, {data}) => {
        return {
            ...state,
            loading: false,
            commitSuccess: true,
            settings: data
        };
    })
);

export function plannerflowReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}
