/**
 * Created by ostapenko.r on 24/12/2014.
 */

angular.module('flipto.core.company', ['flipto.core.inherits', 'flipto.core.lodash'])
    .factory('Company', ['inherits', '_', function (inherits, _) {

        /**
         * Context object
         * @param context
         * @constructor
         */
        function Context(context) {
            this.id = context.UUID;
            this.name = context.Name;
            this.slug = context.Slug;
        }

        /**
         * Property object
         * @param property
         * @constructor
         */
        function Property(property) {
            Property.superClass.constructor.call(this, property);
            this.security = property.security;
            this.backgroundImageUrl = property.BackgroundImageUrl;
            this.logoUrl = property.LogoUrl;
            this.isDemographicsEnabled = property.IsDemographicsEnabled;
            this.isTrackingEnabled = property.IsTrackingEnabled;
            // this.companyCode = property.companyCode;
        }

        inherits(Context, Property);

        /**
         * Company object
         * @param properties
         * @constructor
         */
        function Company(uuid, properties) {
            this.uuid = uuid;
            this.properties = [];

            _.forEach(properties, angular.bind(this, function (property) {
                this.properties.push(new Property(property));
            }));
        }

        /**
         * Returns properties by slug
         * @param slug
         * @returns {Property}
         */
        Company.prototype.getBySlug = function (slug) {
            return _.find(this.properties, {slug: slug});
        };

        /**
         * Returns properties by id
         * @returns {Property}
         * @param id
         */
        Company.prototype.getById = function (id) {
            return _.find(this.properties, {id: id});
        };

        /**
         * Add new property
         * @param property
         */
        Company.prototype.addProperty = function (property) {
            var newProperty = new Property(property);
            this.properties.push(newProperty);
        };

        return Company;

    }]);
