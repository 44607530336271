import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AudienceOnboardService } from '../../services/audience-onboard.service';
import { first } from 'rxjs/operators';

@Component({
    selector: 'ft-audience-cancellations',
    templateUrl: './audience-cancellations.component.html',
    styleUrls: ['./audience-cancellations.component.scss']
})
export class AudienceCancellationsComponent {
    slideIndex = 0;
    totalSlides = 2;
    dataFileType = 2;
    files: File[] = [];
    error = null;
    loading$ = new Subject<boolean>();
    isUploadComplete = false;

    constructor(public dialogRef: MatDialogRef<any>,
        private audienceOnboardService: AudienceOnboardService) {
    }

    onClose() {
        this.dialogRef.close();
    }

    submit() {
        const fileData = this.createFileData();
        this.uploadFile(fileData);
    }

    createFileData(): FormData {
        const data = new FormData();
        this.files.forEach((file) => {
            data.append('formFile', file, file.name);
        });
        return data;
    }

    uploadFile(fileData: FormData) {
        this.loading$.next(true);
        this.error = null;
        this.audienceOnboardService.sendFiles(fileData, this.dataFileType).pipe(
            first(),
        ).subscribe(() => {
            this.loading$.next(false);
            this.isUploadComplete = true;
        }, (err) => {
            this.loading$.next(false);
            this.error = {
                status: err.status,
                message: err.error
            };
        });
    }

}
