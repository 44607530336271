import { Action, createReducer, on } from '@ngrx/store';
import { NotificationsState } from "../store/notifications.state";
import {
    loadNotifications,
    loadNotificationsFailure,
    loadNotificationsSuccess,
    setLatestBadge,
    updateSeenDateOfNotifications,
    updateSeenDateOfNotificationsSuccess
} from "../store/notifications.actions";
import _ from 'lodash';


export const notificationsInitialState: NotificationsState = {
    notifications: [],
    latestBadge: null,
    loading: false,
    error: null
};

const reducer = createReducer(
    notificationsInitialState,
    on(loadNotifications, state => {
        return {
            ...state,
            loading: true,
            error: null
        };
    }),
    on(loadNotificationsSuccess, (state, {notifications}) => {
        return {
            ...state,
            loading: false,
            notifications
        };
    }),
    on(loadNotificationsFailure, (state, {error}) => {
        return {
            ...state,
            loading: false,
            error
        };
    }),
    //@ts-ignore
    on(setLatestBadge, (state, {latestBadge}) => {
        return {
            ...state,
            latestBadge
        };
    }),
    on(updateSeenDateOfNotifications, state => {
        return {
            ...state,
            loading: true,
            error: null
        };
    }),
    on(updateSeenDateOfNotificationsSuccess, (state, {notifications}) => {
        notifications = _.unionBy(notifications, state.notifications, 'uuid');
        return {
            ...state,
            loading: false,
            notifications,
            latestBadge: null
        };
    })
);

export function notificationsReducer(state: NotificationsState | undefined, action: Action) {
    return reducer(state, action);
}


