import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { SpacesService } from '../spaces.service';
import { SpaceType } from '../models/space-type';

type Operation = 'EQUAL' | 'NOT_EQUAL' | 'ANY';

@Directive({
    selector: '[ftCheckSpaceType]'
})
export class CheckSpaceTypeDirective {

    spaceType: string | string[];
    operation: Operation;
    defaultOperation: Operation;

    constructor(private spacesService: SpacesService,
        private templateRef: TemplateRef<any>,
        private vc: ViewContainerRef) {
    }

    @Input() set ftCheckSpaceType(spaceType: string | string[]) {
        this.spaceType = spaceType;
        this.defaultOperation = Array.isArray(spaceType) ? 'ANY' : 'EQUAL';
        this.updateView();
    }

    @Input() set ftCheckSpaceTypeOperation(operation: Operation) {
        this.operation = operation;
        this.updateView();
    }


    private updateView() {
        if (!this.spaceType || !this.spacesService.current) return;

        const space = this.spacesService.current;
        const spaceTypes = Array.isArray(this.spaceType) ? this.spaceType.map(type => SpaceType[type]) : [SpaceType[this.spaceType]];
        let expressionResult = false;
        const operation = this.operation || this.defaultOperation;
        switch (operation) {
            case 'EQUAL': {
                // 'Company'
                expressionResult = spaceTypes.every(type => type === space.type);
                break;
            }
            case 'NOT_EQUAL': {
                // 'Company'
                expressionResult = spaceTypes.every(type => type !== space.type);
                break;
            }
            case 'ANY': {
                // ['Company', 'Property']
                expressionResult = spaceTypes.some(type => type === space.type);
                break;
            }
        }

        if (expressionResult) {
            this.vc.createEmbeddedView(this.templateRef);
        } else {
            this.vc.clear();
        }
    }
}
