import { Component, Input, OnInit } from '@angular/core';
import { CampaignGoalsTypes, CampaignGoals } from './campaign-goals';


@Component({
  selector: 'ft-campaign-goals',
  templateUrl: './campaign-goals.component.html',
  styleUrls: ['./campaign-goals.component.scss']
})
export class CampaignGoalsComponent implements OnInit {
  @Input() goals: CampaignGoals[];
  @Input() layer: string;
  CampaignGoalsTypes = CampaignGoalsTypes;
  newLineTagsRegExp = new RegExp("<(p|br)[^><]*>|<\/(p|br)[^><]*>", "gm");

  constructor() { }

  ngOnInit() {
  }

  getGoalName(goal) {
    let descriptor = this.goalDescriptor(goal);
    let format = descriptor?.type;

    if (descriptor?.value) {
      format = format.replace('#', String(descriptor.value));
    }

    return format;
  }

  goalDescriptor(goal: any) {
    if (goal.type == '1') {
      let descriptor: any = {type: CampaignGoalsTypes.SpreadTheWord},
          clicks = parseInt(goal.clicks, 10),
          signups = parseInt(goal.signups, 10);

      if (Number(clicks) > 0) {
          descriptor.type = CampaignGoalsTypes.GetUniqueVisitors;
          descriptor.value = clicks;
      } else if (Number(signups) > 0) {
          descriptor.type = CampaignGoalsTypes.GetSocialConnections;
          descriptor.value = signups;
      }
      return descriptor;
    }
  }

  getIncentiveName(goal) {
    if (!goal) return 'Incentive description is not available';
    let name = this.getFirst(goal) || this.getNotAvailableMessage();
    name = name.replace(this.newLineTagsRegExp, '');
    return name;
  }

  getFirst(goal) {
    if (goal.languages) {
        var name = goal.languages.item[0];
        if (name) {
          return name.formalText;
        }
    }
  }

  getNotAvailableMessage() {
    return 'Incentive description is not available';
  }

  editGoals(goalTypes, initialIncentiveTag) {

  }

}
