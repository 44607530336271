/**
 * Created by ostapenko.r on 02/24/2015.
 */
angular
    .module('flipto.components.common.brackets', [])
    .filter('roundBrackets', [function () {
        var template = "([val])";
        return function (val) {
            return val && template.replace('[val]', val);
        }
    }]);