import { Language } from '../../../models/language.model';

export function mapLanguageNameToCode(mappingItem: any, availableLanguages: Language[]) {
    if (!availableLanguages.length)
        return mappingItem;
    if (Array.isArray(mappingItem)) {
        return mappingItem.filter(item => availableLanguages.some(language => language.languageCode === item.languageCode)).map(item => ({
            ...item,
            languageName: availableLanguages.find(language => language.languageCode === item?.languageCode)?.languageName
        }));
    }
    return ({
        ...mappingItem,
        languageName: availableLanguages.find(language => language.languageCode === mappingItem?.languageCode)?.languageName
    });
}
