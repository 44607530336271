/**
 * Created by ostapenko.r on 1/19/2015.
 */

angular.module('flipto.accountv1.SharedFeatures')
    .filter('nights', ['$filter', function($filter) {
        var translate = $filter('translate'),
            nightText = translate('flipto.account.platform.preStay.campaign.Night'),
            nightsText = translate('flipto.account.platform.preStay.campaign.Nights'),
            moreThan7 = translate('flipto.account.platform.preStay.campaign.MoreThan7');

        return function(nights) {

            switch (nights) {
                case 1:
                    return nights +' '+ nightText;
                    break;
                case 99:
                    return moreThan7 +' '+ nightsText;
                    break;
                case undefined:
                    return undefined;
                    break;
                default:
                    return nights +' '+ nightsText;
            }
        };
    }]);