<div class="ft2-datepicker">
    <input class="ft2-input -large mt-3"
           ngbDatepicker
           [name]="name"
           #datepicker="ngbDatepicker"
           navigation="arrows"
           [placeholder]="placeholder"
           [disabled]="disabled"
           (click)="datepicker.open()"
           [(ngModel)]="value"
           [readOnly]="readonly"
           [minDate]="minDate"
           (dateSelect)="datepicker.close()"
           type="text">
</div>
