/**
 * Created by Roman on 2014-11-19.
 */

angular.module('flipto.components.common.contextualNavigation')
    .factory('contextsConfig', ['contextualNavigation', 'ftSidenav', 'user', 'page', function (contextualNavigation, ftSidenav, user, page) {

        return {
            setup: function () {
                contextualNavigation.clear();
                var accountContext = contextualNavigation.newContext('account', {
                        name: user.current.company
                    }).handler(function (context) {
                        ftSidenav.close();
                        page.open('flipto.account');
                    }),
                    propertiesEntityGroup = accountContext.entityGroup('properties', {title: pluralizeTitle(user.company.properties.length, 'Property', 'Properties')});

                var subContext = contextualNavigation.newSubContext('property', null, accountContext.context)
                    .handler(function (context) {
                        openOverview(context.entity)
                    })
                    .action('platform', {
                        title: 'Platform',
                        state: 'flipto.property.overview.platform',
                        handler: function (context) {
                            ftSidenav.close();
                            page.open(this.state, {
                                slug: context.entity.slug
                            });
                        }
                    })
                    // .action('stories', {
                    //     title: 'Stories',
                    //     state: 'flipto.property.overview.stories.library',
                    //     handler: function (context) {
                    //         ftSidenav.close();
                    //         page.open(this.state, {
                    //             slug: context.entity.slug
                    //         });
                    //     }
                    // })
                    .action('people', {
                        title: 'People',
                        state: 'flipto.property.overview.people.directory',
                        handler: function (context) {
                            ftSidenav.close();
                            page.open(this.state, {
                                slug: context.entity.slug
                            });
                        }
                    })
                    .action('branding', {
                        title: 'Branding',
                        state: 'flipto.property.overview.settings.branding',
                        handler: function (context) {
                            ftSidenav.close();
                            page.open(this.state, {
                                slug: context.entity.slug
                            });
                        }
                    }).action('incentives', {
                        title: 'Incentives',
                        state: 'flipto.property.overview.settings.incentives',
                        handler: function (context) {
                            ftSidenav.close();
                            page.open(this.state, {
                                slug: context.entity.slug
                            });
                        }
                    }).action('notifications', {
                        title: 'Notifications',
                        state: 'flipto.property.overview.settings.notifications',
                        handler: function (context) {
                            ftSidenav.close();
                            page.open(this.state, {
                                slug: context.entity.slug
                            });
                        }
                    }).action('languages', {
                        title: 'Languages',
                        state: 'flipto.property.overview.settings.languages',
                        handler: function (context) {
                            ftSidenav.close();
                            page.open(this.state, {
                                slug: context.entity.slug
                            });
                        }
                    }).action('demos', {
                        title: 'Demos',
                        state: 'flipto.property.overview.settings.demos',
                        handler: function (context) {
                            ftSidenav.close();
                            page.open(this.state, {
                                slug: context.entity.slug,
                                type: context.entity.type
                            });
                        }
                    });

                // Limit the available options based off of permissions
                if (user.current.role === 3) { // Limit coop user's access to people
                    subContext.removeAction('people');
                }

                angular.forEach(user.company.properties, function (property) {
                    propertiesEntityGroup.entity(property.slug, {
                        title: property.name,
                        entity: property,
                        handler: function () {
                            openOverview(property)
                        }
                    });
                });

                function pluralizeTitle(count, one, many) {
                    var title = count + " ";
                    title += count > 1 ? many : one;
                    return title;
                }

                function openOverview(property) {
                    ftSidenav.close();
                    page.open('flipto.property.overview', {
                        slug: property.slug
                    });
                    contextualNavigation.setContext('property', property);
                }
            }
        };
    }]);