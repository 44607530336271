/**
 * Created by roman.kupin on 9/11/2017.
 */

import { environment } from 'account-hybrid-environments/environment';
const TEMPLATE_URL = require("./app.html");

export const NAME = "ftAccountApp";

let isGtmScriptAdded = false;
export class AccountAppController {

    static $inject = ["$ngRedux", "$location", 'IdentityService', "$window", "$transitions", "ngrxStore"];

    constructor(public $ngRedux,
        public $location,
        public identity,
        public $window,
        public $transitions,
        public ngrxStore) {

        const onStartActions = [
            () => {

                if (!isGtmScriptAdded && environment.production) {
                    isGtmScriptAdded = true;
                    const gtmScript = document.createElement("script");
                    gtmScript.src = "https://www.googletagmanager.com/gtag/js?id=UA-94027034-11";
                    gtmScript.async = true;
                    document.head.appendChild(gtmScript);

                    const startupScript = document.createElement("script");
                    startupScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'UA-94027034-11');`;
                    document.head.appendChild(startupScript);
                    $transitions.onSuccess({}, function () {
                        $window.gtag('config', 'UA-94027034-11', { 'page_path': `${$location.path()}` });
                    });
                }
            }
        ];

        onStartActions.forEach(action => {
            action();
        });

    }
}

export const OPTIONS = {
    templateUrl: TEMPLATE_URL,
    controllerAs: "vm",
    controller: AccountAppController
};
