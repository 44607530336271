<header class="ft-touchpoint-header" *ngIf="data">
    <div class="ft-touchpoint-header_container">
        <button class="ft-btn-close" [uiSref]="'^'"></button>
        <div class="ft-touchpoint-header_title ft-text-plus-3">
            Booking button
        </div>
    </div>
    <div class="ft-touchpoint-components">
        <div class="ft-touchpoint-component" *ngFor="let cmp of data; index as $index;"
             [ngClass]="{'-active': selectedComponent.componentUuid === cmp.componentUuid}"
             ftScrollIntoView
             (click)="selected.next(cmp.componentUuid)">
            <strong class="mb-1">
                <mat-icon class="icon-sm mr-1" svgIcon="target"></mat-icon>
                {{cmp.name}}
            </strong>
            <span class="mb-3">
                {{cmp.selector}}
            </span>
            <ft-permissions [ftPermissions]="'FliptoAdministration'" [hide]="false">
                <mat-slide-toggle [ngModel]="cmp.isActive" [disabled]="!cmp.selector"
                                  (ngModelChange)="activeToggled.next($event)">
                </mat-slide-toggle>
            </ft-permissions>
        </div>
        <ft-permissions [ftPermissions]="'FliptoAdministration'" [hide]="false">
            <ft-input-editor [(ngModel)]="newName" maxlength="100" (save)="created.next(newName); newName = ''"
                             title="Enter name" required>
                <button class="ft-touchpoint-component-create">+ New instance</button>
            </ft-input-editor>
        </ft-permissions>
    </div>
</header>
