export class PropertyUserDto {
    Email?: string;
    First?: string;
    FullName?: string;
    CompanyUserUuid?: string;
    Id?: string;
    Last?: string;
    id?: string;
    first?: string;
    last?: string;
    Title?: string;
}

export class PropertyUser extends PropertyUserDto {
    constructor(dto: Partial<PropertyUserDto>) {
        super();
        Object.assign(this, dto);
    }

    get displayName() {
        let displayName = this.FullName;
        if (this.Title) {
            displayName += ', ' + this.Title;
        }

        return displayName;
    }
}

export abstract class PropertyUserDtoExtensions {
    static getPropertyUserDtoKey(a: PropertyUserDto, defaultKey?: string) {
        return (a?.Id || a?.id)?.toLowerCase() || defaultKey;
    }
}
