/**
 * Created by Roman on 2015-04-20.
 */


angular.module('flipto.accountv1.SharedFeatures')
    .directive('ftStoryCard', ['curationModal', 'parse', function (curationModal, parse) {

        return {
            restrict: 'E',
            templateUrl: '/app/sections/stories/storyCard.html',
            scope: {
                'type': '@',
                'story': '=',
                'onSelected': '&'
            },
            link: function (scope, elem, attrs) {

                scope.highlightOld = false;
                attrs.$observe("highlightOld", function () {
                    scope.highlightOld = angular.isDefined(attrs.highlightOld) && parse.boolOrDefault(attrs.highlightOld, true);
                });


                scope.select = function (story) {
                    scope.onSelected({story: story});
                };

                curationModal.on('photo-rated', function (args) {
                    if(!scope.story.photo) return;
                    if(scope.story.photo.photoUUID == args[0]){
                        scope.story.photo.photoRating = parse.int(args[1]);
                    }
                });
                curationModal.on('caption-rated', function (args) {
                    if(!scope.story.photo) return;
                    if(scope.story.photo.photoUUID == args[0]){
                        scope.story.photo.captionRating = parse.int(args[1]);
                    }
                });
            }
        };
    }]);
