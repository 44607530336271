import { Component, OnInit } from '@angular/core';
import {
    DiscoveryComponentIntegrationViewModel,
    DiscoveryComponentMappingViewModel,
    DiscoveryComponentSettings,
    DiscoveryComponentSignatureUser,
    DiscoveryComponentStylingViewModel,
    DiscoveryProperty
} from '../../models';
import { combineLatest, Observable } from 'rxjs';
import { Transition } from '@uirouter/core';
import { select, Store } from '@ngrx/store';

import * as fromTouchpoints from '../../store/index';
import { IdentityService } from 'account-hybrid/core/authentication';
import { ComponentGroupType } from 'account-hybrid/features/discovery/enums/component-group-type.enum';
import { filter, map, take, tap, withLatestFrom } from 'rxjs/operators';
import { SpacesService } from 'account-hybrid/common/components/spaces';
import { MissionControlSettingsDetailsDto } from 'account-hybrid/features/mission-control/models';
import { OrderPipe } from 'ngx-order-pipe';
import { DiscoveryVersions } from 'account-hybrid/features/discovery/enums/discovery-versions.enum';
import { setCurrentDisplayedDiscoveryVersion } from 'account-hybrid/features/discovery/store';
import { selectInstalledAccountLanguages } from 'account-hybrid/common/modules/account-management/store';
import { PropertyHierarchyDto } from '../../../../../../../../../libs/api/data-contracts';
import { loadMissionControlDiscoverySettings, selectDiscoveryComponentSettingsLoadingError } from '../../store/index';
import { MultiLanguageComponent } from 'account-hybrid/common/components/multi-linguage/multi-language.component';


@Component({
    selector: 'ft-discovery-component',
    templateUrl: './discovery-component.component.html'
})
export class DiscoveryComponentComponent extends MultiLanguageComponent implements OnInit {
    isActive$: Observable<boolean>;
    integrationViewModel$: Observable<DiscoveryComponentIntegrationViewModel>;
    mappingViewModel$: Observable<DiscoveryComponentMappingViewModel>;
    stylingViewModel$: Observable<DiscoveryComponentStylingViewModel>;
    missionControlDetailsSettings$: Observable<MissionControlSettingsDetailsDto>;
    componentGroupUuid: string;
    isLoading$: Observable<boolean>;
    componentGroupType$: Observable<ComponentGroupType>;
    ownerPropertyUuid$: Observable<string>;
    ownerCompanyUuid$: Observable<string>;
    commitSuccess$: Observable<boolean>;
    title = 'Site Integration';
    properties$: Observable<DiscoveryProperty[]>;
    languages$: Observable<any>;
    isFliptoStaff = false;
    selectedIndex = 1;
    users$: Observable<DiscoveryComponentSignatureUser[]>;
    associations$: Observable<PropertyHierarchyDto[]>;
    associationsError$: Observable<any>;
    displayedVersion: DiscoveryVersions;

    constructor(
        private transition: Transition,
        private identityService: IdentityService,
        private spacesService: SpacesService,
        public store$: Store<fromTouchpoints.State>,
        private orderPipe: OrderPipe
    ) {
        super(store$);
    }


    ngOnInit(): void {
        this.componentGroupUuid = this.transition.params().componentGroupUuid;
        this.displayedVersion = this.transition.params().version;
        this.isFliptoStaff = this.identityService.isFliptoStaff();
        this.store$.dispatch(fromTouchpoints.loadMissionControlDiscoverySettings());
        this.languages$ = this.store$.pipe(select(selectInstalledAccountLanguages));
        this.missionControlDetailsSettings$ = this.store$.select(
            fromTouchpoints.selectMissionControlDetailsSettings
        );
        this.associationsError$ = this.store.pipe(select(selectDiscoveryComponentSettingsLoadingError));
        if (this.displayedVersion) {
            this.store$.dispatch(setCurrentDisplayedDiscoveryVersion({ version: this.displayedVersion }));
        }

        if (this.componentGroupUuid) {
            this.store$.dispatch(
                fromTouchpoints.loadDiscoveryComponentSettings({ componentGroupUuid: this.componentGroupUuid })
            );

            this.store$.dispatch(fromTouchpoints.loadDiscoveryProperties(
                { componentGroupUuid: this.componentGroupUuid })
            );
        }

        this.isActive$ = this.store$.pipe(
            select(fromTouchpoints.selectDiscoveryComponentSettingsIsActive)
        );

        this.componentGroupType$ = this.store$.pipe(
            select(fromTouchpoints.selectDiscoveryComponentGroupType)
        );

        this.users$ = this.store$.pipe(
            select(fromTouchpoints.selectDiscoveryComponentUsers)
        );

        this.store$.pipe(
            select(fromTouchpoints.selectDiscoveryComponentOwnerAndType),
            filter((settings) => !!settings),
            take(1),
            tap((settings) => {
                switch (settings.componentGroupType) {
                    case ComponentGroupType.Company: {
                        // settings.owningCompanyUuid
                        this.store$.dispatch(
                            fromTouchpoints.loadDiscoveryComponentCompanyUsers({ companyUuid: settings.owningCompanyUuid })
                        );
                        break;
                    }
                    case ComponentGroupType.Property: {
                        // settings.owningPropertyUuid
                        this.store$.dispatch(
                            fromTouchpoints.loadDiscoveryComponentCompanyUsers({
                                companyUuid: settings.owningCompanyUuid,
                                propertyUuid: settings.owningPropertyUuid
                            })
                        );
                        break;
                    }
                }
            })
        )
            .subscribe();

        this.ownerPropertyUuid$ = this.store$.pipe(
            select(fromTouchpoints.selectDiscoveryComponentOwnerPropertyUuid)
        );

        this.ownerCompanyUuid$ = this.store$.pipe(
            select(fromTouchpoints.selectDiscoveryComponentOwnerCompanyUuid)
        );

        this.integrationViewModel$ = this.store$.pipe(
            select(fromTouchpoints.selectDiscoveryComponentSettingsIntegrationViewModel),
        );

        this.mappingViewModel$ = this.store$.pipe(
            select(fromTouchpoints.selectDiscoveryComponentSettingsMappingViewModel)
        );

        this.associations$ = combineLatest([
            this.languages$,
            this.mappingViewModel$.pipe(map(mappingViewModel => mappingViewModel?.properties))
        ])
            .pipe(
                map(([languages, associations]) => {
                    return [...(associations || []).map(association => ({
                        ...association,
                        languages: [...languages.map(language => ({
                            ...(association.languages?.find(item => item.languageCode === language.languageCode) || {
                                languageCode: language.languageCode,
                                websiteUrl: null
                            })
                        }))]
                    }))];
                })
            );

        this.stylingViewModel$ = this.store$.pipe(
            select(fromTouchpoints.selectDiscoveryComponentSettingsStylingViewModel)
        );

        this.isLoading$ = this.store$.pipe(
            select(fromTouchpoints.selectDiscoveryComponentSettingsIsLoading)
        );

        this.commitSuccess$ = this.store$.pipe(
            select(fromTouchpoints.selectDiscoveryComponentSettingsCommitSuccess)
        );

        this.properties$ = this.store$.pipe(
            select(fromTouchpoints.selectDiscoveryProperties),
            withLatestFrom(this.mappingViewModel$),
            map(([properties, mappingModel]) => {
                const tempProperies = properties?.filter(item => !mappingModel?.properties?.some(prop => item.propertyUuid === prop.propertyUuid));
                return this.orderPipe.transform(tempProperies, 'name');
            })
        );

    }

    commit(settings: Partial<DiscoveryComponentSettings>) {
        this.store$.dispatch(fromTouchpoints.commitDiscoveryComponentSettings({
            componentGroupUuid: this.componentGroupUuid,
            settings,
            version: this.displayedVersion
        }));
    }

}
