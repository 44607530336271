/**
 * Created by Roman on 2014-09-15.
 */


angular.module('flipto.components.common.dateRange')
    .factory('predefinedDateRanges', [function () {
        return {
            'Past 7 Days': [moment().subtract(7, 'days').toDate(), moment().subtract(1, 'days').toDate()],
            'Past 30 Days': [moment().subtract(30, 'days').toDate(), moment().subtract(1, 'days').toDate()],
            'Past 90 Days': [moment().subtract(90, 'days').toDate(), moment().subtract(1, 'days').toDate()]
        };
    }]);