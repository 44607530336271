import { CarouselItem, EmailTypes, EmailTypesReminder, TokensPerType } from './../../models/certificate-email';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Transition } from '@uirouter/core';
import { JourneysApiService } from '../../services/journeys-api.service';
import Actions from 'v2/app/actions/startup';
import * as fromTouchpoints from '../../store';
import { Observable } from 'rxjs';
import { changeCertificateEmail, loadCertificateEmail } from '../../store/certificate-email';

@Component({
  selector: 'ft-certificate-email',
  templateUrl: './certificate-email.component.html',
  styleUrls: ['./certificate-email.component.scss']
})
export class CertificateEmailComponent implements OnInit {
  isReminder: boolean;
  items: CarouselItem[];
  selectedEmailData: any;
  componentGroupUuid: string;
  certificateEmailComponent$: Observable<any>;
  selectedcertificateEmailLoading$: Observable<boolean>;
  commitSuccess$: Observable<any>;
  formCertificateEmail = {
    body: null,
    contestContent: null,
    emailType: null,
    footer: null,
    languageCode: 'en',
    subject: null
  };
  tokensPerType = TokensPerType;

  constructor(
    private store$: Store<fromTouchpoints.State>,
    private transition: Transition,
    private journeysApiService: JourneysApiService,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.isReminder = this.transition.params().isReminder;
    this.items = this.setUpItems();
    this.componentGroupUuid = this.transition.params().componentGroupUuid;
    this.store$.dispatch(loadCertificateEmail({componentGroupUuid: this.componentGroupUuid}));
    this.certificateEmailComponent$ = this.store$.pipe(select(fromTouchpoints.getCertificateEmailData));
    this.selectedcertificateEmailLoading$ = this.store$.pipe(select(fromTouchpoints.getCertificateEmailLoading));
    this.commitSuccess$ = this.store$.pipe(select(fromTouchpoints.getConfirmationExperienceCommitSuccess));
  }

  setUpItems() {
    return [
      {
        isVisible: true,
        text: `A <b>website visitor</b> showed some love for a guest's story.`,
        emailTypes: this.isReminder ? EmailTypesReminder.anonymousPhotoSignupCertificateReminder : EmailTypes.anonymousPhotoSignupCertificate
      },
      {
        isVisible: false,
        text: `A <b>friend</b> voted for a guest's story.`,
        emailTypes: this.isReminder ? EmailTypesReminder.friendPhotoSignupCertificateReminder : EmailTypes.friendPhotoSignupCertificate
      },
      {
        isVisible: false,
        text: `A <b>friend</b> learned about a guest's upcoming trip.`,
        emailTypes: this.isReminder ? EmailTypesReminder.friendPrestayHomepageSignupCertificateReminder : EmailTypes.friendPrestayHomepageSignupCertificate
      },
      {
        isVisible: false,
        text: `A <b>friend</b> learned about a guest's recent stay.`,
        emailTypes: this.isReminder ? EmailTypesReminder.friendPoststayHomepageSignupCertificateReminder : EmailTypes.friendPoststayHomepageSignupCertificate
      },
      {
        isVisible: false,
        text: `A <b>guest</b> shared about an upcoming stay.`,
        emailTypes: EmailTypes.guestPrestayPromotionCertificate
      },
      {
        isVisible: false,
        text: `A <b>guest</b> shared about a recent stay.`,
        emailTypes: this.isReminder ? EmailTypesReminder.guestPoststayPromotionCertificateReminder : EmailTypes.guestPoststayPromotionCertificate
      },
      {
        isVisible: false,
        text: `A <b>participant</b> was approved for a storyteller campaign<br>(Optional incentive for finalists, can be set up in your campaign)`,
        emailTypes: this.isReminder ? EmailTypesReminder.guestPhotoFinalistCertificateReminder : EmailTypes.guestPhotoFinalistCertificate
      },
      {
        isVisible: false,
        text: `A <b>traveler</b> earned a certificate<br>(Generic message that can be sent to anyone)`,
        emailTypes: this.isReminder ? EmailTypesReminder.defaultCertificateReminder : EmailTypes.defaultCertificate
      },
    ];
  }

  getActiveEmail(data) {
    return data.find((val) => val.emailType === this.items.find((el) => el.isVisible).emailTypes);
  }


  onFormCommit(params, data) {
    const changes: any = [{
      ...this.formCertificateEmail,
      emailType: data.emailType,
      subject: data.languages[0].subject,
      body: data.languages[0].body,
      ...params
    }];
    this.store$.dispatch(changeCertificateEmail({componentGroupUuid: this.componentGroupUuid, changes}));
    this.ref.detectChanges();
  }
}
