/**
 * Created by ostapenko.r on 9/19/2014.
 */



angular.module('flipto.components.common.codeEditor', [
    'flipto.core.jsbeautify',
    'flipto.core.cssbeautify'
])
    .directive('ftCodeEditor', ["cssBeautify", "jsBeautify", '$timeout',
        function (cssBeautify, jsBeautify, $timeout) {
            //TODO: Implement dynamic options
            return {
                restrict: "E",
                templateUrl: '/app/components/common/code-editor/codeEditor.html',
                scope: {
                    'code': '=',
                    'syntax': '@',
                    'extraOptions': '@?options'
                },
                link: function (scope, element, attrs) {
                    scope.errors = [];

                    scope.aceLoaded = function (_editor) {
                        $timeout(function () {
                            if (scope.code) {
                                if (scope.syntax === 'css')
                                    scope.code = cssBeautify(scope.code);
                                if (scope.syntax === 'json' || scope.syntax === 'javascript')
                                    scope.code = jsBeautify(scope.code);
                            }
                        }, 20);


                        _editor.renderer.setShowPrintMargin(false);
                        _editor.setOptions({
                            enableBasicAutocompletion: true
                        });

                        var session = _editor.getSession();
                        session.setMode("ace/mode/" + scope.syntax);
                        session.on("changeAnnotation", function () {
                            var annotations = _editor.getSession().getAnnotations();
                            $timeout(function () {
                                scope.errors = scope.code ? _.filter(annotations, function (annotation) {
                                    return annotation.type === 'error'
                                }) : [];
                            });
                        });

                    }
                }
            }
        }
    ]);