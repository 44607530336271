<div class="daterange ft2-form-group">
    <div *ngIf="fromLabel && toLabel" class="flex full-width">
        <label class="ft2-form-group-label full-width">{{ fromLabel }}</label>
        <label class="ft2-form-group-label full-width">{{ toLabel }}</label>
    </div>
    <div class="flex full-width datepickers" [class.-divider]="divider">
        <input class="ft2-input -large -square -no-border"
               name="fromDate"
               [(ngModel)]="ngbFromDate"
               (ngModelChange)="onRangeSelected()"
               placeholder="Start date"
               readonly
               datepickerClass="ft-daterange-picker-calendar"
               ngbDatepicker
               firstDayOfWeek="7"
               (click)="dpStart.open()"
               #dpStart="ngbDatepicker"
               [maxDate]="toDate"
               [dayTemplate]="t"
               [navigation]="'arrows'">
        <input class="ft2-input -large -square -no-border"
               name="toDate"
               [minDate]="fromDate"
               [(ngModel)]="ngbToDate"
               (ngModelChange)="onRangeSelected()"
               placeholder="End date"
               readonly
               datepickerClass="ft-daterange-picker-calendar"
               ngbDatepicker
               firstDayOfWeek="7"
               (click)="dpEnd.open()"
               #dpEnd="ngbDatepicker"
               [dayTemplate]="t"
               [navigation]="'arrows'">
    </div>
</div>
<ng-template #t let-date let-focused="focused" let-disabled="disabled">
          <span class="custom-day"
                [class.disabled]="disabled"
                [class.focused]="focused">
            {{ date.day }}
          </span>
</ng-template>
