
/**
 * Created by luis.suarez on 1/31/2018.
 */



export const PREFIX = "[ADVOCACY_IMPACT_API]";

export const LAYER_PRESTAY_STORIES_FETCH = `${PREFIX}.PrestayStoriesFetch`;
export const LAYER_PRESTAY_STORIES_FETCH_SUCCESS = `${LAYER_PRESTAY_STORIES_FETCH}Success`;
export const LAYER_PRESTAY_STORIES_FETCH_ERROR = `${LAYER_PRESTAY_STORIES_FETCH}Error`;

export const LAYER_POSTSTAY_STORIES_FETCH = `${PREFIX}.PoststayStoriesFetch`;
export const LAYER_POSTSTAY_STORIES_FETCH_SUCCESS = `${LAYER_POSTSTAY_STORIES_FETCH}Success`;
export const LAYER_POSTSTAY_STORIES_FETCH_ERROR = `${LAYER_POSTSTAY_STORIES_FETCH}Error`;

export const LAYER_PHOTOCONTEST_STORIES_FETCH = `${PREFIX}.PhotocontestStoriesFetch`;
export const LAYER_PHOTOCONTEST_STORIES_FETCH_SUCCESS = `${LAYER_PHOTOCONTEST_STORIES_FETCH}Success`;
export const LAYER_PHOTOCONTEST_STORIES_FETCH_ERROR = `${LAYER_PHOTOCONTEST_STORIES_FETCH}Error`;


export const LAYER_HOMEPAGE_STORIES_FETCH = `${PREFIX}.HomepageStoriesFetch`;
export const LAYER_HOMEPAGE_STORIES_FETCH_SUCCESS = `${LAYER_HOMEPAGE_STORIES_FETCH}Success`;
export const LAYER_HOMEPAGE_STORIES_FETCH_ERROR = `${LAYER_HOMEPAGE_STORIES_FETCH}Error`;


export const LAYER_QUOTELIST_STORIES_FETCH = `${PREFIX}.QuotelistStoriesFetch`;
export const LAYER_QUOTELIST_STORIES_FETCH_SUCCESS = `${LAYER_QUOTELIST_STORIES_FETCH}Success`;
export const LAYER_QUOTELIST_STORIES_FETCH_ERROR = `${LAYER_QUOTELIST_STORIES_FETCH}Error`;

export function getLayerPrestayStories(propertyUuid, startDate, endDate) {
    return (dispatch, getState, {$http, environment}) => {
        dispatch({type: LAYER_PRESTAY_STORIES_FETCH});
        return $http.get(`${environment.apiBaseUrl}/${propertyUuid}/properties/${propertyUuid}/big-picture/prestay/statistics?StartDate=${startDate}&EndDate=${endDate}`)
            .then(response => {
                dispatch({type: LAYER_PRESTAY_STORIES_FETCH_SUCCESS, payload: response.data});
            }, error => {
                dispatch({type: LAYER_PRESTAY_STORIES_FETCH_ERROR, payload: error});
                throw LAYER_PRESTAY_STORIES_FETCH_ERROR;
            })
    }
}

export function getLayerPoststayStories(propertyUuid, startDate, endDate) {
    return (dispatch, getState, {$http, environment}) => {
        dispatch({type: LAYER_POSTSTAY_STORIES_FETCH});
        return $http.get(`${environment.apiBaseUrl}/${propertyUuid}/properties/${propertyUuid}/big-picture/poststay/statistics?StartDate=${startDate}&EndDate=${endDate}`)
            .then(response => {
                dispatch({type: LAYER_POSTSTAY_STORIES_FETCH_SUCCESS, payload: response.data});
            }, error => {
                dispatch({type: LAYER_POSTSTAY_STORIES_FETCH_ERROR, payload: error});
                throw LAYER_POSTSTAY_STORIES_FETCH_ERROR;
            })
    }
}

export function getLayerPhotocontestStories(propertyUuid, startDate, endDate) {
    return (dispatch, getState, {$http, environment}) => {
        dispatch({type: LAYER_PHOTOCONTEST_STORIES_FETCH});
        return $http.get(`${environment.apiBaseUrl}/${propertyUuid}/properties/${propertyUuid}/big-picture/photo-contest/statistics?StartDate=${startDate}&EndDate=${endDate}`)
            .then(response => {
                dispatch({type: LAYER_PHOTOCONTEST_STORIES_FETCH_SUCCESS, payload: response.data});
            }, error => {
                dispatch({type: LAYER_PHOTOCONTEST_STORIES_FETCH_ERROR, payload: error});
                throw LAYER_PHOTOCONTEST_STORIES_FETCH_ERROR;
            })
    }
}

export function getLayerHomepageStories(propertyUuid, startDate, endDate) {
    return (dispatch, getState, {$http, environment}) => {
        dispatch({type: LAYER_HOMEPAGE_STORIES_FETCH});
        return $http.get(`${environment.apiBaseUrl}/${propertyUuid}/properties/${propertyUuid}/big-picture/homepage/statistics?StartDate=${startDate}&EndDate=${endDate}`)
            .then(response => {
                dispatch({type: LAYER_HOMEPAGE_STORIES_FETCH_SUCCESS, payload: response.data});
            }, error => {
                dispatch({type: LAYER_HOMEPAGE_STORIES_FETCH_ERROR, payload: error});
                throw LAYER_HOMEPAGE_STORIES_FETCH_ERROR;
            })
    }
}

export function getLayerQuoteListStories(propertyUuid, startDate, endDate) {
    return (dispatch, getState, {$http, environment}) => {
        dispatch({type: LAYER_QUOTELIST_STORIES_FETCH});
        return $http.get(`${environment.apiBaseUrl}/${propertyUuid}/properties/${propertyUuid}/big-picture/quotelist/statistics?StartDate=${startDate}&EndDate=${endDate}`)
            .then(response => {
                dispatch({type: LAYER_QUOTELIST_STORIES_FETCH_SUCCESS, payload: response.data});
            }, error => {
                dispatch({type: LAYER_QUOTELIST_STORIES_FETCH_ERROR, payload: error});
                throw LAYER_QUOTELIST_STORIES_FETCH_ERROR;
            })
    }
}

const All = {getLayerPrestayStories, getLayerPoststayStories, getLayerPhotocontestStories, getLayerHomepageStories, getLayerQuoteListStories};
export default All;