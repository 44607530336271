<ft-modal-wrapper>
  <ft-touchpoint-header
    [name]="options.touchpointTitle"
    [canActivate]="true"
    [isActive]="(selectedInvitationEmailComponent$ | async)?.isActive == 1"
    (activeToggled)="commit($event)"
    >
  </ft-touchpoint-header>
  <mat-tab-group
    class="mat-tab-group-nav"
    mat-align-tabs="center"
    animationDuration="0ms"
    [(selectedIndex)]="selectedIndex">
    <mat-tab label="messaging">
      <ng-template matTabContent>
        <ft-snapshot-form
          *ngIf="selectedInvitationEmailComponent$ | async | clone as data"
          [loading$]="[selectedInvitationEmailLoading$]"
          [commitSuccess$]="[commitSuccess$]"
          (commit)="onFormCommit($event)">
          <section class="ft2-section">
            <div class="ft2-preview">
              <div class="preview-chrome">
                <span>Inbox</span>
              </div>
              <div class="preview-content">
                <form ngForm>
                  <span class="muted-text">Subject: </span>
                  <ft-input-editor
                    title="Subject"
                    [(ngModel)]="data.languages.item[0].data.emailSubject"
                    required
                    name="emailSubject">
                    <span [innerHTML]="data.languages.item[0].data.emailSubject"></span>
                  </ft-input-editor>
                  <hr>
                  <div *ngIf="options.isContest" class="ft2-preview-banner mt-4 mb-4">
                    <div class="h5 mb-1">Contest name</div>
                    <hr />
                    X days remaining
                    <br />
                    Contest end date
                    <br />
                    Prizes
                  </div>
                  <ft-html-editor
                    title="Body"
                    [tokens]="messageTokens"
                    name="emailBody"
                    [(ngModel)]="data.languages.item[0].data.emailBody">
                    <div [innerHTML]="data.languages.item[0].data.emailBody"></div>
                  </ft-html-editor>
                  <ft-html-editor
                    *ngIf="data.languages.item[0].data.emailFooter"
                    title="Footer"
                    [tokens]="messageTokens"
                    name="emailFooter"
                    [(ngModel)]="data.languages.item[0].data.emailFooter">
                    <div [innerHTML]="data.languages.item[0].data.emailFooter"></div>
                  </ft-html-editor>
                  <div class="muted-text">
                    {{'{property}'}}
                  </div>
                </form>
              </div>
              <div class="preview-options">
                <a class="launch-preview">
                  Email sample
                </a>
              </div>
            </div>
          </section>
        </ft-snapshot-form>
      </ng-template>
    </mat-tab>
    <mat-tab label="styling">
      <ng-template matTabContent>
        <ft-snapshot-form
          *ngIf="stylingInvitationEmailComponent$ | async | clone as styleData"
          [loading$]="[selectedInvitationEmailLoading$]"
          [commitSuccess$]="[commitSuccess$]"
          (commit)="onFormCommit($event)">
          <section class="ft2-section">
            <div class="ft2-container">
              <div class="row">
                <div class="col-12">
                  <form ngForm>
                    <ft-select-editor
                      title="Email template"
                      required
                      [placeholder]="'Select template'"
                      [name]="'templateUUID'"
                      (save)="onSelect($event)"
                      [ngModel]="selectedTemplate(styleData)"
                      [options]="styleData.templates.item"
                      [bindLabel]="'name'"
                      [bindValue]="'id'">
                        <ft-edit-panel label="Email template">
                          {{selectedTemplate(styleData) + '(Default)'}}
                        </ft-edit-panel>
                    </ft-select-editor>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </ft-snapshot-form>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</ft-modal-wrapper>
