/**
 * Created by Roman on 2015-08-26.
 */






angular.module('flipto.core.api.guests', ['flipto.core.parse', 'flipto.core.lodash', 'flipto.core.moment'])
    .config(['$provide', function ($provide) {

        $provide.factory('Guests', ['urlFormatter', '$http', function (urlFormatter, $http) {

            /**
             * Guests service
             * @constructor
             */
            function Guests() {

            }

            /**
             * Returns guest details
             * @param guestUUID
             * @returns {*}
             * @param recovery
             */
            Guests.prototype.details = function (guestUUID, recovery) {
                return $http.get(urlFormatter.format('/api/guests/:guestUUID', {guestUUID: guestUUID}, false), {recovery: recovery})
                    .then(function (response) {
                        return response.data;
                    });
            };

            /**
             * Returns property guests given query
             * @param guestUUID
             * @param query
             * @returns {*}
             */
            Guests.prototype.guests = function (propertyUUID, query) {
                var url = urlFormatter.format('/api/:propertyUUID/properties/:propertyUUID/guests', angular.extend({propertyUUID: propertyUUID}, query), true);
                return $http.get(url)
                    .then(function (response) {
                        return response.data;
                    });
            };

            /**
             * Returns available feedbacks for given query
             * @param propertyUUID
             * @param query
             * @returns {*}
             */
            Guests.prototype.feedback = function (propertyUUID, query) {
                var url = urlFormatter.format('/api/:propertyUUID/properties/:propertyUUID/feedback', angular.extend({propertyUUID: propertyUUID}, query), true);
                return $http.get(url)
                    .then(function (response) {
                        return response.data;
                    });
            };

            /**
             * Returns property photos given query
             * @param propertyUUID
             * @param query
             * @returns {*}
             */
            Guests.prototype.photos = function (propertyUUID, query) {
                var url = urlFormatter.format('/api/:propertyUUID/properties/:propertyUUID/photos', angular.extend({propertyUUID: propertyUUID}, query), true);
                return $http.get(url)
                    .then(function (response) {
                        return response.data;
                    });
            };

            Guests.prototype.unratedStoriesPhotos = function (propertyUUID, query) {
                var url = urlFormatter.format('/api/:propertyUUID/properties/:propertyUUID/stories/photos', angular.extend({propertyUUID: propertyUUID}, query), true);
                return $http.get(url)
                    .then(function (response) {
                        return response.data;
                    });
            };

            Guests.prototype.unratedStoriesNoPhotos = function (propertyUUID, query) {
                var url = urlFormatter.format('/api/:propertyUUID/properties/:propertyUUID/stories/nophotos', angular.extend({propertyUUID: propertyUUID}, query), true);
                return $http.get(url)
                    .then(function (response) {
                        return response.data;
                    });
            };

            /**
             * Returns property quotes given query
             * @param propertyUUID
             * @param query
             * @returns {*}
             */
            Guests.prototype.quotes = function (propertyUUID, query) {
                var url = urlFormatter.format('/api/:propertyUUID/properties/:propertyUUID/quotes', angular.extend({propertyUUID: propertyUUID}, query), true);
                return $http.get(url)
                    .then(function (response) {
                        return response.data;
                    });
            };

            /**
             * Returns popular keywords
             * @param propertyUUID
             * @returns {*}
             */
            Guests.prototype.getCommonKeywords = function (propertyUUID) {
                return $http.get(urlFormatter.format('/api/:propertyUUID/properties/:propertyUUID/tags/popular', {propertyUUID: propertyUUID}, false))
                    .then(function (response) {
                        return response.data;
                    });
            };

            /**
             * Search keyword
             * @param propertyUUID
             * @param search
             * @returns {*}
             */
            Guests.prototype.searchKeyword = function (propertyUUID, search) {
                return $http.get(urlFormatter.format('/api/:propertyUUID/properties/:propertyUUID/tags/search', {propertyUUID: propertyUUID, search: search}, true))
                    .then(function (response) {
                        return response.data;
                    });
            };


            return new Guests();
        }]);
    }]);