import { SocialNetwork } from "../../api/socialNetwork";

const TEMPLATE_URL = require("./shareImageReady.html");

export const NAME = "ftBroadcastShareImageReady";

export class BroadcastShareImageReadyController {

    static $inject = [];

    isRecommendationSetup = false;
    shareImageUrl: any;
    network: any;
    message: any;
    shareType;

    onReprocessImage: Function | undefined;
    onDownloadShareImage: Function | undefined;
    onShare: Function | undefined;

    isInstagram() {
        return this.network && this.network.type == SocialNetwork.Instagram;
    }

    isTwitter() {
        return this.network && this.network.type == SocialNetwork.Twitter;
    }

    isLinkedIn() {
        return this.network && this.network.type == SocialNetwork.LinkedIn;
    }

    share(shareImageReadyForm) {
        if (shareImageReadyForm.$invalid) return;
        if (this.onShare) {
            this.onShare({ message: this.message });
        }
    }

    downloadShareImage() {
        if (this.onDownloadShareImage) {
            this.onDownloadShareImage();
        }
    }

    reprocessImage() {
        if (this.onReprocessImage) {
            this.onReprocessImage();
        }
    }

};


export const OPTIONS = {
    bindings: {
        shareType: "<",
        shareImageUrl: "<",
        network: "<",
        shareUrl: "<",
        first: "<?",
        isRecommendationSetup: "<?",
        onReprocessImage: "&?",
        onDownloadShareImage: "&?",
        onShare: "&?"
    },
    templateUrl: TEMPLATE_URL,
    controller: BroadcastShareImageReadyController,
    controllerAs: "vm"
};
