/**
 * Created by roman.kupin on 11/20/2018.
 */


export const NAME = "ftHideInV2";

export const DEFINITION = [function () {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            elem[0].remove();
        }
    };
}];
