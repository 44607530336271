import { ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';

@Component({
    selector: 'ft-select-editor',
    templateUrl: './select-editor.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => SelectEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: SelectEditorComponent
        }
    ]
})
export class SelectEditorComponent extends ModalEditorBase {
    @Input() options: any[];
    @Input() bindLabel = 'label';
    @Input() bindValue = 'value';
    @Input() searchable = false;
    @Input() placeholder: string;
    @Input() clearable = false;
    @Input() id: string;
    @Input() compareWith: (a: any, b: any) => boolean;

    constructor(private detectorRef: ChangeDetectorRef) {
        super();
    }

    onModelChange(value: any) {
        this.value = value;
    }

    onEditorVisible() {
        this.detectorRef.detectChanges();
    }
}

