/**
 * Created by roman.kupin on 20/08/2014.
 */


angular
    .module("flipto.components.goals",
    [
        'flipto.components.incentives',
        'pascalprecht.translate',
        'flipto.core.lodash',
        'flipto.components.common'
    ]);