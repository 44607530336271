angular.module('flipto.accountv1.SharedFeatures')
    .factory("Demo", function () {
        function Demo(description, demotypeID, modifiedDate, HTML, UUID) {
            this.description = description;
            this.demotypeID = demotypeID;
            this.modifiedDate = modifiedDate;
            this.HTML = HTML;
            this.UUID = UUID;
        }

        return Demo;
    });