import { createAction, props } from '@ngrx/store';
import { ContextSelectorSetup } from '../../../../utils/context-selector-setup.util';

export const addNew = createAction(
    '[ContextSelector] Add new',
    props<{ payload: ContextSelectorSetup }>()
);
export const show = createAction(
    '[ContextSelector] Show',
    props<{
        payload: {
            contextSelectorId: string;
        }
    }>()
);
export const hide = createAction(
    '[ContextSelector] Hide',
    props<{
        payload: {
            contextSelectorId: string;
        }
    }>()
);
export const discardChanges = createAction(
    '[ContextSelector] Discard changes',
    props<{
        payload: {
            contextSelectorId: string;
        }
    }>()
);
export const selectGroup = createAction(
    '[ContextSelector] Select group',
    props<{
        payload: {
            contextSelectorId: string;
            groupName: string;
        }
    }>()
);
export const updateContextSelectorGroup = createAction(
    '[ContextSelector] Update context selector group',
    props<{
        payload: {
            contextSelectorId: string;
            groupName: string;
            contexts: any;
        }
    }>()
);
