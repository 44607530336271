/**
 * Created by Roman on 2015-10-29.
 */

angular.module('flipto.accountv1.SharedFeatures')
    .directive("ftContestStatus", [function () {

        return {
            restrict: "E",
            template: "{{vm.statusText}}",
            scope: {
                "contest": "="
            },
            controllerAs: "vm",
            bindToController: true,
            controller: ["$filter", "$scope", "parse", function ($filter, $scope, parse) {
                var translate = $filter("translate"),
                    dateFormat = $filter("dateFormat"),
                    votingEnds = translate("flipto.account.sections.platform.photoAdvocacy.VotingEnds"),
                    submissions = translate("flipto.account.sections.platform.photoAdvocacy.SubmissionsReviewed"),
                    chooseWinners = translate("flipto.account.sections.platform.photoAdvocacy.ChooseYourWinners"),
                    off = translate("flipto.components.campaign.Off"),
                    winnersAnnounced = translate("flipto.account.sections.platform.photoAdvocacy.WinnersAnnounced");


                /**
                 * Returns true if contest is still running
                 * @param contest
                 * @returns {*}
                 */
                this.isRunning = function (contest) {
                    var now = moment();
                    return now.isAfter(contest.startDate) && now.isBefore(contest.votingEndDate);
                };

                /**
                 * Returns text depending on contest status
                 * @returns {*}
                 */
                this.getStatusText = function () {
                    if (!parse.bool(this.contest.isActive)) {
                        return off;
                    } else if (parse.bool(this.contest.isClosed)) {
                        return winnersAnnounced + " " + dateFormat(this.contest.closeDate, "MMM D, YYYY");
                    } else if (this.isRunning(this.contest)) {
                        // contest object passed is different submissions vs audiences
                        return !!this.contest.totalUncuratedPhotos ? (this.contest.totalUncuratedPhotos + " " + submissions) : (votingEnds + " " + dateFormat(this.contest.votingEndDate, "MMM D, YYYY"));
                    } else {
                        return chooseWinners;
                    }
                };

                $scope.$watch(angular.bind(this, function () {
                    return this.contest;
                }), angular.bind(this, function (contest) {
                    if (!!contest) {
                        this.statusText = this.getStatusText();
                    }
                }));


            }]
        };
    }]);