/**
 * Created by ostapenko.r on 8/29/2014.
 */

angular.module('flipto.core.interceptors', ['flipto.core.storage'])
    .factory('signInTokenCleanup', [function () {
        return {
            request: function (config) {
                if(config.url.indexOf("/api/user/login") === 0){
                    localStorage.removeItem('flipto.session');
                }
                return config;
            }
        };
    }])
    .factory('tokenInjector', ['IdentityService', function (identity) {
        return {
            request: function (config) {
                if (config.url.indexOf("/api") !== -1) {
                    config.headers['X-Flipto-Token'] = identity.getToken();
                    config.headers['X-Flipto-Property'] = identity.getPropertyId();
                }

                return config;
            }
        };
    }])
    .factory('tokenExpiration', ['$q', function ($q) {
        return {
            'responseError': function (response) {
                if (response.status === 401) {
                    localStorage.removeItem('flipto.session');
                    window.location.reload();
                }
                return $q.reject(response);
            }
        };
    }])
    .factory('accessForbidden', ['$q', '$location', function ($q, $location) {
        return {
            'responseError': function (response) {
                var recovery = response.config.recovery, hasRecoveryFunction = !!recovery;
                if (response.status === 403) {
                    if(!hasRecoveryFunction){
                        $location.url('/home');
                        $location.replace();
                    }
                }
                return hasRecoveryFunction ? recovery(response) : $q.reject(response);
            }
        };
    }])
    .factory('requestsLogger', ['AppLog', '$q', function (AppLog, $q) {

        function handleRequest(config) {
            if (config.url.indexOf('/api') === 0) {
                AppLog.info('api', {request: config});
            }
            return config;
        }

        function handleResponse(response) {
            var identifier = response.config.headers['X-Flipto-Log-Entry'];
            if (!!identifier) {
                var logItems = AppLog.search(function (item) {
                    return item.entry.request.headers['X-Flipto-Log-Entry'] === identifier;
                });
                angular.forEach(logItems, function (item) {
                    item.entry.response = response;
                })
            }
            return response;
        }

        function handleResponseError(response) {
            handleResponse(response);
            return $q.reject(response);
        }


        return {
            request: handleRequest,
            response: handleResponse,
            responseError: handleResponseError
        };
    }]);
