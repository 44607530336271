import {
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input,
    ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Frequency as RRuleFrequency, RRule, datetime, Options as RRuleOptions, ByWeekday } from 'rrule';
import dayjs from 'dayjs';

import {
    MODAL_EDITOR_TOKEN,
    ModalEditorBase,
} from '../../../classes/modal-editor-base';
import { CalendarRRuleBehavior } from '../../../models/calendar-rrule-behavior.enum';
import { DateRange, TimeRange, ModalEditorWrapperComponent } from 'account-shared';

enum Position {
    First = 1,
    Second = 2,
    Third = 3,
    Fourth = 4,
    Last = -1,
}

enum Frequency {
    DAILY = 0,
    WEEKLY = 1,
    MONTHLY_FIXED_DATE = 2,
    MONTHLY_RELATIVE_DAY = 3,
    YEARLY_FIXED_DATE = 4,
    YEARLY_RELATIVE_DAY = 5,
}

interface FrequencyOption {
    name: string;
    value: Frequency;
    freq: RRuleFrequency;
}

interface PositionOption {
    name: string;
    value: Position;
}

interface MonthOption {
    name: string;
    value: number;
}

interface WeekDayOption {
    name: string;
    value: number;
    weekday: ByWeekday;
}

export interface CalendarEditorRRule {
    rule: string;
    behavior: CalendarRRuleBehavior;
    recurringStartDate: Date,
    recurringEndDate: Date,
}

@Component({
    selector: 'ft-calendar-editor',
    templateUrl: './calendar-editor.component.html',
    styleUrls: ['./calendar-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => CalendarEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: CalendarEditorComponent
        }
    ],
})
export class CalendarEditorComponent extends ModalEditorBase {
    @Input() behavior: CalendarRRuleBehavior = CalendarRRuleBehavior.AddDates;
    @Input('from') fromDate: string | Date;
    @Input('to') toDate: string | Date;
    @ViewChild('modalEditorWrapper') modalEditorWrapper: ModalEditorWrapperComponent;

    CalendarRRuleBehavior = CalendarRRuleBehavior;
    Frequency = Frequency;
    RRuleFrequency = RRuleFrequency;

    frequencyOptions: FrequencyOption[] = [
        { name: 'Daily', value: Frequency.DAILY, freq: RRule.DAILY },
        { name: 'Weekly', value: Frequency.WEEKLY, freq: RRule.WEEKLY },
        { name: 'Monthly on a fixed date', value: Frequency.MONTHLY_FIXED_DATE, freq: RRule.MONTHLY },
        { name: 'Monthly on a relative day', value: Frequency.MONTHLY_RELATIVE_DAY, freq: RRule.MONTHLY },
        { name: 'Yearly on a fixed date', value: Frequency.YEARLY_FIXED_DATE, freq: RRule.YEARLY },
        { name: 'Yearly on a relative day', value: Frequency.YEARLY_RELATIVE_DAY, freq: RRule.YEARLY },
    ];
    weekDaysOptions: WeekDayOption[] = [
        { name: 'Sun', value: 0, weekday: RRule.SU },
        { name: 'Mon', value: 1, weekday: RRule.MO },
        { name: 'Tue', value: 2, weekday: RRule.TU },
        { name: 'Wed', value: 3, weekday: RRule.WE },
        { name: 'Thu', value: 4, weekday: RRule.TH },
        { name: 'Fri', value: 5, weekday: RRule.FR },
        { name: 'Sat', value: 6, weekday: RRule.SA },
    ];
    monthFixedDates = Array.from({ length: 31 }, (_, i) => i + 1);
    monthOptions: MonthOption[] = [
        { name: 'Jan', value: 0 },
        { name: 'Feb', value: 1 },
        { name: 'Mar', value: 2 },
        { name: 'Apr', value: 3 },
        { name: 'May', value: 4 },
        { name: 'Jun', value: 5 },
        { name: 'Jul', value: 6 },
        { name: 'Aug', value: 7 },
        { name: 'Sep', value: 8 },
        { name: 'Oct', value: 9 },
        { name: 'Nov', value: 10 },
        { name: 'Dec', value: 11 }
    ];
    positionOptions: PositionOption[] = [
        { name: 'First', value: Position.First },
        { name: 'Second', value: Position.Second },
        { name: 'Third', value: Position.Third },
        { name: 'Fourth', value: Position.Fourth },
        { name: 'Last', value: Position.Last },
    ];

    fromTime: string;
    toTime: string;
    dateRange: DateRange;
    timeRange: TimeRange;
    frequencyOption: FrequencyOption = this.frequencyOptions[0];
    interval: number = 1;
    weekDayMap: Map<number, WeekDayOption> = new Map();
    monthFixedDate: number = this.monthFixedDates[0];
    monthRelativeDay: WeekDayOption = this.weekDaysOptions[0];
    monthRelativePos: PositionOption = this.positionOptions[0];
    yearFixedDate: Date = new Date();
    yearRelativeDay: WeekDayOption = this.weekDaysOptions[0];
    yearRelativePos: PositionOption = this.positionOptions[0];
    yearRelativeMonth: MonthOption = this.monthOptions[0];

    constructor(private cdr: ChangeDetectorRef) {
        super();
    }

    onWeekdayToggle(checked: boolean, weekdayOption: WeekDayOption) {
        if (checked) {
            this.weekDayMap.set(weekdayOption.value, weekdayOption);
        } else {
            this.weekDayMap.delete(weekdayOption.value);
        }
    }

    isWeekDayOptionChecked(index: number) {
        return this.weekDayMap.has(index);
    }

    onDateRangeChanged(range: DateRange) {
        this.dateRange = range;
        this.fromDate = this.dateRange.fromDate;
        this.toDate = this.dateRange.toDate;
    }

    onTimeRangeChanged(range: TimeRange) {
        this.timeRange = range;
        this.fromTime = this.timeRange.fromTime;
        this.toTime = this.timeRange.toTime;
    }

    generateRrule() {
        const options: Partial<RRuleOptions> = {};

        options.freq = this.frequencyOption.freq;

        if (this.frequencyOption.freq !== RRule.YEARLY) {
            options.interval = this.interval;
        }

        if (this.frequencyOption.freq === RRule.WEEKLY) {
            options.byweekday = Array.from(this.weekDayMap.values()).map(o => o.weekday);
        }

        if (this.frequencyOption.freq === RRule.MONTHLY) {
            if (this.frequencyOption.value === Frequency.MONTHLY_FIXED_DATE) {
                options.bymonthday = this.monthFixedDate;
            }
            if (this.frequencyOption.value === Frequency.MONTHLY_RELATIVE_DAY) {
                options.bysetpos = this.monthRelativePos.value;
                options.byweekday = this.monthRelativeDay.weekday;
            }
        }

        if (this.frequencyOption.freq === RRule.YEARLY) {
            if (this.frequencyOption.value === Frequency.YEARLY_FIXED_DATE) {
                const date = dayjs(this.yearFixedDate);
                options.bymonth = date.month();
                options.bymonthday = date.date();
            }
            if (this.frequencyOption.value === Frequency.YEARLY_RELATIVE_DAY) {
                options.bysetpos = this.yearRelativePos.value;
                options.byweekday = this.yearRelativeDay.weekday;
                options.bymonth = this.yearRelativeMonth.value;
            }
        }

        return new RRule(options);
    }

    getDatetime(date: string | Date, time: string): Date {
        const [h, m, s] = (time || '')?.split(':');
        return dayjs(date).set('hour', +(h || 0)).set('minute', +(m || 0)).set('second', +(s || 0)).toDate();
    }

    isInvalid() {
        if (this.control) {
            return this.control.invalid;
        }
        return !this.fromDate || !this.toDate;
    }

    applyChanges() {
        const rrule = this.generateRrule();
        this.save.next({
            rule: rrule.toString(),
            behavior: this.behavior,
            recurringStartDate: this.getDatetime(this.dateRange.fromDate, this.timeRange?.fromTime),
            recurringEndDate: this.getDatetime(this.dateRange.toDate, this.timeRange?.toTime),
        });
    }

    resetEditor() {
        this.fromDate = undefined;
        this.toDate = undefined;
        this.fromTime = undefined;
        this.toTime = undefined;
        this.dateRange = undefined;
        this.timeRange = undefined;
        this.frequencyOption = this.frequencyOptions[0];
        this.interval = 1;
        this.weekDayMap = new Map();
        this.monthFixedDate = this.monthFixedDates[0];
        this.monthRelativeDay = this.weekDaysOptions[0];
        this.monthRelativePos = this.positionOptions[0];
        this.yearFixedDate = new Date();
        this.yearRelativeDay = this.weekDaysOptions[0];
        this.yearRelativePos = this.positionOptions[0];
        this.yearRelativeMonth = this.monthOptions[0];
        this.cdr.detectChanges();
    }
}
