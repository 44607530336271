/**
 * Created by max.ackerman on 8/21/2018.
 */


declare let angular;
export const NAME = "ftNextPage";

export const DEFINITION = {
    scope: false,
    require: "^ftPaging",
    link: function NextPageLinkFn(scope, elem, attrs, ctrl) {

        var hasNextPage;

        scope.$watch(() => { return scope.$eval(attrs.hasNextPage); }, (newHasNextPage) => {
            hasNextPage = newHasNextPage;
            return hasNextPage ? elem.removeClass('ng-hide') : elem.addClass('ng-hide');
        });

        function next() {
            if (!hasNextPage) return;
            scope.$apply(() => {
                ctrl.next();
            });
        }

        elem.on("click", next);

        scope.$on("$destroy", () => {
            elem.off("click", next);
        });
    }
};