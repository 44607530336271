/**
 * Created by Roman on 2014-10-15.
 */


angular
    .module("flipto.core.user", ['flipto.core.storage'])
    .config(['$provide', '$filterProvider', function ($provide, $filterProvider) {

        /**
         * Defined roles of admins
         */
        var AdminRole = {
            None: 0,
            FlipToAdmin: 1,
            FlipToStaff: 2,
            MultipassUser: 3
        }

        /**
         * Defined types of users
         * @type {{Admin: number, User: number, Anonymous: number}}
         */
        var UserType = {
            Anonymous: 0,
            Admin: 1,
            User: 2
        };
        /**
         * Defined roles of users
         */
        var UserRole = {
            None: 0,
            PlatformLead: 1,
            Editor: 2,
            CooperativeUser: 3,
            Reviewer: 4
        };

        var ExternalUserType = {
            None: 0,
            FliptoStaff: 1,
            ExternalUser: 2
        }

        var AnonymousUser = {
            type: UserType.Anonymous,
            Companies: [],
            token: null
        };


        /**
         * Object that represents currently logged in user
         * @type {{current: null, company: null, property: null}}
         */
        var user = {
            current: null,
            company: null,
            property: null
        };
/*
        $provide.constant('user', user);
        $provide.constant('UserType', UserType);
        $provide.factory('IdentityService', ['localStorage', 'sessionStorage', 'user', '_', '$injector', function (localStorage, sessionStorage, user, _, injector) {

            function getUser() {
                return localStorage.get('flipto.session') || AnonymousUser;
            }

            function getToken() {
                return getUser().token;
            }

            function getPropertyId() {
                return user && user.property ? user.property.id : null;
            }

            function isFliptoStaff() {
                var user = getUser();
                if (!user) return false;

                // Flipto admin outside of impersonation
                if (user.type === UserType.Admin &&
                    (user.role === AdminRole.FlipToAdmin || user.role === AdminRole.FlipToStaff)) return true;

                // Flipto admin impersonating user in company
                return user.externalUserType === ExternalUserType.FliptoStaff;
            }

            function isCooperativeUser() {
                var user = getUser();
                return user && user.role === UserRole.CooperativeUser;
            }

            function isPlatformLeadUser() {
                var user = getUser();
                return user && user.role === UserRole.PlatformLead;
            }

            function isEditorUser() {
                var user = getUser();
                return user && user.role === UserRole.Editor;
            }

            function checkV2Permissions(requiredPermissions, propertyUuid) {
                if (!injector || !injector.has("$ngRedux"))
                    return null;
                var redux = injector.get("$ngRedux");
                var user = getUser();
                var applicationState = redux.getState();
                if (!propertyUuid) {
                    var property = applicationState.apiData.properties.find(function (property) {
                        return property.slug === applicationState.ui.context.singleProperty;
                    });
                    if (property == null) {
                        return null;
                    }
                    propertyUuid = property.id;
                }

                var company = applicationState.session.Companies.find(function (company) {
                    return company.Code === user.companyCode;
                });
                if (company == null) {
                    return null;
                }
                var currentPermissions = company.PropertyPermissions[propertyUuid];
                //TODO: FIND A WAY TO USE ENUM
                return (currentPermissions & requiredPermissions) === requiredPermissions;
            }

            return {
                getUser: getUser,
                getToken: getToken,
                getPropertyId: getPropertyId,
                logout: function () {
                    localStorage.remove('flipto.session');
                },
                isAdmin: function () {
                    var user = getUser();
                    return user && user.type === UserType.Admin;
                },
                isFliptoStaff: isFliptoStaff,
                isCooperativeUser: isCooperativeUser,
                isPlatformLeadUser: isPlatformLeadUser,
                isEditorUser: isEditorUser,
                isExternalUser: function () {
                    var user = getUser();
                    if (!user) return false;

                    // External partner or FlipToStaff outside of impersonation
                    if (user.type === UserType.Admin && user.role !== AdminRole.None) return true;

                    // External partner or FlipToStaff impersonating user in company
                    return user.externalUserType !== ExternalUserType.None;
                },
                isAnonymous: function () {
                    return !getUser() || getUser().type === UserType.Anonymous;
                },
                //TODO: Find a way to use Permission ENUM here!
                hasPiiPermissions: function (propertyUuid) {
                    // v2 using redux through injector due to v1 code
                    var isValid = checkV2Permissions(1 << 17, propertyUuid);
                    if (isValid != null)
                        return isValid;
                    // V1 Fallback
                    var user = getUser();
                    return user && user.role !== UserRole.CooperativeUser;
                },
                hasPeoplePermissions: function () {
                    // v2 using redux through injector due to v1 code
                    var isValid = checkV2Permissions(1 << 4);
                    if (isValid != null)
                        return isValid;
                    // V1 Fallback
                    return true;
                }
            };
        }]);

        */
        
    }]);