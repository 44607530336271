/**
 * Created by roman.kupin on 1/29/2018.
 */

import "v1/features";
import "v1/components/changes-transformer/transformer";

import "v1/components/states/states";

import "v1/components/campaigns/module";
import "v1/components/campaigns/filters/campaignName";
import "v1/components/campaigns/directives/campaignListDirective";
import "v1/components/campaigns/directives/campaignModeSelector";
import "v1/components/campaigns/constants/campaignMode";
import "v1/components/campaigns/constants/campaignTypes";

import "v1/components/goals/module";
import "v1/components/goals/filters/editGoalsTitle.filter";
import "v1/components/goals/filters/goalDescription";
import "v1/components/goals/filters/goalName";
import "v1/components/goals/factories/goalDescriptor";
import "v1/components/goals/factories/goalsSerializer";
import "v1/components/goals/directives/campaignGoals";
import "v1/components/goals/directives/editCampaignGoals";
import "v1/components/goals/directives/editGoals";
import "v1/components/goals/directives/goalSelector";
import "v1/components/goals/directives/goalsPanel";
import "v1/components/goals/directives/incentiveSelector";
import "v1/components/goals/constants/goalType";

import "v1/components/incentives/module";
import "v1/components/incentives/filters/incentiveMode";
import "v1/components/incentives/filters/incentiveName";
import "v1/components/incentives/filters/incentiveType";

import "v1/components/touchpoints/module";
import "v1/components/touchpoints/services/touchpointActivator";
import "v1/components/touchpoints/directives/touchpointActive";
import "v1/components/touchpoints/directives/touchpointContext";
import "v1/components/touchpoints/directives/touchpointGroupStatusAction";
import "v1/components/touchpoints/directives/touchpointInactive";
import "v1/components/touchpoints/directives/touchpointStatusAction";
import "v1/components/touchpoints/directives/touchpointToggleStatus";

import "v1/components/curation/module";
import "v1/components/curation/curationModal.service";
import "v1/components/curation/curationModal.directive";
import "v1/components/curation/shareOAuth.service";

import "v1/components/rating/rating"

import "v1/sections/platform/layer/touchpoint/messaging/messaging.controller";
import "v1/sections/platform/layer/homepage/homepage.messaging.controller";
import "v1/sections/platform/layer/touchpoint/styling/styling.controller";
import "v1/sections/platform/layer/touchpoint/styling/styling.email-template.controller";
import "v1/sections/platform/layer/touchpoint/styling/styling.reminder-emails-template.controller";
import "v1/sections/platform/layer/touchpoint/styling/styling.reengagement-emails-template.controller";
import "v1/sections/platform/layer/touchpoint/integration/integration.controller";

import "v1/sections/platform/layer/warm-lead-nurturing/emailsData.controller";

import "v1/sections/platform/layer/pre-stay/touchpoint/integration/confirmationDisplayType.filter";
import "v1/sections/platform/layer/post-stay/touchpoint/integration/modeName";
import "v1/sections/platform/layer/photo-advocacy/touchpoint/cnameUrl.filter";
import "v1/sections/platform/layer/photo-advocacy/touchpoint/integration/insertionType.filter";
import "v1/sections/platform/layer/touchpoint/touchPointName.filter.js";

import "v1/sections/platform/layer/campaign/campaignController";
import "v1/sections/platform/layer/pre-stay/campaign/nights.filter";

import "v1/components/version-panel/version-panel";
import "v1/language/language";



import "v1/components/text-angular-tools/textAngularTools";
import "v1/components/field-editors/module";
import "v1/components/field-editors/boolField";
import "v1/components/field-editors/codeField";
import "v1/components/field-editors/dateField";
import "v1/components/field-editors/htmlField";
import "v1/components/field-editors/numberField";
import "v1/components/field-editors/selectField";
import "v1/components/field-editors/textAreaField";
import "v1/components/field-editors/textField";

import "v1/sections/settings/subsections/languages/languages.controller";
import "v1/sections/settings/subsections/notifications/notifications.controller";
import "v1/sections/settings/subsections/privacy/privacy.controller";
import "v1/sections/settings/subsections/demos/demos.controller";
import "v1/sections/settings/subsections/demos/demo.service";
import "v1/sections/settings/subsections/demos/demotype.service";

import "v1/components/notifications/filters/notificationName";
import "v1/components/notifications/filters/notificationDescription";

import "v1/sections/platform/layer/post-stay/postStayVersions.js"

import "v1/sections/platform/layer/photo-advocacy/contest/contestStatus.directive";
import "v1/sections/platform/layer/photo-advocacy/contest/finalistIncentives";
import "v1/sections/platform/layer/photo-advocacy/contest/invitationShareModal.directive";
import "v1/sections/platform/layer/photo-advocacy/contest/invitationShareModal.service";
import "v1/sections/platform/layer/photo-advocacy/contest/details.controller";
import "v1/sections/platform/layer/photo-advocacy/contest/submissions.controller";
import "v1/sections/platform/layer/photo-advocacy/contest/winners.controller";
import "v1/sections/platform/layer/photo-advocacy/submissionSummary.directive"
import "v1/sections/platform/layer/photo-advocacy/winnerSummary.directive"

import "v1/sections/reports/reports.controller";
import "v1/sections/admin/reports/reports.controller";
import "v1/components/reports/reports.module";
import "v1/components/reports/reportPanel.component";

import "v1/sections/stories/storyCard.directive";
import "v1/sections/stories/quotes.controller";
import "v1/sections/stories/photos.controller";
import "v1/sections/stories/library/library.controller";
import "v1/sections/stories/curation/curation.controller";

import "v1/sections/people/guestCard.directive";
import "v1/sections/people/activityType.filter";
import "v1/sections/people/directory/directory.controller";
import "v1/sections/people/feedback/feedback.controller";
import "v1/sections/people/details/details.controller";

import "v1/sections/account/settings/domains/domains.controller";

import "v1/components/dashboard/importantOnlySocialConnections.filter";

import "v1/components/ratings-meter/ratingsMeter"
import "v1/components/profile/profile"

import "v1/components/image-library/imageLibrary";
import "v1/components/image-library/imageList";
import "v1/components/image-library/imagePreview";
import "v1/components/image-library/imageProperties";
import "v1/components/image-library/imageUpload";
import "v1/components/image-library/imageUploadFromUrl";
import "v1/components/email-images-preview/emailImagesPreview.component";

import "v1/components/podium/podium";

import "v1/components/location-autocomplete/autocomplete";

import "v1/sections/settings/subsections/incentives/incentiveTags.constant";

declare let angular;

export default angular.module("v1imports.features", [
    "flipto.accountv1.SharedFeatures",
    "flipto.account.language",
    "flipto.components.states",
    "flipto.components.reports",
    "flipto.components.rating",
    "flipto.components.ratingsMeter",
    "flipto.components.profile",
    "flipto.components.podium",
    "flipto.components.autocomplete"
])
