import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import { CompanyLanguage } from 'account-hybrid/common/models/company-language.model';

export interface Language {
    languageName: string;
    languageCode: string | number;
}

@Component({
    selector: 'ft-language-preview-panel',
    templateUrl: './language-preview-panel.component.html',
    styleUrls: ['./language-preview-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguagePreviewPanelComponent implements OnChanges {
    @Input() languages: Language[] | CompanyLanguage[] | any = [
        {
            languageName: 'English',
            languageCode: 'en'
        }
    ];
    @Input() selectedLanguage = this.languages[0];
    @Input() simplified = false;
    @Input() bindLabel = 'languageName';
    @Input() bindValue = 'languageCode';
    @Output() previewLanguageChange = new EventEmitter<Language | CompanyLanguage>();

    defaultLanguageCode = 'en';

    compareLanguages(a: Language, b: Language): boolean {
        return a.languageCode == b.languageCode;
    }

    onPreviewLanguageChange(language: Language) {
        this.selectedLanguage = language;
        this.previewLanguageChange.emit(language);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('languages') && Array.isArray(changes.languages.currentValue)) {
            const { languages: { currentValue = null } } = changes;
            this.languages = currentValue
                .map(language => ({
                    ...language,
                    languageName: language?.languageName ?? language?.name,
                    languageCode: language?.languageCode ?? language?.code
                }));

            this.onPreviewLanguageChange(this.languages[0]);
        }
    }
}
