/**
 * Created by Roman on 2014-10-06.
 */



angular
    .module('flipto.components.goals')
    .directive('ftGoalsPanel', ['$sce', '$filter', 'ftModal', '_', "parse", function ($sce, $filter, ftModal, _, parse) {

        var goalDescriptionFilter = $filter("goalDescription");

        return {
            restrict: 'E',
            templateUrl: '/app/components/goals/directives/goalsPanel.html',
            scope: {
                goals: '=',
                propertyIncentives: '=',
                culture: '=',
                onSaved: '&',
                type: '='
            },
            compile: function (tElem) {

                var modalId = 'editGoalsModal' + parseInt((Math.random() * 1000), 10);
                tElem.find('ft-modal').attr('id', modalId);

                return function (scope) {

                    scope.filteredByTagIncentives = {item:[]};
                    scope.$watch("propertyIncentives", function (incentives) {
                       if(incentives) {
                           parse.arrayOrDefault(incentives).forEach(function(incentive) {
                               if((parse.int(incentive.categoryFlags) & scope.type) === scope.type) {
                                   scope.filteredByTagIncentives.item.push(incentive);
                               }
                           });
                       }
                    });

                    scope.getGoalDescription = function (goal) {
                        return $sce.trustAsHtml(goalDescriptionFilter(goal, scope.culture));
                    };

                    scope.showModal = function () {
                        scope.editGoalsModel = {
                            newGoals: [],
                            updateGoals: $filter("filter")(scope.goals, {type: scope.type}),
                            deleteGoals: []
                        };
                        ftModal.show(modalId);
                    };

                    scope.save = function () {
                        var goalsToBeRemoved = _.remove(scope.editGoalsModel.updateGoals, function (goal) {
                            return goal.markedForRemoval === true;
                        });
                        scope.editGoalsModel.deleteGoals = _.map(goalsToBeRemoved, function (goal) {
                            return goal.id;
                        });

                        scope.onSaved({goals: scope.editGoalsModel, type: scope.type});
                    };
                }
            }
        }
    }]);