/**
 * Created by roman.kupin on 10/3/2017.
 */



export const NAME = "ftCarouselCardsMoveRight";

export const DEFINITION = [function () {
    return {
        scope: false,
        require: "^ftCarouselCards",
        link: function (scope, elem, attrs, ctrl) {

            const cardsCtrl = ctrl;

            function move() {
                scope.$apply(() => {
                    if(cardsCtrl.activeCardIndex < cardsCtrl.cards.length - 1) {
                        cardsCtrl.moveRight();
                    }
                });
            }

            elem.bind("click", move);

            scope.$on("$destroy", () => {
                elem.unbind("click", move);
            });
        }
    };
}];

