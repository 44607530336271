/**
 * Created by Roman on 2015-03-13.
 */


angular.module('flipto.components.common.modelFormatters')
    .directive('ftDecode', function () {

        /**
         * Decode html
         * @param {String} html
         * @returns {String}
         */
        function decodeHtml(html) {
            var txt = document.createElement("textarea");
            txt.innerHTML = html;
            return txt.value;
        }

        return {
            require: 'ngModel',
            link: function (scope, element, attrs, modelCtrl) {

                modelCtrl.$parsers.push(function (viewValue) {
                    return decodeHtml(viewValue);
                });

            }
        };
    });