/**
 * Created by ostapenko.r on 9/23/2014.
 */

angular.module('flipto.components.common.fieldEditors')
    .factory('fieldEditor', ['_', function (_) {

        /**
         * Construct of field editor
         * @param {Object} source
         * @constructor
         */
        function FieldEditor(source) {

            /**
             * Type of field editor
             * @type {string}
             */
            this.type = source.type;

            /**
             * Attributes of field editor tag
             * @type {Array}
             */
            this.attributes = [];

            /**
             * Tag name template
             * @type {string}
             */
            this.tagName = 'ft-[type]-field';

            /**
             * Html element template
             * @type {string}
             */
            this.template = '<[tagName] [attributes]></[tagName]>';

            /**
             * Html code of the editor
             * @type {string}
             */
            this.html = '';

            /**
             * Source data
             * @type {Object}
             */
            this.source = source;


            this.constructAttributes = constructAttributes;

        }

        /**
         * Add attribute to field editor tag
         * @param {string} key
         * @param {string} value
         */
        FieldEditor.prototype.addAttribute = function (key, value) {
            this.attributes.push({key: key, value: value});
        };

        /**
         * Get field editor html code
         * @returns {string}
         */
        FieldEditor.prototype.getHtml = function () {
            this.constructEditor();
            return this.html;
        };

        /**
         * Get field editor element
         * @returns {Element}
         */
        FieldEditor.prototype.getElement = function () {
            return angular.element(this.html);
        };


        FieldEditor.prototype.constructEditor = function () {

            if (this.source.type === 'text' && this.source.tokens) {
                this.source.type = 'html';
                this.addAttribute('mode', 'text');
                this.addAttribute('trimClipboardFormat', this.source.trimclipboardformat);
            }
            this.constructAttributes(this.source);

            var attributesHtml = _.reduce(this.attributes, function (result, attribute) {
                return result + ' ' + attribute.key + '="' + attribute.value + '"';
            }, '');

            this.html = this.template
                .replace('[tagName]', this.tagName.replace('[type]', this.source.type))
                .replace('[attributes]', attributesHtml)
                .replace('[tagName]', this.tagName.replace('[type]', this.source.type));

        };

        function constructAttributes(source) {
            var self = this;
            var availableOptionsByType = {};
            availableOptionsByType['html'] = ['required', 'ng-minlength', 'ng-maxlength', 'available-tags', 'replace-wrapper', 'remove-wrapper', 'type'];
            availableOptionsByType['textarea'] = ['required', 'ng-minlength', 'ng-maxlength', 'pattern', 'height'];
            availableOptionsByType['text'] = ['required', 'ng-minlength', 'ng-maxlength', 'type', 'pattern'];
            availableOptionsByType['number'] = ['required', 'min', 'max', 'pattern'];
            availableOptionsByType['js'] = ['required'];
            availableOptionsByType['css'] = ['required'];
            availableOptionsByType['json'] = ['required'];
            availableOptionsByType['select'] = ['required'];

            if (source.fieldsOptions) {
                var options = availableOptionsByType[source.type] || [];
                _.forEach(options, function (option) {
                    if (angular.isDefined(source.fieldsOptions[option])) {
                        self.addAttribute(option, source.fieldsOptions[option]);
                    }
                });
            }
            self.addAttribute('fields', source.fields);

            switch (source.type) {
                case 'html':
                    self.addAttribute('languages', source.datasource);
                    self.addAttribute('tokens', source.tokens);
                    self.addAttribute('trimclipboardformat', source.trimclipboardformat);
                    self.addAttribute('readonlytext', source.readonlytext);
                    break;
                default:
                    self.addAttribute('datasource', source.datasource);

            }

        }

        return FieldEditor;
    }]);
