import { createAction, props } from '@ngrx/store';
import { FriendLandingIntegrationSettings, FriendLandingSettings, FriendLandingStyleSettings } from '../../models/friend-landing';

// messaging
export const loadFriendLanding = createAction('[Friend Landing] load',
props<{
    componentGroupUuid: string,
    propertyId: string,
    typeRequest: string,
    state: string
}>());

export const loadFriendLandingSuccess = createAction('[Friend Landing] loadSuccess', props<{ data: FriendLandingSettings }>());
export const loadFriendLandingFail = createAction('[Friend Landing] loadFail', props<{ error: any }>());

//styling
export const loadStylingFriendLanding = createAction('[Friend Landing] load styling',
props<{
    componentGroupUuid: string,
    propertyId: string,
    typeRequest: string,
    state: string
}>());

export const loadStylingFriendLandingSuccess = createAction('[Friend Landing] styling loadSuccess', props<{ data: FriendLandingStyleSettings }>());
export const loadStylingFriendLandingFail = createAction('[Friend Landing] styling loadFail', props<{ error: any }>());

//integration
export const loadIntegrationFriendLanding = createAction('[Friend Landing] load integration',
props<{
    componentGroupUuid: string,
    propertyId: string,
    typeRequest: string,
    state: string
}>());

export const loadIntegrationFriendLandingSuccess = createAction('[Friend Landing] integration loadSuccess', props<{ data: FriendLandingIntegrationSettings }>());
export const loadIntegrationFriendLandingFail = createAction('[Friend Landing] integration loadFail', props<{ error: any }>());

// change data
export const changeFriendLanding = createAction('[Friend Landing] Change Friend Landing',
  props<{
    componentGroupUuid: string,
    propertyId: string,
    typeRequest: string,
    state: string,
    changes: Partial<FriendLandingSettings>,
    tab: string
}>());
export const changeFriendLandingSuccess = createAction('Friend Landing] changeSuccess',
props<{ changes: any }>());
export const changeFriendLandingFail = createAction('[Friend Landing] changeFail',
props<{ error: any }>());

// toogle
export const commitFriendLanding = createAction('[Friend Landing] commit',
    props<{
        componentGroupUuid: string,
        propertyId: string,
        isActive: boolean
    }>());
export const commitFriendLandingSuccess = createAction('[Friend Landing] commitSuccess', props<{ data: FriendLandingSettings }>());
export const commitFriendLandingFail = createAction('[Friend Landing] commitFail', props<{ error: any }>());
