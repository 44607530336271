/* global angular */

angular.module('flipto.accountv1.SharedFeatures')
    .controller('flipto.account.sections.platform.CampaignController', [
        '$scope', '$rootScope', 'Campaign', "Incentives", 'campaignId', 'layerName', 'isCreating', '_', 'changesTransformer', 'property', 'page', 'SettingsLanguages', 'CampaignType', 'ftModal', 'languageHelper', 'campaignCreatedCallback', 'campaignCreateCancelCallback',
        function ($scope, $rootScope, Campaign, Incentives, campaignId, layerName, isCreating, _, changesTransformer, property, page, Languages, CampaignType, ftModal, languageHelper, campaignCreatedCallback, campaignCreateCancelCallback) {
            'use strict';

            var  selectedDateRange,
                originalLayerName = layerName,
                layerName = layerName.replace('-', ''),
                model = {
                    campaignId: campaignId,
                    isCreating: isCreating,
                    campaignType: CampaignType,
                    stayLengths: _.range(1, 8)
                };

            angular.extend($scope, {model: model});

            if(!angular.isFunction(campaignCreatedCallback)) {
                campaignCreatedCallback = function goToCampaign(campaign) {
                    page.open('flipto.property.overview.platform.' + originalLayerName + '.campaign.details', {campaignId: campaign.id.toUpperCase()});
                }
            }

            $scope.reloadGoals = function () {
                $scope.activate();
            };

            $scope.removeItemFrom = function (item, collection) {
                collection.splice(collection.indexOf(item), 1);
            };

            $scope.editMessage = function (message) {
                $scope.model.selectedMessage = message;
                $scope.model.editingNewMessage = false;
                ftModal.show(layerName + '.editMessageModal');
            };

            $scope.closeMessageEdit = function () {
                ftModal.get(layerName + '.editMessageModal').done();
            };

            $scope.deleteMessage = function (message) {
                _.remove($scope.model.campaign.messages, function (campaignMessage) {
                    return campaignMessage == message;
                });
                $scope.closeMessageEdit();
            };

            $scope.addMessage = function () {
                var newMessage = {
                    isActive: true,
                    languages: _.map($scope.model.languages, function (language) {
                        return {
                            name: language.name,
                            code: language.code,
                            text: ''
                        };
                    })
                };
                $scope.model.campaign.messages.push(newMessage);
                $scope.editMessage(newMessage);
                $scope.model.editingNewMessage = true;
            };

            $scope.editMessageCancel = function () {
                if ($scope.model.editingNewMessage) {
                    _.remove($scope.model.campaign.messages, function (campaignMessage) {
                        return campaignMessage == $scope.model.selectedMessage;
                    });
                    $scope.model.editingNewMessage = false;
                }
            };

            $scope.activate = function() {
                if (model.isCreating) {
                    setupCreating();
                } else {
                    setupEditing();
                }

                Incentives
                    .getAvailableIncentives(property.id)
                    .then(function (response) {
                        $scope.propertyIncentives = response.data;
                    });
            };

            function setupEditing() {
                return Campaign.get({id: model.campaignId, layerName: layerName, propertyUuid: property.id})
                    .then(prepareCampaignEditResponse);
            }

            function prepareCampaignEditResponse(response) {
                model.campaign = response;
                model.languages = response.languages.item;
                ensureMessages(model.campaign, model.languages);
            }

            function setupCreating() {
                return Languages.get({ propertyId: property.id }).then(function (data) {
                    model.campaign = Campaign.emptyCampaign();
                    model.languages = data.languages.item;
                    model.campaign.type = CampaignType.Custom;
                    ensureMessages(model.campaign, model.languages);
                });
            }

            function ensureMessages(campaign, languages){
                if(!campaign.messages || !campaign.messages.length){
                    var message = createDefaultMessage();
                    languageHelper.merge(message.languages, languages);
                    campaign.messages = [message];
                }
            }

            function createDefaultMessage(){
                var message = {isActive: 1, languages: []};
                if(layerName==='prestay'){
                    message.languages.push({code: 'en', text: 'Planning my upcoming trip - staying at {property}. Some perks for friends:', name: 'English'});
                } else if(layerName==='groupadvocacy'){
                    message.languages.push({code: 'en', text: 'Attending {group-casual-name} - staying at {property}. A few comps for friends & attendees:', name: 'English'});
                }
                return message;
            }


            $scope.commit = function () {
                var params = {propertyUUID: property.id, layerName: layerName, id: model.campaign.id};

                (function (campaign) {
                    if (model.isCreating) {
                        Campaign.create(params, campaign)
                            .then(campaignCreatedCallback).then(function () {
                                $rootScope.$broadcast('campaign.changed', campaign);
                            });
                    } else {
                        Campaign.update(params, campaign)
                            .then(prepareCampaignEditResponse)
                            .then(function () {
                                $scope.campaignSnapshot.retake();
                                $rootScope.$broadcast('campaign.changed', campaign);
                            });
                    }
                })($scope.model.campaign)

            };

            $scope.setupCampaignSnapshot = function (snapshot) {
                $scope.campaignSnapshot = snapshot;
            };

            $scope.cancel = campaignCreateCancelCallback || function () {
                page.open('flipto.property.overview.platform.' + originalLayerName);
            };

            $scope.languageNotAdded = function (language) {
                return !_.some(model.campaign.criteria.languages.item, {code: language.code})
            };

            $scope.lessThan = function (max) {
                return function (value) {
                    return max ? value <= max : true;
                }
            };

            $scope.minNotExist = function (value) {
                return !_.some(model.campaign.criteria.stayLengths.item, {minNights: value});
            };


            $scope.moreThan = function (min) {
                return function (value) {
                    return min ? value >= min : true;
                }
            };

            $scope.updateDateRange = function (startDate, endDate) {
                angular.extend(selectedDateRange, {
                    startMonth: startDate.month() + 1, startDay: startDate.date(), endMonth: endDate.month() + 1, endDay: endDate.date()
                });
            };

            $scope.updateDateRangeForDate = function (startDate, endDate) {
                angular.extend(selectedDateRange, {
                    startDate: moment(startDate).format('YYYY-MM-DD'),
                    endDate: moment(endDate).format('YYYY-MM-DD')
                });
            };

            $scope.addNewRange = function (startDate, endDate) {
                model.campaign.criteria.dateRanges.item.push({
                    startMonth: startDate.month() + 1, startDay: startDate.date(), endMonth: endDate.month() + 1, endDay: endDate.date()
                });
            };

            $scope.addNewRangeForDate = function (startDate, endDate) {
                model.campaign.criteria.dateRanges.item.push({
                    startDate: moment(startDate).format('YYYY-MM-DD'),
                    endDate: moment(endDate).format('YYYY-MM-DD')
                });
            };

            $scope.selectDateRange = function (dateRange) {
                selectedDateRange = dateRange;
            };

            $scope.activate();
        }
    ]);
