/**
 * Created by Roman on 2015-04-24.
 */

angular.module('flipto.components.incentives')
    .filter('incentiveType', ['$filter', function ($filter) {

        var translate = $filter("translate");
        var namesByType = {
            '1': translate('flipto.components.incentives.types.Promoter'),
            '3': translate('flipto.components.incentives.types.PoststayParticipation'),
            '4': translate('flipto.components.incentives.types.PoststayAdvocate'),
            '6': translate('flipto.components.incentives.types.PoststayPhotoSubmission')
        };

        return function (incentive, typeKey) {
            if (!!incentive) {
                return namesByType[incentive[typeKey]];
            }
        };

    }]);
