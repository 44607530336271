import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { DiscoveryApiService } from "account-hybrid/common/components/touchpoints/services/discovery-api.service";
import {
    load,
    loadSuccess,
    loadFail,
    commit,
    commitSuccess,
    commitFail,
    create,
    createSuccess,
    createFail
} from './actions';
import { select, Store } from "@ngrx/store";
import * as fromTouchpoints from "account-hybrid/common/components/touchpoints/store";


@Injectable()
export class BooknowEffects {
    load$ = createEffect(() =>
        this.actions$.pipe(
            ofType(load),
            mergeMap((action) =>
                this.discoveryApiService.getComponentSettings(action.componentGroupUuid, 'booknow')
                    .pipe(
                        map(data => loadSuccess({data})),
                        catchError(error => of(loadFail({error})))
                    ))
        )
    );

    commit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(commit),
            withLatestFrom(
                this.store.pipe(select(fromTouchpoints.getBooknowSelectedComponentUuid))
            ),
            mergeMap(([{componentGroupUuid, data}, componentUuid]) =>
                this.discoveryApiService.updateComponentSettings(componentGroupUuid, 'booknow', componentUuid, data)
                    .pipe(
                        map(settings => commitSuccess({data: settings})),
                        catchError(error => of(commitFail({error})))
                    ))
        )
    );

    create$ = createEffect(() =>
        this.actions$.pipe(
            ofType(create),
            mergeMap(action =>
                this.discoveryApiService.createComponent(action.componentGroupUuid, 'booknow', action.data)
                    .pipe(
                        map(data => createSuccess({data})),
                        catchError(error => of(createFail({error})))
                    ))
        )
    );

    constructor(private actions$: Actions,
                private store: Store<fromTouchpoints.State>,
                private discoveryApiService: DiscoveryApiService) {
    }
}

