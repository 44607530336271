/**
 * Created by roman.kupin on 9/18/2017.
 */

import * as BranchesDirective from "./branches.directive";

declare var angular;
export const BRANCHES_MODULE = angular.module("Components.Navigation.Branches", []);

export class ComponentConfig {

    static $inject = ["$compileProvider"];

    constructor($compileProvider) {
        const directives = [BranchesDirective];
        directives.forEach(directive => {
            $compileProvider.directive(directive.NAME, () => directive.DEFINITION);
        });
    }

}

BRANCHES_MODULE.config(ComponentConfig);