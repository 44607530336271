/**
 * User: Kupin.R
 * Date: 5/29/14
 */



angular
    .module("flipto.components.goals")
    .constant("goalType", {
        "SpreadTheWord": 0,
        "GetSocialConnections": 1,
        "GetUniqueVisitors": 2,
        "SignupAndGet": 3,
        "SubmitQuoteAndGet": 4,
        "ShareAndGet": 5,
        "SubmitPhotoAndGet": 6,
        "PhotoContestWinner": 7,
        "AttendeeSignup": 8
    });
