/**
 * Created by roman.kupin on 6/13/2018.
 */

import * as fromSpaces from 'account-hybrid/common/components/spaces/store/reducers/spaces.reducer';
import { SpaceType, getPathForSpace, storedHistory } from 'account-hybrid/common/components/spaces';


export class StateConfig {

    static $inject = ["$stateProvider"];

    constructor($stateProvider) {

        $stateProvider
            .state({
                name: "timeline",
                url: "/timeline/{companyCode:[\\d\\w]{2}}/{guestUUID:[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}}",
                data: {
                    requiresAuth: true
                },
                resolve: {
                    companyCode: ['$stateParams', function ($stateParams) {
                        return $stateParams.companyCode;
                    }],
                    guestUUID: ['$stateParams', function ($stateParams) {
                        return $stateParams.guestUUID;
                    }]
                },
                controller: ["companyCode", "guestUUID", 'Guests', 'SessionService', function (companyCode, guestUUID, Guests, session) {
                    Guests.details(guestUUID)
                        .then((details) => {
                            const userSession = session.get();
                            const spaces = fromSpaces.createSpaces({ companies: userSession.Companies, properties: userSession.Properties, history: storedHistory() });
                            const space = spaces.find(s => s.type === SpaceType.Property && s.slug === details.propertySlug) ||
                                spaces.find(s => s.type === SpaceType.Mixed && s.slug === `${companyCode}-${details.propertySlug}`);

                            location.href = !!space ? `${getPathForSpace(space)}/people/library/${guestUUID}` : '/home';
                        });
                }],
                template:
                    `
                    <div class="ft-loader-orgswitch">
                        <div class="ft-cube-face-single">
                            <div class="ft-content">
                                <div class="ft-loading-message">
                                    Loading <div class="ft2-spinner"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                            `
            });

    }
}
