<ft-pending-changes *ngIf="isInitialized && !disabled"
                    [alwaysVisible]="alwaysVisible"
                    [canCommit]="canCommit"
                    [validationRules]="validationRules"
                    [message]="message"
                    [draftSaveVisible]="draftSaveVisible"
                    [cancelVisible]="cancelVisible"
                    [publishVisible]="publishVisible"
                    [totalChanges]="totalChanges"
                    [showCounter]="showCounter"
                    (cancel)="onCancel()"
                    (commit)="onCommit()"
                    (draftCommit)="onDraftCommit()"
                    [draftSaveButtonLabel]="draftSaveButtonLabel"
                    [cancelConfirmation]="cancelConfirmation"
                    [loading$]="loading$">
</ft-pending-changes>
<ft-loading *ngIf="loading$ && !isInitialized && !disabled" [loading$]="loading$"></ft-loading>
<ng-content></ng-content>
