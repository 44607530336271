    /**
 * Created by Roman on 2016-01-06.
 */


angular.module('flipto.accountv1.SharedFeatures')
    .filter('cnameUrl', ['_', 'environment', 'urlFormatter', 'parse', function (_, environment, urlFormatter, parse) {

        var routePurpose = {
            General: 0,
            PhotoExplorer: 1,
            Landing: 2,
            Invite: 3,
            Poststay: 4,
            Trip: 5
        };

        function createUrl(host, slug, app, params) {
            var segments = ['https://', host];

            if (slug) {
                segments.push('/');
                segments.push(slug);
            }

            if (app) {
                segments.push('/');
                segments.push(app);
            }

            var baseUrl = Array.prototype.join.call(segments, '');

            return urlFormatter.format(baseUrl, params, true);
        }

        function getPurposeAsText(purpose) {
            if (purpose === routePurpose.PhotoExplorer) return "photo-explorer";
            if (purpose === routePurpose.Landing) return "landing";
            if (purpose === routePurpose.Invite) return "invite";
            if (purpose === routePurpose.Poststay) return "poststay";
            if (purpose === routePurpose.Trip) return "trip";
            return "";
        }

        // This section of code is duplicated in the UnauthenticatedSubdomainService -> PrettyUrlFromCampaign
        // Ensure any changes occur in both places
        return function (domains, purpose, propertySlug, params) {
            var _domains = parse.arrayOrDefault(domains);
            if (!_domains.length) {
                return "";
            }
            var sortedDomains = _domains.slice().sort(function (a, b) {
                //order by default (custom first, default last)
                var x = a.isDefault - b.isDefault;
                if (x !== 0) {
                    return x;
                }

                // Then by purpose descending (specific first, general last)
                x = b.routePurposeId - a.routePurposeId;
                if (x !== 0) {
                    return x;
                }

                // Then by date descending (recent first, old last)
                return new Date(b.createDate) - new Date(a.createDate);
            });

            if (sortedDomains.length === 0) {
                return "";
            }

            var selectedDomain = sortedDomains[0];
            var properties = parse.arrayOrDefault(selectedDomain.properties);
            var includeSlug = properties.length > 1;
            var includePurpose = selectedDomain.routePurpose !== purpose;
            var cnameUrl = createUrl(parse.bool(selectedDomain.isDefault) ? selectedDomain.host + "." + environment.FliptoHubDomain : selectedDomain.host, includeSlug ? propertySlug : null, includePurpose ? getPurposeAsText(purpose) : null, params);
            return cnameUrl;
        };
    }]);