import { Pipe, PipeTransform } from "@angular/core";
import { cloneDeep } from "lodash-es";

@Pipe({
    name: 'clone'
})
export class ClonePipe implements PipeTransform {
    transform(value, args?: any) {
        return cloneDeep(value);
    }
}
