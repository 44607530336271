import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContextSelectorComponent } from './components/contex-selector/context-selector.component';
import { AccountSharedModule } from 'apps/account/src/account-shared';
import { StoreModule } from '@ngrx/store';
import { CONTEXT_SELECTOR_FEATURE_NAME, contextSelectorReducer } from 'account-hybrid/features/context-selector/store';
import { ContextSelectorLinkComponent } from 'account-hybrid/features/context-selector/components/context-selector-link/context-selector-link.component';
import { FormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

@NgModule({
    declarations: [ContextSelectorComponent, ContextSelectorLinkComponent],
    exports: [
        ContextSelectorLinkComponent,
        ContextSelectorComponent
    ],
    imports: [
        CommonModule,
        AccountSharedModule,
        StoreModule.forFeature(CONTEXT_SELECTOR_FEATURE_NAME, contextSelectorReducer),
        FormsModule,
        Ng2SearchPipeModule
    ]
})
export class ContextSelectorModule {
}
