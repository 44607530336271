import { createAction, props } from '@ngrx/store';
import { ComponentSettingsBase } from '../../models/component-settings-base';

export const load = createAction('[Discovery Bookingbar] load',
    props<{
        componentGroupUuid: string
    }>());
export const loadSuccess = createAction('[Discovery Bookingbar] loadSuccess', props<{ data: ComponentSettingsBase }>());
export const loadFail = createAction('[Discovery Bookingbar] loadFail', props<{ error: any }>());

export const commit = createAction('[Discovery Bookingbar] commit',
    props<{
        componentGroupUuid: string,
        data: Partial<ComponentSettingsBase>
    }>());

export const commitSuccess = createAction('[Discovery Bookingbar] commitSuccess',
    props<{
        data: ComponentSettingsBase
    }>());

export const commitFail = createAction('[Discovery Bookingbar] commitFail',
    props<{
        error: any
    }>());


export const create = createAction('[Discovery Bookingbar] create', props<{ componentGroupUuid: string, data: Partial<ComponentSettingsBase> }>());
export const createSuccess = createAction('[Discovery Bookingbar] createSuccess', props<{ data: ComponentSettingsBase }>());
export const createFail = createAction('[Discovery Bookingbar] createFail', props<{ error: any }>());
