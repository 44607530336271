/**
 * Created by roman.kupin on 9/30/2017.
 */


export class InterceptorsConfig {

    static $inject = ["$httpProvider"];

    constructor($httpProvider) {
        $httpProvider.interceptors.push('tokenInjectorInterceptor');
        $httpProvider.interceptors.push('unAuthorizedInterceptor');
        $httpProvider.interceptors.push('forbiddenInterceptor');        
        $httpProvider.interceptors.push('v1ApiUrlInterceptor');
        $httpProvider.interceptors.push('cacheInterceptor');

        $httpProvider.useApplyAsync(true);
    }
}
