/**
 * Created by Roman on 2015-05-29.
 */

angular.module('flipto.core.api.certificates', ['flipto.core.parse', 'flipto.core.lodash']).config([
	'$provide',
	function($provide) {
		$provide.factory('Certificate', [
			'urlFormatter',
			'$http',
			'parse',
			'_',
			'$q',
			'$timeout',
			function(urlFormatter, $http, parse, _, $q, $timeout) {
				/**
				 * Certificates service
				 * @constructor
				 */
				function Certificates() {
					/**
					 * Send certificate
					 * @param guestUUID
					 * @param voucherUUID
					 * @param incentiveTypeId
					 * @returns {*}
					 */
					this.send = function(propertyUUID, guestUUID, certificateUUID) {
						return $http
							.post(
								urlFormatter.format(
									'/api/:propertyUUID/properties/:propertyUUID/guests/:guestUUID/certificates/:certificateUUID/send-email',
									{
										propertyUUID: propertyUUID,
										guestUUID: guestUUID,
										certificateUUID: certificateUUID
									}
								)
							)
							.then(function(response) {
								return response.data;
							});
					};

					/**
					 * Update certificate
					 * @param propertyUUID
					 * @param guestUUID
					 * @param certificateUUID
					 * @param model
					 * @returns {*}
					 */
					this.update = function(propertyUUID, guestUUID, certificateUUID, model) {
						return $http
							.put(
								urlFormatter.format(
									'/api/:propertyUUID/properties/:propertyUUID/guests/:guestUUID/certificates/:certificateUUID',
									{
										propertyUUID: propertyUUID,
										guestUUID: guestUUID,
										certificateUUID: certificateUUID
									}
								),
								model
							)
							.then(function(response) {
								return response.data;
							});
					};
				}

				return new Certificates();
			}
		]);
	}
]);
