<ft-modal-wrapper>
  <ft-touchpoint-header
    name="Banner for Pre-Arrival Email"
    [canActivate]="true"
    [isActive]="(selectedStylingBannerPrearrivalEmailComponent$ | async)?.isActive == 1"
    (activeToggled)="commit($event)"
    >
  </ft-touchpoint-header>
  <mat-tab-group
    class="mat-tab-group-nav"
    mat-align-tabs="center"
    animationDuration="0ms"
    [(selectedIndex)]="selectedIndex">
    <mat-tab label="styling">
      <ng-template matTabContent>
        <ft-snapshot-form
          *ngIf="selectedStylingBannerPrearrivalEmailComponent$ | async | clone as styleData"
          [loading$]="[selectedBannerPrearrivalEmailLoading$]"
          [commitSuccess$]="[commitSuccess$]"
          (commit)="onFormCommit($event)">
          <section class="ft2-section">
            <div class="ft2-container">
              <div class="row">
                <div class="col-12">
                  <form ngForm>
                    <ft-custom-editor
                      title="Banner size">
                        <ng-template #summary>
                          <p>
                            Set the width of the banner (measured in px). The height will adjust to fit the content. 
                            You can also add a mobile banner if your email has a responsive layout for mobile devices.
                          </p>
                        </ng-template>
                        <ft-edit-panel label="Banner size">
                          <span>{{ styleData.desktopWidth ? styleData.desktopWidth + 'px width for desktop' : 'Click to edit' }}</span>
                          <span *ngIf="styleData.mobileWidth">
                            {{ styleData.mobileWidth ? styleData.mobileWidth + 'px width for mobile' : 'Click to edit' }}
                          </span>
                        </ft-edit-panel>
                      <div content>
                        <div class="ft2-form-group">
                          <label>WIDTH FOR DESKTOP</label>
                          <input class="ft2-input"
                            type="text"
                            maxlength="100"
                            [(ngModel)]="styleData.desktopWidth"
                            name="desktopWidth"
                            required>
                        </div>
                        <div *ngIf="styleData.mobileWidth" class="ft2-form-group">
                          <label>WIDTH FOR Mobile</label>
                          <input class="ft2-input"
                            type="text"
                            maxlength="100"
                            [(ngModel)]="styleData.mobileWidth"
                            name="mobileWidth"
                            required>
                        </div>
                        <button
                          *ngIf="!styleData.mobileWidth"
                          class="ft2-banner-email-button ft2-btn-light -outline -large"
                          (click)="styleData.mobileWidth = 320">
                          Add a mobile banner
                        </button>
                      </div>
                    </ft-custom-editor>
                    <ft-code-editor
                      title="Touchpoint CSS"
                      [(ngModel)]="styleData.touchpointCSS"
                      [language]="'css'"
                      name="touchpointCSS">
                        <ft-edit-panel label="CSS">
                          <p>{{styleData.touchpointCSS ? styleData.touchpointCSS : 'Click to edit'}}</p>
                        </ft-edit-panel>
                    </ft-code-editor>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </ft-snapshot-form>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</ft-modal-wrapper>
