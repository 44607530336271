<ft-modal-wrapper>
    <ft-touchpoint-header [name]="'Planner Flow - Global scope'" [activeToggleEnabled]="false">
    </ft-touchpoint-header>
    <mat-tab-group class="mat-tab-group-nav" mat-align-tabs="center" animationDuration="0ms"
                   [(selectedIndex)]="selectedIndex">
        <mat-tab label="Styling">
            <ng-template matTabContent>
                <ng-container *ngIf="discoverySettings$ | async | clone as settings">
                    <ft-snapshot-form (commit)="commit($event)"
                                      [loading$]="isLoading$"
                                      [useFlattenStrategy]="true"
                                      [commitSuccess$]="commitSuccess$">
                        <section class="ft2-block -small">
                            <div class="ft2-container">
                                <form ngForm>
<!--                                    <ft-select-editor title="Theme"-->
<!--                                                      [(ngModel)]="settings.stylingViewModel.routerTheme"-->
<!--                                                      [placeholder]="'Select theme'"-->
<!--                                                      name="stylingViewModel.routerTheme"-->
<!--                                                      [bindLabel]="'name'"-->
<!--                                                      [bindValue]="'value'"-->
<!--                                                      [options]="discoveryRouterThemeOptions$ | async">-->
<!--                                        <ft-edit-panel label="Theme">-->
<!--                                            <ng-container *ngFor="let theme of discoveryRouterThemeOptions$ | async">-->
<!--                                                <ng-container-->
<!--                                                    *ngIf="settings.stylingViewModel.routerTheme == theme?.value">-->
<!--                                                    {{ theme?.name | ftPlaceholder }}-->
<!--                                                </ng-container>-->
<!--                                            </ng-container>-->
<!--                                        </ft-edit-panel>-->
<!--                                    </ft-select-editor>-->
                                    <ft-select-editor [title]="'Planner Flow styling'"
                                                      [options]="inheritBrandingOptions"
                                                      name="isInheritPropertyBranding"
                                                      [(ngModel)]="settings.isInheritPropertyBranding"
                                                      [required]="false">
                                        <ft-edit-panel label="Planner Flow styling" [loading]="isLoading$ | async">
                                            {{getInheritBrandingLabel(settings.isInheritPropertyBranding)}}
                                        </ft-edit-panel>
                                    </ft-select-editor>
                                    <ft-code-editor [replaceInvalidSymbols]="true" [language]="'css'" [title]="'CSS'" name="stylingViewModel.css"
                                                    [(ngModel)]="settings.stylingViewModel.css">
                                        <ft-edit-panel label="CSS" [loading]="isLoading$ | async"
                                                       [error]="error$ | async">
                                            <div class="col-8"
                                                 [innerHTML]="settings.stylingViewModel.css | ftPlaceholder"></div>
                                        </ft-edit-panel>
                                    </ft-code-editor>
                                </form>
                            </div>
                        </section>
                    </ft-snapshot-form>
                </ng-container>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</ft-modal-wrapper>
