<div *ngIf="isVisible" class="ft-modal" tabindex="-1" role="dialog">
  <div class="ft-modal-bg">
    <div class="ft-bg-image"></div>
    <div class="ft-bg-matte"></div>
  </div>

  <button class="ft-btn-close" (click)="cancel()" *ngIf="cancelVisible"></button>

  <div class="ft-modal-container ft-v-align-container" [ngClass]="size">
    <div class="ft-modal-content ft-v-align-content">
      <form #modalForm="ngForm">

        <div class="modal-header" *ngIf="headerText || headerDescription">
          <div class="modal-heading" *ngIf="headerText" [innerText]="headerText"></div>
          <div class="modal-summary" *ngIf="headerDescription" [innerText]="headerDescription"></div>
        </div>

        <div class="modal-body">
          <ng-container
            [ngTemplateOutlet]="selectedTemplate"
            [ngTemplateOutletContext]="{$implicit: modalData}"
          ></ng-container>
          <ng-container #tc>
            <ng-content></ng-content>
          </ng-container>
        </div>

        <div class="modal-footer" *ngIf="footerVisible" [ngStyle]="{'text-align': footerAlign}">
          <button
            type="button"
            class="ft-btn ft-btn-plus-1 ft-btn-default"
            *ngIf="doneVisible"
            (click)="done()"
          >
            <span [innerText]="doneText"></span>
          </button>
          <button
            type="button"
            class="ft-btn ft-btn-plus-1 ft-btn-outline-light"
            [ngClass]="{'ml-2': doneVisible}"
            *ngIf="footerCancelVisible"
            (click)="cancel()"
            [innerText]="footerCancelText"
          ></button>
        </div>

      </form>
    </div>
  </div>
</div>
