<ft-modal-editor-wrapper>
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
    <ng-select class="ft2-select -large"
               [searchable]="false"
               [clearable]="false"
               (ngModelChange)="onSelectChange($event)"
               [(ngModel)]="rangeSelectModel">
        <ng-option [value]="false">No limit</ng-option>
        <ng-option [value]="true">Custom range</ng-option>
    </ng-select>
    <ng-container *ngIf="rangeSelectModel">
        <input class="ft2-input -large mt-3 mb-2"
               [(ngModel)]="value"
               placeholder="Enter the number of months"
               required
               [min]="minMonth"
               [pattern]="pattern"
               [name]="name"
               #inputElement
               #control="ngModel"
               type="number">
         <span *ngIf="value">
            A planner can book a stay up to {{ bookableDate | date }}.
        </span>
        <div class="ft2-form-error mt-2" *ngIf="control.invalid && control.touched">
            <p *ngIf="control.errors.required">
                Please enter the number of months
            </p>
            <p *ngIf="control.errors.pattern">
                Only whole numbers are allowed
            </p>
            <p *ngIf="control.errors.min">
                Value can't be less then {{ minMonth }}
            </p>
        </div>
    </ng-container>
</ft-modal-editor-wrapper>
