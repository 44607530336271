import { Component, Input, OnInit } from '@angular/core';
import { User } from 'account-hybrid/features/team/models/user.model';

@Component({
    selector: 'ft-user-profile-image',
    templateUrl: './user-profile-image.component.html',
    styleUrls: ['./user-profile-image.component.scss']
})
export class UserProfileImageComponent implements OnInit {
    @Input() user: Partial<User>;
    @Input() size: 'small' | 'large' = 'large';

    constructor() {
    }

    ngOnInit(): void {
    }
}
