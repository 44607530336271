/* global angular */


angular.module('flipto.accountv1.SharedFeatures')
//angular.module('flipto.account.sections.platform')
    .controller('flipto.account.sections.platform.MessagingController',
    ['$scope', 'messaging', 'Messaging', 'endpointUrl', '$log', 'layerName', 'touchpointType', 'touchpointGroupId', '$sce', 'changesTransformer', 'IdentityService',
        'transformType', 'touchpointActivator', 'Touchpoint', 'SpacesService', '$filter', 'postStayVersion', 'parse', 'languageHelper',
            'versionSelection', 'livePreview', 'notifications', 'ConfirmationImage',
        function ($scope, messaging, Messaging, endpointUrl, $log, layerName, touchpointType, touchpointGroupId, $sce, changesTransformer, identity, transformType, touchpointActivator, Touchpoint, spacesService, $filter, postStayVersion, parse, languageHelper, versionSelection, livePreview, notifications, ConfirmationImage) {
            'use strict';
            var layerName = $scope.layerName = layerName,
                touchpointType = $scope.touchpointType = touchpointType,
                groupId = touchpointGroupId,
                translate = $filter('translate'),
                dataByLanguage = {},
                upgradeDataByLanguage = {};

            $scope.identity = identity;
            $scope.$sce = $sce;
            $scope.canActivate = touchpointActivator.canActivate;

            activate();
            function activate() {
                var languages = messaging.languages.item,
                    upgradeLanguages = messaging.upgrade && messaging.upgrade.languages.item;

                prepare();

                $scope.model = {
                    postStayVersion: postStayVersion,
                    propertyId: spacesService.getPropertyUuid(),
                    languages: [],
                    selectedLanguage: {},
                    messaging: messaging,
                    isActive: messaging.isActive === '1' || messaging.isEmailActive === '1',
                    isPhotoAdvocacyEnabled: messaging.isPhotoAdvocacyEnabled == '1',
                    isNewQuoteProcess: messaging.isNewQuoteProcess,
                    languageItems: languages,
                    isCanSendEmail: parse.bool(messaging.isCanSendEmail)
                };

                setupVersionManager(messaging);

                $scope.model.language = {};

                languages && languages.forEach(function (language) {
                    language.data = language.data || {};
                    dataByLanguage[language.code] = language.data;
                });

                upgradeLanguages && upgradeLanguages.forEach(function (language) {
                    language.data = language.data || {};
                    upgradeDataByLanguage[language.code] = language.data;
                });

                $scope.model.language = $scope.version.currentIsNewVersion() ? upgradeDataByLanguage : dataByLanguage;
                $scope.model.languageItems = $scope.version.currentIsNewVersion() ? upgradeLanguages : languages;

                if ($scope.model.languages.length === 0 && languages) {
                    languages.forEach(function (language) {
                        $scope.model.languages.push({code: language.code, name: language.name})
                    });
                }

                // create confirmation image if applicable
                if (touchpointType === 'confirmation-email-image' && !parse.bool(messaging.hasConfirmationEmailImages)) {
                    ConfirmationImage.refresh(groupId, 'en', spacesService.getPropertyUuid());
                }
            }

            $scope.updateState = function () {
                Touchpoint.activate({
                    propertyUuid: spacesService.getPropertyUuid(),
                    layer: layerName.replace('-', ''),
                    touchpoint: groupId,
                    touchpointType: touchpointType,
                    isActive: $scope.model.isActive
                });
            };

            $scope.commit = function (deepDiffChanges) {
                var mainChanges = _.filter(deepDiffChanges, function (change) {
                    var path = change.path.join('.');
                    return path.indexOf('model.messaging.upgrade.languages.item') == -1;
                });
                var upgradeChanges = _.filter(deepDiffChanges, function (change) {
                    var path = change.path.join('.');
                    return path.indexOf('model.messaging.upgrade.languages.item') != -1;
                });

                saveData(mainChanges, $scope.version.activatedVersion);
                messaging.upgrade && saveData(upgradeChanges, messaging.upgrade.poststayVersionId);
            };

            $scope.$on('touchpoint.statusChanged', function (event, args) {
                if (touchpointType == args.touchpointType) {
                    $scope.model.isActive = args.isActive;
                }
            });

            $scope.canSendSampleEmail = function () {
                var languageItem = getCurrentLanguage();
                return !!languageItem && parse.bool(languageItem.isCanSendSampleEmail);
            };

            $scope.isVersion = function (version) {
                return $scope.model.messaging.poststayVersionId == version;
            };

            $scope.activateVersion = function (version) {
                Touchpoint.activateVersion({
                    propertyUuid: spacesService.getPropertyUuid(),
                    layerName: layerName.replace('-', ''),
                    groupId: groupId,
                    touchpointType: touchpointType,
                    version: version
                }).then(function () {
                    setupVersionManager({poststayVersionId: version});
                });
            };

            $scope.installVersion = function (version) {
                Touchpoint.installVersion({
                    propertyUuid: spacesService.getPropertyUuid(),
                    layerName: layerName.replace('-', ''),
                    groupId: groupId,
                    touchpointType: touchpointType,
                    version: version
                }).then(function () {
                    versionSelection.clear();
                    reloadData();
                });
            };

            $scope.versionChanged = function (oldVersion, newVersion) {
                var changedToNewVersion = newVersion > oldVersion;
                $scope.model.languageItems = changedToNewVersion ? messaging.upgrade.languages.item : messaging.languages.item;
                $scope.model.language = changedToNewVersion ? upgradeDataByLanguage : dataByLanguage;
                versionSelection.update(newVersion);
            };

            $scope.openLandingSignUpLivePreview = function () {
                var language = getCurrentLanguage();
                language.demos.signupCode && livePreview.landing({code: language.demos.signupCode, domain: $scope.model.messaging.domain});
            };

            $scope.openLandingSignUpGustLivePreview = function () {
                var language = getCurrentLanguage();
                language.demos.signupQuoteCode && livePreview.landing({code: language.demos.signupQuoteCode, domain: $scope.model.messaging.domain});
            };

            $scope.openMobileLandingLivePreview = function () {
                var language = getCurrentLanguage();
                language.demos.signupCode && livePreview.mobileLanding({code: language.demos.signupCode, domain: language.demos.domain});
            };

            $scope.openFollowupLivePreview = function () {
                var language = getCurrentLanguage();
                language.demos.signupCode && livePreview.followup({ code: language.demos.signupCode, domain: $scope.model.messaging.domain }); //language.demos.domain});
            };

            $scope.openConfirmationLivePreview = function () {
                var language = getCurrentLanguage();
                var splitEmail = identity.user.email.split("@");
                splitEmail[0] += "+" + Date.now();
                var modifiedEmail = splitEmail.join("@");
                language.code && language.fullCode && livePreview.confirmation({code: language.code, fullCode: language.fullCode, email: modifiedEmail, first: identity.user.firstName, last: identity.user.lastName});
            };

            $scope.openPoststayPreview = function () {
                livePreview.poststay({
                    propertyUUID: spacesService.getPropertyUuid(),
                    version: $scope.version.currentVersion,
                    languageCode: $scope.model.selectedLanguage.code
                });
            };

            $scope.openPhotoRiverLivePreview = function () {
                var language = getCurrentLanguage();
                language.code && language.fullCode && livePreview.photoRiver({ code: language.code, fullCode: language.fullCode });
            };

            $scope.sendFollowupEmailPreview = function () {
                livePreview.followupEmail(
                    {
                        propertyUUID: spacesService.getPropertyUuid(),
                        touchpointUUID: groupId,
                        language: $scope.model.selectedLanguage.code
                    })
                    .then(showEmailSendingSuccess, showEmailSendingError);
            };

            $scope.sendRedeemEmailPreview = function () {
                livePreview.redeemEmail(
                    {
                        propertyUUID: spacesService.getPropertyUuid(),
                        touchpointUUID: groupId,
                        language: $scope.model.selectedLanguage.code
                    })
                    .then(showEmailSendingSuccess, showEmailSendingError);
            };

            $scope.sendPoststayEmailPreview = function () {
                livePreview.poststayEmail(
                    {
                        propertyUUID: spacesService.getPropertyUuid(),
                        touchpointUUID: groupId,
                        language: $scope.model.selectedLanguage.code
                    })
                    .then(showEmailSendingSuccess, showEmailSendingError);
            };

            $scope.sendPoststayNonViewEmailPreview = function () {
                livePreview.poststayNonViewEmail(
                    {
                        propertyUUID: spacesService.getPropertyUuid(),
                        touchpointUUID: groupId,
                        language: $scope.model.selectedLanguage.code
                    })
                    .then(showEmailSendingSuccess, showEmailSendingError);
            };

            $scope.sendPoststayAfterViewEmailPreview = function () {
                livePreview.poststayAfterViewEmail(
                    {
                        propertyUUID: spacesService.getPropertyUuid(),
                        touchpointUUID: groupId,
                        language: $scope.model.selectedLanguage.code
                    })
                    .then(showEmailSendingSuccess, showEmailSendingError);
            };

            $scope.sendPoststayNoQuoteEmailPreview = function () {
                livePreview.poststayNoQuoteEmail(
                    {
                        propertyUUID: spacesService.getPropertyUuid(),
                        touchpointUUID: groupId,
                        language: $scope.model.selectedLanguage.code
                    })
                    .then(showEmailSendingSuccess, showEmailSendingError);
            };

            $scope.sendPoststayNoPhotoEmailPreview = function () {
                livePreview.poststayNoPhotoEmail(
                    {
                        propertyUUID: spacesService.getPropertyUuid(),
                        touchpointUUID: groupId,
                        language: $scope.model.selectedLanguage.code
                    })
                    .then(showEmailSendingSuccess, showEmailSendingError);
            };

            $scope.sendFinalistEmailPreview = function () {
                livePreview.finalistEmail(
                    {
                        propertyUUID: spacesService.getPropertyUuid(),
                        touchpointUUID: groupId,
                        language: $scope.model.selectedLanguage.code
                    })
                    .then(showEmailSendingSuccess, showEmailSendingError);
            };

            $scope.sendReminderEmailPreview = function () {
                livePreview.reminderEmail(
                    {
                        propertyUUID: spacesService.getPropertyUuid(),
                        touchpointUUID: groupId,
                        language: $scope.model.selectedLanguage.code
                    })
                    .then(showEmailSendingSuccess, showEmailSendingError);
            };

            $scope.sendActivityEmailPreview = function () {
                livePreview.activityEmail(
                    {
                        propertyUUID: spacesService.getPropertyUuid(),
                        touchpointUUID: groupId,
                        language: $scope.model.selectedLanguage.code
                    })
                    .then(showEmailSendingSuccess, showEmailSendingError);
            };

            $scope.sendWinnerEmailPreview = function () {
                livePreview.winnerEmail(
                    {
                        propertyUUID: spacesService.getPropertyUuid(),
                        touchpointUUID: groupId,
                        language: $scope.model.selectedLanguage.code
                    })
                    .then(showEmailSendingSuccess, showEmailSendingError);
            };

            $scope.getCurrentLanguage = function () {
                return getCurrentLanguage();
            };

            $scope.setupSnapshotProxy = function (proxy) {
                $scope.$watchCollection(function () {
                    return proxy.getTracker().pendingChanges;
                }, function(changes) {
                    $scope.model.hasPendingChanges = changes.length > 0;
                });
            };

            function showEmailSendingSuccess() {
                notifications.show(translate('flipto.account.SendSampleEmailSuccess'));
            }

            function showEmailSendingError() {
                notifications.show(translate('flipto.account.SendSampleEmailError'));
            }

            function saveData(deepDiffChanges, version) {
                if (_.isEmpty(deepDiffChanges)) return;
                var fixedLanguagePath = getFixedLanguagePath(deepDiffChanges);
                var changes = changesTransformer(transformType.MultiLanguageData, fixedLanguagePath, messaging.languages.item);
                var url = endpointUrl.getDataUrl(layerName, touchpointType, groupId, spacesService.getPropertyUuid(), version);
                Messaging.put(url, changes);

            }

            function reloadData() {
                var url = endpointUrl.getDataUrl(layerName, touchpointType, groupId, spacesService.getPropertyUuid());
                Messaging.get(url).then(function (response) {
                    if (response.data) {
                        languageHelper.sortByDefaultLanguage(response.data.languages);
                        response.data.upgrade && languageHelper.sortByDefaultLanguage(response.data.upgrade.languages);
                    }
                    return response.data;
                }).then(function (data) {
                    messaging = data;
                    activate();
                });
            }

            function getFixedLanguagePath(deepDiffChanges) {
                return _.map(deepDiffChanges, function (change) {
                    var copy = angular.copy(change),
                        path = change.path.join('.'),
                        patchesToCrop = ['model.languageItems', 'model.messaging.languages.item', 'model.messaging.upgrade.languages.item'];

                    angular.forEach(patchesToCrop, function (toCrop) {
                        if (path.indexOf(toCrop) != -1) {
                            copy.path.splice(0, toCrop.split('.').length);
                        }
                    });
                    return copy;
                });
            }

            function getCurrentLanguage() {
                if(!!$scope.model.selectedLanguage){
                    return _.find($scope.model.languageItems, {code: $scope.model.selectedLanguage.code});
                }
            }

            function setupVersionManager(objectToUpgrade) {
                $scope.version = {
                    currentVersion: versionSelection.get($scope.version && $scope.version.currentVersion, objectToUpgrade.poststayVersionId, objectToUpgrade.installedVersionId),
                    activatedVersion: objectToUpgrade.poststayVersionId,
                    updateInstalled: angular.isDefined(objectToUpgrade.upgrade),
                    availableVersion: objectToUpgrade.lastVersionId,
                    currentIsNewVersion: function () {
                        return $scope.version && $scope.version.updateInstalled && $scope.version.currentVersion === $scope.version.availableVersion;
                    }
                }
            }

            function prepare() {
                messaging.isNewQuoteProcess = parse.bool(messaging.isNewQuoteProcess);
                messaging.installedVersionId && (messaging.installedVersionId = parse.intOrDefault(messaging.installedVersionId));
                messaging.poststayVersionId && (messaging.poststayVersionId = parse.intOrDefault(messaging.poststayVersionId));
                messaging.lastVersionId && (messaging.lastVersionId = parse.intOrDefault(messaging.lastVersionId));
            }

        }
    ]);
