/**
 * Created by Roman on 2014-09-21.
 */


angular.module('flipto.components.common.snapshot', ['flipto.core.DeepDiff', 'flipto.core.lodash', 'flipto.core.changesTracker'])
    .config(['$compileProvider', function ($compileProvider) {

        $compileProvider.directive('ftSnapshot', ['changesTracker', function (changesTracker) {
            return {
                restrict: 'A',
                require: 'ftSnapshot',
                controller: ['$scope', function ($scope) {
                    var tracker,
                        trackingKeys;

                    /**
                     * Take snapshot
                     * @param keys
                     */
                    this.take = function (keys) {
                        trackingKeys = keys;
                        tracker = changesTracker.create($scope, keys);
                    };

                    /**
                     * Retake snapshot again
                     */
                    this.retake = function () {
                        tracker.stopTracking();
                        tracker.track($scope, trackingKeys);
                    };

                    /**
                     * Destroy snapshot
                     */
                    this.destroy = function () {
                        tracker.stopTracking();
                        tracker = null;
                    };

                    /**
                     * Return tracker instance
                     * @returns {*}
                     */
                    this.getTracker = function () {
                        return tracker;
                    };

                    /**
                     * Rollback changes
                     */
                    this.rollback = function () {
                        tracker.rollback();
                    };

                    /**
                     * Commit changes
                     */
                    this.commit = function () {
                        tracker.commit();
                    };
                }],
                link: function (scope, element, attrs, snapshot) {

                    /**
                     * Take snapshot
                     */
                    if (angular.isDefined(angular.element(element[0]).attr('ft-snapshot'))) {
                        snapshot.take(attrs.ftSnapshot);

                        if (angular.isDefined(attrs.setupSnapshotProxy)) {
                            scope.$eval(attrs.setupSnapshotProxy)(snapshot);
                        }
                    }
                }
            };
        }]);
    }]);