export interface CarouselItem {
    isVisible: boolean;
    emailTypes: number;
    text: string;
}

export interface CertificateEmailSettings {
    emailType: number;
    languages: any;
}

export const TokensPerType = {
    11: [
        {name: 'visitor-firstname', value: '{visitor-firstname}'},
        {name: 'guest-firstname', value: '{guest-firstname}'},
        {name: 'property-city', value: '{property-city}'},
        {name: 'property-name', value: '{property-name}'},
        {name: 'property-shortname', value: '{property-shortname}'},
        {name: 'link-to-guest-photo', value: '{link-to-guest-photo}'},
        {name: 'link-to-gallery', value: '{link-to-gallery}'}
    ],
    7: [
        {name: 'friend-firstname', value: '{friend-firstname}'},
        {name: 'guest-firstname', value: '{guest-firstname}'},
        {name: 'property-city', value: '{property-city}'},
        {name: 'property-name', value: '{property-name}'},
        {name: 'property-shortname', value: '{property-shortname}'},
        {name: 'link-to-guest-photo', value: '{link-to-guest-photo}'},
        {name: 'link-to-gallery', value: '{link-to-gallery}'}
    ],
    1: [
        {name: 'friend-firstname', value: '{friend-firstname}'},
        {name: 'guest-firstname', value: '{guest-firstname}'},
        {name: 'property-city', value: '{property-city}'},
        {name: 'property-name', value: '{property-name}'},
        {name: 'property-shortname', value: '{property-shortname}'},
        {name: 'link-to-gallery', value: '{link-to-gallery}'},
        {name: 'contest-content', value: '{contest-content}'}
    ],
    3: [
        {name: 'friend-firstname', value: '{friend-firstname}'},
        {name: 'guest-firstname', value: '{guest-firstname}'},
        {name: 'property-city', value: '{property-city}'},
        {name: 'property-name', value: '{property-name}'},
        {name: 'property-shortname', value: '{property-shortname}'},
        {name: 'link-to-gallery', value: '{link-to-gallery}'},
        {name: 'contest-content', value: '{contest-content}'}
    ],
    13: [
        {name: 'guest-firstname', value: '{guest-firstname}'},
        {name: 'property-city', value: '{property-city}'},
        {name: 'property-name', value: '{property-name}'},
        {name: 'property-shortname', value: '{property-shortname}'},
        {name: 'link-to-gallery', value: '{link-to-gallery}'},
        {name: 'contest-content', value: '{contest-content}'}
    ],
    22: [
        {name: 'guest-firstname', value: '{guest-firstname}'},
        {name: 'property-city', value: '{property-city}'},
        {name: 'property-name', value: '{property-name}'},
        {name: 'property-shortname', value: '{property-shortname}'},
        {name: 'link-to-gallery', value: '{link-to-gallery}'},
        {name: 'contest-content', value: '{contest-content}'}
    ],
    12: [
        {name: 'visitor-firstname', value: '{visitor-firstname}'},
        {name: 'guest-firstname', value: '{guest-firstname}'},
        {name: 'property-city', value: '{property-city}'},
        {name: 'property-name', value: '{property-name}'},
        {name: 'property-shortname', value: '{property-shortname}'},
        {name: 'link-to-guest-photo', value: '{link-to-guest-photo}'},
        {name: 'link-to-gallery', value: '{link-to-gallery}'}
    ],
    8: [
        {name: 'friend-firstname', value: '{friend-firstname}'},
        {name: 'guest-firstname', value: '{guest-firstname}'},
        {name: 'property-city', value: '{property-city}'},
        {name: 'property-name', value: '{property-name}'},
        {name: 'property-shortname', value: '{property-shortname}'},
        {name: 'link-to-guest-photo', value: '{link-to-guest-photo}'},
        {name: 'link-to-gallery', value: '{link-to-gallery}'}
    ],
    2: [
        {name: 'friend-firstname', value: '{friend-firstname}'},
        {name: 'guest-firstname', value: '{guest-firstname}'},
        {name: 'property-city', value: '{property-city}'},
        {name: 'property-name', value: '{property-name}'},
        {name: 'property-shortname', value: '{property-shortname}'},
        {name: 'link-to-gallery', value: '{link-to-gallery}'},
        {name: 'contest-content', value: '{contest-content}'}
    ],
    4: [
        {name: 'friend-firstname', value: '{friend-firstname}'},
        {name: 'guest-firstname', value: '{guest-firstname}'},
        {name: 'property-city', value: '{property-city}'},
        {name: 'property-name', value: '{property-name}'},
        {name: 'property-shortname', value: '{property-shortname}'},
        {name: 'link-to-gallery', value: '{link-to-gallery}'},
        {name: 'contest-content', value: '{contest-content}'}
    ],
    23: [
        {name: 'guest-firstname', value: '{guest-firstname}'},
        {name: 'property-city', value: '{property-city}'},
        {name: 'property-name', value: '{property-name}'},
        {name: 'property-shortname', value: '{property-shortname}'},
        {name: 'link-to-gallery', value: '{link-to-gallery}'},
        {name: 'contest-content', value: '{contest-content}'}
    ],
    36: [
        {name: 'traveler-firstname', value: '{traveler-firstname}'},
        {name: 'property-city', value: '{property-city}'},
        {name: 'property-name', value: '{property-name}'},
        {name: 'property-shortname', value: '{property-shortname}'},
        {name: 'link-to-gallery', value: '{link-to-gallery}'},
        {name: 'contest-content', value: '{contest-content}'}
    ],
    37: [
        {name: 'traveler-firstname', value: '{traveler-firstname}'},
        {name: 'property-city', value: '{property-city}'},
        {name: 'property-name', value: '{property-name}'},
        {name: 'property-shortname', value: '{property-shortname}'},
        {name: 'link-to-gallery', value: '{link-to-gallery}'},
        {name: 'contest-content', value: '{contest-content}'}
    ],
    80: [
        {name: 'guest-firstname', value: '{guest-firstname}'},
        {name: 'property-city', value: '{property-city}'},
        {name: 'property-name', value: '{property-name}'},
        {name: 'property-shortname', value: '{property-shortname}'},
        {name: 'link-to-gallery', value: '{link-to-gallery}'},
        {name: 'contest-content', value: '{contest-content}'},
        {name: 'incentive-custom-code', value: '{incentive-custom-code}'}
    ],
    81: [
        {name: 'guest-firstname', value: '{guest-firstname}'},
        {name: 'property-city', value: '{property-city}'},
        {name: 'property-name', value: '{property-name}'},
        {name: 'property-shortname', value: '{property-shortname}'},
        {name: 'link-to-gallery', value: '{link-to-gallery}'},
        {name: 'contest-content', value: '{contest-content}'},
        {name: 'incentive-custom-code', value: '{incentive-custom-code}'}
    ]
};

export enum EmailTypes {
    anonymousPhotoSignupCertificate = 11,
    friendPhotoSignupCertificate = 7,
    friendPrestayHomepageSignupCertificate = 1,
    friendPoststayHomepageSignupCertificate = 3,
    guestPrestayPromotionCertificate = 13,
    guestPoststayPromotionCertificate = 22,
    guestPhotoFinalistCertificate = 80,
    defaultCertificate = 36
};

export enum EmailTypesReminder {
    anonymousPhotoSignupCertificateReminder = 12,
    friendPhotoSignupCertificateReminder = 8,
    friendPrestayHomepageSignupCertificateReminder = 2,
    friendPoststayHomepageSignupCertificateReminder = 4,
    guestPoststayPromotionCertificateReminder = 23,
    guestPhotoFinalistCertificateReminder = 81,
    defaultCertificateReminder = 37
};
