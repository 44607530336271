import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'share'
})
export class SharePipe implements PipeTransform {

  transform(value: any, total: string): any {
    const share = Number(value) / Number(total);
    return isNaN(share) ? 0 : Math.ceil(share);
  }

}
