import { NgModule } from '@angular/core';
import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { BackgroundImageComponent } from './background-image.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { UserProfileImageComponent } from './user-profile-image/user-profile-image.component';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        PipesModule
    ],
    declarations: [
        ImagePreviewComponent,
        BackgroundImageComponent,
        UserProfileImageComponent,
    ],
    exports: [
        ImagePreviewComponent,
        BackgroundImageComponent,
        UserProfileImageComponent,
    ]
})
export class ImagesModule {

}
