/**
 * Created by Kevin on 2019-01-16.
 */
var isJsLoaded = false;
angular.module('flipto.components.autocomplete', [])
    .directive('ftLocationAutocomplete', function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, ngModelCtrl) {
                // Make the init function global so it can be called by the loaded library
                window.initAutocomplete = function () {
                    var componentForm = {
                        street_number: 'short_name',
                        route: 'long_name',
                        locality: 'long_name',
                        administrative_area_level_1: 'short_name',
                        country: 'short_name',
                        postal_code: 'short_name'
                    };

                    // Create the autocomplete object, restricting the search to full addresses.
                    var autocomplete = new google.maps.places.Autocomplete(element[0], { types: ['address'] });

                    // When the user selects an address from the dropdown, populate the address fields in the form.
                    autocomplete.addListener('place_changed', function () {
                        // Get the place details from the autocomplete object.
                        var place = autocomplete.getPlace();

                        // Get each component of the address from the place details and populate an easy to read object
                        var location = {
                            lat: place.geometry.location.lat(),
                            long: place.geometry.location.lng()
                        };
                        for (var i = 0; i < place.address_components.length; i++) {
                            var addressType = place.address_components[i].types[0];
                            if (componentForm[addressType]) {
                                location[addressType] = place.address_components[i][componentForm[addressType]];
                            }
                        }

                        // Convert our object into a location object
                        var cleanLocation = {
                            streetAddress: location.street_number + " " + location.route,
                            countryName: location.country,
                            region: location.administrative_area_level_1,
                            city: location.locality,
                            postalCode: location.postal_code,
                            latitude: location.lat,
                            longitude: location.long
                        };
                        cleanLocation.toString = function () {
                            return [[this.streetAddress, this.city, this.region].join(', '), this.postalCode, this.countryName].join(' ');
                        };

                        // Push the value into angular using arcane and obscure code provided by Roman
                        scope.$apply(function () {
                            ngModelCtrl.$setViewValue(cleanLocation);
                            ngModelCtrl.$render();
                        });
                    });
                };

                if (!isJsLoaded) {
                    // Add the script tag to head.  Should callback to initAutocomplete
                    var firstScript = document.getElementsByTagName('script')[0];
                    var script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDW_RhNMchTilWj6McY8VEAH-DjZ4do6s8&libraries=places&callback=initAutocomplete';
                    script.async = true;
                    firstScript.parentNode.insertBefore(script, firstScript);
                    isJsLoaded = true;
                } else {
                    window.initAutocomplete();
                }
            }
        };
    });
