<ft-modal-editor-wrapper>
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
    <ng-container *ngIf="value">
        <div class="ft2-form-group">
            <ng-select [items]="options"
                       #controlsModelRef="ngModel"
                       class="ft2-select -large mb-3"
                       name="childrenAgesRequired"
                       bindLabel="text"
                       bindValue="value"
                       [searchable]="false"
                       [clearable]="false"
                       [disabled]="selectDisabled"
                       [(ngModel)]="value.childrenAgesRequired">
            </ng-select>
        </div>
        <div class="ft2-form-group" *ngIf="value.childrenAgesRequired">
            <label>Max age for kids</label>
            <input class="ft2-input -large"
                   #controlsModelRef="ngModel"
                   type="number"
                   [max]="18"
                   name="maxChildrenAge"
                   required
                   [(ngModel)]="value.maxChildrenAge">
        </div>
    </ng-container>
</ft-modal-editor-wrapper>
