import { IComponentFlowBuilder, FlowStep } from "..";


export class BuildBroadcastStoryRecommendationComponentFlowBuilder implements IComponentFlowBuilder {

    createSteps(): FlowStep[] {
        return [
            new FlowStep("ChooseGoal",
                `<div>
                    <button class="ft-btn-close" ng-click="$ftFlow.close()"></button>

                    <div class="ft-modal-view choose-goal">
                        <h1 class="modal-heading">What's your goal for this broadcast?</h1>
                        <ft-broadcast-choose-goal story="$ftFlow.story" contests="$ftFlow.contests" on-invite-to-story="$ftFlow.startLeadShare()" on-invite-to-contest="$ftFlow.startStorytellersShare(contest)"></ft-broadcast-choose-goal>
                    </div>
                </div>`,
                true
            ),
            new FlowStep("StoryShareImageSetup",
                `<div>
                    <button class="ft-btn-close" ng-click="$ftFlow.close()"></button>
                    <a class="ft-btn ft-btn-outline-light ft-btn-back" ng-if="!!$ftFlow.contests" ng-click="$ftFlow.chooseDifferentGoal()">
                        Choose a different goal
                    </a>
                
                    <div class="ft-modal-view image-setup">
                        <h1 class="modal-heading">Let's set up your content.</h1>
                        <p class="modal-summary">
                            Use the editor to set up content that will display with your broadcast. The final appearance may be adjusted for the channels you select in the next step.
                        </p>
                        
                        <ft-story-image-setup 
                                story="$ftFlow.story" 
                                property="$ftFlow.property"
                                options="$ftFlow.imageSetupOptions"
                        ></ft-story-image-setup>
                    
                    <button type="button" class="ft-btn ft-btn-plus-1 ft-btn-default ft-btn-confirm" ng-click="$ftFlow.finishImageSetup()">
                        Continue
                    </button>

                    </div>
                </div>`
            ),
            new FlowStep("PhotocontestInvitationShareImageSetup",
                `<div>
                    <button class="ft-btn-close" ng-click="$ftFlow.close()"></button>
                    <a class="ft-btn ft-btn-outline-light ft-btn-back" ng-click="$ftFlow.chooseDifferentGoal()">
                        Choose a different goal
                    </a>
                
                    <div class="ft-modal-view image-setup">
                        <h1 class="modal-heading">Let's set up your content.</h1>
                        <p class="modal-summary">
                            Use the editor below to set up content to display to your audience. The final appearance may differ
                            based on the channels you select in the next step.
                        </p>

                        <ft-story-image-setup 
                                story="$ftFlow.story" 
                                property="$ftFlow.property"
                                options="$ftFlow.imageSetupOptions"
                        ></ft-story-image-setup>
                        
                        <!--<ft-photocontest-invitation-image-setup 
                                template="$ftFlow.shareImageTemplateData.template"
                                on-reload="$ftFlow.createShareImageTemplate()"
                        ></ft-photocontest-invitation-image-setup>-->
                    
                    <button type="button" class="ft-btn ft-btn-plus-1 ft-btn-default ft-btn-confirm" ng-click="$ftFlow.finishImageSetup()">
                        Continue
                    </button>

                    </div>
                </div>`
            ),
            new FlowStep("ChooseNetwork",
                `<div>
                    <button class="ft-btn-close" ng-click="$ftFlow.close()"></button>
                    <a class="ft-btn ft-btn-outline-light ft-btn-back" ng-click="$ftFlow.returnToImageSetup()">
                        Return to set up your content
                    </a>
                    <div class="ft-modal-view choose-channel">
                        <h1 class="modal-heading">Which channel do you want to broadcast to?</h1>
            
                        <ft-broadcast-choose-network
                                shared-to="$ftFlow.storySharedTo"
                                on-open-share-modal="$ftFlow.openShareModal(network)"
                        ></ft-broadcast-choose-network>

                        <div class="or ft-supertitle-divider">
                            <div class="ft-supertitle">OR</div>
                        </div>

                        <button class="ft-btn ft-btn-plus-1 ft-btn-default ft-btn-confirm" ng-click="$ftFlow.finishRecommendation()">Conclude your recommendation</button>
                    </div>
                </div>`
            ),
            new FlowStep("CreateImageProgress",
                `<div>
                    <button class="ft-btn-close" ng-click="$ftFlow.close()"></button>
                    <div class="ft-modal-view rendering-image">
                        <div class="ft2-spinner -large"></div>
                        <h1 class="modal-heading">{{'flipto.account.CreatingImage' | translate}}</h1>
                    </div>
                </div>`
            ),
            new FlowStep("ShareImageReady",
                `<div>
                    <button class="ft-btn-close" ng-click="$ftFlow.close()"></button>
                    <a class="ft-btn ft-btn-outline-light ft-btn-back" ng-click="$ftFlow.chooseDifferentChannel()">Choose a different
                        channel.</a>
            
                    <ft-broadcast-share-image-ready
                        first="$ftFlow.story.guest.first"
                        share-image-url="$ftFlow.shareImageUrl"
                        network="$ftFlow.network"
                        share-url="$ftFlow.shareUrl"
                        share-type="$ftFlow.shareType"
                        on-reprocess-image="$ftFlow.reprocessImage()"
                        on-download-share-image="$ftFlow.downloadShareImage()"
                        on-share="$ftFlow.openOAuth(message)"
                        is-recommendation-setup="true"
                    ></ft-broadcast-share-image-ready>

                    <button class="ft-btn ft-btn-plus-1 ft-btn-default ft-btn-confirm" ng-click="$ftFlow.finishRecommendation()">Conclude your recommendation</button>
                </div>`
            ),
            new FlowStep("ConcludeRecommendation",
                `<div><ng-form name="recommendationForm" novalidate>
                    <button class="ft-btn-close" ng-click="$ftFlow.close()"></button>
                    <a class="ft-btn ft-btn-outline-light ft-btn-back" ng-click="$ftFlow.returnToImageSetup()">Return to broadcast setup</a>
            
                    <div class="ft-modal-view conclude-recommendation">
                        <h1 class="modal-heading">In a sentence or two, explain why you're making this recommendation.</h1>
                        <p class="modal-summary">
                            <a class="ft-link-underline" target="_blank" ui-state="'missionControl.notifications'" ui-state-params="{context:'{{$ftFlow.fullContext}}'}">View notification list</a> (opens in new tab)
                        </p>

                        <div class="ft-broadcast-editor">
                            <div class="modal-panel-input" ng-class="{'ft-field-error': recommendationForm.recommendationMessage.$invalid && recommendationForm.$submitted, 'field-error': recommendationForm.recommendationMessage.$invalid && recommendationForm.$submitted}">
                                <textarea class="form-control ft-share-message" 
                                    autofocus
                                    placeholder="{{'flipto.account.AddYourMessage' | translate}}"
                                    name="recommendationMessage" 
                                    ng-model="$ftFlow.recommendationMessage"
                                    required
                                ></textarea>
                            </div>
                            <div class="modal-panel-input">
                                <textarea class="form-control ft-share-message" 
                                    placeholder="Instructions (optional)"
                                    disabled="disabled"
                                >I’ve set everything up so it’s to ready to broadcast. Follow the link below to take a look at the story. When you’re ready, simply click the recommendation to review it and share with your audience. (You can always edit your broadcast to your liking.)</textarea>
                            </div>
                            <div class="ft-expiration">
                                Keep your recommendation active for 
                                <ft-select items='$ftFlow.recommendationExpirationRanges' 
                                    select-key="date" select-value='name' 
                                    selected-item='$ftFlow.recommendationExpirationDate' 
                                    selected-item-key="date"></ft-select>
                            </div>
                        </div>

                        <button class="ft-btn ft-btn-plus-1 ft-btn-default ft-btn-confirm" 
                            type="button"
                            ng-click="$ftFlow.createRecommendation(recommendationForm)"
                            ft-loading-animation="{trigger: $ftFlow.isProcessingRequest}">
                            <span class="ft-btn-label">All done!</span>
                            <span class="ft-btn-subline">Send your recommendation</span>
                        </button>
                    </div>
                </ng-form></div>`
            )
        ];
    }

    createTemplate(steps: FlowStep[]): string {
        return `
        <ft-modal id="account-v2.BuildBroadcastStoryRecommendation" class="ft-broadcast-modal" done-visible="false" cancel-visible="false" size="full">
            <ft-initialized-content is-initialized="$ftFlow.isInitialized">
                <ft-flow>
                    ${steps.map(s => `<ft-step name='${s.name}'>${s.template}</ft-step>`).join("")}
                </ft-flow>
            </ft-initialized-content>
        </ft-modal>
        `;
    }
}

