/**
 * Created by Roman on 2015-12-28.
 */

import { Permissions } from 'account-hybrid/core/authentication';

angular.module('flipto.accountv1.SharedFeatures')
    .controller('flipto.account.sections.account.settings.domains.DomainsController',
        [
            '$scope', 'Account', '_', 'IdentityService', 'changesTransformer', 'transformType', 'ftModal', 'ftTasks', '$timeout', 'Property', 'Company', '$q',
            'Domains', 'environment', 'moment', 'SpacesService', 'SessionService',
            function ($scope, Account, _, identity, changesTransformer, transformType, ftModal, ftTasks, $timeout, Property, Company, $q,
                      Domains, environment, moment, spacesService, sessionService) {
                var vm = this;

                vm.ftTasks = ftTasks;

                angular.extend(vm, {
                    identity: identity,
                    hubDomain: environment.FliptoHubDomain
                });

                vm.init = function () {
                    Domains.getCompanyDomains()
                        .then(function (data) {
                            const companySpace = spacesService.current.type == 1 ? spacesService.current : spacesService.current.company;
                            const properties = sessionService.getCompanyProperties(companySpace.id).filter(function(p) { return (p.Permissions & Permissions.AdvocacyRead) === Permissions.AdvocacyRead; })
                            vm.company = new Company(companySpace.id, properties);
                            vm.domains = data;
                        });
                };

                vm.saveDomain = function () {
                    var defer = $q.defer();
                    defer.promise.then(function (domain) {
                        if (vm.company.properties.length === 1) {
                            Domains.updateDomain({
                                    SubdomainUUID: domain.SubdomainUUID,
                                    Host: domain.Host,
                                    RoutePurpose: domain.Purpose,
                                    IsActive: domain.IsActive,
                                    AssociatedPropertyUUIDs: _.map(vm.company.properties, function (property) {
                                        return property.id;
                                    })
                                })
                                .then(function (response) {
                                    vm.domains.push(response);
                                }, function (response) {

                                })
                                .finally(function () {
                                    vm.newDomain = null;
                                    ftModal.hide('flipto.modals.domains.addDomain');
                                    ftTasks.finish('flipto.tasks.newDomain');
                                });
                        }
                        else {
                            vm.domains.push(domain);
                            ftModal.hide('flipto.modals.domains.addDomain');
                            ftTasks.finish('flipto.tasks.newDomain');
                            vm.cleanAddDomainData();
                        }
                    }, function () {
                        ftTasks.finish('flipto.tasks.newDomain');
                    });


                    Domains.createDomain(vm.newDomain)
                        .then(function (data) {
                            vm.responseError = null;
                            defer.resolve(data);
                        }, function (response) {
                            vm.responseError = {
                                status: response.status,
                                data: response.data
                            };
                            defer.reject(vm.responseError);
                        });

                };

                vm.setPropertyMapping = function (domain) {
                    vm.domainMappings = {
                        domain: domain,
                        mappings: _.map(vm.company.properties, function (property) {
                            return {
                                propertyUUID: property.id,
                                name: property.name,
                                checked: !!_.find(domain.Properties, {PropertySlug: property.slug})
                            };
                        })
                    };
                    ftModal.show('flipto.modals.domains.properties');
                };

                /**
                 * Save mapping
                 */
                vm.saveMappingsSelection = function () {
                    var domain = vm.domainMappings.domain;
                    Domains.updateDomain({
                            SubdomainUUID: domain.SubdomainUUID,
                            Host: domain.Host,
                            RoutePurpose: domain.Purpose,
                            IsActive: domain.IsActive,
                            AssociatedPropertyUUIDs: _.map(_.filter(vm.domainMappings.mappings, function (mapping) {
                                return mapping.checked;
                            }), function (mapping) {
                                return mapping.propertyUUID;
                            })
                        })
                        .then(function (response) {
                            vm.domainMappings = null;
                            vm.replaceDomain(response);
                        }, function (response) {

                        });
                };

                vm.showInstructions = function () {
                    ftModal.hide('flipto.modals.domains.test')
                        .then(function () {
                            ftModal.show('flipto.modals.domains.setupInstructions');
                        });
                };

                /**
                 * Show test domain modal
                 * @param domain
                 */
                vm.testDomain = function (domain) {
                    vm.testedDomain = domain;
                    ftModal.show("flipto.modals.domains.test");
                };

                /**
                 * Run test configuration
                 */
                vm.testConfiguration = function () {
                    var domain = vm.testedDomain;
                    Domains.verify(domain.Host)
                        .then(function (status) {
                            domain.VerificationStatus = status;
                            domain.VerificationDate = moment.utc().toDate();
                        })
                        .finally(function () {
                            ftTasks.finish("flipto.tasks.test-domain." + domain.SubdomainUUID);
                        });
                };

                vm.updateDomainStatus = function (domain) {
                    ftTasks.register("flipto.tasks.test-domain." + domain.SubdomainUUID);
                    ftTasks.register("flipto.tasks.update-domain-status." + domain.SubdomainUUID);
                    Domains.updateDomain({
                            SubdomainUUID: domain.SubdomainUUID,
                            Host: domain.Host,
                            RoutePurpose: domain.Purpose,
                            IsActive: domain.IsActive,
                            AssociatedPropertyUUIDs: _.map(domain.Properties, function (property) {
                                return property.PropertyUUID;
                            })
                        })
                        .then(function (response) {
                            vm.replaceDomain(response);
                        }, function () {
                            domain.IsActive = !domain.IsActive;
                        })
                        .finally(function () {
                            ftTasks.finish("flipto.tasks.test-domain." + domain.SubdomainUUID);
                            ftTasks.finish("flipto.tasks.update-domain-status." + domain.SubdomainUUID);
                        });
                };

                vm.replaceDomain = function (newDomain) {
                    _.remove(vm.domains, function (domain) {
                        return domain.SubdomainUUID == newDomain.SubdomainUUID;
                    });
                    vm.domains.push(newDomain);
                };

                vm.removeDomain = function (domain) {
                    Domains.remove(domain.SubdomainUUID)
                        .then(function () {
                            _.remove(vm.domains, function (d) {
                                return d.SubdomainUUID == domain.SubdomainUUID;
                            });
                        })
                        .finally(function () {
                            ftTasks.finish('flipto.tasks.domain-removal');
                            ftModal.hide('flipto.modals.domains.test');
                        })
                };

                vm.cleanAddDomainData = function () {
                    delete vm.newDomain;
                    delete vm.responseError;
                };

                vm.isInTesting = function (domain) {
                    return ftTasks.isPending("flipto.tasks.test-domain." + domain.SubdomainUUID);
                };

                vm.getPropertyDefaultDomainUrl = function (property) {
                    var propertyDomains = _.filter(vm.domains, function (domain) {
                        return !!_.find(domain.Properties, function (innerProperty) {
                            return innerProperty.PropertyUUID.toLowerCase() === property.id.toLowerCase();
                        });
                    });

                    var defaultDomains = _.filter(propertyDomains, function (domain) {
                        return domain.IsDefault;
                    });

                    var urls = _.map(defaultDomains, function (domain) {
                        var includeSlug = vm.company.properties.length > 1;
                        var segments = ['http://', domain.Host, '.', vm.hubDomain];
                        if (includeSlug) {
                            segments.push('/');
                            segments.push(property.slug);
                        }
                        return Array.prototype.join.call(segments, '');
                    });

                    return urls;
                };

                vm.getPropertyDomains = function (property) {
                    var propertyDomains = _.filter(vm.domains, function (domain) {
                        return !domain.IsDefault && !!_.find(domain.Properties, function (innerProperty) {
                                return innerProperty.PropertyUUID.toLowerCase() === property.id.toLowerCase();
                            });
                    });

                    var urls = _.map(propertyDomains, function (domain) {
                        var includeSlug = vm.company.properties.length > 1;
                        var segments = ['http://', domain.Host];
                        if (includeSlug) {
                            segments.push('/');
                            segments.push(property.slug);
                        }
                        return Array.prototype.join.call(segments, '');
                    });

                    return urls;
                };

                vm.init();
            }
        ]
    );