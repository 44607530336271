/**
 * Created by Roman on 2014-10-30.
 */



angular
    .module("flipto.components.imageLibrary")
    .config(['$compileProvider', function ($compileProvider) {
        $compileProvider.directive('ftImageProperties', [function () {
            var imageTypes = [
                {type: 1, name: 'Logo'},
                {type: 2, name: 'Header logo'},
                {type: 3, name: 'Property photo'}
            ];
            return {
                restrict: 'EA',
                templateUrl: '/app/components/image-library/imageProperties.html',
                scope: {
                    'image': '='
                },
                link: function (scope) {
                    scope.imageTypes = imageTypes;
                }
            };
        }]);
    }]);