/**
 * Created by roman.kupin on 9/26/2017.
 */


import ContextSelectorActions from "../actions/index";
import { ContextSelectorSetup } from "../contextSelector.setup";

export default function contextSelectorGroupsReducer(state = {}, action) {

    switch (action.type) {
        case ContextSelectorActions.ADD_NEW: {
            let setup = <ContextSelectorSetup>JSON.parse(JSON.stringify(action.payload));
            let newState = Object.assign({}, state);
            newState[setup.id] = setup;
            return newState;
        }
        case ContextSelectorActions.SHOW: {
            let newState = Object.assign({}, state);
            newState[action.payload.contextSelectorId].isActive = true;
            newState[action.payload.contextSelectorId].contextGroups.forEach((contextGroup) => {
                contextGroup.initialActiveContextIds = contextGroup.contexts
                    .filter(context => context.isActive)
                    .map(context => context._id);
                contextGroup.totalSelected = contextGroup.initialActiveContextIds.length;
            });
            return newState;
        }
        case ContextSelectorActions.DISCARD_CHANGES: {
            let newState = Object.assign({}, state);
            newState[action.payload.contextSelectorId].contextGroups.forEach((contextGroup) => {
                contextGroup.contexts.forEach(context => {
                    context.isActive = !!contextGroup.initialActiveContextIds.find(id => context._id === id);
                });
            });
            return newState;
        }
        case ContextSelectorActions.HIDE: {
            let newState = Object.assign({}, state);
            newState[action.payload.contextSelectorId].isActive = false;
            return newState;
        }
        case ContextSelectorActions.SELECT_GROUP: {
            let newState = JSON.parse(JSON.stringify(state));
            newState[action.payload.contextSelectorId].contextGroups.filter(group => group.name === action.payload.groupName).forEach(group => group.isActive = true);
            return newState;
        }
        case ContextSelectorActions.CONTEXT_TOGGLE_MANY: {
            let newState = JSON.parse(JSON.stringify(state));
            let contextGroups = newState[action.payload.contextSelectorId].contextGroups
                .filter(group => group.name === action.payload.groupName);
            let contexts = contextGroups
                .map(group => group.contexts)
                .reduce(contexts => contexts);
            contexts
                .filter(context => context._id === action.payload.contextId)
                .forEach(context => context.isActive = !context.isActive);

            contextGroups.forEach(contextGroup => {
                contextGroup.totalSelected = contextGroup.contexts.filter(context => context.isActive).length;
            });

            return newState;
        }
        case ContextSelectorActions.CONTEXT_TOGGLE_SINGLE: {
            let newState = JSON.parse(JSON.stringify(state));
            let contexts = newState[action.payload.contextSelectorId].contextGroups
                .filter(group => group.name === action.payload.groupName)
                .map(group => group.contexts)
                .reduce(contexts => contexts);
            contexts
                .filter(context => context._id === action.payload.contextId)
                .forEach(context => context.isActive = !context.isActive);
            contexts
                .filter(context => context._id !== action.payload.contextId)
                .forEach(context => context.isActive = false);
            return newState;
        }
        case ContextSelectorActions.CONTEXT_SET_SINGLE: {
            let newState = JSON.parse(JSON.stringify(state));
            let contexts = newState[action.payload.contextSelectorId].contextGroups
                .filter(group => group.name === action.payload.groupName)
                .map(group => group.contexts)
                .reduce(contexts => contexts);
            contexts
                .filter(context => context._id === action.payload.contextId)
                .forEach(context => context.isActive = true);
            contexts
                .filter(context => context._id !== action.payload.contextId)
                .forEach(context => context.isActive = false);
            return newState;
        }
        case ContextSelectorActions.CONTEXT_SELECT_ALL: {
            let newState = JSON.parse(JSON.stringify(state));
            let contextGroups = newState[action.payload.contextSelectorId].contextGroups
                .filter(group => group.name === action.payload.groupName)
            contextGroups.map(group => group.contexts)
                .reduce(contexts => contexts)
                .forEach(context => context.isActive = true);
            contextGroups.forEach(contextGroup => {
                contextGroup.totalSelected = contextGroup.contexts.filter(context => context.isActive).length;
            });
            return newState;
        }
        case ContextSelectorActions.CONTEXT_SELECT_NONE: {
            let newState = JSON.parse(JSON.stringify(state));
            let contextGroups = newState[action.payload.contextSelectorId].contextGroups
                .filter(group => group.name === action.payload.groupName)
            contextGroups.map(group => group.contexts)
                .reduce(contexts => contexts)
                .forEach(context => context.isActive = false);

            contextGroups.forEach(contextGroup => {
                contextGroup.totalSelected = 0;
            });

            return newState;
        }
        default: {
            return state;
        }
    }

}
