import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';

@Component({
    selector: 'ft-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => DatePickerComponent)
        }
    ]
})
export class DatePickerComponent implements ControlValueAccessor {
    private _minDate: NgbDateStruct;
    private _value: NgbDateStruct = {
        year: null,
        month: null,
        day: null
    };


    @Input() set minDate(date: any) {
        const dateObj = dayjs(date).toDate(); // wrap to dayjs in order do not have issue with different timezones
        this._minDate = {
            year: dateObj.getFullYear(),
            month: dateObj.getMonth() + 1,
            day: dateObj.getDate()
        }
    }
    get minDate(): any {
        return this._minDate;
    }

    set value(date: NgbDateStruct) {
        this._value = date;
        this.onChangeValue();
    }

    get value(): NgbDateStruct {
        return this._value;
    }

    @Input() name = 'datepicker';
    @Input() disabled = false;
    @Input() readonly = false;
    @Input() nullable = false;
    @Input() outputFormat: string;
    @Input() placeholder: string;

    writeValue(value: Date | string) {
        if (this.nullable && !value) {
            this._value = null;
        } else {
            const dateObj = dayjs(value).toDate(); // wrap to dayjs in order do not have issue with different timezones
            this._value = {
                year: dateObj.getFullYear(),
                month: dateObj.getMonth() + 1,
                day: dateObj.getDate()
            }
        }
        this.onChangeValue();
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    onChangeValue() {
        if (this.nullable && !this._value) {
            this.onChange(null);
        } else {
            let date = new Date(this._value.year, this._value.month - 1, this._value.day);
            let newValue = this.outputFormat ? dayjs(date).format(this.outputFormat) : date.toDateString();
            this.onChange(newValue);
        }
    }

    onChange = (_) => {};
    onTouched = () => {};
    registerOnChange(fn: (_: any) => void): void { this.onChange = fn; }
    registerOnTouched(fn: () => void): void { this.onTouched = fn; }

}
