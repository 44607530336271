/**
 * Created by ostapenko.r on 3/6/2015.
 */
angular.module('flipto.accountv1.SharedFeatures')
    .controller('flipto.account.sections.platform.StylingReengagementEmailsTemplateController',
    ['$scope', 'touchpointGroupId', 'layerName', 'touchpointType', 'Styling', 'ftModal', 'parse', 'SpacesService',
        function ($scope, touchpointGroupId, layerName, touchpointType, Styling, ftModal, parse, spacesService) {
            'use strict';
          var vm = this,
              touchpointGroupId = touchpointGroupId,
              layerName = $scope.layerName = layerName,
              touchpointType = $scope.touchpointType = touchpointType;

            Styling.getEmail({
                propertyUuid: spacesService.getPropertyUuid(),
                layerName : layerName,
                touchpointUUID: touchpointGroupId,
                touchpointType:  touchpointType
            }).then(function(data){
                vm.noQuoteEmailTemplates = parse.arrayOrDefault(data.noQuoteEmailTemplates);
                vm.noPhotoEmailTemplates = parse.arrayOrDefault(data.noPhotoEmailTemplates);

                vm.noQuoteEmailSelectedTemplate = vm.getSelectedTemplate(vm.noQuoteEmailTemplates, data.noQuoteEmailtemplateUUID);
                vm.noPhotoEmailSelectedTemplate = vm.getSelectedTemplate(vm.noPhotoEmailTemplates, data.noPhotoEmailtemplateUUID);
            });

            vm.getSelectedTemplate = function(templates, templateUUID) {
                var defaultTemplate = _.find(templates, { isDefaultTemplate: "1" });
                defaultTemplate && (defaultTemplate.name = defaultTemplate.name + ' (Default)');
                return _.find(templates, { id: templateUUID }) || defaultTemplate;
            };

            vm.updateEmailTemplate = function(){
                Styling.putReengagementEmails({
                    propertyUuid: spacesService.getPropertyUuid(),
                    layerName : layerName,
                    touchpointUUID: touchpointGroupId,
                    touchpointType:  touchpointType,
                    noQuoteEmailTemplateUUID: vm.noQuoteEmailSelectedTemplate.isDefaultTemplate === "0" ? vm.noQuoteEmailSelectedTemplate.id : "",
                    noPhotoEmailTemplateUUID: vm.noPhotoEmailSelectedTemplate.isDefaultTemplate === "0" ? vm.noPhotoEmailSelectedTemplate.id : ""
                });
            };

        }
    ]);
