/**
 * Created by ostapenko.r on 11/5/2014.
 */

angular.module('flipto.components.common.list')
    .directive('ftRemovable', [function () {
        return {
            restrict: 'A',
            require: '^?ftList',
            compile: function(element, attrs){
                var removeButtonTemplate = angular.element('<span>&nbsp;</span><button type="button" class="btn-delete-item" ng-click="removeItem()"></button>');
                element.append(removeButtonTemplate);

                return function(scope, element, attributes, ftList){
                    if(!ftList) return;
                   scope.removeItem = function () {
                        var items = ftList.getItems();
                        var child = ftList.getExpression().child;
                        items.splice(items.indexOf(scope[child]), 1);
                    };
                }
            },
            controller: ['$scope', function($scope){

            }]
        };
    }]);