/**
 * Created by roman.kupin on 27/08/2014.
 */


angular
    .module('flipto.components.common.navigation', [])
    .config(['$compileProvider', function ($compileProvider) {

        $compileProvider.directive('ftNav',
            [
                function () {

                    return {
                        restrict: 'EA',
                        template: '<ul class="nav navbar-nav"><li ng-repeat="state in stateArray" class="{{state.title.toLowerCase()}}"><a ui-sref="{{state.name}}" ui-sref-active="active"><i class="nav-icon icon-{{state.title.toLowerCase()}}"></i>{{state.title}}</a></li></ul>',
                        scope: {
                            'stateArray': '='
                        }
                    };
                }
            ]);


        $compileProvider.directive('ftSheetTabs',
            [
                function () {

                    return {
                        restrict: 'EA',
                        template: '<div class="sheet-tabs"><ul><li ng-repeat="state in stateArray"><a ui-sref="{{state.name}}" ui-sref-active="active">{{state.title}}</a></li></ul></div>',
                        scope: {
                            'stateArray': '='
                        }
                    };
                }
            ]);

    }]);
