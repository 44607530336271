/**
 * Created by ostapenko.r on 7/22/2014.
 */

angular
    .module("flipto.components.common.select", [])
    .directive("ftSelect",
    [
        '_', function (_) {
        return {
            restrict: "E",
            templateUrl: '/app/components/common/select/select.html',
            scope: {
                'disabledControl': '=?',
                'items': '=',
                'key': '@selectKey',
                'value': '@selectValue',
                'source': '=?selectedItem',
                'sourceKey': '@?selectedItemKey',
                'autoSelect': '@?',
                'placeholder': '@?',
                'notifySelectionChanged': '&?onSelectionChanged'
            },
            link: function (scope, element, attrs) {

                scope.required = (angular.isDefined(attrs.required) && attrs.required !== 'false') || false;

                var sourceIsProperty = !_.isEmpty(scope.sourceKey);

                scope.selectedItem = {};
                scope.capitalize = angular.isDefined(attrs.capitalize);

                scope.$watchCollection('[items, source]', function (result) {
                    if (!_.isObject(scope.items) || !angular.isDefined(scope.source)) return;

                    scope.selectedKey = sourceIsProperty ? scope.source && scope.source[scope.sourceKey] : '';
                    scope.key = scope.key || scope.sourceKey;
                    if (!_.isEmpty(scope.selectedKey) || _.isNumber(scope.selectedKey)) {
                        var filter = {};
                        filter[scope.key] = scope.selectedKey;
                        scope.selectedItem = _.find(scope.items, filter);
                    }

                    if (_.isEmpty(scope.selectedItem) && scope.autoSelect) {
                        scope.selectedItem = scope.items && scope.items[0] && scope.items[0];
                        if (sourceIsProperty) {
                            scope.source[scope.sourceKey] = scope.selectedItem && scope.selectedItem[scope.key];
                        } else {
                            scope.source = scope.selectedItem;
                        }
                    }
                });

                scope.selectItem = function (item) {
                    var from = angular.copy(scope.selectedItem),
                        to = angular.copy(item);

                    if (sourceIsProperty) {
                        scope.source[scope.sourceKey] = item[scope.key];
                    } else {
                        scope.source = item;
                    }
                    scope.selectedItem = item;
                    scope.notifySelectionChanged && scope.notifySelectionChanged({from: from, to: to});
                };
                scope.getText = function () {
                    return scope.selectedItem[scope.value] || scope.placeholder;
                }
            }
        }
    }
    ]);