/**
 * Created by Roman on 2014-11-27.
 */

angular.module('flipto.components.common.images')
    .directive('ftImage', [function () {
        return {
            restrict: 'E',
            template: '<div class="ft-image-div" ft-background-image="{{url || fallbackUrl}}" ng-show="isVisible()"></div>',
            require: 'ftImage',
            scope: {
                'src': '=',
                'fallbackImage': '='
            },
            link: function (scope, elem, attrs, ftImage) {
                scope.alwaysVisible = angular.isDefined(attrs.alwaysVisible);
                loadImage('src', 'url');
                loadImage('fallbackImage', 'fallbackUrl');

                function loadImage(src, dest) {
                    angular.isDefined(attrs[src]) &&
                        scope.$watch(src, function (val) {
                            if (val) {
                                var image = new Image();
                                image.src = val;
                                image.onload = function () {
                                    scope.$apply(function () {
                                        scope[dest] = image.src;
                                        image = null;
                                    })
                                };
                                image.onerror = function () {
                                    scope.$apply(function () {
                                        image = null;
                                        scope[dest] = null;
                                    })
                                };
                            } else {
                                scope[dest] = null;
                            }
                        });
                }

                /* undefined -> we dont know yet */
                scope.$watch(function () {
                    if (angular.isDefined(scope.url) === false &&
                        angular.isDefined(scope.fallbackUrl) === false) return undefined;
                    if (scope.url === null && scope.fallbackUrl === null) return false;
                    return !!(scope.url || scope.fallbackUrl);
                }, function (exist) {
                    if (angular.isDefined(exist)) {
                        ftImage.photoExist(exist);
                    }
                });

                scope.isVisible = function () {
                    return !!(scope.alwaysVisible || ftImage.photoExist());
                }

            },
            controller: [function () {
                var exist = undefined;
                this.photoExist = function (value) {
                    if (angular.isDefined(value)) {
                        exist = value;
                    }
                    return exist;
                };
            }]

        };
    }]);