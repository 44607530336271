import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventHooksService {

  onBootstrap = new Subject();
  onBootstrap$ = this.onBootstrap.asObservable();

  constructor() { }
}
