/**
 * Created by Kevin Tiller on 06/19/2017.
 */


angular.module('flipto.core.api.propertyDemos', [])
    .config(['$provide', function ($provide) {
        $provide.factory('PropertyDemos', ['parse', 'Demotype', 'urlFormatter', '$http',
            function (parse, Demotype, urlFormatter, $http) {

                /**
                 * PropertyDemos service
                 * @constructor
                 */
                function PropertyDemos() {
                    /**
                     * Get property demos
                     * @param params
                     * @returns {*}
                     */
                    this.getAllDemos = function (params) {
                        return $http.get(urlFormatter.format('/api/:propertyId/properties/:propertyId/settings/demos', params))
                            .then(function (response) {
                                var demos = response.data.demos ? response.data.demos.item : null;
                                return [parse.arrayOrDefault(demos), parse.arrayOrDefault(response.data.types.item)];
                            });
                    };

                    /**
                     * Update property demos
                     * @param params
                     * @param model
                     * @returns {*}
                     */
                    this.updateDemo = function (params, model) {
                        return $http.put(urlFormatter.format('/api/:propertyId/properties/:propertyId/settings/demos', params, true), model)
                            .then(function (response) {
                                return response.data;
                            });
                    };

                    /**
                     * Create new demo
                     * @param params
                     * @param model
                     * @returns {*}
                     */
                    this.createDemo = function (params, model) {
                        return $http.post(urlFormatter.format('/api/:propertyId/properties/:propertyId/settings/demos', params), model)
                            .then(function (response) {
                                return response.data;
                            });
                    };

                    /**
                     * Update property demos
                     * @param params
                     * @param model
                     * @returns {*}
                     */
                    this.removeDemo = function (params) {
                        return $http.delete(urlFormatter.format('/api/:propertyId/properties/:propertyId/settings/demos', params, true))
                            .then(function (response) {
                                return response.data;
                            });
                    };

                    /**
                     * Scrape for html
                     * @param url
                     * @returns {*}
                     */
                    this.scrapeHTML = function (params) {
                        return $http.get(urlFormatter.format('/api/:propertyId/properties/:propertyId/settings/demos/scrape', params, true))
                            .then(function (response) {
                                return response.data;
                            });
                    };
                }
                return new PropertyDemos();
            }]);
    }]);