import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityOp } from '@ngrx/data';
import { catchError, delay, finalize, first, map, tap } from 'rxjs/operators';
import { HttpClient } from "@angular/common/http";
import { defer, Observable, shareReplay, throwError } from "rxjs";

export function doOnSubscribe<T>(onSubscribe: () => void): (source: Observable<T>) => Observable<T> {
    return function inner(source: Observable<T>): Observable<T> {
        return defer(() => {
            onSubscribe();
            return source;
        });
    };
}

export class CustomEntityCollectionServiceBase<T> extends EntityCollectionServiceBase<T> {
    public selectedId: number | string;
    public selectedEntity$ = this.entityMap$.pipe(map(entityMap => entityMap[this.selectedId]));
    totalLoadings = 0;

    constructor(public entityName: string,
                private serviceElementsFactory: EntityCollectionServiceElementsFactory,
                public http: HttpClient,
                public url: string) {
        super(entityName, serviceElementsFactory);
    }

    get(path: string, options?: any): Observable<any> {
        const observable = this.http.get(`${this.url}/${path}`, options).pipe(
            doOnSubscribe(() => {
                this.setLoading(true);
            }),
            catchError((error) => {
                return throwError(error);
            }),
            finalize(() => this.setLoading(false)),
            shareReplay(),
        );
        observable.pipe(first()).subscribe();
        return observable;
    }

    put(path: string, body?: any, options?: any): Observable<any> {
        const observable = this.http.put(`${this.url}/${path}`, body, options).pipe(
            doOnSubscribe(() => {
                this.setLoading(true);
            }),
            catchError((error) => {
                return throwError(error);
            }),
            finalize(() => this.setLoading(false)),
            shareReplay(),
        );
        observable.pipe(first()).subscribe();
        return observable;
    }

    setLoading(isLoading: boolean) {
        if (isLoading) {
            super.setLoading(isLoading);
            this.totalLoadings++;
        } else {
            this.totalLoadings--;
            if (this.totalLoadings === 0) {
                super.setLoading(isLoading);
            }
        }

    }
}
