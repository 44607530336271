<div class="ft2-form-error" *ngIf="error">
    <ng-container *ngIf="error.Status === 400; else defaultError">
        <ng-container [ngSwitch]="error.Message">
            <ng-container *ngSwitchDefault>
                Error processing request
            </ng-container>
            <ng-container *ngSwitchCase="'INVALID_CREDENTIALS'">
                Link is expired, please refresh the page.
            </ng-container>
            <ng-container *ngSwitchCase="'PASSWORD_WAS_USED_PREVIOUSLY'">
                This password was used previously, please create new password.
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-template #defaultError>
        {{error.Message || 'There was an error. Please try again later.'}}
    </ng-template>
</div>
