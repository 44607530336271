/**
 * Created by Roman on 2014-09-29.
 */


angular.module('flipto.core.api.layers', ['flipto.core.parse'])
    .config(['$provide', function ($provide) {

        $provide.factory('Layers', ['urlFormatter', '$http', 'parse', function (urlFormatter, $http, parse) {

            /**
             * Layers service
             * @constructor
             */
            function Layers() {
                this.basePath = '/api/:propertyId/properties/:propertyId/layers/:layer';

                this.prestay = function (params) {
                    params.layer = 'prestay';
                    return $http.get(urlFormatter.format(this.basePath, params, true))
                        .then(function (response) {
                            response.data && response.data.campaigns && angular.forEach(response.data.campaigns.item, setupCampaign);
                            return response.data;
                        });
                };

                this.quotelist = function (params) {
                    params.layer = 'quotelist';
                    return $http.get(urlFormatter.format(this.basePath, params, true))
                        .then(function (response) {
                            return response.data;
                        });
                };

                this.poststay = function (params) {
                    params.layer = 'poststay';
                    return $http.get(urlFormatter.format(this.basePath, params, true))
                        .then(function (response) {
                            return response.data;
                        });
                };

                this.photocontest = function (params) {
                    params.layer = 'photocontest';
                    return $http.get(urlFormatter.format(this.basePath, params, true))
                        .then(function (response) {
                            return response.data;
                        });
                };

                this.homepage = function (params) {
                    params.layer = 'homepage';
                    return $http.get(urlFormatter.format(this.basePath, params, true))
                        .then(function (response) {
                            return response.data;
                        });
                };

                this.groupAdvocacy = function (propertyUUID) {
                    return $http.get(urlFormatter.format(this.basePath, {layer: 'groupadvocacy', propertyId: propertyUUID}))
                        .then(function (response) {
                            return response.data;
                        });
                };


                function setupCampaign(campaign){
                    campaign._isActiveOrder = -campaign.isActive;
                    campaign.goals && angular.forEach(campaign.goals.item, setupGoal);
                    batchConvert(campaign, ['type', 'isActive', 'campaignGuests', 'campaignShares', 'campaignUniqueVisitors'], parse.intOrDefault);
                }

                function setupGoal(goal){
                    batchConvert(goal, ['clicks', 'signups', 'type', 'isActive'], parse.intOrDefault);
                }

                function batchConvert(object, properties, converter){
                    angular.forEach(properties, function (property) {
                        object[property] = converter(object[property]);
                    })
                }

                /**
                 * Create a new layer
                 * @param params
                 * @param model
                 * @returns {*}
                 */
                this.create = function (params, model) {
                    return $http.post(urlFormatter.format('/api/:companyId/properties/layers/:propertyId/create', params), model)
                        .then(function (response) {
                            return response.data;
                        });
                };
            }

            return new Layers();
        }]);
    }]);