/**
 * Created by Roman on 2015-05-13.
 */


angular.module('flipto.components.curation')
    .provider('shareOAuth', [function () {

        var v1BaseUrl = "";

        this.$get = ['$window', 'urlFormatter', 'AppLog', 'environment', function ($window, urlFormatter, AppLog, environment) {
            return new OAuthWindow(AppLog, $window, urlFormatter, environment, v1BaseUrl);
        }];

        this.setV1BaseUrl = function (url) {
            v1BaseUrl = url;
        };

        /**
         * Oauth service
         * @constructor
         * @param $window
         * @param urlFormatter
         * @param AppLog
         * @param environment
         */
        function OAuthWindow(AppLog, $window, urlFormatter, environment, v1BaseUrl) {
            this.AppLog = AppLog;
            this.$window = $window;
            this.urlFormatter = urlFormatter;
            this.environment = environment;
            this.v1BaseUrl = v1BaseUrl;
        }

        /**
         * Window instance
         */
        OAuthWindow.windowInstance;

        /**
         * Window size by network settings
         * @type {{1: {width: number, height: number}, 2: {width: number, height: number}, 3: {width: number, height: number}}}
         */
        OAuthWindow.windowSizeByNetwork = {
            '1': { width: 640, height: 730 },
            '2': { width: 800, height: 450 },
            '3': { width: 600, height: 600 }
        };

        /**
         * Returns true if network supported
         * @param network
         * @returns {boolean}
         */
        OAuthWindow.prototype.isSupported = function (networkType) {
            return networkType == 1 ||
                networkType == 2 ||
                networkType == 3;
        };

        /**
         * Returns window features or null if network does not require open window
         * @param network
         * @returns {*}
         */
        OAuthWindow.prototype.getWindowFeatures = function (networkType) {
            if (!this.isSupported(networkType)) throw 'Network does not require oauth';
            var size = OAuthWindow.windowSizeByNetwork[networkType];
            var left = (this.$window.document.documentElement.clientWidth / 2) - (size.width / 2),
                top = (this.$window.document.documentElement.clientHeight / 2) - (size.height / 2);

            var features = 'titlebar=0,toolbar=0,location=0,directories=0,status=0,menubar=0,scrollbars=yes,resize=yes,modal=yes,width=' + size.width + ',height=' + size.height + ',top=' + top + ',left=' + left,
                debugFeatures = "";

            return this.environment.debugOAuth ? debugFeatures : features;
        };

        /**
         * Open oauth window if required by network
         * @param network
         */
        OAuthWindow.prototype.tryOpenWindow = function (networkType) {
            if (!this.isSupported(networkType)) return;
            var features = this.getWindowFeatures(networkType);
            OAuthWindow.windowInstance = this.$window.open('', '_blank', features);
        };

        /**
         * Set oauth location if window is open
         * @param network
         * @param model
         */
        OAuthWindow.prototype.trySetLocation = function (networkType, model) {
            if (!this.isSupported(networkType) || !OAuthWindow.windowInstance) return;
            if (networkType == 1) {
                if (!!model.invitationUrl) {
                    OAuthWindow.windowInstance.location = this.v1BaseUrl + this.urlFormatter.format('oauth/facebook/invitation-share', model, true);
                }
                else if (!!model.websiteShareUrl) {
                    OAuthWindow.windowInstance.location = this.v1BaseUrl + this.urlFormatter.format('oauth/facebook/website-share', model, true);
                }
                else {
                    OAuthWindow.windowInstance.location = this.v1BaseUrl + this.urlFormatter.format('oauth/facebook/curation-share', model, true);
                }
            } else {
                OAuthWindow.windowInstance.location = this.v1BaseUrl + this.urlFormatter.format('socialnetwork/sharing/oauth/authorize', model, true);
            }
        };

        /**
         * Close oauth window if any
         */
        OAuthWindow.prototype.close = function () {
            if (OAuthWindow.windowInstance) {
                OAuthWindow.windowInstance.close();
            }
        };

        /**
         * Logs action if debug=true
         */
        OAuthWindow.prototype.log = function (action, args) {
            this.AppLog.info(action, args);
        };

    }]);
