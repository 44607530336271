import {
    ContentChild,
    Directive,
    ElementRef,
    EventEmitter,
    InjectionToken,
    Input,
    OnDestroy,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { ControlValueAccessor, NgModel } from '@angular/forms';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { EditorField } from 'apps/account/src/account-shared';
import { LanguageVariationsComponent } from '../components/language-variations/language-variations.component';
import { EditorLanguageVariation } from '../models/editor-language-variation.model';
import { ReplaceInvalidSymbolsDirective } from '../directives/replace-invalid-symbols.directive';
import { LanguageName } from 'shared';

export const MODAL_EDITOR_TOKEN = new InjectionToken('MODAL_EDITOR_TOKEN');

@Directive()
export abstract class ModalEditorBase implements ControlValueAccessor, OnDestroy {

    @Input() set value(value: any) {
        this.value$.next(cloneDeep(this.mapValue(value)));
    }

    get value() {
        return this.value$.getValue();
    }

    get controlValue() {
        return this.value;
    }

    @ViewChild('control', { read: NgModel, static: false }) control: NgModel;
    @ViewChild(LanguageVariationsComponent, { static: false }) languageVariationsRef: LanguageVariationsComponent;
    @ViewChildren('controlsModelRef', { read: NgModel }) controlsModelRef: QueryList<NgModel>;
    @ViewChildren('controlsRef', { read: ElementRef }) controlsRef: QueryList<ElementRef>;
    @ContentChild('title', { static: false }) titleTemplate: TemplateRef<any>;
    @ContentChild('summary', { static: false }) summaryTemplate: TemplateRef<any>;
    @Input() title: string;
    @Input() summary: string;
    @Input() buttonLabel = 'Save';
    @Input() name: string;
    @Input() required: boolean;
    @Input() replaceInvalidSymbols = false;
    @Input() placeholder = '';
    @Input() label: string;
    @Input() saveSuccess$: Observable<any>;
    @Input() error$: Observable<any>;
    @Input() defaultError = 'Error processing request';
    value$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    initialValue: any;
    onChangeFn: any;
    @Output() save = new EventEmitter();
    @Output() cancel = new EventEmitter();
    isNgModelExist = false;
    visible = false;
    destroySubj = new Subject();
    activeVariations: EditorLanguageVariation[] = [];
    fields: EditorField[] = [];

    constructor() {
    }

    ngOnDestroy() {
        this.destroySubj.next(null);
    }

    writeValue(value: any): void {
        this.value = value;
        if (!this.initialValue) {
            this.initialValue = value;
        }
    }

    registerOnChange(fn: any): void {
        this.onChangeFn = fn;
        this.isNgModelExist = true;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState?(isDisabled: boolean): void {
    }

    visibilityChanged(state: boolean) {
        this.visible = state;
        if (this.visible) {
            this.onEditorVisible();
        } else {
            this.onEditorHidden();
        }
    }

    onEditorVisible(): void {
    }

    onEditorHidden(): void {
    }

    onModelChange(value: any) {
        if (this.isNgModelExist) {
            this.value = value;
        }
    }

    onModelInit(): Observable<any> {
        return this.value$.pipe(
            filter((value => !!value)),
            first()
        );
    }

    get outputValue() {
        if (this.name) {
            return { [this.name]: this.controlValue };
        }

        return this.controlValue;
    }

    isInvalid() {
        if (this.control) {
            return this.control.invalid;
        }
        return false;
    }

    updateInitialValue() {
        this.initialValue = cloneDeep(this.controlValue);
    }

    applyChanges() {
        // replace curly quotation etc. conditionally
        if (this.replaceInvalidSymbols) {
            this.value = Array.isArray(this.value)
                ? this.value.map(value => {
                    const valueToUpdate = { ...value };
                    this.fields?.forEach(field => valueToUpdate[field.name] = ReplaceInvalidSymbolsDirective.replaceInvalidSymbols(valueToUpdate[field.name]));
                    return valueToUpdate;
                })
                : ReplaceInvalidSymbolsDirective.replaceInvalidSymbols(this.value);
        }

        if (this.isNgModelExist) {
            this.onChangeFn(this.controlValue);
        }
        this.save.next(this.outputValue);
    }

    onCancelChanges() {
        this.value = this.initialValue;
        if (this.isNgModelExist) {
            this.onChangeFn(this.initialValue);
        }
    }

    onCancel() {
        this.cancel.next(null);
    }

    mapValue(value: any) {
        return value;
    }

    get valueObjectKeys() {
        return Object.keys(this.controlValue);
    }

    getInputAttributeByName(controlName: string, attribute: string) {
        return this.controlsRef.find(control => control.nativeElement.getAttribute('name') === controlName)?.nativeElement?.getAttribute(attribute);
    }

    public languageNameByCode(languageCode: string) {
        return LanguageName[languageCode];
    }


    isValueRemovable(i: number) {
        return false;
    }

    removeValue(i: number, fieldName: string) {
        if (Array.isArray(this.controlValue)) {
            this.controlValue[i][fieldName] = '';

            this.languageVariationsRef.removeVariation(this.controlValue[i]?.languageCode);
        }
    }

    onActiveVariationChanged(variations: EditorLanguageVariation[]) {
        this.activeVariations = variations;
    }

    isVariationActive(languageCode: string) {
        return this.activeVariations?.find(variation => variation.languageCode === languageCode)?.isVariationActive;
    }

    get activeVariationsCount(): number {
        const test = {
            'csm': {
                'first': 'Joseph',
                'email': 'jmeuse@flip.to',
                'profileImageUrl': 'https:\/\/cdn.flip.to\/public\/2a56d9d0-3941-4e3f-85a3-6cb69c26416a-thumbnail.jpg'
            },
            'features': {
                'plannerSpecialCodes': false,
                'plannerHeadcount': true,
                'plannerFlight': false,
                'discovery': true,
                'plannerExperiences': true
            }
        };


        return this.activeVariations?.filter(variation => variation.isVariationActive)?.length;
    }
}
