/**
 * Created by roman.kupin on 2/20/2018.
 */

import * as AdminReportsActions from "../actions/index";


export default function adminReportsReducer(state = {
    isInitialized: false,
    startDate: null,
    endDate: null
}, {type, payload}) {
    switch (type) {
        case AdminReportsActions.INITIALIZED: {
            return Object.assign({}, state, {isInitialized: payload});
        }/*
        case HubActions.DATE_RANGE_CHANGE: {
            return Object.assign({}, state, {startDate: payload.startDate, endDate: payload.endDate});
        }*/
        default: {
            return state;
        }
    }
}