import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Language } from 'account-hybrid/common/models/language.model';
import { selectActiveLanguages } from 'account-hybrid/common/modules/account-management/store';

@Component({
    selector: 'ft-multi-language',
    templateUrl: './multi-language.component.html',
    standalone: true
})
export class MultiLanguageComponent {
    languages$: Observable<Language[]>;
    selectedPreviewLanguage: Language = {
        languageCode: 'en',
        languageName: 'English'
    };

    constructor(public store: Store) {
        this.languages$ = this.store.pipe(select(selectActiveLanguages));
    }


    onPreviewLanguageChange(language: Language) {
        this.selectedPreviewLanguage = language;
    }
}
