import { AccountLanguagesState } from './account-languages.state';
import { createReducer, on } from '@ngrx/store';
import {
    createAccountLanguage,
    createAccountLanguageFailure,
    createAccountLanguageSuccess,
    loadAccountLanguages,
    loadAccountLanguagesFailure,
    loadAccountLanguagesSuccess,
    loadAvailableAccountLanguages,
    loadAvailableAccountLanguagesFailure,
    loadAvailableAccountLanguagesSuccess,
    removeAccountLanguage,
    removeAccountLanguageFailure,
    removeAccountLanguageSuccess,
    updateAccountLanguages,
    updateAccountLanguagesFailure,
    updateAccountLanguagesSuccess
} from './account-languages.actions';

const initialState: AccountLanguagesState = {
    languages: [],
    availableLanguages: [],
    loaded: false,
    loading: false,
    error: null
};

export const accountLanguagesReducer = createReducer(initialState,
    on(loadAccountLanguages, state => ({ ...state, loading: true, loaded: false, error: null })),
    on(loadAccountLanguagesSuccess, (state, action) => ({ ...state, loading: false, loaded: true, error: null, languages: action.languages })),
    on(loadAccountLanguagesFailure, (state, action) => ({ ...state, loading: false, loaded: false, error: action.error, languages: [] })),
    on(loadAvailableAccountLanguages, state => ({ ...state, loading: true, loaded: false, error: null, availableLanguages: [] })),
    on(loadAvailableAccountLanguagesSuccess, (state, action) => ({ ...state, loading: false, loaded: true, error: null, availableLanguages: action.languages })),
    on(loadAvailableAccountLanguagesFailure, (state, action) => ({ ...state, loading: false, loaded: false, error: action.error, availableLanguages: [] })),
    on(createAccountLanguage, state => ({ ...state, loading: true, loaded: false, error: null })),
    on(createAccountLanguageSuccess, state => ({ ...state, loading: false, loaded: true, error: null })),
    on(createAccountLanguageFailure, (state, action) => ({ ...state, loading: false, loaded: false, error: action.error })),
    on(updateAccountLanguages, (state, action) => ({ ...state, languages: action.languages, loading: false, loaded: false, error: null })),
    on(updateAccountLanguagesSuccess, state => ({ ...state, loading: false, loaded: true, error: null })),
    on(updateAccountLanguagesFailure, (state, action) => ({ ...state, loading: false, loaded: false, error: action.error })),
    on(removeAccountLanguage, (state, action) => {
          const languages = [...state.languages];
          languages.splice(languages.findIndex(language => language.languageCode === action.languageCode), 1);

          return {
              ...state,
              languages,
              loading: true,
              loaded: false,
              error: null
          }
    }),
    on(removeAccountLanguageSuccess, state => ({ ...state, loading: false, loaded: true, error: null })),
    on(removeAccountLanguageFailure, (state, action) => ({ ...state, loading: false, loaded: false, error: action.error }))
);
