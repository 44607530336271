import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: 'ftLoadingCallback',
    exportAs: 'loadingCallback',
    standalone: true
})
export class LoadingCallbackDirective {
    @HostListener('loaded', ['$event']) onLoaded(value) {
        this.value = value;
    }

    value = false;
}
