/**
 * Created by roman.kupin on 7/30/2018.
 */


import * as ExpandableContentComponent from "./expandableContent.directive";

declare const angular;
export const EXPANDABLE_CONTENT_MODULE = angular.module("AccountApp.v2.Components.ExpandableContent", []);

export class ExpandableContentConfig {

    static $inject = ["$compileProvider"];

    constructor($compileProvider) {
        const directives = [ExpandableContentComponent];
        directives.forEach(directive => {
            $compileProvider.directive(directive.NAME, directive.DEFINITION);
        });
    }
}

EXPANDABLE_CONTENT_MODULE.config(ExpandableContentConfig);