/**
 * Created by roman.kupin on 2/20/2018.
 */


import Actions from "./actions/index";

const TEMPLATE_URL = require("./adminReports.component.html");

export const NAME = "ftAdminReports";


export class AdminReportsController {

    static $inject = ["$ngRedux", "snapshot", "$controller", 'IdentityService'];

    hub: any;
    unsubscribe: any;
    properties: any[];
    company: any;
    $ftReportsController: any;

    constructor(public $ngRedux,
                public snapshot,
                public $controller,
                public identity) {
        this.unsubscribe = $ngRedux.connect(state => {
            return {
                ...state.views.adminReports
            };
        }, Actions)(this);
        $ngRedux.dispatch(Actions.initialized(true));

        this.$ftReportsController = () => {
            return this.$controller("flipto.account.sections.admin.reports.AdminReportsController");
        }
    }

    $onDestroy() {
        this.unsubscribe();
    }

}

export const OPTIONS = {
    templateUrl: TEMPLATE_URL,
    controllerAs: "vm",
    controller: AdminReportsController
};
