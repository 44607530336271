
angular.module('flipto.components.common.scroll', [])
    .config(['$compileProvider', '$provide', function ($compileProvider, $provide) {

        $provide.service("scroll", [function() {
            
            function ScrollService() {
    
            }

            ScrollService.prototype.scrollTo = function(element, duration) {
                var startingY = window.pageYOffset;
                var elementY = window.pageYOffset + element.getBoundingClientRect().top;

                var scrollHeight = Math.max(
                    Math.max(document.body.scrollHeight, document.documentElement.scrollHeight),
                    Math.max(document.body.offsetHeight, document.documentElement.offsetHeight),
                    Math.max(document.body.clientHeight, document.documentElement.clientHeight)
                );

                // If element is close to page's bottom then window will scroll only to some position above the element.
                var targetY = scrollHeight - elementY < window.innerHeight ? scrollHeight - window.innerHeight : elementY;
                var diff = targetY - startingY;
                // Easing function: easeInOutCubic
                // From: https://gist.github.com/gre/1650294
                var easing = function(t) { return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1 };
                var start;

                if (!diff) return;

                // Bootstrap our animation - it will get called right before next frame shall be rendered.
                window.requestAnimationFrame(function step(timestamp) {
                    if (!start) start = timestamp;
                    // Elapsed miliseconds since start of scrolling.
                    var time = timestamp - start;
                    // Get percent of completion in range [0, 1].
                    var percent = Math.min(time / duration, 1);
                    // Apply the easing.
                    // It can cause bad-looking slow frames in browser performance tool, so be careful.
                    percent = easing(percent);

                    window.scrollTo(0, startingY + diff * percent);

                    // Proceed with animation as long as we wanted it to.
                    if (time < duration) {
                        window.requestAnimationFrame(step);
                    }
                });
            };

            return new ScrollService();

        }]);


        $compileProvider.directive('ftScrollTo', ['scroll', function (scroll) {
            return {
                restrict: 'A',
                link: function (scope, element, attrs) {

                    function scrollToElement() {
                        scroll.scrollTo(document.querySelector(attrs.ftScrollTo), 1);
                    }

                    element.bind("click", scrollToElement);
                    scope.$on("$destroy", function() {
                        element.unbind("click", scrollToElement);
                    });
                }
            };
        }]);
    }]);