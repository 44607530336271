/**
 * Created by ostapenko.r on 9/19/2014.
 */
angular.module('flipto.components.common.listEditor')
    .directive('ftAddToList', [function () {
        return {
            restrict: 'A',
            require: '^ftListEditor',
            link: function (scope, element, attrs, ftListEditor) {
                element.bind('click', function () {
                    var value = attrs.ftAddToList && scope.$eval(attrs.ftAddToList);
                    if(value){
                        scope.$apply(function(){
                            ftListEditor.addItem(value);
                        });
                    }
                });

                scope.$on('$destroy', function () {
                    element.unbind('click');
                });
            }
        };
    }]);