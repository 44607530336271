/**
 * Created by ostapenko.r on 12/3/2014.
 */


angular.module('flipto.accountv1.SharedFeatures')
    .directive('ftSubmissionSummary', ['$animate', '$compile', '$filter', function ($animate, $compile, $filter) {

        var translate = $filter('translate'),
            summaryTemplate = '<div class="photo-info submission">' +
                '<ft-image src="profileImage" ft-profile-photo></ft-image>' +
                '<div class="ft-label">{{getStateName(state)}}</div>' +
                '</div>',
            submissionStateMap = {
                0: '',
                1: translate('flipto.account.sections.platform.photoAdvocacy.Passed'),
                2: translate('flipto.account.sections.platform.photoAdvocacy.New'),
                3: translate('flipto.account.sections.platform.photoAdvocacy.Finalist')
            };

        function getStateName(type) {
            return submissionStateMap[type];
        }

        return {
            restrict: 'EA',
            link: function (scope, elem, attrs) {

                scope.$watch(function () {
                    return scope.$eval(attrs.ftSubmissionSummary);
                }, showSummary);
                var summary;

                function showSummary(submission) {
                    if(!!summary) $animate.leave(summary);
                    if (!submission)return;
                    var state = submission.isPassed ? 1 : (submission.isNewSubmission ? 2 : (submission.isFinalist ? 3 : 0));
                    var childScope = angular.extend(scope.$new(true), {
                        profileImage: submission.profileImageUrl,
                        state: state,
                        getStateName: getStateName
                    });

                    summary = $compile(summaryTemplate)(childScope);
                    $animate.enter(summary, elem);
                }
            }
        };
    }]);