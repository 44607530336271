import dayjs from "dayjs";
import { SocialNetwork, SocialNetworkName, SocialNetworkShortName } from "./curation.enum";

export const SOCIAL_NETWORKS = [
    { type: SocialNetwork.Facebook, title: SocialNetworkShortName.Facebook, name: SocialNetworkName.Facebook },
    { type: SocialNetwork.Twitter, title: SocialNetworkShortName.Twitter, name: SocialNetworkName.Twitter },
    { type: SocialNetwork.LinkedIn, title: SocialNetworkShortName.LinkedIn, name: SocialNetworkName.LinkedIn },
    { type: SocialNetwork.Instagram, title: SocialNetworkShortName.Instagram, name: SocialNetworkName.Instagram }
];

export const CURATION_FILTERS = [
    {
        name: 'Current',
        query: {
            SortBy: 'create_date',
            SortDirection: 'desc',
            Offset: 0,
            Fetch: 6,
            isCurrentOnly: true
        }
    },
    {
        name: 'Older',
        query: {
            SortBy: 'create_date',
            SortDirection: 'desc',
            Offset: 0,
            Fetch: 6,
            isCurrentOnly: false
        }
    }
];

export const CURATION_FILTERS_QUOTES = [
    {
        name: 'Current',
        query: {
            SortBy: 'create_date',
            SortDirection: 'desc',
            Offset: 0,
            Fetch: 6,
            isCurrentOnly: true,
            cutoffDate: dayjs().subtract(7, 'days').format('YYYY-MM-DD')
        }
    },
    {
        name: 'Older',
        query: {
            SortBy: 'create_date',
            SortDirection: 'desc',
            Offset: 0,
            Fetch: 6,
            isCurrentOnly: false,
            cutoffDate: dayjs().subtract(7, 'days').format('YYYY-MM-DD')
        }
    }
];
