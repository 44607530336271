/**
 * User: Kupin.R
 */

angular
    .module('flipto.core.inherits', [])
    .factory('inherits', [
        function () {

            function inherits(parent, child) {

                if (!parent || !child) throw 'Argument null';

                var F = function () {
                };
                F.prototype = parent.prototype;
                child.prototype = new F();
                child.prototype.constructor = child;
                child.superClass = parent.prototype;
            }

            return inherits;
        }]);
