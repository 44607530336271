/**
 * Created by roman.kupin on 9/8/2017.
 */

import "./account";
import "./admin";
import "./advocates";
import "./bookingEngines";
import "./campaign";
import "./certificates";
import "./confirmationImage";
import "./domains";
import "./general";
import "./groupAdvocacy";
import "./guests";
import "./images";
import "./incentives";
import "./languages";
import "./layers";
import "./photoAdvocacy";
import "./platform";
import "./postStay";
import "./prestay";
import "./property";
import "./propertyDemos";
import "./reports";
import "./settingsBranding";
import "./settingsDetails";
import "./settingsIncentive";
import "./settingsLanguages";
import "./settingsNotifications";
import "./settingsPrivacy";
import "./styling";
import "./touchpoint";
import "./user";
import "./rateCodes";
import "./dataParse";

angular.module("flipto.core.api", [
    "flipto.core.api.account",
    "flipto.core.api.admin",
    "flipto.core.api.advocates",
    "flipto.core.api.bookingEngines",
    "flipto.core.api.campaign",
    "flipto.core.api.certificates",
    "flipto.core.api.confirmationImage",
    "flipto.core.api.domains",
    "flipto.core.api.general",
    "flipto.core.api.groupAdvocacy",
    "flipto.core.api.guests",
    "flipto.core.api.image",
    "flipto.core.api.incentives",
    "flipto.core.api.languages",
    "flipto.core.api.layers",
    "flipto.core.api.photoAdvocacy",
    "flipto.core.api.platform",
    "flipto.core.api.postStay",
    "flipto.core.api.preStay",
    "flipto.core.api.property",
    "flipto.core.api.propertyDemos",
    "flipto.core.api.reports",
    "flipto.core.api.settingsBranding",
    "flipto.core.api.settingsDetails",
    "flipto.core.api.settingsIncentive",
    "flipto.core.api.settingsLanguages",
    "flipto.core.api.settingsNotifications",
    "flipto.core.api.settingsPrivacy",
    "flipto.core.api.styling",
    "flipto.core.api.touchpoint",
    "flipto.core.api.user",
    "flipto.core.api.rateCodes",
    "flipto.core.api.dataParse"
]);