/**
 * Created by kpomk on 1/9/2017.
 */


angular.module('flipto.accountv1.SharedFeatures')
    .service("invitationShareModal", ["$compile", "$rootScope", "ftModal", function ($compile, $rootScope, ftModal) {


        function InvitationShareModal() {

        }

        InvitationShareModal.prototype.inProgress = false;
        InvitationShareModal.prototype.show = function(contestUUID, companyCode, contestName, invitationBackground,
                                                       invitationUrl, property,  network, languageCode){

            if(this.inProgress) return;
            this.inProgress = true;

            var scope = angular.extend($rootScope.$new(true), {
                contestUUID: contestUUID,
                companyCode: companyCode,
                property: property,
                contest: contestName,
                network: network,
                invitationBackground: invitationBackground,
                propertyBackground: property.BackgroundImageUrl,
                invitationUrl: invitationUrl,
                languageCode: languageCode
            });

            $compile('<ft-contest-invitation-share contest-uuid="contestUUID" company-code="companyCode" property="property" contest="contest" network="network" invitation-background="invitationBackground" property-background="propertyBackground" invitation-url="invitationUrl" language-code="languageCode"></ft-contest-invitation-share>')(scope);
            var deRegFn = scope.$on('modal-invitationShare.create', function () {
                ftModal.show('invitationShare');
                deRegFn();
            });
            scope.$on('modal-invitationShare.cancel', function () {
                scope.$destroy();
            });
            scope.$on('$destroy', angular.bind(this, function () {
                this.inProgress = false;
            }));
            scope.$on('$locationChangeSuccess', angular.bind(this, function () {
                this.inProgress && ftModal.hide('invitationShare')
                    .then(function () {
                        ftModal.destroy('invitationShare');
                        scope.$destroy();
                    });
            }));

        };

        return new InvitationShareModal();
    }]);
