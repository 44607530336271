/**
 * Created by roman.kupin on 2/15/2017.
 */



angular.module('flipto.accountv1.SharedFeatures')
    .controller('flipto.account.sections.stories.QuotesController',
        [
            'Guests', 'curationModal', 'isCuration', '$scope', 'moment', "$rootScope", 'SpacesService', 'SessionService',
            function (Guests, curationModal, isCuration, $scope, moment, $rootScope, spacesService, sessionService) {

                var vm = this;
                vm.isCuration = isCuration;
                vm.scrollDisabled = false;
                vm.isProcessingRequest = false;
                vm.quotes = [];
                vm.isRecentOnly = true;

                var libraryFilters = [
                        {
                            name: 'Most recent',
                            query: {SortBy: 'create_date', SortDirection: 'desc', Offset: 0, Fetch: 24}
                        },
                        {
                            name: 'Highest rating',
                            query: {SortBy: 'rating', SortDirection: 'desc', Offset: 0, Fetch: 24}
                        }
                    ],
                    curationFilters = [
                        {
                            name: 'Most recent',
                            query: {SortBy: 'create_date', SortDirection: 'desc', Offset: 0, Fetch: 6, cutoffDate: moment().subtract('days', 7).toDate()}
                        },
                        {
                            name: 'Older',
                            query: {SortBy: 'create_date', SortDirection: 'desc', Offset: 0, Fetch: 6, cutoffDate: moment().subtract('days', 90).toDate()}
                        }
                    ];

                vm.filters = vm.isCuration ? curationFilters : libraryFilters;

                /**
                 * Load photos
                 * @param query
                 */
                vm.load = function (query) {
                    vm.scrollDisabled = true;
                    vm.isProcessingRequest = true;
                    var loader = vm.isCuration ? Guests.unratedStoriesNoPhotos : Guests.quotes;
                    return loader(spacesService.getPropertyUuid(), query)
                        .then(function (quotes) {
                            let total;
                            if (!angular.isArray(quotes) && quotes.hasOwnProperty('stories')) {
                                total = angular.isArray(quotes.stories) && quotes.stories.length > 0 ? quotes.stories[0].totalCount : 0;
                            } else {
                                total = angular.isArray(quotes) && quotes.length > 0 ? quotes[0].totalCount : 0;
                            }
                            vm.hasMoreData = total > (vm.filter.query.Offset + vm.filter.query.Fetch);
                            return angular.isArray(quotes) ? quotes : quotes.stories;
                        })
                        .finally(function () {
                            vm.scrollDisabled = false;
                            vm.isProcessingRequest = false;
                        });
                };

                /**
                 * Load next page
                 */
                vm.loadNextPage = function () {
                    if(!vm.isCuration) {
                        vm.filter.query.Offset += vm.filter.query.Fetch;
                    } else {
                        var notCurated = _.filter(vm.quotes, function (item) {
                            return item.isCurated !== true;
                        });
                        vm.filter.query.Offset = notCurated.length;
                    }
                    vm.setPageSizeForCurationRecentPhotos();
                    return vm.load(angular.extend({}, vm.filter.query))
                        .then(function (quotes) {
                            vm.quotes.push.apply(vm.quotes, quotes);
                            $scope.$emit("curation-controller.counters.guest-quotes", {total: vm.getTotal(), isRecentOnly: vm.isRecentOnly});
                            return quotes;
                        });
                };

                /**
                 * Set pagesize to 24 for all subsequent pages on curation
                 */
                vm.setPageSizeForCurationRecentPhotos = function () {
                    if (vm.isCuration) {
                        vm.filter.query.Fetch = 24;
                    }
                };

                /**
                 * Change filter
                 * @param newFilter
                 */
                vm.changeFilter = function (newFilter) {
                    vm.scrollLoadsResetCounter = 0;
                    vm.quotes = [];
                    vm.filter = angular.copy(newFilter);
                    return vm.load(angular.extend({}, vm.filter.query))
                        .then(function (quotes) {
                            vm.quotes = angular.isArray(quotes) ? quotes : [];
                            $scope.$emit("curation-controller.counters.guest-quotes", {total: vm.getTotal(), isRecentOnly: vm.isRecentOnly});
                            return vm.quotes;
                        });
                };

                vm.curate = function (story) {
                    curationModal.new(sessionService.getProperty(spacesService.getPropertyUuid()), story.promoter.guestUUID, true);
                };

                vm.openStory = function (story, isCuration) {
                    if (isCuration) {
                        vm.curate(story);
                    } else {
                        $rootScope.$broadcast("[StoryModal].OpenStory", { propertyUuid: spacesService.getPropertyUuid(), storyUuid: story.promoter.guestUUID, isOpenLibrary: false });
                    }
                };

                /**
                 * Show advocate details
                 * @param story
                 */
                vm.curateBulk = function (story) {
                    var chunk = vm.getChunk(story);
                    var total = vm.getTotalNotCurated() - (!story.isCurated ? 1 : 0);
                    curationModal.new(sessionService.getProperty(spacesService.getPropertyUuid()), story.promoter.guestUUID, true, chunk, total, vm.nextChunk);
                };

                vm.getChunk = function (story) {
                    var notCurated = _.filter(vm.quotes, function (item) {
                        return item.isCurated !== true;
                    });
                    var notCuratedWithoutSelected = _.filter(notCurated, function (item) {
                        return item.promoter.guestUUID !== story.promoter.guestUUID;
                    });
                    return vm.getCurationChunkFromQuotes(notCuratedWithoutSelected);
                };

                vm.getTotalNotCurated = function () {
                    if(!vm.quotes || !vm.quotes.length) return 0;
                    var curated = _.filter(vm.quotes, function (item) {
                        return item.isCurated === true;
                    });
                    return vm.quotes[0].totalCount - curated.length;
                };

                vm.getNotCurated = function() {
                    return _.filter(vm.quotes, function(item) {
                        return item.isCurated !== true;
                    });
                };

                vm.curateBulkFromEmailLink = function (guestUUID) {
                    var notCurated = _.filter(vm.quotes, function (item) {
                        return item.isCurated !== true;
                    });
                    var curated = _.filter(vm.quotes, function (item) {
                        return item.isCurated === true;
                    });
                    var chunk = vm.getCurationChunkFromQuotes(notCurated);
                    var total = vm.quotes[0].totalCount - 1- curated.length;
                    curationModal.new(sessionService.getProperty(spacesService.getPropertyUuid()), guestUUID, true, chunk, total, vm.nextChunk);
                };

                vm.nextChunk = function () {
                    vm.setPageSizeForCurationRecentPhotos();
                    return vm.load(angular.extend({}, vm.filter.query))
                        .then(function (quotes) {
                            vm.quotes.push.apply(vm.quotes, quotes);
                            $scope.$emit("curation-controller.counters.guest-quotes", {total: vm.getTotal(), isRecentOnly: vm.isRecentOnly});
                            return quotes;
                        })
                        .then(function (advocates) {
                            return vm.getCurationChunkFromQuotes(advocates);
                        });
                };

                curationModal.on("curated", function (guestUUID) {
                    var quotes = _.filter(vm.quotes, function (quote) {
                        return quote.promoter.guestUUID == guestUUID;
                    });
                    _.each(quotes, function (quote) {
                        quote.isCurated = true;
                    });
                    if(quotes.length > 0) {
                        clearCurated();
                        attemptToGetOlderSubmissions();
                    }
                    $scope.$emit("curation-controller.counters.guest-quotes", {total: vm.getTotal(), isRecentOnly: vm.isRecentOnly});
                });

                $scope.$on("bulk-curation.start", function (evnt, args) {
                    if(!args.hasQuoteOnly) return;
                    vm.curateBulkFromEmailLink(args.advocateUUID);
                });


                /**
                 * Returns total number of advocates
                 * @returns {*}
                 */
                vm.getTotal = function () {
                    if(vm.quotes.length > 0) {
                        var curated = _.filter(vm.quotes, function(quote){ return quote.isCurated;}).length;
                        return vm.quotes[0].totalCount - curated;
                    }
                    return 0;
                };

                vm.loadOlderSubmissions = function () {
                    vm.scrollLoadsResetCounter = 0;
                    vm.quotes = [];
                    vm.filter = angular.copy(vm.filters[1]);
                    return vm.load(angular.extend({}, vm.filter.query))
                        .then(function (quotes) {
                            return angular.isArray(quotes) ? quotes : [];
                        });
                };

                vm.showOlderSubmissions = function () {
                    vm.quotes = vm.olderQuotes;
                    delete vm.olderQuotes;
                    vm.isRecentOnly = false;
                    $scope.$emit("curation-controller.counters.guest-quotes", {total: vm.getTotal(), isRecentOnly: vm.isRecentOnly});
                };


                vm.changeFilter(vm.filters[0])
                    .then(function (stories) {
                        if (stories.length === 0 && vm.isCuration) {
                            vm.loadOlderSubmissions()
                                .then(function (olderQuotes) {
                                    vm.olderQuotes = olderQuotes;
                                    vm.hasOlderQuotes = olderQuotes.length > 0;
                                })
                        }
                    });


                vm.getCurationChunkFromQuotes = function(quotes) {
                    return _.map(quotes, function (quote) {
                        return quote.promoter.guestUUID
                    });
                }

                function clearCurated(){
                    if(!vm.hasMoreData) {
                        var hasAnythingElseToCurate = _.some(vm.quotes, function (quote) {
                            return !quote.isCurated;
                        });
                        if(!hasAnythingElseToCurate){
                            vm.quotes.splice(0, vm.quotes.length);
                        }
                    }
                }

                function attemptToGetOlderSubmissions() {
                    if(vm.isRecentOnly && vm.quotes.length == 0) {
                        vm.loadOlderSubmissions()
                            .then(function (olderQuotes) {
                                vm.olderQuotes = olderQuotes;
                                vm.hasOlderQuotes = olderQuotes.length > 0;
                            })
                    } else if(!vm.isRecentOnly && vm.quotes.length == 0) {
                        vm.hasOlderQuotes = false;
                    }
                }


            }
        ]);
