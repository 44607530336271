import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'seasonRange'
})
export class SeasonRangePipe implements PipeTransform {
    constructor(
    ) {}

    transform(date): string {
        const month = date.month();
        const year = date.year();
        let season = '';

        switch(month) {
            case 11:
            case 0:
            case 1:
                const rangeYear = year == 11 ? `${year} - ${year + 1}` : `${year - 1} - ${year}`;
                season = `Winter ${rangeYear}`;
                break;
            case 2:
            case 3:
            case 4:
                season = `Spring ${year}`;
                break;
            case 5:
            case 6:
            case 7:
                season = `Summer ${year}`;
                break;
            case 8:
            case 9: 
            case 10:
                season = `Fall ${year}`;
                break;
        }
        
        return season;
    }
}
