angular.module('flipto.components.common.tasks')
    .config(['$provide', '$compileProvider',
        function ($provide, $compileProvider) {
            $compileProvider.directive('ftShowWhileTaskPending', ['ftTasks', function (ftTasks) {

                return function (scope, elem, attrs) {

                    var taskScope = {};
                    taskScope.taskName = attrs.ftShowWhileTaskPending;

                    attrs.$observe('ftShowWhileTaskPending', function (newTaskName) {
                        taskScope.taskName = newTaskName;
                    });

                    scope.$watch(function () {
                        return ftTasks.isPending(taskScope.taskName);
                    }, function (isPending) {
                        elem[!!isPending ? 'removeClass' : 'addClass']('ng-hide');
                    });
                };

            }]);
        }
    ]);