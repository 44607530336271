import { Action, createReducer, on } from '@ngrx/store';
import { DiscoveryInjectableComponentState } from './discovery-injectable-component.state';
import * as fromActions from './discovery-injectable-component.actions';
import { DiscoveryComponentType } from '../../enums';


const initialState: DiscoveryInjectableComponentState = {
    isLoading: false,
    error: null,
    createSuccess: false,
    bookingButtonSettings: [],
    bookingBarSettings: [],
    discoveryBarSettings: [],
    settingsPendingCommit: null,
    commitSuccess: false,
    selectedBookingBarComponentUuid: null,
    selectedBookingButtonComponentUuid: null,
    selectedDiscoveryBarComponentUuid: null,
};

// @ts-ignore
// @ts-ignore
const reducer = createReducer(initialState,
    on(fromActions.selectDiscoveryInjectableComponentSettings, (state, { componentUuid, componentType }) => {
        return {
            ...state,
            selectedComponentType: componentType,
            selectedBookingBarComponentUuid: componentType === DiscoveryComponentType.BookingBar
                ? componentUuid
                : state.selectedBookingBarComponentUuid,
            selectedBookingButtonComponentUuid: componentType === DiscoveryComponentType.BookingButton
                ? componentUuid
                : state.selectedBookingButtonComponentUuid
        };
    }),
    on(fromActions.loadDiscoveryInjectableComponentSettings, (state) => {
        return {
            ...state,
            isLoading: true,
            createSuccess: false,
            error: null,
            bookingButtonSettings: [],
            bookingBarSettings: [],
            settingsPendingCommit: null,
            commitSuccess: false,
            selectedBookingBarComponentUuid: null,
            selectedBookingButtonComponentUuid: null
        };
    }),
    on(fromActions.loadDiscoveryInjectableComponentSettingsSuccess, (state, {
        injectableComponentSettings,
        componentType
    }) => {
        return {
            ...state,
            isLoading: false,
            createSuccess: false,
            error: null,
            bookingButtonSettings: componentType === DiscoveryComponentType.BookingButton
                ? injectableComponentSettings
                : state.bookingButtonSettings,
            bookingBarSettings: componentType === DiscoveryComponentType.BookingBar
                ? injectableComponentSettings
                : state.bookingBarSettings,
            discoveryBarSettings: componentType === DiscoveryComponentType.DiscoveryBar
                ? injectableComponentSettings
                : state.discoveryBarSettings,
            selectedBookingBarComponentUuid: componentType === DiscoveryComponentType.BookingBar
                ? injectableComponentSettings[0]?.componentUuid
                : state.selectedBookingBarComponentUuid,
            selectedBookingButtonComponentUuid: componentType === DiscoveryComponentType.BookingButton
                ? injectableComponentSettings[0]?.componentUuid
                : state.selectedBookingButtonComponentUuid,
            selectedDiscoveryBarComponentUuid: componentType === DiscoveryComponentType.DiscoveryBar
                ? injectableComponentSettings[0]?.componentUuid
                : state.selectedDiscoveryBarComponentUuid
        };
    }),
    on(fromActions.loadDiscoveryInjectableComponentSettingsFailure, (state, { error }) => {
        return {
            ...state,
            isLoading: false,
            createSuccess: false,
            error
        };
    }),
    on(fromActions.commitDiscoveryInjectableComponentSettings, (state, { settings }) => {
        return {
            ...state,
            isLoading: true,
            commitSuccess: false,
            createSuccess: false,
            settingsPendingCommit: settings
        };
    }),
    on(fromActions.commitDiscoveryInjectableComponentSettingsSuccess, (state, { settings, componentType }) => {

        clean(settings);
        for (const messaging of settings?.messaging) {
            clean(messaging);
        }

        let updatedSettings;
        switch (componentType) {
            case DiscoveryComponentType.BookingBar: {
                let selectedSettings = state.bookingBarSettings.find(b => b.componentUuid === settings.componentUuid);
                selectedSettings = {
                    ...selectedSettings,
                    ...settings
                };
                updatedSettings = state.bookingBarSettings.filter(b => b.componentUuid !== settings.componentUuid);
                updatedSettings.push(selectedSettings);
                break;
            }
            case DiscoveryComponentType.BookingButton: {
                let selectedSettings = state.bookingButtonSettings.find(b => b.componentUuid === settings.componentUuid);
                selectedSettings = {
                    ...selectedSettings,
                    ...settings
                };
                updatedSettings = state.bookingButtonSettings.filter(b => b.componentUuid !== settings.componentUuid);
                updatedSettings.push(selectedSettings);
                break;
            }
            case DiscoveryComponentType.DiscoveryBar: {
                let selectedSettings = state.bookingButtonSettings.find(b => b.componentUuid === settings.componentUuid);
                selectedSettings = {
                    ...selectedSettings,
                    ...settings
                };
                updatedSettings = state.discoveryBarSettings.filter(b => b.componentUuid !== settings.componentUuid);
                updatedSettings.push(selectedSettings);
                break;
            }
        }

        if (!updatedSettings || updatedSettings.length === 0) {
            return state;
        }

        return {
            ...state,
            isLoading: false,
            commitSuccess: true,
            createSuccess: false,
            error: null,
            bookingBarSettings: componentType === DiscoveryComponentType.BookingBar
                ? updatedSettings : state.bookingBarSettings,
            bookingButtonSettings: componentType === DiscoveryComponentType.BookingButton
                ? updatedSettings : state.bookingButtonSettings,
            discoveryBarSettings: componentType === DiscoveryComponentType.DiscoveryBar
                ? updatedSettings : state.discoveryBarSettings,
            selectedBookingBarComponentUuid: componentType === DiscoveryComponentType.BookingBar
                ? settings?.componentUuid
                : state.selectedBookingBarComponentUuid,
            selectedBookingButtonComponentUuid: componentType === DiscoveryComponentType.BookingButton
                ? settings?.componentUuid
                : state.selectedBookingButtonComponentUuid,
            selectedDiscoveryBarComponentUuid: componentType === DiscoveryComponentType.DiscoveryBar
                ? settings?.componentUuid
                : state.selectedDiscoveryBarComponentUuid
        };
    }),
    on(fromActions.commitDiscoveryInjectableComponentSettingsFailure, (state, { error }) => {
        return {
            ...state,
            isLoading: false,
            commitSuccess: false,
            createSuccess: false,
            settingsPendingCommit: null,
            error
        };
    }),
    on(fromActions.createDiscoveryInjectableComponentSettings, (state, { settings }) => {
        return {
            ...state,
            isLoading: true,
            commitSuccess: false,
            createSuccess: false,
            settingsPendingCommit: settings
        };
    }),
    //@ts-ignore
    on(fromActions.createDiscoveryInjectableComponentSettingsSuccess, (state, { settings, componentType }) => {

        clean(settings);
        for (const messaging of settings?.messaging) {
            clean(messaging);
        }

        const bookingBarSettings = [...state.bookingBarSettings, settings];
        const bookingButtonSettings = [...state.bookingButtonSettings, settings];
        const discoveryBarSettings = [...state.discoveryBarSettings, settings];

        return {
            ...state,
            isLoading: false,
            commitSuccess: false,
            createSuccess: true,
            error: null,
            bookingBarSettings: componentType === DiscoveryComponentType.BookingBar
                ? bookingBarSettings : state.bookingBarSettings,
            bookingButtonSettings: componentType === DiscoveryComponentType.BookingButton
                ? bookingButtonSettings : state.bookingButtonSettings,
            discoveryBarSettings: componentType === DiscoveryComponentType.DiscoveryBar
                ? discoveryBarSettings : state.discoveryBarSettings,
            selectedBookingBarComponentUuid: componentType === DiscoveryComponentType.BookingBar
                ? settings?.componentUuid
                : state.selectedBookingBarComponentUuid,
            selectedBookingButtonComponentUuid: componentType === DiscoveryComponentType.BookingButton
                ? settings?.componentUuid
                : state.selectedBookingButtonComponentUuid,
            selectedDiscoveryBarComponentUuid: componentType === DiscoveryComponentType.DiscoveryBar
                ? settings?.componentUuid
                : state.selectedDiscoveryBarComponentUuid
        };
    }),
    on(fromActions.createDiscoveryInjectableComponentSettingsFailure, (state, { error }) => {
        return {
            ...state,
            isLoading: false,
            commitSuccess: false,
            createSuccess: false,
            settingsPendingCommit: null,
            error
        };
    })
);

export function fromDiscoveryInjectableComponentReducer(state: DiscoveryInjectableComponentState | undefined, action: Action) {
    return reducer(state, action);
}

function clean(obj: Object) {
    for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
        }
    }
};
