/**
 * Created by roman.kupin on 4/26/2018.
 */



angular.module('flipto.components.reports')
    .component("ftReportPanel", {
        templateUrl: "/app/components/reports/reportPanel.component.html",
        bindings: {
            'report': '<',
            'group': '<',
            'onFilterChanged': '&'
        },
        controller: function ReportPanelComponentController() {
            var vm = this;
            vm.changeActiveFiter = function (filter) {
                vm.report.filters.forEach(function (filter) {
                    filter.active = false;
                });
                filter.active = true;
                vm.onFilterChanged(vm.group, vm.report);
            };
        },
        controllerAs: "vm"
    });