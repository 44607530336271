
angular
    .module('flipto.accountv1.SharedFeatures')
    .controller('flipto.account.sections.settings.LanguagesController', [
        'SettingsLanguages', 'Languages', '_', '$log', 'SpacesService',
        function (SettingsLanguages, Languages, _, $log, spacesService) {
            'use strict';

            var languages,
                vm = this;
            vm.newLanguages = [];

            SettingsLanguages.get({ propertyId: spacesService.getPropertyUuid() }).then(function (data) {
                languages = data;
                vm.languages = languages && languages.languages && languages.languages.item;
                vm.languages.forEach(function (language) {
                    language.isActive = language.isActive == 1;
                });

                Languages.get().then(function (languages) {
                    var propertyLanguages = [];

                    _.remove(languages.item, function (language) {
                        return _.find(vm.languages, { code: language.code })
                    });
                    _.remove(languages.item, function (language) {
                        return _.find(vm.newLanguages, { code: language.code })
                    });

                    _.forEach(languages.item, function (language) {
                        propertyLanguages.push({
                            languageCode: language.code,
                            name: language.name,
                            isActive: false
                        })
                    });
                    vm.availableLanguages = angular.copy(propertyLanguages);
                    vm.propertyLanguages = angular.copy(propertyLanguages);
                    vm.newLanguages.push({});
                });

            })

            vm.onStateChanged = function (language) {
                var data = { languages: [] };
                data.languages.push({ languageCode: language.code || language.languageCode, isActive: language.isActive });
                commitChanges(data);
            };

            vm.languageChanged = function (from, to) {
                if (angular.isDefined(from) && angular.isDefined(from.languageCode)) {
                    vm.propertyLanguages.push(from);
                }
                _.remove(vm.propertyLanguages, function (lng) {
                    return lng.languageCode == to.languageCode;
                });
            };
            vm.addLanguage = function () {
                var hasEmptySelections = !!_.find(vm.newLanguages, function (language) {
                    return angular.isDefined(language.languageCode) === false;
                });
                var hasMoreLanguages = vm.propertyLanguages.length > 0;
                if (hasEmptySelections === false &&
                    hasMoreLanguages === true) {
                    vm.newLanguages.push({});
                }
            };

            vm.cancel = function () {
                vm.propertyLanguages = _.union(vm.propertyLanguages, vm.newLanguages);
                vm.newLanguages = [
                    {}
                ];
            };

            vm.commit = function () {
                _.remove(vm.newLanguages, function (lng) {
                    return angular.isDefined(lng.languageCode) === false;
                });
                if (vm.newLanguages.length === 0) {
                    vm.newLanguages = [
                        {}
                    ];
                    return;
                }
                commitChanges({ newLanguages: vm.newLanguages }).then(function () {
                    angular.forEach(vm.newLanguages, function (language) {
                        var languageInfo = _.find(vm.availableLanguages, { languageCode: language.languageCode });
                        vm.languages.push(languageInfo);
                        _.remove(vm.propertyLanguages, function (lng) {
                            return lng.languageCode == language.languageCode;
                        });
                    });
                    vm.newLanguages = [
                        {}
                    ];
                });
            };

            function commitChanges(data) {
                return SettingsLanguages.put({ propertyId: spacesService.getPropertyUuid() }, data);
            }

        }
    ]);