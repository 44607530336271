import { Component, Input, ElementRef, HostListener, OnInit } from '@angular/core';

@Component({
    selector: 'ft-password-toggle',
    templateUrl: './password-toggle.component.html',
})
export class PasswordToggleComponent {
    @Input() inputRef: ElementRef;
    types: string[] = ['password', 'text'];
    visible = false;

    @HostListener("click")
    onClick() {
        (this.inputRef as any).type = this.types[
            Math.abs(Number(this.visible) - 1)
        ];
        this.visible = !this.visible;
    }
}
