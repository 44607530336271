/**
 * Created by roman.kupin on 9/27/2017.
 */


const isCryptoSupported = !!window.crypto;
const randomNumbers = new Uint32Array(10);
let lastAccessedIndex = -1;

function getRandomNumberCrypto() {
    if (lastAccessedIndex === -1 || lastAccessedIndex === 9) {
        window.crypto.getRandomValues(randomNumbers);
        lastAccessedIndex = -1;
        return randomNumbers[++lastAccessedIndex];
    }
    else {
        return randomNumbers[++lastAccessedIndex];
    }
}

function getRandomNumber() {
    return isCryptoSupported ? getRandomNumberCrypto() : Math.round(Math.random() * 10000000000);
}

export default function idGen(seed) {
    // take first 3 chars at even index from start
    let key = seed.split("").reduce((acc = "", val, idx) => {
        return idx % 2 == 0 && acc.length < 3 && /[\w]/gi.test(val) ? acc + val.toUpperCase() : acc;
    });
    // take first 2 chars at odd index from end
    key += seed.split("").reverse().reduce((acc = "", val, idx) => {
        return idx % 2 == 1 && acc.length < 5 && /[\w]/gi.test(val) ? acc + val.toUpperCase() : acc;
    });
    return `${key}.${getRandomNumber()}`;
}


export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
