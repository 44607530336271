import { Directive, Input, HostListener, ElementRef, Renderer2 } from '@angular/core';


@Directive({
    selector: '[ftClickClass]'
})
export class ClickClassDirective {

    @Input() selector: string;
    @Input() toggleClass: string;
    @Input() addClass: string;
    @Input() removeClass: string;

    constructor(
        private elem: ElementRef,
        private renderer: Renderer2) {
    }

    @HostListener('document:click', ['$event.target'])
    handler(target: Element) {
        const targetEl: Element = !!this.selector ? document.querySelector(this.selector) : this.elem.nativeElement;
        const isElemOrItsChild = target === this.elem.nativeElement || targetEl.contains(target);
        if (isElemOrItsChild) {
            this.renderer.addClass(targetEl, this.addClass);
            this.renderer.removeClass(targetEl, this.removeClass);
            if (targetEl.classList.contains(this.toggleClass)) {
                this.renderer.removeClass(targetEl, this.toggleClass);
            } else {
                this.renderer.addClass(targetEl, this.toggleClass);
            }
        } else {
            targetEl.classList.remove(this.removeClass);
            targetEl.classList.remove(this.toggleClass);
        }
    }
}
