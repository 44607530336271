/**
 * Created by Roman on 2015-07-24.
 */

angular.module('flipto.components.common.spinner', [])
    .directive('ftSpinner', [function () {
        return {
            restrict: 'E',
            template: '<span class="ft2-spinner"></span>'
        };
    }]);
