/**
 * Created by Roman on 2015-05-29.
 */

angular
	.module('flipto.core.api.general', ['flipto.core.parse', 'flipto.core.lodash'])
	.config([
		'$provide',
		function($provide) {
			$provide.factory('General', [
				'urlFormatter',
				'$http',
				'parse',
				'_',
				'$q',
				'$timeout',
				function(urlFormatter, $http, parse, _, $q, $timeout) {
					/**
					 * General service
					 * @constructor
					 */
					function General() {
						/*
						 * Returns list of countries
						 * @returns {}
						 */
						this.getCountries = function() {
							return $http.get('/api/countries').then(function(response) {
								return response.data;
							});
						};

						/*
						 * Returns list of admins
						 * @returns {}
						 */
						this.getAdmins = function() {
							return $http.get('/api/admins').then(function(response) {
								return response.data;
							});
						};

						/*
						 * Returns list of users
						 * @returns {}
						 */
						this.getUsers = function(propertyUuid) {
							return $http
								.get(
									urlFormatter.format('/api/:propertyUuid/properties/:propertyUuid/users', {
										propertyUuid: propertyUuid
									})
								)
								.then(function(response) {
									return response.data;
								});
						};
					}

					return new General();
				}
			]);
		}
	]);
