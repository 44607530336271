/**
 * Created by roman.kupin on 10/3/2017.
 */
import { combineReducers } from 'redux';


export function createViewsReducer(features?) {
    return combineReducers(
        features
            .map(feature => {
                return {...feature.REDUCER};
            })
            .reduce((combineReducer, featureReducer) => {
                return Object.assign(combineReducer, featureReducer);
            }, {})
    );
}
