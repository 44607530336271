/**
 * Created by ostapenko.r on 02/26/015.
 */

angular
    .module("flipto.accountv1.SharedFeatures")
    .constant("postStayVersion", {
        "Version0QuoteOnly": 0,
        "Version1QuotePhoto": 1,
        "Version2QuotePhoto": 2
    });