<button (click)="onClose()" class="ft-btn-close"></button>
<div class="ft2-page-overlay d-flex">
    <div class="ft2-container -full">
        <div class="row full-height">
            <div class="ft2-audience-onboard-bg col-3 d-md-none">
            </div>
            <div class="col-8 col-md-12 py-9 py-md-7 py-xs-4">
                <div class="ft2-container -narrow ml-0 px-0">
                    <div *ngIf="!isUploadComplete" class="swiper-container" [swiper] [(index)]="slideIndex">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <div class="text-content px-8 px-lg-4 px-md-0">
                                    <h3>Update any canceled reservations.</h3>
                                    <p>
                                        Keep your platform up-to-date with the latest reservation info. On the next
                                        step, you can upload a file of guests who have canceled their stay dates. We’ll
                                        take travelers who canceled off of journeys that no longer suit them.
                                    </p>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="px-8 px-lg-4 px-md-0">
                                    <div class="text-content">
                                        <h3>Ok, let’s put your audience on the right journey.</h3>
                                    </div>
                                    <ft-dropzone-uploader [multiple]="false" [accept]="'.xls, .xlsx'"
                                        (selected)="files = $event">
                                    </ft-dropzone-uploader>
                                    <div class="ft2-form-error" *ngIf="error">
                                        {{error.message}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-navigation h-between v-center pl-8 pl-lg-4 pl-md-3">
                            <div class="swiper-pagination"></div>
                            <div class="swiper-button-next" [hidden]="totalSlides === slideIndex + 1">
                                <button class="swiper-button-circle">
                                    <mat-icon class="icon-md" svgIcon="arrow-right"></mat-icon>
                                </button>
                            </div>
                            <button class="ft2-btn-dark -outline -large" [ftLoading]="loading$" (click)="submit()"
                                [disabled]="!files.length" *ngIf="totalSlides === slideIndex + 1">
                                Submit
                            </button>
                        </div>
                    </div>
                    <div *ngIf="isUploadComplete" class="ft2-audience-onboard-complete">
                        <div class="text-content px-8 px-lg-4 px-md-0">
                            <h3>Upload complete.</h3>
                            <p>
                                Your audience will begin their journey on your platform right away. You’ll find them in
                                the People area of your account as soon as they start engaging with you.
                            </p>
                        </div>
                        <div class="h-end">
                            <button class="ft2-btn-dark -outline -large" (click)="onClose()">Return to your account
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>