angular.module('flipto.components.common.tasks')
    .config(['$provide', '$compileProvider',
        function ($provide, $compileProvider) {

            $provide.factory('ftTasks', function () {

                /**
                 * Encapsulates tasks manager
                 * @constructor
                 */
                function Tasks() {
                    this.pendingTasks = [];
                }

                /**
                 * Register task as pending
                 * @param name
                 */
                Tasks.prototype.register = function (name) {
                    if (!this.isPending(name)) {
                        this.pendingTasks.push(name);
                    }
                };

                /**
                 * Remove task from pending
                 * @param name
                 */
                Tasks.prototype.finish = function (name) {
                    _.remove(this.pendingTasks, function (task) {
                        return task === name;
                    });
                };

                /**
                 * Returns true if task is not being executed currently
                 * @param name
                 * @returns {*}
                 */
                Tasks.prototype.isPending = function (name) {
                    return _.includes(this.pendingTasks, name);
                };

                return new Tasks();

            });
        }
    ]);