/**
 * Created by Kevin Tiller on 6/7/16
 */



angular.module('flipto.components.common.faceHighlighter', [])
    .directive('ftFaceHighlighter', ['parse', function (parse) {
        return {
            restrict: 'A',
            scope: {
                faceArray: '=',
                picHeight: '=',
                picWidth: '=',
                faceShow: "<"
            },
            link: function (scope, element, attrs) {

                scope.$watch("faceArray", function () {
                    if (scope.faceArray && scope.faceArray.length > 0 && scope.picHeight && scope.picHeight > 0 && scope.picWidth && scope.picWidth > 0) {
                        var originalAspectRatio = scope.picWidth / scope.picHeight;  //  1920/1080 =  16/9
                        var maxDimension = 320;
                        var realWidth = maxDimension;
                        var realHeight = maxDimension;

                        // Adjust for aspect ratio
                        if (scope.picWidth > scope.picHeight) {
                            realHeight = maxDimension / originalAspectRatio;
                        } else {
                            realWidth = maxDimension * originalAspectRatio;
                        }

                        var faceBoxes = [];
                        for (var i = 0; i < scope.faceArray.length; i++) {
                            var face = scope.faceArray[i];
                            // Map the face rectangles onto the new size object
                            var left = Math.round(face.left * realWidth / scope.picWidth) + ((maxDimension - realWidth) / 2);
                            var top = Math.round(face.top * realHeight / scope.picHeight); // not necessary to adjust by max-real / 2 because the wrapper will only be realheight
                            var width = Math.round(face.width * realWidth / scope.picWidth);
                            var height = Math.round(face.height * realHeight / scope.picHeight);
                            var newBox = angular.element('<div></div>');
                            newBox.css({ top: top, left: left, width: width, height: height });
                            newBox.addClass('faceBox');
                            newBox.append("<div class='faceNumber'>" + (i + 1) + "</div>");
                            element.after(newBox);
                            faceBoxes.push(newBox);
                        }
                        
                        scope.$watch("faceShow", function (isShow) {
                            if (typeof isShow !== "undefined") {
                                toggleFaceBoxes(isShow);
                            }
                        }, true);

                        function toggleFaceBoxes(isShow) {
                            for (var x = 0; x < faceBoxes.length; x++) {
                                if (isShow) {
                                    faceBoxes[x].removeClass('ng-hide');
                                } else {
                                    faceBoxes[x].addClass('ng-hide');
                                }
                            }
                        }
                    }
                });


            }
        }
    }]);