export interface CampaignGoals {
    clicks: string;
    id: string;
    incentiveUUID: string;
    isActive: string;
    languages: any;
    signups: string;
    type: string;
}

export enum CampaignGoalsTypes {
    SpreadTheWord = 'Spread the word',
    GetSocialConnections = 'Get # social connections to sign up',
    GetUniqueVisitors = 'Get # unique visitors to the site',
    SignupAndGet = 'Sign up and get',
    SubmitQuoteAndGet = 'Submit quote and get',
    ShareAndGet = 'Share and get',
    SubmitPhotoAndGet = 'Submit photo and get',
    PhotoContestWinner = 'Story contest winner',
    AttendeeSignup = 'Sign up and get'
}
