import { CertificateEmailSettings } from './../../models/certificate-email';
import { createAction, props } from '@ngrx/store';
import { FollowupEmailSettings, FollowupEmailStylingSettings } from '../../models/followup-email';

export const loadFollowupEmail = createAction('[Follow-up Email] load',
props<{
    componentGroupUuid: string,
    propertyId: string,
    typeRequest: any,
    state: any
}>());

export const loadFollowupEmailSuccess = createAction('[Follow-up Email] loadSuccess', props<{ data: FollowupEmailSettings }>());
export const loadFollowupEmailFail = createAction('[Follow-up Email] loadFail', props<{ error: any }>());

// save changes toogle
export const commitFollowupEmail = createAction('[Follow-up Email] commit',
    props<{
        componentGroupUuid: string,
        propertyId: string,
        isActive: boolean
    }>());
export const commitFollowupEmailSuccess = createAction('[Follow-up Email] commitSuccess', props<{ data: FollowupEmailSettings }>());
export const commitFollowupEmailFail = createAction('[Follow-up Email] commitFail', props<{ error: any }>());

//styling
export const loadStylingFollowupEmail = createAction('[Follow-up Email] load styling',
props<{
    componentGroupUuid: string,
    propertyId: string,
    typeRequest: any,
    state: any
}>());

export const loadStylingFollowupEmailSuccess = createAction('[Follow-up Email] styling loadSuccess', props<{ data: FollowupEmailStylingSettings }>());
export const loadStylingFollowupEmailFail = createAction('[Follow-up Email] styling loadFail', props<{ error: any }>());

// save data campaigns
export const saveFollowupEmail = createAction('[Follow-up Email] save data',
props<{
    componentGroupUuid: string,
    propertyId: string,
    typeRequest: string,
    changes: FollowupEmailSettings,
    tab: string
}>());

export const saveFollowupEmailSuccess = createAction('[Follow-up Email] save loadSuccess');
export const saveFollowupEmailFail = createAction('[Follow-up Email] save loadFail', props<{ error: any }>());
