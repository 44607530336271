/**
 * Created by ostapenko.r on 2/19/2015.
 */
angular.module('flipto.components.common.listEditor')
    .directive('ftAddToListBySubmit', [function () {
        return {
            restrict: 'A',
            require: ['^ftListEditor', '^form', 'ngModel'],
            link: function (scope, element, attrs, controllersArr) {
                var ftListEditor = controllersArr[0],
                    form = controllersArr[1],
                    ngModel = controllersArr[2],
                    isUseEvalToGetValue = !!attrs.ftAddToListByEnter;

                scope.$watch(function () {
                    return form.$submitted;
                }, function (value) {
                    var value = isUseEvalToGetValue ? scope.$eval(attrs.ftAddToListByEnter) : ngModel.$modelValue;
                    if (!!value) {
                        ftListEditor.addItem(value);
                    }
                });
            }
        };
    }]);