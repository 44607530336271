import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ftFilePath'
})
export class FilePathPipe implements PipeTransform {
    rootPath = '/dist/v2-hybrid/';

    constructor() {
    }

    transform(filePath): string {
        return `${this.rootPath}${filePath}`;
    }
}
