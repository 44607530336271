/**
 * Created by roman.kupin on 11/17/2017.
 */

import * as TableChartComponent from "./table-chart/tableChart.component";
import * as BarChartComponent from "./bar-chart/barChart.component";
import * as GeoChartComponent from "./geo-chart/geoChart.component";

declare let angular;
export const GOOGLE_CHARTS_MODULE = angular.module("AccountApp.v2.Components.GoogleCharts", []);

export class ComponentConfig {

    static $inject = ["$compileProvider"];

    constructor($compileProvider) {
        const components = [TableChartComponent, BarChartComponent, GeoChartComponent];
        components.forEach(component => {
            $compileProvider.component(component.NAME, component.OPTIONS);
        });
    }
}

GOOGLE_CHARTS_MODULE.config(ComponentConfig);