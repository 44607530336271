<ft-modal-wrapper>
  <ft-touchpoint-header
    [name]="options.touchpointTitle"
    [canActivate]="true"
    [isActive]="(selectedBannerEmailComponent$ | async)?.isActive == 1"
    (activeToggled)="commit($event)"
    >
  </ft-touchpoint-header>
  <mat-tab-group
    class="mat-tab-group-nav"
    mat-align-tabs="center"
    animationDuration="0ms"
    [(selectedIndex)]="selectedIndex">
    <mat-tab label="messaging">
      <ng-template matTabContent>
        <ft-snapshot-form
          *ngIf="selectedBannerEmailComponent$ | async | clone as data"
          [loading$]="[selectedBannerEmailLoading$]"
          [commitSuccess$]="[commitSuccess$]"
          (commit)="onFormCommit($event, data)">
          <section class="ft2-section">
            <div class="ft2-preview">
              <div class="preview-chrome">
                <span>Inbox</span>
              </div>
              <div class="preview-content">
                <form ngForm>
                  <div class="ft2-preview-content-container px-2 py-2">
                    <ft-input-editor
                      title="Banner heading"
                      [(ngModel)]="data.languages.item[0].data.header"
                      required
                      name="header">
                      <span class="text-dark h5" [innerHTML]="data.languages.item[0].data.header"></span>
                    </ft-input-editor>
                    <ft-html-editor
                      title="Banner message"
                      name="text"
                      [(ngModel)]="data.languages?.item[0].data.text">
                      <div class="text-dark base-text" [innerHTML]="data.languages?.item[0].data.text"></div>
                    </ft-html-editor>
                    <div *ngIf="options.isContest" class="ft2-preview-banner mt-4">
                      <div class="h5 mb-1">Contest name</div>
                      Contest dates
                      <br />
                      <hr />
                      Prizes
                    </div>
                    <ft-input-editor
                      title="Banner button"
                      [(ngModel)]="data.languages.item[0].data.buttonText"
                      required
                      name="buttonText">
                        <button class="ft2-btn mt-4 mb-2 -large">
                          {{data.languages.item[0].data.buttonText}}
                        </button>
                    </ft-input-editor>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </ft-snapshot-form>
      </ng-template>
    </mat-tab>
    <mat-tab label="styling">
      <ng-template matTabContent>
        <ft-snapshot-form
          *ngIf="stylingBannerEmailComponent$ | async | clone as styleData"
          [loading$]="[selectedBannerEmailLoading$]"
          [commitSuccess$]="[commitSuccess$]"
          (commit)="onFormCommit($event, styleData)">
          <section class="ft2-section">
            <div class="ft2-container">
              <div class="row">
                <div class="col-12">
                  <form ngForm>
                    <ft-custom-editor
                      title="Banner size">
                        <ng-template #summary>
                          <p>
                            Set the width of the banner (measured in px). The height will adjust to fit the content. 
                            You can also add a mobile banner if your email has a responsive layout for mobile devices.
                          </p>
                        </ng-template>
                        <ft-edit-panel label="Banner size">
                          <span>{{ styleData.desktopWidth ? styleData.desktopWidth + 'px width for desktop' : 'Click to edit' }}</span>
                          <span *ngIf="styleData.mobileWidth">
                            {{ styleData.mobileWidth ? styleData.mobileWidth + 'px width for mobile' : 'Click to edit' }}
                          </span>
                        </ft-edit-panel>
                      <div content>
                        <div class="ft2-form-group">
                          <label>WIDTH FOR DESKTOP</label>
                          <input class="ft2-input"
                            type="text"
                            maxlength="100"
                            [(ngModel)]="styleData.desktopWidth"
                            name="desktopWidth"
                            required>
                        </div>
                        <div *ngIf="styleData.mobileWidth" class="ft2-form-group">
                          <label>WIDTH FOR Mobile</label>
                          <input class="ft2-input"
                            type="text"
                            maxlength="100"
                            [(ngModel)]="styleData.mobileWidth"
                            name="mobileWidth"
                            required>
                        </div>
                        <button
                          *ngIf="!styleData.mobileWidth"
                          class="ft2-banner-email-button ft2-btn-light -outline -large"
                          (click)="styleData.mobileWidth = 320">
                          Add a mobile banner
                        </button>
                      </div>
                    </ft-custom-editor>
                    <!-- [uploadFromUrl]="uploadImageUrl + '?type=3'"
                              [uploadUrl]="uploadImageFromUrl  + '?type=3'"
                              [getUrl]="getImagesUrl + '?type=3'" -->
                    <ft-image-library-editor
                        *ngIf="!options.isContest"
                        title="Banner image"
                        name="BackgroundImageUrl"
                        [ngModel]="getImageBackground(styleData)"
                        >
                      <ft-edit-panel [label]="'Photo 1'">
                        <ft-image-preview [src]="getImageBackground(styleData)"
                            mode="background"
                            width="300px"
                            height="150px">
                        </ft-image-preview>
                      </ft-edit-panel>
                    </ft-image-library-editor>
                    <ft-custom-editor
                      *ngIf="!options.isContest"
                      title="Confirmation email banner preview">
                        <ng-template #summary>
                          <p>
                            Previews may appear smaller than actual size. Click Refresh if the preview is not up to date with your latest changes.
                          </p>
                        </ng-template>
                        <ft-edit-panel label="Confirmation email banner preview">
                        </ft-edit-panel>
                      <div content>
                        
                      </div>
                    </ft-custom-editor>
                    <ft-code-editor
                      title="CSS"
                      [(ngModel)]="styleData.touchpointCSS"
                      [language]="'css'"
                      name="touchpointCSS">
                        <ft-edit-panel label="CSS">
                          <p>{{styleData.touchpointCSS ? styleData.touchpointCSS : 'Click to edit'}}</p>
                        </ft-edit-panel>
                    </ft-code-editor>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </ft-snapshot-form>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</ft-modal-wrapper>
