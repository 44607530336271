/**
 * Created by roman.kupin on 10/07/2014.
 */

angular
    .module("flipto.components.touchpoints")
    .directive("ftTouchpointInactive",
    [
        function () {

            return {
                restrict: "A",
                require: "^ftTouchpointContext",
                link: function (scope, element, attrs, touchpointContext) {

                    scope.$watch(
                        touchpointContext.getStatus,
                        function (isActive) {
                            angular.element(element)[!isActive ? "removeClass" : "addClass"]("ng-hide");
                        }
                    )
                }
            };
        }
    ]);