/**
 * Created by roman.kupin on 2/15/2017.
 */



angular.module('flipto.accountv1.SharedFeatures')
    .controller('flipto.account.sections.stories.CurationController',
        [
            'tabs', '$scope', 'advocateUUID', 'hasQuoteOnly', "Property", 'SpacesService',
            function (tabs, $scope, advocateUUID, hasQuoteOnly, Property, spacesService) {

                var vm = this;
                vm.tabs = tabs;
                vm.contestSubmissionsReady = false;
                vm.guestQuotesReady = false;
                vm.isProcessingRequest = true;

                vm.isCollectingPhotos = false;
                vm.isCollectingQuotes = false;

                Property.getAdvocacyGuestJourney(spacesService.getPropertyUuid())
                    .then(function (journeys) {
                        vm.isCollectingQuotes = journeys.poststayAdvocacy.isActive;
                        vm.isCollectingPhotos = journeys.photoAdvocacy.isActive;
                    });


                $scope.$on("curation-controller.counters.contest-submissions", function (evnt, args) {
                    vm.contestSubmissions = args;
                    vm.contestSubmissionsReady = true;
                });
                $scope.$on("curation-controller.counters.guest-quotes", function (evnt, args) {
                    vm.guestQuotes = args;
                    vm.guestQuotesReady = true;
                });

                $scope.$watchGroup(["vm.contestSubmissionsReady", "vm.guestQuotesReady"], function (newVal) {
                    vm.isProcessingRequest = !newVal[0] || !newVal[1];
                    if (!vm.isProcessingRequest && advocateUUID != null) {
                        $scope.$broadcast("bulk-curation.start", {
                            advocateUUID: advocateUUID,
                            hasQuoteOnly: hasQuoteOnly
                        })
                    }
                });
            }
        ]);
