import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ChecklistRule } from '../../../validation-checklist';

@Component({
    selector: 'ft-pending-changes',
    templateUrl: './pending-changes.component.html',
    styleUrls: ['./pending-changes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PendingChangesComponent implements OnChanges {
    @Input() title: string;
    @Input() titleVisible = false;
    @Input() message: string;
    @Input() totalChanges: number;
    @Input() hasChanges: boolean;
    @Input() hasDraftChanges: boolean;
    @Input() showCounter = true;
    @Input() draftSaveButtonLabel = 'Save draft';
    @Input() alwaysVisible = false;
    @Input() cancelVisible = true;
    @Input() publishVisible = true;
    @Input() draftSaveVisible = false;
    @Input() loading$:
        | Observable<boolean>
        | Array<Observable<boolean>>
        | boolean;
    @Input() canCommit = true;
    @Input() disabled = false;
    @Input() validationRules: ChecklistRule[] = [];
    @Input() cancelConfirmation = false;
    @Output() commit = new EventEmitter();
    @Output() draftCommit = new EventEmitter();
    @Output() cancel = new EventEmitter();
    // Seems like the `ft-validation-checklist` component calls to ngDoCheck are preventing the two-way binding from updating the parent component properly
    // By using BehaviorSubject and Observable we ensure that changes to isValid are managed reactively,
    // and the parent component can easily track and respond to these changes through the BehaviorSubject and Observable mechanisms.
    // This approach prevents infinite loops and ensures efficient state management between components.
    canSaveSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        this.getCanSave()
    );
    canSave$: Observable<boolean> = this.canSaveSubject$.asObservable().pipe(delay(0));

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.disabled || changes.canCommit) {
            this.updateCanSave();
        }
    }

    onIsValidChange() {
        this.updateCanSave();
    }

    updateCanSave() {
        this.canSaveSubject$.next(this.getCanSave());
    }

    getCanSave() {
        return !this.disabled && this.canCommit;
    }
}
