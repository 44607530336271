/**
 * User: Kupin.R
 * Date: 6/2/14
 */



angular
    .module('flipto.components.goals')
    .directive("ftGoalSelector",
    [
        "goalType", "$filter", "$document", "goalDescriptor", "_",
        function (goalType, $filter, $document, goalDescriptor, _) {

            var translate = $filter("translate"),
                filter = $filter("filter"),
                allGoalsVariations = [
                    {name: translate("flipto.components.goals.SpreadTheWord"), identifier: goalType.SpreadTheWord, type: 1},
                    {name: translate("flipto.components.goals.GetSocialConnections"), identifier: goalType.GetSocialConnections, type: 1},
                    {name: translate("flipto.components.goals.GetUniqueVisitors"), identifier: goalType.GetUniqueVisitors, type: 1},
                    {name: translate("flipto.components.goals.SignupAndGet"), identifier: goalType.SignupAndGet, type: 2 },
                    {name: translate("flipto.components.goals.ShareAndGet"), identifier: goalType.ShareAndGet, type: 4},
                    {name: translate("flipto.components.goals.SubmitQuoteAndGet"), identifier: goalType.SubmitQuoteAndGet, type: 3 },
                    {name: translate("flipto.components.goals.PhotoContestWinner"), identifier: goalType.PhotoContestWinner, type: 5 },
                    {name: translate("flipto.components.goals.SubmitPhotoAndGet"), identifier: goalType.SubmitPhotoAndGet, type: 6 },
                    {name: translate("flipto.components.goals.AttendeeSignup"), identifier: goalType.AttendeeSignup, type: 7 }
                ];

            return {
                restrict: "E",
                templateUrl: "/app/components/goals/directives/goalSelector.html",
                scope: {
                    "goal": "=",
                    'types': '='
                },
                link: function (scope, element) {

                    scope.$watch("goal", function (goal) {
                        if (goal) {

                            var availableGoals = _.filter(allGoalsVariations, function (variation) {
                                return _.filter(scope.types, function (type) {
                                    return type == variation.type;
                                }).length > 0;
                            });

                            angular.extend(scope, {
                                model: {
                                    availableGoals: availableGoals,
                                    goalLimits: _.range(1, 21),
                                    descriptor: goalDescriptor(goal)
                                }
                            });
                        }
                    }, true);

                    /**
                     * Set new goal
                     * @param goal
                     */
                    scope.setGoal = function (goal) {
                        scope.goal.type = goal.type;
                        scope.goal.signups = 0;
                        scope.goal.clicks = 0;
                        if (goal.identifier === goalType.GetSocialConnections) {
                            scope.goal.signups = 1;
                        } else if (goal.identifier === goalType.GetUniqueVisitors) {
                            scope.goal.clicks = 1;
                        }
                        scope.model.descriptor.type = goal.identifier;
                        scope.model.descriptor.value = 0;
                    };

                    /**
                     * Set goal limit
                     * @param limit
                     */
                    scope.setLimit = function (limit) {
                        if (scope.model.descriptor.type === goalType.GetUniqueVisitors) {
                            scope.goal.clicks = scope.model.descriptor.value = limit;
                        } else if (scope.model.descriptor.type === goalType.GetSocialConnections) {
                            scope.goal.signups = scope.model.descriptor.value = limit;
                        }
                    };

                    /**
                     * Returns true if goal could have a limit
                     */
                    scope.goalHasLimit = function (goal) {
                        var descriptor = goalDescriptor(goal);
                        return goal.type == 1 && descriptor.type !== goalType.SpreadTheWord;
                    };

                }
            };
        }
    ]);
