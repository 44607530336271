import { Inject, Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { AudienceOnboardComponent } from "../components/audience-onboard/audience-onboard.component";
import { HttpClient } from "@angular/common/http";
import { SpacesService } from "../../../components/spaces/spaces.service";
import { Environment, ENVIRONMENT_TOKEN } from "../../../../core/environment.service";
import { AudienceCancellationsComponent } from '../components/audience-cancellations/audience-cancellations.component';
import { ComponentType } from '@angular/cdk/overlay';

@Injectable({
    providedIn: 'root'
})
export class AudienceOnboardService {

    constructor(private dialog: MatDialog,
                private spacesService: SpacesService,
                @Inject(ENVIRONMENT_TOKEN) private environment: Environment,
                private http: HttpClient) {
    }

    // openDialog<T extends ComponentType<T>>() {
    //     const dialogRef = this.dialog.open(T, {
    //         panelClass: 'ft2',
    //         width: '100%',
    //         backdropClass: '-light',
    //     });
    // }

    openDialog(reservations = true) {
        if (reservations) {
            const dialogRef = this.dialog.open(AudienceOnboardComponent, {
                panelClass: 'ft2',
                width: '100%',
                backdropClass: '-light',
            });
        } else {
            const dialogRef = this.dialog.open(AudienceCancellationsComponent, {
                panelClass: 'ft2',
                width: '100%',
                backdropClass: '-light',
            });
        }
    }

    sendFiles(data, filetype) {
        const propertyUuid = this.spacesService.getPropertyUuid();
        return this.http.post(`${this.environment.apiBaseUrl}/${propertyUuid}/properties/${propertyUuid}/datafiles/upload/${filetype}`, data);
    }
}
