/* global angular */

angular.module('flipto.accountv1.SharedFeatures')
    .controller('flipto.account.sections.platform.homepage.HomepageMessagingController',
    ['$scope', 'messaging', 'Messaging', 'endpointUrl', '$log', 'layerName', 'touchpointType', 'touchpointGroupId', '$sce', 'changesTransformer',
        'transformType', 'touchpointActivator', 'Touchpoint', 'SpacesService', '$filter', 'Image', '$rootScope',
        function ($scope, messaging, Messaging, endpointUrl, $log, layerName, touchpointType, touchpointGroupId, $sce, changesTransformer, transformType, touchpointActivator, Touchpoint, spacesService, $filter, Image, $rootScope) {
            'use strict';
            var layerName = $scope.layerName = layerName,
                touchpointType = $scope.touchpointType = touchpointType,
                groupId = touchpointGroupId,
                translate = $filter('translate');


            messaging.isReplacePictures = messaging.isReplacePictures === "1";
            messaging.isUseFixedQuote = messaging.isUseFixedQuote === "1";
            $scope.$sce = $sce;
            $scope.errorMessage = '';
            $scope.model = {
                propertyId: spacesService.getPropertyUuid(),
                languages: [],
                selectedLanguage: {},
                messaging: messaging,
                isActive: messaging.isActive === '1'
            };
            $scope.canActivate = touchpointActivator.canActivate;

            activate();
            function activate() {
                $scope.model.language = {};
                var languages = $scope.model.languageItems = messaging.languages.item;

                languages && languages.forEach(function (language) {
                    setupLanguagesData(language);
                    $scope.model.language[language.code] = language.data;
                });

                if ($scope.model.languages.length === 0 && languages) {
                    languages.forEach(function (language) {
                        $scope.model.languages.push({code: language.code, name: language.name})
                    });
                }
            }

            function setupLanguagesData(language){
                if(language.data) return;
                var langKeys = ['isRtl', 'name', 'code'];
                var languageData = angular.copy(language);
                angular.forEach(langKeys, function(key){delete languageData[key]});
                angular.forEach(language, function(value, key){langKeys.indexOf(key) == -1 && delete language[key]});
                language.data = languageData || {};
            }

            $scope.updateState = function () {
                Touchpoint.activate({
                    propertyUuid: spacesService.getPropertyUuid(),
                    layer: layerName.replace('-', ''),
                    touchpoint: groupId,
                    touchpointType: touchpointType,
                    isActive: $scope.model.isActive
                }).then(function(model){
                    $rootScope.$broadcast('touchpoint.statusChanged', model);
                });;
            };

            $scope.commit = function (deepDiffChanges) {
                /*todo: redo this along with transformer*/
                var fixedLanguagePath = _.map(deepDiffChanges, function (change) {
                    var copy = angular.copy(change);
                    if(change.path.join('.').indexOf('model.messaging.languages.item') != -1){
                        copy.path.splice(0,2);
                    }
                    return copy;
                });

                var changes = changesTransformer(transformType.HomepageMixedData, fixedLanguagePath, messaging);
                var url = endpointUrl.getDataUrl(layerName, touchpointType, groupId, spacesService.getPropertyUuid());

                Messaging.put(url, changes);
            };

            $scope.$on('touchpoint.statusChanged', function (event, args) {
                if (touchpointType == args.touchpointType) {
                    $scope.model.isActive = args.isActive;
                }
            });

            $scope.updateFixedQuoteProfileImage = function (image) {
                $scope.model.language[$scope.model.selectedLanguage.code].fixedQuoteProfileImageUrl = image ? image.url : '';
            };

            Image.getPropertyImages({propertyId: spacesService.getPropertyUuid()}).then(function (data) {
                $scope.model.propertyImages = data['asset_images'] ? data['asset_images'] : [];
            });

            var translatePrefix = 'flipto.account.platform.homepage.touchpoints.';
            $scope.model.dialogTypes = [
                {id: 0, name: translate(translatePrefix + 'GuestQuote')},
                {id: 1, name: translate(translatePrefix + 'FixedQuote')}
            ];
            $scope.model.selectedDialogType = {id: messaging.isUseFixedQuote ? 1 : 0};
            $scope.$watch('model.selectedDialogType.id', function (selectedType) {
                if(angular.isDefined(selectedType)) {
                    messaging.isUseFixedQuote = selectedType === 1;
                    $scope.model.selectedDialogType.name = ($scope.model.dialogTypes[selectedType] &&
                        $scope.model.dialogTypes[selectedType].name) || $scope.model.dialogTypes[0].name;
                }
            })

        }
    ]);
