/**
 * Created by ostapenko.r on 9/25/2014.
 */

angular.module('flipto.core.api.touchpoint', ['flipto.core.lodash']).config([
	'$provide',
	function($provide) {
		var emailTouchpointsUriMap = {
			finalistsEmail: 'emails/finalist',
			reminderEmail: 'emails/finalistreminder',
			activityEmail: 'emails/finalistactivity',
			winnerEmail: 'emails/winnernotification',
			'invitation-email': 'emails/invitation-email',
			'reminder-emails': 'emails/reminder-emails',
			'reengagement-emails': 'emails/reengagement-emails',
			homepageEmail: 'emails/homepage',
			followup: 'emails/followup'
		};

		var names = ['Messaging', 'Integration'];
		for (var index in names) {
			$provide.factory(names[index], [
				'$http',
				function($http) {
					return {
						get: $http.get,
						put: $http.put
					};
				}
			]);
		}

		$provide.factory('endpointUrl', [
			'_',
			function(_) {
				var touchpointUrlParts = [
					//{ type: 'poststay', postfix: 'email' },
					{type: 'quote', postfix: 'data'},
					{type: 'predefinedQuote', postfix: 'quote'},
					{type: 'homepageEmail', postfix: 'email'},
					{type: 'homepage', postfix: 'data'},
					{type: 'contestPage', postfix: 'data'}
				];

				var layerUrlParts = [
					{name: 'pre-stay', urlName: 'prestay'},
					{name: 'post-stay', urlName: 'poststay'},
					{name: 'passkey-events', urlName: 'passkeyevents'},
					{name: 'photo-advocacy', urlName: 'photo-advocacy'},
					{name: 'group-advocacy', urlName: 'groupadvocacy'},
					{name: 'quote-list', urlName: 'quotelist'}
				];

				function getLayerUrlName(layerName) {
					var urlParts = _.find(layerUrlParts, {name: layerName});
					return urlParts ? urlParts.urlName : layerName;
				}

				function getDataUrl(layerName, touchpointType, groupId, propertyUuid, version) {
					var layerUrlName = getLayerUrlName(layerName),
						emailPostfix = emailTouchpointsUriMap[touchpointType];
					layerUrlName = layerUrlName == 'photocontest' && emailPostfix ? layerName : layerUrlName;
					var basePart = '/api/' + propertyUuid + '/properties/layers/' + layerUrlName + '/touchpoints/' + groupId + '/';
					if (angular.isDefined(version)) {
						basePart += 'versions/' + version + '/';
					}
					var defaultEndOfUrl = touchpointType + '/data';
					var parts = _.find(touchpointUrlParts, {type: touchpointType});
					if (emailPostfix) {
						return basePart + emailPostfix + '/data';
					}
					if (parts) {
						return basePart + parts.postfix;
					}
					return basePart + defaultEndOfUrl;
				}

				function getStyleUrl(layerName, touchpointType, groupId, propertyUuid, version) {
					var layerUrlName = getLayerUrlName(layerName),
						emailPostfix = emailTouchpointsUriMap[touchpointType];
					layerUrlName = layerUrlName == 'photocontest' && emailPostfix ? layerName : layerUrlName;

					var basePart = '/api/' + propertyUuid + '/properties/layers/' + layerUrlName + '/touchpoints/' + groupId + '/';
					if (angular.isDefined(version)) {
						basePart += 'versions/' + version + '/';
					}

					if (emailPostfix) {
						return basePart + emailPostfix + '/styling';
					} else {
						basePart +=
							layerName === 'pre-stay' ||
							layerName === 'group-advocacy' ||
							touchpointType === 'photo-river' ||
							touchpointType === 'pre-arrival-email' ||
							touchpointType === 'poststay-email-image'
								? touchpointType + '/'
								: '';
					}

					return basePart + 'styling';
				}

				function getIntegrationUrl(layerName, touchpointType, groupId, propertyUuid) {
					var layerUrlName = getLayerUrlName(layerName);
					var url = String.prototype.concat.apply('', [
						'/api/' + propertyUuid + '/properties/layers/',
						layerUrlName,
						'/touchpoints/',
						groupId,
						'/',
						layerName === 'pre-stay' || layerName === 'group-advocacy' || touchpointType === 'photo-river'
							? touchpointType + '/'
							: '',
						'integration'
					]);
					return url;
				}

				return {
					getDataUrl: getDataUrl,
					getStyleUrl: getStyleUrl,
					getIntegrationUrl: getIntegrationUrl
				};
			}
		]);

		$provide.factory('Touchpoint', [
			'$http',
			'urlFormatter',
			function($http, urlFormatter) {
				/**
				 * Touchpoint service
				 * @constructor
				 */
				function Touchpoint() {
					/**
					 * Saves the touchpoint version for the specified campaign
					 * @param model
					 * @returns {*|HttpPromise|promise}
					 */
					this.activateVersion = function(model) {
						var url = urlFormatter.format(
							'/api/:propertyUuid/properties/layers/:layerName/touchpoints/:groupId/versions/:version/activate',
							model
						);
						return $http.post(url).then(function(response) {
							return response.data;
						});
					};

					/**
					 * Installs the touchpoint version upgrade.
					 * @param model
					 * @returns {*|HttpPromise|promise}
					 */
					this.installVersion = function(model) {
						var url = urlFormatter.format(
							'/api/:propertyUuid/properties/layers/:layerName/touchpoints/:groupId/versions/:version/install',
							model
						);
						return $http.post(url).then(function(response) {
							return response.data;
						});
					};

					/**
					 * Updates touchpoint group status
					 * @param model
					 * @returns {*|HttpPromise|promise}
					 */
					this.setGroupStatus = function(model) {
						var url = urlFormatter.format(
							'/api/:propertyUuid/properties/layers/touchpoints/:groupId/activate/:isActive',
							model
						);
						return $http.put(url).then(function(response) {
							return response.data;
						});
					};

					/**
					 * Updates touchpoint status
					 * @param model
					 * @returns {*|HttpPromise|promise}
					 */
					this.setStatus = function(model) {
						model.layerName = model.layerName.replace('-', '');
						var template = '/api/:propertyUuid/properties/layers/:layerName/touchpoints/:groupId/:type/';
						template += model.feature ? ':feature/' : '';
						template += 'activate/:isActive';

						var url = urlFormatter.format(template, model);
						return $http.put(url).then(function(response) {
							return response.data;
						});
					};

					this.canActivate = function(touchpointType) {
						var canActivateTouchpoints = [
							'followup',
							'email',
							'finalistsEmail',
							'reminderEmail',
							'activityEmail',
							'winnerEmail',
							'homepageEmail',
							'confirmation',
							'quote',
							'contestPage',
							'list',
							'homepage'
						];
						return canActivateTouchpoints.indexOf(touchpointType) > -1;
					};

					/**
					 * Activate/Deactivate touchpoint
					 * @param model
					 * @returns {*|HttpPromise|promise}
					 */
					this.activate = function(model) {
						var url;
						var origModel = angular.copy(model);

						var emailPostfix = emailTouchpointsUriMap[model.touchpointType];
						if (model.layer == 'photoadvocacy') {
							model.layer =
								emailPostfix ||
								model.touchpointType == 'poststay-email-image' ||
								model.touchpointType == 'pre-arrival-email' ||
								model.touchpointType == 'photo-river'
									? 'photo-advocacy'
									: 'photocontest';
						}

						if (
							model.touchpointType == 'confirmation' ||
							model.touchpointType == 'list' ||
							model.touchpointType == 'homepage' ||
							model.touchpointType == 'quote' ||
							model.touchpointType == 'contestPage'
						) {
							url =
								'/api/' + model.propertyUuid + '/properties/layers/touchpoints/' +
								model.touchpoint +
								'/activate/' +
								model.isActive;
						} else {
							url =
								'/api/' + model.propertyUuid + '/properties/layers/' +
								model.layer +
								'/touchpoints/' +
								model.touchpoint +
								'/' +
								(emailPostfix || model.touchpointType) +
								'/activate/' +
								model.isActive;
						}
						return $http.put(url).then(function() {
							return origModel;
						});
					};

					this.get = $http.get;
					this.put = $http.put;
				}

				return new Touchpoint();
			}
		]);
	}
]);
