/**
 * Created by Roman on 2015-03-13.
 */



angular.module('flipto.components.common.modelFormatters')
    .directive('ftAvailableTags', function () {

        return {
            require: 'ngModel',
            link: function (scope, element, attrs, modelCtrl) {

                var tagsString = (attrs.tagsExpression && scope.$eval(attrs.tagsExpression)) ||
                    (attrs.ftAvailableTags && attrs.ftAvailableTags);

                var tags = tagsString && tagsString.split(',').join('|');
                tags && modelCtrl.$parsers.push(function (inputValue) {
                    var regexp = new RegExp("<(?!\/?(" + tags + ")(?=>|\\s.*>))\/?.*?>", "gm");
                    return inputValue.replace(regexp, '');
                });
            }
        };
    });
