/**
 * Created by ostapenko.r on 9/26/2014.
 */
angular.module('flipto.accountv1.SharedFeatures')
    .controller('flipto.account.sections.platform.IntegrationController',
        ['$scope', 'integration', 'SpacesService', 'SessionService', 'RateCodes', 'Integration', 'BookingEngines', 'endpointUrl', 'layerName', 'touchpointType', 'touchpointGroupId', '$sce', 'changesTransformer',
            'transformType', '_', 'ftModal', '$filter', 'Touchpoint', 'IdentityService', '$rootScope', 'PostStay', 'postStayVersion',
            'parse', 'versionSelection', 'languageHelper', 'confirmationDisplayTypes', 'confirmationTimerActionTypes', 'insertionTypes', 'overlayTypes', 'environment',
            function ($scope, integration, spacesService, sessionService, RateCodes, Integration, BookingEngines, endpointUrl, layerName, touchpointType, touchpointGroupId, $sce, changesTransformer, transformType, _, ftModal, $filter, Touchpoint, identity, $rootScope, PostStay, postStayVersion, parse, versionSelection, languageHelper, confirmationDisplayTypes, confirmationTimerActionTypes, insertionTypes, overlayTypes, environment) {
                'use strict';

                var layerName = $scope.layerName = layerName,
                    touchpointType = $scope.touchpointType = touchpointType,
                    groupId = touchpointGroupId,
                    multiEngineMode = integration.hasOwnProperty('bookingEngines'),
                    translate = $filter('translate'),
                    bookingEngines;

                if (!(integration.codes && integration.codes.item)) {
                    integration.codes = { item: [] };
                }
                $scope.identity = identity;
                $scope.$sce = $sce;
                $scope.errorMessage = '';
                $scope.model = {
                    integration: integration,
                    integrationModes: [
                        { mode: '1', name: translate('flipto.account.platform.postStay.touchpoints.Integration.AfterCheckOut') },
                        /*{mode: '2', name: translate('flipto.account.platform.postStay.touchpoints.Integration.AfterExternalRating')},*/
                        { mode: '4', name: translate('flipto.account.platform.postStay.touchpoints.Integration.HostedFirstPage') },
                        { mode: '0', name: translate('flipto.account.platform.postStay.touchpoints.Integration.HostedFinalPage') }
                    ],
                    selectedEngine: {},
                    emails: '',
                    isActive: integration.isActive == '1',
                    instruction: [],
                    engineNames: [],
                    postStayVersions: [],
                    tempTransformType: undefined,
                    permanentTransformType: undefined,
                    property: sessionService.getProperty(spacesService.getPropertyUuid()),
                    rateCodes: [],
                    enums: {
                        confirmationTimerActionTypes: confirmationTimerActionTypes,
                        confirmationDisplayTypes: confirmationDisplayTypes,
                        insertionTypes: insertionTypes,
                        overlayTypes: overlayTypes
                    }
                };

                if (multiEngineMode) {
                    $scope.model.bookingEngines = bookingEngines = integration.bookingEngines.item;
                    _.forEach(bookingEngines, function (bookingEngine) {
                        bookingEngine.codes = bookingEngine.codes || { item: [] };
                    })
                }


                if (touchpointType === 'quote') {
                    $scope.$watch('model.integration.poststayVersionId', function (poststayVersionId) {
                        if (poststayVersionId == postStayVersion.Version0QuoteOnly) {
                            $scope.model.integration.isEnablePhotos = false;
                        }
                    });

                    PostStay.versions().then(function (versions) {
                        $scope.model.postStayVersions = _.filter(versions, { isActive: true });
                    });

                    $scope.versionNameById = function (id) {
                        var version = _.find($scope.model.postStayVersions, { id: id });
                        return version ? version.name : '';
                    }
                }

                if (touchpointType === 'homepage' || touchpointType === 'list') {
                    integration.isReplacePictures = integration.isReplacePictures === "1";
                    var translatePrefix = 'flipto.account.platform.touchpoints.';
                    $scope.model.profileImageTypes = [
                        { id: 0, name: translate(translatePrefix + 'UseGuestPhoto') },
                        { id: 1, name: translate(translatePrefix + 'ReplaceGuestPhoto') }
                    ];
                    $scope.model.selectedImageProfileType = { id: integration.isReplacePictures ? 1 : 0 };
                    $scope.$watch('model.selectedImageProfileType.id', function (selectedType) {
                        if (angular.isDefined(selectedType)) {
                            integration.isReplacePictures = selectedType === 1;
                            $scope.model.selectedImageProfileType.name = ($scope.model.profileImageTypes[selectedType] &&
                                $scope.model.profileImageTypes[selectedType].name) || $scope.model.profileImageTypes[0].name;
                        }
                    })
                }

                if (touchpointType == 'contestPage') {
                    $scope.model.integration.isBookNowActive = parse.bool($scope.model.integration.isBookNowActive);
                    $scope.model.integration.isFooterActive = parse.bool($scope.model.integration.isFooterActive);
                }

                activate();

                $scope.updateState = function () {
                    Touchpoint.activate({
                        propertyUuid: spacesService.getPropertyUuid(),
                        layer: layerName.replace('-', ''),
                        touchpoint: groupId,
                        touchpointType: touchpointType,
                        isActive: $scope.model.isActive
                    }).then(function (model) {
                        $rootScope.$broadcast('touchpoint.statusChanged', model);
                    });
                };

                $scope.updateBookingEngineState = function (engine) {
                    BookingEngines.activate(spacesService.getPropertyUuid(), engine);
                };

                $scope.commit = function (deepDiffChanges) {
                    var fixedChanges = _.map(deepDiffChanges, function (change) {
                        var copy = angular.copy(change);
                        if (change.path.join('.').indexOf('model.bookingEngines') != -1 ||
                            change.path.join('.').indexOf('model.languageItems') != -1) {
                            copy.path.splice(0, 2);
                        }
                        return copy;
                    });

                    var changes = multiEngineMode ? changesTransformer(transformType.BookingEngineIntegrationData, fixedChanges, bookingEngines)
                        : changesTransformer($scope.model.tempTransformType || $scope.model.permanentTransformType || transformType.FlatData, fixedChanges, integration);
                    angular.isDefined(changes.maxQuoteTextLength) && _.isNull(changes.maxQuoteTextLength) && (changes.maxQuoteTextLength = 0)
                    var url = endpointUrl.getIntegrationUrl(layerName, touchpointType, groupId, spacesService.getPropertyUuid());

                    if (touchpointType == 'contestPage') {
                        var langChanges = _.filter(deepDiffChanges, function (change) {
                            return change.path.join('.').indexOf('model.languageItems') != -1;
                        });
                        var fixedLangChanges = _.map(langChanges, function (change) {
                            var copy = angular.copy(change);
                            if (change.path.join('.').indexOf('model.languageItems') != -1) {
                                copy.path.splice(0, 2);
                            }
                            return copy;
                        });
                        var transformedLanguages = changesTransformer(transformType.MultiLanguageData, fixedLangChanges, integration.languages.item);
                        if (transformedLanguages.length > 0)
                            changes.languages = transformedLanguages;
                    }

                    !_.isEmpty(changes) && Integration.put(url, changes);
                    $scope.model.tempTransformType = undefined;
                };

                $scope.commitLandingPageHtml = function () {
                    var model = {
                        propertyUuid: spacesService.getPropertyUuid(),
                        landingPageType: $scope.model.integration.landingPageType,
                        languages: [angular.extend({ languageCode: $scope.model.selectedLanguage }, $scope.model.language[$scope.model.selectedLanguage])]
                    };
                    var url = endpointUrl.getIntegrationUrl(layerName, touchpointType, groupId, spacesService.getPropertyUuid());
                    Integration.put(url, model);
                };

                $scope.commitLanguages = function (deepDiffChanges) {
                    var fixedChanges = _.map(deepDiffChanges, function (change) {
                        var copy = angular.copy(change);
                        if (change.path.join('.').indexOf('model.languageItems') != -1) {
                            copy.path.splice(0, 2);
                        }
                        return copy;
                    });

                    var changes = { languages: changesTransformer(transformType.MultiLanguageData, fixedChanges, integration.languages.item) };
                    var url = endpointUrl.getIntegrationUrl(layerName, touchpointType, groupId, spacesService.getPropertyUuid());
                    !_.isEmpty(changes.languages) && Integration.put(url, changes);
                };


                $scope.sendInstructions = function () {
                    var emails = $scope.model.emails.split(';');
                    var instructions = $scope.openInstruction($scope.model.selectedEngine);
                    $scope.model.emails = '';
                };

                $scope.openInstruction = function (engine, event) {
                    event.stopPropagation();
                    if (!$scope.engineHaveInstructions(engine)) {
                        return;
                    }

                    $scope.model.selectedEngine = engine;
                    ftModal.show('instructionModal');
                };

                $scope.engineHaveInstructions = function (engine) {
                    return $scope.model.instruction[engine.type] && angular.isDefined($scope.model.instruction[engine.type].integrationInstructions);
                };

                $scope.selectBookingEngine = function (engine) {
                    $scope.model.selectedEngine = engine;
                };

                $scope.openEditSelectorEditor = function (type) {
                    $scope.model.selectedSelectorType = type;
                    ftModal.show('group-advocacy.confirmation.integration.selector');
                };

                $scope.savePreArrivalEmailImageSize = function (diffs) {
                    if (diffs.length == 0) return;
                    var model = {
                        width: $scope.model.integration.width
                    };
                    var url = endpointUrl.getIntegrationUrl(layerName, touchpointType, groupId, spacesService.getPropertyUuid());
                    Integration.put(url, model);
                };

                $scope.activateVersion = function (version) {
                    Touchpoint.activateVersion({
                        propertyUuid: spacesService.getPropertyUuid(),
                        layerName: layerName.replace('-', ''),
                        groupId: groupId,
                        touchpointType: touchpointType,
                        version: version
                    }).then(function () {
                        setupVersionManager({ poststayVersionId: version });
                    });
                };

                $scope.installVersion = function (version) {
                    Touchpoint.installVersion({
                        propertyUuid: spacesService.getPropertyUuid(),
                        layerName: layerName.replace('-', ''),
                        groupId: groupId,
                        touchpointType: touchpointType,
                        version: version
                    }).then(function () {
                        versionSelection.clear();
                        loadData();
                    });
                };

                $scope.saveRateCodes = function() {
                    RateCodes.updateRateCodes(spacesService.getPropertyUuid(), $scope.model.rateCodes);
                }

                $scope.versionChanged = function (oldVersion, newVersion) {
                    versionSelection.update(newVersion);
                };

                $scope.isEnablePhotosDisabled = function () {
                    var version = $scope.version;
                    return (version.activatedVersion == 0 && version.currentVersion == 0) || (version.activatedVersion == 1 && version.currentVersion == 2);
                };

                $scope.allowToggleLandingPageType = function (landingPageType) {
                    // ALlow integrated iff we have at least one landingpageurl
                    var allow = false;
                    switch (landingPageType) {
                        case 1:
                            allow = _.some($scope.model.languageItems, function (lang) {
                                return !!lang.data.landingPageUrl;
                            });
                            break;
                        case 2:
                            allow = _.some($scope.model.languageItems, function (lang) {
                                return !!lang.data.landingPageHtml;
                            });
                            break;
                        case 3:
                            allow = true;
                            break;
                    }
                    return allow;
                };

                $scope.setIntegratedLandingPageType = function () {
                    $scope.toggleLandingPageType(1);
                };

                $scope.setCustomLandingPageType = function () {
                    $scope.toggleLandingPageType(2);
                };

                $scope.setHostedLandingPageType = function () {
                    $scope.toggleLandingPageType(3);
                };

                $scope.toggleLandingPageType = function (type) {
                    $scope.model.integration.landingPageType = type;
                    var url = endpointUrl.getIntegrationUrl(layerName, touchpointType, groupId, spacesService.getPropertyUuid());
                    Integration.put(url, { landingPageType: type });
                };

                $scope.getLandingPagePreviewUrl = function (pageType) {
                    var promoCode = $scope.model.languageItems[0].data.promoterCode;
                    return String.prototype.concat.call(environment.FliptoPlatformDomain, '/', promoCode, '?pageType=', pageType);
                };

                $scope.$on('touchpoint.statusChanged', function (event, args) {
                    if (touchpointType == args.touchpointType) {
                        $scope.model.isActive = args.isActive;
                    }
                });

                function loadData() {
                    var url = endpointUrl.getIntegrationUrl(layerName, touchpointType, groupId, spacesService.getPropertyUuid());
                    Integration.get(url)
                        .then(function (response) {
                            return response.data;
                        })
                        .then(function (data) {
                            integration = data;
                            activate();
                        });
                }

                function activate() {
                    prepare();
                    setupVersionManager(integration);

                    multiEngineMode ? _.forEach(bookingEngines, normalizeStrings) : normalizeStrings(integration);
                    if (multiEngineMode) {
                        BookingEngines.get().then(function (engines) {
                            $scope.model.engines = parse.arrayOrDefault(engines);
                            _.forEach($scope.model.engines, function (engine) {
                                $scope.model.engineNames[engine.id] = engine.name;
                                $scope.model.instruction[engine.id] = {
                                    integrationCodeSnippet: engine.integrationCodeSnippet,
                                    integrationInstructions: engine.integrationInstructions && engine.integrationInstructions.replace('{code-snippet}', '')
                                }
                            })
                        });
                    }

                    RateCodes.getRateCodes(spacesService.getPropertyUuid()).then(function (data) {
                         $scope.model.rateCodes = data;
                    });

                    $scope.model.language = {};
                    $scope.model.languages = [];

                    languageHelper.sortByDefaultLanguage(integration.languages);
                    var languages = $scope.model.languageItems = !!integration.languages ? integration.languages.item : [
                        { 'code': 'en', name: 'English' }
                    ];

                    languages && languages.forEach(function (language) {
                        language.data = language.data || {};
                        $scope.model.language[language.code] = language.data;
                        $scope.model.language[language.code].livePreviewQueryParams = { lang: language.code, flipto: language.data.promoterCode, debug: 1 };
                    });

                    if ($scope.model.languages.length === 0 && languages) {
                        languages.forEach(function (language) {
                            $scope.model.languages.push({ code: language.code, name: language.name })
                        });
                    }

                }

                function normalizeStrings(source) {
                    var jsonProperties = ['jsonSettings'];
                    var boolProperties = ['isReplacePictures', 'isPredefinedQuote', 'isLowRatingExternalReview', 'isNewQuoteProcess', 'isEnablePhotos', 'isActive', 'isDesktopReopenerActive', 'isMobileReopenerActive', 'isDesktopOpenNewWindow', 'isMobileOpenNewWindow', 'isTimerEnabled', 'isShowIncentiveOnIntro'];
                    var intProperties = ['maxQuoteTextLength', 'pageSize', 'width', 'timerDuration'];

                    _.forEach(source, function (value, key) {
                        if (_.includes(boolProperties, key)) {
                            source[key] = value === '1' ? true : false;
                        }
                        if (_.includes(intProperties, key)) {
                            source[key] = parseInt(value) || 0;
                        }
                        if (_.includes(jsonProperties, key)) {
                            source[key] = value == null ? '' : value.replace(/[\r]/g, '');
                        }
                    });
                }

                function setupVersionManager(objectToUpgrade) {
                    $scope.version = {
                        currentVersion: versionSelection.get($scope.version && $scope.version.currentVersion, objectToUpgrade.poststayVersionId, objectToUpgrade.installedVersionId),
                        activatedVersion: objectToUpgrade.poststayVersionId,
                        updateInstalled: objectToUpgrade.installedVersionId > objectToUpgrade.poststayVersionId,
                        availableVersion: objectToUpgrade.lastVersionId,
                        currentIsNewVersion: function () {
                            return $scope.version && $scope.version.updateInstalled && $scope.version.currentVersion === $scope.version.availableVersion;
                        }
                    }
                }

                function prepare() {
                    integration.installedVersionId && (integration.installedVersionId = parse.intOrDefault(integration.installedVersionId));
                    integration.poststayVersionId && (integration.poststayVersionId = parse.intOrDefault(integration.poststayVersionId));
                    integration.lastVersionId && (integration.lastVersionId = parse.intOrDefault(integration.lastVersionId));
                    integration.subdomains && (integration.subdomains = parse.arrayOrDefault(integration.subdomains));
                    _.each(integration.subdomains, function (domain) {
                        domain.isActive = parse.bool(domain.isActive);
                        domain.isDefault = parse.bool(domain.isDefault);
                        domain.routePurpose = parse.int(domain.routePurposeId);
                        domain.properties = parse.arrayOrDefault(domain.properties);
                    })
                }
            }
        ]);
