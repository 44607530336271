export enum DomInjectionStrategy {
    ReplaceContent = 0,
    ReplaceWith = 1,
    Append = 2,
    Prepend = 3,
    CloneTarget = 4,
    Before = 5,
    After = 6
}

export const domInjectionStrategyOptions = [
    {
        label: 'Replace content',
        value: 0
    },
    {
        label: 'Replace with',
        value: 1
    },
    {
        label: 'Append',
        value: 2
    },
    {
        label: 'Prepend',
        value: 3
    },
    {
        label: 'Clone target',
        value: 4
    },
    {
        label: 'Before',
        value: 5
    },
    {
        label: 'After',
        value: 6
    }
];
