import { Inject, Injectable, Optional } from '@angular/core';
import { NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';
import { dateToNgbDate } from './custom-ngb-adapter';
import { DATE_FORMAT_VALUE_TOKEN } from '../di/tokens';
import { DateFormatValue } from './date-format-value';

export const ngbDateToDateString = (date: NgbDate) => {
    if (!date) {
        return null;
    }

    const year = date.year;
    const month = ('0' + date.month).slice(-2);
    const day = ('0' + date.day).slice(-2);
    return `${year}-${month}-${day}`;
};


/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class NgbCustomFormatter extends NgbDateParserFormatter {
    constructor(@Optional() @Inject(DATE_FORMAT_VALUE_TOKEN) private dateFormat: DateFormatValue) {
        super();
    }

    parse(value: string): NgbDateStruct | null {
        return dateToNgbDate(new Date(value));
    }

    format(date: NgbDateStruct | null): string {
        const dateString = ngbDateToDateString(date as NgbDate);
        return this.dateFormat?.value && dateString ? dayjs(dateString).format(this.dateFormat.value) : dateString;
    }
}
