import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from "../../../classes/modal-editor-base";

@Component({
    selector: 'ft-children-ages-editor',
    templateUrl: './children-ages-editor.component.html',
    styleUrls: ['./children-ages-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ChildrenAgesEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: ChildrenAgesEditorComponent
        }
    ]
})
export class ChildrenAgesEditorComponent extends ModalEditorBase {
    @Input() selectDisabled = false;
    options = [
        { value: false, text: 'Do not require kid\'s age' },
        { value: true, text: 'Require kid\'s age' },
    ];

    isInvalid(): boolean {
        return this.controlsModelRef.some(input => input.invalid) || false;
    }
}
