import { Component, EventEmitter, Output, Input, Inject, forwardRef } from '@angular/core';
import { ShortUrlService } from '../short-urls.service';
import { take } from 'rxjs/operators';
import { ContestInviteSetupOptions } from './setup-options';
import { URL_FORMATTER_TOKEN } from '../../../app.module';

@Component({
  selector: 'short-url-wizard',
  templateUrl: './short-url-wizard.component.html',
  styleUrls: ['./short-url-wizard.component.css']
})
export class ShortUrlWizardComponent {
  @Input() params: ContestInviteSetupOptions;
  @Output() done: EventEmitter<any> = new EventEmitter();
  wI = 0;
  data: any = {};
  submitted = false;

  error = false;
  errorMessage = '';

  constructor(
    private shortUrls: ShortUrlService,
    @Inject(forwardRef(() => URL_FORMATTER_TOKEN)) private urlFormatter
  ) { }

  err(message: string) {
    this.submitted = false;
    this.error = true;
    this.errorMessage = message;
  }

  saveStep() {
    this.submitted = true;
    this.shortUrls
      .createShortUrl(
        this.params.companyCode,
        this.params.propertyUuid,
        this.data.url,
        this.data.displayName,
        this.data.shortUrl,
        this.params.contestUuid,
        this.params.theme
      )
      .pipe(take(1))
      .subscribe(
        () => this.done.emit(this.data),
        (err: any) => {
          this.err(err.error);
        }
      );
  }

  destUrlStep() {
    if (!this.data.destinationUrl) {
      this.data.url = this.urlFormatter.format(
        this.params.baseUrl,
        {
          utm_source: 'flipto',
          utm_medium: 'referral',
          utm_campaign: 'broadcast'
        },
        true
      );
    } else {
      this.data.url = this.urlFormatter.appendToQueryString(this.params.baseUrl, this.data.destinationUrl);
    }

    this.continue();
  }

  continue() {
    this.wI++;
    this.submitted = false;
    this.error = false;
    this.errorMessage = '';
  }

  previousStep() {
    this.submitted = false;
    if (this.wI > 0) { this.wI--; }
  }

}
