/**
 * Created by roman.kupin on 10/3/2017.
 */



declare let angular;
export const NAME = "ftSectionTrigger";

export const DEFINITION = {
    scope: false,
    require: "^ftSections",
    link: function SectionTriggerLinkFn(scope, elem, attrs, ctrl) {

        function setActive() {
            scope.$apply(() => {
                ctrl.activate(attrs.ftSectionTrigger);
            });
        }

        elem.on("click", setActive);

        scope.$on("$destroy", () => {
            elem.off("click", setActive);
        });
    }
};

