/**
 * Created by Roman on 2014-10-30.
 */



angular
    .module("flipto.components.imageLibrary")
    .config(['$compileProvider', function ($compileProvider) {
        $compileProvider.directive('ftImageList', [function () {
            return {
                restrict: 'E',
                require: '^ftImageLibrary',
                templateUrl: '/app/components/image-library/imageList.html',
                scope: {
                    'images': '=',
                    'filter': '='
                },
                link: function (scope, elem, attrs, ctrl) {

                    /**
                     * Show image preview
                     * @param image
                     */
                    scope.showPreview = function (image) {
                        ctrl.selectImage(image);
                    };

                    /**
                     * Clear imageType filter
                     */
                    scope.clearFilter = function(){
                        delete scope.filter;
                    };
                }
            };
        }]);
    }]);