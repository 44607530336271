import { Pipe, PipeTransform } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DateUtils } from 'shared';

@Pipe({
    name: 'isDateInPast'
})
export class IsDateInPastPipe implements PipeTransform {
    constructor() {
    }

    transform(date: Date | NgbDate, inclusive?: boolean): boolean {
        if (date) {
           return DateUtils.isBefore(DateUtils.toDateFormat(date), new Date(), inclusive);
        }
        return false;
    }
}
