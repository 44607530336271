/**
 * Created by roman.kupin on 24/08/2014.
 */



(function (angular) {

    var routing = {};

    /**
     * StateProxy object
     * @param config
     * @param parent
     * @constructor
     */
    routing.StateProxy = function (config, parent) {

        /**
         * Initial config object
         */
        this.config = config;

        /**
         * Prent state
         */
        this.parent = parent;

        /**
         * Visible is True by default
         * @type {boolean}
         */
        this.visible = angular.isDefined(config.visible) ? config.visible : true;

        /**
         * Array of sheets
         * @type {Array}
         */
        this.sheets = [];
    };

    /**
     * Returns state object
     * @returns {{name: *, url: *, template: string}}
     */
    routing.StateProxy.prototype.toState = function () {
        throw 'Not implemented';
    };

    /**
     * Returns this page as it is parent
     * @returns {routing.Page}
     */
    routing.StateProxy.prototype.getRoot = function () {
        throw 'Not implemented';
    };

    /**
     * Returns true if state allows access to specified userType
     * @returns {boolean}
     */
    routing.StateProxy.prototype.hasAccess = function (role) {
        if (!this.config.grantAccessTo) return true;
        for (var i = 0, len = this.config.grantAccessTo.length; i < len; ++i) {
            if (this.config.grantAccessTo[i] === role) return true;
        }
        return false;
    };

    /**
     * Page object
     * @constructor
     */
    routing.Page = function (config) {
        routing.Page.superClass.constructor.call(this, config);
        this.isPage = true;
    };
    inherits(routing.StateProxy, routing.Page);

    /**
     * Returns state object
     * @returns {{name: *, url: *, template: string}}
     */
    routing.Page.prototype.toState = function () {
        var state = angular.extend({}, this.config, {name: !this.config.parent ? this.config.name : (this.config.parent + '.' + this.config.name)});
        delete state.parent;
        return state;
    };

    /**
     * Returns this page as it is parent
     * @returns {routing.Page}
     */
    routing.Page.prototype.getRoot = function () {
        return this;
    };

    /**
     * Returns class or name as a class
     * @returns {*}
     */
    routing.Page.prototype.getClass = function () {
        return this.config.class || this.config.name;
    };

    /**
     * PartialPage object
     * @constructor
     */
    routing.PartialPage = function (config, parent) {
        routing.PartialPage.superClass.constructor.call(this, config, parent);
        this.isPage = true;
    };
    inherits(routing.StateProxy, routing.PartialPage);

    /**
     * Returns state object
     * @returns {{name: *, url: *, template: string}}
     */
    routing.PartialPage.prototype.toState = function () {
        var state = angular.extend({}, this.config, {name: this.parent.toState().name + '.' + this.config.name});
        delete state.parent;
        return state;
    };

    /**
     * Returns this page as it is parent
     * @returns {routing.Page|routing.PartialPage}
     */
    routing.PartialPage.prototype.getRoot = function () {
        return this.parent.getRoot();
    };

    /**
     * Returns class or name as a class
     * @returns {*}
     */
    routing.PartialPage.prototype.getClass = function () {
        return this.config.class || this.parent.getClass();
    };

    /**
     * Sheet object
     * @constructor
     */
    routing.Sheet = function (config, parent) {
        routing.Sheet.superClass.constructor.call(this, config, parent);
        this.tabVisible = angular.isDefined(config.tabVisible) ? config.tabVisible : true;
        this.isPage = false;
    };
    inherits(routing.StateProxy, routing.Sheet);

    /**
     * Returns state object
     * @returns {Object}
     */
    routing.Sheet.prototype.toState = function () {
        var state = angular.extend({}, this.config, {name: this.parent.toState().name + '.' + this.config.name});
        delete state.parent;
        return state;
    };

    /**
     * Returns root page
     * @returns {*}
     */
    routing.Sheet.prototype.getRoot = function () {
        return this.parent.getRoot();
    };

    /**
     * Returns class or parent's class
     * @returns {*}
     */
    routing.Sheet.prototype.getClass = function () {
        return this.config.class || this.parent.getClass();
    };

    /**
     * Encapsulates sheet's template decoration logic
     * @constructor
     */
    routing.SheetTemplateDecorator = function () {
    };

    /**
     * Decorate sheet.
     * Only decorate by template and template url if provided.
     * Decorate provider functionality is not implemented.
     * @param sheet
     */
    routing.SheetTemplateDecorator.prototype.decorate = function (sheet) {
        var state = sheet.toState();
        if (!sheet.config.abstract) {
            state = angular.copy(sheet.toState());
            state.templateProvider = state.templateProvider || ['$templateFactory', function ($templateFactory) {
                return routing.SheetTemplateDecorator.decorateByTemplate(sheet) ||
                    routing.SheetTemplateDecorator.decorateByTemplateUrl(sheet, $templateFactory);
            }];
            /* Remove decorated properties */
            delete state.template;
            delete state.templateUrl;
        }
        return state;
    };

    /**
     * Decorates template string
     * @param sheet
     * @returns {*}
     */
    routing.SheetTemplateDecorator.decorateByTemplate = function (sheet) {
        return angular.isDefined(sheet.config.template) && routing.SheetTemplateDecorator.decorateContent(sheet.config.template, sheet);
    };

    /**
     * Decorates template by url
     * @param sheet
     * @param $templateFactory
     * @returns {.views.templateUrl|*|templateUrl|F.templateUrl|r.templateUrl|derivedSyncDirective.templateUrl}
     */
    routing.SheetTemplateDecorator.decorateByTemplateUrl = function (sheet, $templateFactory) {
        return angular.isDefined(sheet.config.templateUrl) &&
            $templateFactory
                .fromUrl(sheet.config.templateUrl)
                .then(function (content) {
                    return routing.SheetTemplateDecorator.decorateContent(content, sheet);
                });
    };

    /**
     * Decrate content depending on type of sheet
     * @param content
     * @param sheet
     * @returns {*}
     */
    routing.SheetTemplateDecorator.decorateContent = function (content, sheet) {
        if (sheet instanceof routing.Sheet) {
            content = routing.SheetTemplateDecorator.surroundWithSheet(content, sheet);
        }
        content = routing.SheetTemplateDecorator.appendUiView(content, sheet);
        return content;
    };

    /**
     * Surround with <ft-sheet/> directive
     * @param content
     * @sheet sheet object
     */
    routing.SheetTemplateDecorator.surroundWithSheet = function (content, sheet) {
        return String.prototype.concat.call('<ft-sheet name="', sheet.toState().name, '">', content, '</ft-sheet>');
    };

    /**
     * Append <ui-view/> directive at the end of content
     * @param content
     */
    routing.SheetTemplateDecorator.appendUiView = function (content) {
        return String.prototype.concat.call(content, '<ui-view class="anim-sheet"></ui-view>');
    };

    /**
     * Page factory
     * @constructor
     */
    routing.PageFactory = function () {
        /**
         * Sheet factory
         * @type {routing.SheetFactory}
         */
        this.sheetFactory = new routing.SheetFactory();
    };

    /**
     * Create page from config
     * @param config
     */
    routing.PageFactory.prototype.create = function (config) {
        var page = new routing.Page(config),
            sheetFactory = this.sheetFactory;
        if (config.sheets) {
            angular.forEach(config.sheets, function (sheet) {
                page.sheets.push(sheetFactory.create(sheet, page));
            });
        }
        return page;
    };

    /**
     * Sheet factory
     * @constructor
     */
    routing.SheetFactory = function () {
    };

    /**
     * Create sheet
     * @param config
     * @param parent
     * @returns {Object}
     */
    routing.SheetFactory.prototype.create = function (config, parent) {
        if (!parent) throw 'page must be defined';
        var sheet = new routing.Sheet(config, parent);
        if (config.sheets) {
            for (var i = 0, len = config.sheets.length; i < len; ++i) {
                sheet.sheets.push(this.create(config.sheets[i], sheet));
            }
        }
        return sheet;
    };

    /**
     * Page processor
     * @constructor
     */
    routing.PageVisitor = function () {
    };

    /**
     *
     * @param source
     * @param fn
     */
    routing.PageVisitor.prototype.walk = function (source, fn) {
        fn(source);
        if (source.sheets) {
            angular.forEach(source.sheets, function (sheet) {
                this.walk(sheet, fn);
            }, this);
        }
    };

    /**
     * Array of registered pages
     * @type {Array}
     */
    routing.pages = [];


    /**
     * Pages provider
     * @constructor
     */
    function PageProvider($stateProvider) {

        /**
         * Page factory
         * @type {routing.PageFactory}
         */
        this.pageFactory = new routing.PageFactory();

        /**
         * Sheet factory
         * @type {routing.SheetFactory}
         */
        this.sheetFactory = new routing.SheetFactory();

        /**
         * Page visitor
         * @type {routing.PageVisitor}
         */
        this.visitor = new routing.PageVisitor();

        /**
         * Sheet decorator
         * @type {routing.SheetTemplateDecorator}
         */
        this.decorator = new routing.SheetTemplateDecorator();

        /**
         * $stateProvider instance
         */
        this.$stateProvider = $stateProvider;

        /**
         * Returns pages service instance
         * @returns {*}
         */
        this.$get = ['$injector', function ($injector) {
            return $injector.instantiate(routing.PageService);
        }];

        /**
         * Register page
         * @param config
         */
        this.page = function (config) {
            var page = this.pageFactory.create(config);
            this.visitor.walk(page, this.decorateAndRegister.bind(this));
            routing.pages.push(page);
            runSheetQueue();
        };


        var sheetQueue = [],
            self = this;

        /**
         * Register page
         * @param config
         */
        this.sheet = function (config) {
            runSheetQueue();
            var page = pageLookup(config.parent);
            if (angular.isDefined(page) == false) {
                sheetQueue.push(config);
                return;
            }
            registerSheet(config, page);
            //todo: verify
            runSheetQueue();
        };

        /**
         * Decorate state's template and register it
         * @param state
         */
        this.decorateAndRegister = function (state) {
            var decoratedState = this.decorator.decorate(state);
            this.$stateProvider.state(decoratedState);
        };

        /**
         * Register delayed sheets
         */
        function runSheetQueue() {
            angular.forEach(sheetQueue, function (config, index) {
                var page = pageLookup(config.parent);
                if (angular.isDefined(page) == true) {
                    registerSheet(config, page);
                    sheetQueue.splice(index, 1);
                    runSheetQueue();
                }
            }, this);
        }

        /**
         * Register sheet
         * @param config
         * @param page
         */
        function registerSheet(config, page) {
            var sheet = self.sheetFactory.create(config, page);
            self.visitor.walk(sheet, self.decorateAndRegister.bind(self));
            page.sheets.push(sheet);
        }
    }

    /**
     * Pages service object
     * @constructor
     */
    function PageService($state) {

        /**
         * $state instance
         */
        this.$state = $state;

        /**
         * Current page
         * @type {*}
         */
        this.current = undefined;
    }

    /**
     * Open page by name
     * @param name
     * @param params
     */
    PageService.prototype.open = function (name, params) {
        var page = this.get(name);
        if(!page) throw new Error('Cannot find page\'' + name + '\'');
        var destination = page.toState().name;
        return this.$state.go(destination, params);
    };
    /**
     * Perform sheet lookup
     * @param name
     * @param parent
     * @returns {*}
     */
    PageService.prototype.get = pageLookup;

    /**
     * Close current page
     */
    PageService.prototype.close = function () {
        var navigateTo = getFirstNotAbstractParent(this.current);
        this.$state.go(navigateTo.toState().name);
    };

    /**
     * Inherit child from parent
     * @param parent
     * @param child
     */
    function inherits(parent, child) {

        if (!parent || !child) throw 'Argument null';

        var F = function () {
        };
        F.prototype = parent.prototype;
        child.prototype = new F();
        child.prototype.constructor = child;
        child.superClass = parent.prototype;
    }

    /**
     * Returns first non-abstract parent
     * @param current
     * @returns {*}
     */
    function getFirstNotAbstractParent(current) {
        if (!current.parent.config.abstract) return current.parent;
        return getFirstNotAbstractParent(current.parent);
    }

    /**
     * Perform lookup
     * @param name
     * @param rootPage
     * @returns {*}
     */
    function pageLookup(name, rootPage) {
        rootPage = rootPage || angular.extend(new routing.Page({}), {
            name: '',
            sheets: routing.pages
        });
        if (rootPage.toState().name === name) return rootPage;
        for (var i = 0, len = rootPage.sheets.length; i < len; ++i) {
            var sheet = pageLookup(name, rootPage.sheets[i]);
            if (sheet) return sheet;
        }
    }

    /**
     * Sheet tabs factory object
     * @constructor
     */
    function SheetTabsFactory() {
    }

    /**
     * Create sheet menu for page
     * @param page
     * @returns {Array}
     */
    SheetTabsFactory.prototype.create = function (page) {
        var sheets = [];
        page.parent && page.parent.sheets && angular.forEach(page.parent.sheets, function (sheet) {
            if (sheet.tabVisible !== false){
                sheets.push({
                    title: sheet.config.tabTitle || sheet.config.title || sheet.config.name,
                    name: sheet.toState().name
                });
            }
        });
        return sheets;
    };

    /**
     * Navigation pages (top level manu)
     * @type {Array}
     */
    var navPages = [];
    routing.module = angular.module('flipto.routing', ['ui.router', /*'ngAnimate', */'pascalprecht.translate', 'flipto.core.api.user', 'flipto.core.api.property', 'flipto.core.lodash', 'flipto.core.company', 'flipto.components.common']);
    routing.PageProvider = ['$stateProvider', PageProvider];
    routing.PageService = ['$state', PageService];
    routing.module.config(['$provide', function ($provide) {
        $provide.provider('page', routing.PageProvider);
        $provide.constant('navPages', navPages);
        $provide.factory('sheetTabs', ['page', function (page) {
            var factory = new SheetTabsFactory();
            return function (sheet) {
                var tabsForSheet = angular.isString(sheet) ? page.get(sheet) : (sheet || page.current);
                return factory.create(tabsForSheet);
            }
        }]);
    }]);
    routing.module.run(['$rootScope', '$injector', '$state', 'page', 'IdentityService', "$transitions", function ($rootScope, $injector, $state, page, identity, $transitions) {

        /**
         * Update navPages
         */
        $rootScope.$watchCollection(function () {
            if (identity.isAnonymous()) return [];
            return routing.pages;
        }, function (pages) {
            if (!angular.isDefined(pages)) return;
            navPages.splice(0, navPages.length);
            angular.forEach(createNavPages(pages), function (page) {
                navPages.push(page);
            });
        });

        $rootScope.$on('flipto.user-logged-in', function () {
            navPages.splice(0, navPages.length);
            angular.forEach(createNavPages(routing.pages), function (page) {
                navPages.push(page);
            });
        });

        $rootScope.$on('flipto.user-logged-out', function () {
            navPages.splice(0, navPages.length);
            angular.forEach(createNavPages(routing.pages), function (page) {
                navPages.push(page);
            });
        });

        function createNavPages(pages) {
            var navPages = [];
            if (!angular.isDefined(pages)) return navPages;
            angular.forEach(pages, function (page) {
                if (!page.visible) return;
                if (!identity.isAnonymous() && page.config.userType === identity.getUser().type) {
                    var stateToBeActivated = page.toState().name;
                    navPages.push({
                        title: page.config.title || page.config.name,
                        name: stateToBeActivated
                    });
                }
            });
            return navPages;
        }

        $transitions.onStart({}, function ($t) {
            var changedToPage = page.get($t.to().name);
            if (!!changedToPage) {
                page.current = changedToPage;
            }
        });

        $transitions.onSuccess({}, function ($t) {
            var changedToPage = page.get($t.to().name);
            if (!!changedToPage) {
                $rootScope.$broadcast('page.open', page.current);
            }
        });


    }]);

})(angular);