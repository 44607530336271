import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

export class VisibilityChanges {
    constructor (public id: string, public isVisible: boolean) { }
}

@Injectable({ providedIn: 'root' })
export class ModalService {
    modals: string[] = [];

    onVisibilityChanges$ = new Subject<VisibilityChanges>();
    onVisibilityChanges = this.onVisibilityChanges$.asObservable();

    onModalRequested$ = new Subject<string>();
    onModalRequested = this.onModalRequested$.asObservable();

    add(id: string) {
        if (this.modals.find(m => m == id)) { return; }
        this.modals.push(id);
    }

    remove(id: string) {
        const idx = this.modals.findIndex(m => m == id);
        if (idx < 0) { return; }
        this.hide(id);
        this.modals.splice(idx, 1);
    }

    show(id: string) {
        const modal = this.modals.find(m => m == id);
        if (!!modal) {
            this.onVisibilityChanges$.next(new VisibilityChanges(id, true));
        } else {
            this.onModalRequested$.next(id);
        }
    }

    hide(id: string) {
        const modal = this.modals.find(m => m == id);
        if (!modal) { return; }
        this.onVisibilityChanges$.next(new VisibilityChanges(modal, false));
    }
}
