<ft-modal-editor-wrapper>
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
    <ng-container *ngIf="value">
        <div class="ft2-schedule-editor ft2-form-group flex f-md-wrap">
            <div class="row">
                <div class="col-12 pr-2">
                    <div class="d-flex full-width pt-1">
                        <div class="fd-column">
                            <div class="uppercase tiny-text muted-text bold-text">From</div>
                            <input class="ft2-input -no-border pl-0 pb-2"
                                   [(ngModel)]="value.startDate"
                                   placeholder="Start date"
                                   readonly
                                   datepickerClass="ft-daterange-picker-calendar"
                                   ngbDatepicker
                                   firstDayOfWeek="7"
                                   (click)="dpStart.open()"
                                   #dpStart="ngbDatepicker"
                                   [dayTemplate]="t"
                                   [navigation]="'arrows'">
                        </div>
                        <div class="fd-column">
                            <div class="uppercase tiny-text muted-text bold-text">To</div>
                            <input class="ft2-input -no-border pl-0 pb-2"
                                   [(ngModel)]="value.endDate"
                                   placeholder="End date"
                                   readonly
                                   datepickerClass="ft-daterange-picker-calendar"
                                   ngbDatepicker
                                   firstDayOfWeek="7"
                                   (click)="dpEnd.open()"
                                   #dpEnd="ngbDatepicker"
                                   [dayTemplate]="t"
                                   [navigation]="'arrows'">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <input [(ngModel)]="value.rRule"
               name="rRule"
               class="ft2-input -large mb-1"
               placeholder="rRule"
               required>
    </ng-container>
</ft-modal-editor-wrapper>
<ng-template #t let-date let-focused="focused" let-disabled="disabled">
          <span class="custom-day"
                [class.disabled]="disabled"
                [class.focused]="focused">
            {{ date.day }}
          </span>
</ng-template>
