/**
 * Created by roman.kupin on 12/4/2017.
 */

import * as InitialLetters from "./initial-letters/initialLetters.filter";
import * as JoinWith from "./join-with/joinWith.filter";


declare let angular;


export default angular.module("AccountApp.v2.Filters", [])
    .filter(InitialLetters.NAME, InitialLetters.FACTORY)
    .filter(JoinWith.NAME, JoinWith.FACTORY);