import { Action } from '@ngrx/store';
import { Company, Property } from 'account-hybrid/core/authentication';
import { SpacesHistoryItem } from '../..';

export const enum SpacesActionTypes {
    SetIsVisible = '[Spaces] Set isVisible',
    SetIsCancelVisible = '[Spaces] Set isCancelVisible',
    Initialize = '[Spaces] Initialize',
    SearchCriteriaChanged = '[Spaces] Search criteria changed',
    FetchNextPage = '[Spaces] Fetch next page',
}

export class Initialize implements Action {
    readonly type = SpacesActionTypes.Initialize;

    constructor(public payload: { companies: Company[], properties: Property[], history: SpacesHistoryItem[] }) {
    }
}

export class SearchCriteriaChanged implements Action {
    readonly type = SpacesActionTypes.SearchCriteriaChanged;

    constructor(public payload: string) {
    }
}

export class SetIsVisible implements Action {
    readonly type = SpacesActionTypes.SetIsVisible;

    constructor(public payload: boolean) {
    }
}

export class SetIsCancelVisible implements Action {
    readonly type = SpacesActionTypes.SetIsCancelVisible;

    constructor(public payload: boolean) {
    }
}
export class FetchNextPage implements Action {
    readonly type = SpacesActionTypes.FetchNextPage;
}

export type SpacesActions = Initialize
    | SearchCriteriaChanged
    | SetIsVisible
    | SetIsCancelVisible
    | FetchNextPage;
