<ft-modal-editor-wrapper>
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
    <div class="ft2-form-group">
        <label>
            Label
        </label>
        <ng-container *ngFor="let model of value">
            <div class="ft2-input-container" [ngClass]="{'-pure': !model?.[bindLabel]}">
                <div class="d-flex f-nowrap v-center">
                    <input class="ft2-input -large"
                           #control="ngModel"
                           maxlength="50"
                           required
                           [ngClass]="{'-pure': model?.[bindLabel]}"
                           [(ngModel)]="model[bindLabel]">
                    <span class="ft2-input-language" *ngIf="model?.languageName && value?.length > 1">
                            {{ model.languageName }}
                        </span>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="ft2-form-group">
        <label>
            Summary
        </label>
        <ng-container *ngFor="let model of value">
            <div class="ft2-input-container" [ngClass]="{'-pure': !model?.[bindSummary]}">
                <div class="d-flex f-nowrap v-center">
                    <input class="ft2-input -large"
                           #controlsModelRef="ngModel"
                           maxlength="100"
                           required
                           [ngClass]="{'-pure': model?.[bindSummary]}"
                           [(ngModel)]="model[bindSummary]">
                    <span class="ft2-input-language" *ngIf="model?.languageName && value?.length > 1">
                            {{ model.languageName }}
                        </span>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="ft2-form-group">
        <label>
            Extra info (Optional)
        </label>
        <ng-container *ngFor="let model of value">
            <div class="ft2-input-container" [ngClass]="{'-pure': !model?.[bindDescription]}">
                <div class="d-flex f-nowrap v-center">
                       <textarea class="ft2-textarea"
                                 placeholder="Optional"
                                 #controlsModelRef="ngModel"
                                 maxlength="400"
                                 [ngClass]="{'-pure': model?.[bindDescription]}"
                                 [(ngModel)]="model[bindDescription]">
                             </textarea>
                    <span class="ft2-input-language" *ngIf="model?.languageName && value?.length > 1">
                            {{ model.languageName }}
                        </span>
                </div>
            </div>
        </ng-container>
    </div>
</ft-modal-editor-wrapper>
