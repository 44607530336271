import {
    ExperimentStats,
    ExperimentStatsResponse
} from "../models/experiment-stats.model";


class ExperimentStatsFactory {
    createStats(stats: ExperimentStatsResponse, percentage: number): ExperimentStats {
        const type = ExperimentStatsTypes[stats.type];
        const total_book_clicks = parseFloat(stats.total_book_clicks);
        const total_website_visitors = parseFloat(stats.total_website_visitors);
        const total_website_unveiled = parseFloat(stats.total_website_unveiled);
        const total_bookings = parseFloat(stats.total_bookings);
        const total_revenue = parseFloat(stats.total_revenue);
        // extra
        const visitors_percentage = Math.round(percentage);
        const total_visitors = total_website_visitors;
        const total_unveiled = total_website_unveiled;
        const unveiled_percentage = this.avoidInfinity(total_website_unveiled / total_book_clicks * 100 || 0);
        const booking_percentage = this.avoidInfinity(total_bookings / total_visitors * 100 || 0);

        // revenue
        const projected_bookings_per_thousand_visitors = this.avoidInfinity(total_bookings / total_visitors * 1000);
        const avg_transactions_value = this.avoidInfinity(total_revenue / total_bookings);
        const projected_revenue_per_thousand_visitors = this.avoidInfinity(total_revenue / total_visitors * 1000);
        const projected_revenue = this.avoidInfinity(total_revenue / (visitors_percentage / 100));
        return {
            type,
            total_book_clicks,
            total_website_visitors,
            total_website_unveiled,
            total_bookings,
            total_revenue,
            visitors_percentage,
            total_visitors,
            total_unveiled,
            unveiled_percentage,
            booking_percentage,
            projected_bookings_per_thousand_visitors,
            avg_transactions_value,
            projected_revenue_per_thousand_visitors,
            projected_revenue
        };
    }

    avoidInfinity(value) {
        return value === Infinity ? 0 : value;
    }

    public createStatsArray(stats: ExperimentStatsResponse[]): ExperimentStats[] {
        const total_visitors = stats.reduce((prev, currentStats) => {
            return prev + parseFloat(currentStats.total_website_visitors);
        }, 0);

        return stats.map((item: ExperimentStatsResponse) => {
            const percentage = parseFloat(item.total_website_visitors) / total_visitors * 100;
            return this.createStats(item, percentage);
        });
    }
}

export enum ExperimentStatsTypes {
    control = 'Control',
    flipto = 'Variant'
}

export const experimentStatsFactory = new ExperimentStatsFactory();
