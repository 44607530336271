/**
 * Created by roman.kupin on 02/07/2014.
 */


angular
    .module('flipto.core.storage', [])
    .service('localStorage',
    [
        function () {

            /**
             * Local storage creation
             * @constructor
             */
            function LocalStorage() {

                this.storage_ = localStorage;
            }

            /**
             * Additional functionality
             * @type {{constructor: Function, put: Function, get: Function, delete: Function}}
             */
            LocalStorage.prototype = {

                constructor: LocalStorage,

                /**
                 * Put data into storage
                 * @param key
                 * @param data
                 */
                put: function (key, data) {
                    this.storage_.setItem(key, JSON.stringify(data));
                },

                /**
                 * Get data from storage
                 * @param key
                 */
                get: function (key) {
                    var item = this.storage_.getItem(key);
                    return item && JSON.parse(item) || null;
                },

                /**
                 * Remove data from storage
                 * @param key
                 */
                remove: function (key) {
                    this.storage_.removeItem(key);
                }
            };


            return new LocalStorage();
        }
    ]);

