export class DiscoveryComponentPlannerFlowViewModel {
  defaultStayLength: number;
  logoUrl: string;
  defaultRooms: number;
  defaultAdults: number;
  defaultChildren: number;
  defaultPickerMode: number; // enum
  onLoadJs: string;
  emailLinkCustomParameters: string;
  openingDate: Date;
  maxAdults: number;
  minAdults: number;
  maxChildren: number;
  minChildren: number;
  maxOccupancyPerRoom: number;
  isHeadcountStepEnabled: boolean;
  headcountStepDisclosure: string;
  maxNights: number;
  minNights: number;
  maxRooms: number;
  minRooms: number;
  signatureText: string;
  introGreetingText: string;
}
