/**
 * User: Kupin.R
 * Date: 6/3/14
 */



angular
    .module("flipto.components.incentives")
    .filter("incentiveName",
        [
            "$filter", "_",
            function ($filter, _) {

                var translate = $filter("translate"),
                    capitalize = $filter("capitalize"),
                    notAvailableMessage = translate("flipto.components.incentives.IncentiveDescriptionIsNotAvailable"),
                    newLineTagsRegExp = new RegExp("<(p|br)[^><]*>|<\/(p|br)[^><]*>", "gm"),
                    defaultCulture = "en";

                function getIncentiveName(incentive, culture) {
                    if (!incentive) return notAvailableMessage;
                    var name = getByCultureOrDefault(incentive, culture) ||
                        getFirst(incentive) ||
                        getNotAvailableMessage(incentive);
                    name = name.replace(newLineTagsRegExp, '');
                    return capitalize(name, true);
                }

                function getByCultureOrDefault(incentive, culture) {
                    return getByCulture(incentive, culture) || getByCulture(incentive, defaultCulture);
                }

                function getFirst(incentive) {
                    if (incentive.languages) {
                        var name = _.head(incentive.languages.item);
                        if (name) {
                            return name.formalText;
                        }
                    }
                }

                function getNotAvailableMessage() {
                    return notAvailableMessage;
                }

                function getByCulture(incentive, culture) {
                    if (incentive.languages) {
                        var code = !!culture ? culture.code : defaultCulture;
                        var name = _.find(incentive.languages.item, function (language) {
                            return language && language.code == code;
                        });
                        if (name) {
                            return name.formalText;
                        }
                    }
                }

                return function (incentive, culture) {
                    return getIncentiveName(incentive, culture);
                };
            }
        ]);
