
import { Injectable } from '@angular/core';
import { SpacesService } from '../../spaces';
import { loadCurationModalData } from '../store/actions';
import { select, Store } from '@ngrx/store';
import { selectCurationModalData } from '../store';
import { CurationState } from '../store/curation.state';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CurationModalData } from '../models/curation.model';
import { Parse } from 'shared';
import { StoryModel } from 'account-hybrid/features/stories/models/stories-library.model';

@Injectable({
  providedIn: 'root'
})
export class CurationHandlerService {
  isCurationDone$ = new Subject<boolean>();
  storiesIsCuration$: Observable<StoryModel[]>;

  constructor(
    private spacesService: SpacesService,
    private store: Store<CurationState>
  ) { }

  getNotCurated(stories) {
    return stories.filter(item => {
      return item?.isCurated !== true;
    });
  }

  getTotal(storiesCollection) {
    if ( !!storiesCollection && storiesCollection.length > 0 ){
      const curated = storiesCollection.filter(story => {
        return story?.isCurated;
      })?.length || 0;

      return storiesCollection[0].totalCount - curated;
    }

    return 0;
  }

  getTotalNotCurated(storiesCollection) {
    if ( !storiesCollection || !storiesCollection.length ) return 0;
    const curated = storiesCollection.filter(item => {
      return item?.isCurated === true;
    });

    return storiesCollection[0].totalCount - curated?.length;
  }

  isCurated(data: CurationModalData) {
    let isCurated;
    if (this.hasPhoto(data)) {
        isCurated = this.isNotReviewed(data?.guest?.poststay?.photo)
          || (Parse.bool(data?.guest?.poststay?.photo?.isPassed) || Parse.bool(data?.guest?.poststay?.photo?.isFinalist));
        isCurated = isCurated && !this.isNotDefined(data?.guest?.poststay?.photo?.internalInfo?.rating);

        if (this.hasCaption(data)) {
          isCurated = isCurated && !this.isNotDefined(data?.guest?.poststay?.photo?.internalInfo?.captionRating);
        }

        if (this.hasQuote(data) && Parse.bool(data?.guest?.poststay?.quote?.isPositive)) {
          isCurated = isCurated && !this.isNotDefined(data?.guest?.poststay?.quote?.internalInfo?.rating);
        }

      this.isCurationDone$.next(isCurated)
      return isCurated;
    } else if (this.hasQuote(data)) {
      isCurated = Parse.bool(data?.guest?.poststay?.quote?.isPositive) && !this.isNotDefined(data?.guest?.poststay?.quote?.internalInfo?.rating);
      this.isCurationDone$.next(isCurated);
      return isCurated;
    }

    this.isCurationDone$.next(false);
    return false;
  }

  hasPhoto(data: CurationModalData): boolean {
    return !!data?.guest?.poststay.photo;
  }

  isNotReviewed(photo) {
    return (photo.photoContest.isClosed) && !(Parse.bool(photo.isPassed) || Parse.bool(photo.isFinalist));
  }

  isNotDefined(data): boolean {
    return data === null || typeof data === 'undefined';
  }

  hasCaption(data: CurationModalData) {
    return data.guest.poststay.photo.caption.length > 0;
  }

  hasQuote(data: CurationModalData) {
    return data?.guest?.poststay?.quote?.notesPositive;
  }
}
