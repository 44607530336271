/**
 * Created by ostapenko.r on 2/6/2015.
 */
angular.module('flipto.components.common.listEditor')
    .directive('ftClearAfterAdd', ['$timeout', function ($timeout) {
        return {
            restrict: 'A',
            require: ['^ftListEditor', 'ngModel'],
            link: function (scope, element, attrs, controllersArr) {
                var ngModel = controllersArr[1];
                scope.$on('list-editor.added-to-list', function(e, item){
                    ngModel.$setViewValue('');
                    ngModel.$render();
                    $timeout(function() {
                        element[0].focus();
                    }, 0, false);
                });

            }
        };
    }]);