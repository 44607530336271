/**
 * Created by Roman on 2015-04-20.
 */

angular.module('flipto.accountv1.SharedFeatures')
    .controller('flipto.account.sections.people.AdvocatesDetailsController',
        [
            'advocateUUID', 'Advocates', 'curationModal', '$filter', 'parse', 'Certificate', '$window', '$state', '$rootScope', 'ftTasks', 'ftModal', 'moment', 'curate', 'SessionService', 'SpacesService', 'IdentityService', "environment",
            function (advocateUUID, Advocates, curationModal, $filter, parse, Certificate, $window, $state, $rootScope, ftTasks, ftModal, moment, curate, sessionService, spacesService, identity, environment) {

                $rootScope.$broadcast("updateSheetTitle", true);

                var vm = this;
                vm.environment = environment;
                vm.identity = identity;
                vm.isLoading = true;
                vm.timestamp = new Date().getTime();
                vm.deleteReasons = [{ value: "Guest canceled reservation" }, { value: "Test user" }, { value: "Other" }];
                var ftAzureStoredPhoto = $filter('ftAzureStoredPhoto'),
                    translate = $filter('translate');

                /**
                 * Returns true if hotel stay is available
                 * @returns {boolean}
                 */
                vm.isHotelStayAvailable = function () {
                    return vm.advocate && !!(vm.advocate.epochStartDate) && !!(vm.advocate.epochEndDate);
                };

                vm.isInvitee = function () {
                    var guest = vm.advocate;
                    return guest && guest.logTypeId == 11;
                };

                /**
                 * Returns true if prestay is available
                 * @returns {boolean}
                 */
                vm.isPrestayAvailable = function () {
                    return vm.advocate && !!(vm.advocate.prestay);
                };

                /**
                 * Returns true if poststay is available
                 * @returns {boolean}
                 */
                vm.isPoststayAvailable = function () {
                    return vm.advocate && !!(vm.advocate.poststay);
                };

                /**
                 * Returns true if photo is finalist
                 * @returns {boolean}
                 */
                vm.isPhotoContestAvailable = function () {
                    return vm.advocate && !!(vm.advocate.photoContest) && vm.isPoststayAvailable() && !!(vm.advocate.poststay.photo) && parse.bool(vm.advocate.poststay.photo.isFinalist);
                };

                /**
                 * Show advocates list page
                 */
                vm.showAdvocates = function () {
                    $state.go("^");
                };

                /**
                 * Open curation modal
                 */
                vm.curate = function () {
                    curationModal.new(sessionService.getProperty(spacesService.getPropertyUuid()), advocateUUID, false);
                };

                /**
                 * Send certificate
                 * @param incentive
                 */
                vm.sendCertificate = function (incentive) {
                    return Certificate.send(spacesService.getPropertyUuid(), advocateUUID, incentive.voucherUUID)
                        .then(function () {
                            $window.alert(translate('flipto.account.SendCertificateSuccessfull'));
                        }, function () {
                            $window.alert(translate('flipto.account.SendCertificateFailed'));
                        })
                        .finally(function () {
                            ftTasks.finish('advocate-details.send-certificate.' + incentive.voucherUUID);
                        });
                };

                /**
                 * Edit incentive expiration date
                 * @param incentive
                 */
                vm.editCertificateExpirationDate = function (incentive) {
                    vm.selectedIncentive = incentive;
                    ftModal.show('advocateEditCertificateExpirationDate');
                };

                /**
                 * Save certificate expiration date
                 */
                vm.saveCertificateExpirationDate = function () {
                    Certificate.update(spacesService.getPropertyUuid(), advocateUUID, vm.selectedIncentive.voucherUUID, { ExpirationDate: vm.selectedIncentive.utcExpirationDate })
                        .then(function () {
                            vm.selectedIncentive.isExpired = moment(vm.selectedIncentive.utcExpirationDate).isBefore(moment().utc());
                        });
                };

                curationModal
                    .on('photo-updated', function () {
                        vm.loadDetails();
                    })
                    .on('finalist', function () {
                        vm.loadDetails();
                    });

                /**
                 * Load details
                 * @returns {*}
                 */
                vm.loadDetails = function () {
                    return Advocates.details(spacesService.getPropertyUuid(), advocateUUID)
                        .then(function (data) {
                            vm.advocate = data;
                            vm.isLoading = false;
                            if (vm.advocate.poststay && vm.advocate.poststay.photo) {
                                vm.advocate.poststay.photo.photoUrl = ftAzureStoredPhoto(vm.advocate.poststay.photo, 'small') + `?timestamp=${new Date().getTime()}`;
                            }

                            if (vm.advocate.photoContest) {
                                angular.forEach(vm.advocate.photoContest.photos, function (photo) {
                                    photo.photoUrl = ftAzureStoredPhoto(photo, 'small') + `?timestamp=${new Date().getTime()}`;
                                });
                            }

                            vm.advocate.startDate = moment.unix(vm.advocate.epochStartDate / 1000);
                            vm.advocate.endDate = moment.unix(vm.advocate.epochEndDate / 1000);

                            correctForTimezone(false);

                            vm.advocate.reasonDeleted = {};

                            if (!vm.advocate.profileImageUrl) {
                                vm.advocate.profileImageUrl = "";
                            }

                        }, vm.showAdvocates);
                };

                vm.loadDetails()
                    .then(function () {
                        var isAnyPiiDataAvailable = !!vm.advocate.reservationNumber || !!vm.advocate.email || !!vm.advocate.twitter;
                        vm.isPiiInfoVisible = !isAnyPiiDataAvailable;

                        if (curate) {
                            vm.curate();
                        }
                    });

                vm.showPiiInfo = function () {
                    vm.isPiiInfoVisible = true;
                    $rootScope.$broadcast("updateSheetTitle", {isPiiInfoVisible: vm.isPiiInfoVisible});
                };

                vm.save = function () {
                    var dates = {};
                    if (vm.advocate.epochStartDate > 0) {
                        correctForTimezone(true);

                        vm.advocate.epochStartDate = vm.advocate.startDate.valueOf();
                        vm.advocate.epochEndDate = vm.advocate.endDate.valueOf();

                        dates.StartDate = vm.advocate.startDate;
                        dates.EndDate = vm.advocate.endDate;
                    }

                    return Advocates.save(spacesService.getPropertyUuid(), vm.advocate.guestUUID, _.assign({
                        FirstName: vm.advocate.first,
                        LastName: vm.advocate.last,
                        Email: vm.advocate.email,
                        ProfileImageUrl: vm.removingProfileImage ? "" : vm.advocate.profileImageUrl,
                        PersonUUID: vm.advocate.guestUUID
                    }, dates))
                        .then(function (response) {
                            $rootScope.$broadcast("updateSheetTitle");
                            if (vm.removingProfileImage) {
                                vm.advocate.profileImageUrl = "";
                            }
                            return response;
                        }, function (response) {
                            return response;
                        });
                };

                vm.cancelReservation = function () {
                    var flagObject = {};

                    if (vm.advocate.reasonDeleted.value == vm.deleteReasons[0].value) {
                        flagObject.IsCancelled = true;
                    } else if (vm.advocate.reasonDeleted.value == vm.deleteReasons[1].value) {
                        flagObject.IsTest = true;
                    }

                    return Advocates.delete(spacesService.getPropertyUuid(), vm.advocate.guestUUID, _.assign({
                        DeleteReason: vm.advocate.reasonDeleted.value
                    }, flagObject))
                        .then(function (response) {
                            vm.goToDirectory();
                            $rootScope.$broadcast("reservationCanceled");
                            return response;
                        }, function (response) {
                            return response;
                        });
                }

                vm.goToDirectory = function () {
                    $state.go("flipto.property.overview.people.directory");
                }

                vm.hideCancelModal = function () {
                    ftModal.hide("people.details.cancelReservation");
                    vm.advocate.reasonDeleted = {};
                }

                vm.saveCanceled = function () {
                    vm.advocate.startDate = moment.unix(vm.advocate.epochStartDate / 1000);
                    vm.advocate.endDate = moment.unix(vm.advocate.epochEndDate / 1000);
                    vm.removingProfileImage = false;
                }

                vm.requestPromotionCertificate = function(){
                    Advocates.requestPromotionCertificate(vm.advocate.companyCode, vm.advocate.guestUUID);
                };

                function correctForTimezone(save) {
                    var timeOffset = new Date().getTimezoneOffset();

                    if (timeOffset > 0) {
                        vm.advocate.startDate.add(timeOffset, "m");

                        if (save) {
                            vm.advocate.endDate.subtract(timeOffset, "m");
                        } else {
                            vm.advocate.endDate.add(timeOffset, "m");
                        }
                    } else if (timeOffset < 0) {
                        vm.advocate.startDate.subtract(timeOffset, "m");

                        if (!save) {
                            vm.advocate.endDate.subtract(timeOffset, "m");
                        }
                    }
                }
            }
        ]);
