/**
 * Created by roman.kupin on 1/30/2018.
 */


export enum PrestayTouchpoints {
    ConfirmationEmailImage,
    Confirmation,
    Followup,
    Landing,
    Mobile
}

export enum PrestayTouchpointsNames {
    ConfirmationEmailImage = "confirmation-email-image",
    Confirmation = "confirmation",
    Followup = "followup",
    Landing = "landing",
    Mobile = "mobile"
}

export enum PoststayTouchpoints {
    InvitationEmail,
    PoststayProcess,
    ReengagementEmails,
    ReminderEmails
}

export enum PoststayTouchpointsNames {
    InvitationEmail = "invitation-email",
    PoststayProcess = "quote",
    ReengagementEmails = "reengagement-emails",
    ReminderEmails = "reminder-emails"
}

export enum PhotoAdvocacyTouchpoints {
    PhotoExplorer,
    FinalistsEmail,
    ReminderEmail,
    ActivityEmail,
    WinnerEmail,
    BannerForPrearrivalEmail,
    BannerForPoststayEmail,
    PhotoRiver
}

export enum PhotoAdvocacyTouchpointsNames {
    PhotoExplorer = "contestPage",
    FinalistsEmail = "finalistsEmail",
    ReminderEmail = "reminderEmail",
    ActivityEmail = "activityEmail",
    WinnerEmail = "winnerEmail",
    BannerForPrearrivalEmail = "pre-arrival-email",
    BannerForPoststayEmail = "poststay-email-image",
    PhotoRiver = "photo-river"
}

export enum QuoteListTouchpoints {
    List
}

export enum QuoteListTouchpointsNames {
    List = "list"
}

export enum HomepageTouchpoints {
    Homepage,
    HomepageEmail
}

export enum HomepageTouchpointsNames {
    Homepage = "homepage",
    HomepageEmail = "homepageEmail"
}

export const MESSAGING_TAB = "messaging";
export const STYLING_TAB = "styling";
export const INTEGRATION_TAB = "integration";
export const ALL_TABS = [MESSAGING_TAB, STYLING_TAB, INTEGRATION_TAB];
export const MESSAGING_STYLING_TABS = [MESSAGING_TAB, STYLING_TAB];
export const STYLING_INTEGRATION_TABS = [STYLING_TAB, INTEGRATION_TAB];

export const SUPPORTED_TABS = {};
SUPPORTED_TABS[PrestayTouchpointsNames.Confirmation] = ALL_TABS;
SUPPORTED_TABS[PrestayTouchpointsNames.Landing] = ALL_TABS;
SUPPORTED_TABS[PrestayTouchpointsNames.Mobile] = MESSAGING_STYLING_TABS;
SUPPORTED_TABS[PrestayTouchpointsNames.Followup] = MESSAGING_STYLING_TABS;
SUPPORTED_TABS[PrestayTouchpointsNames.ConfirmationEmailImage] = MESSAGING_STYLING_TABS;

SUPPORTED_TABS[PoststayTouchpointsNames.PoststayProcess] = ALL_TABS;
SUPPORTED_TABS[PoststayTouchpointsNames.InvitationEmail] = MESSAGING_STYLING_TABS;
SUPPORTED_TABS[PoststayTouchpointsNames.ReminderEmails] = MESSAGING_STYLING_TABS;
SUPPORTED_TABS[PoststayTouchpointsNames.ReengagementEmails] = MESSAGING_STYLING_TABS;

SUPPORTED_TABS[PhotoAdvocacyTouchpointsNames.PhotoExplorer] = STYLING_INTEGRATION_TABS;
SUPPORTED_TABS[PhotoAdvocacyTouchpointsNames.FinalistsEmail] = MESSAGING_STYLING_TABS;
SUPPORTED_TABS[PhotoAdvocacyTouchpointsNames.ReminderEmail] = MESSAGING_STYLING_TABS;
SUPPORTED_TABS[PhotoAdvocacyTouchpointsNames.ActivityEmail] = MESSAGING_STYLING_TABS;
SUPPORTED_TABS[PhotoAdvocacyTouchpointsNames.WinnerEmail] = MESSAGING_STYLING_TABS;
SUPPORTED_TABS[PhotoAdvocacyTouchpointsNames.BannerForPrearrivalEmail] = [STYLING_TAB];
SUPPORTED_TABS[PhotoAdvocacyTouchpointsNames.BannerForPoststayEmail] = MESSAGING_STYLING_TABS;
SUPPORTED_TABS[PhotoAdvocacyTouchpointsNames.PhotoRiver] = ALL_TABS;

SUPPORTED_TABS[HomepageTouchpointsNames.Homepage] = ALL_TABS;
SUPPORTED_TABS[HomepageTouchpointsNames.HomepageEmail] = MESSAGING_STYLING_TABS;

SUPPORTED_TABS[QuoteListTouchpointsNames.List] = STYLING_INTEGRATION_TABS;
