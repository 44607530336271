import { Directive, ElementRef, forwardRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
    selector: '[ftReplaceInvalidSymbols]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ReplaceInvalidSymbolsDirective),
            multi: true,
        },
    ]
})
export class ReplaceInvalidSymbolsDirective implements ControlValueAccessor {
    static invalidSymbolsMap = new Map<string, string>()
        .set('`', '\'')
        .set('‘', '\'')
        .set('’', '\'')
        .set('“', '"')
        .set('”', '"');

    private onChange: any = () => {};
    private onTouched: any = () => {};

    constructor(private el: ElementRef, private renderer: Renderer2) {}

    writeValue(value: any): void {
        this.renderer.setProperty(this.el.nativeElement, 'value', value);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.renderer.setProperty(this.el.nativeElement, 'disabled', isDisabled);
    }

    @HostListener('input', ['$event.target.value'])
    handleInput(value: any): void {
        this.onChange(value);
    }

    @HostListener('blur')
    handleBlur(): void {
        this.onTouched();

        // Get the current value of the input
        const currentValue = this.el.nativeElement.value;

        // Replace quotation marks (single and double)
        const newValue = ReplaceInvalidSymbolsDirective.replaceInvalidSymbols(currentValue);

        // Update the view with the new value
        this.renderer.setProperty(this.el.nativeElement, 'value', newValue);

        // Notify Angular about the change
        this.onChange(newValue);
    }

    static replaceInvalidSymbols(value: any) {
        if (typeof value === 'string' || value instanceof String) {
            return Array.from(ReplaceInvalidSymbolsDirective.invalidSymbolsMap.keys()).reduce((acc, key) => acc.replace(new RegExp('\\' + key, 'g'), ReplaceInvalidSymbolsDirective.invalidSymbolsMap.get(key)), value);
        }
        return value;
    }
}
