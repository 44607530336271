/**
 * Created by Roman on 2015-05-01.
 */



angular.module("flipto.components.common.textFormatting")
    .filter('quot', ['$sce', function ($sce) {

        return function (text) {
            if (!text) return '';
            return $sce.trustAsHtml('&quot;' + text + '&quot;');
        };
    }]);