/**
 * Created by ostapenko.r on 12/18/2014.
 */

angular.module('flipto.components.profile', ['flipto.components.common'])
    .config(['$compileProvider', function ($compileProvider) {

        $compileProvider.directive('ftProfilePhoto', [function () {
            return {
                restrict: 'A',
                require: '^ftImage',
                compile: function (tElement, tAttrs) {

                    angular.element(tElement[0].childNodes).addClass('ft-profile-pic');

                    return function (scope, elem, attrs, ftImage) {
                        var imageElement = angular.element(elem[0].childNodes);

                        if (angular.isDefined(attrs.silhouetteClass)) {
                            scope.$watchGroup([function () {
                                return ftImage.photoExist();
                            }, function () {
                                return scope.$eval(attrs.silhouetteClass);
                            }], function (values, oldValues) {
                                if (angular.isDefined(values[0])) {
                                    updateProfilePhoto(values[0], values[1], oldValues[1]);
                                }
                            });
                        }

                        function updateProfilePhoto(exist, newSilhouette, oldSilhouette) {
                            !exist && (newSilhouette && imageElement.addClass(newSilhouette));
                            exist && ((oldSilhouette && imageElement.removeClass(oldSilhouette)));
                            exist && ((newSilhouette && imageElement.removeClass(newSilhouette)));
                        }
                    }
                }
            };
        }]);
    }])