import { Action, createReducer, on } from '@ngrx/store';
import { ManagementState } from './management.state';
import {
    createAccount,
    createAccountFailure,
    createAccountSuccess,
    createProperty, createPropertyFailure, createPropertySuccess
} from './management.actions';

export const initialState: ManagementState = {
    error: undefined,
    loading: false,
    createdCompany: undefined,
    createdProperty: undefined
};

const reducer = createReducer(
  initialState,
  on(createAccount, state => {
      return {
          ...state,
          loading: true
      };
  }),
  on(createAccountSuccess, (state, { createdCompany }) => {
      return {
          ...state,
          loading: false,
          createdCompany
      };
  }),
  on(createAccountFailure, (state, { error }) => {
      return {
          ...state,
          error,
          loading: false
      };
  }),
  on(createProperty, state => {
      return {
          ...state,
          loading: true
      };
  }),
  on(createPropertySuccess, (state, { createdProperty }) => {
      return {
          ...state,
          loading: false,
          createdProperty
      };
  }),
  on(createPropertyFailure, (state, { error }) => {
      return {
          ...state,
          error,
          loading: false
      };
  })
);

export function managementReducer(state: ManagementState | undefined, action: Action) {
    return reducer(state, action);
}

