<ft-modal-wrapper>
  <ft-touchpoint-header
    name="Story Explorer"
    [canActivate]="true"
    [isActive]="(selectedStoryExplorerComponent$ | async)?.isActive == 1"
    (activeToggled)="commit($event)"
    >
  </ft-touchpoint-header>
  <mat-tab-group
    class="mat-tab-group-nav"
    mat-align-tabs="center"
    animationDuration="0ms"
    [(selectedIndex)]="selectedIndex">
    <mat-tab label="styling">
      <ng-template matTabContent>
        <ft-snapshot-form
          *ngIf="stylingStoryExplorerComponent$ | async | clone as styleData"
          [loading$]="[selectedStoryExplorerLoading$]"
          [commitSuccess$]="[commitSuccess$]"
          (commit)="onFormCommit($event, 'styling')">
          <section class="ft2-section">
            <div class="ft2-container">
              <div class="row">
                <div class="col-12">
                  <form ngForm>
                    <ng-container>
                      <ft-code-editor
                        title="CSS"
                        [(ngModel)]="styleData.advancedCss"
                        [language]="'css'"
                        name="advancedCss">
                          <ft-edit-panel label="CSS">
                            <p>{{styleData?.advancedCss || 'Click to edit'}}</p>
                          </ft-edit-panel>
                      </ft-code-editor>
                    </ng-container>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </ft-snapshot-form>
      </ng-template>
    </mat-tab>
    <mat-tab label="integration">
      <ng-template matTabContent>
        <section class="ft2" *ngIf="integrationStoryExplorerComponent$ | async | clone as integrationData">
          <form class="ft2-block"
                #form="ngForm"
                ngForm>
              <ft-loading [loading$]="selectedStoryExplorerLoading$"></ft-loading>
              <div class="ft2-container">
                <!-- Configuration section -->
                  <div class="row">
                      <div class="col-4">
                          <p class="solid-text medium-text">
                            Configuration
                          </p>
                      </div>
                      <div class="col-8">
                        <ft-list-editor
                          title="Property code"
                          name="codes"
                          [(ngModel)]="integrationData.codes.item"
                          type="text"
                          placeholder="Add code"
                          [allowDuplicates]="false"
                          [caseSensitive]="true"
                          (save)="onFormCommit($event, 'integration')">
                          <ft-edit-panel label="Property code">
                            <ng-container *ngIf="integrationData.codes.item?.length !== 0">
                              <span *ngFor="let element of integrationData.codes.item">{{element.code}} ,</span>
                            </ng-container>
                            <span *ngIf="integrationData.codes.item?.length === 0">
                              No codes have been set.
                            </span>
                          </ft-edit-panel>
                        </ft-list-editor>
                        <ft-edit-panel
                          label="Story Explorer URL"
                          secondLabel="Old URL"
                          [static]="true">
                          <a target="_blank" [href]="integrationData.subdomains | cnameUrl:1:slug" class="mr-auto">
                            <span [innerHTML]="integrationData.subdomains | cnameUrl:1:slug"></span>
                          </a>
                          <a
                            *ngIf="integrationData.languages.item[0]['landingPageUrl']"
                            target="_blank"
                            [href]="integrationData.languages.item[0]['landingPageUrl']"
                            secondLabel>
                            <span [innerHTML]="integrationData.languages.item[0]['landingPageUrl']"></span>
                          </a>
                        </ft-edit-panel>
                        <ft-toggle-editor name="isBookNowActive" [ngModel]="integrationData.isBookNowActive === '1'" title="Book now" label="Show &ldquo;book now&rdquo; button in navigation">
                          <ft-edit-panel label="Show &ldquo;book now&rdquo; button in navigation">
                            {{integrationData.isBookNowActive === '1' ? 'On' : 'Off'}}
                          </ft-edit-panel>
                        </ft-toggle-editor>
                        <ft-toggle-editor name="isFooterActive" [ngModel]="integrationData.isFooterActive === '1'" title="Footer" label="Show footer">
                          <ft-edit-panel label="Show footer">
                            {{integrationData.isFooterActive === '1' ? 'On' : 'Off'}}
                          </ft-edit-panel>
                        </ft-toggle-editor>
                        <ft-edit-panel label="Flip.to account code" [readOnly]="true">
                          <p>{{integrationData.companyCode}}</p>
                        </ft-edit-panel>
                      </div>
                  </div>
            </div>
          </form>
        </section>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</ft-modal-wrapper>
