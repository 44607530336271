import { createAction, props } from '@ngrx/store';
import { PlannerflowSettings } from "account-hybrid/common/components/touchpoints/models/plannerflow-settings";

export const load = createAction('[Discovery Plannerflow] load',
    props<{
        componentGroupUuid: string
    }>());

export const loadSuccess = createAction('[Discovery Plannerflow] loadSuccess', props<{ data: PlannerflowSettings }>());
export const loadFail = createAction('[Discovery Plannerflow] loadFail', props<{ error: any }>());

export const commit = createAction('[Discovery Plannerflow] commit',
    props<{
        componentGroupUuid: string,
        data: Partial<PlannerflowSettings>
    }>());
export const commitSuccess = createAction('[Discovery Plannerflow] commitSuccess', props<{ data: PlannerflowSettings }>());
export const commitFail = createAction('[Discovery Plannerflow] commitFail', props<{ error: any }>());
