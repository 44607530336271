<div class="timerange ft2-form-group">
    <div *ngIf="fromLabel && toLabel" class="flex full-width">
        <label class="ft2-form-group-label full-width">{{ fromLabel }}</label>
        <label class="ft2-form-group-label full-width">{{ toLabel }}</label>
    </div>
    <div class="flex full-width timepickers" [class.-divider]="divider">
        <ng-template #fromTimeTemplate>
            <ngb-timepicker class="ft-timerange-picker-popup" [seconds]="seconds" [(ngModel)]="ngbFromTime" (ngModelChange)="onRangeSelected()"></ngb-timepicker>
        </ng-template>
        <input class="ft2-input -large -square -no-border"
               name="fromTime"
               [(ngModel)]="fromTime"
               [ngbPopover]="fromTimeTemplate"
               [autoClose]="'outside'"
               placement="bottom-start"
               [triggers]="'click'"
               [placeholder]="fromPlaceholder"
               readonly>

        <ng-template #toTimeTemplate>
            <ngb-timepicker class="ft-timerange-picker-popup" [seconds]="seconds" [(ngModel)]="ngbToTime" (ngModelChange)="onRangeSelected()"></ngb-timepicker>
        </ng-template>
        <input class="ft2-input -large -square -no-border"
               name="toTime"
               [(ngModel)]="toTime"
               [ngbPopover]="toTimeTemplate"
               [autoClose]="'outside'"
               placement="bottom-start"
               [triggers]="'click'"
               [placeholder]="toPlaceholder"
               readonly>
    </div>
</div>
