import { Component, forwardRef, Injectable, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';
import { datetime, Frequency, RRule } from 'rrule';
import { NgbDate, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { dateToNgbDate, NgbCustomAdapter } from '../../../../../classes/custom-ngb-adapter';
import { NgbCustomFormatter } from '../../../../../classes/custom-ngb-formatter';


@Component({
    selector: 'ft-schedule-editor',
    templateUrl: './schedule-editor.component.html',
    styleUrls: ['./schedule-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ScheduleEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: ScheduleEditorComponent
        },
        {
            provide: NgbDateAdapter,
            useClass: NgbCustomAdapter
        },
        {
            provide: NgbDateParserFormatter,
            useClass: NgbCustomFormatter
        }
    ]
})
export class ScheduleEditorComponent extends ModalEditorBase implements OnInit {
    rules: RRule[];
    rruleFrequency = Frequency;

    test = new NgbDate(2022, 8, 1);

    ngOnInit(): void {
        this.rules = [
            new RRule({
                freq: this.rruleFrequency.DAILY,
                dtstart: datetime(2023, 8, 29),
                until: datetime(2023, 9, 30)
            })
        ];
    }

    onChange(rule, index, prop, value) {
        this.rules[index] = new RRule({
            ...rule.options,
            [prop]: value
        });
    }

    toNgbDate(date: Date): NgbDate {
        return dateToNgbDate(new Date(date));
    }

}
