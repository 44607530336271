export class ForcePasswordChangeCheckHook {

    static $inject = ["$transitions", 'IdentityService', "$location"];

    constructor($transitions, identity, $location) {

        $transitions.onBefore({
            to: function (state) {
                return state.data && state.data.requiresAuth;
            }
        }, function (transition) {
            if (!identity.isAnonymous() && identity.user.isForceChangePassword) {
                return transition.router.stateService.target("recover-password");
            }
        });

    }
}
