<ft-modal-editor-wrapper>
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
    <div class="ft2-input -large v-center mb-4"
         *ngIf="!value?.length">
        No dates have been set.
    </div>
    <div class="ft2-form-group flex f-md-wrap"
         *ngFor="let val of value; let i = index;">
        <div class="range-select">
            <ng-select class="ft2-select -large -no-border"
                       [searchable]="false"
                       [clearable]="false"
                       [(ngModel)]="val.isOffPeak">
                <ng-option [value]="false">
                    Peak season
                </ng-option>
                <ng-option [value]="true">
                    Off season
                </ng-option>
            </ng-select>
        </div>
        <div class="flex full-width datepickers">
            <input class="ft2-input -large -square -no-border"
                   [(ngModel)]="val.startDate"
                   placeholder="Start date"
                   readonly
                   datepickerClass="ft-daterange-picker-calendar"
                   ngbDatepicker
                   (click)="dpStart.open()"
                   #dpStart="ngbDatepicker"
                   [maxDate]="val.endDate"
                   [dayTemplate]="t"
                   [navigation]="'arrows'">
            <input class="ft2-input -large -square -no-border"
                   [minDate]="val.startDate"
                   [(ngModel)]="val.endDate"
                   placeholder="End date"
                   readonly
                   datepickerClass="ft-daterange-picker-calendar"
                   ngbDatepicker
                   (click)="dpEnd.open()"
                   #dpEnd="ngbDatepicker"
                   [dayTemplate]="t"
                   [navigation]="'arrows'">
        </div>
        <button class="ft2-btn-close"
                (click)="removeRange(i)">
        </button>
    </div>
    <button class="ft2-btn-light -outline -float-left -large"
            (click)="addRange()">
        + Add date range
    </button>
</ft-modal-editor-wrapper>
<ng-template #t let-date let-focused="focused" let-disabled="disabled">
          <span class="custom-day"
                [class.disabled]="disabled"
                [class.focused]="focused">
            {{ date.day }}
          </span>
</ng-template>
