<ft-modal-wrapper>
    <ft-touchpoint-header [name]="title"
                          (activeToggled)="commit({isActive: $event})" [isActive]="isActive$ | async">
    </ft-touchpoint-header>
    <mat-tab-group class="mat-tab-group-nav" mat-align-tabs="center" animationDuration="0ms"
                   [(selectedIndex)]="selectedIndex">
        <mat-tab label="styling">
            <ng-template matTabContent>
                <ft-permissions [ftPermissions]="'FliptoAdministration'" [hide]="false">
                    <ft-discovery-component-styling [stylingViewModel$]="stylingViewModel$" [isLoading$]="isLoading$"
                                                    [commitSuccess$]="commitSuccess$" (commitAction)="commit({stylingViewModel: $event})">
                    </ft-discovery-component-styling>
                </ft-permissions>
            </ng-template>
        </mat-tab>
        <mat-tab label="integration">
            <ng-template matTabContent>
                <ft-permissions [ftPermissions]="'FliptoAdministration'" [hide]="false">
                    <ft-discovery-component-integration [componentGroupType$]="componentGroupType$"
                                                        [integrationViewModel]="integrationViewModel$ | async"
                                                        [users$]="users$" [isLoading$]="isLoading$" [commitSuccess$]="commitSuccess$"
                                                        (commitAction)="commit({integrationViewModel: $event})"></ft-discovery-component-integration>
                </ft-permissions>
            </ng-template>
        </mat-tab>
        <mat-tab label="mapping" *ngIf="!!isFliptoStaff && !(ownerPropertyUuid$ | async)">
            <ng-template matTabContent>
                <ft-permissions [ftPermissions]="'FliptoAdministration'" [hide]="false">
                    <ft-properties-associations [properties]="properties$ | async"
                                                [languages]="languages$ | async"
                                                [selectedPreviewLanguage]="selectedPreviewLanguage"
                                                [error]="associationsError$ | async"
                                                [integrationViewModel]="integrationViewModel$ | async"
                                                [missionControlDetailsSettings]="missionControlDetailsSettings$ | async"
                                                (changed)="commit({mappingViewModel: {properties: $event}})"
                                                (languageChanged)="onPreviewLanguageChange($event)"
                                                [associations]="associations$ | async"
                                                [loading]="isLoading$ | async">
                    </ft-properties-associations>
                </ft-permissions>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</ft-modal-wrapper>
