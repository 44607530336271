/**
 * Created by roman.kupin on 10/3/2017.
 */



declare let angular;

export const NAME = "ftCarouselCards";

export class CarouselCardsDirectiveController {

    cards: any[] = [];
    activeCardIndex: number;
    moveLeftCardName: string;
    moveRightCardName: string;

    private _options: any;

    constructor() {

    }

    set options(value) {
        this._options = value;
        this.adjustPosition();
    }

    addCard(card: any) {
        this.cards.push(card);
        if (card.isActive) {
            this.activeCardIndex = this.cards.indexOf(card);
        }
        this.adjustSpots();
        this.adjustPosition();
        this.adjustMoveLeftAndRightCards();
    }

    activate(card: any) {
        if (typeof card === "string") {
            card = this.cards.filter(c => c.name === card)[0];
        }

        this.cards.forEach((value, index) => {
            value.elem.removeClass(`from-before`);
            value.elem.removeClass(`from-after`);
            value.isActive = value == card;
            if (value.isActive && index < this.activeCardIndex) {
                value.elem.addClass(`from-before`);
            }
            else if (value.isActive && index > this.activeCardIndex) {
                value.elem.addClass(`from-after`);
            }
        });
        this.activeCardIndex = this.cards.indexOf(card);
        this.adjustMoveLeftAndRightCards();
        this.adjustSpots();
        this.adjustPosition();
    }

    private adjustMoveLeftAndRightCards() {
        this.moveLeftCardName = this.activeCardIndex > 0 ? this.cards[this.activeCardIndex - 1].name : this.cards[0].name;
        this.moveRightCardName = this.activeCardIndex < this.cards.length - 1 ? this.cards[this.activeCardIndex + 1].name : this.cards[this.cards.length - 1].name;
    }

    public getMoveLeftOrRightCardName(cardName) {
        const card = this.cards.find(c => c.name === cardName);
        const cardIndex = this.cards.indexOf(card);
        if (cardIndex === this.activeCardIndex) return card.name;
        return cardIndex > this.activeCardIndex ? this.moveRightCardName : this.moveLeftCardName;
    }

    private adjustSpots() {
        let activeIndex;
        this.cards.forEach((value, index) => {
            if (value.isActive) {
                activeIndex = index;
            }
            value.elem.removeClass("active");
            for (let i = 0; i < this.cards.length; ++i) {
                value.elem.removeClass(`active-before-${i}`);
                value.elem.removeClass(`active-after-${i}`);
            }
        });
        this.cards.forEach((value, index) => {
            if (value.isActive) {
                value.elem.addClass("active");
            } else if (index < activeIndex) {
                value.elem.addClass(`active-before-${activeIndex - index}`);
            } else if (index > activeIndex) {
                value.elem.addClass(`active-after-${index - activeIndex}`);
            }
        });
    }

    leftPaddingElem: any;
    rightPaddingElem: any;

    private adjustPosition() {
        if (!this._options) return;
        if (!!this.leftPaddingElem) {
            this.leftPaddingElem.remove();
            this.leftPaddingElem = null;
        }
        if (!!this.rightPaddingElem) {
            this.rightPaddingElem.remove();
            this.rightPaddingElem = null;
        }

        this.leftPaddingElem = document.createElement(this._options.paddingElement);
        this.rightPaddingElem = document.createElement(this._options.paddingElement);
        if (!!this.cards.length) {
            let parent = angular.element(this.cards[0].elem).parent();
            parent.append(this.rightPaddingElem);
            parent.prepend(this.leftPaddingElem, this.cards[0].elem);
        }

        let activeIndex = this.activeCardIndex;
        let elemsToTheLeft = activeIndex, elemsToTheRight = this.cards.length - 1 - activeIndex,
            diff = Math.abs(elemsToTheLeft - elemsToTheRight);
        if (elemsToTheRight > elemsToTheLeft) {
            angular.element(this.leftPaddingElem).addClass(`active-before-${Math.abs(elemsToTheLeft + 1)} offset-${diff}`);
            angular.element(this.rightPaddingElem).addClass(`offset-0`);
        }
        if (elemsToTheRight < elemsToTheLeft) {
            angular.element(this.leftPaddingElem).addClass(`offset-0`);
            angular.element(this.rightPaddingElem).addClass(`active-after-${Math.abs(elemsToTheRight + 1)} offset-${diff}`);
        }
    }

    moveLeft() {
        if (this.activeCardIndex > 0) {
            this.activate(this.cards[this.activeCardIndex - 1]);
        }
    }

    moveRight() {
        if (this.activeCardIndex < this.cards.length - 1) {
            this.activate(this.cards[this.activeCardIndex + 1]);
        }
    }

}

export const DEFINITION = [function () {
    return {
        controller: CarouselCardsDirectiveController,
        controllerAs: "$ftCarouselCards",
        bindToController: true,
        require: "ftCarouselCards",
        link: function (scope, elem, attrs, ctrl) {
            ctrl.options = scope.$eval(attrs.options);
            if(!!attrs.onAfterInit) {
                scope.$eval(attrs.onAfterInit, {controller: ctrl});
            }
            
        }
    };
}];