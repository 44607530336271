/**
 * Created by ostapenko.r on 7/9/2014.
 */

angular
    .module("flipto.accountv1.SharedFeatures")
    .filter("modeName",
    [
        "$filter","_",
        function ($filter,_) {

            var translate = $filter("translate"),
                modeNames = [{text:"flipto.account.platform.postStay.touchpoints.Integration.HostedFinalPage", value: "0"},
                    {text:"flipto.account.platform.postStay.touchpoints.Integration.HostedFirstPage", value: "4"},
                    {text:"flipto.account.platform.postStay.touchpoints.Integration.AfterCheckOut", value: "1"},
                    {text:"flipto.account.platform.postStay.touchpoints.Integration.AfterExternalRating", value: "2"},
            ];

            function getModeName(mode) {
                var modeName = _.find(modeNames, function(name){return name.value === mode})
                if(modeName)
                {
                    return translate(modeName.text);
                }
                return '';
            }

            return function (mode) {
                return getModeName(mode);
            };
        }
    ]);
