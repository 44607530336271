import { loadStylingInvitationEmailFail } from './actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { JourneysApiService } from '../../services/journeys-api.service';
import { InvitationEmailSettings, InvitationEmailStylingSettings } from '../../models/invitation-email';
import {
    commitInvitationEmail,
    commitInvitationEmailFail,
    commitInvitationEmailSuccess,
    loadinvitationEmail,
    loadinvitationEmailFail,
    loadinvitationEmailSuccess,
    loadStylingInvitationEmail,
    loadStylingInvitationEmailSuccess
} from '.';

@Injectable()
export class InvitationEmailEffects {
    constructor(private actions$: Actions,
        private store$: Store<any>,
        private journeysApiService: JourneysApiService) {
    }

    load$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadinvitationEmail),
            mergeMap(({componentGroupUuid, propertyId, typeRequest, state}) =>
                this.journeysApiService.getTouchpointInvitationEmailData(componentGroupUuid, propertyId, typeRequest, state)
                    .pipe(
                        map((settings: InvitationEmailSettings) => {
                            return loadinvitationEmailSuccess({data: settings});
                        }),
                        catchError(error => of(loadinvitationEmailFail({error})))
                    ))
        )
    );

    commit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(commitInvitationEmail),
            mergeMap(({componentGroupUuid, propertyId, isActive}) =>
                this.journeysApiService.commitTouchpointInvitationEmail(componentGroupUuid, propertyId, isActive)
                    .pipe(
                        map((settings: InvitationEmailSettings) => {
                            return commitInvitationEmailSuccess({data: settings});
                        }),
                        catchError(error => of(commitInvitationEmailFail({error})))
                    ))
        )
    );

    loadStyling$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadStylingInvitationEmail),
            mergeMap(({componentGroupUuid, propertyId, typeRequest, state}) =>
                this.journeysApiService.getStylingInvitationEmailData(componentGroupUuid, propertyId, typeRequest, state)
                    .pipe(
                        map((stylingSettings: InvitationEmailStylingSettings) => {
                            return loadStylingInvitationEmailSuccess({data: stylingSettings});
                        }),
                        catchError(error => of(loadStylingInvitationEmailFail({error})))
                    ))
        )
    );
}
