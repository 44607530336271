<ft-modal-editor-wrapper>
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
    <ng-container *ngIf="value?.length && fields?.length">
        <ng-container *ngFor="let model of value; let i = index; trackBy: trackByFn">
            <ng-container *ngFor="let field of fields">
                <ng-container
                    *ngIf="(languageVariationsView && isVariationActive(model?.languageCode))">
                    <ng-container
                        *ngTemplateOutlet="editorTmpl; context: { model: model, field: field, i: i, isVariation: true }"></ng-container>
                </ng-container>

                <ng-container
                    *ngIf="!languageVariationsView">
                    <ng-container
                        *ngTemplateOutlet="editorTmpl; context: { model: model, field: field, i: i, isVariation: false }"></ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-template #editorTmpl let-field="field" let-model="model" let-i="i" let-isVariation="isVariation">
        <div class="ft2-input-container"
             [ngClass]="{'-pure': !field?.label && !model[field?.languageName]}">
            <div class="ft2-editor-panel h-end" *ngIf="tokens.length">
                <ng-select class="ft2-select -small -pure -no-border"
                           [items]="tokens"
                           [bindLabel]="'name'"
                           [bindValue]="'value'"
                           [(ngModel)]="selectedToken"
                           [clearable]="false"
                           [searchable]="false"
                           [placeholder]="'Insert token'"
                           (ngModelChange)="onTokenSelected($event, i, field.name)">
                </ng-select>
            </div>
            <textarea class="ft2-textarea"
                      (ngModelChange)="onModelChange($event, i, field.name)"
                      [ngModel]="model[field.name]"
                      [ngModelOptions]="{standalone: true}"
                      [placeholder]="placeholder"
                      [minlength]="(validationDefinition && validationDefinition[i])?.minLength || (validationDefinition && validationDefinition[0]?.minLength)"
                      [maxlength]="(validationDefinition && validationDefinition[i])?.maxLength || (validationDefinition && validationDefinition[0]?.maxLength)"
                      [min]="(validationDefinition && validationDefinition[i])?.min || (validationDefinition && validationDefinition[0]?.min)"
                      [max]="(validationDefinition && validationDefinition[i])?.max || (validationDefinition && validationDefinition[0]?.max)"
                      [required]="(validationDefinition && validationDefinition[i])?.required || (validationDefinition && validationDefinition[0]?.required)"
                      [pattern]="(validationDefinition && validationDefinition[i])?.pattern || (validationDefinition && validationDefinition[0]?.pattern)"
                      [name]="(validationDefinition && validationDefinition[i])?.name || (validationDefinition && validationDefinition[0]?.name)"
                      #textareaElement
                      #control="ngModel">
            </textarea>
            <span class="ft2-input-language"
                  [ngClass]="{ '-clickable': isValueRemovable(i) && isVariation }"
                  *ngIf="(activeVariationsCount > 1 && isVariation) || (value?.length > 1 && !isVariation)">
                        {{ model[field?.languageName] || languageNameByCode(model.languageCode) }}
                <button *ngIf="isValueRemovable(i) && isVariation"
                        class="ft2-btn-close -inline -round" (click)="removeValue(i, field.name)">
                </button>
            </span>
            <div class="ft2-form-error mt-2"
                 *ngIf="control?.invalid && control?.touched">
                <p *ngIf="control.errors.required">
                    Value is required.
                </p>
                <p *ngIf="control.errors.minlength">
                    Value must be at
                    least {{ validationDefinition[i]?.minLength || validationDefinition[0]?.minLength }}
                    characters long.
                </p>
                <p *ngIf="control.errors.maxlength">
                    Value must be at
                    most {{ validationDefinition[i]?.maxLength || validationDefinition[0]?.maxLength }}
                    characters long.
                </p>
                <p *ngIf="control.errors.email">
                    Value is not valid email address.
                </p>
                <p *ngIf="control.errors.pattern">
                    {{
                        validationDefinition[i]?.patternValidationText || validationDefinition[0]?.patternValidationText
                            ? validationDefinition[i]?.patternValidationText || validationDefinition[0]?.patternValidationText
                            : 'Value doesn\'t not match pattern.' + validationDefinition[i]?.pattern || validationDefinition[0]?.pattern
                    }}
                </p>
            </div>
        </div>
    </ng-template>

    <div class="ft2-edit-dialog_footer-control" *ngIf="languageVariationsView">
        <ft-language-variations *ngIf="languages?.length"
                                [fieldName]="fields?.[0]?.name"
                                [languages]="languages"
                                [value]="value"
                                (activeVariationsChange)="onActiveVariationChanged($event)">
        </ft-language-variations>
    </div>
    <button
        *ngIf="secondButton"
        class="ft2-edit-dialog_footer-control ft2-second-button ft2-btn-light -large -outline mr-3"
        (click)="action.emit()">
        {{ secondButton }}
    </button>
</ft-modal-editor-wrapper>
