/**
 * Created by ostapenko.r on 7/9/2014.
 */

angular
    .module("flipto.components.common.textFormatting")
    .filter("onOff", function() {
        return function (input) {
            if(typeof input == 'string')
            {
                if(!isNaN(input))
                {
                    return +input ? 'On' : 'Off';
                }
                return input.toLowerCase() === 'true' ? 'On' : 'Off';
            }
            return input ? 'On' : 'Off';


        }
    });
