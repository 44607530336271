import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input, OnChanges,
    OnDestroy,
    OnInit,
    Output, SimpleChanges,
    ViewChild
} from '@angular/core';

@Component({
    selector: 'ft-instant-search',
    templateUrl: './instant-search.component.html',
    styleUrls: ['./instant-search.component.scss']
})
export class InstantSearchComponent implements OnInit, OnDestroy, OnChanges {
    @Output() clear = new EventEmitter();
    @Input() value: string;
    @Output() valueChange = new EventEmitter<string>();
    @Input() visible = false;
    @Output() visibleChange = new EventEmitter<boolean>();
    @Input() searchable = true;
    @ViewChild("input", {read: ElementRef, static: false}) input: ElementRef;
    isAlive = true;

    constructor() {
    }

    @HostListener('document:keypress', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent & { target: HTMLInputElement}) {
        if (this.isAlive && event.key && this.searchable && event.target && !["input", "textarea", "select"].includes(event.target?.tagName.toLowerCase())) {
            this.showSearch();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.value) {
            this.setVisible(!!this.value);
        }
    }

    ngOnDestroy() {
        this.isAlive = false;
    }

    showSearch() {
        this.setVisible(true);
        if (this.input) {
            this.input.nativeElement.focus();
        }
    }

    clearSearch() {
        this.setValue(null);
        this.setVisible(false);
        this.clear.next(null);
    }

    setValue(val: string) {
        this.value = val;
        this.valueChange.next(val);
    }

    setVisible(val: boolean) {
        this.visible = val;
        this.visibleChange.next(val);
    }

    ngOnInit(): void {
    }

}
