import { createReducer, Action, on } from '@ngrx/store';
import {
    DiscoveryComponentIntegrationViewModel,
    DiscoveryComponentPlannerFlowViewModel
} from '../../models';
import * as fromActions from './discovery-component.actions';
import { DiscoveryComponentState } from './discovery-component.state';

const initialState: DiscoveryComponentState = {
    isLoading: false,
    settings: null,
    discoveryProperties: null,
    isLoadingDiscoveryProperties: false,
    discoveryPropertiesError: null,
    settingsPendingCommit: null,
    missionControlDetailsSettings: null,
    commitSuccess: false,
    error: null,
    users: null,
};

const reducer = createReducer(initialState,
    on(fromActions.loadDiscoveryComponentSettings, (state) => {
        return {
            ...state,
            isLoading: true
        };
    }),
    on(fromActions.loadDiscoveryComponentSettingsSuccess, (state, { settings }) => {
        return {
            ...state,
            isLoading: false,
            settings,
            error: null
        };
    }),
    on(fromActions.loadDiscoveryComponentSettingsFailure, (state, { error }) => {
        return {
            ...state,
            isLoading: false,
            error
        };
    }),
    on(fromActions.loadDiscoveryProperties, (state) => {
        return {
            ...state,
            isLoadingDiscoveryProperties: true
        };
    }),
    on(fromActions.loadDiscoveryPropertiesSuccess, (state, { discoveryProperties }) => {
        return {
            ...state,
            isLoadingDiscoveryProperties: false,
            discoveryProperties,
            discoveryPropertiesError: null
        };
    }),
    on(fromActions.loadDiscoveryPropertiesFailure, (state, { error }) => {
        return {
            ...state,
            isLoadingDiscoveryProperties: false,
            error
        };
    }),
    on(fromActions.commitDiscoveryComponentSettings, (state, { settings }) => {
        return {
            ...state,
            isLoading: true,
            commitSuccess: false,
            settingsPendingCommit: settings
        };
    }),
    on(fromActions.commitDiscoveryComponentSettingsSuccess, (state, { settings }) => {
        const newIntegrationViewModel = new DiscoveryComponentIntegrationViewModel();
        newIntegrationViewModel.trafficPercentage =
            settings?.integrationViewModel?.trafficPercentage ?? state.settings.integrationViewModel.trafficPercentage;
        newIntegrationViewModel.defaultWebsiteUrl =
            settings?.integrationViewModel?.defaultWebsiteUrl ?? state.settings.integrationViewModel.defaultWebsiteUrl;
        newIntegrationViewModel.prefix =
            settings?.integrationViewModel?.prefix ?? state.settings.integrationViewModel.prefix;
        newIntegrationViewModel.code = settings?.integrationViewModel?.code ?? state.settings.integrationViewModel.code;
        newIntegrationViewModel.signatureUserUuid = settings?.integrationViewModel?.signatureUserUuid ?? state.settings.integrationViewModel.signatureUserUuid;
        newIntegrationViewModel.signatureText = settings?.integrationViewModel?.signatureText ?? state.settings.integrationViewModel.signatureText;
        newIntegrationViewModel.facadeDelayInMs = settings?.integrationViewModel?.facadeDelayInMs ?? state.settings.integrationViewModel.facadeDelayInMs;
        newIntegrationViewModel.isBrowserBackButtonNavActive = settings?.integrationViewModel?.isBrowserBackButtonNavActive ?? state.settings.integrationViewModel.isBrowserBackButtonNavActive;

        newIntegrationViewModel.bookingEngineTemplateUrl =
            settings?.integrationViewModel?.bookingEngineTemplateUrl ?? state.settings.integrationViewModel.bookingEngineTemplateUrl;
        newIntegrationViewModel.languageData = settings?.integrationViewModel?.languageData ?? state.settings.integrationViewModel.languageData


        const newPlannerFlowViewModel = new DiscoveryComponentPlannerFlowViewModel();
        newPlannerFlowViewModel.defaultAdults = settings?.plannerFlowViewModel?.defaultAdults ?? state.settings?.plannerFlowViewModel?.defaultAdults;
        newPlannerFlowViewModel.defaultChildren = settings?.plannerFlowViewModel?.defaultChildren ?? state.settings?.plannerFlowViewModel?.defaultChildren;
        newPlannerFlowViewModel.defaultPickerMode = settings?.plannerFlowViewModel?.defaultPickerMode ?? state.settings?.plannerFlowViewModel?.defaultPickerMode;
        newPlannerFlowViewModel.defaultRooms = settings?.plannerFlowViewModel?.defaultRooms ?? state.settings?.plannerFlowViewModel?.defaultRooms;
        newPlannerFlowViewModel.defaultStayLength = settings?.plannerFlowViewModel?.defaultStayLength ?? state.settings?.plannerFlowViewModel?.defaultStayLength;
        newPlannerFlowViewModel.emailLinkCustomParameters = settings?.plannerFlowViewModel?.emailLinkCustomParameters ?? state.settings?.plannerFlowViewModel?.emailLinkCustomParameters;
        newPlannerFlowViewModel.headcountStepDisclosure = settings?.plannerFlowViewModel?.headcountStepDisclosure ?? state.settings?.plannerFlowViewModel?.headcountStepDisclosure;
        newPlannerFlowViewModel.introGreetingText = settings?.plannerFlowViewModel?.introGreetingText ?? state.settings?.plannerFlowViewModel?.introGreetingText;
        newPlannerFlowViewModel.isHeadcountStepEnabled = settings?.plannerFlowViewModel?.isHeadcountStepEnabled ?? state.settings?.plannerFlowViewModel?.isHeadcountStepEnabled;
        newPlannerFlowViewModel.logoUrl = settings?.plannerFlowViewModel?.logoUrl ?? state.settings?.plannerFlowViewModel?.logoUrl;
        newPlannerFlowViewModel.maxAdults = settings?.plannerFlowViewModel?.maxAdults ?? state.settings?.plannerFlowViewModel?.maxAdults;
        newPlannerFlowViewModel.maxChildren = settings?.plannerFlowViewModel?.maxChildren ?? state.settings?.plannerFlowViewModel?.maxChildren;
        newPlannerFlowViewModel.maxNights = settings?.plannerFlowViewModel?.maxNights ?? state.settings?.plannerFlowViewModel?.maxNights;
        newPlannerFlowViewModel.maxOccupancyPerRoom = settings?.plannerFlowViewModel?.maxOccupancyPerRoom ?? state.settings?.plannerFlowViewModel?.maxOccupancyPerRoom;
        newPlannerFlowViewModel.minAdults = settings?.plannerFlowViewModel?.minAdults ?? state.settings?.plannerFlowViewModel?.minAdults;
        newPlannerFlowViewModel.minChildren = settings?.plannerFlowViewModel?.minChildren ?? state.settings?.plannerFlowViewModel?.minChildren;
        newPlannerFlowViewModel.minNights = settings?.plannerFlowViewModel?.minNights ?? state.settings?.plannerFlowViewModel?.minNights;
        newPlannerFlowViewModel.onLoadJs = settings?.plannerFlowViewModel?.onLoadJs ?? state.settings?.plannerFlowViewModel?.onLoadJs;
        newPlannerFlowViewModel.openingDate = settings?.plannerFlowViewModel?.openingDate ?? state.settings?.plannerFlowViewModel?.openingDate;
        newPlannerFlowViewModel.signatureText = settings?.plannerFlowViewModel?.signatureText ?? state.settings?.plannerFlowViewModel?.signatureText;
        newPlannerFlowViewModel.minRooms = settings?.plannerFlowViewModel?.minRooms ?? state.settings?.plannerFlowViewModel?.minRooms;
        newPlannerFlowViewModel.maxRooms = settings?.plannerFlowViewModel?.maxRooms ?? state.settings?.plannerFlowViewModel?.maxRooms;

        let updatedEmailSettingsViewModels = [];

        if (!!settings?.emailSettingsViewModels && settings?.emailSettingsViewModels.length > 0) {
            for (const updatedEmailSettings of settings?.emailSettingsViewModels) {
                const originalSetting = state.settings.emailSettingsViewModels.find(esvm => esvm.emailSettingsUuid === updatedEmailSettings.emailSettingsUuid);
                originalSetting.emailScheduleInMinutes = updatedEmailSettings.emailScheduleInMinutes ?? originalSetting.emailScheduleInMinutes;
                originalSetting.emailTemplatePath = updatedEmailSettings.emailTemplatePath ?? originalSetting.emailTemplatePath;
                updatedEmailSettingsViewModels.push(originalSetting);
            }
        }

        updatedEmailSettingsViewModels = updatedEmailSettingsViewModels.length > 1 ? updatedEmailSettingsViewModels : state.settings.emailSettingsViewModels;

        return {
            ...state,
            isLoading: false,
            settingsPendingCommit: null,
            commitSuccess: true,
            settings: {
                isActive: settings.isActive ?? state.settings.isActive,
                stylingViewModel: {
                    globalCss: settings?.stylingViewModel?.globalCss ?? state?.settings?.stylingViewModel?.globalCss,
                    css: settings?.stylingViewModel?.css ?? state?.settings?.stylingViewModel?.css,
                    reengagementCss: settings?.stylingViewModel?.reengagementCss ?? state?.settings?.stylingViewModel?.reengagementCss,
                    routerCss: settings?.stylingViewModel?.routerCss ?? state?.settings?.stylingViewModel?.routerCss,
                    routerTheme: settings?.stylingViewModel?.routerTheme ?? state?.settings?.stylingViewModel?.routerTheme
                },
                integrationViewModel: newIntegrationViewModel,
                mappingViewModel: settings?.mappingViewModel ?? state.settings?.mappingViewModel,
                plannerFlowViewModel: newPlannerFlowViewModel,
                emailSettingsViewModels: updatedEmailSettingsViewModels,
                componentGroupType: settings?.componentGroupType ?? state.settings?.componentGroupType,
                owningCompanyUuid: settings?.owningCompanyUuid ?? state.settings?.owningCompanyUuid,
                owningPropertyUuid: settings?.owningPropertyUuid ?? state.settings?.owningPropertyUuid,
                isInheritPropertyBranding: settings?.isInheritPropertyBranding ?? state.settings?.isInheritPropertyBranding,
                isUsePropertyBookingEngineTemplateUrl: settings?.isUsePropertyBookingEngineTemplateUrl ?? state.settings?.isUsePropertyBookingEngineTemplateUrl
            },
            error: null
        };
    }),
    on(fromActions.commitDiscoveryComponentSettingFailure, (state, { error }) => {
        return {
            ...state,
            isLoading: false,
            commitSuccess: false,
            settingsPendingCommit: null,
            error
        };
    }),
    on(fromActions.loadDiscoveryComponentCompanyUsersSuccess, (state, { users }) => {
        return {
            ...state,
            isLoading: false,
            users,
            error: null
        };
    }),
    on(fromActions.loadMissionControlDiscoverySettings, (state) => {
        return {
            ...state,
            isLoading: true
        };
    }),
    on(fromActions.loadMissionControlDiscoverySettingsSuccess, (state, { missionControlDetailsSettings }) => {
        return {
            ...state,
            isLoading: false,
            missionControlDetailsSettings,
            error: null
        };
    }),
    on(fromActions.loadMissionControlDiscoverySettingsFailure, (state, { error }) => {
        return {
            ...state,
            isLoading: false,
            error
        };
    }),
);

export function discoveryComponentReducer(state: DiscoveryComponentState | undefined, action: Action) {
    return reducer(state, action);
}
