import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(array: any, key: any, value: any): any {
    if (typeof key === 'object' && key !== null && !Array.isArray(key)) {
      return _.filter(array, key);
    }

    if (!array || !value) {
      return array;
    }

    if (Array.isArray(value)) {
      return array.filter(item => value.includes(item[key]));
    }

    return array.filter(item => item[key] === value);
  }
}
