
declare const window: any;
window.$ = window.jQuery = require('jquery');
import 'angular';
// setAngularJSGlobal(angular);

angular.lowercase = angular.$$lowercase; // (text) => text.toLowerCase();

import 'angular-translate';
import 'oclazyload';
// import "angular-animate";

// lazy load


// import "angular-sanitize";
import 'textangular/dist/textAngular-sanitize.min';
// import "textangular";
import 'textangular/dist/textAngular.min';
import 'angular-strap';
import 'lodash';

const EventEmitter = require('wolfy87-eventemitter');
window.EventEmitter = EventEmitter;
// stuff that needs to sit on global scope at least now
import * as DeepDiff from 'deep-diff/dist/deep-diff.min.js';

window.DeepDiff = DeepDiff;
import moment from 'moment';

window.moment = moment;

import 'angular-moment';


import 'bootstrap-daterangepicker';

import 'ace-builds/src-min-noconflict/ace';
import 'ace-builds/src-min-noconflict/ext-language_tools';

import beautify from 'js-beautify';

window.js_beautify = beautify.js_beautify;
window.css_beautify = beautify.css_beautify;
// window["html_beautify"] = beautify.html_beautify;


import 'bootstrap/dist/js/bootstrap.bundle.min.js';

window.saveAs = require('file-saver').saveAs;

import 'waypoints/lib/noframework.waypoints.min';
import Dropzone from "dropzone/dist/dropzone-amd-module";
window.Dropzone = Dropzone;


import 'v2/polyfills';


import 'v2/v1imports.core';
import 'v2/v1imports.components.common';
import 'v2/v1templates';
import 'v1/language/language';
import 'v2/v1imports.features';


/*global stuff*/
import { AuthenticationCheckHook } from 'v2/app/global/transition-hooks/authentication-check';
import { ForcePasswordChangeCheckHook } from 'v2/app/global/transition-hooks/force-password-change-check';
import { SpaceTypeCheckHook } from 'v2/app/global/transition-hooks/space-type-check';
import { PermissionsCheckHook } from 'v2/app/global/transition-hooks/permissions-check';
import { ParamsCheckHook } from "v2/app/global/transition-hooks/params-check.hook";
import { ComponentConfig } from 'v2/app/global/component.config';
import { ServiceConfig } from 'v2/app/global/service.config';
import GlobalStates from 'v2/app/global/states/index';
import { InterceptorsConfig } from 'v2/app/global/interceptors.config';

/*components & directives*/
import Components from 'v2/app/components/index';
import Directives from 'v2/app/directives/index';
import Filters from 'v2/app/filters/index';
import * as PageClassHookDirective from 'v2/app/directives/page-class-hook/pageClassHook.directive';
import { TypeScriptCommonComponents } from 'v2/v1imports.components.common';

/*redux*/
import 'ng-redux';
import ngReduxUiRouter from 'redux-ui-router';

import { createRootReducer } from 'v2/app/reducers/rootReducer';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { stateGo } from 'redux-ui-router';
import Actions from 'v2/app/actions/startup';

import uiRouter from '@uirouter/angularjs';
import { UIRouterUpgradeModule, upgradeModule } from '@uirouter/angular-hybrid';
import { SpaceType, getCurrentSpaceFromPath, getPathForSpace } from './common/components/spaces/index';
// import LOGIN_STATES from './features/login/login.module';


declare const angular;


// add PageClassHook directive to body
document.body.setAttribute(PageClassHookDirective.SNAKECASE_NAME, '');

// const features = getFeatures();

const APP_MODULE = angular.module('AccountApp.v2', [
    'ngSanitize',
    // "ngAnimate",
    'pascalprecht.translate',
    uiRouter, upgradeModule.name,
    'ngRedux',

    'v1imports.core',
    'v1imports.features',
    'flipto.components.common',
    'flipto.account.language',

    'mgcrea.ngStrap',
    'angularMoment',
    'oc.lazyLoad',
    TypeScriptCommonComponents.name,
    ngReduxUiRouter,
    ...Components.map(c => c.name),
    Directives.name,
    Filters.name,
    // ...features.map(feature => feature.MODULE.name)
]);
APP_MODULE.config(['$urlServiceProvider', '$urlRouterProvider', '$translateProvider', "$sceDelegateProvider", "$sceProvider", '$locationProvider',
    ($urlService, $urlRouterProvider, $translateProvider, $sceDelegateProvider, $sceProvider, $locationProvider) => {
        $urlService.deferIntercept();
        $urlRouterProvider.otherwise(($injector) => {
            const state = $injector.get('$state');
            const location = $injector.get('$location')
            const space = getCurrentSpaceFromPath();
            if (!space) {
                state.go('home');
            } else {
                location.path(getPathForSpace(space));
            }
        });
        $locationProvider.html5Mode(true); // .hashPrefix('!');
        $translateProvider.preferredLanguage('en');
        $translateProvider.useSanitizeValueStrategy('sanitize');

        // $sceDelegateProvider.resourceUrlWhitelist([
        //     "self"
        // ]);

        $sceProvider.enabled(false);
    }
]);

APP_MODULE.decorator("$browser", ["$delegate", "$window", function ($delegate, $window) {
    $delegate.baseHref = function () {
        return "/";
    };
    return $delegate;
}]);

// this is needed so that we can run angularjs components within angular components ui-view
APP_MODULE.component("ftUiViewWrapper", {
    template: "<ui-view></ui-view>"
});
GlobalStates.forEach(stateConfig => APP_MODULE.config(stateConfig));

APP_MODULE.config(ServiceConfig);
APP_MODULE.config(ComponentConfig);
APP_MODULE.config(InterceptorsConfig);


APP_MODULE.service('ftCache', ['$cacheFactory', function ($cacheFactory) {
    return $cacheFactory('ftCache');
}]);

APP_MODULE.run(['$http', 'ftCache', function ($http, ftCache) {
    $http.defaults.cache = ftCache;
    // clear http cache every 5 mins
    setInterval(() => {
        ftCache.removeAll();
    }, 300000);
}]);


APP_MODULE.run(AuthenticationCheckHook);
APP_MODULE.run(ForcePasswordChangeCheckHook);
APP_MODULE.run(PermissionsCheckHook);
APP_MODULE.run(SpaceTypeCheckHook);
APP_MODULE.run(ParamsCheckHook);

APP_MODULE.run(['$rootScope', '$ngRedux', '$state', 'StoryModal', 'BroadcastModal', ($rootScope, $ngRedux, $state, StoryModal, BroadcastModal) => {

    $rootScope.$on('curationModal.openDetails', (evnt, guestUuid) => {
        let state = `${$state.current.name}.details`;
        if (/advocacy\.audiences/.test($state.current.name)) {
            state = 'advocacy.audiences.advocate';
        } else if (/advocacy\.contests/.test($state.current.name)) {
            state = 'advocacy.contests.advocate';
        }
        const contextState = $ngRedux.getState().ui.context;
        const fullContext = `/${contextState.organization}/${contextState.singleProperty}`;
        $ngRedux.dispatch(stateGo(state, {
            advocateUUID: guestUuid,
            context: fullContext
        }));
    });

    $rootScope.$on('[StoryModal].OpenStory', (evnt, args) => {
        if (args.isOpenLibrary || typeof args.isOpenLibrary === 'undefined') {
            const contextState = $ngRedux.getState().ui.context;
            const fullContext = `/${contextState.organization}/${contextState.singleProperty}`;
            $ngRedux.dispatch(stateGo('stories.library', {
                context: fullContext
            }))
                .then(() => {
                    StoryModal.showStory(args.propertyUuid, args.storyUuid);
                });
        } else {
            $rootScope.$broadcast('[Library].ShowStory', { propertyUuid: args.propertyUuid, storyUuid: args.storyUuid });
        }
    });

    $rootScope.$on('[StoryModal].BroadcastStory', (evnt, args) => {
        BroadcastModal.broadcastStoryFromStoryModal(args.propertyUuid, args.storyUuid);
    });

}]);
// hack to add/remove modal-open class in 1+ modals open scenarios
APP_MODULE.run(['$rootScope', 'ftModal', ($rootScope, ftModal) => {
    $rootScope.$watch(() => ftModal.isAnyActive(), (isActive) => {
        angular.element(document.body)[isActive ? 'addClass' : 'removeClass']('modal-open');
    }, true);
}]);

const thunkExtras = {};
APP_MODULE.run(['$injector', ($injector) => {
    [
        '$rootScope', '$http', '$location',
        'dateSerializer', 'Layers', 'urlFormatter',
        'PhotoContest', 'Property', 'Admin', 'localStorage',
        'User', 'IdentityService', 'SessionService', 'SpacesService', 'Guests', 'Account', 'ftCache', 'environment']
        .forEach(injectable => {
            thunkExtras[injectable] = $injector.get(injectable);
        });
}]);

APP_MODULE.config(['$ngReduxProvider', ($ngReduxProvider) => {
    $ngReduxProvider.createStoreWith(
        createRootReducer([]/*features*/),
        [thunk.withExtraArgument(thunkExtras), 'delayedResultMiddleware', 'ngUiRouterMiddleware'/*, logger*/],
        [],
        {}
    );
}]);


/**
 * Run context selectors setup when required deps are ready
 */
APP_MODULE.run(['$ngRedux', 'IdentityService', 'SpacesService', ($ngRedux, identity, spacesService) => {
    if (!identity.isAnonymous() && !!spacesService.current && spacesService.current.type != SpaceType.Collaborative) {
        $ngRedux.dispatch(Actions.setupContextSelectors());
    }
}]);

APP_MODULE.config(['emailBannerUpdatesProvider', (emailBannerUpdates) => {
    emailBannerUpdates.setSignalrUrl('/signalr/email-banner-updates');
}]);

// APP_MODULE.config(['$stateProvider', function ($stateProvider) {
//     LOGIN_STATES.forEach(state => $stateProvider.state(state));
// }]);

export { APP_MODULE };
