<div class="ft2-instant-search-hint" (click)="showSearch()">
    <ng-container *ngIf="!visible">
        <mat-icon class="icon-sm text-light mr-3" [svgIcon]="'search'"></mat-icon>
        <span class="muted-text d-xs-none">Press any key to search</span>
    </ng-container>
</div>

<div class="ft2-instant-search" [ngClass]="{'transparent': !visible}">
    <mat-icon svgIcon="search"></mat-icon>
    <input #input
           class="ft2-instant-search_input"
           (keydown)="$event.stopPropagation()"
           (ngModelChange)="valueChange.next($event)"
           [ftRestrictInput]="'^[\\w\\s-]*$'"
           [ngModel]="value"
           autocomplete="off"
           ftAutofocus
           type="text"/>
    <button class="ft2-btn-text" (click)="clearSearch()">
        <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
    </button>
</div>
