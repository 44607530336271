/**
 * Created by ostapenko.r on 9/19/2014.
 */

angular.module('flipto.components.common.objectList')
    .directive('ftObjectList', [function () {
        return {
            restrict: 'E',
            template: '<div class="list-item" ng-repeat="item in list track by $index"><span>{{map ? item[map] : item}}</span><button class="btn-delete-item" ng-if="removable" ng-click="removeItem(item)"></div></div>',
            scope: {
                'list': '=',
                'map': '@?'
            },
            link: function (scope, element, attrs) {
                scope.removable = angular.isDefined(attrs.removable) && attrs.removable!='false';

                scope.removeItem = function (item) {
                    scope.list.splice(scope.list.indexOf(item), 1);
                };
            }
        };
    }]);