<div class="ft-custom-link-modal" [ngSwitch]="wI">
  <div *ngSwitchCase="0">
    <div class="modal-header">
      <h1 class="modal-heading">Let's set up tracking for your link</h1>
      <p class="modal-summary">
        Here's the full link to your Invitation Page for 
        <span class="ft-emphasis">
          <span *ngIf="params.theme == 0">guest</span>
          <span *ngIf="params.theme == 1">wedding</span>
          <span *ngIf="params.theme == 2">team</span>
          storytellers.
        </span>
        Add custom tracking or leave it empty to use the defaults. You'll create a shareable link on the next step.
      </p>
    </div>
    <form #destUrlForm="ngForm" (submit)="destUrlForm.valid && continue()">
      <div class="ft-custom-link-url ft-text-plus-1">{{ params.baseUrl }}/?</div>
      <div class="modal-panel modal-panel-input">
        <input
        type="text"
        name="destUrl"
        #destUrl="ngModel"
        [(ngModel)]="data.destinationUrl"
        pattern="\??(&?([\w\d_-]+=[\w\d_-]+)+)*"
        placeholder="utm_source=flipto&utm_medium=referral&utm_campaign=broadcast"
        autofocus
        />
      </div>
      <div class="ft-form-error-alert" *ngIf="destUrlForm.submitted && destUrlForm.invalid">
        <ng-container *ngIf="destUrl.errors.pattern">Invalid format. Make sure there are no spaces or characters that would not work in a URL.</ng-container>
      </div>
      <div class="modal-footer">
        <button
          type="submit"
          class="ft-btn ft-btn-plus-1 ft-btn-default"
          [disabled]="destUrlForm.submitted && destUrlForm.invalid"
          [ngClass]="{disabled: destUrlForm.submitted && destUrlForm.invalid}">
          <span>Continue</span>
        </button>
      </div>
    </form>
  </div>

  <div *ngSwitchCase="1">
    <button class="ft-btn ft-btn-outline-light ft-modal-btn-previous" (click)="previousStep()">Edit tracking</button>

    <div class="modal-header">
      <h1 class="modal-heading">Create your shareable link</h1>
      <p class="modal-summary">
        This link will redirect your audience to your Invitation Page with any tracking you provided. Add a short code to your link so it's easy to use.
      </p>
    </div>
    <form #shortUrlForm="ngForm" (submit)="shortUrlForm.valid && destUrlStep()">
      <div class="modal-panel modal-panel-input">
        <div class="number-field-label">https://go.flip.to/</div>
        <input autofocus type="text" name="shortUrl" #shortUrl="ngModel" [(ngModel)]="data.shortUrl" [ngModelOptions]="{updateOn: 'change'}" 
        required maxlength="50" pattern="[a-zA-Z0-9-]+" shortLinkCodeUnique />
      </div>
      <div class="ft-form-error-alert" *ngIf="shortUrlForm.submitted && shortUrlForm.invalid">
        <ng-container *ngIf="shortUrl.errors.pattern"><div>Invalid format. Make sure there are no spaces or characters that would not work in a URL.</div></ng-container>
        <ng-container *ngIf="shortUrl.errors.required"><div>Short code is required</div></ng-container>
        <ng-container *ngIf="shortUrl.errors.maxlength"><div>Short code must be 50 characters or less</div></ng-container>
        <ng-container *ngIf="shortUrl.errors.shortLinkCodeUnique"><div>There is already another link with this short code. Create a unique short code.</div></ng-container>
      </div>
      <div class="modal-footer">
        <button
          type="submit"
          class="ft-btn ft-btn-plus-1 ft-btn-default"
          [disabled]="shortUrlForm.submitted && shortUrlForm.invalid"
          [ngClass]="{disabled: shortUrlForm.submitted && shortUrlForm.invalid}"
        >
          <span *ngIf="!shortUrlForm.pending">Continue</span>
          <span *ngIf="shortUrlForm.pending" class="ft2-spinner"></span>
        </button>
      </div>
    </form>
  </div>

  <div *ngSwitchCase="2">
    <button class="ft-btn ft-btn-outline-light ft-modal-btn-previous" (click)="previousStep()">Edit shareable link</button>

    <div class="modal-header">
      <h1 class="modal-heading">Give your link a name</h1>
      <p class="modal-summary">This will help your team identify what it was created for.</p>
    </div>
    <form #displayNameForm="ngForm" (submit)="displayNameForm.valid && continue()">
      <div class="modal-panel modal-panel-input">
        <input
          autofocus
          type="text"
          name="displayName"
          #displayName="ngModel"
          [(ngModel)]="data.displayName"
          [ngModelOptions]="{updateOn: 'change'}"
          required
          maxlength="100"
          [shortLinkNameUnique]="params"
          placeholder="ie. Print outreach for guests"
        />
      </div>
      <div class="ft-form-error-alert" *ngIf="displayNameForm.submitted && displayNameForm.invalid">
        <ng-container *ngIf="displayName.errors.maxlength"><div>Name must be 100 characters or less</div></ng-container>
        <ng-container *ngIf="displayName.errors.required"><div>Name is required</div></ng-container>
        <ng-container *ngIf="displayName.errors.shortLinkNameUnique"><div>There is already another link with this name. Create a unique name.</div></ng-container>
      </div>

      <div class="modal-footer">
        <button
          class="ft-btn ft-btn-plus-1 ft-btn-default"
          [disabled]="displayNameForm.submitted && displayNameForm.invalid"
          [ngClass]="{disabled: displayNameForm.submitted && displayNameForm.invalid}"
        >
          <span *ngIf="!displayNameForm.pending">Continue</span>
          <span *ngIf="displayNameForm.pending" class="ft2-spinner"></span>
        </button>
      </div>
    </form>
  </div>

  <div *ngSwitchCase="3">
    <button class="ft-btn ft-btn-outline-light ft-modal-btn-previous" (click)="previousStep()">Edit your link name</button>
    
    <div class="modal-header">
      <h1 class="modal-heading">Activate your custom link</h1>
      <p class="modal-summary">
        Your link is dynamic&mdash;it will always route to the current, active campaign for your target audience so you'll never
        send people to outdated experiences. You can copy the link below and start using it right after you activate it. 
        We'll keep a record of this link on your invitations tab.
      </p>
    </div>

    <div class="modal-panel ft-text-plus-2" ngxClipboard  [cbContent]="'https://go.flip.to/' + data.shortUrl" (cbOnSuccess)="copiedTooltip.show()">
      https://go.flip.to/{{ data.shortUrl }}
      <ft-tooltip #copiedTooltip [hideDelay]="2000">
        <ng-template>
            https://go.flip.to/{{ data.shortUrl }} copied to your clipboard
        </ng-template>
      </ft-tooltip> 
    </div>
    
    <div class="error-message" *ngIf="error">{{ errorMessage }}</div>

    <div class="modal-footer">
      <button
        autofocus
        class="ft-btn ft-btn-plus-1 ft-btn-default"
        [disabled]="submitted"
        [ngClass]="{disabled: submitted}"
        (click)="saveStep()"
      >
        <span *ngIf="!submitted">Activate</span>
        <span *ngIf="submitted" class="ft2-spinner"></span>
      </button>
    </div>
  </div>
</div>
