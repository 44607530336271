/**
 * Created by ostapenko.r on 9/19/2014.
 */

angular.module('flipto.components.common.switch', [])
    .directive('ftSwitch', ['$timeout', function ($timeout) {

        function getUniqueId() {
            return 'switch' + (+Date.now() + parseInt(Math.random() * 100));
        }

        return {
            restrict: 'E',
            templateUrl: '/app/components/common/switch/switch.html',
            scope: {
                'disabledControl': '=',
                'state': '=',
                'onChange': '&?'
            },
            link: function (scope, elem) {
                var id = getUniqueId();
                elem.find('input').attr('id', id);
                elem.find('label').attr('for', id);
                /*scope.$watch('state', function (state) {
                    if (!state) scope.state = false;
                });*/
                scope.change = function () {
                    scope.onChange && $timeout(scope.onChange);
                };

                scope.$watch('disabledControl', function (disabled) {
                    var toogleElement = elem.find('input');
                    disabled ? toogleElement.attr('disabled', '') :  toogleElement.removeAttr('disabled');
                })
            }
        };
    }]);