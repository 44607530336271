/**
 * Created by ostapenko.r on 9/19/2014.
 */

angular.module('flipto.components.common.enter', [])
    .config(['$compileProvider', function ($compileProvider) {

        $compileProvider.directive('ftEnter', [function () {
            return {
                restrict: 'A',
                link: function (scope, element, attrs) {
                    element.bind("keydown keypress", function(event) {
                        if(event.which === 13) {
                            scope.$apply(function(){
                                scope.$eval(attrs.ftEnter, {'event': event});
                            });
                            event.preventDefault();
                        }
                    });
                }
            };
        }]);
    }]);