/**
 * User: Kupin.R
 * Date: 5/21/14
 */



angular
    .module('flipto.components.campaigns')
    .filter('campaignName',
    [
        '$filter', function ($filter) {

        var translate = $filter('translate');
        var allGuests = translate('flipto.components.campaign.AllOtherGuests'),
            allVisitors = translate("flipto.components.campaign.AllVisitors");

        /**
         * Returns campaign name
         * @param campaign
         * @returns {string}
         */
        function getCampaignName(campaign, campaignType) {
            if (!campaign) return '';
            if (campaignType == 6 && campaign.type == 255) return allVisitors;
            return campaign.type == 255 ? allGuests : campaign.name;
        }

        return function (campaign, campaignType) {
            return getCampaignName(campaign, campaignType);
        }
    }
    ]
);
