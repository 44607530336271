/**
 * Created by roman.kupin on 6/13/2018.
 */

import * as fromSpaces from 'account-hybrid/common/components/spaces/store/reducers/spaces.reducer';
import { SpaceType, getPathForSpace, storedHistory } from 'account-hybrid/common/components/spaces';


export class StateConfig {

    static $inject = ["$stateProvider"];

    constructor($stateProvider) {

        $stateProvider
            .state({
                name: "feedback-all",
                url: "/{propertySlug}/feedback/{companyCode:[\\d\\w]{2}}",
                data: {
                    requiresAuth: true
                },
                resolve: {
                    companyCode: ['$stateParams', function ($stateParams) {
                        return $stateParams.companyCode;
                    }],
                    propertySlug: ['$stateParams', function ($stateParams) {
                        return $stateParams.propertySlug;
                    }]
                },
                controller: ["companyCode", "propertySlug", 'SessionService', function (companyCode, propertySlug, session) {
                    const userSession = session.get();
                    const spaces = fromSpaces.createSpaces({ companies: userSession.Companies, properties: userSession.Properties, history: storedHistory() });
                    const space = spaces.find(s => s.type === SpaceType.Property && s.slug === propertySlug) ||
                        spaces.find(s => s.type === SpaceType.Mixed && s.slug === `${companyCode}-${propertySlug}`);

                    location.href = !!space ? `${getPathForSpace(space)}/people/feedback` : '/home';
                }],
                template:
                    `
                    <div class="ft-loader-orgswitch">
                        <div class="ft-cube-face-single">
                            <div class="ft-content">
                                <div class="ft-loading-message">
                                    Loading <div class="ft2-spinner"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                            `
            });

    }
}
