/**
 * Created by Roman on 2015-02-05.
 */


angular.module('flipto.core.parse', [])
    .config(['$provide', '$filterProvider', function ($provide, $filterProvider) {

        $provide.factory('parse', [function () {


            /**
             * Encapsulate parsing
             * @constructor
             */
            function ParseService() {

            }

            /**
             *
             * @param source
             * @returns {boolean}
             */
            ParseService.prototype.bool = function (source) {
                return source === true || source == '1' || source === 'true';
            };

            /**
             *
             * @param source
             * @param defaultValue
             * @returns {*}
             */
            ParseService.prototype.boolOrDefault = function (source, defaultValue) {
                if (angular.isDefined(source) && source != null)
                    return source === true || source == '1' || source === 'true';
                return defaultValue;
            };

            /**
             * Return value as integer or defaultValue if passed, 0 - otherwise
             * @param source
             * @param defaultValue=
             * @returns {*|number}
             */
            ParseService.prototype.intOrDefault = function (source, defaultValue) {
                var parsed = parseInt(source, 10);
                if (!isNaN(parsed)) return parsed;
                return typeof defaultValue !== "undefined" ? defaultValue : 0;
            };

            /**
             * Return source as integer or throws exception if it's not a number
             * @param source
             * @returns {number}
             */
            ParseService.prototype.int = function (source) {
                var parsed = parseInt(source, 10);
                if (isNaN(parsed)) throw source + ' is not a number';
                return parsed;
            };

            /**
             * Returns empty array or array if passed value is valid
             * @param array
             * @returns {*}
             */
            ParseService.prototype.arrayOrDefault = function (array) {
                if (!array) return [];
                if (angular.isArray(array)) return array;
                if (angular.isArray(array.item)) return array.item;
                if (angular.isArray(array.datum)) return array.datum;
                return [array];
            };

            return new ParseService();

        }]);

        $filterProvider.register('parse', ['parse', function (parse) {

            return function (text, type, defaultValue) {
                if (type == 'number')
                    return parse.intOrDefault(text, defaultValue);
                else if (type == 'array')
                    return parse.arrayOrDefault(text, defaultValue);
                else if (type == 'bool')
                    return parse.bool(text);
            }
        }]);
    }]);
