/**
 * Created by Roman on 2014-10-07.
 */


angular
    .module('flipto.components.common.dateRange')
    .filter('daysUntilDate', [function () {
        return function (end, start) {
            var startMoment = start ? moment(start) : moment();
            return moment(end).diff(startMoment.subtract('1', 'days'), 'days');
        }
    }])
    .filter('daysLeft', [function () {
        return function (end, start) {
            var startMoment = start ? moment(start) : moment();
            var daysLeft = moment(end).diff(startMoment.subtract('1', 'days'), 'days');

            if (daysLeft < 1) return "Less than a day";
            if (daysLeft == 1) return "1 day";
            return daysLeft + " days";
        }
    }])
    .filter('daysSince', [function () {
        return function (start) {
            var startMoment = start ? moment(start) : moment();
            var daysSince = moment().diff(startMoment, 'days');

            if (daysSince < 1) return "Today";
            if (daysSince == 1) return "Yesterday";
            if (daysSince <= 14) return daysSince + " days ago";
            return moment(start).format("MMM D, YYYY");
        }
    }]);