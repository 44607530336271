import {
    Component,
    OnInit,
    Inject,
    OnDestroy,
    forwardRef,
    Input,
    Renderer2
} from '@angular/core';
import { takeWhile, map, first, debounceTime, distinctUntilChanged, tap, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
    Initialize,
    SearchCriteriaChanged,
    SetIsVisible,
    FetchNextPage,
    SetIsCancelVisible
} from './store/actions/spaces.actions';
import * as fromSpaces from './store/reducers/spaces.reducer';
import * as fromStore from '../../../store/reducers';
import { Observable, Subject } from 'rxjs';
import Parse from 'v2/app/utils/parse';
import { StateService } from '@uirouter/core';

import { DOCUMENT, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { SpacesService } from './spaces.service';
import { SpaceBase, getPathForSpace, CompanySpace, MixedSpace } from './models/space';
import { SpacesHistory } from './spaces-history.service';
import { SpaceType } from './models/space-type';
import { environment } from 'account-hybrid-environments/environment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SessionService, IdentityService, Company } from 'account-hybrid/core/authentication';


@Component({
    selector: 'ft-spaces',
    templateUrl: './spaces.component.html',
    styleUrls: ['./spaces.component.scss'],
    providers: [
        Location, { provide: LocationStrategy, useClass: PathLocationStrategy }
    ],
    animations: [
        trigger('scale', [
            state('*', style({
                opacity: '1',
                transform: 'scale(1) translate(0, 0)'
            })),
            state('void', style({
                opacity: '0',
                transform: 'scale(1) translate(0, 50px)'
            })),
            transition(':enter', animate('0.5s  cubic-bezier(0, 0, 0.1, 1)'))
        ])
    ]
})
export class SpacesComponent implements OnInit, OnDestroy {

    @Input('isVisible')
    set visible(value: boolean) {
        this.store.dispatch(new SetIsVisible(Parse.bool(value)));
    }

    constructor(@Inject(forwardRef(() => SessionService)) private sessionService,
                private store: Store<fromStore.State>,
                private spacesService: SpacesService,
                private state: StateService,
                private identity: IdentityService,
                private spacesHistory: SpacesHistory,
                @Inject(DOCUMENT) private document: Document,
                private renderer: Renderer2) {
        this.spacesService = spacesService;
    }

    isAlive = true;
    spaces$: Observable<fromSpaces.State>;
    collaborativeSpaces$: Observable<SpaceBase[]>;
    searchCriteria$: Observable<string>;
    searchCriteriaChanged$: Subject<string> = new Subject();
    filteredSpaces$: Observable<SpaceBase[]>;
    history$: Observable<fromSpaces.VisitedSpace[]>;
    isPrefixVisible = !environment.production;
    totalSpacesAmount$: Observable<number>;
    isVisible: boolean;
    isSearchable = true;


    ngOnInit() {
        if (this.identity.isAnonymous()) {
            return;
        }
        this.spaces$ = this.store.select(
          fromStore.selectSpaces,
          takeWhile(() => this.isAlive)
        );

        this.collaborativeSpaces$ = this.spaces$
          .pipe(
            map(spaces => spaces.collaborativeSpaces)
          );

        this.filteredSpaces$ = this.spaces$
          .pipe(
            map(spaces => spaces.filteredSpaces)
          );

        this.history$ = this.spaces$
          .pipe(
            map(spaces => spaces.history)
          );

        this.searchCriteria$ = this.spaces$
          .pipe(
            map(spaces => spaces.searchCriteria)
          );

        const userSession = this.sessionService.get();
        this.store.dispatch(new Initialize({
            companies: userSession.Companies,
            properties: userSession.Properties,
            history: this.spacesHistory.history
        }));

        this.totalSpacesAmount$ = this.spaces$.pipe(
          map(spaces => spaces.allAccessibleSpaces.length)
        );

        this.searchCriteria$.pipe(
          first(),
          takeWhile(() => this.isAlive)
        ).subscribe((value) => {
            if (value) {
                this.storeSearchCriteria(value);
            }
        });

        this.searchCriteriaChanged$.pipe(
          debounceTime(500),
          distinctUntilChanged()
        ).subscribe(value => {
            this.storeSearchCriteria(value);
        });

        this.spaces$.pipe(
          takeWhile(() => this.isAlive)
        ).subscribe(({ isVisible }) => {
            if (isVisible) {
                this.renderer.addClass(this.document.body, 'no-scroll');
            } else {
                this.renderer.removeClass(this.document.body, 'no-scroll');
            }
        });

        this.spaces$.pipe(
          takeWhile(() => this.isAlive),
          map(spaces => spaces.isVisible)
        ).subscribe((isVisible) => {
            this.isVisible = isVisible;
        });
    }

    ngOnDestroy(): void {
        this.isAlive = false;
    }

    setSearchCriteria($event) {
        if ($event !== null) {
            this.searchCriteriaChanged$.next($event);
        }
    }

    storeSearchCriteria(searchCriteria) {
        this.store.dispatch(new SearchCriteriaChanged(searchCriteria));
        if (!searchCriteria) {
            sessionStorage.removeItem('SpacesSearchCriteria');
        } else {
            sessionStorage.setItem('SpacesSearchCriteria', searchCriteria);
        }
    }


    signOut() {
        this.sessionService.signOut();
        this.store.dispatch(new SetIsVisible(false));
        this.store.dispatch(new SetIsCancelVisible(false));
    }

    cancel() {
        this.store.dispatch(new SetIsVisible(false));
    }

    fetchNextPage() {
        this.store.dispatch(new FetchNextPage());
    }

    swapUrl(newSpace: SpaceBase) {
        const oldSpace = this.spacesService.current;
        let currentPathname = location.pathname;

        if (oldSpace) {
            location.href = currentPathname.replace(getPathForSpace(oldSpace), getPathForSpace(newSpace));
        } else {
            location.href = getPathForSpace(newSpace);
        }
    }

    goTo(newSpace: SpaceBase) {
        // const oldSpace = this.spacesService.current;

        this.spacesService.go(newSpace);
        this.spacesService.history.addNew(newSpace);
        // this.store.dispatch(new SetIsCancelVisible(true));
        // this.store.dispatch(new SetIsVisible(false));
        this.swapUrl(newSpace);


        // if (this.isAppReloadRequired(oldSpace, newSpace)) {
        //     // location.href = '/home';
        //     location.href = getPathForSpace(newSpace);
        // } else {
        //     // location.href = getPathForSpace(space);
        //     this.stateService.go(
        //         this.stateService.current,
        //         { type: SpaceTypeToParamMapping[newSpace.type], slug: newSpace.slug },
        //         // { reload: false, notify: false }
        //     );
        //     setTimeout(() => {
        //         this.stateService.reload();
        //     });
        // }
    }

    isAppReloadRequired(oldSpace: SpaceBase, newSpace: SpaceBase): boolean {
        if (!oldSpace || oldSpace.type !== newSpace.type) {
            return true;
        }

        const getCompany = (space: SpaceBase) => {
            if (space.type === SpaceType.Company) {
                return this.sessionService.getCompany((space as CompanySpace).id) as Company;
            } else if (space.type === SpaceType.Mixed) {
                return this.sessionService.getCompany((space as MixedSpace).company.id) as Company;
            }
        };

        if (oldSpace.type === SpaceType.Company || oldSpace.type === SpaceType.Mixed) {
            const oldCompany = getCompany(oldSpace);
            const newCompany = getCompany(newSpace);
            return oldCompany.Type !== newCompany.Type;
        }

        return false;
    }

    isCompanyOrMixedSpace() {
        return this.spacesService.current &&
          (this.spacesService.current.type === SpaceType.Company || this.spacesService.current.type === SpaceType.Mixed);
    }

    clearSearchCriteria() {
        this.storeSearchCriteria(null);
    }
}
