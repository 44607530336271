/**
 * Created by roman.kupin on 9/18/2017.
 */


export const NAME = "ftBranches";


export class BranchesController {

    registry = [];

    setup(branches: any[]) {
        this.registry = branches.map( b => {
            if(typeof b.isVisible === "undefined") b.isVisible = true;
            if(typeof b.isDisabled === "undefined") b.isDisabled = false;
            return b;
        });
    }
}


export const DEFINITION = {
    scope: true,
    controller: BranchesController,
    controllerAs: "$ftBranches",
    bindToController: true,
    require: "ftBranches",
    link: function BranchesLinkFn(scope, elem, attrs, ctrl) {
        scope.$watch(() => scope.$eval(attrs.ftBranches), (val) => ctrl.setup(val), true);
    }
};

