import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnapshotFormComponent } from './components/snapshot-form/snapshot-form.component';
import { FormsModule } from '@angular/forms';
import { LoadingModule } from '../loading/loading.module';
import { PendingChangesComponent } from './components/pending-changes/pending-changes.component';
import { ValidationChecklistModule } from '../validation-checklist';
import { StickyModule } from '../../modules/sticky';
import { ConfirmModalComponent } from '../../dialogs/confirm-modal/confirm-modal.component';


@NgModule({
    declarations: [
        SnapshotFormComponent,
        PendingChangesComponent,
        ConfirmModalComponent,
    ],
  imports: [
    CommonModule,
    FormsModule,
    LoadingModule,
    ValidationChecklistModule,
    StickyModule,
  ],
    exports: [
        SnapshotFormComponent,
        PendingChangesComponent,
        ConfirmModalComponent,
    ]
})
export class SnapshotModule {
}
