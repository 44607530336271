/**
 * Created by roman.kupin on 10/3/2017.
 */


declare let angular;
export const NAME = "ftCarouselCardTrigger";

export const DEFINITION = [function () {
    return {
        scope: false,
        require: "^ftCarouselCards",
        link: function CarouselCardTriggerLinkFn(scope, elem, attrs, ctrl) {
            function setActive() {
                scope.$apply(() => {
                    ctrl.activate(attrs.ftCarouselCardTrigger);
                });
            }

            elem.on("click", setActive);

            scope.$on("$destroy", () => {
                elem.off("click", setActive);
            });
        }
    };
}];

