/**
 * Created by ostapenko.r on 10/1/2014.
 */


angular.module('flipto.core.api.settingsIncentive', ['flipto.core.lodash', 'flipto.core.language', 'flipto.core.parse'])
    .config(['$provide', function ($provide) {

        $provide.factory('SettingsIncentive', ['urlFormatter', '$http', 'languageHelper', 'parse',
            function (urlFormatter, $http, languageHelper, parse) {

                /**
                 * SettingsIncentive service
                 * @constructor
                 */
                function SettingsIncentive() {

                    /**
                     * Get property incentive
                     * @param params
                     * @returns {*}
                     */
                    this.get = function (params) {
                        return $http.get(urlFormatter.format('/api/:propertyId/properties/incentives/:id', params))
                            .then(function (response) {
                                setupIncentive(response.data);
                                return response.data;
                            });
                    };

                    /**
                     * Update incentive
                     * @param params
                     * @param model
                     * @returns {*}
                     */
                    this.put = function (params, model) {                  
                        return $http.put(urlFormatter.format('/api/:propertyId/properties/incentives/:id', params), model)
                            .then(function (response) {
                                return response.data;
                            });
                    };

                    /**
                     * Create incentive
                     * @param params
                     * @param model
                     * @returns {*}
                     */
                    this.create = function (params, model) {
                        return $http.post(urlFormatter.format('/api/:propertyId/properties/:propertyId/incentives/create', params), model)
                            .then(function (response) {
                                var data = {id: response.data.replace(/["]+/g, '')}
                                return data;
                            });
                    };

                    /**
                     * Get property incentives
                     * @param params
                     * @returns {*}
                     */
                    this.list = function (params) {
                        return $http.get(urlFormatter.format('/api/:propertyId/properties/:propertyId/settings/incentives', params))
                            .then(function (response) {
                                response.data.incentives && _.forEach(response.data.incentives.item, setupIncentive)
                                return response.data;
                            });
                    };

                    /**
                     * Activate incentive
                     * @param params
                     * @param model
                     * @returns {*}
                     */
                    this.activate = function (params, model) {
                        return $http.put(urlFormatter.format('/api/:propertyId/properties/incentives/:id/activate/:isActive', params), model)
                            .then(function (response) {
                                return response.data;
                            });
                    };

                    this.updateExistingRedeemUrl = function (incentiveUuid, url, propertyId) {                  
                        return $http.put(urlFormatter.format('/api/:propertyId/properties/incentives/:id', {id: incentiveUuid, propertyId: propertyId}), {UpdateExistingCertificatesRedeemUrl: url})
                            .then(function (response) {
                                return response.data;
                            });
                    };

                    function setupIncentive(incentive) {
                        var boolProperties = ['isActive', 'isHideBarcode', 'isHideRedeemButton'];
                        var intProperties = ['stashPoints', 'daysValidFor'];

                        _.forEach(boolProperties, function (property) {
                            incentive[property] = parse.bool(incentive[property]);
                        });
                        _.forEach(intProperties, function (property) {
                            if (angular.isDefined(incentive[property])) {
                                incentive[property] = parse.intOrDefault(incentive[property]);
                            }
                        });
                        incentive.mode = incentive.mode || "0";

                        languageHelper.sortByDefaultLanguage(incentive.languages);

                        return incentive;
                    }
                }

                return new SettingsIncentive();
            }]);
    }]);
