import { ContextState } from '../context.state';
import { createReducer, on } from '@ngrx/store';
import { setMultipleContext, setSingleContext } from '../actions';

const initialState: ContextState = {
    singleProperty: null,
    multipleProperties: null
};

export const contextReducer = createReducer(initialState,
    on(setSingleContext, (state, action) => ({
        ...state,
        singleProperty: action.property
    })),
    on(setMultipleContext, (state, action) => ({
        ...state,
        multipleProperties: action.properties
    }))
);
