import { AbstractControl, NG_VALIDATORS, Validator } from "@angular/forms";
import { Directive, forwardRef, Input } from "@angular/core";

@Directive({
    selector: '[min]',
    providers: [{provide: NG_VALIDATORS,  useExisting: forwardRef(() => MinValidatorDirective), multi: true}]
})
export class MinValidatorDirective implements Validator {
    @Input() min: number;

    validate(control: AbstractControl): {[key: string]: any} | null {
        return control.value < this.min ? {'min': true} : null;
    }
}
