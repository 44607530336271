import { createAction, props } from '@ngrx/store';
import { CampaignGuestsSettings } from '../../models/campgain-guests';

// load data campaigns
export const loadCampaignGuests = createAction('[Campaign Guests] load data',
props<{
    componentGroupUuid: string,
    propertyId: string,
    state: string
}>());

export const loadCampaignGuestsSuccess = createAction('[Campaign Guests] data loadSuccess', props<{ data: CampaignGuestsSettings }>());
export const loadCampaignGuestsFail = createAction('[Campaign Guests] data loadFail', props<{ error: any }>());

// save data campaigns
export const saveCampaignGuests = createAction('[Campaign Guests] save data',
props<{
    componentGroupUuid: string,
    propertyId: string,
    state: string,
    changes: CampaignGuestsSettings
}>());

export const saveCampaignGuestsSuccess = createAction('[Campaign Guests] save loadSuccess');
export const saveCampaignGuestsFail = createAction('[Campaign Guests] save loadFail', props<{ error: any }>());
