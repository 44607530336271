<ft-modal-editor-wrapper [id]="id">
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
    <ng-select [items]="options"
               *ngIf="compareWith"
               class="ft2-select -large"
               [required]="required"
               [searchable]="searchable"
               [bindLabel]="bindLabel"
               [clearable]="clearable"
               [placeholder]="placeholder"
               [compareWith]="compareWith"
               #control="ngModel"
               [name]="name"
               (ngModelChange)="onModelChange($event)"
               [ngModel]="value">
    </ng-select>
    <ng-select [items]="options"
               *ngIf="!compareWith"
               class="ft2-select -large"
               [required]="required"
               [searchable]="searchable"
               [bindLabel]="bindLabel"
               [bindValue]="bindValue"
               [clearable]="clearable"
               [placeholder]="placeholder"
               #control="ngModel"
               [name]="name"
               (ngModelChange)="onModelChange($event)"
               [ngModel]="value">
    </ng-select>
</ft-modal-editor-wrapper>
