
export class FlowStep {
    constructor(public name: string,
        public template: string,
        public isActive: boolean = false
    ) { }
}

export interface IComponentFlowBuilder {
    createSteps(): FlowStep[];
    createTemplate(steps: FlowStep[]): string
}
