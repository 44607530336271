import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DiscoveryComponentType } from '../enums';
import { IDiscoveryInjectableComponentMessaging, IDiscoveryInjectableComponentSettings } from '../interfaces';
import { DiscoveryInjectableComponentState } from './discovery-injectable-component';
import { TouchpointsState } from './touchpoints.state';
import { BookingOptionId } from 'account-hybrid/features/mission-control/models/booking-option.dto';

export const selectTouchpoints = createFeatureSelector<TouchpointsState>('touchpoints');

export const selectBookingbar = createSelector(selectTouchpoints, state => state.bookingbar);
export const getBookingbarData = createSelector(selectBookingbar, bookingbar => bookingbar.settings);
export const getBookingbarLoading = createSelector(selectBookingbar, bookingbar => bookingbar.loading);
export const selectDiscoveryPropertyState = createSelector(selectTouchpoints, state => state.discoveryPropertySettings);

export const selectBooknow = createSelector(selectTouchpoints, state => state.booknow);
export const getBooknowData = createSelector(selectBooknow, booknow => booknow.settings);
// export const getBooknowSelectedIndex = createSelector(selectBooknow, booknow => booknow.selectedIndex);
export const getBooknowSelectedComponent = createSelector(selectBooknow, booknow => booknow.settings.find(item => item.componentUUID === booknow.selectedComponentUuid));
export const getBooknowSelectedComponentUuid = createSelector(selectBooknow, booknow => booknow.selectedComponentUuid);
export const getBooknowLoading = createSelector(selectBooknow, booknow => booknow.loading);

export const selectPlannerflow = createSelector(selectTouchpoints, state => state.plannerflow);
export const getPlannerflowData = createSelector(selectPlannerflow, plannerflow => plannerflow.settings);
export const getPlannerflowDataLoading = createSelector(selectPlannerflow, plannerflow => plannerflow.loading);

// confirmation experience modal
// messaging
export const selectConfirmationExperience = createSelector(selectTouchpoints, (state) => state.confirmationExperience);
export const getConfirmationExperienceData = createSelector(selectConfirmationExperience, confirmationExperience => confirmationExperience.settings);
export const getConfirmationExperienceLoading = createSelector(selectConfirmationExperience, confirmationExperience => confirmationExperience.loading);
export const getConfirmationExperienceCommitSuccess = createSelector(selectConfirmationExperience, state => state?.commitSuccess);
// styling
export const getStylingConfirmationExperienceData = createSelector(selectConfirmationExperience, confirmationExperience => confirmationExperience.stylingSettings);
// integration
export const getIntegrationConfirmationExperienceData = createSelector(selectConfirmationExperience, confirmationExperience => confirmationExperience.integrationSettings);

// certificate email
export const selectCertificateEmail = createSelector(selectTouchpoints, (state) => state.certificateEmail);
export const getCertificateEmailData = createSelector(selectCertificateEmail, certificateEmail => certificateEmail.settings);
export const getCertificateEmailLoading = createSelector(selectCertificateEmail, certificateEmail => certificateEmail.loading);
export const getCertificateEmailCommitSuccess = createSelector(selectCertificateEmail, state => state?.commitSuccess);

// follow-up email
export const selectFollowupEmail = createSelector(selectTouchpoints, (state) => state.followupEmail);
export const getFollowupEmailData = createSelector(selectFollowupEmail, followupEmail => followupEmail.settings);
export const getFollowupEmailLoading = createSelector(selectFollowupEmail, followupEmail => followupEmail.loading);
export const getFollowupEmailCommitSuccess = createSelector(selectFollowupEmail, state => state?.commitSuccess);
//follow up styling
export const getStylingFollowupEmailData = createSelector(selectFollowupEmail, followupEmail => followupEmail.stylingSettings);

// Banner email
export const selectBannerEmail = createSelector(selectTouchpoints, (state) => state.bannerEmail);
export const getBannerEmailData = createSelector(selectBannerEmail, bannerEmail => bannerEmail.settings);
export const getBannerEmailLoading = createSelector(selectBannerEmail, bannerEmail => bannerEmail.loading);
export const getBannerEmailCommitSuccess = createSelector(selectBannerEmail, state => state?.commitSuccess);
// styling
export const getStylingBannerEmailData = createSelector(selectBannerEmail, bannerEmail => bannerEmail.stylingSettings);

// Pre-arrival Banner email
export const selectBannerPrearrivalEmail = createSelector(selectTouchpoints, (state) => state.bannerPrearrivalEmail);
export const getBannerPrearrivalEmailLoading = createSelector(selectBannerPrearrivalEmail, bannerPrearrivalEmail => bannerPrearrivalEmail.loading);
export const getBannerPrearrivalEmailCommitSuccess = createSelector(selectBannerPrearrivalEmail, state => state?.commitSuccess);
// styling
export const getStylingBannerPrearrivalEmailData = createSelector(selectBannerPrearrivalEmail, bannerPrearrivalEmail => bannerPrearrivalEmail.stylingSettings);

// Invitation email
export const selectInvitationEmail = createSelector(selectTouchpoints, (state) => state.invitationEmail);
export const getInvitationEmailData = createSelector(selectInvitationEmail, invitationEmail => invitationEmail.settings);
export const getInvitationEmailLoading = createSelector(selectInvitationEmail, invitationEmail => invitationEmail.loading);
export const getInvitationEmailCommitSuccess = createSelector(selectInvitationEmail, state => state?.commitSuccess);
// styling
export const getStylingInvitationEmaillData = createSelector(selectInvitationEmail, invitationEmail => invitationEmail.stylingSettings);

// post-stay process modal
// messaging
export const selectPoststayProcess = createSelector(selectTouchpoints, (state) => state.poststayProcess);
export const getPoststayProcessData = createSelector(selectPoststayProcess, poststayProcess => poststayProcess.settings);
export const getPoststayProcessLoading = createSelector(selectPoststayProcess, poststayProcess => poststayProcess.loading);
export const getPoststayProcessCommitSuccess = createSelector(selectPoststayProcess, state => state?.commitSuccess);

// Story explorer
export const selectStoryExplorer = createSelector(selectTouchpoints, (state) => state.storyExplorer);
export const getStoryExplorerIntegrationData = createSelector(selectStoryExplorer, storyExplorer => storyExplorer.settings);
export const getStoryExplorerLoading = createSelector(selectStoryExplorer, storyExplorer => storyExplorer.loading);
export const getStoryExplorerCommitSuccess = createSelector(selectStoryExplorer, state => state?.commitSuccess);
// styling
export const getStylingStoryExplorerData = createSelector(selectStoryExplorer, storyExplorer => storyExplorer.stylingSettings);

// friend landing modal
// messaging
export const selectFriendLandign = createSelector(selectTouchpoints, (state) => state.friendLanding);
export const getFriendLandignData = createSelector(selectFriendLandign, friendLanding => friendLanding.settings);
export const getFriendLandignLoading = createSelector(selectFriendLandign, friendLanding => friendLanding.loading);
export const getFriendLandignCommitSuccess = createSelector(selectFriendLandign, state => state?.commitSuccess);
// styling
export const getStylingFriendLandignData = createSelector(selectFriendLandign, friendLanding => friendLanding.stylingSettings);
// integration
export const getIntegrationFriendLandignData = createSelector(selectFriendLandign, friendLanding => friendLanding.integrationSettings);

// Campgain Guests modal
export const selectCampaignGuests = createSelector(selectTouchpoints, (state) => state.campaignGuests);
export const getCampaignGuestsData = createSelector(selectCampaignGuests, campaignGuests => campaignGuests.settings);
export const getCampaignGuestsLoading = createSelector(selectCampaignGuests, campaignGuests => campaignGuests.loading);
export const getCampaignGuestsCommitSuccess = createSelector(selectCampaignGuests, state => state?.commitSuccess);

export const selectDiscoveryComponent = createSelector(selectTouchpoints, state => state.discoveryComponent);
export const selectDiscoveryComponentSettingsIsLoading = createSelector(selectDiscoveryComponent, state => state?.isLoading);
export const selectDiscoveryComponentSettingsLoadingError = createSelector(selectDiscoveryComponent, state => state?.error);
export const selectDiscoveryComponentSettingsCommitSuccess = createSelector(selectDiscoveryComponent, state => state?.commitSuccess);

export const selectDiscoveryProperties = createSelector(selectDiscoveryComponent, state => state.discoveryProperties);
export const selectDiscoveryPropertiesError = createSelector(selectDiscoveryComponent, state => state.discoveryPropertiesError);
export const selectDiscoveryPropertiesIsLoading = createSelector(selectDiscoveryComponent, state => state.isLoadingDiscoveryProperties);
export const selectMissionControlDetailsSettings = createSelector(selectDiscoveryComponent, state => state?.missionControlDetailsSettings);
export const selectIsFlightBookingOptionActive = createSelector(selectDiscoveryComponent, state =>
    state?.missionControlDetailsSettings?.bookingOptions?.find(option => option.bookingOptionId === BookingOptionId.StayAndFlight)?.isActive
);

export const selectDiscoveryComponentSettings = createSelector(selectDiscoveryComponent, state => state?.settings);
export const selectDiscoveryComponentSettingsIsActive = createSelector(selectDiscoveryComponentSettings, state => state?.isActive);
export const selectDiscoveryComponentGroupType = createSelector(selectDiscoveryComponentSettings, state => state?.componentGroupType);
export const selectDiscoveryComponentOwnerPropertyUuid = createSelector(selectDiscoveryComponentSettings, state => state?.owningPropertyUuid);
export const selectDiscoveryComponentOwnerCompanyUuid = createSelector(selectDiscoveryComponentSettings, state => state?.owningCompanyUuid);
export const selectDiscoveryComponentUsers = createSelector(selectDiscoveryComponent, state => state?.users);
export const selectDiscoveryComponentOwnerAndType = createSelector(selectDiscoveryComponentSettings, state => !state ? null : {
    componentGroupType: state.componentGroupType,
    owningCompanyUuid: state?.owningCompanyUuid,
    owningPropertyUuid: state?.owningPropertyUuid
});

const emailSettingsPath = {
    reengagement: 'Guest/plannerReengagement',
    reengagementreminder: 'Guest/plannerReengagementReminder'
};

export const selectEmailSettings = createSelector(selectDiscoveryComponentSettings, (state, { email }) =>
    state?.emailSettingsViewModels?.find(es => es.emailTemplatePath === emailSettingsPath[email])
);

export const selectDiscoveryComponentSettingsStylingViewModel = createSelector(selectDiscoveryComponentSettings, state => state?.stylingViewModel);
export const selectDiscoveryComponentSettingsIntegrationViewModel = createSelector(selectDiscoveryComponentSettings, state => state?.integrationViewModel);
export const selectDiscoveryComponentSettingsMappingViewModel = createSelector(selectDiscoveryComponentSettings, state => state?.mappingViewModel);
export const selectDiscoveryComponentSettingsPlannerFlowViewModel = createSelector(selectDiscoveryComponentSettings, state => state?.plannerFlowViewModel);

export const selectDiscoveryInjectableComponents = createSelector(selectTouchpoints, state => state?.discoveryInjectableComponents);
export const selectDiscoveryBookingBarComponents = createSelector(selectDiscoveryInjectableComponents, state => state?.bookingBarSettings);
export const selectDiscoveryBookingButtonComponents = createSelector(selectDiscoveryInjectableComponents, state => state?.bookingButtonSettings);
export const selectDiscoveryBarComponents = createSelector(selectDiscoveryInjectableComponents, state => state?.discoveryBarSettings);
export const selectDiscoveryInjectableComponentIsLoading = createSelector(selectDiscoveryInjectableComponents, state => state?.isLoading);
export const selectDiscoveryInjectableComponentCommitSuccess = createSelector(selectDiscoveryInjectableComponents, state => state?.commitSuccess);
export const selectDiscoveryInjectableComponentError = createSelector(selectDiscoveryInjectableComponents, state => state?.error);


export const selectDiscoveryBookingButtonComponentSettings = createSelector(selectDiscoveryInjectableComponents, (state) => {
    return state.bookingButtonSettings.find(b => b.componentUuid === state.selectedBookingButtonComponentUuid);
});
export const selectDiscoveryBarComponentSettings = createSelector(selectDiscoveryInjectableComponents, (state) => {
    return state.discoveryBarSettings.find(b => b.componentUuid === state.selectedDiscoveryBarComponentUuid);
});

export const selectDiscoveryBookingBarComponentSettings = createSelector(selectDiscoveryInjectableComponents, (state) => {
    return state.bookingBarSettings.find(b => b.componentUuid === state.selectedBookingBarComponentUuid);
});

export const selectDiscoveryPlannerFlowSettings = createSelector(selectTouchpoints, (state) => {
    return state.discoveryComponent.settings.plannerFlowViewModel;
});

export const selectSelectedDiscoveryInjectableComponentExists = createSelector(selectDiscoveryInjectableComponents, (state: DiscoveryInjectableComponentState, { componentType }: any) => {
    switch (componentType) {
        case DiscoveryComponentType.BookingButton: {
            return Array.isArray(state?.bookingButtonSettings) && state.bookingButtonSettings.length > 0;
        }
        case DiscoveryComponentType.BookingBar: {
            return Array.isArray(state?.bookingBarSettings) && state.bookingBarSettings.length > 0;
        }
        case DiscoveryComponentType.DiscoveryBar: {
            return Array.isArray(state?.discoveryBarSettings) && state.discoveryBarSettings.length > 0;
        }
        default:
            return false;
    }
});

export const selectDiscoveryInjectableComponentCreatedSuccess = createSelector(selectDiscoveryInjectableComponents, state => state.createSuccess);
export const selectDiscoveryInjectableComponentMessaging = createSelector(selectDiscoveryInjectableComponents, (state, {
    componentType,
    componentUuid
}) => {
    let component: IDiscoveryInjectableComponentSettings<IDiscoveryInjectableComponentMessaging>;
    switch (componentType) {
        case DiscoveryComponentType.BookingButton: {
            component = state?.bookingButtonSettings?.find(b => b?.componentUuid === componentUuid);
            break;
        }
        case DiscoveryComponentType.BookingBar: {
            component = state?.bookingBarSettings?.find(b => b?.componentUuid === componentUuid);
            break;
        }
        case DiscoveryComponentType.DiscoveryBar: {
            component = state?.discoveryBarSettings?.find(b => b?.componentUuid === componentUuid);
            break;
        }
    }

    if (!!component) {
        return component.messaging;
    } else {
        return [];
    }
});

export const selectDiscoveryPropertySettings = createSelector(selectDiscoveryPropertyState, state => state?.settings);
export const selectDiscoveryPropertySettingsIntegration = createSelector(selectDiscoveryPropertySettings, state => state?.integration);
export const selectDiscoveryPropertySettingsMessaging = createSelector(selectDiscoveryPropertySettings, state => ({
    messaging: state?.messaging
}));
export const selectDiscoveryPropertySettingsStyling = createSelector(selectDiscoveryPropertySettings, state => state?.styling);
export const selectDiscoveryPropertySettingsEmail = createSelector(selectDiscoveryPropertySettings, state => state?.emailSchedule);
export const selectDiscoveryPropertySettingsLoading = createSelector(selectDiscoveryPropertyState, state => state.loading);
export const selectDiscoveryPropertySettingsError = createSelector(selectDiscoveryPropertyState, state => state?.error);
export const selectDiscoveryPropertySettingsCommitSuccess = createSelector(selectDiscoveryPropertyState, state => state?.commitSuccess);
