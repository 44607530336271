/**
 * Created by ostapenko.r on 11/5/2014.
 */
angular.module('flipto.components.common.list')
    .directive('ftList', [function () {
        return {
            restrict: 'E',
            require: 'ftList',
            compile: function (element, attrs) {
                var removable = angular.isDefined(attrs.removable) && attrs.removable != 'false';
                var template = angular.element('<div class="list-item"><inject></inject></div>');


                template.attr("ng-repeat", attrs.repeat + " track by $index");
                removable && (template.attr("ft-removable", ""));

                var children = element.contents();
                element.empty();
                var injectElement = template.find('inject');
                injectElement.replaceWith(children);
                element.append(template);

                return function (scope, element, attributes, controller) {
                    var expression = attributes.repeat.split(' in ');
                    expression = {
                        child: expression[0],
                        property: expression[1]
                    };
                    controller.init(expression);
                }
            },

            controller: ['$scope', function ($scope) {
                var _expression;
                var _items;

                this.init = function (expression) {
                    _expression = expression;
                    _items = $scope.$eval(_expression.property);
                };

                this.getExpression = function () {
                    return _expression;
                };
                this.getItems = function () {
                    return $scope.$eval(_expression.property);
                }
            }]
        };
    }]);
