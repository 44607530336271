/**
 * Created by Roman on 2015-04-21.
 */



angular.module('flipto.accountv1.SharedFeatures')
    .filter('activityType', ['$filter', function ($filter) {

        var translate = $filter('translate'),
            typeMap = {
                'prestay.share': translate('flipto.account.advocateActivity.prestay.share'),
                'quote': translate('flipto.account.advocateActivity.quote'),
                'photo.upload': translate('flipto.account.advocateActivity.photo.upload'),
                'photo.finalist': translate('flipto.account.advocateActivity.photo.finalist'),
                'photo.vote': translate('flipto.account.advocateActivity.photo.vote'),
                'photo.share': translate('flipto.account.advocateActivity.photo.share')
            };

        return function (type) {
            return typeMap[type];
        };

    }]);