/**
 * Created by ostapenko.r on 1/21/2015.
 */

angular.module('flipto.components.common.sortable', ['flipto.core.array'])
    .directive("ftSortable", ['ArrayUtil', function (ArrayUtil) {
        return {
            restrict: 'EA',
            require: 'ftSortable',
            controller: ['$scope', function ($scope) {
                var self = this,
                    expression,
                    onSort;


                this.getItem = function () {
                    return $scope.$eval(expression.child);
                };

                this.getItems = function () {
                    return $scope.$eval(expression.property);
                };

                this.moveUp = function () {
                    var items = self.getItems();
                    $scope.$apply(function () {
                        ArrayUtil.moveUp(items, self.getItem());
                        onSort && onSort(items);
                    })
                };

                this.moveDown = function () {
                    var items = self.getItems();
                    $scope.$apply(function () {
                        ArrayUtil.moveDown(self.getItems(), self.getItem());
                        onSort && onSort(items);
                    })
                };

                this.isLast = function () {
                    var items = self.getItems();
                    return items.indexOf(self.getItem()) === items.length - 1;
                };

                this.isFirst = function () {
                    var items = self.getItems();
                    return items.indexOf(self.getItem()) === 0;
                };

                this.register = function (exprs, onSortFn) {
                    expression = exprs;
                    onSort = onSortFn;
                }

            }],
            link: function (scope, element, attrs, ftSortable) {
                var expression = attrs.ngRepeat.split(' in ');
                var onSort = scope.$eval(attrs.onSort);
                expression = {
                    child: expression[0],
                    property: expression[1]
                };
                ftSortable.register(expression, onSort);
                /*

                                scope.$watch(function () {
                                    return attrs.onSort && );
                                }, function (onSortFn) {
                                    ftSortable.register(expression, onSortFn);
                                });
                */


            }
        }
    }])
    .directive("ftSortUp", [function () {
        return {
            restrict: 'A',
            require: '^ftSortable',
            link: function (scope, element, attrs, ftSortable) {

                /*
                 scope.$watch(function () {
                     return ftSortable.isFirst(item);
                 }, function (isFirst) {
                     isFirst ? element.hide() : element.show();
                 });*/

                element.on("click", moveUp);
                scope.$on("$destroy", function () {
                    element.off("click", moveUp);
                });

                function moveUp(e) {
                    e.preventDefault();
                    e.stopPropagation();
                    ftSortable.moveUp();
                }
            }
        }
    }])
    .directive("ftSortDown", [function () {
        return {
            restrict: 'A',
            require: '^ftSortable',
            link: function (scope, element, attrs, ftSortable) {

                /*
                 scope.$watch(function () {
                     return ftSortable.isLast(item);
                 }, function (isLast) {
                     isLast ? element.hide() : element.show();
                 });*/

                element.on("click", moveDown);
                scope.$on("$destroy", function () {
                    element.off("click", moveDown);
                });
                function moveDown(e) {
                    e.preventDefault();
                    e.stopPropagation();
                    ftSortable.moveDown();
                }
            }
        }
    }]);
