/**
 * Created by ostapenko.r on 02/23/2015.
 */

angular
    .module("flipto.components.goals")
    .filter("editGoalsTitle",
    [
        "$filter",
        function ($filter) {

            var translate = $filter("translate"),
                capitalize = $filter('capitalize'),
                editGoalsFor = translate("flipto.components.goals.GoalsFor"),
                guests = translate('flipto.account.Guests'),
                advocates = translate('flipto.account.Advocates'),
                friends = translate('flipto.account.Friends'),
                attendees = capitalize(translate('flipto.components.goals.Attendees')),
                socialConnection = translate('flipto.account.SocialConnections'),
                warmLeads = translate('flipto.account.WarmLeads'),
                visitors = translate('flipto.account.Visitors');

            var map = {
                'pre-stay': {
                    1: advocates,
                    2: warmLeads
                },
                'post-stay': {
                    2: warmLeads,
                    3: advocates,
                    4: advocates,
                    6: advocates
                },
                'group-advocacy': {
                    1: advocates,
                    2: friends,
                    7: attendees
                },
                'photo-advocacy': {
                    2: visitors
                }
            };

            return function (type, layer) {
                return editGoalsFor + ' ' + map[layer][type];
            }
        }
    ]);