/**
 * Created by Roman on 2015-04-29.
 */



angular.module('flipto.core.moment', [])
    .provider('moment', [function () {

        this.$get = ['$window', function ($window) {
            return $window.moment;
        }];

        this.setRelativeTimeLocale = function (culture, config) {
            moment.updateLocale(culture, {
                relativeTime: config
            });
        };

    }]);
