/**
 * Created by Roman on 2015-02-02.
 */


angular
    .module('flipto.accountv1.SharedFeatures')
    .filter('importantOnlySocialConnections', ['_', function (_) {

        var socialMedia = ['fb', 'li', 'tw', 'Others'];

        return function (connections) {
            return _.filter(connections, function (connection) {
                return !!_.find(socialMedia, function (network) {
                    return angular.isString(connection.title) && network.toLowerCase() === connection.title.toLowerCase();
                });
            });
        }
    }]);