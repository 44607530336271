/**
 * Created by Roman on 2014-10-30.
 */


angular
    .module("flipto.components.imageLibrary")
    .config(['$compileProvider', function ($compileProvider) {
        $compileProvider.directive('ftImageUploadFromUrl', ['Image', function (Image) {
            return {
                restrict: 'EA',
                templateUrl: '/app/components/image-library/imageUploadFromUrl.html',
                scope: {
                    'onImageUploaded': '&',
                    'onImageUploading': '&',
                    'propertyId': '=',
                    'imageType': '='
                },
                link: function (scope) {

                    /**
                     * Upload image
                     */
                    scope.upload = function () {
                        var hasScheme = /^http(s)?:\/\//gi.test(scope.imageUrl);
                        var url = !hasScheme ? 'http://' + scope.imageUrl : scope.imageUrl;

                        scope.onImageUploading && scope.onImageUploading();
                        Image.uploadPropertyImageFromUrl(scope.propertyId, scope.imageType, url).then(function (response) {
                            scope.onImageUploaded && scope.onImageUploaded({ image: response });
                        });
                        scope.imageUrl = '';
                    }
                }
            };
        }]);
    }]);